import React from "react";

import {  Content } from "../../../styled-components/visualization";
import { CompetitorLandscapeBubbleChartContainer } from "../charts/CompetitorLandscapeBubbleChart";
import { TitleContainer } from "../utilComponents/Title";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";
import LoaderImage from "../../../components/LoaderImage";
import ErrorMessage from "../../../components/ErrorMessage";

export function CompetitorLandscapeContainer({companyDomain,competitorLandscapeResults,loadingCompetitorLandscape,successCompetitorLandscape,errorCompetitorLandscape}) {
  return (
    <Content.FlexColumn
    style={{
            justifyContent: 'space-between',
            padding: '1%',
            background: 'white',
        }} 
    >
        <TitleContainer 
            title='Battle of the Brands' 
            subtitle='Know where you stand among your industry peers and quickly identify who is a threat.'
            backdropTop = '55vw'           
            pages={insightsText.CompetitorLandscape[0]}
            p11={insightsText.CompetitorLandscape[1]}
            p21={insightsText.CompetitorLandscape[2]}
            p31={insightsText.CompetitorLandscape[3]}
            p41={insightsText.CompetitorLandscape[4]}
            p51={insightsText.CompetitorLandscape[5]}
            assistantMessage={assistantText.CompetitorLandscape} 
            />

          <Content.FlexColumn className={"justifyContentCenter"} >
          {
            loadingCompetitorLandscape || !successCompetitorLandscape 
              ? 
              <LoaderImage /> : 
              errorCompetitorLandscape 
              ? 
              <ErrorMessage /> :
              competitorLandscapeResults[0] && competitorLandscapeResults.length != 0 ? 
              <CompetitorLandscapeBubbleChartContainer companyDomain={companyDomain} competitorLandscapeResults={competitorLandscapeResults}/>
                : 
              <ResultsMissing details = {competitorLandscapeResults.detail}/>

          }
          </Content.FlexColumn>
       
       
    </Content.FlexColumn>
  )
}
