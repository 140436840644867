import styled from "styled-components"


export const Container = styled.div`
    
`
export const SelectContainer = styled.div`
    background: #F6F6F6;
    margin-bottom: 19px;

`

export const SelectHeader = styled.div`
    border-radius: 4px 0px 0px 0px;
    padding-left: 10px;
    font-size: 14px;
    height: 36px;
    &:hover{
        cursor:pointer;
    }
`

export const SelectList = styled.ul`
    padding: 0;
    overflow: auto;
    max-height: 150px;

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background:#F6F6F6;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 1px 0px 0px #F6F6F6;
        background: #F4865E;
    }
    
`

export const SelectListPink = styled.ul`
    padding: 0;
    overflow: auto;
    max-height: 150px;
    z-index: 100;

    width: 100%;
    background: #F6F6F6;


    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background:#F6F6F6;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 1px 0px 0px #F6F6F6;
        background: #C51852;
    }
    
`

export const SelectListItem = styled.li`
    list-style: none;
    font-size: 14px;
    height: 36px;
    padding: 8.5px 10px;
    &:hover {
        background: #F4865E;

        color: #FFFFFF;
    }

`

export const SelectListItemPink = styled.li`
    list-style: none;
    font-size: 14px;
    height: 36px;
    padding: 8.5px 10px;
    z-index: 1000;
    background: #F6F6F6;

    &:hover {
        background: #C51852;

        color: #FFFFFF;
    }

`

export const ResetPassword = styled.div`
    background: #841E5A;
    border-radius: 8px;
    position: absolute:
    left: 26px;
    top: 26px;
    opacity: 1;
    padding: 26px;

`

export const Loader = styled.div`
    background: #C51852;
    box-shadow: 0px 30px 26px #00000029;
    border-radius: 8px;
    position: absolute;
    bottom 90px;
    right: 50px;
    animation: fadeIn 1s;
    min-width: 362px;
`

export const TextArea = styled.textarea`
    background: #F6F6F6 ;
    border-radius: 4px;
    border: none;
    width: 100%;
    min-height: 350px;
    color: #222222;
    font-size: 14px;
    margin-top: 15px;

    ::placeholder {
        color: #222222;
        font-size: 14px;
        opacity: 50%;
      }


    outline-color: #F6F6F6;
  
`

export const RadioButtonItem = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 2px solid #C51852;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 9px;
`

export const RadioButtonLeft = styled.div`
      width: 80%;
`

export const RadioButtonRight = styled.div`
      width: 10%;
      display: flex;
      align-items: center;
`

export const RadioButtonEmptyCircle = styled.div`
      height: 22px;
      width: 22px;
    //  margin: 20px;
      border: 2px solid var(--unnamed-color-c51852);
    border: 2px solid #C51852;
    border-radius: 20px;
    display: flex;
    align-items: center;
`

export const RadioButtonFullCircle = styled.div`
    height: 8px;
    width: 8px;

    border: 2px solid var(--unnamed-color-c51852);
    border: 2px solid #C51852;
    border-radius: 20px;
    background: #C51852;
    margin: auto;
`

export const InputCSV = styled.label`
    border: 2px dashed #D4D4D4;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


  //  display: none;
    
`

export const TableRow = styled.div`
    display: flex;
    padding: 10px;
    margin-left: -13px;
    border-left: 3px solid transparent;
    
    &:hover{
        background: #F6F6F6;
        border-left: 3px solid #C51852;
    }
    
`

export const Icon22 = styled.img`
    width: 26px; 
    height: 26px;

    &:hover{
        cursor: pointer;
    }
`