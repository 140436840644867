import React, { useEffect, useState } from "react";

import { Text, Content } from "../../styled-components/visualization";

export function HelpAssistantContainer({ title,   assistantMessage}) {

  const [messageLink, setMessageLink] = useState(assistantMessage);

  const createAnchorLink = () => {
    const content = assistantMessage;

    if( title == 'SERP Real Estate Fingerprint' && content.includes('check here')){
      const parts = content.split('check here');
      return (
        <p>
          {parts[0]}
          <a target="_blank" href="https://search.google.com/test/rich-results">check here</a>
          {parts[1]}
        </p>
      );
    }else{
      return (<p>{content}</p>)
    }
  };

  

  useEffect( ()=>{

  },messageLink)

  return (
    <Content.AbsoluteDiv>
    <Content.FlexRow
    
    >
      <Content.FlexColumn 
        style = {{
          alignItems: 'center'
        }}
      >
        <Content.Triangle src={require("../../images/visualization/triangle.svg").default}/>
        <Content.HelpAssistant>
          <Text.Bold15White>
            {title}
          </Text.Bold15White>
          <Text.Regular15White>
           {createAnchorLink()}
          </Text.Regular15White>
          <Text.Regular15White>
           
          </Text.Regular15White>
        </Content.HelpAssistant>
      </Content.FlexColumn>
      </Content.FlexRow>
      </Content.AbsoluteDiv>
  )
}
