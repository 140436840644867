import React from 'react'

import * as ROUTES from "../../constants/routes/routes"

import { Text, MenuHeader, Content } from "../../styled-components/visualization";
import { useHistory } from 'react-router-dom'

export function SectionsFooterContainer({  pageName }) {
    let history = useHistory()
    const redirectToSummary = () => {
        history.push(ROUTES.VISUALIZATION_SUMMARY)
    }

    const redirectToBenchmarks = () => {
        history.push(ROUTES.VISUALIZATION_BENCHMARKS)
    }

    const redirectToCoverage = () => {
        history.push(ROUTES.VISUALIZATION_COVERAGE)
    }

    const redirectToCompetition = () => {
        history.push(ROUTES.VISUALIZATION_COMPETITION)
    }

    const redirectToResults = () => {
        history.push(ROUTES.VISUALIZATION_RESULTS)
    }

    return (
        <> 
            <Content.Overflow>
                <Content.FlexColumn
                    style={{
                        background: "#F7F7F7",
                        alignItems: 'center'
                    }}
                >
                    <Text.Medium40Black>
                        Visit more sections
                    </Text.Medium40Black>
                </Content.FlexColumn>
                <Content.FlexRow
                
                    style={{
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '3% 12%',
                        background: "#F7F7F7"
                    }}
                >
                <Content.FlexColumn
                    style={{
                        background: '#f1eaee',
                        padding: '15px 30px',
                        borderRadius: '5px',
                        width: '15%',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={redirectToSummary}
                >
                    <MenuHeader.Link
                        to={ROUTES.VISUALIZATION_SUMMARY}
                    >
                        <MenuHeader.ItemImg  src={require("../../images/visualization/Summary_Purple.svg").default}/>
                        <Text.Regular16Purple>Welcome</Text.Regular16Purple> 
                    </MenuHeader.Link>
                </Content.FlexColumn>
                <Content.FlexColumn
                    style={{
                        background: pageName === 'Benchmarks' ? '#841e5a' : '#f1eaee'  ,
                        padding: '15px 30px',
                        borderRadius: '5px',
                        width: '15%',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={redirectToBenchmarks}
                >
                    <MenuHeader.Link
                        to={ROUTES.VISUALIZATION_BENCHMARKS}
                    >
                    {pageName === 'Benchmarks' ?
                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    
                    src={require("../../images/visualization/Benchmarks_white.svg").default}/> 
                    :
                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    
                    src={require("../../images/visualization/Benchmarks_purple.svg").default}/> 
                    
                    }
                    {pageName === 'Benchmarks' ? 
                    <Text.Regular16White>Benchmarks</Text.Regular16White>
                    : <Text.Regular16Purple>Benchmarks</Text.Regular16Purple>
                    }
                    
                    </MenuHeader.Link>
                </Content.FlexColumn>
                <Content.FlexColumn
                    style={{
                        background: pageName === 'Coverage' ? '#841e5a' : '#f1eaee' ,
                        padding: '15px 30px',
                        borderRadius: '5px',
                        width: '15%',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={redirectToCoverage}
                >
                    <MenuHeader.Link
                        to={ROUTES.VISUALIZATION_COVERAGE}
                    >
                    {pageName === 'Coverage' ? 
                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../images/visualization/Coverage_white.svg").default}/>
                    :

                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../images/visualization/Coverage_purple.svg").default}/>
                    }
                    {pageName === 'Coverage' ? 
                    <Text.Regular16White>Coverage</Text.Regular16White> :
                    <Text.Regular16Purple>Coverage</Text.Regular16Purple>}
                    
                    </MenuHeader.Link>
                    </Content.FlexColumn>
                    <Content.FlexColumn
                    style={{
                        background: pageName === 'Competition' ? '#841e5a' : '#f1eaee' ,
                        padding: '15px 30px',
                        borderRadius: '5px',
                        width: '15%',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={redirectToCompetition}
                >
                    <MenuHeader.Link
                        to={ROUTES.VISUALIZATION_COMPETITION}
                    >
                    {pageName === 'Competition' ?
                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../images/visualization/Competition_white.svg").default}/>
                    :
                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../images/visualization/Competition_purple.svg").default}/>
                    } 
                    {pageName === 'Competition' ? 
                    <Text.Regular16White>Competition</Text.Regular16White>
                    :
                    <Text.Regular16Purple>Competition</Text.Regular16Purple>}
                    </MenuHeader.Link>
                    </Content.FlexColumn>
                    {/* <Content.FlexColumn
                    style={{
                        background: pageName === 'Results' ? '#841e5a' : '#f1eaee' ,
                        padding: '15px 30px',
                        borderRadius: '5px',
                        width: '15%',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={redirectToResults}
                >
                    <MenuHeader.Link
                        to={ROUTES.VISUALIZATION_RESULTS}
                    >
                    {pageName === 'Results' ? 
                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../images/visualization/Results_white.svg").default}/>
                    :
                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../images/visualization/Results_purple.svg").default}/>
                    }
                    {pageName === 'Results' ? 
                    <Text.Regular16White>Results</Text.Regular16White>
                    :
                    <Text.Regular16Purple>Results</Text.Regular16Purple>
                    
                    }
                    </MenuHeader.Link>
                    </Content.FlexColumn> */}
                </Content.FlexRow>
            </Content.Overflow>
        </>       
    )
}


