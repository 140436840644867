export const PARENT_LIST_REQUEST   = 'PARENT_LIST_REQUEST'
export const PARENT_LIST_SUCCESS   = 'PARENT_LIST_SUCCESS'
export const PARENT_LIST_FAIL      = 'PARENT_LIST_FAIL'
export const PARENT_LIST_RESET     = 'PARENT_LIST_RESET'

export const PARENT_DETAILS_REQUEST= 'PARENT_DETAILS_REQUEST'
export const PARENT_DETAILS_SUCCESS= 'PARENT_DETAILS_SUCCESS'
export const PARENT_DETAILS_FAIL   = 'PARENT_DETAILS_FAIL'
export const PARENT_DETAILS_RESET   = 'PARENT_DETAILS_RESET'

export const PARENT_DETAILS_BY_ID_REQUEST   = 'PARENT_COMPETITOR_BY_ID_REQUEST'
export const PARENT_DETAILS_BY_ID_SUCCESS   = 'PARENT_COMPETITOR_BY_ID_SUCCESS'
export const PARENT_DETAILS_BY_ID_FAIL      = 'PARENT_COMPETITOR_BY_ID_FAIL'
export const PARENT_DETAILS_BY_ID_RESET     = 'PARENT_COMPETITOR_BY_ID_RESET'

export const PARENT_CREATE_REQUEST = 'PARENT_CREATE_REQUEST'
export const PARENT_CREATE_SUCCESS = 'PARENT_CREATE_SUCCESS'
export const PARENT_CREATE_FAIL    = 'PARENT_CREATE_FAIL'
export const PARENT_CREATE_RESET   = 'PARENT_CREATE_RESET'

export const PARENT_UPDATE_REQUEST = 'PARENT_UPDATE_REQUEST'
export const PARENT_UPDATE_SUCCESS = 'PARENT_UPDATE_SUCCESS'
export const PARENT_UPDATE_FAIL    = 'PARENT_UPDATE_FAIL'
export const PARENT_UPDATE_RESET   = 'PARENT_UPDATE_RESET'
