import React, {useState, useEffect} from 'react'
import { Form, Button, Row, Col, Table} from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'
import { listProfileDetails } from '../actions/profileActions'
import { updateCompany, listCompanyDetailsById } from '../actions/companyActions'
import { listWebsiteDetails, createWebsite } from '../actions/websiteActions'
import { WEBSITE_UPDATE_RESET, WEBSITE_DETAILS_BY_ID_RESET } from '../constants/websiteConstants'
import { Link } from 'react-router-dom'
import { 
    COMPETITOR_DETAILS_RESET, 
    COMPETITOR_CREATE_RESET, 
    COMPETITOR_UPDATE_RESET, 
} from '../constants/competitorConstants'
import { 
    KEYWORD_DETAILS_RESET, 
    KEYWORD_CREATE_RESET, 
    KEYWORD_UPDATE_RESET, 
} from '../constants/keywordConstants'
import { days_of_week } from '../constants/utils'

function Company_details({match, history}) {
    const dispatch = useDispatch()
    const [accessToken, setAccessToken] = useState('')
    const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();


    //user details
    const [email, setEmail] = useState('')

    const profileDetails = useSelector(state => state.profileDetails)
    const {
        //loading, 
        //error, 
        success, 
        profile} = profileDetails

    //Company details
    const [companyId, setCompanyId] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyTelephone, setCompanyTelephone] = useState('')
    const [companyCreatedBy, setCompanyCreatedBy] = useState('')
    const [companyCreatedAt, setCompanyCreatedAt] = useState('')
    const [companyModifiedAt, setCompanyModifiedAt] = useState('')
    const [companyModifiedBy, setCompanyModifiedBy] = useState('')


    // const companyUpdate = useSelector(state => state.companyUpdate)
    // const {loading: loadingUpdateCompany, 
    //     error: errorUpdateCompany, 
    //     success: successUpdateCompany, 
    //     // company: updateCompany
    // } = companyUpdate

    const companyDetailsById = useSelector(state => state.companyDetailsById)
    const {//loading: loadingDetailsByIdCompany, 
        //error: errorDetailsByIdCompany, 
        success: successDetailsByIdCompany, 
        company
    } = companyDetailsById

    

    //Website info
    const [websiteName, setWebsiteName] = useState('')
    const [websiteURL, setWebsiteURL] = useState('')
    const [theme, setTheme] = useState('')
    const [active, setActive] = useState(false)
    const [frequency, setFrequency] = useState('')
    const [trigger_wday, setTrigger_wday] = useState('')
    const [trigger_mday, setTrigger_mday] = useState('')
    const [trigger_time] = useState('')
    const [slack_channel, setSlack_channel] = useState('')

    const websiteDetails = useSelector(state => state.websiteDetails)
    const {//loading: loadingDetailsWebsite, 
        //error: errorDetailsWebsite, 
        success: successDetailsWebsite, 
        website: detailsWebsite
    } = websiteDetails

    // const websiteCreate = useSelector(state => state.websiteCreate)
    // const {loading: loadingCreateWebsite, 
    //     error: errorCreateWebsite, 
    //     success: successCreateWebsite, 
    //     // website: createdWebsite
    // } = websiteCreate

    useEffect(()=>{
        const getAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getAccessToken()
        if(!user) {
            loginWithRedirect()
        } else {
            if(accessToken) {
                if(success) {
                    setEmail(profile.email)
                    if(successDetailsByIdCompany){
                        setCompanyId(company.id)
                        setCompanyName(company.name)
                        setCompanyAddress(company.address)
                        setCompanyTelephone(company.telephone)
                        setCompanyCreatedBy(company.createdBy)
                        setCompanyCreatedAt(company.createdAt)
                        setCompanyModifiedBy(company.modifiedBy)
                        setCompanyModifiedAt(company.modifiedAt)
                        if(successDetailsWebsite){
                        } else {
                            dispatch(listWebsiteDetails(accessToken, company.id))
                        }
                    } else {
                        
                        dispatch(listCompanyDetailsById(accessToken, match.params.companyId))
                    }
                } else {
                    dispatch(listProfileDetails(accessToken, user.email))
                }
            }
            console.log(frequency)
        }
    },[user, 
        frequency,
        accessToken, 
        success, 
        successDetailsByIdCompany,
        successDetailsWebsite,
        company,
        dispatch,
        loginWithRedirect,
        match,
        profile,
        detailsWebsite,
        getAccessTokenSilently,
        detailsWebsite,
    ])

    //Company functions
    const editCompanyHandler = (e) => {
        e.preventDefault()
        const company = {
            id: companyId,
            name: companyName,
            address: companyAddress,
            telephone: companyAddress,
            email
        }
        dispatch(updateCompany(accessToken, company, user.email))
    }


    //Website functions

    const createWebsiteHandler = (e) => {
        e.preventDefault()
        const website = {
            name: websiteName,
            website: websiteURL,
            theme,
            active,
            frequency,
            trigger_wday,
            trigger_mday,
            trigger_time,
            slack_channel
        }
        
        dispatch(createWebsite(accessToken, website, email, companyId))
    }

    const redirectWebsiteHandler = (e, websiteId) => {
        e.preventDefault()
        dispatch({type: WEBSITE_DETAILS_BY_ID_RESET})
        dispatch({type: WEBSITE_UPDATE_RESET})
        dispatch({type: COMPETITOR_DETAILS_RESET})
        dispatch({type: COMPETITOR_CREATE_RESET})
        dispatch({type: COMPETITOR_UPDATE_RESET})
        dispatch({type: KEYWORD_DETAILS_RESET})
        dispatch({type: KEYWORD_CREATE_RESET})
        dispatch({type: KEYWORD_UPDATE_RESET})
        history.push(`/website/details/${websiteId}`)
    }

    return (
        <>
        <Link to={`/profile`} className='btn btn-light my-3'>Go Back</Link>
        <Row className="d-flex justify-content-between">
                <Col md={5}>
                <h3>Company Info</h3>
                    <Form>
                        <Form.Group controlId='companyName'>
                            <Form.Label>
                                Name
                            </Form.Label>
                            <Form.Control
                                required
                                type='name'
                                placeholder='Enter Company Name'
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        
                        <Form.Group controlId='companyAddress'>
                            <Form.Label>
                                Address
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Enter Company Address'
                                value={companyAddress}
                                onChange={(e) => setCompanyAddress(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='companyTelephone'>
                            <Form.Label>
                                Telephone
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Enter Company Telephone'
                                value={companyTelephone}
                                onChange={(e) => setCompanyTelephone(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        {companyCreatedBy && <Form.Group controlId='companyCreatedBy'>
                            <Form.Label>
                                Created By
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={companyCreatedBy}
                                readOnly
                            >
                            </Form.Control>
                        </Form.Group>}
                        {companyCreatedAt && <Form.Group controlId='companyCreatedAt'>
                            <Form.Label>
                                Created At
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={companyCreatedAt && companyCreatedAt.substring(0, 10)}
                                readOnly
                            >
                            </Form.Control>
                        </Form.Group>}
                        {companyModifiedBy && <Form.Group controlId='companyModifiedBy'>
                            <Form.Label>
                                Modified By
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={companyModifiedBy}
                                readOnly
                            >
                            </Form.Control>
                        </Form.Group>}
                        {companyModifiedAt && <Form.Group controlId='companyModifiedAt'>
                            <Form.Label>
                                Modified At
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={companyModifiedAt && companyModifiedAt.substring(0, 10)}
                                readOnly
                            >
                            </Form.Control>
                        </Form.Group>}                    
                        <Button
                            onClick={editCompanyHandler}
                            variant='primary'
                        >
                            Edit Company
                        </Button>
                    </Form>
                </Col>
                <Col md={5}>
                <h2> Add Website</h2>
                <Form>
                    <Form.Group controlId='websiteName'>
                        <Form.Label>
                            Name
                        </Form.Label>
                        <Form.Control
                            required
                            type='name'
                            placeholder='Enter Website Name'
                            value={websiteName}
                            onChange={(e) => setWebsiteName(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    
                    <Form.Group controlId='websiteURL'>
                        <Form.Label>
                            Website URL
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Enter Website URL'
                            value={websiteURL}
                            onChange={(e) => setWebsiteURL(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='theme'>
                        <Form.Label>
                            Theme
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder='A custom theme for this websites set of keywords.'
                            value={theme}
                            onChange={(e) => setTheme(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='active'>
                        <Form.Check
                            type='checkbox'
                            label='Active?' 
                            id='active'
                            checked={active}
                            name='active'
                            onChange={(e) => setActive(!active)}                           
                        >
                        </Form.Check>
                    </Form.Group>
                    <Form.Label>
                        Frequency
                    </Form.Label>
                    <select 
                        className="form-control" 
                        onChange={(e) => setFrequency(e.target.value)}
                        required
                    >
                        <option value="weekly">Please select an option</option>
                        <option value="hourly">Hourly</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        
                    </select>
                    <br/>

                    <Form.Label>
                        Day of Week Trigger
                    </Form.Label>
                    <select 
                        className="form-control" 
                        onChange={(e) => setTrigger_wday(e.target.value)}
                        required
                    >
                        <option value="0">Please select an option</option>
                        {days_of_week.map((day, i) => (
                                    <option key={i} value={i}>{day}</option>
                                ))}
                    </select>
                    <br/>

                    <Form.Group controlId='trigger_mday'>
                        <Form.Label>
                            Day of Month Trigger
                        </Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='If monthly frequency, day of the month to run.(Can leave this blank).'
                            value={trigger_mday}
                            onChange={(e) => setTrigger_mday(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='slack_channel'>
                        <Form.Label>
                            Slack Channel
                        </Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Name of the Slack channel to issue alerts into. '
                            value={slack_channel}
                            onChange={(e) => setSlack_channel(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Button
                        onClick={createWebsiteHandler}
                        variant='primary'
                    >
                        Create Website
                    </Button>
                </Form>
            </Col>
            </Row>
            
            {detailsWebsite && <Row> 
            
            <Col md={5}>
               
               <h3>
                   Website details
               </h3>
               {(successDetailsWebsite && !(detailsWebsite.length === 0)) ? 
                    <div>
                    <Table striped  hover responsive className='table-sm'>
                        <thead >
                            <tr>
                                <th>Website name</th>
                                <th>Created At</th>
                                <th></th>
                            </tr>
                        </thead>
                        {detailsWebsite.map((website, index) => (
                            <tbody key={index}>
                                <tr>
                                    <td>{website.name}</td>
                                    <td>{website.createdAt.substring(0, 10)}</td>
                                    <td>
                                    <Button 
                                        variant='primary' 
                                        className='btn-sm' 
                                        onClick={(e) => redirectWebsiteHandler(e, website.id)}
                                    >
                                        <i className='fas fa-edit' ></i> Edit
                                    </Button>
                                    </td>
                                </tr>
                            </tbody>
                            ))} 
                    </Table>
                    
                    </div> :
                    <div>
                         <h3>Add a website</h3>
                    </div>
                }
            </Col>
            </Row> }
            </>
    )
}

export default Company_details
