import React from "react";
import { Text, Content } from "../../../../styled-components/visualization";
import {

} from "./seprSummariesUtils";

export const VerticalPanelComponent = (props) => {
  return (
    <Content.FlexColumn style={{ padding: "5%", textAlign: "center" }}>
      <Content.FlexRow>
        <Content.FlexColumn style={{ width: "100%", marginTop: "10px" }}>
          <Text.Regular13Grey style={{ textTransform: "uppercase" }}>
            KNOWLEDGE PANEL
          </Text.Regular13Grey>
        </Content.FlexColumn>
      </Content.FlexRow>

      <Content.FlexColumn
        style={{
          justifyContent: "space-between",
          textAlign: "center",
          padding: "7%",
        }}
      >
        <Text.Regular13Grey style={{ marginTop: "5px" }}>
          Avg. position
        </Text.Regular13Grey>
        <Text.Bold15Black>{props.row.avg_position.toFixed(2)}</Text.Bold15Black>
        <Text.Regular13Grey style={{ marginTop: "5px" }}>
          Avg. number of slots
        </Text.Regular13Grey>
        <Text.Bold15Black>
          {props.row.avg_available_slots.toFixed(2)}
        </Text.Bold15Black>
        <Text.Regular13Grey style={{ marginTop: "5px" }}>
          Keywords
        </Text.Regular13Grey>
        <Text.Bold15Black>{props.row.keyword_count}</Text.Bold15Black>
      </Content.FlexColumn>
    </Content.FlexColumn>
  );
};
