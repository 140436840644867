import React, {useContext, useEffect, useState} from 'react'
import {  Grid, Text } from "../styled-components/dashboard";
import {  Welcome} from "../styled-components/visualization";
import { useDispatch } from "react-redux";

import { GET_FILTERS_RESET,
  BIG_KEYWORD_RANKING_RESET, 
  COMPETITOR_CHANNEL_RESET, 
  COMPETITOR_LANDSCAPE_RESET, 
  COMPETITOR_SWOT_HEATMAP_RESET, 
  CONTENT_EXPLORER_RESET, 
  FEATURED_SNIPPED_OPPORTUNITIES_RESET, 
  HVN_SCORE_RESET, 
  MONTHLY_MOVEMENTS_RESET, 
  RETRIEVE_SWOT_RESET, 
  SERP_COMPOSITION_RESET,
  SERP_COMPOSITION_INCLUDE_RESET,
  SERP_LEAGUE_RESET,
  READ_RANKINGS_RESET,
  READ_SRE_RESET,
  INDUSTRY_BENCHMARKS_POWER_RESET,
  INDUSTRY_BENCHMARKS_STATS_RESET,
  SERP_COMPOSITION_FOR_BENCHMARKS_RESET,
  SERP_REAL_ESTATE_SCORE_RESET,
  TOTAL_SEARCH_CTR_RESET,
  INDUSTRY_BENCHMARKS_CHANNEL_RESET,
  TRACKING_SUMMARY_RESET,
  SERP_MOCKUP_RESET,
  WEBSITE_TRACKING_STATUS_RESET,
  SERPR_SCORE_100_RESET,
  SERPR_SCORE_RESET,
  COMPETITOR_SWOT_LANDING_PAGES_RESET
 

} from "../constants/visualization/dataScienceAPIsConstants";
import {KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET} from '../constants/keywordConstants'

import { CompanyContext } from "../contexts/CompanyContext";
import { useHistory } from "react-router-dom"
import { FilterContext } from '../contexts/FilterContext';

function WebsiteComponent({websiteId, websiteName, websiteUrl,  companyIndustry, 
  admin=false,
  createdBy,
  oldDashboard=false,
  country
}) {

  const dispatch = useDispatch()
  const {setDate,setCompanyId4Api, setCompanyIndustry, setWebsiteName, setCompanyDomain} = useContext(CompanyContext);
  const {
    exclude_array_keyword_parent,
            exclude_array_keyword_child,
            exclude_array_keyword_portfolio,
            exclude_array_keyword_subportfolio,
            exclude_array_keyword,
            exclude_array_country,
            exclude_array_location,
            exclude_array_language,
            exclude_array_device,
            exclude_array_domain,
            exclude_array_component_clean,
            initial_array_keyword_parent,
            initial_array_keyword_child,
            initial_array_keyword_portfolio,
            initial_array_keyword_subportfolio,
            initial_array_keyword,
            initial_array_country,
            initial_array_location,
            initial_array_language,
            initial_array_device,
            initial_array_domain,
            initial_array_component_clean,
  } = useContext(FilterContext);
  const history = useHistory()

  const nextHandler = (webId, websiteName, websiteUrl) => {

    // Re-initiliaze the filter's array in order to get the new values for the new website
    exclude_array_keyword_parent.splice(0,exclude_array_keyword_parent.length)
    exclude_array_keyword_child.splice(0,exclude_array_keyword_child.length)
    exclude_array_keyword_portfolio.splice(0,exclude_array_keyword_portfolio.length)
    exclude_array_keyword_subportfolio.splice(0,exclude_array_keyword_subportfolio.length)
    exclude_array_keyword.splice(0,exclude_array_keyword.length)
    exclude_array_country.splice(0,exclude_array_country.length)
    exclude_array_location.splice(0,exclude_array_location.length)
    exclude_array_language.splice(0,exclude_array_language.length)
    exclude_array_device.splice(0,exclude_array_device.length)
    exclude_array_domain.splice(0,exclude_array_domain.length)
    exclude_array_component_clean.splice(0,exclude_array_component_clean.length)

    initial_array_keyword_parent.splice(0,initial_array_keyword_parent.length)
    initial_array_keyword_child.splice(0,initial_array_keyword_child.length)
    initial_array_keyword_portfolio.splice(0,initial_array_keyword_portfolio.length)
    initial_array_keyword_subportfolio.splice(0,initial_array_keyword_subportfolio.length)
    initial_array_keyword.splice(0,initial_array_keyword.length)
    initial_array_country.splice(0,initial_array_country.length)
    initial_array_location.splice(0,initial_array_location.length)
    initial_array_language.splice(0,initial_array_language.length)
    initial_array_device.splice(0,initial_array_device.length)
    initial_array_domain.splice(0,initial_array_domain.length)
    initial_array_component_clean.splice(0,initial_array_component_clean.length)

    dispatch({type: GET_FILTERS_RESET})

    //Benchmarks + Summary
     dispatch({type: READ_RANKINGS_RESET})
     dispatch({type: READ_SRE_RESET})
     dispatch({type: INDUSTRY_BENCHMARKS_POWER_RESET})
     dispatch({type: INDUSTRY_BENCHMARKS_STATS_RESET})
     dispatch({type: SERP_COMPOSITION_FOR_BENCHMARKS_RESET})
     dispatch({type: SERP_REAL_ESTATE_SCORE_RESET})
     dispatch({type: TOTAL_SEARCH_CTR_RESET})
     dispatch({type: INDUSTRY_BENCHMARKS_CHANNEL_RESET})
     dispatch({type: TRACKING_SUMMARY_RESET})
     dispatch({type: SERP_MOCKUP_RESET})
     dispatch({type: WEBSITE_TRACKING_STATUS_RESET})
     dispatch({type: SERPR_SCORE_100_RESET})
     dispatch({type: SERPR_SCORE_RESET})

    //Coverage
    dispatch({type: BIG_KEYWORD_RANKING_RESET})
    dispatch({type: SERP_COMPOSITION_RESET})
    dispatch({type: SERP_COMPOSITION_INCLUDE_RESET})
    dispatch({type: CONTENT_EXPLORER_RESET})
    dispatch({type: RETRIEVE_SWOT_RESET})
    dispatch({type: RETRIEVE_SWOT_RESET})
    dispatch({type: FEATURED_SNIPPED_OPPORTUNITIES_RESET}) 

    //COMPETITION
    dispatch({type: COMPETITOR_LANDSCAPE_RESET})
    dispatch({type: COMPETITOR_SWOT_HEATMAP_RESET})
    dispatch({type: COMPETITOR_SWOT_LANDING_PAGES_RESET})
    dispatch({type: SERP_LEAGUE_RESET})
    dispatch({type: MONTHLY_MOVEMENTS_RESET})
    dispatch({type: HVN_SCORE_RESET})
    dispatch({type: COMPETITOR_CHANNEL_RESET})

    dispatch({type: KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET})

    // Reset date
    setDate([,]);

    setCompanyIndustry(companyIndustry)
    localStorage.setItem('companyIndustry', JSON.stringify(companyIndustry))

    setWebsiteName(websiteName)
    localStorage.setItem('websiteName', JSON.stringify(websiteName))

    // setWebsiteTheme(websiteTheme)
    // localStorage.setItem('websiteTheme', JSON.stringify(websiteTheme))

    //reset company domain (old domain is passed throw endpoints until page refresh)
    setCompanyDomain('')

    if(oldDashboard){
      setCompanyId4Api(webId)
      localStorage.setItem('companyId4Api', JSON.stringify(webId))
    } else {
      if(websiteName === 'Napier'){
        setCompanyId4Api(70)
        localStorage.setItem('companyId4Api', JSON.stringify(70))

        setCompanyIndustry('finance')
        localStorage.setItem('companyIndustry', JSON.stringify('finance'))
      } else{
        if(websiteName === 'Found'){
          setCompanyId4Api(59)
          localStorage.setItem('companyId4Api', JSON.stringify(59))

          setCompanyIndustry('internet and telecom')
          localStorage.setItem('companyIndustry', JSON.stringify('internet and telecom'))
        } else{
          if(websiteName === 'Secret Sales'){
            setCompanyId4Api(79)
            localStorage.setItem('companyId4Api', JSON.stringify(79))

            setCompanyIndustry('shopping')
            localStorage.setItem('companyIndustry', JSON.stringify('shopping'))
          } else {
            setCompanyId4Api(webId * 1000000000)
            localStorage.setItem('companyId4Api', JSON.stringify(webId * 1000000000))
            
          }
        }
      }
      
      
    }

    history.push(`/visualization/summary`)
   //setCompanyId4Api(7000)
  //  setCompanyId4Api(92)
  
  }


  const [modifiedUrl, setModifiedUrl] = useState(websiteUrl)

  useEffect(() => {

    if(modifiedUrl.includes('www.'))
      setModifiedUrl(modifiedUrl.replace('www.',''))
    if(modifiedUrl.includes('https://'))
      setModifiedUrl(modifiedUrl.replace('https://',''))
    if(modifiedUrl.includes('https:'))
      setModifiedUrl(modifiedUrl.replace('https:',''))
    if(modifiedUrl.includes('/'))
      setModifiedUrl(modifiedUrl.replace('/',''))
  }, [ modifiedUrl, country])
  

  return (
      <Grid.SectionWebsiteComponent
      onClick={()=>nextHandler(websiteId, modifiedUrl)}
      >
      <Grid.SectionWebsiteComponentBackground 
        style={{
              zIndex: '11'
            }}>
      <Welcome.FooterRightImageForWebsiteComponent
            style={{
              zIndex: '-1'              
            }}
            src={require("../images/visualization/04.png").default} />

       <Text.Bold26White
        style={{
          wordWrap: 'break-word',
          fontSize: '20px'
        }}
       >{modifiedUrl}</Text.Bold26White>
       {modifiedUrl == 'taggstar.com' &&
       <Text.Bold16Black
       style={{
        color: '#FFFFFF'
       }}
       >
        {country}
       </Text.Bold16Black>} 
       </Grid.SectionWebsiteComponentBackground>
       {admin && <Text.Normal14Black
        style={{
          marginTop: '.5rem'
        }}
       >{createdBy}</Text.Normal14Black>}
      </Grid.SectionWebsiteComponent>

  )
}

export default WebsiteComponent

