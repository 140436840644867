import {
    SERP_LEAGUE_REQUEST,
    SERP_LEAGUE_SUCCESS,
    SERP_LEAGUE_FAIL,
    SERP_LEAGUE_RESET,
    MONTHLY_MOVEMENTS_NUMBER_REQUEST,
    MONTHLY_MOVEMENTS_NUMBER_SUCCESS,
    MONTHLY_MOVEMENTS_NUMBER_FAIL,
    MONTHLY_MOVEMENTS_NUMBER_RESET,
} from '../../constants/visualization/dataScienceAPIsConstants'

import {
    READ_SRE_REQUEST,
    READ_SRE_SUCCESS,
    READ_SRE_FAIL,
    READ_SRE_RESET,
} from '../../constants/visualization/dataScienceAPIsConstants'

import {
    GET_FILTERS_REQUEST,
    GET_FILTERS_SUCCESS,
    GET_FILTERS_FAIL,
    GET_FILTERS_RESET
} from '../../constants/visualization/dataScienceAPIsConstants'


import {
    RETRIEVE_SWOT_REQUEST,
    RETRIEVE_SWOT_SUCCESS,
    RETRIEVE_SWOT_FAIL,
    RETRIEVE_SWOT_RESET,
} from '../../constants/visualization/dataScienceAPIsConstants'

import {
    CONTENT_EXPLORER_REQUEST,
    CONTENT_EXPLORER_SUCCESS,
    CONTENT_EXPLORER_FAIL,
    CONTENT_EXPLORER_RESET,
} from '../../constants/visualization/dataScienceAPIsConstants'

import {
    INDUSTRY_BENCHMARKS_POWER_REQUEST,
    INDUSTRY_BENCHMARKS_POWER_SUCCESS,
    INDUSTRY_BENCHMARKS_POWER_FAIL,
    INDUSTRY_BENCHMARKS_POWER_RESET,
} from '../../constants/visualization/dataScienceAPIsConstants'

import {
    INDUSTRY_BENCHMARKS_STATS_REQUEST,
    INDUSTRY_BENCHMARKS_STATS_SUCCESS,
    INDUSTRY_BENCHMARKS_STATS_FAIL,
    INDUSTRY_BENCHMARKS_STATS_RESET,
} from '../../constants/visualization/dataScienceAPIsConstants'

import {
    READ_RANKINGS_REQUEST,
    READ_RANKINGS_SUCCESS,
    READ_RANKINGS_FAIL,
    READ_RANKINGS_RESET,
} from '../../constants/visualization/dataScienceAPIsConstants'

import { 
    SERP_COMPOSITION_REQUEST,
    SERP_COMPOSITION_SUCCESS,
    SERP_COMPOSITION_FAIL,
    SERP_COMPOSITION_RESET,

    SERP_COMPOSITION_INCLUDE_REQUEST,
    SERP_COMPOSITION_INCLUDE_SUCCESS,
    SERP_COMPOSITION_INCLUDE_FAIL,
    SERP_COMPOSITION_INCLUDE_RESET,

    SERP_COMPOSITION_FOR_BENCHMARKS_REQUEST,
    SERP_COMPOSITION_FOR_BENCHMARKS_SUCCESS,
    SERP_COMPOSITION_FOR_BENCHMARKS_FAIL,
    SERP_COMPOSITION_FOR_BENCHMARKS_RESET,

    SERP_REAL_ESTATE_SCORE_REQUEST,
    SERP_REAL_ESTATE_SCORE_SUCCESS,
    SERP_REAL_ESTATE_SCORE_FAIL,
    SERP_REAL_ESTATE_SCORE_RESET,

    COMPETITOR_SWOT_HEATMAP_REQUEST,
    COMPETITOR_SWOT_HEATMAP_SUCCESS,
    COMPETITOR_SWOT_HEATMAP_FAIL,
    COMPETITOR_SWOT_HEATMAP_RESET,

    COMPETITOR_SWOT_LANDING_PAGES_REQUEST,
    COMPETITOR_SWOT_LANDING_PAGES_SUCCESS,
    COMPETITOR_SWOT_LANDING_PAGES_FAIL,
    COMPETITOR_SWOT_LANDING_PAGES_RESET,

    HVN_SCORE_REQUEST,
    HVN_SCORE_SUCCESS,
    HVN_SCORE_FAIL,
    HVN_SCORE_RESET,

    TOTAL_SEARCH_CTR_REQUEST,
    TOTAL_SEARCH_CTR_SUCCESS,
    TOTAL_SEARCH_CTR_FAIL,
    TOTAL_SEARCH_CTR_RESET,

    BIG_KEYWORD_RANKING_REQUEST,
    BIG_KEYWORD_RANKING_SUCCESS,
    BIG_KEYWORD_RANKING_FAIL,
    BIG_KEYWORD_RANKING_RESET,

    COMPETITOR_LANDSCAPE_REQUEST,
    COMPETITOR_LANDSCAPE_SUCCESS,
    COMPETITOR_LANDSCAPE_FAIL,
    COMPETITOR_LANDSCAPE_RESET,

    FEATURED_SNIPPED_OPPORTUNITIES_REQUEST,
    FEATURED_SNIPPED_OPPORTUNITIES_SUCCESS,
    FEATURED_SNIPPED_OPPORTUNITIES_FAIL,
    FEATURED_SNIPPED_OPPORTUNITIES_RESET,

    MONTHLY_MOVEMENTS_REQUEST,
    MONTHLY_MOVEMENTS_SUCCESS,
    MONTHLY_MOVEMENTS_FAIL,
    MONTHLY_MOVEMENTS_RESET,

    INDUSTRY_BENCHMARKS_CHANNEL_REQUEST,
    INDUSTRY_BENCHMARKS_CHANNEL_SUCCESS,
    INDUSTRY_BENCHMARKS_CHANNEL_FAIL,
    INDUSTRY_BENCHMARKS_CHANNEL_RESET,

    TRACKING_SUMMARY_REQUEST,
    TRACKING_SUMMARY_SUCCESS,
    TRACKING_SUMMARY_FAIL,
    TRACKING_SUMMARY_RESET,

    COMPETITOR_CHANNEL_REQUEST,
    COMPETITOR_CHANNEL_SUCCESS,
    COMPETITOR_CHANNEL_FAIL,
    COMPETITOR_CHANNEL_RESET,

    SERP_MOCKUP_REQUEST,
    SERP_MOCKUP_SUCCESS,
    SERP_MOCKUP_FAIL,
    SERP_MOCKUP_RESET,

    WEBSITE_TRACKING_STATUS_REQUEST,
    WEBSITE_TRACKING_STATUS_SUCCESS,
    WEBSITE_TRACKING_STATUS_FAIL,
    WEBSITE_TRACKING_STATUS_RESET,

    SERPR_SCORE_100_REQUEST,
    SERPR_SCORE_100_SUCCESS,
    SERPR_SCORE_100_FAIL,
    SERPR_SCORE_100_RESET,

    SERPR_SCORE_REQUEST,
    SERPR_SCORE_SUCCESS,
    SERPR_SCORE_FAIL,
    SERPR_SCORE_RESET,


 } from '../../constants/visualization/dataScienceAPIsConstants'

export const serpLeagueReducer = (state = {serpLeagueResults:[]}, action) => {
    switch (action.type) {
        case SERP_LEAGUE_REQUEST:
            return { loading: true }

        case SERP_LEAGUE_SUCCESS:
            return {
                loading: false,
                success: true,
                serpLeagueResults: action.payload,
                serpLeagueResultsTotalRows:action.total_rows
            }

        case SERP_LEAGUE_FAIL:
            return { loading: false, error: action.payload }

        case SERP_LEAGUE_RESET: 
            return {loading:false, success:false, serpLeagueResults: {},serpLeagueResultsTotalRows:0}

        default:
            return state
    }
}

export const readRankingsReducer = (state = {readRankingsResults:[]}, action) => {
    switch (action.type) {
        case READ_RANKINGS_REQUEST:
            return { loading: true }

        case READ_RANKINGS_SUCCESS:
            return {
                loading: false,
                success: true,
                readRankingsResults: action.payload,
            }

        case READ_RANKINGS_FAIL:
            return { loading: false, error: action.payload }

        case READ_RANKINGS_RESET: 
            return {loading:false, success:false, readRankingResults: {}}

        default:
            return state
    }
}

export const getFiltersReducer = (state = {filtersResults:[]}, action) => {
    switch (action.type) {
        case GET_FILTERS_REQUEST:
            return { loading: true }

        case GET_FILTERS_SUCCESS:
            return {
                loading: false,
                success: true,
                filtersResults: action.payload,
            }

        case GET_FILTERS_FAIL:
            return { loading: false, error: action.payload }

        case GET_FILTERS_RESET: 
            return {loading:false, success:false, filtersResults: {}}
    

        default:
            return state
    }
}

export const readSREReducer = (state = {readSREResults:[]}, action) => {
    switch (action.type) {
        case READ_SRE_REQUEST:
            return { loading: true }

        case READ_SRE_SUCCESS:
            return {
                loading: false,
                success: true,
                readSREResults: action.payload,
            }

        case READ_SRE_FAIL:
            return { loading: false, error: action.payload }

        case READ_SRE_RESET: 
            return {loading:false, success:false, readSREResults: {}}

        default:
            return state
    }
}

export const retrieveSWOTReducer = (state = {retrieveSWOTResults:[]}, action) => {
    switch (action.type) {
        case RETRIEVE_SWOT_REQUEST:
            return { ...state,loading: true } //keep the state and update only the needed value

        case RETRIEVE_SWOT_SUCCESS:
            return {
                loading: false,
                success: true,
                retrieveSWOTResults: action.payload,
            }

        case RETRIEVE_SWOT_FAIL:
            return { loading: false, error: action.payload }

        case RETRIEVE_SWOT_RESET: 
            return {loading:false, success:false, retrieveSWOTResults: {}}

        default:
            return state
    }
}

export const contentExplorerReducer = (state = {contentExplorerResults:[]}, action) => {
    switch (action.type) {
        case CONTENT_EXPLORER_REQUEST:
            return { loading: true }

        case CONTENT_EXPLORER_SUCCESS:
            return {
                loading: false,
                success: true,
                contentExplorerResults: action.payload,
            }

        case CONTENT_EXPLORER_FAIL:
            return { loading: false, error: action.payload }

        case CONTENT_EXPLORER_RESET: 
            return {loading:false, success:false, contentExplorerResults: {}}

        default:
            return state
    }
}

export const industryBenchmarksPowerReducer = (state = {industryBenchmarksPowerResults:[]}, action) => {
    switch (action.type) {
        case INDUSTRY_BENCHMARKS_POWER_REQUEST:
            return { loading: true }

        case INDUSTRY_BENCHMARKS_POWER_SUCCESS:
            return {
                loading: false,
                success: true,
                industryBenchmarksPowerResults: action.payload,
            }

        case INDUSTRY_BENCHMARKS_POWER_FAIL:
            return { loading: false, error: action.payload }

        case INDUSTRY_BENCHMARKS_POWER_RESET: 
            return {loading:false, success:false, industryBenchmarksPowerResults: {}}

        default:
            return state
    }
}

export const industryBenchmarksStatsReducer = (state = {industryBenchmarksStatsResults:[]}, action) => {
    switch (action.type) {
        case INDUSTRY_BENCHMARKS_STATS_REQUEST:
            return { loading: true }

        case INDUSTRY_BENCHMARKS_STATS_SUCCESS:
            return {
                loading: false,
                success: true,
                industryBenchmarksStatsResults: action.payload,
            }

        case INDUSTRY_BENCHMARKS_STATS_FAIL:
            return { loading: false, error: action.payload }

        case INDUSTRY_BENCHMARKS_STATS_RESET: 
            return {loading:false, success:false, industryBenchmarksStatsResults: {}}

        default:
            return state
    }
}

export const serpCompositionReducer = (state = {serpCompositionResults:[]}, action) => {
    switch (action.type) {
        case SERP_COMPOSITION_REQUEST:
            return { loading: true }

        case SERP_COMPOSITION_SUCCESS:
            return {
                loading: false,
                success: true,
                serpCompositionResults: action.payload,
            }

        case SERP_COMPOSITION_FAIL:
            return { loading: false, error: action.payload }

        case SERP_COMPOSITION_RESET: 
            return {loading:false, success:false, serpCompositionResults: {}}

        default:
            return state
    }
}

export const serpCompositionIncludeReducer = (state = {serpCompositionIncludeResults:[]}, action) => {
    switch (action.type) {
        case SERP_COMPOSITION_INCLUDE_REQUEST:
            return { loading: true }

        case SERP_COMPOSITION_INCLUDE_SUCCESS:
            return {
                loading: false,
                success: true,
                serpCompositionIncludeResults: action.payload,
            }

        case SERP_COMPOSITION_INCLUDE_FAIL:
            return { loading: false, error: action.payload }

        case SERP_COMPOSITION_INCLUDE_RESET: 
            return {loading:false, success:false, serpCompositionIncludeResults: {}}

        default:
            return state
    }
}

export const serpCompositionForBenchmarksReducer = (state = {serpCompositionForBenchmarksResults:[]}, action) => {
    switch (action.type) {
        case SERP_COMPOSITION_FOR_BENCHMARKS_REQUEST:
            return { loading: true }

        case SERP_COMPOSITION_FOR_BENCHMARKS_SUCCESS:
            return {
                loading: false,
                success: true,
                serpCompositionForBenchmarksResults: action.payload,
            }

        case SERP_COMPOSITION_FOR_BENCHMARKS_FAIL:
            return { loading: false, error: action.payload }

        case SERP_COMPOSITION_FOR_BENCHMARKS_RESET: 
            return {loading:false, success:false, serpCompositionForBenchmarksResults: {}}

        default:
            return state
    }
}

export const serpRealEstateScoreReducer = (state = {serpRealEstateScoreResults:[]}, action) => {
    switch (action.type) {
        case SERP_REAL_ESTATE_SCORE_REQUEST:
            return { loading: true }

        case SERP_REAL_ESTATE_SCORE_SUCCESS:
            return {
                loading: false,
                success: true,
                serpRealEstateScoreResults: action.payload,
            }

        case SERP_REAL_ESTATE_SCORE_FAIL:
            return { loading: false, error: action.payload }

        case SERP_REAL_ESTATE_SCORE_RESET: 
            return {loading:false, success:false, serpRealEstateScoreResults: {}}

        default:
            return state
    }
}


export const competitorSwotHeatmapReducer = (state = {competitorSwotHeatmapResults:[]}, action) => {
    switch (action.type) {
        case COMPETITOR_SWOT_HEATMAP_REQUEST:
            return { loading: true }

        case COMPETITOR_SWOT_HEATMAP_SUCCESS:
            return {
                loading: false,
                success: true,
                competitorSwotHeatmapResults: action.payload,
            }

        case COMPETITOR_SWOT_HEATMAP_FAIL:
            return { loading: false, error: action.payload }

        case COMPETITOR_SWOT_HEATMAP_RESET: 
            return {loading:false, success:false, competitorSwotHeatmapResults: {}}

        default:
            return state
    }
}

export const competitorSwotLandingPagesReducer = (state = {competitorSwotLandingPagesResults:[]}, action) => {
    switch (action.type) {
        case COMPETITOR_SWOT_LANDING_PAGES_REQUEST:
            return { loading: true }

        case COMPETITOR_SWOT_LANDING_PAGES_SUCCESS:
            return {
                loading: false,
                success: true,
                competitorSwotLandingPagesResults: action.payload,
                competitorSwotLandingPagesResultsTotalRows:action.total_rows
            }

        case COMPETITOR_SWOT_LANDING_PAGES_FAIL:
            return { loading: false, error: action.payload }

        case COMPETITOR_SWOT_LANDING_PAGES_RESET: 
            return {loading:false, success:false, competitorSwotLandingPagesResults: {},competitorSwotLandingPagesResultsTotalRows:0 }

        default:
            return state
    }
}

export const hvnScoreReducer = (state = {hvnScoreResults:[]}, action) => {
    switch (action.type) {
        case HVN_SCORE_REQUEST:
            return { loading: true }

        case HVN_SCORE_SUCCESS:
            return {
                loading: false,
                success: true,
                hvnScoreResults: action.payload,
                hvnScoreResultsTotalRows: action.total_rows,
            }

        case HVN_SCORE_FAIL:
            return { loading: false, error: action.payload }

        case HVN_SCORE_RESET: 
            return {loading:false, success:false, hvnScoreResults: {}, hvnScoreResultsTotalRows:0}

        default:
            return state
    }
}

export const totalSearchCtrReducer = (state = {totalSearchCtrResults:[]}, action) => {
    switch (action.type) {
        case TOTAL_SEARCH_CTR_REQUEST:
            return { loading: true }

        case TOTAL_SEARCH_CTR_SUCCESS:
            return {
                loading: false,
                success: true,
                totalSearchCtrResults: action.payload,
            }

        case TOTAL_SEARCH_CTR_FAIL:
            return { loading: false, error: action.payload }

        case TOTAL_SEARCH_CTR_RESET: 
            return {loading:false, success:false, totalSearchCtrResults: {}}

        default:
            return state
    }
}

export const bigKeywordRankingOppReducer = (state = {bigKeywordRankingOppResults:[]}, action) => {
    switch (action.type) {
        case BIG_KEYWORD_RANKING_REQUEST:
            return { loading: true }

        case BIG_KEYWORD_RANKING_SUCCESS:
            return {
                loading: false,
                success: true,
                bigKeywordRankingOppResults: action.payload,
            }

        case BIG_KEYWORD_RANKING_FAIL:
            return { loading: false, error: action.payload }

        case BIG_KEYWORD_RANKING_RESET: 
            return {loading:false, success:false, bigKeywordRankingOppResults: {}}

        default:
            return state
    }
}

export const competitorLandscapeReducer = (state = {competitorLandscapeResults:[]}, action) => {
    switch (action.type) {
        case COMPETITOR_LANDSCAPE_REQUEST:
            return { loading: true }

        case COMPETITOR_LANDSCAPE_SUCCESS:
            return {
                loading: false,
                success: true,
                competitorLandscapeResults: action.payload,
            }

        case COMPETITOR_LANDSCAPE_FAIL:
            return { loading: false, error: action.payload }

        case COMPETITOR_LANDSCAPE_RESET: 
            return {loading:false, success:false, competitorLandscapeResults: {}}

        default:
            return state
    }
}

export const featuredSnippetOppReducer = (state = {featuredSnippetOppResults:[]}, action) => {
    switch (action.type) {
        case FEATURED_SNIPPED_OPPORTUNITIES_REQUEST:
            return { loading: true }

        case FEATURED_SNIPPED_OPPORTUNITIES_SUCCESS:
            return {
                loading: false,
                success: true,
                featuredSnippetOppResults: action.payload,
                featuredSnippetOppTotalRows:action.total_rows
            }

        case FEATURED_SNIPPED_OPPORTUNITIES_FAIL:
            return { loading: false, error: action.payload }

        case FEATURED_SNIPPED_OPPORTUNITIES_RESET: 
            return {loading:false, success:false, featuredSnippedOppResults: {}, featuredSnippetOppTotalRows:0}

        default:
            return state
    }
}

export const monthlySeoPpcCompetitorReducer = (state = {monthlySeoPpcCompetitorResults:[]}, action) => {
    switch (action.type) {
        case MONTHLY_MOVEMENTS_REQUEST:
            return { loading: true }

        case MONTHLY_MOVEMENTS_SUCCESS:
            return {
                loading: false,
                success: true,
                monthlySeoPpcCompetitorResults: action.payload,
                monthlySeoPpcCompetitorResultsTotalRows: action.total_rows,
            }

        case MONTHLY_MOVEMENTS_FAIL:
            return { loading: false, error: action.payload }

        case MONTHLY_MOVEMENTS_RESET: 
            return {loading:false, success:false, monthlySeoPpcCompetitorResults: {},monthlySeoPpcCompetitorResultsTotalRows:0}

        default:
            return state
    }
}

export const monthlySeoPpcCompetitorNumberReducer = (state = {monthlySeoPpcCompetitorNumberResults:[]}, action) => {
    switch (action.type) {
        case MONTHLY_MOVEMENTS_NUMBER_REQUEST:
            return { loading: true }

        case MONTHLY_MOVEMENTS_NUMBER_SUCCESS:
            return {
                loading: false,
                success: true,
                monthlySeoPpcCompetitorNumberResults: action.payload
            }

        case MONTHLY_MOVEMENTS_NUMBER_FAIL:
            return { loading: false, error: action.payload }

        case MONTHLY_MOVEMENTS_NUMBER_RESET: 
            return {loading:false, success:false, monthlySeoPpcCompetitorNumberResults: {}}

        default:
            return state
    }
}

export const industryBenchmarksChannelReducer = (state = {industryBenchmarksChannelResults:[]}, action) => {
    switch (action.type) {
        case INDUSTRY_BENCHMARKS_CHANNEL_REQUEST:
            return { loading: true }

        case INDUSTRY_BENCHMARKS_CHANNEL_SUCCESS:
            return {
                loading: false,
                success: true,
                industryBenchmarksChannelResults: action.payload,
            }

        case INDUSTRY_BENCHMARKS_CHANNEL_FAIL:
            return { loading: false, error: action.payload }

        case INDUSTRY_BENCHMARKS_CHANNEL_RESET: 
            return {loading:false, success:false, industryBenchmarksChannelResults: {}}

        default:
            return state
    }
}

export const trackingSummaryReducer = (state = {trackingSummaryResults:[]}, action) => {
    switch (action.type) {
        case TRACKING_SUMMARY_REQUEST:
            return { loading: true }

        case TRACKING_SUMMARY_SUCCESS:
            return {
                loading: false,
                success: true,
                trackingSummaryResults: action.payload,
            }

        case TRACKING_SUMMARY_FAIL:
            return { loading: false, error: action.payload }

        case TRACKING_SUMMARY_RESET: 
            return {loading:false, success:false, trackingSummaryResults: {}}

        default:
            return state
    }
}

export const competitorChannelReducer = (state = {competitorChannelResults:[]}, action) => {
    switch (action.type) {
        case COMPETITOR_CHANNEL_REQUEST:
            return { loading: true }

        case COMPETITOR_CHANNEL_SUCCESS:
            return {
                loading: false,
                success: true,
                competitorChannelResults: action.payload,
            }

        case COMPETITOR_CHANNEL_FAIL:
            return { loading: false, error: action.payload }

        case COMPETITOR_CHANNEL_RESET: 
            return {loading:false, success:false, competitorChannelResults: {}}

        default:
            return state
    }
}

export const serpMockupReducer = (state = {serpMockupResults:[]}, action) => {
    switch (action.type) {
        case SERP_MOCKUP_REQUEST:
            return { loading: true }

        case SERP_MOCKUP_SUCCESS:
            return {
                loading: false,
                success: true,
                serpMockupResults: action.payload,
            }

        case SERP_MOCKUP_FAIL:
            return { loading: false, error: action.payload }

        case SERP_MOCKUP_RESET: 
            return {loading:false, success:false, serpMockupResults: {}}

        default:
            return state
    }
}


export const getWebsiteTrackingStatusReducer = (state = {websiteTrackingStatusResults:[]}, action) => {
    switch (action.type) {
        case WEBSITE_TRACKING_STATUS_REQUEST:
            return { loading: true }

        case WEBSITE_TRACKING_STATUS_SUCCESS:
            return {
                loading: false,
                success: true,
                websiteTrackingStatusResults: action.payload,
            }

        case WEBSITE_TRACKING_STATUS_FAIL:
            return { loading: false, error: action.payload }

        case WEBSITE_TRACKING_STATUS_RESET: 
            return {loading:false, success:false, websiteTrackingStatusResults: {}}
    

        default:
            return state
    }
}

export const getSerprScore100Reducer = (state = {serprScore100Results:[]}, action) => {
    switch (action.type) {
        case SERPR_SCORE_100_REQUEST:
            return { loading: true }

        case SERPR_SCORE_100_SUCCESS:
            return {
                loading: false,
                success: true,
                serprScore100Results: action.payload,
            }

        case SERPR_SCORE_100_FAIL:
            return { loading: false, error: action.payload }

        case SERPR_SCORE_100_RESET: 
            return {loading:false, success:false, serprScore100Results: {}}
    

        default:
            return state
    }
}

export const getSerprScoreReducer = (state = {serprScoreResults:[]}, action) => {
    switch (action.type) {
        case SERPR_SCORE_REQUEST:
            return { loading: true }

        case SERPR_SCORE_SUCCESS:
            return {
                loading: false,
                success: true,
                serprScoreResults: action.payload,
            }

        case SERPR_SCORE_FAIL:
            return { loading: false, error: action.payload }

        case SERPR_SCORE_RESET: 
            return {loading:false, success:false, serprScoreResults: {}}
    

        default:
            return state
    }
}