import { 
    PAYMENT_LIST_REQUEST,
    PAYMENT_LIST_SUCCESS,
    PAYMENT_LIST_FAIL,

    PAYMENT_DETAILS_REQUEST,
    PAYMENT_DETAILS_SUCCESS,
    PAYMENT_DETAILS_FAIL,
    PAYMENT_DETAILS_RESET,

    PAYMENT_DETAILS_BY_ID_REQUEST,
    PAYMENT_DETAILS_BY_ID_SUCCESS,
    PAYMENT_DETAILS_BY_ID_FAIL,
    PAYMENT_DETAILS_BY_ID_RESET,

    PAYMENT_CREATE_REQUEST,
    PAYMENT_CREATE_SUCCESS,
    PAYMENT_CREATE_FAIL,
    PAYMENT_CREATE_RESET,

    PAYMENT_UPDATE_STATUS_REQUEST,
    PAYMENT_UPDATE_STATUS_SUCCESS,
    PAYMENT_UPDATE_STATUS_FAIL,
    PAYMENT_UPDATE_STATUS_RESET,

    PAYMENT_DELETE_REQUEST,
    PAYMENT_DELETE_SUCCESS,
    PAYMENT_DELETE_FAIL,
    PAYMENT_DELETE_RESET,

 } from '../constants/paymentConstants'

 export const paymentListReducer =  (state = {payments:[]}, action) => {
    switch(action.type) {
        case PAYMENT_LIST_REQUEST:
            return {loading: true, payments:[]}

        case PAYMENT_LIST_SUCCESS:
            return {loading: false, payments: action.payload}

        case PAYMENT_LIST_FAIL:
            return {loading: false, error: action.payload }

        default:
            return state
    }
}

 export const paymentCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case PAYMENT_CREATE_REQUEST: 
            return {loading:true}

        case PAYMENT_CREATE_SUCCESS: 
            return {loading:false, success: true, payment: action.payload}
        
        case PAYMENT_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case PAYMENT_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const paymentUpdateStatusReducer = ( state = { payment: {} }, action ) => {
    switch(action.type){
        case PAYMENT_UPDATE_STATUS_REQUEST: 
            return {loading:true}

        case PAYMENT_UPDATE_STATUS_SUCCESS: 
            return {loading:false, success: true, payment: action.payload}
        
        case PAYMENT_UPDATE_STATUS_FAIL: 
            return {loading:false, error: action.payload}

        case PAYMENT_UPDATE_STATUS_RESET: 
            return { payment: {} }

        default: 
            return state
    }
}


export const paymentDetailsReducer = ( state = {payment :[]}, action ) => {
    switch(action.type){
        case PAYMENT_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case PAYMENT_DETAILS_SUCCESS: 
            return {loading:false, success:true,  payment: action.payload}
        
        case PAYMENT_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case PAYMENT_DETAILS_RESET: 
            return {}

        default: 
            return state
    }
}

export const paymentDetailsByIdReducer = ( state = {payment :{}}, action ) => {
    switch(action.type){
        case PAYMENT_DETAILS_BY_ID_REQUEST: 
            return {loading:true, ...state}

        case PAYMENT_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  payment: action.payload}
        
        case PAYMENT_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case PAYMENT_DETAILS_BY_ID_RESET: 
            return {}

        default: 
            return state
    }
}


export const paymentDeleteReducer = ( state = {payment :{}}, action ) => {
    switch(action.type){
        case PAYMENT_DELETE_REQUEST: 
            return {loading:true, ...state}

        case PAYMENT_DELETE_SUCCESS: 
            return {loading:false, success:true,  payment: action.payload}
        
        case PAYMENT_DELETE_FAIL: 
            return {loading:false, error: action.payload}

        case PAYMENT_DELETE_RESET: 
            return {loading:false, success:false, payment: {}}

        default: 
            return state
    }
}