import React from 'react'

import { ImproveSerprFirstPageContainer } from '../../containers/signup/improveSerprFirstPage'

export default function ImproveSerprFirstPage({accessToken, name, email, isActive, isSuperuser}) {
    return <ImproveSerprFirstPageContainer 
    accessToken={accessToken} 
    name={name} 
    email={email} 
    isActive={isActive}
    isSuperuser={isSuperuser}
    />
}
