import React from "react"
import { Container, SectionTop, SectionBottom, ButtonsContainer, FlexColumn, FlexRow, 
    Relative, Absolute, TickBox, Icon, SectionTopWithBackground
} from "./styles/content"

export default function Content({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}


Content.SectionTop = function ContentSectionTop({children, ...restProps}){
    return <SectionTop {...restProps}>{children}</SectionTop>   
}

Content.SectionTopWithBackground = function ContentSectionTopWithBackground({children, ...restProps}){
    return <SectionTopWithBackground {...restProps}>{children}</SectionTopWithBackground>   
}

Content.SectionBottom = function ContentSectionBottom({children, ...restProps}){
    return <SectionBottom {...restProps}>{children}</SectionBottom>   
}

Content.ButtonsContainer = function ContentButtonsContainer({children, ...restProps}){
    return <ButtonsContainer {...restProps}>{children}</ButtonsContainer>   
}
 
Content.FlexRow = function ContentFlexRow({children, ...restProps}){
    return <FlexRow {...restProps}>{children}</FlexRow>   
}

Content.FlexColumn = function ContentFlexColumn({children, ...restProps}){
    return <FlexColumn {...restProps}>{children}</FlexColumn>   
}

Content.Relative = function ContentRelative({children, ...restProps}){
    return <Relative {...restProps}>{children}</Relative>   
}

Content.Absolute = function ContentAbsolute({children, ...restProps}){
    return <Absolute {...restProps}>{children}</Absolute>   
}

Content.TickBox = function ContentTickBox({children, ...restProps}){
    return <TickBox {...restProps}>{children}</TickBox>   
}

Content.Icon = function ContentIcon({to, ...restProps}){
    return  <Icon {...restProps} />    
}