import styled from "styled-components"
import {Link as ReachRouterLink} from "react-router-dom"

export const Container = styled.div`
    background-color: rgba(238, 238, 238, 0.5);

    display: flex;
    flex-direction: row;
    margin: 0;
    height: 60px;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

  
   
`

export const TextContainer = styled.div` 
    display: flex;
    align-items: center;
    margin: 0;
    margin-left: 25px;
    vertical-align: middle;

    
    
    
`

export const Text = styled(ReachRouterLink)`
    text-decoration: none;
    font-size: 12px;
    color: #222222;
    //margin-right: 20px;
    margin: 0;
    opacity: 100%;

    &:hover {
        text-decoration: none;    
        color: inherit;  
    }
`

export const Line = styled.p`
    font-size: 12px;
    margin: 0;

`
export const LogoImg = styled.img`
    width: 100px;
    height: auto;
    object-fit: contain;

    &:hover{
        cursor:pointer;
    }

    margin-left: 25px;
  //  margin-top: 20px;
    margin-right: 25px;

`

export const PurpleBackground = styled.div`
    background-color:#230C31;
    padding: 3%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items:center;

    @media (max-width:1199px) {
        width: 1200px;
        overflow-x: auto;
    }
   
`
export const WhiteText = styled.p`
    text-decoration: none;
    font-size: 12px;
    color: #D6D6D6;
    margin: 0;
    opacity: 100%;
    margin-left: 25px;
    padding-top: 10px;
    
`
export const SocialImg = styled.img`
    width: 35px;
    height: 35px;
    object-fit: cover;
    &:hover{
        cursor:pointer;
    }
    margin-left: 5px;
    margin-right: 5px;

`