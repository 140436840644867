import React, { useState, useEffect } from "react";
import {  useDispatch, useSelector } from "react-redux";
import { Content, Grid, Text, Button, General } from "../../styled-components/dashboard";
import { useHistory } from "react-router-dom"
import Papa from "papaparse"
import { createKeyword } from '../../actions/keywordActions'
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import { CSVLink } from "react-csv";
import { listWebsiteDetailsById } from "../../actions/websiteActions";
import { languages, locationsLegend } from "../../constants/utils";


export function AddKeywordsUploadContainer({ accessToken, name, email }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('keywords')+16, 100))
  const [keywords, setKeywords] = useState([])
  const [fileName, setFileName] = useState('')


  const backHandler = (e) => {
    history.push(`/add-keywords/${websiteId}`) 
}
  const [toManyKeywords, setToManyKeywords] = useState(false)
  const [rightFormat, setRightFormat] = useState(true)

  const websiteDetailsById = useSelector(state => state.websiteDetailsById)
  const {loading: loadingDetailsByIdWebsite,
      error: errorDetailsByIdWebsite,
      success: successDetailsByIdWebsite, 
        website: detailsByIdWebsite
  } = websiteDetailsById

  const changeHandler = (event) => {
    setFileName('')
    if(event.target.files[0]){
      setToManyKeywords(false)
      setRightFormat(true)
      setFileName(event.target.files[0].name+'s')
      Papa.parse(event.target.files[0], {
        header: true,
        encoding: "ISO-8859-1",
        skipEmptyLines: true,
        complete: function (results) {
          if(results.data.length > 7001){
            setFileName('')
            setToManyKeywords(true)
            setKeywords([])
          } else {
            if(
              results.data[0] && 
              results.data[0].Keyword 
              

            ) {
              setKeywords(results.data)
              

             
            } else{
              setRightFormat(false)
              setFileName('')
              setKeywords([])
            }        
          }     
        },
      });
    } else{
      setKeywords([])
      setFileName('')
    }
  };

  const keywordCreate = useSelector(state => state.keywordCreate)
  const {loading: loadingCreateKeyword,
      error: errorCreateKeyword,
      success: successCreateKeyword,
      // keyword: createKeyword
  } = keywordCreate

  const addKeywordHandler = (e) => {
    e.preventDefault()
    if(keywords.length>0){
      dispatch(createKeyword(accessToken, keywords, email, websiteId))
    } else{

    }
  
  }

  const csvFilename = 'ExampleKeywords.csv';
  const csvFilenameTemplate = 'TemplateKeywords.csv';

  const csvData = [ 
    ['cheap mens running trainers','FALSE','mens','shoes','trainers','transactional','Searches:33100'],
    ['puma trainers for men','TRUE','mens','shoes','trainers','transactional','Searches:33100'],
    ['cheap womens running trainers','FALSE','womens','shoes','trainers','transactional','Searches:33100'],
    ['puma trainers for women','TRUE','womens','shoes','trainers','transactional','Searches:33100'],
    ['boys tshirts','FALSE','boys','tshirts','other','commercial','Searches:33100']
    ];

  const csvHeader = [
    "Keyword",
    "Brand",
    "Group 1",
    "Group 2",
    "Group 3",
    "Group 4",
    "Searches"
];

  const csvReport = {
    data: csvData,
    headers: csvHeader,
    filename: csvFilename
  };

  const csvReportTemplate = {
    data: csvData,
    headers: csvHeader,
    filename: csvFilenameTemplate
  };

  useEffect(() => {
    if(accessToken && email && websiteId) {
      if(successDetailsByIdWebsite){
        const index = languages.findIndex(x => x.language_name === detailsByIdWebsite.language)
        if(successCreateKeyword)
        {
          history.push(`/add-keywords-success/${websiteId}`)
        }
        if(keywords.length>0){
          keywords.map((keyword,i) =>{
           keyword.language = languages[index].language_code
          //  keyword.language = languages[detailsByIdWebsite.language]
           keyword.country = locationsLegend[detailsByIdWebsite.country].B
           keyword.location = locationsLegend[detailsByIdWebsite.country].C
           keyword.vertical = detailsByIdWebsite.industry
           keyword.google_domain = locationsLegend[detailsByIdWebsite.country].D
           keyword.device = detailsByIdWebsite.device
           keyword.type = detailsByIdWebsite.business_model
           // keyword.seo_group = results.data[i].gro + 
           })
          } 
      }
      else{
        dispatch(listWebsiteDetailsById(accessToken, websiteId, email))
        
      }
      }
}, [
    successCreateKeyword,
    history,
    websiteId,
    toManyKeywords,
    rightFormat,
    successDetailsByIdWebsite,
    accessToken,
    email,
    dispatch,
    keywords
  ])

  return(
      <Content>
        {loadingCreateKeyword || loadingDetailsByIdWebsite ||
        !successDetailsByIdWebsite
        ? <LoaderImage /> :
        errorCreateKeyword || errorDetailsByIdWebsite ?
        <ErrorMessage /> :
        detailsByIdWebsite.detail ? 
        <ErrorMessage message={detailsByIdWebsite.detail} /> :  
        <>
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold42Pink>
                Upload <span> </span>
              </Text.Bold42Pink>
              <Text.Bold40Black>
                your keywords
              </Text.Bold40Black>
              <Text.Normal14Black
              style={{marginTop: '26px'}}>
              Now it is time upload your keyword portfolio.  
              </Text.Normal14Black>
              <Text.Normal14Black
                style={{
                  marginTop: '15px'
                }}
              >
                We have created an easy-to-use template to get your keywords in Luminr format.
              </Text.Normal14Black>

              <Text.Normal14Black
              style={{
                  marginTop: '15px',
                  color: '#C51852', 
                  fontWeight: 'bold'
                }}
              >
              Information required:
              </Text.Normal14Black>
              <Text.Normal14Black>
              A list of your keywords (up to 250)
              </Text.Normal14Black>
              <Text.Normal14Black>
              Their monthly search volume
              </Text.Normal14Black>
              <Text.Normal14Black>
              If they are Branded or Generic
              </Text.Normal14Black>
              <Text.Normal14Black
                style={{
                    marginTop: '15px',
                    color: '#C51852', 
                    fontWeight: 'bold'
                  }}
              >
              For best results we suggest:
              </Text.Normal14Black>
              <Text.Normal14Black>
              Up to four Keyword Groups
              </Text.Normal14Black>
              <Text.Normal14Black
              style={{
                  marginTop: '15px'
                }}
              >
                Keyword grouping is the process of organising related keywords into groups or themes depending on the services, products or topics offered by your website. 
              </Text.Normal14Black>
              <Text.Normal14Black
              style={{
                  marginTop: '15px'
                }}
              >
                For example a B2C company that sells Pet Food may have it’s main group be animals: Dog, Cat, Bird, etc. With a further level looking at food types:types Wet Food, Dry Food, Vegan Food.
              </Text.Normal14Black>
              {/* <CSVLink {...csvReport} style={{
                    textDecoration:'none'
                  }}> 
                <Button.Pink
                  style={{
                    height:'40px',
                    marginTop:'10px',
                    display:'inline-flex',
                    fontSize:'16px'
                  }}
                >
                  Download Example
                </Button.Pink>
              </CSVLink> */}
              <CSVLink {...csvReportTemplate} style={{
                    textDecoration:'none'
                  }}> 
                <Button.Pink
                  style={{
                    height:'40px',
                    marginTop:'10px',
                    display:'inline-flex',
                    fontSize:'16px'
                  }}
                >
                  Download Template
                </Button.Pink>
              </CSVLink>
                
            </Grid.Section>
            <Grid.Section>
            <div
              style={{
                borderRadius: '8px',
                border: '2px solid #C51852',
                width: '100%',
                height: '100%',
                minHeight: '500px',
                padding: '16px',
                position: 'relative',
                cursor:'pointer'
              }}
            >
              <General.InputCSV
                for='upload-csv'
                style={{
                  cursor:'pointer'
                }}
                
              >
                <div style={{
                  
                  margin: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor:'pointer'
                 
                }}>
                <Button.Pink
                  style={{
                    marginBottom: '11px'
                  }}
                >
                  Upload CSV
                  
                </Button.Pink>
                <Text.Normal14Black
                  style={{
                    margin: '2em',
                    textAlign: 'center'
                  }}
                >
                  click inside this panel and add your CSV keywords.
                </Text.Normal14Black>
                {toManyKeywords &&
                <Text.Normal14Black
                  style={{
                    margin: '0 2em 0 2em',
                    textAlign: 'center',
                    color: 'red'
                  }}
                >
                  Your CSV contains more than 7001 limit keywords. Please remove some of them and try again.
                </Text.Normal14Black>}
                {!rightFormat &&
                <Text.Normal14Black
                  style={{
                    margin: '0 2em 0 2em',
                    textAlign: 'center',
                    color: 'red'
                  }}
                >
                  Your CSV has wrong format. Please check and try again.
                </Text.Normal14Black>}
                 {fileName && 
                <Text.Normal14Black>
                  {fileName}
                </Text.Normal14Black>
              } 

                <img
                style={{

                    position: 'absolute',
                    top: '50px' ,
                    width: '80px',
                    height: '80px',
                }}
                alt="icon-upload" 
                 src={require("../../images/dashboard/icon-upload.svg").default} />
                </div>
              </General.InputCSV>
              <input
                id='upload-csv'
                type='file'
                accept='.csv'
                name="file"
                
                onChange={changeHandler}
                onClick={(e)=> { 
                    e.target.value = null
                }}
                style={{
                  opacity: '0'
                }}
              >

              </input>
            </div>
            
            </Grid.Section>
           
            {/* <Grid.Section>
            <div
              style={{
                borderRadius: '8px',
                border: '2px solid #C51852',
                width: '100%',
                height: '100%',
                minHeight: '500px',
                padding: '16px',
                position: 'relative',
                cursor:'pointer'
              }}
            >
            <FileUploader 
            handleChange={handleChange} 
            name="file" 
            types={fileTypes} 
            hoverTitle="drop here"
            maxSize={1}
            />
            </div>
            <div
              onDrop={(e) => {
                e.preventDefault();
                DragAndDrop.handleDrop(e, handleDrop);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                DragAndDrop.handleDragOver(e);
              }}
            >
              <p>Drop files here</p>
            </div>
            </Grid.Section> */}
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          
          <Content.ButtonsContainer>
            
            {/* <Button.Back
            onClick={backHandler}
            >
            Back
          </Button.Back> */}
          <Button.Save 
          onClick={addKeywordHandler}
          style={{
            cursor: keywords.length === 0 && 'not-allowed'
          }}
          >
              Submit
            </Button.Save>
          </Content.ButtonsContainer>
          
        </Content.SectionBottom>
        </>
        }
      </Content>
  )
}
