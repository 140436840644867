import React from "react";
import { Text, Content } from "../../../../styled-components/visualization";
import {
  HorizontalDashLineGrey,
} from "./seprSummariesUtils";

export const HorizontalPanelComponent = (props) => {
  return (
    <>
      <HorizontalDashLineGrey />
      <Content.FlexRow style={{ justifyContent: "space-between" }}>
        <Content.FlexColumn style={{ width: "100%" }}>
          <Text.Regular13Grey style={{ textTransform: "uppercase" }}>
            {props.row.serp_feature}
          </Text.Regular13Grey>
        </Content.FlexColumn>
      </Content.FlexRow>

      <Content.FlexRow
        style={{
          justifyContent: "space-between",
          textAlign: "center",
          padding: "5%",
        }}
      >
        <Content.FlexColumn>
          <Text.Regular13Grey>Avg. position</Text.Regular13Grey>
          <Text.Bold15Black>
            {props.row.avg_position.toFixed(2)}
          </Text.Bold15Black>
        </Content.FlexColumn>
        <Content.FlexColumn>
          <Text.Regular13Grey>Avg. number of slots</Text.Regular13Grey>
          <Text.Bold15Black>
            {props.row.avg_available_slots.toFixed(2)}
          </Text.Bold15Black>
        </Content.FlexColumn>
        <Content.FlexColumn>
          <Text.Regular13Grey>Keywords</Text.Regular13Grey>
          <Text.Bold15Black>{props.row.keyword_count}</Text.Bold15Black>
        </Content.FlexColumn>
      </Content.FlexRow>
    </>
  );
};
