import styled from "styled-components"
import {Link as ReachRouterLink} from "react-router-dom"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 10px 0px 16px #0000001A;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    letter-spacing: 0;   
    margin: 0;
    min-width: 200px
`

export const LogoImg = styled.img`
    width: auto;
    height: 20px;
    margin: 30px 70px;
    object-fit: cover;

    &:hover{
        cursor:pointer;
    }
`

export const Section = styled.div`
    padding: 20px 15% 50px 15%;
   // background: gray;
    //height: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`

export const SectionTop = styled.div`

    background: white;

`

export const SectionButtom = styled.div`

    //background: green;

`

export const Dashboard = styled(ReachRouterLink)`
    display: block;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
    padding-left: 10px;

    text-decoration: none;
    color: #222222;

    border-left: 4px solid rgba(34, 34, 34, .1);

    transition: 0.3s;
    transition-timing-function: ease-out;

    &:hover {
        text-decoration: none;
        color: #C51852;
        border-left: 4px solid rgba(197, 24, 82, 1);
        padding-left: 15px;
    }
`



export const Inbox = styled(Dashboard)`
    margin-top: 20px;
    border-left: 4px solid rgba(197, 24, 82, .1);
    color: #C51852;

    &:hover {
        border-left: 4px solid rgba(197, 24, 82, 1);
        color: #C51852;
    }

`

export const PricingPage = styled(Dashboard)`
    margin-top: 20px;
    border-left: 4px solid rgba(197, 24, 82, .1);
    color: #C51852;

    &:hover {
        border-left: 4px solid rgba(197, 24, 82, 1);
        color: #C51852;
    }

`


export const Companies = styled(Inbox)`
    border-left: 4px solid rgba(132, 30, 90, .1);
    color: #841E5A;

    &:hover {
        border-left: 4px solid rgba(132, 30, 90, 1);
        color: #841E5A;
    }
`

export const Websites = styled(Inbox)`
    border-left: 4px solid rgba(244, 134, 94, .1);
    color: #F4865E;

    &:hover {
        border-left: 4px solid rgba(244, 134, 94, 1);
        color: #F4865E;
    }
`

export const Users = styled(Inbox)`
    border-left: 4px solid rgba(36, 20, 49, .1);
    color: #241431;

    &:hover {
        border-left: 4px solid rgba(36, 20, 49, 1);
        color: #241431;
    }
`

export const Item = styled(Users)`

`

export const ItemSelected = styled(Inbox)`
    border-left: 4px solid rgba(197, 24, 82, 1);

    &:hover {
        color: #222222;
        border-left: 4px solid rgba(34, 34, 34, 1);
    }

`

export const DashboardSelected = styled(Dashboard)`
    border-left: 4px solid rgba(197, 24, 82, 1);
    color: #C51852;

`


export const Organisation = styled(Inbox)`
`

export const Email = styled.p`
    font-size: 14px;
    color: #841E5A;
    margin-bottom: 50px;
`
export const Name = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #841E5A;
    text-transform: capitalize;
    margin: 0;
`

export const ProfileImg = styled.img`
    width: 36px;
    height: 36px;
    object-fit: cover;

    &:hover{
        cursor:pointer;
    }
`









export const MainItem = styled.p`
    display: block;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
    padding-left: 10px;
    margin: 0;
    margin-top: 20px;
    text-decoration: none;
    color: #222222;

    border-left: 4px solid rgba(132,30,90, 1);

    transition: 0.3s;
    transition-timing-function: ease-out;

    // &:hover {
    //     text-decoration: none;
    //     color: #222222;
    //     border-left: 4px solid rgba(34, 34, 34, 1);
    // }
`

export const SecondaryItem = styled(ReachRouterLink)`
    display: block;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 5px;
    padding-left: 10px;
    margin: 0;
    text-decoration: none;

    border-left: 4px solid rgba(132,30,90, .5);

    transition: 0.3s;
    transition-timing-function: ease-out;
    color:#841E5A;

    &:hover {
        text-decoration: none;
        color: #841E5A;
      //  border-left: 4px solid rgba(132,30,90, 1);
        font-weight: bold;
        padding-left: 15px;
    }
`