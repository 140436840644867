import { 
    KEYWORD_PROFILE_DETAILS_REQUEST,
    KEYWORD_PROFILE_DETAILS_SUCCESS,
    KEYWORD_PROFILE_DETAILS_FAIL,
    KEYWORD_PROFILE_DETAILS_RESET,

    KEYWORD_PROFILE_DETAILS_BY_ID_REQUEST,
    KEYWORD_PROFILE_DETAILS_BY_ID_SUCCESS,
    KEYWORD_PROFILE_DETAILS_BY_ID_FAIL,
    KEYWORD_PROFILE_DETAILS_BY_ID_RESET,

    KEYWORD_PROFILE_CREATE_REQUEST,
    KEYWORD_PROFILE_CREATE_SUCCESS,
    KEYWORD_PROFILE_CREATE_FAIL,
    KEYWORD_PROFILE_CREATE_RESET,

    KEYWORD_PROFILE_UPDATE_REQUEST,
    KEYWORD_PROFILE_UPDATE_SUCCESS,
    KEYWORD_PROFILE_UPDATE_FAIL,
    KEYWORD_PROFILE_UPDATE_RESET,

    KEYWORD_PROFILE_DELETE_REQUEST,
    KEYWORD_PROFILE_DELETE_SUCCESS,
    KEYWORD_PROFILE_DELETE_FAIL,
    KEYWORD_PROFILE_DELETE_RESET,
 } from '../constants/keywordProfileConstants'


 export const keywordProfileCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case KEYWORD_PROFILE_CREATE_REQUEST: 
            return {loading:true}

        case KEYWORD_PROFILE_CREATE_SUCCESS: 
            return {loading:false, success: true, keyword: action.payload}
        
        case KEYWORD_PROFILE_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_PROFILE_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const keywordProfileUpdateReducer = ( state = { keyword: {} }, action ) => {
    switch(action.type){
        case KEYWORD_PROFILE_UPDATE_REQUEST: 
            return {loading:true}

        case KEYWORD_PROFILE_UPDATE_SUCCESS: 
            return {loading:false, success: true, keyword: action.payload}
        
        case KEYWORD_PROFILE_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_PROFILE_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}


export const keywordProfileDetailsReducer = ( state = {keyword :[]}, action ) => {
    switch(action.type){
        case KEYWORD_PROFILE_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_PROFILE_DETAILS_SUCCESS: 
            return {
                loading:false, success:true,  
                keyword: action.payload.keyword, 
                page:action.payload.page, 
                pages:action.payload.pages,
                numberOfKeywords: action.payload.numberOfKeywords
            }
        
        case KEYWORD_PROFILE_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_PROFILE_DETAILS_RESET: 
            return {success:false, keyword: []}

        default: 
            return state
    }
}

export const keywordProfileDetailsByIdReducer = ( state = {keyword :{}}, action ) => {
    switch(action.type){
        case KEYWORD_PROFILE_DETAILS_BY_ID_REQUEST: 
            return {loading:true}

        case KEYWORD_PROFILE_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  keyword: action.payload}
        
        case KEYWORD_PROFILE_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_PROFILE_DETAILS_BY_ID_RESET: 
            return {loading:false, success:false, keyword: []}

        default: 
            return state
    }
}


export const keywordProfileDeleteReducer = ( state = {keyword :{}}, action ) => {
    switch(action.type){
        case KEYWORD_PROFILE_DELETE_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_PROFILE_DELETE_SUCCESS: 
            return {loading:false, success:true,  keyword: action.payload}
        
        case KEYWORD_PROFILE_DELETE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_PROFILE_DELETE_RESET: 
            return {loading:false, success:false, keyword: {}}

        default: 
            return state
    }
}