import styled from "styled-components"

import * as pallete from '../../../../constants/theme/visualization/colours'


export const Container = styled.div`
    color: #222222;
    letter-spacing: 0;
    font-family: tenon;   
    margin: 0;
`

export const Bold50Black = styled.p`
    font-size: 50px;
    color: ${pallete.SERPR_BLACK};
    font-weight: bold;
    line-height: 67px;
    margin: 0;
`
export const Bold30Black = styled.p`
    font-size: 30px;
    color: ${pallete.SERPR_BLACK};
    font-weight: bold;
    line-height: 54px;
    margin: 0;
`
export const Bold15Black = styled.p`
    font-size: 15px;
    color: ${pallete.SERPR_BLACK};
    font-weight: bold;
    line-height: 28px;
    margin: 0;
`

export const Bold15White = styled.p`
    font-size: 15px;
    color: white;
    font-weight: bold;
    line-height: 28px;
    margin: 0;
`

export const Bold16Purple = styled.p`
    font-size: 16px;
    color: ${pallete.TEXT_PURPLE};;
    line-height: 30px;
    font-weight: bold;
    margin: 0;

`
export const Medium40Purple = styled.p`
    font-size: 40px;
    color: ${pallete.TEXT_PURPLE};
    line-height: 44px;
    margin: 0;
`

export const Medium66White = styled.p`
    font-size: 66px;
    color: white;
    line-height: 66px;
    margin: 0;
`

export const Medium30Black = styled.p`
    font-size: 30px;
    color: ${pallete.SERPR_BLACK};
    line-height: 40px;
    margin: 0;
`

export const Medium30Red = styled.p`
    font-size: 30px;
    color: #FF4646;
    line-height: 40px;
    margin: 0;
`

export const Medium30Green = styled.p`
    font-size: 30px;
    color: #3DA804;
    line-height: 40px;
    margin: 0;
`
export const Medium22Black = styled.p`
    font-size: 22px;
    color: ${pallete.SERPR_BLACK};
    line-height: 30px;
    margin: 0;
`
export const Regular18Purple = styled.p`
    font-size: 18px;
    color: ${pallete.TEXT_PURPLE};
    line-height: 24px;
    margin: 0;
    width: fit-content;
`
export const Regular16Black = styled.p`
    font-size: 16px;
    color: ${pallete.SERPR_BLACK};
    line-height: 30px;
    margin: 0;
`
export const Regular15Black = styled.p`
    font-size: 15px;
    color: ${pallete.SERPR_BLACK};
    line-height: 28px;
    margin: 0;
    margin-top: 2em;
    white-space: pre-wrap;
    overflow-wrap: break-word
`
export const Regular15White = styled.p`
    font-size: 15px;
    color: white;
    line-height: 30px;
    margin: 0;
    margin-top: 2em;
    white-space: pre-wrap;

`

export const Regular16White = styled.p`
    font-size: 16px;
    color: white;
    line-height: 28px;
    margin: 0;

`

export const Regular16Purple = styled.p`
    font-size: 16px;
    color: ${pallete.TEXT_PURPLE};;
    line-height: 30px;
    margin: 0;

`

export const Regular12White = styled.p`
    font-size: 15px;
    color: white;
    line-height: 28px;
    margin: 0;

`

export const Regular14Black = styled.p`
    font-size: 14px;
    color: ${pallete.SERPR_BLACK};
    line-height: 19px;
    margin: 0;
`

export const Regular14WhitePurpleBackground = styled.p`
    font-size: 14px;
    color: white;
    line-height: 19px;
    margin: 0;
    background: #841F5B;
    text-align: center;
    padding: 3px 0 ;
`

export const Regular14WhitePurpleMediumBackground = styled.p`
    font-size: 14px;
    color: white;
    line-height: 19px;
    margin: 0;
    background: #9c4a7a;
    text-align: center;
    padding: 3px 0 ;
`

export const Regular14WhitePurpleLightBackground = styled.p`
    font-size: 14px;
    color: white;
    line-height: 19px;
    margin: 0;
    background: #caa1b9;
    text-align: center;
    padding: 3px 0 ;
`


export const Regular13Grey = styled.p`
    font-size: 13px;
    color: ${pallete.TEXT_GREY};
    line-height: 16px;
    margin: 0;
`
export const Regular18PurpleBorder = styled.p`
    font-size: 18px;
    color: ${pallete.TEXT_PURPLE};
    line-height: 24px;
    border-bottom: 1px solid ${pallete.TEXT_PURPLE};
    width: fit-content;
    margin: 0;
`
export const Medium12BlackOpacity20 = styled.p`
    font-size: 12px;
    color: ${pallete.SERPR_BLACK};
    line-height: 51px;
    opacity: 20%;
    margin: 0;
`
export const Medium31BlackOpacity20 = styled.p`
    font-size: 31px;
    color: ${pallete.SERPR_BLACK};
    line-height: 51px;
    opacity: 20%;
    margin: 0;
`
export const Regular18Grey = styled.p`
    font-size: 18px;
    color: ${pallete.TEXT_GREY};
    line-height: 24px;
    margin: 0;
`
export const Medium40Black = styled.p`
    font-size: 30px;
    color: ${pallete.SERPR_BLACK};
    line-height: 53px;
    margin: 0;
`
export const Light20DarkPurple = styled.p`
    font-size: 20px;
    color: ${pallete.TEXT_DARK_PURPLE};
    line-height: 28px;
    margin: 0;
`

export const Light20White = styled.p`
    font-size: 20px;
    color: white;
    line-height: 28px;
    margin: 0;
`

export const Light20Black= styled.p`
    font-size: 20px;
    color: #140E26;
    line-height: 28px;
    margin: 0;
`

export const Light20BlackLink = styled.a`
    font-size: 20px;
    color: #140E26;
    line-height: 28px;
    margin: 0;
    text-decoration: none;

    &:hover{
        color: ${pallete.TEXT_PURPLE};
        
    }
`
export const Light14BlackLink = styled.a`
    font-size: 14px;
    color: #140E26;
    line-height: 28px;
    margin: 0;
    text-decoration: none;

    &:hover{
        color: ${pallete.TEXT_PURPLE};
        
    }
`

export const Light16Black= styled.p`
    font-size: 16px;
    color: #140E26;
    line-height: 24px;
    margin: 0;
`

export const Regular18DarkBlackBorder = styled.p`
    font-size: 18px;
    color: ${pallete.TEXT_DARK_BLACK};
    line-height: 24px;
    border-bottom: 1px solid ${pallete.TEXT_DARK_BLACK};
    width: fit-content;
    margin: 0;
`

export const GreenRegular14 = styled.p`
    font-size: 14px;
    color: #FFFFFF;
    line-height: 19px;
    margin: 0;
    background: ${pallete.GREEN};
    width: fit-content;
    margin-right: 4px;
    padding: 0 2px;
    
`

export const RedRegular14 = styled.p`
    font-size: 14px;
    color: #FFFFFF;
    line-height: 19px;
    margin: 0;
    background: ${pallete.RED};
    width: fit-content;
    margin-right: 4px;
    padding: 0 2px;
`
export const InputRegular16Black = styled.input`
    font-size: 16px;
    color: ${pallete.SERPR_BLACK};
    line-height: 30px;
    margin: 0;
    border: none;
    width:100%;

    &:focus{
        border: none;
        outline: none
    }
`