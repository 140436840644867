import React, {  useState,useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Content, Text } from "../../styled-components/visualization";
import  LoaderImage  from "../../components/LoaderImage";
import { OverallCompositionContainer } from "./benchmarksComponents/OverallComposition";
import { YourKeywordsContainer } from "./benchmarksComponents/YourKeywords";
import { InfoLineContainer } from "./benchmarksComponents/InfoLine";
import { AssistantContainer } from "./utilComponents/Assistant";
import { IndustryAverageContainer } from "./benchmarksComponents/IndustryAverage";
import { totalSearchCtr, getIndustryBenchmarksPower,
  getSerpRealEstateScore, getIndustryBenchmarksStats, getSerpCompositionForBenchmarks,getSerprScore100, getSerprScore
} from "../../actions/visualization/dataScienceAPIsActions";
import ErrorMessage from "../../components/ErrorMessage";
import { IndustryNetPower100 } from "./benchmarksComponents/IndustryNetPower100";
import { CompanyContext } from "../../contexts/CompanyContext";

export function BenchmarksContainer({ accessToken, name, email, websiteId }) {
  const dispatch = useDispatch()
  const [companyContextReady, setCompanyContextReady] = useState(false);
  var paramsTotalSearchCtr, 
  paramsSerpRealEstateScore, 
  paramsSerpCompositionForBenchmarks, 
  paramsIndustryBenchmarksStats,
  paramsIndustryBenchmarksPower,
  paramsSerprScore100,
  paramsSerprScore;

  const {
    date,companyDomain,
    companyId4Api, companyIndustry
  } = useContext(CompanyContext);

  useEffect( ()=>{
    if(date && companyDomain && companyId4Api && companyIndustry ){
      setCompanyContextReady(true)
    }
  },[date,companyDomain, companyId4Api, companyIndustry])

   // 17. Tracking Summary
   const trackingSummaryVar = useSelector((state) => state.trackingSummary)
   const {
       loading: loadingTrackingSummary,
       error: errorTrackingSummary,
       success: successTrackingSummary,
       trackingSummaryResults,
   } = trackingSummaryVar

   const websiteTrackingStatus = useSelector((state) => state.websiteTrackingStatus)
  const {
      loading: loadingWebsiteTrackingStatus,
      error: errorWebsiteTrackingStatus,
      success: successWebsiteTrackingStatus,
      websiteTrackingStatusResults,
  } = websiteTrackingStatus


   // 11. Total Search CTR
   const searchCtr = useSelector((state) => state.searchCtr)
   const {
       loading: loadingTotalSearchCtr,
       error: errorTotalSearchCtr,
       success: successTotalSearchCtr,
       totalSearchCtrResults,
   } = searchCtr

     // 6. INDUSTRY BENCHMARKS POWER 100
     const industryBenchmarksPower = useSelector((state) => state.industryBenchmarksPower)
     const {
         loading: loadingIndustryBenchmarksPower,
         error: errorIndustryBenchmarksPower,
         success: successIndustryBenchmarksPower,
         industryBenchmarksPowerResults,
     } = industryBenchmarksPower

     // 8. Overall SERP Real Estate Score
    const serpRealEstateScore = useSelector((state) => state.serpRealEstateScore)
    const {
        loading: loadingSerpRealEstateScore,
        error: errorSerpRealEstateScore,
        success: successSerpRealEstateScore,
        serpRealEstateScoreResults,
    } = serpRealEstateScore

    // 12. INDUSTRY BENCHMARKS STATS
    const industryBenchmarksStats = useSelector((state) => state.industryBenchmarksStats)
    const {
        loading: loadingIndustryBenchmarksStats,
        error: errorIndustryBenchmarksStats,
        success: successIndustryBenchmarksStats,
        industryBenchmarksStatsResults,
    } = industryBenchmarksStats

     // 7. Overall SERP Composition
     const serpCompositionForBenchmarks = useSelector((state) => state.serpCompositionForBenchmarks)
     const {
         loading: loadingSerpCompositionForBenchmarks,
         error: errorSerpCompositionForBenchmarks,
         success: successSerpCompositionForBenchmarks,
         serpCompositionForBenchmarksResults,
     } = serpCompositionForBenchmarks
 
    
    // SERPR SCORE 100
    const serprScore100 = useSelector((state) => state.serprScore100)
    const {
        loading: loadingSerprScore100,
        error: errorSerprScore100,
        success: successSerprScore100,
        serprScore100Results,
    } = serprScore100
    
    // SERPR SCORE INDIVIDUAL
    const serprScore = useSelector((state) => state.serprScore)
    const {
        loading: loadingSerprScore,
        error: errorSerprScore,
        success: successSerprScore,
        serprScoreResults,
    } = serprScore

   useEffect(() => { 
    window.scrollTo(0, 0);
    if (accessToken && email){

      if(companyContextReady || companyDomain==='1'){
        if(companyId4Api !== '1'){

         paramsTotalSearchCtr = {
          "company_id": companyId4Api,
          "domain": companyDomain,
          "segment_by_country": false,
          "start_date": date[0],
          "end_date": date[1],
          "limit": 20,
          "mode": "include",
          "dimension": "device",
          "elements": [
              "mobile",
              "desktop"
          ]
          }
           paramsSerpRealEstateScore = {
            "company_id": companyId4Api,
            "industry": companyIndustry,
            "start_date": date[0],
            "end_date": date[1],
           
           }
            paramsSerpCompositionForBenchmarks = {
            "industry": companyIndustry,
            "start_date": date[0],
            "end_date": date[1],
            "mode": "include",
            "dimension": "device",
            "elements": [
                "mobile",
                "desktop"
            ]
            }
        
            paramsIndustryBenchmarksStats = {
              "company_id": companyId4Api,
              "industry": companyIndustry,
              // "segment_by_country": false,
              // "appearance_threshold": 0.001,
              // "limit": 100,
              // "mode": "include",
              // "dimension": "device",
              // "elements": [
              //     "mobile",
              //     "desktop"
              // ]
            }

            paramsIndustryBenchmarksPower = {
              "themes_regex": "travel|hospitality|tourism|holiday",
              "appearance_threshold": 0.001,
              "limit": 100
          }

            paramsSerprScore100 = {
              "company_id": companyId4Api,
              "industry":companyIndustry
            }
            paramsSerprScore = {
              "company_id": companyId4Api,
              "industry":companyIndustry
            }
      
      if(successSerpCompositionForBenchmarks){
      }
      else{
        if(!loadingSerpCompositionForBenchmarks && paramsSerpCompositionForBenchmarks){
          dispatch(getSerpCompositionForBenchmarks(accessToken, paramsSerpCompositionForBenchmarks, email))
        }
      }

      if(successTotalSearchCtr ){
      }
      else{
        if(!loadingTotalSearchCtr && paramsTotalSearchCtr){
          
          dispatch(totalSearchCtr(accessToken, paramsTotalSearchCtr, email))
        }
      }

      if(successIndustryBenchmarksPower){
      }
      else{
        if(!loadingIndustryBenchmarksPower && paramsIndustryBenchmarksPower){
          dispatch(getIndustryBenchmarksPower(accessToken, paramsIndustryBenchmarksPower, email))
        }
      }

      if(successSerpRealEstateScore){
      }
      else{
        if(!loadingSerpRealEstateScore && paramsSerpRealEstateScore){
          dispatch(getSerpRealEstateScore(accessToken, paramsSerpRealEstateScore, email))
        }
      }

      if(successIndustryBenchmarksStats){
      }
      else{
        if(!loadingIndustryBenchmarksStats && paramsIndustryBenchmarksStats){
          dispatch(getIndustryBenchmarksStats(accessToken, paramsIndustryBenchmarksStats, email))
        }
      }
      if(successSerprScore100){
      }
      else{
        if(!loadingSerprScore100 && paramsSerprScore100){
          dispatch(getSerprScore100(accessToken, paramsSerprScore100, email))
        }
      }
      if(successSerprScore){
      }
      else{
        if(!loadingSerprScore && paramsSerprScore){
          dispatch(getSerprScore(accessToken, paramsSerprScore, email))
        }
      }
    }
    }}
      
   },
   [
    accessToken, 
    successTrackingSummary,
    successTotalSearchCtr,
    successIndustryBenchmarksPower,
    successSerpRealEstateScore,
    successIndustryBenchmarksStats,
    successSerpCompositionForBenchmarks,
    successSerprScore100,
    successSerprScore,
    loadingIndustryBenchmarksPower,
    loadingIndustryBenchmarksStats,
    loadingSerpCompositionForBenchmarks,
    loadingSerpRealEstateScore,
    loadingTotalSearchCtr,
    loadingTrackingSummary,
    loadingSerprScore100,
    loadingSerprScore,
    email,
    companyContextReady,
    companyId4Api
  ])


    return (
      <Content>
        {loadingTrackingSummary || !successTrackingSummary || loadingTotalSearchCtr || !successTotalSearchCtr ||
         loadingIndustryBenchmarksPower || !successIndustryBenchmarksPower ||
         loadingSerpRealEstateScore || !successSerpRealEstateScore ||
         loadingIndustryBenchmarksStats || !successIndustryBenchmarksStats ||
         loadingSerpCompositionForBenchmarks || !successSerpCompositionForBenchmarks ||
         loadingSerprScore || !successSerprScore || 
         loadingSerprScore100 || !successSerprScore100 
         ? 
        <LoaderImage  height={'85vh'} /> : errorTrackingSummary || errorIndustryBenchmarksPower || 
        errorIndustryBenchmarksStats || errorSerpRealEstateScore ||
        errorTotalSearchCtr || errorSerpCompositionForBenchmarks || errorSerprScore || errorSerprScore100
        
        ?
        <ErrorMessage /> :
        <>
  
        <InfoLineContainer 
          totalSearchCtrResults={totalSearchCtrResults} 
          serpRealEstateScoreResults={serpRealEstateScoreResults}
          industryBenchmarksStatsResults={industryBenchmarksStatsResults}
          serprScoreResults={serprScoreResults}
        /> 
     
        <Content.FlexRow
        style={{
                justifyContent: 'space-between',
                marginTop: '3%',
              }}
        >
          <Content.FlexColumn
              style={{
                width:'100%'
              }}
          >
           {/* Industry Average Total Search CTR */}
            <IndustryAverageContainer 
              companyIndustry = {companyIndustry}
              industryBenchmarksStatsResults={industryBenchmarksStatsResults}
              totalSearchCtrResults={totalSearchCtrResults}
              industryBenchmarksPowerResults={industryBenchmarksPowerResults}
            /> 
        </Content.FlexColumn>
       </Content.FlexRow>
      <Content.FlexColumn>
        {/* {{industry}} Page One Power Players */}
        <IndustryNetPower100 
          serprScore100Results={serprScore100Results}
          industry={companyIndustry}
        />
      </Content.FlexColumn>

       
       <AssistantContainer name={name}/>
     
       <Content.FlexRow 
          style={{
              justifyContent: 'space-between',
              marginTop: '3%',
            }}>
            {/* SERP Feature Landscape */}
            <OverallCompositionContainer 
          serpCompositionResults = {serpCompositionForBenchmarksResults}
          /> 
        
          
        </Content.FlexRow>
       
      
       </>
        }
        
      </Content>
    )
}
