import styled from "styled-components"
import {Link as ReachRouterLink} from "react-router-dom"


export const Container = styled.div`
    background: #d4d4d4;
    color: #222222;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    position: fixed;
    left: 0;
    right: 0;
    letter-spacing: 0;
    
`


export const LogoImg = styled.img`
    width: 82px;
    height: 20px;
    object-fit: cover;

    &:hover{
        cursor:pointer;
    }

    margin-left: 25px;
  //  margin-top: 20px;
    margin-right: 25px;

`

export const Path1 = styled.p`
    font-size: 14px;
    left: 121px;
    color: #989898;   
    margin: 0;
   
`

export const Path2 = styled.p`
    font-size: 14px;
    left: 121px;
    color: #222222;  
    margin: 0; 
`

export const PostHeader = styled.div`
    background: #C51852;
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    box-shadow: inset 0px 3px 6px #00000029;

    position: fixed;
    top: 60px;

`

export const PostHeaderText = styled.p`
    font-size: 32px;
    // font-family: tenon;
    font-weight: bold;
    color: #ffffff;
    margin: 0;
    margin-left: 194px;
`
export const LaunchButtonImg = styled.div`
    background-image: url(${require('../../../../images/icon_serpr_square.svg').default});
    background-size: cover;
    width: 22px;
    height: 22px;
    margin-left: 5px;
    margin-right: 11px;
`

export const LaunchButton = styled(ReachRouterLink)`
    display: block;
    display: flex;
    align-items: center;

    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    color: #222222;

    width: 150px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;

    position: fixed;
    right: 24px;
    
    margin: 0;

    transition: 0.3s;
    transition-timing-function: ease-out;
    
    

    &:hover {
        text-decoration: none; 
        color: #ffffff;
        background: #C51852 ;


    }

    &:hover ${LaunchButtonImg} {
        transform: rotate(90deg)
    }
`



export const Icon = styled.img`


    width: 20px;
    height: 20px;

    &:hover{
        cursor: pointer;
    }
    
`

export const IconContainer = styled.div`
    width: 100px;
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
`

export const EmailContainer = styled.button`
    padding: 20px 35px 21px 25px;
    background-color: inherit;
    position: fixed;
    right: 100px;
    top: 0px;
    font-size: 14px;
    border: none;
    img {
      width: 10px;
      height: 4px;
      margin-left: 24px;
    }
    
`