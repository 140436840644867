import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text , Button, General } from "../../styled-components/dashboard";
import {emailRegex} from '../../constants/regex/regex'
import LoaderImage from "../../components/LoaderImage";
import { FlexColumn, FlexRow } from "../../styled-components/visualization/content/styles/content";
import { createProfile, listProfileDetailsByOrganisationEmail } from '../../actions/profileActions'
import { useHistory } from "react-router-dom"
import { Field14Black } from "../../styled-components/dashboard/text/styles/text";
import ErrorMessage from "../../components/ErrorMessage";


export function UsersContainer({ accessToken, name, email }) {

  const dispatch = useDispatch()
  const history = useHistory()
  const [userEmail, setUserEmail] = useState('')

  const profileDetails = useSelector((state) => state.profileDetails);
  const { profile } = profileDetails;

  const [emailIsValid, setEmailIsValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const profileCreate = useSelector((state) => state.profileCreate)
    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
        profile: createdProfile,
    } = profileCreate

  const profileDetailsByOrganisationEmail = useSelector((state) => state.profileDetailsByOrganisationEmail);
  const { 
    loading: loadingDetailsByOrganisationEmailProfile,
    error: errorDetailsByOrganisationEmailProfile,
    success: successDetailsByOrganisationEmailProfile,
    profiles
  } = profileDetailsByOrganisationEmail;

  function validateEmail(email) {
    if (emailRegex.test(email)) {
      setEmailIsValid(true);
      setEmailErrorMessage('');
    } else {
      setEmailIsValid(false);
      setEmailErrorMessage('Invalid email format');
    }
  }

  useEffect(()=>{
    if(accessToken && email) 
        {
            if(profile.isCompanyAdmin || profile.isSuperuser){
            } else{
                history.push('/')
            }

            if(successDetailsByOrganisationEmailProfile){

            }else{
                if(!loadingDetailsByOrganisationEmailProfile){
                    dispatch(listProfileDetailsByOrganisationEmail(accessToken, email))
                }
            }
          
        }
    },[email,
        accessToken,  
        dispatch,
        successDetailsByOrganisationEmailProfile,
        loadingDetailsByOrganisationEmailProfile
    ])



  const tableUsers = () => {
    return(
      <FlexColumn>
                <FlexRow
                    style={{
                        justifyContent: 'space-between',
                        maxWidth: '1000px',
                        marginLeft: '-10px',
                        padding: '10px'
                    }}
                >
                    <FlexColumn
                        style={{
                            width: '30%'
                        }}
                    >
                        <Text.Bold16Black>
                           Name
                        </Text.Bold16Black>
                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '35%'
                        }}
                    >
                        <Text.Bold16Black>
                           Email
                        </Text.Bold16Black>
                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '20%'
                        }}
                    >
                        <Text.Bold16Black>
                            Job Title
                        </Text.Bold16Black>
                    </FlexColumn>
                    
                    <FlexColumn
                    style={{
                            width: '15%'
                        }}
                    >
                        <Text.Bold16Black>
                            Actions
                        </Text.Bold16Black>
                    </FlexColumn>
                </FlexRow>
                
                {profiles && profiles.map((profile) =>(
                   <General.TableRow
                   style={{
                        justifyContent: 'space-between',
                        maxWidth: '1000px',
                        }}
                   >
                   <Content.FlexColumn
                   style={{
                                width: '30%'
                            }}
                   >
                        <Text.Normal14Black>
                            {profile.name}
                        </Text.Normal14Black>
                   </Content.FlexColumn>
                   <Content.FlexColumn
                    style={{
                                    width: '35%'
                                }}
                    >
                        <Text.Normal14Black>
                            {profile.userEmail}
                        </Text.Normal14Black>
                   </Content.FlexColumn>
                   <Content.FlexColumn
                    style={{
                                    width: '20%'
                                }}
                    >
                        <Text.Normal14Black>
                            {profile.jobTitle}
                        </Text.Normal14Black>
                   </Content.FlexColumn>
                   <FlexColumn
                    style={{
                            width: '15%'
                        }}
                    >
                    </FlexColumn>
                   </General.TableRow>

                ))}
                
               
            </FlexColumn>
            
    )
  }

  const addUserHandler = () => {
    const data = {
        name: name,
        email: email,
        userEmail: userEmail,
        handler: 'byAdmin'
    }
    dispatch(createProfile(accessToken, data))
  }
 
  return(
      <Content>
        {loadingDetailsByOrganisationEmailProfile || !successDetailsByOrganisationEmailProfile ||
        loadingCreate   
        ?
          <LoaderImage /> :
       errorDetailsByOrganisationEmailProfile ?
       <ErrorMessage /> :
        <Content.SectionTop>
          <Grid.SectionFull>
            
            <FlexColumn>
            <FlexColumn>
                  <Text.Bold26Pink  >
                      Add a new user to your organisation
                  </Text.Bold26Pink>
                <Content.FlexRow
                    style={{
                        maxWidth: '500px',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                <Content.FlexColumn
                style={{
                    width: '60%',
                    height: 'fit-content'
                }}
                >
                <Field14Black 
                
                placeholder="Enter user email"
                type="text"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                  validateEmail(e.target.value);
                    }}
                ></Field14Black>
               
                </Content.FlexColumn>
                <Button.Save
                    onClick={()=>addUserHandler()}
                    disabled={!emailIsValid || userEmail===''}
                >Add User</Button.Save>
                </Content.FlexRow>
                <span style={{ color: 'red' }}>{emailErrorMessage}</span>
              </FlexColumn>
              {createdProfile && createdProfile.detail && 
              <Text.Bold26Orange
                style={{
                    fontWeight: 'normal',
                    color: 'red',
                    marginTop: '1em'
                }}
            >
                {createdProfile.detail}
              </Text.Bold26Orange>
              }
              {createdProfile && createdProfile.userEmail &&
                <Text.Bold26Orange
                style={{
                    fontWeight: 'normal',
                    color: 'green',
                    marginTop: '1em'
                }}
            >
                New account was created for {createdProfile.userEmail}
              </Text.Bold26Orange>
              }
              {tableUsers()}
              
            </FlexColumn>
          </Grid.SectionFull>
        </Content.SectionTop>
        }
      </Content>
  )
}
