import React from 'react'
import { TemplateContainer } from '../../containers/dashboard/template'

export default function AddKeywords({accessToken, name, email, isActive, isSuperuser}) {
    return (
    <TemplateContainer pageName={'Add Keywords'}  email={email} 
    name={name} 
    accessToken={accessToken}
    isActive={isActive}
    isSuperuser={isSuperuser}
    />  )
}

