import React, { useState } from "react";

import { Text, Content, Button } from "../../../styled-components/visualization";
import { insightsText } from "./InsightsText";

export function InsightsContainer({ title, 
        pages,
        p11='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',       
        p21,
        p31,
        p41,
        p51,
        p61

}) {

    const [currentPage, setCurrentPage] = useState(1)

    const increasePage = () => {
        if(currentPage<pages){
            setCurrentPage(currentPage+1)
        }
    }

    const decreasePage = () => {
        if(currentPage>1){
            setCurrentPage(currentPage-1)
        }
    }



    //add link to Insight Modal
    const createAnchorLink = () => {
        const content = p11;
    
        if( title == 'Featured Snippet Takeovers' && content.includes('check here')){
          const parts = content.split('check here');
          return (
            <p>
              {parts[0]}
              <a target="_blank" href="https://search.google.com/test/rich-results">check here</a>
              {parts[1]}
            </p>
          );
        }else{
          return (<p>{content}</p>)
        }
      };

return(
    <Content.AbsoluteDiv>
        <Content.FlexColumn
        style = {{
            alignItems: 'center'
        }}
        >
        <Content.Triangle src={require("../../../images/visualization/triangle_white.svg").default}/>
        <Content.Insights>
            <Content.FlexColumn>
            <Content.FlexRow
                style={{
                justifyContent: 'space-between'
                }}
            >
                <Content.FlexRow
                style={{
                    alignItems: 'center'
                }}
                >
                <Content.InsightsIcon 
                    style = {{
                    margin: '0'
                    }}
                    src={require("../../../images/visualization/Lightbulb_2.svg").default}/>
                <Text.Bold15Black
                    style={{
                    marginLeft: '1em'
                    }}
                >{title}</Text.Bold15Black>
                </Content.FlexRow>
                {pages > 1 && <Text.Medium22Black>{currentPage}/{pages}</Text.Medium22Black>}
                
            </Content.FlexRow>
            <Content.FlexColumn >
                {currentPage === 1 && 
                <div
                style = {{
                    visibility: 'visible',
                    opacity: '1',
                    transition: 'visibility 0s ease 2000ms, opacity 300ms'
                    }}
                >
                    <Text.Regular15Black>
                    {createAnchorLink()}
                    
                    </Text.Regular15Black>
                </div>}


                {currentPage === 2 &&
                 <div>
                 <Text.Regular15Black>
                    {p21}
                    </Text.Regular15Black>
                </div>}

                {currentPage === 3 &&
                 <div>
                 <Text.Regular15Black>
                    {p31}
                    </Text.Regular15Black>
                 
                </div>}


                {currentPage === 4 &&
                 <div>
                 <Text.Regular15Black>
                    {p41}
                    </Text.Regular15Black>
                </div>}

                {currentPage === 5 &&
                 <div>
                 <Text.Regular15Black>
                    {p51}
                    </Text.Regular15Black>
                </div>}

                {currentPage === 6 &&
                 <div>
                 <Text.Regular15Black>
                    {p61}
                    </Text.Regular15Black>
                </div>}
                 

                
            </Content.FlexColumn>
            <Content.FlexRow
                    style={{
                    justifyContent: 'space-between',
                    marginTop: '1em'
                    }}
            >
                {currentPage > 1 ?     
                <Button.Light
                    style={{
                    width:'48%'
                    }}
                    onClick={() => decreasePage()}
                >
                Previous
                </Button.Light> :
                <Button.Light
                    style={{
                    width:'48%',
                   // visibility: 'hidden',
                    opacity: '0'
                    }}
                    onClick={() => decreasePage()}
                >
                Previous
                </Button.Light>
                }

                {currentPage !== pages &&
                <Button.Primary
                style={{
                    width:'48%'
                    }}
                onClick={() => increasePage()}
                >
                Next
                </Button.Primary> }
                
            </Content.FlexRow>
            </Content.FlexColumn>
        </Content.Insights>
        </Content.FlexColumn>

    </Content.AbsoluteDiv>
    )
}
