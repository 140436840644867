import React, { useState, useEffect } from "react";

import { Text, Content } from "../../../styled-components/visualization";
import { FingerPrintRadialChartContainer } from "../charts/FingerPrintRadialChart";
import { TitleContainer } from "../utilComponents/Title";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";


export function FingerprintContainer({serpCompositionResults, serpCompositionIncludeResults}) {

  const [dataArray] = useState([])
 
    const [labelsArray] = useState([])
    const [firstRender, setFirstRender] = useState(0)
    const [colorsArray] = useState([])
    var colorOptions = ['#841F5B', '#F8855B', '#364DF8', '#C51852', '#854EFF', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0','#9467FA', '#78D9F0', '#FDDF34', '#FF5CE6', '#EF008C'];

    const [dataArrayInclude] = useState([])
 
    const [labelsArrayInclude] = useState([])
    const [firstRenderInclude, setFirstRenderInclude] = useState(0)
    const [colorsArrayInclude] = useState([])

    useEffect(() => { 
        if(firstRender === 0) {
          if(serpCompositionResults.length != 0){
            if(serpCompositionResults.detail == undefined){
              serpCompositionResults.sort(function(a, b){return b.percent_of_serps_with_feature - a.percent_of_serps_with_feature}).map((result, i) => {
                if(result.percent_of_serps_with_feature*100>2){
                  dataArray.push(parseFloat((result.percent_of_serps_with_feature*100).toFixed(1)))
                  labelsArray.push(result.serp_feature)
                }
              })
              setFirstRender(firstRender+1)
            }
          }
        }
        if(firstRenderInclude === 0) {
          if(serpCompositionIncludeResults.length != 0 && serpCompositionIncludeResults.detail == undefined){
            serpCompositionIncludeResults.sort(function(a, b){return b.percent_of_serps_with_feature - a.percent_of_serps_with_feature}).map((resultInclude, i) => {
              if(resultInclude.percent_of_serps_with_feature*100>0){
                dataArrayInclude.push(parseFloat((resultInclude.percent_of_serps_with_feature*100).toFixed(1)))
                labelsArrayInclude.push(resultInclude.serp_feature)
              }
            })
            setFirstRenderInclude(firstRenderInclude+1)
          }
        }

        let fullLabelsArray = labelsArray.concat(labelsArrayInclude)
        // exclude duplicates
        fullLabelsArray = fullLabelsArray.filter((item,index)=>{
          return (fullLabelsArray.indexOf(item) == index)
        })
        // created a dictionary
        var result = {};
        fullLabelsArray.forEach((key, i) => result[key] = colorOptions[i]);

        Object.entries(result).forEach(([key, value]) => {
          labelsArray.map((v, i) => (labelsArray[i]==key ? colorsArray[i]=value : ""))
          labelsArrayInclude.map((v, i) => (labelsArrayInclude[i]==key ? colorsArrayInclude[i]=value : ""))
       });
      },
    [])

  const infoContainer = () => {
    return(
      <Content.FlexColumn
        style={{
          height: '100%',
          justifyContent:'center',
          
        }}
      >
          {dataArray.slice(0, 3).map((v, i) => (
                <>
                  <Text.Medium30Black>
                    {dataArray[i]}%
                  </Text.Medium30Black>
                  <Text.Regular16Black>
                    % {labelsArray[i]}
                  </Text.Regular16Black>
                  {(i < 2) ? <Content.HorizontalLineGrey /> : ""}
                </>
            ))
          }
      </Content.FlexColumn>
    )
  }

  const infoContainerInclude = () => {
    return(
      <Content.FlexColumn
        style={{
          height: '100%',
          justifyContent:'center',
        }}
      >
          {dataArrayInclude.slice(0, 3).map((v, i) => (
                <>
                  <Text.Medium30Black>
                    {dataArrayInclude[i]}%
                  </Text.Medium30Black>
                  <Text.Regular16Black>
                    % {labelsArrayInclude[i]}
                  </Text.Regular16Black>
                  {(i < 2) ? <Content.HorizontalLineGrey /> : ""}
                </>
            ))
          }
      </Content.FlexColumn>
    )
  }

  const yourChart = () => {
    return(
      <Content.FlexRow
          style={{
            marginTop: '2%',
            justifyContent: 'space-between',
            width: '49%'
          }}
        >
          <Content.FlexColumn
            style={{
              width:'67%'
            }}
          >
            {console.log()}
          {(dataArrayInclude.length != 0  && labelsArrayInclude !== []) ?
           <FingerPrintRadialChartContainer 
           variant='You' 
           dataArray={dataArrayInclude} 
           labelsArray={labelsArrayInclude} 
           colorOptions={colorsArrayInclude}/> 
           :
           <ResultsMissing details = ''/>
          }
          </Content.FlexColumn>
          <Content.FlexColumn
            style={{
              width: '27%'
            }}
          >
            {infoContainerInclude()}
          </Content.FlexColumn>
          
        </Content.FlexRow>
    )
  }

  const everyoneChart = () => {
    return(
      <Content.FlexRow
          style={{
            marginTop: '2%',
            justifyContent: 'space-between',
            width: '49%'
          }}
        >
          <Content.FlexColumn
            style={{
              width:'67%'
            }}
          >
           <FingerPrintRadialChartContainer variant='Everyone Else' dataArray={dataArray} labelsArray={labelsArray} colorOptions={colorsArray}/> 
          </Content.FlexColumn>
          <Content.FlexColumn
            style={{
              width: '27%'
            }}
          >
            {infoContainer()}
          </Content.FlexColumn>
          
        </Content.FlexRow>
    )
  }

  return (
    <Content.FlexColumn
    style={{
            width: '100%',
            padding: '1%',
            background: 'white',
        }}
    >
        <TitleContainer 
            title='SERP Real Estate Fingerprint'  
            subtitle="Compare how your search results stack up against competitors."
            backdropTop = '20vw'    
            pages={insightsText.SERPRealEstateFingerprint[0]}
            p11={insightsText.SERPRealEstateFingerprint[1]}
            p21={insightsText.SERPRealEstateFingerprint[2]}
            p31={insightsText.SERPRealEstateFingerprint[3]}
            p41={insightsText.SERPRealEstateFingerprint[4]}
            assistantMessage={assistantText.SERPRealEstateFingerprint}
            />
        {serpCompositionResults.length != 0? 
            serpCompositionResults.detail !== undefined ? 
                <ResultsMissing details = {serpCompositionResults.detail}/>
                 : 
                 <Content.FlexRow>
                    {yourChart()}
                    {everyoneChart()}
                  </Content.FlexRow>
            :
            <ResultsMissing details = {serpCompositionResults.detail}/>
        }
        
    </Content.FlexColumn>
  )
}
