import axios from 'axios'
import {
    LOGGING_SYSTEM_REQUEST,
    LOGGING_SYSTEM_SUCCESS,
    LOGGING_SYSTEM_FAIL,

    LOGS_LIST_REQUEST,
    LOGS_LIST_SUCCESS,
    LOGS_LIST_FAIL,

    LOGS_LIST_BY_EMAIL_REQUEST,
    LOGS_LIST_BY_EMAIL_SUCCESS,
    LOGS_LIST_BY_EMAIL_FAIL,

    LIST_LAST_LOGS_REQUEST,
    LIST_LAST_LOGS_SUCCESS,
    LIST_LAST_LOGS_FAIL,

    LOGS_LIST_BY_FILTER_REQUEST,
    LOGS_LIST_BY_FILTER_SUCCESS,
    LOGS_LIST_BY_FILTER_FAIL,

    ACTION_TYPE_REQUEST,
    ACTION_TYPE_SUCCESS,
    ACTION_TYPE_FAIL,

    LOG_TYPE_REQUEST,
    LOG_TYPE_SUCCESS,
    LOG_TYPE_FAIL,
} from '../../constants/loggingsystem/loggingSystemConstants'


export const createLog = (accessToken, log) => async (dispatch) => {
    try {
        dispatch({
            type: LOGGING_SYSTEM_REQUEST,
        })

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }

        const { data } = await axios.post(`/api/logs/createLog`, log, config)

        dispatch({
            type: LOGGING_SYSTEM_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LOGGING_SYSTEM_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        })
    }
}

export const listLogs = (accessToken) => async (dispatch) => {
    try {
        dispatch({ type: LOGS_LIST_REQUEST })

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }

        const { data } = await axios.get(`/api/logs/`, config)

        dispatch({
            type: LOGS_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LOGS_LIST_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        })
    }
}

export const listLogsByEmail = (accessToken, email) => async (dispatch) => {
    try {
        dispatch({ type: LOGS_LIST_BY_EMAIL_REQUEST })

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }

        const { data } = await axios.get(`/api/logs/byEmail/${email}`, config)

        dispatch({
            type: LOGS_LIST_BY_EMAIL_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LOGS_LIST_BY_EMAIL_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        })
    }
}

export const listLastNLogs = (accessToken, email) => async (dispatch) => {
    try {
        dispatch({ type: LIST_LAST_LOGS_REQUEST })

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }

        const { data } = await axios.get(`/api/logs/lastLogs/${email}`, config)

        dispatch({
            type: LIST_LAST_LOGS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LIST_LAST_LOGS_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        })
    }
}

export const listLogsByFilter = (accessToken, email, params) => async (dispatch) => {
        try {
            dispatch({ type: LOGS_LIST_BY_FILTER_REQUEST })

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                params: params,
            }

            const { data } = await axios.get(
                `/api/logs/byFilter/${email}`, config

            )

            dispatch({
                type: LOGS_LIST_BY_FILTER_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: LOGS_LIST_BY_FILTER_FAIL,
                payload:
                    error.response && error.response.data.detail
                        ? error.response.data.detail
                        : error.message,
            })
        }
    }

export const listActionTypes = (accessToken) => async (dispatch) => {
    try {
        dispatch({ type: ACTION_TYPE_REQUEST })

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }

        const { data } = await axios.get(`/api/logs/actionType`, config)

        dispatch({
            type: ACTION_TYPE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ACTION_TYPE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        })
    }
}

export const listLogTypes = (accessToken) => async (dispatch) => {
    try {
        dispatch({ type: LOG_TYPE_REQUEST })

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }

        const { data } = await axios.get(`/api/logs/logType`, config)

        dispatch({
            type: LOG_TYPE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LOG_TYPE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        })
    }
}
