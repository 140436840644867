import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import ErrorMessage from "../../components/ErrorMessage";
import LoaderImage from "../../components/LoaderImage";

import {  Content, Welcome } from "../../styled-components/visualization";
import { DashboardFeaturesContainer } from "./welcomeComponents/DashboardFeatures";
import { FooterContainer } from "./welcomeComponents/Footer";

import { HeaderContainer } from "./welcomeComponents/Header";
import { IntroContainer } from "./welcomeComponents/Intro";
import { SummaryContainer } from "./welcomeComponents/Summary";
import { ThreeKeyInsightsContainer } from "./welcomeComponents/ThreeKeyInsights";


export function WelcomeContainer({ accessToken, name, email }) {

     // 17. Tracking Summary
     const trackingSummaryVar = useSelector((state) => state.trackingSummary)
     const {
         loading: loadingTrackingSummary,
         error: errorTrackingSummary,
         success: successTrackingSummary,
         trackingSummaryResults,
     } = trackingSummaryVar

     const websiteTrackingStatus = useSelector((state) => state.websiteTrackingStatus)
  const {
      loading: loadingWebsiteTrackingStatus,
      error: errorWebsiteTrackingStatus,
      success: successWebsiteTrackingStatus,
      websiteTrackingStatusResults,
  } = websiteTrackingStatus

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  
  return (

    <div
      style={{
        background: ' #F7F7F7'
      }}
      >
      {loadingTrackingSummary || !successTrackingSummary ||
      loadingWebsiteTrackingStatus || !successWebsiteTrackingStatus
      ? 
      <LoaderImage height={'85vh'}/> : errorTrackingSummary || errorWebsiteTrackingStatus ?
      <ErrorMessage /> :
      <>
      <Content.Overflow>
        {/* Header  */}
        <HeaderContainer name={name}/>
        <Welcome.WhiteDiv>
          {/* Intro Container  */}
            <IntroContainer />
          {/* 3 Key Insights */}
          <ThreeKeyInsightsContainer />
          {/* Summary - needs to be developed */}
            <SummaryContainer 
              trackingSummaryResults={trackingSummaryResults}
              websiteTrackingStatusResults={websiteTrackingStatusResults}
            /> 
          {/* DashboardFeatures */}
            <DashboardFeaturesContainer />
          {/* Footer */}
          <FooterContainer />
        </Welcome.WhiteDiv>
      </Content.Overflow>
      </>
      }
    </div>

  )
}
