import styled from "styled-components"
import {Link as ReachRouterLink} from "react-router-dom"
import * as pallete from '../../../../constants/theme/colours'

export const Container = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
    width: 100%;
    height: 100vh;
    // -moz-box-sizing: border-box;
    // box-sizing: border-box;
    border-bottom: 60px solid white;
    border-top: 120px solid white;
    letter-spacing: 0;
    
`

export const Body = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    width: 100%;
    height: 100%;
    background: #C51852;

`
export const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 247px;
`
export const Menu = styled.div`
    background: #f6f6f6;
    height: 100%;
    padding-top: 16px;
    padding-left: 19px;
    height: 65%;
`

export const MenuHelp = styled.div`
    background: #EEEEEE;
    height: 35%;
    padding-top: 24px;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
`

export const Content = styled.div`
    width: 100%;
    background: #ffffff;
    height: 100%;
    overflow:auto;
    
    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 2px 0px 0px ${pallete.SHADOW_GRAY};
        background: ${pallete.LIGHT_GRAY};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 2px 0px 0px ${pallete.SHADOW_GRAY};
        background: ${pallete.PINK};
    }
`

export const MenuOption = styled(ReachRouterLink)`
    display: block;
    text-decoration: none;
    color: #222222;
    display: flex;
    align-items: center;
  
    height: 35px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    
   
    padding-left: 3px;
    transition: 0.3s;
    transition-timing-function: ease-out;
    

    &:hover {
        text-decoration: none;
        color: #C51852;
        padding-left: 10px;
        border-left-style: solid;
    }
`

export const MenuOptionSelected = styled(ReachRouterLink)`
    display: block;
    display: flex;
    align-items: center;
    left: 19px;
    height: 35px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    
   
    padding-left: 3px;
    text-decoration: none;
    color: #C51852;
    padding-left: 10px;
    border-left-style: solid;

    &:hover {
        text-decoration: none;
        color: #C51852;
        padding-left: 10px;
        border-left-style: solid;
    }

`

export const HelpButtonImg = styled.div`
    
    background-image: url(${require('../../../../images/icon_serpr_square.svg').default});
    background-size: cover;
    width: 22px;
    height: 22px;
    margin-left: 5px;
    margin-right: 11px;
    transition: 0.3s;

`

export const HelpButton = styled(ReachRouterLink)`
    display: block;
    display: flex;
    align-items: center;

    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    color: #222222;

    width: 175px;
    height: 32px;
    background: inherit;
    border-radius: 4px;
    
    margin: 0;

    margin-bottom: 14px;

    transition: 0.3s;
    transition-timing-function: ease-out;
    
    

    &:hover {
        text-decoration: none; 
        background: #ffffff ;
        color: inherit;

    }

    &:hover ${HelpButtonImg} {
        transform: rotate(90deg)
    }
`

