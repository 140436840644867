import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import { GlobalStyles } from "./global-styles"

import { Provider } from 'react-redux'
import store from './store'

import './index.css';
import './bootstrap.min.css'
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react'
import FilterContextProvider from './contexts/FilterContext';
import CompanyContextProvider from './contexts/CompanyContext';

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID

const tagManagerArgs = {
  gtmId: 'GTM-KGCM6WC'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    audience="https://serpr/api"

  >
    <Provider
      store={store}
    >
      <GlobalStyles />
      <CompanyContextProvider>
        <FilterContextProvider>
          <App />
        </FilterContextProvider>
      </CompanyContextProvider>
    </Provider>
    
  </Auth0Provider>,
  document.getElementById('root')
);
