import  axios from 'axios'
import { 
    ORGANISATION_LIST_REQUEST,
    ORGANISATION_LIST_SUCCESS,
    ORGANISATION_LIST_FAIL,

    ORGANISATION_DETAILS_REQUEST,
    ORGANISATION_DETAILS_SUCCESS,
    ORGANISATION_DETAILS_FAIL,

    ORGANISATION_DETAILS_BY_ID_REQUEST,
    ORGANISATION_DETAILS_BY_ID_SUCCESS,
    ORGANISATION_DETAILS_BY_ID_FAIL,

    ORGANISATION_CREATE_REQUEST,
    ORGANISATION_CREATE_SUCCESS,
    ORGANISATION_CREATE_FAIL,

    ORGANISATION_UPDATE_REQUEST,
    ORGANISATION_UPDATE_SUCCESS,
    ORGANISATION_UPDATE_FAIL,
 } from '../constants/organisationConstants'


 export const listOrganisations = (accessToken) => async (dispatch) =>{
    try {
        dispatch({type:ORGANISATION_LIST_REQUEST})

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.get(
            `/api/organisation/`,
            config
            )

        dispatch({
            type: ORGANISATION_LIST_SUCCESS,
            payload: data
        })


    } catch(error){
        dispatch({
            type: ORGANISATION_LIST_FAIL,
            payload: error.response && error.response.data.message ?
            error.response.data.message 
            : error.message,
        })
    }
 }

 export const createOrganisation = (accessToken, organisation) => async (dispatch) => {
    try {
       
        dispatch({
            type: ORGANISATION_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.post(
            `/api/organisation/create/${organisation.email}`,
            organisation,
            config
        )

        dispatch({
            type: ORGANISATION_CREATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: ORGANISATION_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORGANISATION_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateOrganisation = (accessToken, organisation) => async (dispatch) => {
    try {
       
        dispatch({
            type: ORGANISATION_UPDATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/organisation/update/${organisation.id}`,
            organisation,
            config
        )

        dispatch({
            type: ORGANISATION_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: ORGANISATION_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ORGANISATION_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listOrganisationDetails = (accessToken, email) => async (dispatch) => {
    try{
        dispatch({ type: ORGANISATION_DETAILS_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/organisation/byEmail/${email}`,
            config
            )

        dispatch({ 
            type: ORGANISATION_DETAILS_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: ORGANISATION_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }


 export const listOrganisationDetailsById = (accessToken, id) => async (dispatch) => {
    try{
        dispatch({ type: ORGANISATION_DETAILS_BY_ID_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/organisation/${id}`,
            config
            )

        dispatch({ 
            type: ORGANISATION_DETAILS_BY_ID_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: ORGANISATION_DETAILS_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }