import React, { useState, useEffect } from "react";
import { Footer } from '../../styled-components/admin-console'
import { Content } from "../../styled-components/visualization";

function handleLinkedin(){
  window.open('https://www.linkedin.com/company/luminrai/', '_blank');
}

function handleTwitter(){
  window.open('https://twitter.com/luminr_ai/', '_blank');
}

function handleIg(){
  window.open('https://www.instagram.com/luminr_ai/', '_blank');
}

function handleToTheTop(){
  window.scrollTo(0, 0);
}

export function FooterContainer() {
  return (
    <footer>
        <Footer.PurpleBackground>
          <Content.FlexColumn>
            <Footer.LogoImg  
            onClick={handleToTheTop}
            src={require("../../images/dashboard/luminr-logo-white.svg").default}/>
            <Footer.WhiteText>© 2023 Luminr Limited. All rights reserved.</Footer.WhiteText>
          </Content.FlexColumn>
          <Content.FlexColumn >
              <Content.FlexRow style={{paddingRight:"25px"}}>
                <Footer.SocialImg  
                onClick={handleLinkedin}
                src={require("../../images/linkedin.svg").default}/>

                <Footer.SocialImg  
                onClick={handleIg}
                src={require("../../images/instagram.svg").default}/>

                <Footer.SocialImg  
                onClick={handleTwitter}
                src={require("../../images/twitter.svg").default}/>

              </Content.FlexRow>
          </Content.FlexColumn>
        </Footer.PurpleBackground>
    </footer>
  );
}

