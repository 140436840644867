import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button, General } from "../../styled-components/dashboard";
import { useHistory } from "react-router-dom"
import Select from "../../components/Select";
import {  devices, verticals, brands, google_domains, languages2, countries, types, locations2 } from '../../constants/utils'
import { languages, locationsLegend } from "../../constants/utils";
import { createKeyword } from '../../actions/keywordActions'
import LoaderImage from "../../components/LoaderImage";
import { WEBSITE_DETAILS_BY_EMAIL_RESET } from "../../constants/websiteConstants";
import { listWebsiteDetailsById } from "../../actions/websiteActions";

export function AddKeywordsCopyContainer({ accessToken, name, email }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('keywords')+14, 100))
  const backHandler = (e) => {
    history.push(`/add-keywords/${websiteId}`)
  }
  const [keywords, setKeywords] = useState('')
  const [device, setDevice] = useState(devices[0])
  const [brand, setBrand] = useState(brands[0])
  const [google_domain, setGoogle_domain] = useState(google_domains[0])
  const [language, setLanguage] = useState(languages2[0])
  const [location, setLocation] = useState(locations2[0])
  const [country, setCountry] = useState(countries[0])
  const [vertical, setVertical] = useState(verticals[0])
  const [type, setType] = useState(types[0])
  const [keywordActive, setKeywordActive] = useState(brands[0])
  const [toManyKeywords, setToManyKeywords] = useState(false)
  const [keywordToLong, setKeywordToLong] = useState(false)
  const [noOfKeywords, setNoOfKeywords] = useState(0)

  const keywordCreate = useSelector(state => state.keywordCreate)
     const {loading: loadingCreateKeyword, 
         //error: errorCreateKeyword, 
         success: successCreateKeyword, 
         // keyword: createKeyword
     } = keywordCreate

     const websiteDetailsById = useSelector(state => state.websiteDetailsById)
  const {loading: loadingDetailsByIdWebsite,
      error: errorDetailsByIdWebsite,
      success: successDetailsByIdWebsite, 
        website: detailsByIdWebsite
  } = websiteDetailsById

    const addKeywordHandler = (e) => {
      e.preventDefault()
      setNoOfKeywords(keywords.split("\n").length)
      // Check if the user added maximum 250 keywords
      if(keywords.split("\n").length < 250) {
        setToManyKeywords(false)
        // check if each keyword has maximum 50 characters
        keywords.split("\n").forEach((x)=>{
          if(x.length > 50){
            setKeywordToLong(true)
          }
        })
        
        if(keywordToLong === false){
          const keyword = {
            keywords,
            device,
            brand,
            google_domain,
            language,
            location,
            country,
            vertical, 
            type,
            active: keywordActive,
          }
          dispatch({type: WEBSITE_DETAILS_BY_EMAIL_RESET})
          dispatch(createKeyword(accessToken, keyword, email, websiteId))
        }
        
      } else{
        setToManyKeywords(true)
       
      }
      
  }

  let index = -1

  useEffect(() => {
    if(successCreateKeyword)
      history.push(`/add-keywords-success/${websiteId}`)
      
      
      if(successDetailsByIdWebsite && detailsByIdWebsite){
        const index = languages.findIndex(x => x.language_name === detailsByIdWebsite.language)
        if(index !== -1){
          setLanguage(languages[index].language_code)
        }
        console.log(detailsByIdWebsite.country)
        setCountry(locationsLegend[detailsByIdWebsite.country].B) 
        setLocation(locationsLegend[detailsByIdWebsite.country].C)
        setVertical(detailsByIdWebsite.industry)
        setGoogle_domain(locationsLegend[detailsByIdWebsite.country].D)
        setDevice(detailsByIdWebsite.device)
        setType(detailsByIdWebsite.business_model)
        if(detailsByIdWebsite.language === '' || detailsByIdWebsite.country === ''){
          history.push(`/add-keywords-profile/${websiteId}`)
        }
      } else{
        dispatch(listWebsiteDetailsById(accessToken, websiteId, email))
      }

  }, [
    successCreateKeyword,
    history,
    websiteId,
    successDetailsByIdWebsite,
    index
  ])
  
  return(
      <Content>
        {loadingCreateKeyword || loadingDetailsByIdWebsite ? <LoaderImage /> : 
        <>
        <Content.SectionTop >
          <Grid>
            <Grid.Section>
              <Text.Bold40Black>
                Add keywords to your website <span> </span>
              </Text.Bold40Black>
              <Text.Normal14Black
              style={{color: '#C51852', fontWeight: 'bold', marginTop: '15px'}}
              >
              Upload your keywords
              </Text.Normal14Black>

              <Text.Normal14Black>
                Now is the time to upload your keyword portfolio.
              </Text.Normal14Black>

              <Text.Normal14Black
              style={{color: '#C51852', fontWeight: 'bold', marginTop: '15px'}}
              >
              Information required:
              </Text.Normal14Black>
              <Text.Normal14Black>
              Simply copy and paste up to 250 keywords in the box to the right. 
              </Text.Normal14Black>
              <Text.Normal14Black>
              From there we will feed your portfolio into <span style={{color: '#C51852', fontWeight: 'bold'}}> Luminr</span>.
              </Text.Normal14Black>
              <Text.Normal14Black
              style={{color: '#C51852', fontWeight: 'bold', marginTop: '15px'}}
              >
              What happens next:
              </Text.Normal14Black>
              <Text.Normal14Black>
              As soon as your dashboard is ready you will receive an email from our team. It can take up to 72 hours for dashboards to begin populating.
              </Text.Normal14Black>  
              <Text.Normal14Black>
              Get ready to soar above the competition!
              </Text.Normal14Black>  
            </Grid.Section>
            {false && <Grid.Section>
            <Text.Bold26Pink>
                Keywords details
            </Text.Bold26Pink>
            <Select param={device} setParam={setDevice} list={devices} placeholder='Choose device' title='Device'/>
            <Select param={brand} setParam={setBrand} list={brands} placeholder='Choose either True or False' title='Brand'/>
            <Select param={google_domain} setParam={setGoogle_domain} list={google_domains} placeholder='Choose Google Domain' title='Google Domain'/>
            <Select param={language} setParam={setLanguage} list={languages2} placeholder='Choose language' title='Language'/>
            <Select param={location} setParam={setLocation} list={locations2} placeholder='Choose location' title='Location'/>
            <Select param={country} setParam={setCountry} list={countries} placeholder='Choose country' title='Country'/>
            <Select param={vertical} setParam={setVertical} list={verticals} placeholder='Choose vertical' title='Vertical'/>
            <Select param={type} setParam={setType} list={types} placeholder='Choose type' title='Type'/>
            <Select param={keywordActive} setParam={setKeywordActive} list={brands} placeholder='Choose either active or not' title='Active?'/>
            </Grid.Section> }
            <Grid.Section
            style={{
              width: '150%',
            }}
            >
            <General.TextArea
                style={{
                  height: '600px'
                }}
                placeholder=" keyword1
                keyword2
                keyword3
                ..."
                type="text"
                maxlength="5"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              >

              </General.TextArea>
              {toManyKeywords && <span style={{ color: 'red' }}>Please add maximum 250 keywords. Now you have {noOfKeywords} keywords.</span>}
              {keywordToLong && <span style={{ color: 'red' }}>A keyword must have maximum 50 characters.</span>}
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          
          <Content.ButtonsContainer
          style={{
              alignItems: 'center'
            }}
          >
         
            {/* <Button.Back
            onClick={backHandler}
            >
            Back
          </Button.Back> */}
          <Button.Save 
          onClick={addKeywordHandler}
          >
              Submit
            </Button.Save>
          </Content.ButtonsContainer>
          
        </Content.SectionBottom>
        </>}
        
      </Content>
  )
}
