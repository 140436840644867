import React from "react"
import { Container, PurpleDiv, SerprImage, WhiteDiv, Big3Container,
        FooterLeftImage, FooterRightImage, FooterRightImageForWebsiteComponent,
        FooterRightImageForAC
} from "./styles/welcome"

export default function Welcome({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Welcome.PurpleDiv = function WelcomePurpleDiv({children, ...restProps}){
    return <PurpleDiv {...restProps}>{children}</PurpleDiv>   
}

Welcome.WhiteDiv = function WelcomeWhiteDiv({children, ...restProps}){
    return <WhiteDiv {...restProps}>{children}</WhiteDiv>   
}

Welcome.SerprImage = function WelcomeSerprImage({children, ...restProps}){
    return <SerprImage {...restProps}>{children}</SerprImage>   
}

Welcome.Big3Container = function WelcomeBig3Container({children, ...restProps}){
    return <Big3Container {...restProps}>{children}</Big3Container>   
}

Welcome.FooterLeftImage = function WelcomeFooterLeftImage({children, ...restProps}){
    return <FooterLeftImage {...restProps}>{children}</FooterLeftImage>   
}

Welcome.FooterRightImage = function WelcomeFooterRightImage({children, ...restProps}){
    return <FooterRightImage {...restProps}>{children}</FooterRightImage>   
}

Welcome.FooterRightImageForWebsiteComponent = function WelcomeFooterRightImageForWebsiteComponent({children, ...restProps}){
    return <FooterRightImageForWebsiteComponent {...restProps}>{children}</FooterRightImageForWebsiteComponent>   
}

Welcome.FooterRightImageForAC = function WelcomeFooterRightImageForAC({children, ...restProps}){
    return <FooterRightImageForAC {...restProps}>{children}</FooterRightImageForAC>   
}

