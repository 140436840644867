export const keywordsText = "How many Keywords do you plan to test on your site monthly?";
export const locationsText = "How many Locations do you plan to test on your site monthly?";

export const pricingDataBasic = {
    title: "Basic",
    priceOverview: "Standard Version",
    product_id:"prod_Os945T5q4HFoKe"
  };

export const pricingDataAdvanced = {
    title: "Advanced",
    priceOverview: "Multiple Options",
    product_id:"prod_Os958XxFdPerOB"
};

export const pricingDataEnterprise = {
    title: "Enterprise",
    priceOverview: "Custom plan",
    product_id:""
};

export const commonDataBasic = {
    description: "This is the most basic package but it's also the cheapest. Great for ordinary use."
};
export const commonDataAdvanced = {
    description: "Best selling option. This is well suited for medium companies."
};

export const commonDataEnterprise = {
    description: "Get in touch with our specialists and make a suitable plan for your business."
};
export const generic = {
    priceDesc: " taxes not included",
    ctaText: "Pay now",
    ctaLinkPrefix : "#",
};