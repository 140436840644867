import styled from "styled-components"
import * as pallete from '../../../../constants/theme/colours'
import {Link as ReachRouterLink} from "react-router-dom"


export const Container = styled.div`
background: #C51852;
height: 60px;
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
margin: 0;
box-shadow: inset 0px 3px 6px ${pallete.SHADOW_GRAY};
    
`

export const Text = styled.p`
    font-size: 16px;
    // font-family: tenon;
    font-weight: bold;
    color: #ffffff;
    margin: 0;
    margin-left: 24px;
    margin-right: 14px;
    text-shadow: 0px 2px 1px ${pallete.SHADOW_GRAY};
`

export const Search = styled.div`

`

export const Button = styled(ReachRouterLink)`
    align-items: center;
    display: flex;
    text-decoration: none;
    font-family: tenon, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    color: ${pallete.WHITE};

    padding: 17px 12px;
    height: 32px;
    border-radius: 4px;
    border: 2px solid ${pallete.WHITE};

    position: fixed;
    right: 24px;

    margin: 0;

    transition: 0.3s;
    transition-timing-function: ease-out;

    &:hover {
        text-decoration: none;
        color: ${pallete.PINK};
        background: ${pallete.WHITE};
    }
`

// Dropdown
export const DropDownContainer = styled.div``

export const DropDownHeader = styled.div`
    width: 390px;
    font-family: tenon, sans-serif;
    font-weight: bold;
    font-size: 16px;
    box-shadow: inset 0px 5px 3px ${pallete.SHADOW_GRAY};
    border-radius: 4px;
    letter-spacing: 0px;
    height: unset;
    padding: 6px 20px;
    position: relative;
    text-align: left;
    background: ${pallete.WHITE};
    color: ${pallete.BLACK};  
    margin-right: 7px;
`

export const DropDownList = styled.ul`
    position: absolute;
    width: 390px;
    top: 185px;
    box-shadow: -2px 0px 5px ${pallete.SHADOW_GRAY};

    padding: 0;
    margin: 0;

    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    letter-spacing: 0px;
    text-align: left;

    height: 235px;
    overflow: auto;

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${pallete.LIGHT_GRAY};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 1px 0px 0px ${pallete.SHADOW_GRAY};
        background: ${pallete.PINK};
    }
    transition: 2s;
    transition-timing-function: ease-out;
`

export const ListItem = styled.li`
    list-style: none;

    padding: 10px 31px;
    background: ${pallete.WHITE};

    &:hover {
        background: ${pallete.PINK};

        color: ${pallete.WHITE};
    }
    

`

export const DropdownIcon = styled.img`
    position: absolute;
    right: 19px;
    top: 15px;
`

// Search
export const SearchContainer = styled.div`
    position: relative;
`

export const SearchControl = styled.input`
    width: 390px;
    font-family: tenon, sans-serif;
    font-weight: bold;
    font-size: 16px;
    box-shadow: inset 0px 5px 3px ${pallete.SHADOW_GRAY};
    border-radius: 4px;
    border: none;
    outline: none;
    letter-spacing: 0px;
    height: unset;
    padding: 6px 20px;
    text-align: left;
    background: ${pallete.WHITE};
    margin-left: 7px;
    ::placeholder {
        color: ${pallete.BLACK};
      }
`

export const SearchIcon = styled.img`
    position: absolute;
    right: 8px;
    top: 9px;
`