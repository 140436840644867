import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import "./AccountMenuStyle.css";

export const AccountMenuContainer = (props) => {
  const { logout } = useAuth0();

  return (
    <div class="action">
      {props.active ? (
        <div
          class="menu active"
          style={
            props.dashboardStyle
              ? { boxShadow: "0px 10px 16px #0000001a", top:'51px' }
              : {}
          }
        >
          <ul>
            <li>
              <a href="#/">Home</a>
            </li>
            <li>
              <a href="#/profile">Profile</a>
            </li>

            <li>
              <a href="#/settings">Settings</a>
            </li>

            <li>
              <a href="#" onClick={() => logout()}>
                Sign out
              </a>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
