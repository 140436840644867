import styled from "styled-components"

export const Container = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    letter-spacing: 0;
    padding-top: 100px;
    padding-left: 136px;
    
`

export const LargeText = styled.div`
    font-size: 52px;
    font-weight: bold;

`

export const SmallText = styled.div`
    font-size: 14px;
`

export const RedLine = styled.div`
    padding-bottom: 10px;
    border-bottom: 2px solid #C51852;
    width: 250px;
`
export const Img = styled.img`
    height: 481px;
    width: 426px;
    
`

export const Icon = styled.img`
`
//#C51852

export const RightContainer = styled.div`
    margin-left: 136px;
    width: 50%;
`
export const Label = styled.input`
    display: block;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 40%;
    margin-top: 20px;
    font-size: 14px;
    outline: none;
`

export const TextField = styled.textarea`
    
    display: block;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 40%;
    margin-top: 20px;
    height: 124px;
    margin-bottom: 20px;
`

export const Button = styled.button`
    display: block;
    padding: 14px 21px;
    border-radius: 4px;
    border: 2px solid #C51852;
    background: inherit;
    transition: 0.3s;
    transition-timing-function: ease-out;
    font-size: 18px;


    &:hover {
        color: #ffffff;
        background: #C51852;
    }

`