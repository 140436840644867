import React from 'react'
import { Menu } from '../../styled-components/dashboard/'
import { Button } from '../../styled-components/dashboard/'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import * as ROUTES from "../../constants/routes/routes"
import { useSelector, useDispatch } from "react-redux";
import { pricingEnabled } from '../../App';

export function MenuContainer({ children, email, name,  isSuperuser }) {

    let history = useHistory()
    const { logout } = useAuth0();

    const goHome = async (e) => {
        history.push(`/`)
    }

    const profileDetails = useSelector((state) => state.profileDetails);
    const {success, profile } = profileDetails;

    function handleFAQ(){
        window.open('https://luminr.ai/faq/', '_blank');
    }

    return ( 
     <Menu>
        <Menu.LogoImg  src={require("../../images/dashboard/luminr-logo-black.svg").default}
            
            onClick = {goHome}
        />
        <Menu.Section>
            <Menu.SectionTop>

                <Menu.DashboardSelected to={ROUTES.HOME}>
                    Home
                </Menu.DashboardSelected> 

                <Menu.MainItem>
                    Manage
                </Menu.MainItem>
                {(history.location.pathname === ROUTES.COMPANIES) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold'}}
                    to={ROUTES.COMPANIES}>
                     Companies
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.COMPANIES}> 
                        Companies
                    </Menu.SecondaryItem>
                }

                {(history.location.pathname === ROUTES.WEBSITES) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold'}}
                    to={ROUTES.WEBSITES}>
                     Websites
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.WEBSITES}> 
                        Websites
                    </Menu.SecondaryItem>
                }
                
                {   profile.isCompanyAdmin || profile.isSuperuser &&
                    <>

                    {(history.location.pathname === ROUTES.USERS) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold'}}
                    to={ROUTES.USERS}>
                     Users
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.USERS}> 
                        Users
                    </Menu.SecondaryItem>}
                    </>
                }

                <Menu.MainItem
                    style={{borderLeft: '4px solid rgba(244,134,94, 1)'}}
                >
                    Account
                </Menu.MainItem>

                {(history.location.pathname === ROUTES.PROFILE) ?
                    <Menu.SecondaryItem
                    style ={{ fontWeight: 'bold',
                    borderLeft: '4px solid rgba(244,134,94, .5)'}}
                    to={ROUTES.PROFILE}>
                     Profile
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.PROFILE}
                    style={{borderLeft: '4px solid rgba(244,134,94, .5)'}}
                    > 
                        Profile
                    </Menu.SecondaryItem>
                }

                {(history.location.pathname === ROUTES.INBOX) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold',
                    borderLeft: '4px solid rgba(244,134,94, .5)'}}
                    to={ROUTES.INBOX}>
                     Inbox
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.INBOX}
                    style={{borderLeft: '4px solid rgba(244,134,94, .5)'}}> 
                        Inbox
                    </Menu.SecondaryItem>
                }
                {pricingEnabled &&
                <>
                {(history.location.pathname === ROUTES.PRICING) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold',
                    borderLeft: '4px solid rgba(244,134,94, .5)'}}
                    to={ROUTES.PRICING}>
                     Pricing
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.PRICING}
                    style={{borderLeft: '4px solid rgba(244,134,94, .5)'}}> 
                        Pricing
                    </Menu.SecondaryItem>
                }
                </>}

               

                {(history.location.pathname === ROUTES.SETTINGS) ?
                    <Menu.SecondaryItem
                    style ={{ fontWeight: 'bold',
                    borderLeft: '4px solid rgba(244,134,94, .5)'}}
                    to={ROUTES.SETTINGS}>
                     Settings
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.SETTINGS}
                    style={{borderLeft: '4px solid rgba(244,134,94, .5)'}}> 
                        Settings
                    </Menu.SecondaryItem>
                }
                {/* Commented due to requirements to remove Organisation level at this point */}
                {/* <Menu.MainItem
                style={{borderLeft: '4px solid rgba(197, 24, 82, 1)'}}>
                    Organisation
                </Menu.MainItem>

                {(history.location.pathname === ROUTES.ORGANISATION) ?
                    <Menu.SecondaryItem
                    style ={{ fontWeight: 'bold',
                    borderLeft: '4px solid rgba(197, 24, 82, .5)'}}
                    to={ROUTES.ORGANISATION}>
                     Settings
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.ORGANISATION}
                    style={{borderLeft: '4px solid rgba(197, 24, 82, .5)'}}> 
                        Settings
                    </Menu.SecondaryItem>
                } */}

               {isSuperuser && 
               <>
               <Menu.MainItem>
                    Luminr ADMIN
                </Menu.MainItem>
                {(history.location.pathname === ROUTES.USERS_ADMIN) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold'}}
                    to={ROUTES.USERS_ADMIN}>
                     Users
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.USERS_ADMIN}> 
                        Users
                    </Menu.SecondaryItem>
                }
                {(history.location.pathname === ROUTES.USER_PREFERENCES) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold'}}
                    to={ROUTES.USER_PREFERENCES}>
                     Preferences
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.USER_PREFERENCES}> 
                        Preferences
                    </Menu.SecondaryItem>
                }
                {(history.location.pathname === ROUTES.LIST_USERS_AUTH0) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold'}}
                    to={ROUTES.LIST_USERS_AUTH0}>
                     Users Logs
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.LIST_USERS_AUTH0}> 
                    Users Logs
                    </Menu.SecondaryItem>
                }
                {(history.location.pathname === ROUTES.SERPR_DASHBOARDS) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold'}}
                    to={ROUTES.SERPR_DASHBOARDS}>
                     Dashboards
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.SERPR_DASHBOARDS}> 
                        Dashboards
                    </Menu.SecondaryItem>
                }
                {(history.location.pathname === ROUTES.KEYWORDS_ADMIN) ?
                    <Menu.SecondaryItem 
                    style ={{ fontWeight: 'bold'}}
                    to={ROUTES.KEYWORDS_ADMIN}>
                     Keywords
                    </Menu.SecondaryItem> :
                    <Menu.SecondaryItem to={ROUTES.KEYWORDS_ADMIN}> 
                        Keywords
                    </Menu.SecondaryItem>
                }
                
                </>
            }

            


            </Menu.SectionTop>
            <Menu.SectionButtom>
                {/* <Menu.Name>
                    {name}
                </Menu.Name>
                <Menu.Email>
                    {email}
                </Menu.Email> */}
                <Button.Menu onClick={handleFAQ}>
                    <Button.MenuBackground>
                        <Button.MenuImg src={require("../../images/dashboard/question-mark.svg").default} />
                    </Button.MenuBackground>           
                    Help & FAQ
                </Button.Menu>
                <Button.Menu onClick={()=> logout()}>
                    <Button.MenuBackground>
                        <Button.MenuImg src={require("../../images/dashboard/left-arrow-menu-button.svg").default} />
                    </Button.MenuBackground >           
                    Logout
                </Button.Menu>
                

            </Menu.SectionButtom>
        </Menu.Section>
     </Menu>
    )
}


