import React from "react";
import { Content } from "../../styled-components/visualization";
import { SerpSummariesContainer } from "./resultsComponents/serpSummaries/SerpSummaries";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  serpMockup,
} from "../../actions/visualization/dataScienceAPIsActions";
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";

export function ResultsContainer({ accessToken, name, email }) {

  const dispatch = useDispatch();

  // 19. SERP Mockup
  const serpMockupVar = useSelector((state) => state.serpMockup);
  const {
    loading: loadingSerpMockup,
    error: errorSerpMockup,
    success: successSerpMockup,
    serpMockupResults,
  } = serpMockupVar;

  const paramsSerpMockup = {
    company_id: 92,
    limit: 20,
    start_date: "2022-03-01",
    end_date: "2022-03-31",
    domain: "pwc.com",
    mode: "include",
    dimension: "device",
    elements: ["mobile", "desktop"],
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    if (accessToken && email) {

      if(successSerpMockup){
      }
      else{
        if(!loadingSerpMockup){
          dispatch(serpMockup(accessToken, paramsSerpMockup, email))
        }
      }
    }
  }, [accessToken, successSerpMockup, loadingSerpMockup, dispatch, email]);
  

  return (
    <Content>{
        loadingSerpMockup || !successSerpMockup ? 
        <LoaderImage /> :
        errorSerpMockup ? 
        <ErrorMessage /> :
        <>
          <Content.FlexColumn
          style={{
            marginTop: "3%",
          }}
        >
        {
          serpMockupResults[0] && serpMockupResults.detail===undefined ? 
          <SerpSummariesContainer
            serpMockupResults={serpMockupResults}
          /> :
          <ErrorMessage />
        }
          
        </Content.FlexColumn>
        </>
      }
      
    </Content>
  );
}
