import styled from "styled-components"


export const Container = styled.div`
    color: #222222
    letter-spacing: 0;   
    margin: 0;
    font-family: tenon;
`


export const Bold26Pink = styled.p`
    font-size: 26px;
    color: #C51852;
    font-weight: bold;
    margin-bottom: 1.2rem;
   // margin-left: 10px;
`

export const Bold26White = styled.p`
    font-size: 26px;
    color: #FFFFFF;
    font-weight: bold;
   // margin-bottom: 1.2rem;
   // margin-left: 10px;
   margin: 0;
`

export const Bold26Purple = styled.p`
    font-size: 26px;
    color: #841E5A;
    font-weight: bold;
    margin-bottom: 1.2rem;
   // margin-left: 10px;
`

export const Bold26Orange = styled.p`
    font-size: 26px;
    color: #F4865E;
    font-weight: bold;
    margin-bottom: 1.2rem;
   // margin-left: 10px;
`

export const Bold16Black = styled.p`
    font-size: 16px;
    color: #222222;
    font-weight: bold;
    margin-bottom: .5rem;
    margin-top: 1.3rem;
  //  margin-left: 10px;
    
`

export const Bold40Black = styled.p`
    font-size: 40px;
    color: #222222;
    font-weight: bold;
    margin-bottom: .5rem;
  //  margin-left: 10px;
  display: inline;
  line-height: 40px;

  
    
`

export const Bold42Pink = styled.p`
    font-size: 40px;
    color: #C51852;
    font-weight: bold;
    margin-bottom: .5rem;
  //  margin-left: 10px;
  display: inline;
  line-height: 40px;
    
`

export const Bold16BlackOpacity = styled.p`
    font-size: 16px;
    color: #222222;
    font-weight: bold;
    margin-bottom: .5rem;
   // margin-left: 10px;
    opacity: .5;
    
`

export const Field14Black = styled.input`
    text-decoration: none;
    display: block;
    font-size: 14px;
    color: #222222;
    background: #F6F6F6;
    border:0;
    outline:0;
    width: 100%;
    /* margin-bottom: 1.3rem; */
    
    
    padding: 8.5px 10px;

    &:focus {
        border-radius: 4px;
        border: 1px solid #D4D4D4;
    }
`

export const Field14BlackOpacity = styled.input`
    text-decoration: none;
    display: block;
    font-size: 14px;
    color: #222222;
    background: #F6F6F6;
    border:0;
    outline:0;
    width: 100%;
    margin-bottom: 1.3rem;
    
    
    padding: 8.5px 10px;
    opacity: .5;

    &:focus {
        border-radius: 4px;
        border: 1px solid #D4D4D4;
    }
`

export const Normal14Black = styled.p`
    font-size: 14px;
    color: #222222;
    margin: 0;
   // margin-left: 10px;
    
`
export const Normal26Black = styled.p`
    font-size: 26px;
    color: #222222;
    margin: 0;
   // margin-left: 10px;
    
`
export const Normal14Purple = styled.a`
    font-size: 14px;
    color: #841E5A;
    margin-bottom: 1.6rem;
   // margin-left: 10px;
    text-decoration: none;

    &:focus {
        text-decoration: none;
        color: #841E5A;

    }
    
`

export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`

export const Icon16 = styled.img`
    height: 16px;
    width: 16px;
    color: black;
`

export const InputRegular16Black = styled.input`
    font-size: 16px;
    color: black;
    background: inherit;
    line-height: 30px;
    margin: 0;
    border: none;
    width:100%;

    &:focus{
        border: none;
        outline: none
    }
`

export const Icon16Opacity = styled.img`
    height: 16px;
    width: 16px;
    color: black;
    opacity: .5;
`

export const IconSelect = styled.img`
    height: 100%;
    width: 36px;

`

export const SelectText14Black= styled.p`
    font-size: 14px;
    color: #222222;
    margin: 0;
`
export const AsterixPurple = styled.span`
    fontStyle: italic;
    fontWeight: normal;
    color: #C51852;
    padding-right: 2px;
`