import React, { useState, useEffect } from 'react'
import { Signuptest, ModalSignup } from '../../styled-components'
import * as ROUTES from '../../constants/routes/routes'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { Content } from '../../styled-components/dashboard';
import {
    createOrganisation,
    listOrganisationDetails,
} from '../../actions/organisationActions'
import { industries } from '../../constants/utils'
// import { sendEmailVerificationByEmail } from '../../actions/emailSenderActions';
import LoaderImage from '../../components/LoaderImage'

import {
    BackDrop,
    BackDropAbsolute,
  } from "../../styled-components/general/styles/general";
  

export function CreateOrganizationContainer({ children, accessToken, name, email, isActive, isSuperuser }) {
    let history = useHistory()
    const dispatch = useDispatch()
    const [modalBackdrop, setModalBackdrop] = useState(false);
    const { logout } = useAuth0()

    // Organisation details
    //const [organisationId, setOrganisationId] = useState('')
    const [organisationName, setOrganisationName] = useState('')
    const [organisationIndustry, setOrganisationIndustry] = useState('')

   const organisationCreate = useSelector((state) => state.organisationCreate)
    const {
        loading: loadingCreateOrganisation,
      //  error: errorCreateOrganisation,
        success: successCreateOrganisation,
         organisation: createdOrganisation
    } = organisationCreate

    const organisationDetails = useSelector(
        (state) => state.organisationDetails
    )
    const {
       // loading: loadingDetailsOrganisation,
       // error: errorDetailsOrganisation,
        success: successDetailsOrganisation,
        organisation: detailsOrganisation,
    } = organisationDetails

    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)

    const [isOpenModal, setIsOpenModal] = useState(false)

    useEffect(() => {
        if (accessToken && email ) {
            //if account is active
            if(isActive){
                if(successCreateOrganisation){
                    if(createdOrganisation.detail){
                        setIsOpenModal(!isOpenModal)
                    }
                    else{
                        history.push(`/`)
                    }
                }
                if (successDetailsOrganisation) {
                    // setOrganisationId(detailsOrganisation.id)
                    setOrganisationName(detailsOrganisation.name)
                    if(detailsOrganisation.name)
                        history.push(`/`)

                
                } else {
                    dispatch(
                        listOrganisationDetails(accessToken, email)
                    )
                }
            } 
            //if Account not active
            else {
                history.push(`/account-not-active`)
            }
        }
    }, [
        accessToken,
        dispatch,
        detailsOrganisation,
        successDetailsOrganisation,
        email,


    ])

    const toggling = (e) => {
       
        e.target.style.borderColor = '#C51852'
        e.target.style.color = '#222222'
        e.target.childNodes[1].style.backgroundColor = '#C51852'
        if (e.target.childNodes[0].data === 'Industry' && isOpen) {
            e.target.style.borderColor = '#D4D4D4'
            e.target.style.color = '#D4D4D4'
            e.target.childNodes[1].style.backgroundColor = '#D4D4D4'
        }
        setIsOpen(!isOpen)
        setModalBackdrop(true)
    }

    const onOptionClicked = (e, i, industry) => {
        setSelectedOption(industry)
        setOrganisationIndustry(industry)
        setIsOpen(false)
        setModalBackdrop(false)
        
    }

    
    const cancel = () => {
        setIsOpen(!isOpen)
        setModalBackdrop(false);

    }
  

    const toggleModal = (e) => {
        e.preventDefault()
        const organisation = {
            name: organisationName,
            industry: selectedOption,
            email,
        }
        dispatch(createOrganisation(accessToken, organisation))
     //   setIsOpenModal(!isOpenModal)
    }

    // const goBack = async (e) => {
    //     history.push(`/profile-sign-up`)
    // }

    const closeModal = () => {
        setIsOpenModal(!isOpenModal)
        setOrganisationName('')
    }

    const modalOrganisationExist = () =>{
        return(
            <ModalSignup
                show={isOpenModal}
                // onClose={() => setIsOpenModal(false)}
            >
                <div>
                    <ModalSignup.Header>
                        Organisation name exists. Please enter information about your organisation.
                    </ModalSignup.Header>
                    <ModalSignup.Group>
                      

                    <Signuptest.ButtonNext
                        
                            onClick={closeModal}
                        >
                            Close
                        </Signuptest.ButtonNext>
                    </ModalSignup.Group>
                </div>
            </ModalSignup>
        )
    }

    return (
        <>
        {isActive &&
        <>
        {modalBackdrop ? (
        <BackDropAbsolute
          style={{
            
          }}
          onClick={() =>cancel()}
        />
      ) : (
        <BackDrop style={{position: 'fixed'}}/>
      )}
        {loadingCreateOrganisation ? 
        <LoaderImage /> :
        
        <Signuptest>
            <Signuptest.HeaderFrame>
                <div>
                    <Signuptest.Logo
                        href="https://luminr.ai/"
                        alt="Logo"
                        src={require("../../images/luminr-logo.svg").default}
                        
                    />
                </div>
                <div 
                    style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    zIndex: '9999'
                    }}
                >
                    <Signuptest.HeaderText id="login-btn-header" style={{paddingRight: '10px'}}>Log In</Signuptest.HeaderText>
                    <label 
                        style={{
                        position: 'relative',
                        display: 'inline-block',
                        width: '40px',
                        height: '18px',
                        verticalAlign: 'middle',
                        margin: '0'
                        }}
                    >
                        <input type="checkbox"/>
                        <Signuptest.Slider></Signuptest.Slider>
                    </label>
                    <Signuptest.HeaderText id="create-btn-header">Create an account</Signuptest.HeaderText>
                    <Signuptest.HeaderText style={{paddingLeft: '20px'}}>
                        <a href="https://luminr.ai/">
                            Back to Luminr.ai
                        </a>
                    </Signuptest.HeaderText>
                </div>
            </Signuptest.HeaderFrame>
            <Signuptest.Frame>
                {/* <Signuptest.Logo
                    to={ROUTES.HOME}
                    alt="Logo"
                    src={require("../../images/luminr-logo.svg").default}
                    
                />
                <div onClick={()=>logout()}>
                <Signuptest.IconLink
                    to={ROUTES.HOME}
                    alt="Help"
                    src={require("../../images/logout.svg").default}
                    
                />
                </div> */}
                <Signuptest.Title>
                    Set up your organisation
                </Signuptest.Title>
                <Signuptest.Text>
                    Complete some personal details to get started.
                </Signuptest.Text>

                {/* <Signuptest.Text>
                    Small space for still undecided copy
                </Signuptest.Text> */}

                <Signuptest.InputGroup>
                    {/* <Signuptest.LineUp /> */}
                    <Signuptest.InputContainer>
                        <Signuptest.Label placeholder="Organisation Name">Organisation Name</Signuptest.Label>
                        <Signuptest.Input
                            required
                            type="text"
                            title=""
                            placeholder="Organisation Name"
                            value={organisationName}
                            onChange={(e) =>
                                setOrganisationName(e.target.value)
                            }
                        />
                        <Signuptest.Tooltip>
                            This will act as your account name as well
                        </Signuptest.Tooltip>
                    </Signuptest.InputContainer>
                </Signuptest.InputGroup>

                <Signuptest.DropDownContainer>
                <Signuptest.Label placeholder="Industry">The industry you work in</Signuptest.Label>
                    {/* <Signuptest.LineUp /> */}
                    <Signuptest.DropDownHeader onClick={toggling}>
                        {organisationIndustry || 'Industry'}
                        <Signuptest.DropdownIcon
                            style={{
                                pointerEvents: 'none'
                            }}
                            isOpen
                            alt="dropdown"
                            src={require("../../images/-_down.svg").default}
                            
                        />
                    </Signuptest.DropDownHeader>
                    <Content.Relative>
                    {isOpen && (
                        
                        <Signuptest.DropDownListContainer>
                            <Signuptest.DropDownList>
                                {industries.map((industry, i) => (
                                    <Signuptest.ListItem
                                        onClick={(e) =>
                                            onOptionClicked(e, i, industry)
                                        }
                                        key={i}
                                        value={industry}
                                    >
                                        {industry}
                                    </Signuptest.ListItem>
                                ))}
                            </Signuptest.DropDownList>
                        </Signuptest.DropDownListContainer>
                    )}
                    </Content.Relative>
                </Signuptest.DropDownContainer>

                <Signuptest.ButtonGroup>
                    {/* <Signuptest.ButtonBack onClick={goBack}>
                        Back
                    </Signuptest.ButtonBack> */}
                    <Signuptest.ButtonNext
                        // to="/create-company"
                        onClick={toggleModal}
                    >
                        Continue
                    </Signuptest.ButtonNext>
                </Signuptest.ButtonGroup>
            </Signuptest.Frame>
            <Signuptest.FooterFrame>
                © 2023 <a href="https://luminr.ai/">LUMINR</a>. | LUMINR Is Part Of <a href="https://tomorrowgroup.co/">Tomorrow Group Limited</a> | <a href="https://luminr.ai/cookie-policy/">Cookie Policy</a> | <a href="https://luminr.ai/privacy-policy/">Privacy Policy</a>
            </Signuptest.FooterFrame>

            {/* {modalConfirmEmail()} */}
            {modalOrganisationExist()}
            {children}
        </Signuptest>}
        </>}
        </>
    )
}
