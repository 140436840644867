import React from "react"
import { Container, FlexRow, FlexColumn, Icon, HelpAssistant, RelativeDiv, AbsoluteDiv,
    Triangle, InsightsIconContainer, InsightsIcon, Insights, KeywordsContainer,
    DropDownContainer, HorizontalLineGrey, ExampleFeatureContainer, PaginationIcon,
    SerpCompositionTable, IndustryNetScoreScroll, HoverRow, NoWorkingPageContainer,
    DataRangeHeader, FilterContainer, TickBox, DropDownItemContainer, OnlyContainer, Overflow, FingerprintCharts
} from "./styles/content"

export default function Content({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Content.FlexRow = function ContentFlexRow({children, ...restProps}){
    return <FlexRow {...restProps}>{children}</FlexRow>   
}

Content.FlexColumn = function ContentFlexColumn({children, ...restProps}){
    return <FlexColumn {...restProps}>{children}</FlexColumn>   
}

Content.FingerprintCharts = function ContentFingerprintCharts({children, ...restProps}){
    return <FingerprintCharts {...restProps}>{children}</FingerprintCharts>   
}

Content.Overflow = function ContentOverflow({children, ...restProps}){
    return <Overflow {...restProps}>{children}</Overflow>   
}

Content.DataRangeHeader = function ContentDataRangeHeader({children, ...restProps}){
    return <DataRangeHeader {...restProps}>{children}</DataRangeHeader>   
}


Content.Icon = function ContentIcon({to, ...restProps}){
    return  <Icon {...restProps} />    
}

Content.PaginationIcon = function ContentPaginationIcon({to, ...restProps}){
    return  <PaginationIcon {...restProps} />    
}

Content.HelpAssistant = function ContentHelpAssistant({children, ...restProps}){
    return <HelpAssistant {...restProps}>{children}</HelpAssistant>   
}

Content.Insights = function ContentInsights({children, ...restProps}){
    return <Insights {...restProps}>{children}</Insights>   
}

Content.RelativeDiv = function ContentRelativeDiv({children, ...restProps}){
    return <RelativeDiv {...restProps}>{children}</RelativeDiv>   
}

Content.AbsoluteDiv = function ContentAbsoluteDiv({children, ...restProps}){
    return <AbsoluteDiv {...restProps}>{children}</AbsoluteDiv>   
}

Content.Triangle = function ContentTriangle({children, ...restProps}){
    return <Triangle {...restProps}>{children}</Triangle>   
}

Content.InsightsIcon = function ContentInsightsIcon({children, ...restProps}){
    return <InsightsIcon {...restProps}>{children}</InsightsIcon>   
}

Content.InsightsIconContainer = function ContentInsightsIconContainer({children, ...restProps}){
    return <InsightsIconContainer {...restProps}>{children}</InsightsIconContainer>   
}

Content.KeywordsContainer = function ContentKeywordsContainer({children, ...restProps}){
    return <KeywordsContainer {...restProps}>{children}</KeywordsContainer>   
}

Content.DropDownContainer = function ContentDropDownContainer({children, ...restProps}){
    return <DropDownContainer {...restProps}>{children}</DropDownContainer>   
}

Content.HorizontalLineGrey = function ContentHorizontalLineGrey({children, ...restProps}){
    return <HorizontalLineGrey {...restProps}>{children}</HorizontalLineGrey>   
}

Content.ExampleFeatureContainer = function ContentExampleFeatureContainer({children, ...restProps}){
    return <ExampleFeatureContainer {...restProps}>{children}</ExampleFeatureContainer>   
}

Content.SerpCompositionTable = function ContentSerpCompositionTable({children, ...restProps}){
    return <SerpCompositionTable {...restProps}>{children}</SerpCompositionTable>   
}

Content.HoverRow = function ContentHoverRow({children, ...restProps}){
    return <HoverRow {...restProps}>{children}</HoverRow>   
}


Content.IndustryNetScoreScroll = function ContentIndustryNetScoreScroll({children, ...restProps}){
    return <IndustryNetScoreScroll {...restProps}>{children}</IndustryNetScoreScroll>   
}

Content.NoWorkingPageContainer = function ContentNoWorkingPageContainer({children, ...restProps}){
    return <NoWorkingPageContainer {...restProps}>{children}</NoWorkingPageContainer>   
}


Content.FilterContainer = function ContentFilterContainer({children, ...restProps}){
    return <FilterContainer {...restProps}>{children}</FilterContainer>   
}

Content.TickBox = function ContentTickBox({children, ...restProps}){
    return <TickBox {...restProps}>{children}</TickBox>   
}

Content.DropDownItemContainer = function ContentDropDownItemContainer({children, ...restProps}){
    return <DropDownItemContainer {...restProps}>{children}</DropDownItemContainer>   
}

Content.OnlyContainer = function ContentOnlyContainer({children, ...restProps}){
    return <OnlyContainer {...restProps}>{children}</OnlyContainer>   
}