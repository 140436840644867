import React, { useState } from "react";
import { Content, General, Text } from '../styled-components/dashboard';

import {
  BackDrop,
  BackDropAbsolute,
} from "../styled-components/general/styles/general";



function Select({param, setParam, list, placeholder, title, disabled=false}) {
    const [isOpen, setIsOpen] = useState(false)

    const [searchParameter, setSearchParameter] = useState("");
    const [modalBackdrop, setModalBackdrop] = useState(false);

const onOptionClicked = (e , i) => {
  setIsOpen(false)
  setParam(e)
  setSearchParameter('')
  setModalBackdrop(false)
}

const cancel = () => {
  setIsOpen(!isOpen)
  setModalBackdrop(false);
  
}

const search = (value) => {
  setSearchParameter(value.toLowerCase());
};

    return (
        <>
        
        {modalBackdrop ? (
        <BackDropAbsolute
          style={{
            
          }}
          onClick={() =>cancel()}
        />
      ) : (
        <BackDrop style={{position: 'fixed'}}/>
      )}
        <Text.Bold16Black>
          <Text.AsterixPurple>*</Text.AsterixPurple>
                {title}
        </Text.Bold16Black>
         <General.SelectContainer
          style={{
            position: 'relative',
            pointerEvents: disabled &&'none',
            opacity: disabled && .7
          }}
         >
                  <General.SelectHeader onClick={()=>{
                    setIsOpen(!isOpen)
                    setModalBackdrop(true)
                    setParam('')
                    }
                  
                    }>
                    {param === '' ? 
                    <Text.Flex>
                      <Text.InputRegular16Black
                      placeholder="Type to search"
                      value={searchParameter} 
                      onChange={(e) => search(e.target.value)}
                      />

                      {isOpen ? 
                      <Text.IconSelect src={require("../images/dashboard/up-arrow-pink.svg").default} /> :
                      <Text.IconSelect src={require("../images/dashboard/down-arrow-pink.png").default} />
                      }
                    </Text.Flex> :
                    <Text.Flex>
                      {param}
                    {isOpen ? 
                      <Text.IconSelect src={require("../images/dashboard/up-arrow-pink.svg").default} /> :
                      <Text.IconSelect src={require("../images/dashboard/down-arrow-pink.png").default} />
                    }
                    </Text.Flex>
                    
                    }
                  </General.SelectHeader>
                    {isOpen && (
                      <Content.Absolute >
                      <General.SelectListPink 
                    
                      >
                      {list.map((listItem, i ) => (
                      listItem.toLowerCase().startsWith(searchParameter) &&
                          <General.SelectListItemPink
                              onClick={(e) =>
                                  onOptionClicked(listItem, i)
                              }
                              key={i}
                              value={listItem}
                          >
                          {listItem}
                          </General.SelectListItemPink>
                        ))}
                      </General.SelectListPink>
                      </Content.Absolute>
                    )}
              </General.SelectContainer>
              </>
    )
}

export default Select
