import { 
    PARENT_LIST_REQUEST,
    PARENT_LIST_SUCCESS,
    PARENT_LIST_FAIL,
    PARENT_LIST_RESET,

    PARENT_DETAILS_REQUEST,
    PARENT_DETAILS_SUCCESS,
    PARENT_DETAILS_FAIL,
    PARENT_DETAILS_RESET,

    PARENT_DETAILS_BY_ID_REQUEST,
    PARENT_DETAILS_BY_ID_SUCCESS,
    PARENT_DETAILS_BY_ID_FAIL,
    PARENT_DETAILS_BY_ID_RESET,

    PARENT_CREATE_REQUEST,
    PARENT_CREATE_SUCCESS,
    PARENT_CREATE_FAIL,
    PARENT_CREATE_RESET,

    PARENT_UPDATE_REQUEST,
    PARENT_UPDATE_SUCCESS,
    PARENT_UPDATE_FAIL,
    PARENT_UPDATE_RESET,
 } from '../constants/parentConstants'

 export const parentListReducer =  (state = {parents:[]}, action) => {
    switch(action.type) {
        case PARENT_LIST_REQUEST:
            return {loading: true, requests:[]}

        case PARENT_LIST_SUCCESS:
            return {loading: false, parents: action.payload}

        case PARENT_LIST_FAIL:
            return {loading: false, error: action.payload }

        case PARENT_LIST_RESET: 
        return {loading:false, success:false, parent: {}}

        default:
            return state
    }
}

 export const parentCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case PARENT_CREATE_REQUEST: 
            return {loading:true}

        case PARENT_CREATE_SUCCESS: 
            return {loading:false, success: true, parent: action.payload}
        
        case PARENT_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case PARENT_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const parentUpdateReducer = ( state = { parent: {} }, action ) => {
    switch(action.type){
        case PARENT_UPDATE_REQUEST: 
            return {loading:true}

        case PARENT_UPDATE_SUCCESS: 
            return {loading:false, success: true, parent: action.payload}
        
        case PARENT_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case PARENT_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}


export const parentDetailsReducer = ( state = {parent :[]}, action ) => {
    switch(action.type){
        case PARENT_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case PARENT_DETAILS_SUCCESS: 
            return {loading:false, success:true,  parent: action.payload}
        
        case PARENT_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case PARENT_DETAILS_RESET: 
            return {loading:false, success:false, parent: []}

        default: 
            return state
    }
}

export const parentDetailsByIdReducer = ( state = {parent :{}}, action ) => {
    switch(action.type){
        case PARENT_DETAILS_BY_ID_REQUEST: 
            return {loading:true, ...state}

        case PARENT_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  parent: action.payload}
        
        case PARENT_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case PARENT_DETAILS_BY_ID_RESET: 
            return {loading:false, success:false, parent: {}}

        default: 
            return state
    }
}