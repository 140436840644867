import React, { useState, useEffect} from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom"

import { Text, Content } from "../../../styled-components/visualization";
import { Medium22Black, Regular18Purple } from "../../../styled-components/visualization/text/styles/text";
import { HelpAssistantContainer } from "../HelpAssitant";
import { InsightsContainer } from "./Insights";
import {
  BackDrop,
  BackDropAbsolute,
} from "../../../styled-components/general/styles/general";

export function TitleContainer({ title, subtitle='Dummy text here', type = "large" ,backdropTop="0px", 
pages,
  p11,
  p21,
  p31,
  p41,
  p51,
  p61,
  assistantMessage, csvData='', csvHeader=''
}) {
  
  let history = useHistory()
  const [toggletoggleAssistant, setToggletoggleAssistant] = useState(false);
  const [toggletoggleAssistantInsights, setToggletoggleAssistantInsights] =
    useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  
  const csvFilename = title.replace(' ','_') + '.csv';

  const csvReport = {
    data: csvData,
    headers: csvHeader,
    filename: csvFilename
  };

  useEffect(() => {
  }, [])
  

  const setLayerQuestion = () => {
    //console.log(history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100))
    window.dataLayer.push({
      event: "button_click",
      button_type: "help",
      element_name: title.toLowerCase(),
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
    
  }

  const setLayerInsight = () => {
    //console.log(history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100))
    window.dataLayer.push({
      event: "button_click",
      button_type: "insight",
      element_name: title.toLowerCase(),
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
  }

  const setLayerDownload = () => {
    //console.log(history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100))
    window.dataLayer.push({
      event: "button_click",
      button_type: "download",
      element_name: title.toLowerCase(),
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
  }

  return (
    <>
      {type === "large" ? (
        <>
        <Content.FlexRow>
          <Content.FlexRow
            style={{
              width: "70%",
            }}
          >
            <Medium22Black>{title}</Medium22Black>
            {toggletoggleAssistant ? (
              <Content.RelativeDiv>
                <Content.Icon
                  onClick={() =>{
                    setLayerQuestion()
                    setToggletoggleAssistant(!toggletoggleAssistant)
                  }
            }
                  src={
                    require("../../../images/visualization/Help_Assistant_filled.svg")
                      .default
                  }
                />
                <HelpAssistantContainer title={title} assistantMessage={assistantMessage}/>
              </Content.RelativeDiv>
            ) : title!='Your Keywords & Phrases' ? (
              <Content.Icon
                onClick={() => {
                  setLayerQuestion()
                  setToggletoggleAssistant(!toggletoggleAssistant);
                  setModalBackdrop(true);
                }}
                src={
                  require("../../../images/visualization/Help_Assistant.svg")
                    .default
                }
              />
            )
                : ''
          }
          </Content.FlexRow>
          <Content.FlexRow
            style={{
              width: "30%",
              justifyContent: "flex-end",
            }}
          >
            
            {modalBackdrop ? (
              <BackDropAbsolute
                onClick={() => {
                  setToggletoggleAssistant(false);
                  setToggletoggleAssistantInsights(false);
                  setModalBackdrop(false);
                  
                }}
              />
            ) : (
              <BackDrop />
            )}
            {toggletoggleAssistantInsights ? (
              <Content.RelativeDiv>
                <Content.InsightsIconContainer
                  onClick={() =>{
                    setToggletoggleAssistantInsights(
                      !toggletoggleAssistantInsights
                    )
                    setLayerInsight()
                  }
              }
                >
                  <Content.InsightsIcon
                    src={
                      require("../../../images/visualization/Lightbulb_2_filled.svg")
                        .default
                    }
                  />
                  <Text.Regular12White>{pages}</Text.Regular12White>
                </Content.InsightsIconContainer>
                <InsightsContainer
                  title={title}
                  pages={pages}
                  p11={p11}
                  p21={p21}
                  p31={p31}
                  p41={p41}
                  p51={p51}
                  p61={p61}

                />
              </Content.RelativeDiv>
            ) : title!='Your Keywords & Phrases' ? (
              <Content.Icon
                onClick={() => {
                  setToggletoggleAssistantInsights(
                    !toggletoggleAssistantInsights
                  );
                  setModalBackdrop(true);
                  setLayerInsight()
                  }
                }
                src={
                  require("../../../images/visualization/Lightbulb_2.svg")
                    .default
                }
              />
            )
              : ''
          }
           {(Array.isArray(csvReport.data) && (csvReport.data).length > 0 ) && //show Export csv button only when there is data 
            <CSVLink {...csvReport}>
              <Content.Icon 
                src={require("../../../images/visualization/download.svg").default}
                onClick={() => setLayerDownload()}
              />
            </CSVLink>
           } 
            


            
          </Content.FlexRow>
        </Content.FlexRow>
        <Content.FlexRow >
        <Regular18Purple>{subtitle}</Regular18Purple>
        </Content.FlexRow>
        </>
      ) : (
        <Content.FlexRow>
          <Medium22Black>{title}</Medium22Black>
          {modalBackdrop ? (
              <BackDropAbsolute 
                onClick={() => {
                  setToggletoggleAssistant(false);
                  setToggletoggleAssistantInsights(false);
                  setModalBackdrop(false);
                }}
              />
            ) : (
              <BackDrop />
            )}
          {toggletoggleAssistant ? (
            <Content.RelativeDiv>
              <Content.Icon
                onClick={() => {
                  setLayerQuestion()
                setToggletoggleAssistant(!toggletoggleAssistant)
                }
                }
                src={
                  require("../../../images/visualization/Help_Assistant_filled.svg")
                    .default
                }
              />
              <HelpAssistantContainer title={title} assistantMessage={assistantMessage}/>
            </Content.RelativeDiv>
          ) : (
            <Content.Icon
              onClick={() => {
                setLayerQuestion()
                setToggletoggleAssistant(!toggletoggleAssistant);
                setModalBackdrop(true);
              }}
              src={
                require("../../../images/visualization/Help_Assistant.svg")
                  .default
              }
            />
          )}
        </Content.FlexRow>
      )}
    </>
  );
}
