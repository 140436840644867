import React, {  useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content } from "../../styled-components/visualization";
import { FilterContainer } from "./filters/Filter";
import { CompetitorLandscapeContainer } from "./competitionComponents/CompetitorLandscape";
import { getCompetitorLandscape, hvnScoreLeaderboard, getSerpLeague, 
  competitorSwotHeatmap,competitorSwotLandingPages, monthlyMovements,  competitorChannel, getFilters, monthlyMovementsNumber
} from "../../actions/visualization/dataScienceAPIsActions";
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import { PaidSearchThreatsContainer } from "./competitionComponents/PaidSearchThreats";
import { SerpScoreboardContainer } from "./resultsComponents/serpScoreboard/SerpScoreboard";
import { CompetitorSwotContainer } from "./resultsComponents/competitorSwot/CompetitorSwot";
import { CompetitorMovementsContainer } from "./resultsComponents/competitorMovements/CompetitorMovements";
import {  ChannelActivationStrategiesContainer } from "./competitionComponents/ChannelActivationStrategies";
import { BusinessCriticalKeywordsContainer } from "./competitionComponents/BusinessCriticalKeywords";
import { FilterContext } from "../../contexts/FilterContext";
import { CompanyContext } from "../../contexts/CompanyContext";
import { CompetitorMovementsContext } from "../../contexts/CompetitorMovementsContext";
import { CompetitorsSummaryContainer } from "./competitionComponents/CompetitorsSummary";



export function CompetitionContainer({ accessToken, email, websiteId }) {
  const dispatch = useDispatch()
  var paramsFilters,paramsCompetitorLandscape,paramsHvnScore,paramsSerpLeague,paramsCompetitorSwotHeatmap,paramsCompetitorSwotLandingPages,paramsMonthlySeoPpc,paramsMonthlySeoPpcNumber,paramsCompetitorChannel;
  const [companyContextReady, setCompanyContextReady] = useState(false);
  const [extendParent, setExtendParent] = useState(true);
  const [extendChild, setExtendChild] = useState(true);
  const [trafficDriversFilter, setTrafficDriversFilter] = useState(true);
  const [pageNoSerpLeague, setPageNoSerpLeague] = useState(1);
  const [pageNoPaidSearchThreats, setPageNoPaidSearchThreats] = useState(1);
  const [pageNoCompetitorMovements, setPageNoCompetitorMovements] = useState(1);
  const [pageNoTrafficDrivers, setPageNoTrafficDrivers] = useState(1);
  const [hideWinnersTable, setHideWinnersTable] = useState(true);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [selectedCompetitor, setSelectedCompetitor] = useState('');

  const websiteTrackingStatus = useSelector((state) => state.websiteTrackingStatus)
  const {
      loading: loadingWebsiteTrackingStatus,
      error: errorWebsiteTrackingStatus,
      success: successWebsiteTrackingStatus,
      websiteTrackingStatusResults,
  } = websiteTrackingStatus

  const [firstRender, setFirstRender] = useState(true)
  const {filter_criteria, setFilter_criteria,
    initial_array_keyword_parent,
    initial_array_keyword_child,
    initial_array_keyword_portfolio,
    initial_array_keyword_subportfolio,
    initial_array_keyword,
    initial_array_country,
    initial_array_location,
    initial_array_language,  
    initial_array_device,
    initial_array_domain,
    initial_array_component_clean,
  } = useContext(FilterContext)

  const {date,onboardingDate, companyDomain,  companyId4Api, companyIndustry} = useContext(CompanyContext);

  const { 
    filterSerpFeature,
    filterMovements, 
    filterScale} = useContext(CompetitorMovementsContext);


  useEffect( ()=>{

    if(date && companyDomain &&  companyId4Api && companyIndustry ){
      setCompanyContextReady(true)
    }

    //conditionally display Winners&loosers table
    if(onboardingDate){
      var dateObj = new Date(onboardingDate);
      // add 32 days to onboarding date and compare to last analysys date
      var oneMonthAfterOnboardingDate = new Date(dateObj.setDate(dateObj.getDate()+32)).toISOString().slice(0, 10);
      if(date[1] > oneMonthAfterOnboardingDate){
        setHideWinnersTable(false)
      }else{
        setHideWinnersTable(true)
      }
    }
    
  },[date,onboardingDate,companyDomain,  companyId4Api, companyIndustry])

  //make call for traffic drivers table
  // 9. Competitor SWOT Landing Pages
  const swotLandingPages = useSelector((state) => state.swotLandingPages);
  const {
    loading: loadingCompetitorSwotLandingPages,
    error: errorCompetitorSwotLandingPages,
    success: successCompetitorSwotLandingPages,
    competitorSwotLandingPagesResults,
    competitorSwotLandingPagesResultsTotalRows,
  } = swotLandingPages;

  // Filters
  const filters = useSelector((state) => state.filters)
  const {
      loading: loadingFilters,
      error: errorFilters,
      success: successFilters,
      filtersResults,
  } = filters

   // 14. COMPETITOR LANDSCAPE
   const competitorLandscape = useSelector((state) => state.competitorLandscape)
   const {
       loading: loadingCompetitorLandscape,
       error: errorCompetitorLandscape,
       success: successCompetitorLandscape,
       competitorLandscapeResults,
   } = competitorLandscape

   // 10. Hvn Score Leaderboard
   const hvnScore = useSelector((state) => state.hvnScore)
   const {
       loading: loadingHvnScore,
       error: errorHvnScore,
       success: successHvnScore,
       hvnScoreResults,
       hvnScoreResultsTotalRows
   } = hvnScore

   // 1. SERP LEAGUE
  const serpLeague = useSelector((state) => state.serpLeague);
  const {
    loading: loadingSerpLeague,
    error: errorSerpLeague,
    success: successSerpLeague,
    serpLeagueResults,
    serpLeagueResultsTotalRows
  } = serpLeague;

  // 9. Competitor SWOT Heatmap
  const swotHeatmap = useSelector((state) => state.swotHeatmap);
  const {
    loading: loadingCompetitorSwotHeatmap,
    error: errorCompetitorSwotHeatmap,
    success: successCompetitorSwotHeatmap,
    competitorSwotHeatmapResults,
  } = swotHeatmap;

  // 16. MONTHLY SEO AND PPC COMPETITOR MOVEMENTS
  const monthlySeoPpc = useSelector((state) => state.monthlySeoPpc);
  const {
    loading: loadingMonthlySeoPpc,
    error: errorMonthlySeoPpc,
    success: successMonthlySeoPpc,
    monthlySeoPpcCompetitorResults,
    monthlySeoPpcCompetitorResultsTotalRows
  } = monthlySeoPpc;

   // 16. MONTHLY SEO AND PPC COMPETITOR MOVEMENTS NUMBER 
   const monthlySeoPpcNumber = useSelector((state) => state.monthlySeoPpcNumber);
   const {
     loading: loadingMonthlySeoPpcNumber,
     error: errorMonthlySeoPpcNumber,
     success: successMonthlySeoPpcNumber,
     monthlySeoPpcCompetitorNumberResults
   } = monthlySeoPpcNumber;

   // 18. Competitor Channel
   const competitorChannelVar = useSelector((state) => state.competitorChannel)
   const {
       loading: loadingCompetitorChannel,
       error: errorCompetitorChannel,
       success: successCompetitorChannel,
       competitorChannelResults,
   } = competitorChannelVar
   
   useEffect(() => { 
    // window.scrollTo(0, 0);
    if (accessToken && email){
      if(companyContextReady){
         paramsFilters = {
          "company_id":  companyId4Api,
          "start_date": date[0],
          "end_date": date[1],
          "filter_criteria": filter_criteria
        }
        paramsCompetitorLandscape = {
          "company_id":  companyId4Api, 
          "start_date": date[0],
          "end_date": date[1],
          "limit": 50,
          "lowest_position": 5,
          "keyword_overlap_percentile": 90,
          "position_split": 3,
          "filter_criteria": filter_criteria
        }
        paramsHvnScore = {
          "company_id":  companyId4Api,
          "start_date": date[0],
          "end_date": date[1],
          "page_no": pageNoSerpLeague,
          "max_results":100,
          "filter_criteria": filter_criteria
        }
        paramsSerpLeague = {
          "company_id":  companyId4Api,
          "start_date": date[0],
          "end_date": date[1],
          "page_no": pageNoSerpLeague,
          "max_results":100,
          "filter_criteria": filter_criteria
        };
        paramsCompetitorSwotHeatmap = {
          "company_id":  companyId4Api,
          "slices" :extendParent ? ["keyword_group_1"] : !extendChild ? ["keyword_group_1","keyword_group_2","keyword_group_3"] :["keyword_group_1","keyword_group_2"] ,
          "websites": [],
          "start_date": date[0],
          "end_date": date[1],
          "limit": 100,
          "filter_criteria": filter_criteria
        };

        paramsCompetitorSwotLandingPages = {
          "company_id":  companyId4Api,
          "start_date": date[0],
          "end_date": date[1],
          "filter_criteria": filter_criteria,
          "page_no": pageNoTrafficDrivers,
          "max_results":100,
          "topic":selectedTopic[0] ? selectedTopic[0] : null,
          "subtopic":selectedTopic[1] ? selectedTopic[1] : null,
          "competitor_name":selectedCompetitor !='' ? selectedCompetitor : null
        };
        
        
        paramsCompetitorChannel = {
          "company_id":  companyId4Api,
          "limit": 10,
          "start_date": date[0],
          "end_date": date[1],
          "filter_criteria": filter_criteria
        }
      }



      //filters first
      if(successFilters){
        if(successWebsiteTrackingStatus && websiteTrackingStatusResults.status === 'ready'  && filtersResults[0]){
        if(firstRender && initial_array_keyword_parent[0] === undefined){
          setFirstRender(false)
          filtersResults[0].focus__keyword_parent.map((f, i) =>(
           initial_array_keyword_parent.push(f)
          ))

          filtersResults[0].focus__keyword_child.map((f, i) =>(
            initial_array_keyword_child.push(f)
           ))

           filtersResults[0].focus__keyword_portfolio.map((f, i) =>(
            initial_array_keyword_portfolio.push(f)
           ))

           filtersResults[0].focus__keyword_subportfolio.map((f, i) =>(
            initial_array_keyword_subportfolio.push(f)
           ))

           filtersResults[0].focus__keyword.map((f, i) =>(
            initial_array_keyword.push(f)
           ))

           filtersResults[0].localisation__country.map((f, i) =>(
            initial_array_country.push(f)
           ))

           filtersResults[0].localisation__language.map((f, i) =>(
            initial_array_language.push(f)
           ))

           filtersResults[0].localisation__google_domain.map((f, i) =>(
            initial_array_location.push(f)
           ))

           filtersResults[0].localisation__device.map((f, i) =>(
            initial_array_device.push(f)
           ))

           filtersResults[0].serp__domain.map((f, i) =>(
            initial_array_domain.push(f)
           ))

           filtersResults[0].serp__component_clean.map((f, i) =>(
            initial_array_component_clean.push(f)
           ))
         
        }
      }
      }else{
        if(!loadingFilters && paramsFilters){
          dispatch(getFilters(accessToken, paramsFilters, email))
        }
      }
      //serp scoreboard second
      if(successSerpLeague){
      }
      else{
        if(!loadingSerpLeague && paramsSerpLeague){
          dispatch(getSerpLeague(accessToken, paramsSerpLeague, email));
        }
      }
      //competitior lanscape third
      if(successCompetitorLandscape){
      }
      else{
        if(!loadingCompetitorLandscape && paramsCompetitorLandscape){
          dispatch(getCompetitorLandscape(accessToken, paramsCompetitorLandscape, email))
        }
      }
      //competitor swot ..
      if(successCompetitorSwotHeatmap){ 
      } 
      else{
        if(!loadingCompetitorSwotHeatmap && paramsCompetitorSwotHeatmap){
          dispatch(competitorSwotHeatmap(accessToken, paramsCompetitorSwotHeatmap, email))
        }
      }
      //competitor swot LandingPAges
      if(successCompetitorSwotLandingPages){ 
      } 
      else{
        if(!loadingCompetitorSwotLandingPages && paramsCompetitorSwotLandingPages){
          dispatch(competitorSwotLandingPages(accessToken, paramsCompetitorSwotLandingPages, email))
        }
      }

      //Paid search Threats
      if(successHvnScore){    
      }
      else{
        if(!loadingHvnScore && paramsHvnScore){
          dispatch(hvnScoreLeaderboard(accessToken, paramsHvnScore, email))
        }
      }
      //Channel Activation
      if(successCompetitorChannel){
      } else{
        if(!loadingCompetitorChannel && paramsCompetitorChannel){
          // eslint-disable-next-line react-hooks/exhaustive-deps
          dispatch(competitorChannel(accessToken, paramsCompetitorChannel, email))
        }
      }
     
    } 
   },
   [
    accessToken, 
    successCompetitorLandscape,
    successCompetitorSwotHeatmap,
    successCompetitorSwotLandingPages,
    successSerpLeague,
    successMonthlySeoPpc,
    successHvnScore,
    successCompetitorChannel,
    loadingCompetitorChannel,
    loadingCompetitorLandscape,
    loadingCompetitorSwotHeatmap,
    loadingCompetitorSwotLandingPages,
    loadingHvnScore,
    loadingSerpLeague,
    loadingMonthlySeoPpc,
    successFilters,
    firstRender,
    dispatch, 
    email,
    companyContextReady,
    extendChild,
    extendParent
  ])

  //for competitor movements filter
  useEffect( ()=>{
    if(!loadingMonthlySeoPpc && filterSerpFeature.length !=0 && filterMovements.length !=0 && filterScale.length !=0 && companyContextReady){
      
      paramsMonthlySeoPpc = {
        "company_id":  companyId4Api,
        "channel": filterSerpFeature[0] == 'Organic Results' ? 'organic' : 'paid',
        "start_date": date[0],
        "page_no": pageNoCompetitorMovements,
        "max_results":100,
        "severity_labels": filterScale,
        "trend_categories": filterMovements,
        "position_min": 1,
        "position_max": 20,
        "filter_criteria": filter_criteria
      };
      dispatch(monthlyMovements(accessToken, paramsMonthlySeoPpc, email));
    }

    //monthly competitor number
    if(!loadingMonthlySeoPpcNumber && filterSerpFeature.length !=0 && filterMovements.length !=0 && filterScale.length !=0 && companyContextReady){
      
      paramsMonthlySeoPpcNumber = {
        "company_id":  companyId4Api,
        "channel": filterSerpFeature[0] == 'Organic Results' ? 'organic' : 'paid',
        "start_date": date[0],
        "severity_labels": filterScale,
        "trend_categories": filterMovements,
        "position_min": 1,
        "position_max": 20,
        "filter_criteria": filter_criteria
      };
      dispatch(monthlyMovementsNumber(accessToken, paramsMonthlySeoPpc, email));
    }
    

  },[filterSerpFeature,filterMovements,filterScale, companyContextReady,loadingCompetitorChannel])


  useEffect(() => { 
    if(companyContextReady && paramsCompetitorSwotHeatmap){
      dispatch(competitorSwotHeatmap(accessToken, paramsCompetitorSwotHeatmap, email))
    }
   },
   [
    extendParent,extendChild
  ])

  //pagination serp league
  useEffect( ()=>{
    paramsSerpLeague = {
      "company_id":  companyId4Api,
      "start_date": date[0],
      "end_date": date[1],
      "page_no": pageNoSerpLeague,
      "max_results":100,
      "filter_criteria": filter_criteria
    };
    if(pageNoSerpLeague && companyContextReady){
      dispatch(getSerpLeague(accessToken, paramsSerpLeague, email));
    }

  },[pageNoSerpLeague] )

  //pagination paid search threats
  useEffect( ()=>{
    paramsHvnScore = {
      "company_id":  companyId4Api,
      "start_date": date[0],
      "end_date": date[1],
      "page_no": pageNoPaidSearchThreats,
      "max_results":100,
      "filter_criteria": filter_criteria
    }  
    if(pageNoPaidSearchThreats && companyContextReady){
      dispatch(hvnScoreLeaderboard(accessToken, paramsHvnScore, email))
    }

  },[pageNoPaidSearchThreats] )

  //pagination competitor movements
  useEffect( ()=>{

    paramsMonthlySeoPpc = {
      "company_id":  companyId4Api,
      "channel": filterSerpFeature[0] == 'Organic Results' ? 'organic' : 'paid',
      "start_date": date[0],
      "page_no": pageNoCompetitorMovements,
      "max_results":100,
      "severity_labels": filterScale,
      "trend_categories": filterMovements,
      "position_min": 1,
      "position_max": 20,
      "filter_criteria": filter_criteria
    };
   
   
    if(pageNoCompetitorMovements && companyContextReady &&!loadingMonthlySeoPpc ){
      dispatch(monthlyMovements(accessToken, paramsMonthlySeoPpc, email));
    }

  },[pageNoCompetitorMovements] )


   //pagination traffic Drivers
   useEffect( ()=>{
    paramsCompetitorSwotLandingPages = {
      "company_id":  companyId4Api,
      "start_date": date[0],
      "end_date": date[1],
      "filter_criteria": filter_criteria,
      "page_no": pageNoTrafficDrivers,
      "max_results":100,
      "topic":selectedTopic[0] ? selectedTopic[0] : null,
      "subtopic":selectedTopic[1] ? selectedTopic[1] : null,
      "competitor_name":selectedCompetitor !='' ? selectedCompetitor : null
    };
  
    if(pageNoTrafficDrivers && companyContextReady){
      dispatch(competitorSwotLandingPages(accessToken, paramsCompetitorSwotLandingPages, email))
    }

  },[pageNoTrafficDrivers,selectedTopic,selectedCompetitor] )


  //refresh traffic drivers table when click inside swot heatmap
  useEffect( ()=>{

    paramsCompetitorSwotLandingPages = {
      "company_id":  companyId4Api,
      "slices" :extendParent ? ["keyword_group_1"] : !extendChild ? ["keyword_group_1","keyword_group_2","keyword_group_3"] :["keyword_group_1","keyword_group_2"] ,
      "websites": [],
      "start_date": date[0],
      "end_date": date[1],
      "limit": 100,
      "filter_criteria": filter_criteria
    };

     //competitor swot ..
    //  if(successCompetitorSwotLandingPages){ 
    // } 
    // else{
    //   if(!loadingCompetitorSwotHeatmap && paramsCompetitorSwotLandingPages){
    //     dispatch(competitorSwotLandingPages(accessToken, paramsCompetitorSwotLandingPages, email))
    //   }
    // }

  },[trafficDriversFilter]) 


  return (
    <Content>
      {loadingCompetitorChannel || !successCompetitorChannel ||
      loadingCompetitorLandscape || !successCompetitorLandscape ||
      loadingFilters || !successFilters ||
      loadingWebsiteTrackingStatus || !successWebsiteTrackingStatus 
      ?
      <LoaderImage height={'85vh'}/> : 
        errorCompetitorChannel || errorCompetitorLandscape || errorCompetitorSwotHeatmap || errorFilters ||
        errorHvnScore || errorMonthlySeoPpc || errorSerpLeague || errorWebsiteTrackingStatus ?
        <ErrorMessage /> :
        <>
      <Content.FlexColumn >
      {
        loadingFilters || !successFilters 
          ? 
          <LoaderImage height={'85vh'} /> : 
          errorFilters 
          ? 
          <ErrorMessage /> :
          filtersResults[0] && filtersResults.detail===undefined && websiteTrackingStatusResults.status==='ready' &&
            <FilterContainer filtersResults={filtersResults} filter_criteria={filter_criteria} setFilter_criteria={setFilter_criteria} setFirstRender={setFirstRender}/>
      }
      </Content.FlexColumn>
      
      { false && successMonthlySeoPpc && monthlySeoPpcCompetitorResults.length !== 0  && 
      <Content.FlexColumn
          style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
        
          <CompetitorsSummaryContainer
          accessToken={accessToken}
          websiteId={websiteId}
          loadingMonthlySeoPpc={loadingMonthlySeoPpc}
          successMonthlySeoPpc={successMonthlySeoPpc}
          errorMonthlySeoPpc={hideWinnersTable? 'hideTable' : errorMonthlySeoPpc}
          monthlySeoPpcCompetitorResults={monthlySeoPpcCompetitorResults}
          monthlySeoPpcCompetitorResultsTotalRows={monthlySeoPpcCompetitorResultsTotalRows}
          pageNoCompetitorMovements={pageNoCompetitorMovements}
          setPageNoCompetitorMovements = {setPageNoCompetitorMovements}
          monthlyNumbers={{
            loading: loadingMonthlySeoPpcNumber,
            success: successMonthlySeoPpcNumber,
            error: errorMonthlySeoPpcNumber,
            results: monthlySeoPpcCompetitorNumberResults
          }}
          
          ></CompetitorsSummaryContainer>
      </Content.FlexColumn>}


      <Content.FlexColumn
          style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
        {/* Share of Search Leaderboard */}
        <SerpScoreboardContainer  
        loadingSerpLeague= {loadingSerpLeague} 
        successSerpLeague={successSerpLeague} 
        errorSerpLeague={errorSerpLeague}
        serpLeagueResults ={serpLeagueResults}
        serpLeagueResultsTotalRows= {serpLeagueResultsTotalRows}
        pageNoSerpLeague = {pageNoSerpLeague}
        setPageNoSerpLeague = {setPageNoSerpLeague}
        /> 
        </Content.FlexColumn>

      <Content.FlexColumn
          style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
        {/* Battle of the Brands */}
        <CompetitorLandscapeContainer 
        companyDomain = {companyDomain}
        loadingCompetitorLandscape={loadingCompetitorLandscape} 
        successCompetitorLandscape={successCompetitorLandscape} 
        errorCompetitorLandscape={errorCompetitorLandscape}
        competitorLandscapeResults={competitorLandscapeResults}/>
        </Content.FlexColumn>

        <Content.FlexColumn
          style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
        {/* Competitor Positioning Analysis */}
        <CompetitorSwotContainer 
        loadingCompetitorSwotHeatmap={loadingCompetitorSwotHeatmap}
        successCompetitorSwotHeatmap={successCompetitorSwotHeatmap}
        errorCompetitorSwotHeatmap={errorCompetitorSwotHeatmap}
        competitorSwotHeatmapResults={competitorSwotHeatmapResults}  
        extendParent={extendParent} setExtendParent={setExtendParent}  
        extendChild={extendChild} setExtendChild={setExtendChild}
        setPageNoTrafficDrivers={setPageNoTrafficDrivers}
        selectedTopic={selectedTopic}
        setSelectedTopic={setSelectedTopic}
        selectedCompetitor={selectedCompetitor}
        setSelectedCompetitor={setSelectedCompetitor}
        trafficDrivers={{
          loading: loadingCompetitorSwotLandingPages,
          success: successCompetitorSwotLandingPages,
          error: errorCompetitorSwotLandingPages,
          results: competitorSwotLandingPagesResults,
          total_rows:competitorSwotLandingPagesResultsTotalRows,
          pageNoTrafficDrivers:pageNoTrafficDrivers
        }}        />
        </Content.FlexColumn>

        <Content.FlexColumn
          style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
        {/* Paid Threats */}
        <PaidSearchThreatsContainer 
        loadingHvnScore={loadingHvnScore}
        successHvnScore={successHvnScore}
        errorHvnScore={errorHvnScore}
        hvnScoreResults={hvnScoreResults}
        hvnScoreResultsTotalRows={hvnScoreResultsTotalRows}
        pageNoPaidSearchThreats = {pageNoPaidSearchThreats}
        setPageNoPaidSearchThreats = {setPageNoPaidSearchThreats}
        /> 
        </Content.FlexColumn>

        <Content.FlexRow
          style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
          <Content.FlexColumn
            style={{
              width: '100%'
            }}
          >
          {/* Competitive Traffic Drivers */}
          <ChannelActivationStrategiesContainer 
          loadingCompetitorChannel={loadingCompetitorChannel}
          successCompetitorChannel={successCompetitorChannel}
          errorCompetitorChannel={errorCompetitorChannel}
          competitorChannelResults={competitorChannelResults}/> 
          </Content.FlexColumn>

          {/* total CTR table here */}
          {/* <Content.FlexColumn
           style={{
              width: '48%'
            }}
          >
            <BusinessCriticalKeywordsContainer />
          </Content.FlexColumn> */}

        </Content.FlexRow>
        
        <Content.FlexColumn
        style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
        {/* Winners and Losers */}
       {/* guarded temporary until we can guard this properly */}
      <CompetitorMovementsContainer 
        loadingMonthlySeoPpc={loadingMonthlySeoPpc}
        successMonthlySeoPpc={successMonthlySeoPpc}
        errorMonthlySeoPpc={hideWinnersTable? 'hideTable' : errorMonthlySeoPpc}
        monthlySeoPpcCompetitorResults={monthlySeoPpcCompetitorResults}
        monthlySeoPpcCompetitorResultsTotalRows={monthlySeoPpcCompetitorResultsTotalRows}
        pageNoCompetitorMovements={pageNoCompetitorMovements}
        setPageNoCompetitorMovements = {setPageNoCompetitorMovements}
        />  
        </Content.FlexColumn>
        </>
      }
        
    </Content>
  )
}
