import { createGlobalStyle} from "styled-components"

export const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
         font-family: tenon, sans-serif;
        letter-spacing: 0;
    }

    @font-face {
        font-family: 'Tenon';
       
      
    }
`