import React from "react"
import { Container, Text, TextContainer, Line, LogoImg, PurpleBackground,WhiteText,SocialImg } from "./styles/footer"

export default function Footer({children, ...restProps}){
    return <Container {...restProps}>{children}</Container>
}

Footer.TextContainer = function FooterTextContainer({children, ...restProps}){
    return <TextContainer {...restProps}>{children}</TextContainer>
}

Footer.Text = function FooterText({children, ...restProps}){
    return <Text {...restProps}>{children}</Text>
}

Footer.Line = function FooterLine({children, ...restProps}){
    return <Line {...restProps}>{children}</Line>
}
Footer.LogoImg = function FooterLogoImg({to, ...restProps}){
    return  <LogoImg {...restProps} />    
}
Footer.PurpleBackground = function FooterPurpleBackground({to, ...restProps}){
    return  <PurpleBackground {...restProps} />    
}
Footer.WhiteText = function FooterWhiteText({to, ...restProps}){
    return  <WhiteText {...restProps} />    
}
Footer.SocialImg = function FooterSocialImg({to, ...restProps}){
    return  <SocialImg {...restProps} />    
}