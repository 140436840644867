import React, {useState, useEffect} from 'react'
import { Form, Button, Row, Col} from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'
import { listProfileDetails } from '../actions/profileActions'
import { updateWebsite, listWebsiteDetailsById } from '../actions/websiteActions'

import { createLog } from '../actions/loggingsystem/loggingSystemActions'
import { days_of_week } from '../constants/utils'

function Website_details({match, history}) {
    const dispatch = useDispatch()
    const [accessToken, setAccessToken] = useState('')
    const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();


    //user details
    const [email, setEmail] = useState('')

    const profileDetails = useSelector(state => state.profileDetails)
    const {
        //loading, 
        //error, 
        success, 
        profile} = profileDetails

    //Website info
    const [websiteId, setWebsiteId] = useState('')
    const [websiteCompanyId, setWebsiteCompanyId] = useState('')
    const [websiteName, setWebsiteName] = useState('')
    const [websiteURL, setWebsiteURL] = useState('')
    const [theme, setTheme] = useState('')
    const [active, setActive] = useState(false)
    const [frequency, setFrequency] = useState('')
    const [trigger_wday, setTrigger_wday] = useState('')
    const [trigger_mday, setTrigger_mday] = useState('')
    const [trigger_time, setTrigger_time] = useState('')
    const [slack_channel, setSlack_channel] = useState('')
    const [websiteCreatedBy, setWebsiteCreatedBy] = useState('')
    const [websiteCreatedAt, setWebsiteCreatedAt] = useState('')
    const [websiteModifiedAt, setWebsiteModifiedAt] = useState('')
    const [websiteModifiedBy, setWebsiteModifiedBy] = useState('')

    const websiteDetailsById = useSelector(state => state.websiteDetailsById)
    const {//loading: loadingDetailsByIdWebsite, 
        //error: errorDetailsByIdWebsite, 
        success: successDetailsByIdWebsite, 
        website
    } = websiteDetailsById


    useEffect(()=>{
        const getAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getAccessToken()
        if(!user) {
            loginWithRedirect()
        } else {
            if(accessToken) {
                if(success) {
                    setEmail(profile.email)
                    if(successDetailsByIdWebsite){
                        setWebsiteId(website.id)
                        setWebsiteName(website.name)
                        setWebsiteURL(website.website)
                        setTheme(website.theme)
                        setActive(website.active)
                        setFrequency(website.frequency)
                        setTrigger_wday(website.trigger_wday)
                        setTrigger_mday(website.trigger_mday)
                        setTrigger_time(website.trigger_time)
                        setSlack_channel(website.slack_channel)
                        setWebsiteCreatedBy(website.createdBy)
                        setWebsiteCreatedAt(website.createdAt)
                        setWebsiteModifiedBy(website.modifiedBy)
                        setWebsiteModifiedAt(website.modifiedAt)
                        setWebsiteCompanyId(website.companyId)           
                    } else {
                        dispatch(listWebsiteDetailsById(accessToken, match.params.websiteId))
                    }
                  
                } else {
                    dispatch(listProfileDetails(accessToken, user.email))
                }
            }
        }
    },[user, 
        accessToken, 
        success, 
        successDetailsByIdWebsite,
        website,
        dispatch,
        loginWithRedirect,
        match,
        profile,
        getAccessTokenSilently,
    ])

    const createLogHandler = (logType, actionType, logMessage) => {
        const log = {
            userEmail: user.email,
            logType: logType,
            actionType: actionType,
            logMessage: logMessage,
        }
        dispatch(createLog(accessToken, log))
    }

    //Website functions
    const editWebsiteHandler = (e) => {
        e.preventDefault()
        const website = {
            id: websiteId,
            name: websiteName,
            website: websiteURL,
            theme,
            active,
            frequency,
            trigger_wday,
            trigger_mday,
            trigger_time,
            slack_channel,
            email
        }
        dispatch(updateWebsite(accessToken, website, websiteCompanyId))
        createLogHandler('info', 'website details', 'The website details was successfully edited!')
    }

    const redirectCompanyHandler = (e, websiteCompanyId) => {
        e.preventDefault()

        history.push(`/company/details/${websiteCompanyId}`)
    }

    const redirectCompetitorHandler = () => {

        history.push(`/competitors/${match.params.websiteId}`)
    }

    const redirectKeywordHandler = () => {
        history.push(`/keywords/${match.params.websiteId}`)
    }

    const redirectKeywordListHandler = () => {
        history.push(`/keywords/list/${match.params.websiteId}`)
    }

    const redirectKeywordCategorizer = () => {
        history.push(`/keywords/categorizer/${match.params.websiteId}?param=&portfolio=&page=1`)
    }

    const redirectKeywordCategorizerUpdated = () => {
        history.push(`/keywords/categorizer/updated/${match.params.websiteId}`)
    }

    return (
        <>
        <Button 
                variant='secondary' 
                className='btn btn-light my-3'
                onClick={(e) => redirectCompanyHandler(e, websiteCompanyId)}
            >
                Go back
            </Button>
            
        <Row className="d-flex justify-content-between">
        <Col md={5}>
            
        
            <h3>Website info</h3>
            <Form>
                <Form.Group controlId='websiteName'>
                    <Form.Label>
                        Name
                    </Form.Label>
                    <Form.Control
                        required
                        type='name'
                        placeholder='Enter Website Name'
                        value={websiteName}
                        onChange={(e) => setWebsiteName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                
                <Form.Group controlId='websiteURL'>
                    <Form.Label>
                        Website URL
                    </Form.Label>
                    <Form.Control
                        required
                        type='text'
                        placeholder='Enter Website URL'
                        value={websiteURL}
                        onChange={(e) => setWebsiteURL(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='theme'>
                    <Form.Label>
                        Theme
                    </Form.Label>
                    <Form.Control
                        required
                        type='text'
                        placeholder='A custom theme for this websites set of keywords.'
                        value={theme}
                        onChange={(e) => setTheme(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId='active'>
                    <Form.Check
                        type='checkbox'
                        label='Active?' 
                        id='active'
                        checked={active}
                        name='active'
                        onChange={(e) => setActive(!active)}                           
                    >
                    </Form.Check>
                </Form.Group>
                <Form.Label>
                    Frequency
                </Form.Label>
                <select 
                    className="form-control" 
                    onChange={(e) => setFrequency(e.target.value)}
                    required
                >
                    <option value={frequency}>{frequency}</option>
                    <option value="hourly">Hourly</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    
                </select>
                <br/>

                <Form.Label>
                    Day of Week Trigger
                </Form.Label>
                <select 
                    className="form-control" 
                    onChange={(e) => setTrigger_wday(e.target.value)}
                    required
                >
                    <option value={trigger_wday}>{days_of_week[trigger_wday]}</option>
                    {days_of_week.map((day, i) => (
                                <option key={i} value={i}>{day}</option>
                            ))}
                </select>
                <br/>

                <Form.Group controlId='trigger_mday'>
                    <Form.Label>
                        Day of Month Trigger
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='If monthly frequency, day of the month to run.(Can leave this blank).'
                        value={trigger_mday}
                        onChange={(e) => setTrigger_mday(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='slack_channel'>
                    <Form.Label>
                        Slack Channel
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Name of the Slack channel to issue alerts into. '
                        value={slack_channel}
                        onChange={(e) => setSlack_channel(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                {websiteCreatedBy && <Form.Group controlId='websiteCreatedBy'>
                        <Form.Label>
                            Created By
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder=''
                            value={websiteCreatedBy}
                            readOnly
                        >
                        </Form.Control>
                    </Form.Group>}
                    {websiteCreatedAt && <Form.Group controlId='websiteCreatedAt'>
                        <Form.Label>
                            Created At
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder=''
                            value={websiteCreatedAt && websiteCreatedAt.substring(0, 10)}
                            readOnly
                        >
                        </Form.Control>
                    </Form.Group>}
                    {websiteModifiedBy && <Form.Group controlId='websiteModifiedBy'>
                        <Form.Label>
                            Modified By
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder=''
                            value={websiteModifiedBy}
                            readOnly
                        >
                        </Form.Control>
                    </Form.Group>}
                    {websiteModifiedAt && <Form.Group controlId='websiteModifiedAt'>
                        <Form.Label>
                            Modified At
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder=''
                            value={websiteModifiedAt && websiteModifiedAt.substring(0, 10)}
                            readOnly
                        >
                        </Form.Control>
                    </Form.Group>}      
                    <Button
                        onClick={editWebsiteHandler}
                        variant='primary'
                    >
                        Edit Website
                    </Button>
                    <br/>
                    <br/>
                </Form>
            </Col>
            <Col md={5}>
           <h4>Keywords</h4>
            <div>
                <Button 
                    variant='primary' 
                    className='btn my-3'
                    onClick={() => redirectKeywordHandler()}
                >
                    Generate keywords
                </Button>
            </div>
            <div>
                <Button 
                    variant='primary' 
                    className='btn my-3'
                    onClick={() => redirectKeywordListHandler()}
                >
                    See your keywords
                </Button>
            </div>
            <div>
                <Button 
                    variant='primary' 
                    className='btn my-3'
                    onClick={() => redirectKeywordCategorizer()}
                >
                    Categorize your keywords
                </Button>
            </div>
            <div>
                <Button 
                    variant='primary' 
                    className='btn my-3'
                    onClick={() => redirectKeywordCategorizerUpdated()}
                >
                    Categorizer updated
                </Button>
            </div>
            <br/>
            <br/>
            <h4>Competitors</h4>
            <div>
                <Button 
                    variant='primary' 
                    className='btn my-3'
                    onClick={() => redirectCompetitorHandler()}
                >
                    Go to competitors
                </Button>
            </div>
            </Col>
        </Row>
        </>
    )
}

export default Website_details
