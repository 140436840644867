import React,{useEffect, useState} from "react";
import { Text, Content } from "../../../styled-components/visualization";
import { nFormatter } from "../resultsComponents/serpScoreboard/sortingUtils";
import { CounterContainer } from "../utilComponents/Counter";
import { ProgressBarContainer } from "../utilComponents/ProgressBar";

export function ContentCreationOpportunitiesTableContainer({colorIntervals,selectedContentUrl,setSelectedContentUrl,contentExplorerResults}) {
    const [numberOfPages] = useState(Math.ceil(contentExplorerResults.length/15))
    const [currentPage, setCurrentPage] = useState(1)
    const [growth_max, setGrowth_max] = useState(0)

    useEffect (()=>{
        let maxGrowth =0;
        contentExplorerResults.map((result,i) =>{
            const growth_val = result.serp_real_estate_current - result.serp_real_estate_previous_period ;
            if(growth_val > maxGrowth){
                maxGrowth = growth_val.toFixed(0);
            }
        })
        setGrowth_max(maxGrowth)
    },[growth_max,contentExplorerResults])
    

    const nextPage = () => {
        if(currentPage<numberOfPages){
          setCurrentPage(currentPage+1)
        }   
      }
  
      const previousPage = () => {
        if(currentPage>1){
          setCurrentPage(currentPage-1)
        }   
      }

    const paginationLine = () =>{
        return (
            <Content.FlexRow
            style ={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '3%'
            }}
         >
         <Content.PaginationIcon
         onClick={()=>previousPage()}
         src={require("../../../images/visualization/left_arrow.svg").default}/>
         {currentPage !== numberOfPages ?
          <Text.Regular13Grey>{(currentPage-1)*15+1} - {currentPage*15} / {contentExplorerResults.length}</Text.Regular13Grey> :
          <Text.Regular13Grey>{(currentPage-1)*15+1} - {contentExplorerResults.length} / {contentExplorerResults.length}</Text.Regular13Grey>
         }
         
         <Content.PaginationIcon
        onClick={() =>nextPage()}
         src={require("../../../images/visualization/right_arrow.svg").default}/>
         </Content.FlexRow>
        )
    }

  return (
      <>
      <Content.FlexRow
        style={{
            justifyContent:'space-between',
        }}
      >
          <Content.FlexColumn  style={{ width: '30px'  }}> </Content.FlexColumn>
          <Text.Regular13Grey style={{width:'25%'}}>Content</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'15%', textAlign: 'center'}}>Keyword Group</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Current SERP Real Estate</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Previous SERP Real Estate</Text.Regular13Grey>

          {/* <Content.FlexColumn
            style={{ 
                width:'20%',
                alignItems: 'end',
                marginRight: '2%'
                }}
          >
            <Text.Regular13Grey >Growth</Text.Regular13Grey>
          </Content.FlexColumn> */}
          <Text.Regular13Grey style={{width:'20%' ,textAlign:'center'}}>Change in SERP Real Estate</Text.Regular13Grey>
      </Content.FlexRow>
      {contentExplorerResults && 
      <Content.DropDownContainer
        style={{
            marginTop:'2%',
            maxHeight: '580px'
        }}
      >
        {contentExplorerResults.map((result,i) =>(
            ((i) >= (currentPage-1)*15 && (i)<currentPage*15) ? 
                <Content.HoverRow  //seems that only URL is unique => filter left chart by url
                    className={selectedContentUrl != '' ? (selectedContentUrl != result.url ? 'inactiveRow' : 'selectedRow') : ''}
                    onClick={() => selectedContentUrl != result.url ? setSelectedContentUrl(result.url) : setSelectedContentUrl('')}>
                        
                    <Content.FlexColumn style={{ width: '30px' }}>
                    <CounterContainer number={i+1} />
                    </Content.FlexColumn>
                    <Content.FlexRow
                        style={{
                            width: '25%'
                        }}
                    >   
                       
                        <Text.Regular14Black style={{overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{result.domain}</Text.Regular14Black>
                        <a href={result.url} target="_blank"><Content.Icon src={require("../../../images/visualization/Arrow_2.svg").default}/></a>
                    </Content.FlexRow>
                    <Text.Regular14Black
                        style={{
                            width: '15%', textAlign: 'center'
                        }}
                    >
                        {result.keyword_parent}
                    </Text.Regular14Black>
                    {
                        colorIntervals.map((range, i) => (
                            result.serp_real_estate_current > range.start && result.serp_real_estate_current <= range.end && 
                             <Text.Regular14WhitePurpleLightBackground style={{width: '10%',background:range.color}}>
                                {nFormatter(result.serp_real_estate_current)}
                            </Text.Regular14WhitePurpleLightBackground> 
                        ))
                    }

                    {result.serp_real_estate_previous_period ?
                    <Text.Regular14Black
                        style={{
                            width: '10%', textAlign: 'center'
                        }}
                    >
                        {nFormatter(result.serp_real_estate_previous_period)}
                    </Text.Regular14Black>: 
                    <Text.Regular14Black
                        style={{
                            width: '10%', textAlign: 'center'
                        }}
                    >
                        -
                    </Text.Regular14Black>
                    }
                    {/* <Content.FlexColumn
                    style={{
                            width: '10%',
                            alignItems:'end',
                            marginRight: '2%', textAlign: 'center'
                        }}>
                    {result.serp_real_estate_current - result.serp_real_estate_previous_period >= 0  ? 
                    <Text.GreenRegular14
                    style={{
                        textAlign: 'center'
                    }}
                        
                    >
                        + {(result.serp_real_estate_current - result.serp_real_estate_previous_period).toFixed(0)}
                    </Text.GreenRegular14> :
                    <Text.RedRegular14
            
                    
                    >- {(result.serp_real_estate_previous_period - result.serp_real_estate_current ).toFixed(0)}</Text.RedRegular14>
                    }
                    </Content.FlexColumn> */}
                    
                    <Content.FlexRow
                        style={{
                            width: '20%'
                        }}
                    >
                         <Content.FlexRow style={{
                            width: '100%',
                            overflow:'auto'
                        }}>
                            <ProgressBarContainer growthMax={growth_max}  growthValue={(result.serp_real_estate_current - result.serp_real_estate_previous_period).toFixed(0)}/>
                        </Content.FlexRow> 
                        
                    </Content.FlexRow>
                </Content.HoverRow>
                
            : null
        ))}
    
      
      </Content.DropDownContainer>}
      {paginationLine()}
      
 
      </>
  )
}
