import { Link } from "react-router-dom";
import { Text, Content, Button, Welcome, MenuHeader } from "../styled-components/visualization";
import { HeaderContainer } from "../containers/visualization/welcomeComponents/Header";
import image from "../images/visualization/06.png"

export default function PageNotFound() {
    return (
        <Welcome.PurpleDiv style={{minHeight: '100vh'}}>
        <div style={{margin: '50px auto', width: '50%', textAlign: 'center', backgroundImage: 'url(../images/visualization/06.png)'}}>
            <h1 style={{color: 'white', fontSize: '150px'}}>404</h1>
            <h3 style={{color: 'white', textTransform: 'initial'}}>The page you are looking for cannot be found.</h3>
            <p style={{color: 'white'}}>Take me back to <Link to='/' style={{color: 'white'}}>dashboard</Link></p>
            
        </div>
        </Welcome.PurpleDiv>
    )
}