import styled from "styled-components"
import * as pallete from '../../../../constants/theme/visualization/colours'
import image from "../../../../images/visualization/06.png"


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: #F7F7F7;
`

export const PurpleDiv = styled.div`
    background-image: url(${image});
    min-height: 40vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 23%;
    position: relative;
    
    @media (${pallete.devices.desktop}) {
        padding: 3% 15%;
    }

    @media (${pallete.devices.laptop}) {
        padding: 3% 15%;
    }
`
export const SerprImage = styled.img`
    width: 30%;
    position: absolute;
    z-Index: 98;
    bottom: -60%;
    right: 8%;

    @media (${pallete.devices.mobile}) {
        display: none;
    }
`

export const WhiteDiv = styled.div`
    background: #F7F7F7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3% 23%;
    position: relative;
    overflow: hidden;

    @media (${pallete.devices.laptop}) {
        padding: 3% 15%;
    }

    @media (${pallete.devices.desktop}) {
        padding: 3% 15%;
    }
`



export const Big3Container = styled.div`
    width: 30%;
    background: white;
    padding: 30px;

    @media (${pallete.devices.mobile}) {
        width: 100%;
    }
`

export const FooterLeftImage = styled.img`
    position: absolute;
    bottom: 0px;
    left: -350px;

    @media (${pallete.devices.tablet}) {
        display: none;
    }
`

export const FooterRightImage = styled.img`
    position: absolute;
    bottom: 0px;
    
    right: -100px;
    height: 600px;
    overflow: hidden;

    @media (${pallete.devices.tablet}) {
        display: none;
    }
`



export const FooterRightImageForWebsiteComponent = styled.img`
    position: absolute;
    bottom: 0px;
    overflow: hidden;
    right: 0;
    height: 100px;

`

export const FooterRightImageForAC = styled.img`
position: absolute;
    bottom: 0px;
    
    right: -100px;
    height: 600px;
    overflow: hidden;

    @media (max-width:1300px) {
        display: none;
    }
`