import React from 'react'
import { Footer } from '../../styled-components/dashboard/'

export function FooterContainer({ children }) {

    function handleSerpr(){
        window.open('https://luminr.ai/', '_blank');
    }

    function handleTomorrow(){
        window.open('https://tomorrowgroup.co/', '_blank');
    }

    function handleCookiePolicy(){
        window.open('https://luminr.ai/cookie-policy/', '_blank');
    }

    function handlePrivacyPolicy(){
        window.open('https://luminr.ai/privacy-policy/', '_blank');
    }

    return (
     <Footer>
         <Footer.TextContainer>
            <Footer.Text 
            onClick={handleSerpr}
            >
            © 2023 Luminr.
            </Footer.Text>
            <div>
           <Footer.Line>
           &nbsp; | &nbsp;
           </Footer.Line>
           </div> 
            {/* <Footer.Text>
            All Rights Reserved. 
            </Footer.Text>
            <Footer.Line>
           &nbsp; | &nbsp;
           </Footer.Line> */}
            <Footer.Text
            onClick={handleTomorrow}
            >
            Luminr Is Part Of Tomorrow Group Limited
            </Footer.Text>
            <Footer.Line>
           &nbsp; | &nbsp;
           </Footer.Line>
            {/* <Footer.Text>
            Data Science
            </Footer.Text>
            <Footer.Line>
           &nbsp; | &nbsp;
           </Footer.Line>
            <Footer.Text >
            Data Analytics  
            </Footer.Text>
            <Footer.Line>
           &nbsp; | &nbsp;
           </Footer.Line> */}
            <Footer.Text
            onClick={handleCookiePolicy}
            >
            Cookie Policy
            </Footer.Text>
            <Footer.Line>
           &nbsp; | &nbsp;
           </Footer.Line>
            <Footer.Text
            onClick={handlePrivacyPolicy}
            >
            Privacy Policy      
            </Footer.Text>
         </Footer.TextContainer>
     </Footer>
    )
}


