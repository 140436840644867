import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listProfileDetails } from "../actions/profileActions";

import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const dispatch = useDispatch();

  const profileDetails = useSelector((state) => state.profileDetails);
  const { success, profile } = profileDetails;

  const [isSuperuser, setIsSuperuser] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [isCompanyAdmin, setIsCompanyAdmin] = useState(false)
  // const [isCompanyEmployee, setIsCompanyEmployee] = useState(false)

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };
    if (isAuthenticated) {
      getAccessToken();
      if (accessToken)
        if (success) {
          setIsSuperuser(profile.isSuperuser);
          setIsAdmin(profile.isAdmin);
          // setIsCompanyAdmin(profile.isCompanyAdmin)
          // setIsCompanyEmployee(profile.isCompanyEmployee)
        } else {
          dispatch(listProfileDetails(accessToken, user.email));
        }
    }
  }, [
    accessToken,
    isAuthenticated,
    success,
    dispatch,
    profile,
    user,
    getAccessTokenSilently,
  ]);

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/#/admin-console/home">Luminr</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {/* <LinkContainer to='/organisation'>
                            <Nav.Link> <i className='fas fa-building'></i> Organisation</Nav.Link>
                        </LinkContainer> */}
              {!isAuthenticated && (
                <LinkContainer to="/login">
                  <Nav.Link onClick={() => loginWithRedirect()}>
                    <i className="fas fa-user"> </i> Log In
                  </Nav.Link>
                </LinkContainer>
              )}

              {isAuthenticated && profile.name && (
                <LinkContainer to="/profile">
                  <Nav.Link>
                    {" "}
                    <i className="fas fa-user"> </i> {profile.name}
                  </Nav.Link>
                </LinkContainer>
              )}
              {isAuthenticated && !profile.name && (
                <LinkContainer to="/profile">
                  <Nav.Link>
                    {" "}
                    <i className="fas fa-user"> </i> {user.name}
                  </Nav.Link>
                </LinkContainer>
              )}
              {isAuthenticated && (isAdmin || isSuperuser) && (
                <LinkContainer to="/admin">
                  <Nav.Link>
                    {" "}
                    <i className="fas fa-user-cog"> </i> Admin Dashboard
                  </Nav.Link>
                </LinkContainer>
              )}

              {isAuthenticated && (
                <LinkContainer to="/">
                  <Nav.Link onClick={() => logout()}>
                    <i className="fas fa-sign-out-alt"> </i>
                    Logout
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
