import styled from "styled-components"
import {Link as ReachRouterLink} from "react-router-dom"

export const Container = styled.div`
    //width: 100%;
    letter-spacing: 0;   
    margin: 0;
`

export const MenuImg = styled.img`
    width: 9px;
    height: 9px;
    //object-fit: cover;
    color: black;

    &:hover{
        cursor:pointer;
    }
`

export const Menu = styled(ReachRouterLink)`
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    background: white;
     border: solid 2px #D4D4D4;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 0;
    margin-top: 20px;
    text-decoration: none;
    color: #222222;

    &:hover{
        text-decoration: none;
    color: #222222;
    }
`

export const Menu2 = styled.div`
    background: 'black';
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    background: white;
    border: solid 2px #D4D4D4;
    height: 36px;
    display: flex;  
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 0;
    margin-top: 20px;
    text-decoration: none;
    color: #222222;

    &:hover{
        text-decoration: none;
        color: #222222;
    }
`

export const MenuBackground = styled.div`
    background: #D4D4D4;
     border-radius: 4px 4px 4px 4px;
    height:36px;
    width: 36px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: solid 2px #D4D4D4;
    margin-left: -2px; 
`

export const Save = styled.button`
  //  display: block;
    min-width: 110px;
    height: 50px;
    color: #FFFFFF;
    background: #C51852;
    border-radius: 4px;
    border: 2px solid var(--unnamed-color-c51852);
    font-size: 18px;
    margin-left: 16px;
    width: fit-content;
`

export const Back = styled.button`
    display: block;
    width: 110px;
    height: 50px;
    color: #222222;
    background: #FFFFFF;
    border-radius: 4px;
    border: 2px solid #D4D4D4;
    font-size: 18px;
    margin-left: 16px;
`

export const Reset = styled.button`
    display: block;
    font-size: 18px;
    padding: 6px 10px;
    color: #FFFFFF;
    background: #841E5A;
    border: 2px solid #841E5A;
    border-radius: 4px;
   // margin-left: 10px;
    margin-bottom: 1rem;
`

export const Pink = styled.div`
    display: flex;
    padding: 7px 10px;
    height: 50px;
    color: #FFFFFF;
    background: #C51852;
    border-radius: 4px;
    border: 2px solid var(--unnamed-color-c51852);
    font-size: 18px;
    margin: auto;
    align-Items: center;
`

export const CloseModal = styled.div`
    display: flex;
    padding: 7px 7px;
    height: 20px;
    color: black;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    font-size: 17px;
    margin: auto;
    align-Items: center;
    font-weight:bold;
    position: absolute;
    right:6%;
    top:15%;
`

export const GoTo = styled.button`
    width: fit-content;
    margin: 0.5rem 0px 0px;
    height: 40px;
    padding: 0px 10px;
    background: transparent;
    border: 2px solid rgb(197, 24, 82);
    border-radius: 4px;
    color: rgb(34, 34, 34);
    font-weight: bold;
    font-size: 16px;

    &:hover{
        background: rgb(197, 24, 82);
        color: #FFF;
    }
`
