import styled from 'styled-components'
import * as pallete from '../../../constants/theme/colours'

export const Background = styled.div`
    width: 100%;
    height: 100%; 
    min-height: 100vh;
    background: url("https://luminr.ai/wp-content/themes/luminr/images/luminr-platform/new-bg.svg") bottom center;
    min-width: 400px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    // @media (max-width: 1030px) {
    //     background: url('/images/bg-dashes.png') center no-repeat,
    //     url('/images/dashed-pink.svg') top right 150px no-repeat;
    //     background-size: auto, 20px;
    // }
`

export const ContainerHeader = styled.div`
    padding: 10px 10% 10px;
    background-color: #FFFFFF;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
    display: grid;
    grid-template-columns: 1fr 1fr;
`

export const ContainerFooter = styled.div`
    font: normal normal normal calc(0.4rem + 0.4vw)/calc(0.5rem + 0.5vw) 'Work Sans';
    background-color: #D4D4D4;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0%;
    z-index: 10000;

    a {
        color:#222222;
    }
    a:hover {
        text-decoration: underline;
        cursor: pointer;
        color: #841E5A;
    }
`

export const Container = styled.div`
    display: flex;
    padding: 50px;
    margin: auto;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    padding-bottom: 120px;

    @media (max-width: 1000px) {
        padding: 70px 30px;
    }
`

export const Logo = styled.img`
    vertical-align: middle;
    border: 0;
    width: auto;
    height: 30px;

    @media (max-width: 768px) {
        height: 38px;
    }
`

export const IconLink = styled.img`
    position: absolute;
    top: 50px;
    right: 70px;
`

export const Title = styled.h1`
    /* text-transform: none;
    font-family: tenon, sans-serif;
    font-weight: bold;
    font-size: 52px;
    color: ${pallete.BLACK};
    letter-spacing: 0px;
    padding: 20px 0 15px;
    margin: 0; */
    text-transform: none;
    font: normal normal normal calc(1rem + 1.6vw)/calc(1rem + 2.2vw) 'Work Sans';
    font-weight: 700;
    margin: 0;
    padding: 4vw 0 1vw;
    text-align: center;
    letter-spacing: 0px;
    // @media (max-width: 768px) {
    //     font-size: 88px;
    //     line-height: 94px;
    // }
`

export const MainColor = styled.span`
    color: ${pallete.PINK};
`

export const Text = styled.div`
    /* font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    color: ${pallete.BLACK};
    letter-spacing: 0px;
    padding-top: 15px;
    padding-bottom: 30px;
    width: 352px; */
    font: normal normal normal calc(0.5rem + 0.8vw)/calc(1rem + 0.5vw) 'Work Sans';
    font-weight: 500;
    color: var(--black);
    padding: 0 0 1.5vw;
    text-align: center;
    letter-spacing: -0.5px;
`

export const HeaderText = styled.div`
    font: normal normal normal calc(0.5rem + 0.5vw)/calc(0.5rem + 0.6vw) 'Work Sans';
    display: initial;
    padding-left: 10px;
    vertical-align: middle;
    color: #000000;
    a {
        color: #000000;
    }
    a:hover {
        color: #841E5A;
    }
`

export const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D4D4D4;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 4px 4px 4px 4px;

    &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 0px;
        bottom: 0px;
        background-color: #C51852;
        opacity: var(--before-opacity, 0.5);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 4px 4px 4px 4px;
        box-shadow: inset -3px 3px 2px #00000029;
    }
`

export const LineUp = styled.div`
  
    height: 30px;
    margin-right: 50%;
    border-right: 2px solid  ${pallete.GRAY};
    border-right: 2px solid ${(props) => (props.isActive ? '#D4D4D4' : '#C51852')};
`

export const Border = styled.div`
    position: relative;

    /* &:focus-within::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 10px solid white;
        border-right: 10px solid transparent;
    }

    &:focus-within::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        border-bottom: 10px solid white;
        border-left: 10px solid transparent;
    } */

    #lineUp + &:focus-within {
        border-color: ${pallete.PINK};
      }
`

export const InputGroup = styled.div`
    position: relative;
    text-align: left;

    &:focus-within {
        color: ${pallete.PINK};
    }
    
`

export const InputContainer = styled.div`
    position: relative;
`

export const Label = styled.label`
    font-family: 'Work Sans';
    font-size: 13px;
    /* line-height: 30px; */
    color: #281736;
    /* margin-bottom: 0; */
    margin: 5px 0;
`

// #a:hover ~ #b {
//     background: #ccc
// }


export const Input = styled.input`
    font: normal normal normal calc(0.5rem + 0.8vw)/calc(1rem + 0.5vw) 'Work Sans';
    border-radius: 4px !important;
    border: 2px solid #E8E8E8;
    padding: 0.8vw 1vw;
    color: #000000;
    display: inline;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-bottom: 10px;
    width: -webkit-fill-available;

    &:focus, &:focus-visible {
        color: #000000;
        border: 2px solid #C51852;
        box-shadow: none;
        -webkit-box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: rgba(0, 0, 0, 0.2);
        font: normal normal normal calc(0.5rem + 0.8vw)/calc(1rem + 0.5vw) 'Work Sans';
    }

    &:focus::placeholder {
        color: #000000;
    }

    /* &:not(:placeholder-shown) {
        border-color: ${pallete.PINK};
    }
    // + ${LineUp} { border-color: ${pallete.PINK}; }

    &:hover ~ ${LineUp} {
        border-right: 2px solid blue;
    } */

    // ${InputGroup}:not(:placeholder-shown) & {
    //     color: ${pallete.PINK} !important;
    // }

    // ${InputGroup}:valid & {
    //     color: ${pallete.PINK}
    //   }

`

export const Textarea = styled.textarea`
    font: normal normal normal calc(0.5rem + 0.8vw)/calc(1rem + 0.5vw) 'Work Sans';
    border-radius: 4px !important;
    border: 2px solid #E8E8E8;
    padding: 0.8vw 1vw;
    color: #000000;
    display: inline;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-bottom: 10px;
    width: -webkit-fill-available;
    height: 13vw;

    &:focus, &:focus-visible {
        color: #000000;
        border: 2px solid #C51852;
        box-shadow: none;
        -webkit-box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: rgba(0, 0, 0, 0.2);
        /* font: normal normal normal calc(0.5rem + 0.8vw)/calc(1rem + 0.5vw) 'Work Sans'; */
        font-family: 'Work Sans';
        font-size: 17px;
    }

    &:focus::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
`

export const Select = styled.select`

    font: normal normal normal calc(0.5rem + 0.8vw)/calc(1rem + 0.5vw) 'Work Sans';
    border-radius: 4px !important;
    border: 2px solid #E8E8E8;
    padding: 0.8vw 1vw;
    color: #000000;
    display: inline;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-bottom: 10px;
    width: -webkit-fill-available;

    &:focus, &:focus-visible {
        color: #000000;
        border: 2px solid #C51852;
        box-shadow: none;
        -webkit-box-shadow: none;
        outline: none;
    }

    ::placeholder {
        color: rgba(0, 0, 0, 0.2);
        font: normal normal normal calc(0.5rem + 0.8vw)/calc(1rem + 0.5vw) 'Work Sans';
    }

    &:focus::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
    &::after {
    content: "";
    justify-self: end;
    }
`

export const SelectList = styled.div``


export const Tooltip = styled.div`
    font-family: 'Work Sans';
    font-size: 12px;
    letter-spacing: 0px;
    display: inline-block;
    position: absolute;
    color: #FFF;
    background-color: #C51852;
    border-radius: 4px;
    text-align: left;
    min-width: 187px;
    margin-left: 16px;
    padding: 18px;
    top: 0%;
    left: 100%;

    ${InputContainer}:hover & {
        visibility: visible;
    }

    &:before {
        content: '';
        position: absolute;
        right: 100%;
        border-width: 10px 10px 13px 10px;
        border-style: solid;
        border-color: #C51852 transparent transparent transparent;
        opacity: 1;
        transform: translatey(-50%) rotate(90deg);
    }

    &:after {
        position: relative;
        background-color: ${pallete.LIGHT_GRAY};
        border-radius: 4px;
        padding: 18px;
        opacity: 1;
        transition: opacity ease 0.3s;
    }
`

export const Dropdown = styled.select`
    width: 358px;
    font-size: 17px;
    font-family: 'Work Sans', sans-serif;
    letter-spacing: 0px;
    border-radius: 0px !important;
    border: 2px solid ${pallete.GRAY};
    height: unset;
    padding: 13px 30px;

    &:focus-within {
        color: rgba(0, 0, 0, 1);
        outline: none;
        border-color: ${pallete.PINK};
        border-bottom: 10px solid ${pallete.PINK};
    }

    ::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }

    &:focus::placeholder {
        color: rgba(0, 0, 0, 1);
    }

    &:not(:placeholder-shown) {
        border-color: ${pallete.PINK};
    }

    &:hover ~ ${LineUp} {
        border-right: 2px solid blue;
    }
`

export const ButtonGroup = styled.div`
    margin-top: 20px;
`

export const ButtonNext = styled.button`
    font-family: tenon, sans-serif;
    font-size: 18px;
    letter-spacing: 0px;
    padding: 0.8vw 1.6vw;
    background-color: #841E5A;
    color: #FFFFFF;
    border: 2px solid #841E5A;
    border-radius: 4px;
    width: -webkit-fill-available;
`

export const ButtonBack = styled.button`
    font-family: tenon, sans-serif;
    font-size: 18px;
    letter-spacing: 0px;

    background-color: ${pallete.WHITE};
    cursor: pointer;

    border: 2px solid ${pallete.PINK};
    border-radius: 4px;
    margin-right: 8px;
    padding: 14px 63px;

    &:hover {
        background-color: ${pallete.PINK};
        color: ${pallete.WHITE};
    }
`

export const LineDown = styled.div`
    border-left: 1px solid ${pallete.PINK};
    height: 265px;
    position: relative;
`

export const LineBottom = styled.div`
    border-bottom: 10px solid ${pallete.PINK};
    width: 500px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        border-bottom: 10px solid ${pallete.WHITE};
        border-left: 10px solid ${pallete.PINK};
        width: 0;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        border-bottom: 10px solid ${pallete.WHITE};
        border-right: 10px solid ${pallete.PINK};
        width: 0;
    }
`
// Dropdown
export const DropDownContainer = styled.div`
    text-align: left;
    margin-bottom: 10px;
`

export const DropDownHeader = styled.div`
    font-family: 'Work Sans', sans-serif;
    font-size: 17px;
    letter-spacing: 0px;
    border-radius: 4px;
    border: 2px solid ${pallete.GRAY};
    height: unset;
    padding: 0.8vw 1vw;
    position: relative;
    text-align: left;
    background: ${pallete.WHITE};
    color: #d4d4d4;

    &:focus {
        color: ${pallete.PINK};
    }

    &:focus-within {
        border: 2px solid ${pallete.PINK};
    }
    
`

export const DropDownListContainer = styled.div`
    position: absolute;
    width: -webkit-fill-available;
    z-index: 100;

    background: #F6F6F6;
 
`

export const DropDownList = styled.ul`
    padding: 0;
    margin: 0;

    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
    font-size: 17px;
    letter-spacing: 0px;
    text-align: left;

    height: 235px;
    overflow: auto;

    border: 2px solid #46464611;
    border-radius: 4px;
    height: auto;
    background: #FFFFFF;

    /* width */
    /* ::-webkit-scrollbar {
        width: 8px;
    } */

    /* Track */
    /* ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 2px 0px 0px ${pallete.SHADOW_GRAY};
        background: ${pallete.LIGHT_GRAY};
    } */

    /* Handle */
    /* ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 2px 0px 0px ${pallete.SHADOW_GRAY};
        background: ${pallete.PINK};
    } */
`

export const ListItem = styled.li`
    list-style: none;
    border-bottom: 2px solid ${pallete.WHITE};
    margin: 0.8vw 1vw 0.4vw;
    padding-bottom: 0.4vw;
    background: #FFFFFF;
    border-bottom: 2px solid #46464611;
    cursor: pointer;

    &:last-child {
        border-bottom: 0px solid ${pallete.WHITE};
    }
`

export const DropdownIcon = styled.img`
    background-color: ${pallete.WHITE};
    width: 17px;
    height: 8px;
    float: right;
    margin-top: 8px;

    &:focus-within {
        
        background-color: ${pallete.WHITE};
    }
    // ${InputGroup}:valid & {
        //     color: ${pallete.PINK}
        //   } 
`

export const Link = styled.a`
    color: ${pallete.PINK};
    cursor: pointer;
`