import styled from "styled-components";

export const HorizontalLineGrey = styled.p`
  border-top: 1px solid #707070;
  opacity: 0.23;
  width: 100%;
`;

export const HorizontalDashLineGrey = styled.p`
  border-top: 1px dashed #707070;
  opacity: 0.23;
  width: 100%;
`;

export const PanelWidth = styled.p`
  width: 60%;
`;

export const GoogleImg = styled.img`
  height: 6vw;
`;

export const GoogleSearchImg = styled.img`
  height: 4vw;
  width: 20vw;
`;
