import React, { createContext, useState, useEffect } from 'react'


export const CompetitorMovementsContext = createContext()
export default function CompetitorMovementsProvider(props)  {
  

    var serpFeatureFilterArray = ['Ads(Top-of-Page)', 'Organic Results'];
    var movementsFilterArray = ['Stable', 'Riser','Newcomer' , 'Dropout','Decliner'];
    var scaleFilterArray = ['Major', 'Minor'];

    //filters for api call
    const [filterSerpFeature, setFilterSerpFeature] = useState(['Organic Results']);
    const [filterMovements, setFilterMovements] = useState(['Stable', 'Riser','Newcomer' , 'Dropout','Decliner']);
    const [filterScale, setFilterScale] = useState(['Major', 'Minor']);



  
    return (
         <CompetitorMovementsContext.Provider 
         value={{
            serpFeatureFilterArray,
            movementsFilterArray,
            scaleFilterArray,
            filterSerpFeature,setFilterSerpFeature,
            filterMovements,setFilterMovements,
            filterScale,setFilterScale
         }}
         >
               {props.children}
         </CompetitorMovementsContext.Provider>
    )
}
