import React, {useEffect, useState} from "react";

import { Text, Content } from "../../../styled-components/visualization";
import { PaidSearchRealTimeChartContainer } from "../charts/PaidSearchRealTimeChart";
import { CounterContainer } from "../utilComponents/Counter";
import { TitleContainer } from "../utilComponents/Title";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";
import LoaderImage from "../../../components/LoaderImage";
import ErrorMessage from "../../../components/ErrorMessage";
import { ProgressBarContainer } from "../utilComponents/ProgressBar";
import { COLOR_PALETTE_1, COLOR_PALETTE_2, COLOR_PALETTE_3, COLOR_PALETTE_4, COLOR_PALETTE_5, COLOR_PALETTE_6, COLOR_PALETTE_7, COLOR_PALETTE_8 } from "../../../constants/theme/colours";


export function PaidSearchThreatsContainer({loadingHvnScore,successHvnScore,errorHvnScore,hvnScoreResults,hvnScoreResultsTotalRows,pageNoPaidSearchThreats,setPageNoPaidSearchThreats}) {
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [colorIntervals, setColorIntervals] = useState([])
    const [growthMax, setGrowthMax] = useState(0);

    // let growth_max = 0 ;
  
    const nextPage = () => {
        if (pageNoPaidSearchThreats < numberOfPages) {
            setPageNoPaidSearchThreats(pageNoPaidSearchThreats + 1)
          }
      }
  
    const previousPage = () => {
        if (pageNoPaidSearchThreats > 1) {
            setPageNoPaidSearchThreats(pageNoPaidSearchThreats -1)
        } 
    }

    const paginationLine = () =>{
        return (
            <Content.FlexRow
            style ={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '3%'
            }}
         >
         <Content.PaginationIcon
         onClick={()=>previousPage()}
         src={require("../../../images/visualization/left_arrow.svg").default}/>
         {pageNoPaidSearchThreats !== numberOfPages ?
          <Text.Regular13Grey>{(pageNoPaidSearchThreats-1)*100+1} - {pageNoPaidSearchThreats*100} / {hvnScoreResultsTotalRows}</Text.Regular13Grey> :
          <Text.Regular13Grey>{(pageNoPaidSearchThreats-1)*100+1} - {hvnScoreResultsTotalRows} / {hvnScoreResultsTotalRows}</Text.Regular13Grey>
         }
         
         <Content.PaginationIcon
        onClick={() =>nextPage()}
         src={require("../../../images/visualization/right_arrow.svg").default}/>
         </Content.FlexRow>
        )
    }
    const csvHeader = [
        { label: "Website", key: "domain" },
        { label: "Aggro Score", key: "hvn_score" },
        { label: "Keywords", key: "num_keywords" },
        { label: "Top-of-Page Rate", key: "top_of_page_rate" },
        { label: "Avg. Position", key: "avg_rank" }
    ];


    useEffect(()=>{
        if(hvnScoreResultsTotalRows){
            setNumberOfPages(Math.ceil(hvnScoreResultsTotalRows/100))
        }

        let maxValue = 0;
        if(hvnScoreResults && hvnScoreResults.detail == undefined){
            hvnScoreResults.map((result, i) => {
                if(result.hvn_score > maxValue) maxValue = result.hvn_score;

                //maxGrowth
                const growth_val = result.num_keywords;
                if(growth_val > growthMax){
                    // growth_max = growth_val.toFixed(0);
                    setGrowthMax(growth_val.toFixed(0))
                }
            })
            createMaxValueIntervals(maxValue);

            
        }
        

    },[hvnScoreResultsTotalRows,hvnScoreResults,growthMax])

    const createMaxValueIntervals = (maxVal) =>{
        // Calculate the size of each interval
         const intervalSize = maxVal / 8;
    
         // Create the intervals with colors
         const intervals = [];
         for (let i = 1; i <= 8; i++) { // 8 intervals
         const intervalStart = intervalSize * (i - 1);
         const intervalEnd = intervalSize * i;
         let color;
         switch (i) {
             case 1:
             color = COLOR_PALETTE_1;
             break;
             case 2:
             color = COLOR_PALETTE_2;
             break;
             case 3:
             color = COLOR_PALETTE_3;
             break;
             case 4:
             color = COLOR_PALETTE_4;
             break;
             case 5:
             color = COLOR_PALETTE_5;
             break;
             case 6:
             color = COLOR_PALETTE_6;
             break;
             case 7:
             color = COLOR_PALETTE_7;
             break;
             case 8:
             color = COLOR_PALETTE_8;
             break;
             default:
             color = 'black'; // or any default color
         }
         intervals.push({ start: intervalStart, end: intervalEnd, color });
         }
    
    
         setColorIntervals(intervals);
      }

    const table = () => {
        return(
            <> 
            <Content.FlexRow
        style={{
            justifyContent:'space-between',
            width: '99%',
            padding: '1%'
        }} 
      > 
          <Content.FlexColumn  style={{ width: '30px'  }}> </Content.FlexColumn>
          <Text.Regular13Grey style={{width:'25%'}}>Website</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Aggro Score</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Keywords</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Top-of-Page Rate</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Avg. Position</Text.Regular13Grey>
      </Content.FlexRow>
      <Content.DropDownContainer
        style={{
            marginTop:'2%',
            height: '400px'
        }}
      >

      {hvnScoreResults.map((result,i)=>(
            <Content.HoverRow>
            <Content.FlexColumn style={{ width: '30px' }}>
            
                <CounterContainer number={(pageNoPaidSearchThreats-1)*100+i+1} />
                </Content.FlexColumn>
            <Text.Regular14Black style={{width:'25%' }}>{result.domain}</Text.Regular14Black>

            {
                colorIntervals.map((range, i) => (
                    result.hvn_score > range.start && result.hvn_score <= range.end && 
                        <Text.Regular14WhitePurpleLightBackground style={{width: '10%',background:range.color}}>
                        {result.hvn_score.toFixed(0)}
                    </Text.Regular14WhitePurpleLightBackground> 
                ))
            }
            
            {/* <Text.Regular14Black style={{width:'10%', textAlign: 'center',}}>{result.total_keywords}</Text.Regular14Black> */}
            <ProgressBarContainer style={{width:'10%', textAlign: 'center',}} onlyOneSide={true} growthMax={growthMax}  growthValue={result.num_keywords}/>
            <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{(result.top_of_page_rate*100).toFixed(0)}%</Text.Regular14Black>
            <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{result.avg_rank.toFixed(2)}</Text.Regular14Black>
            </Content.HoverRow>
       
      ))}
      
      </Content.DropDownContainer>
      {paginationLine()}
      </>
        )
    }

    

  return (
    <Content.FlexColumn
    style={{
            justifyContent: 'space-between',
            padding: '1%',
            background: 'white',
        }}
    >
        <TitleContainer 
            title='Paid Threats' 
            subtitle="Experimental ranking system for paid search competitors showing who you are up against in the biddable space."
            backdropTop = '135vw'         
            pages={insightsText.PaidSearchThreats[0]}
            p11={insightsText.PaidSearchThreats[1]}
            p21={insightsText.PaidSearchThreats[2]}
            p31={insightsText.PaidSearchThreats[3]}
            p41={insightsText.PaidSearchThreats[4]}
            assistantMessage={assistantText.PaidSearchThreats}
            csvData={hvnScoreResults}
            csvHeader={csvHeader}
            />

            <Content.FlexColumn className={"justifyContentCenter"} >
            {
                loadingHvnScore || !successHvnScore 
                ? 
                <LoaderImage height={'550px'}/> : 
                errorHvnScore 
                ? 
                <ErrorMessage /> :
                    hvnScoreResults.length != 0 ? 
                    hvnScoreResults.detail == undefined ? 
                    <Content.FlexRow
                style={{
                    justifyContent:'space-between',
                    marginTop:'2%'
                }}
            >
            <Content.FlexColumn
                style={{
                    width: '55%',
                    height:'550px'
                }}
            >
                {table()}
            </Content.FlexColumn>
            <Content.FlexColumn
                style={{
                    width: '40%'
                }}
            >
                <PaidSearchRealTimeChartContainer hvnScoreResults={hvnScoreResults} />
            </Content.FlexColumn>
            
                    </Content.FlexRow>  : <ResultsMissing details = {hvnScoreResults.detail}/>
                    : 
                    <ResultsMissing details = {hvnScoreResults.detail}/>
            }
            </Content.FlexColumn>
            

           
       
    </Content.FlexColumn>
  )
}
