import React, {useEffect} from "react";
import { AddCompanyContainer } from "./addCompany";
import { AddKeywordsContainer } from "./addKeywords";
import { AddKeywordsCopyContainer } from "./addKeywordsCopy";
import { AddKeywordsSuccessContainer } from "./addKeywordsSuccess";
import { AddKeywordsUploadContainer } from "./addKeywordsUpload";
import { AddKeywordsGenerateContainer } from "./addKeywordsGenerate";
import { AddWebsiteContainer } from "./addWebsite";
import { FooterContainer } from "./footer";
import { HeaderContainer } from "./header";
import { HomeContainer } from "./home";
import { MenuContainer } from "./menu";
import { ProfileContainer } from "./profile"; 
import { BillingContainer } from "./billing";
import { BulkAddUsersContainer } from "./bulkAddUsers";
import { CompaniesContainer } from "./companies";
import { DeleteCompanyContainer } from "./deleteCompany";
import { DeleteWebsiteContainer } from "./deleteWebsite";
import { DeleteUserContainer } from "./deleteUser";
import { EditCompanyContainer } from "./editCompany";
import { EditKeywordsContainer } from "./editKeywords";
import { EditUserContainer } from "./editUser";
import { EditWebsiteContainer } from "./editWebsite";
import { InboxContainer } from "./inbox";
import { InviteUserContainer } from "./inviteUser";
import { KeywordsContainer } from "./keywords";
import { OrganisationContainer } from "./organisation";
import { OrganisationSecurityContainer } from "./organisationSecurity";
import { PricingPageContainer } from "./pricingPage";
import { SettingsContainer } from "./settings";
import { UsersContainer } from "./users";
import { WebsitesContainer } from "./websites";
import { UserAdminContainer } from "./SerprAdmin/usersAdmin";
import { UserPreferencesContainer } from "./SerprAdmin/usersPreferences";
import { UsersAuth0Container } from "./SerprAdmin/usersAuth0";
import { Content } from "../../styled-components/visualization";
import { useHistory } from 'react-router-dom'
import { DashboardsAdminContainer } from "./SerprAdmin/dashboardsAdmin";
import { AddCompetitorsContainer } from "./addCompetitors";
import { AddKeywordsProfileContainer } from "./addKeywordsProfile";
import { KeywordsAdminContainer } from "./SerprAdmin/keywordsAdmin";

export function TemplateContainer({ pageName, email, name, accessToken, isActive, isSuperuser, user }) {
  let history = useHistory()
  
  useEffect(() => {
    if (accessToken  ) {
        //if account is active
      if (name === undefined) {
        history.push("/profile-sign-up");
      } else {
        if(isActive=== undefined && isSuperuser === undefined){
        
        } else{
          if(isActive){
          }
          else {

                history.push(`/account-not-active`)
          }
        } 
      }

     
       
    }
}, [
    accessToken,
    email,
    isActive,
    isSuperuser
])
  
  return (
    <>
    {isActive &&
    <Content.FlexRow
      style={{
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          
        }}
      >
        <MenuContainer email={email} name={name} isSuperuser={isSuperuser}/>
      </div>
      <Content.FlexColumn
        style={{
          width: "100%",
          justifyContent: "space-between",
          minWidth: '1000px'
        }}
      >
        <HeaderContainer
          pageName={pageName}
          email={email}
          name={name}
          accessToken={accessToken}
          isActive={isActive}
          isSuperuser={isSuperuser}
        />
        {pageName === "Profile" && (
          <ProfileContainer
            accessToken={accessToken}
            name={name}
            email={email}
            user={user}
          />
        )}
        {pageName === "Home" && (
          <HomeContainer accessToken={accessToken} name={name} email={email} />
        )}
        {pageName === "Add Company" && (
          <AddCompanyContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Add Competitors" && (
          <AddCompetitorsContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Add Keywords - Copy and Paste" && (
          <AddKeywordsCopyContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Add Keywords Profile" && (
          <AddKeywordsProfileContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Success" && (
          <AddKeywordsSuccessContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Add Keywords - Upload CSV" && (
          <AddKeywordsUploadContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Add Keywords - Generate" && (
          <AddKeywordsGenerateContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Add Keywords" && (
          <AddKeywordsContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Set up a new website" && (
          <AddWebsiteContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Billing" && (
          <BillingContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Add a new user" && (
          <BulkAddUsersContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Companies" && (
          <CompaniesContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Delete Company" && (
          <DeleteCompanyContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Delete Website" && (
          <DeleteWebsiteContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Delete User" && (
          <DeleteUserContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Edit Company" && (
          <EditCompanyContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Edit Keywords" && (
          <EditKeywordsContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Edit User" && (
          <EditUserContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Edit Website" && (
          <EditWebsiteContainer
            accessToken={accessToken}
            name={name}
            email={email}
            isSuperuser={isSuperuser}
          />
        )}
        {pageName === "Inbox" && (
          <InboxContainer accessToken={accessToken} name={name} email={email} />
        )}
         {pageName === "Pricing" && (
          <PricingPageContainer accessToken={accessToken} name={name} email={email} />
        )}
        {pageName === "Invite User" && (
          <InviteUserContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Keywords" && (
          <KeywordsContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Organisation" && (
          <OrganisationContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Organisation Security" && (
          <OrganisationSecurityContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Settings" && (
          <SettingsContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Users" && (
          <UsersContainer accessToken={accessToken} name={name} email={email} />
        )}
        {pageName === "Websites" && (
          <WebsitesContainer
            accessToken={accessToken}
            name={name}
            email={email}
          />
        )}
        {pageName === "Users from Luminr Platform" && (
          <UserAdminContainer
            accessToken={accessToken}
            name={name}
            email={email}
            isSuperuser={isSuperuser}
          />
        )}
        {pageName === "Users Preferences" && (
          <UserPreferencesContainer
            accessToken={accessToken}
            name={name}
            email={email}
            isSuperuser={isSuperuser}
          />
        )}
        {pageName === "Users Logs" && (
          <UsersAuth0Container
            accessToken={accessToken}
            name={name}
            email={email}
            isSuperuser={isSuperuser}
          />
        )}
        {pageName === "All Luminr Dashboards" && (
          <DashboardsAdminContainer
            accessToken={accessToken}
            name={name}
            email={email}
            isSuperuser={isSuperuser}
          />
        )}
        {pageName === "Clients keywords" && (
          <KeywordsAdminContainer
            accessToken={accessToken}
            name={name}
            email={email}
            isSuperuser={isSuperuser}
          />
        )}

        <FooterContainer />
      </Content.FlexColumn>
    </Content.FlexRow>}
    </>
  );
}
