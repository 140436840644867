import React, { useEffect, useState } from "react";
import ResultsMissing from "../../../components/ResultsMissing";
import {
  BackDrop,
  BackDropAbsolute,
} from "../../../styled-components/general/styles/general";
import {
  Text,
  Content,
} from "../../../styled-components/visualization";
import { Regular18Purple } from "../../../styled-components/visualization/text/styles/text";
import { HelpAssistantContainer } from ".././HelpAssitant";
import { nFormatter } from "../resultsComponents/serpScoreboard/sortingUtils";
import { assistantText } from "../utilComponents/InsightsText";
import { useHistory } from "react-router-dom"

export function InfoLineContainer({ 
  totalSearchCtrResults,
  serpRealEstateScoreResults,
  industryBenchmarksStatsResults,
  serprScoreResults
}) {
  let history = useHistory()
  //line info
  const [toggleRanking, setToggleRanking] = useState(false);
  const [toggleAverage, setToggleAverage] = useState(false);
  const [toggleSERPRealEstate, setToggleSERPRealEstate] = useState(false);
  const [toggleSERPRScore, setToggleSERPRScore] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [serprScore, setSerprScore] = useState(0);
  let scoreR = 0;

  let evolutionIndustryAverage = 0;
  if(industryBenchmarksStatsResults[0] && totalSearchCtrResults[0]){
    evolutionIndustryAverage = (totalSearchCtrResults[0].total_search_ctr - industryBenchmarksStatsResults[0].industry_avg_ctr)/industryBenchmarksStatsResults[0].industry_avg_ctr;
    evolutionIndustryAverage = (evolutionIndustryAverage * 100).toFixed(2);
  }

  let evolutionSerprScore = 0;
  if(serpRealEstateScoreResults[0]  && serpRealEstateScoreResults[0].serp_real_estate_previous_period > 0){
    evolutionSerprScore = (serpRealEstateScoreResults[0].serp_real_estate - serpRealEstateScoreResults[0].serp_real_estate_previous_period)/serpRealEstateScoreResults[0].serp_real_estate_previous_period;
    evolutionSerprScore = parseFloat((evolutionSerprScore * 100).toFixed(2));
  }

  const setLayerQuestion = (title) => {
    console.log(history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100))
    
    window.dataLayer.push({
      event: "button_click",
      button_type: "help",
      element_name: title.toLowerCase(),
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
    
  }



  useEffect (()=>{
      serprScoreResults[0] && setSerprScore(serprScoreResults[0].serpr_score)

  },[serprScoreResults])

  const title = () => {
    return (
      <Content.FlexRow
        style={{
          justifyContent: "space-between",
          marginTop: "3%",
          padding: "0 1%",
        }}
      >

        <Content.FlexRow
          style={{
            width: "22%",
          }}
        >
            {modalBackdrop ? (
            <BackDropAbsolute
              onClick={() => {
                setToggleRanking(false);
                setToggleAverage(false);
                setToggleSERPRealEstate(false);
                setToggleSERPRScore(false);
                setModalBackdrop(false);
              }}
            />
          ) : (
            <BackDrop />
          )}
          <Regular18Purple>Average CTR</Regular18Purple>
          {toggleAverage ? (
            <Content.RelativeDiv>
              <Content.Icon
                onClick={() => setToggleAverage(!toggleAverage)}
                src={
                  require("../../../images/visualization/Help_Assistant_filled.svg")
                    .default
                }
              />
              <HelpAssistantContainer 
              title={"Average CTR?"} 
              assistantMessage={assistantText.AverageCTR}
              />
            </Content.RelativeDiv>
          ) : (
            <Content.Icon
              onClick={() => {
                setToggleAverage(!toggleAverage);
                setModalBackdrop(true);
                setLayerQuestion('Average CTR')
              }}
              src={
                require("../../../images/visualization/Help_Assistant.svg")
                  .default
              }
            />
          )}
        </Content.FlexRow>

        <Content.FlexRow
          style={{
            width: "22%",
          }}
        >
          <Regular18Purple>SERP Real Estate</Regular18Purple>
          {toggleSERPRealEstate ? (
            <Content.RelativeDiv>
              <Content.Icon
                onClick={() => setToggleSERPRealEstate(!toggleSERPRealEstate)}
                src={
                  require("../../../images/visualization/Help_Assistant_filled.svg")
                    .default
                }
              />
              <HelpAssistantContainer 
              title={"SERP Real Estate?"} 
              assistantMessage={assistantText.SERPRealEstate}
              
              />
            </Content.RelativeDiv>
          ) : (
            <Content.Icon
              onClick={() => {
                setToggleSERPRealEstate(!toggleSERPRealEstate);
                setModalBackdrop(true);
                setLayerQuestion('SERP Real Estate')
              }}
              src={
                require("../../../images/visualization/Help_Assistant.svg")
                  .default
              }
            />
          )}
        </Content.FlexRow>
        <Content.FlexRow
          style={{
            width: "22%",
          }}
        >
          <Regular18Purple>Serpr Score</Regular18Purple>
          {toggleSERPRScore ? (
            <Content.RelativeDiv>
              <Content.Icon
                onClick={() => setToggleSERPRScore(!toggleSERPRScore)}
                src={
                  require("../../../images/visualization/Help_Assistant_filled.svg")
                    .default
                }
              />
              <HelpAssistantContainer 
              title={"SERPR Score?"} 
              assistantMessage={assistantText.SERPRScore}
              />
            </Content.RelativeDiv>
          ) : (
            <Content.Icon
              onClick={() => {
                setToggleSERPRScore(!toggleSERPRScore);
                setModalBackdrop(true);
                setLayerQuestion('SERPR Score')
              }}
              src={
                require("../../../images/visualization/Help_Assistant.svg")
                  .default
              }
            />
          )}
        </Content.FlexRow>
      </Content.FlexRow>
    );
  };

  return (
    <>
      {title()}
      <Content.FlexRow
        style={{
          justifyContent: "space-between",
          padding: "0% 1%",
        }}
      >
        
        {/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}
        {/* <Content.FlexColumn
          style={{
            width: "22%",
          }}
        >

        {trackingSummaryResults[0].ranking_domains_discovered.length != 0? 
            
            <Text.Bold50Black>
              {trackingSummaryResults[0].ranking_domains_discovered.toLocaleString()}
            </Text.Bold50Black>
              :
              <ResultsMissing details = {trackingSummaryResults[0].ranking_domains_discovered.detail}/>
        }


        </Content.FlexColumn> */}
        {/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}


        <Content.FlexColumn
              style={{
                width: "22%",
              }}
            >
        {totalSearchCtrResults[0] ? 
              <>
                <Content.FlexRow
                  style={{
                    alignItems: "end",
                  }}
                >
                  <Text.Bold50Black>
                    {(totalSearchCtrResults[0].total_search_ctr * 100).toFixed(2)}
                  </Text.Bold50Black>
                  <Text.Bold30Black>%</Text.Bold30Black>
                </Content.FlexRow>
      
                <Content.FlexRow
                  style={{
                    alignItems: "center",
                  }}
                >
                  {evolutionIndustryAverage >0 ? 
                  <Text.GreenRegular14>+{evolutionIndustryAverage}%</Text.GreenRegular14> :
                  <Text.RedRegular14>{evolutionIndustryAverage}%</Text.RedRegular14>
                  }
                
                  <Text.Regular14Black>on Industry average</Text.Regular14Black>
                </Content.FlexRow>
              </>
              :
              <ResultsMissing details = {'noimage'}/>
        }
        </Content.FlexColumn>


        {/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}
        <Content.FlexColumn
          style={{
            width: "22%",
          }}
        >
          {serpRealEstateScoreResults[0] && serpRealEstateScoreResults[0].serp_real_estate  ?
            <>
              <Content.FlexRow
                style={{
                  alignItems: "end",
                }}
              >
              <Text.Bold50Black>
                {nFormatter(serpRealEstateScoreResults[0].serp_real_estate) }
              </Text.Bold50Black>
             
              </Content.FlexRow>
              <Content.FlexRow
                style={{
                  alignItems: "center",
                }}
              >
                {evolutionSerprScore > 0 ? 
                <Text.GreenRegular14>+{nFormatter(evolutionSerprScore)}%</Text.GreenRegular14>
                  :
                  <Text.RedRegular14>{nFormatter(evolutionSerprScore)}%</Text.RedRegular14>
                }
                <Text.GreenRegular14>
                </Text.GreenRegular14>
                <Text.Regular14Black>from previous 30 days</Text.Regular14Black>
              </Content.FlexRow>
            </>
           
          :
          <ResultsMissing  details = {'noimage'}/>
        }
        </Content.FlexColumn>

        {/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}
        <Content.FlexColumn
          style={{
            width: "22%",
          }}
        >
          <Content.FlexRow
            style={{
              alignItems: "end",
            }}
          >
            <Text.Bold50Black>{serprScore}</Text.Bold50Black>
            <Text.Bold30Black>/100</Text.Bold30Black>
          </Content.FlexRow>
          {/* <Content.FlexRow
            style={{
              alignItems: "center",
            }}
          >
            <Text.RedRegular14>-0.2</Text.RedRegular14>
            <Text.Regular14Black>from previous 30 days</Text.Regular14Black>
          </Content.FlexRow> */}
        </Content.FlexColumn>
        {/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */}
      </Content.FlexRow>
    </>
  );
}
