import React, {useState, useEffect} from "react";
import {emailRegex} from '../../../constants/regex/regex'
import { useHistory } from 'react-router-dom'
import { createProfile, listProfiles, updateProfileByAdmin } from '../../../actions/profileActions'
import { useSelector, useDispatch } from "react-redux";
import { Content, General, Grid, Button,  Text } from "../../../styled-components/dashboard";
import LoaderImage from "../../../components/LoaderImage";
import ErrorMessage from "../../../components/ErrorMessage";
import { FlexColumn, FlexRow } from "../../../styled-components/visualization/content/styles/content";
import { listAuthUsers } from '../../../actions/loggingsystem/authUserActions'
import { listLastNLogs } from '../../../actions/loggingsystem/loggingSystemActions'
import { LIST_LAST_LOGS_RESET } from '../../../constants/loggingsystem/loggingSystemConstants'

import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Loader from '../../../components/Loader'
import Message from '../../../components/Message'
import Sidebar from '../../../components/Sidebar'
import SearchBox from '../../../components/SearchBox'
import Paginate from '../../../components/Paginate'
import { AUTH_USER_LIST_RESET } from "../../../constants/loggingsystem/authUserConstants";

export function UsersAuth0Container({isSuperuser, name, email, isActive, accessToken}) {
    let history = useHistory()
    const dispatch = useDispatch()
    let param = history.location.search
    const [paramPrev, setParamPrev] = useState('')

    const [open, setOpen] = useState(false)
    const [userEmail, setUserEmail] = useState('')

    const authUsersList = useSelector((state) => state.authUsersList)
    const {
        loading: loadingUsers,
        error: errorUsers,
        success: successUsers,
        authUsers,
        page,
        pages,
    } = authUsersList


    useEffect(() => {
        if(accessToken){
            if(isActive=== undefined && isSuperuser === undefined){
            } 
            else{
                if(successUsers){
                    if(param !== paramPrev){
                        setParamPrev(param)
                        dispatch({type: AUTH_USER_LIST_RESET}) 
                    }
                }
               

             if(isSuperuser){ 
                if (successUsers){

                } else 
                if (!loadingUsers && !successUsers) {
                    dispatch(listAuthUsers(accessToken, param))

             }
            // if (!successUsers) dispatch(listAuthUsers(accessToken, param))
            }
        }
    }
    }, [
        accessToken,
        successUsers,
        loadingUsers,
        dispatch,
        param,
        paramPrev,
        history.location.pathname
    ])

    const handleDrawerOpen = (e, email) => {
        setOpen(true)
        e.preventDefault()
        setUserEmail(email)
        dispatch({ type: LIST_LAST_LOGS_RESET })
        dispatch(listLastNLogs(accessToken, email))
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    console.log("authUser", authUsers)
    const dateObj = new Date()

    const tableBody = (authUser) => {
        return (
            <General.TableRow
            style={{
                justifyContent: 'space-between',
                width: 'auto',
                // maxWidth: '1000px',
                }}
            >
                <FlexColumn
                style={{
                        width: '22%'
                    }}
                >
                    <Text.Normal14Black style={{overflowWrap: 'break-word'}}>
                        {authUser.name}
                        {/* <button
                            type="button"
                            variant="light"
                            id={authUser.user_id + '_btn'}
                            to="#"
                            className="btn_users"
                            onClick={(e) => handleDrawerOpen(e, authUser.email)}
                            style={{ display: 'inline-flex', float: 'right' }}
                        >
                            Preview
                        </button> */}
                    </Text.Normal14Black>
                </FlexColumn>
                <FlexColumn
                    style={{
                            width: '26%'
                        }}
                >
                    <Text.Normal14Black style={{overflowWrap: 'break-word'}}>{authUser.email}</Text.Normal14Black>
                </FlexColumn>
                {new Date(authUser.last_login).valueOf() >
                    new Date(dateObj).valueOf() ? (
                        <FlexColumn
                        style={{
                                width: '15%'
                            }}
                        >
                            <Text.Normal14Black>
                                {new Intl.DateTimeFormat('en-GB', {
                                    dateStyle: 'long',
                                    timeStyle: 'short',
                                }).format(Date.parse(authUser.last_login))}
                            </Text.Normal14Black>
                        </FlexColumn>
                    ) : (
                        <FlexColumn
                        style={{
                                width: '15%'
                            }}
                        >
                            <Text.Normal14Black>
                                {new Intl.DateTimeFormat('en-GB', {
                                    dateStyle: 'medium',
                                }).format(Date.parse(authUser.last_login))}
                            </Text.Normal14Black>
                        </FlexColumn>
                )}
                {/* <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Normal14Black>{authUser.logins_count}</Text.Normal14Black>
                </FlexColumn> */}
                {new Date(authUser.created_at).valueOf() >
                new Date(dateObj).valueOf() ? (
                    <FlexColumn
                    style={{
                            width: '15%'
                        }}
                    >
                        <Text.Normal14Black>
                            {new Intl.DateTimeFormat('en-GB', {
                                dateStyle: 'long',
                                timeStyle: 'short',
                            }).format(Date.parse(authUser.created_at))}
                        </Text.Normal14Black>
                    </FlexColumn>
                ) : (
                    <FlexColumn
                    style={{
                            width: '15%'
                        }}
                    >
                        <Text.Normal14Black>
                            {new Intl.DateTimeFormat('en-GB', {
                                dateStyle: 'medium',
                            }).format(Date.parse(authUser.created_at))}
                        </Text.Normal14Black>
                    </FlexColumn>
                )}
                <FlexColumn
                style={{
                        width: '12%'
                    }}
                >
                    <Text.Normal14Black>
                        {authUser.email_verified ? (
                            <i className="fas fa-check" style={{ color: 'green' }}>
                                {' '}
                            </i>
                        ) : (
                            <i
                                className="fas fa-window-close"
                                style={{ color: 'red' }}
                            >
                                {' '}
                            </i>
                        )}
                    </Text.Normal14Black>
                </FlexColumn>
                <FlexColumn
                style={{
                        width: '8%'
                    }}
                >
                    <button
                        type="button"
                        variant="light"
                        id={authUser.user_id + '_btn'}
                        to="#"
                        className="btn_users"
                        onClick={(e) => handleDrawerOpen(e, authUser.email)}
                        // style={{ display: 'inline-flex', float: 'right' }}
                        style={{ 
                            width: '100%', 
                            background: 'white', 
                            border: 'solid 2px #D4D4D4',
                            height: '28px',
                            borderRadius: '4px'
                        }}
                    >
                        Preview
                    </button></FlexColumn>
            </General.TableRow>
        )
    }
   
    function getUserByEmail(email) {
        for (let i = 0; i < authUsers.length; i++) {
          if (authUsers[i].email === email) {
            return authUsers[i];
          }
        }
      }

  return (
    <Content>
        {loadingUsers || !successUsers  ?
        <LoaderImage /> :
        errorUsers ? 
        <ErrorMessage /> :
        <Content.SectionTop>
            <Grid.SectionFull>
            <SearchBox />
            {loadingUsers ? (
                <Loader />
            ) : errorUsers ? (
                <Message variant="danger">{errorUsers}</Message>
            ) : (
                <div>
                <FlexColumn>
                    <FlexRow
                        style={{
                            justifyContent: 'space-between',
                            // maxWidth: '1000px',
                            marginLeft: '-10px',
                            padding: '10px',
                            alignItems:'center'
                        }}
                    >
            
                            <FlexColumn
                                style={{
                                    width: '22%'
                                }}
                            >
                                <Text.Bold16Black>NAME</Text.Bold16Black>
                            </FlexColumn>
                            <FlexColumn
                                style={{
                                        width: '26%'
                                    }}
                            >
                                <Text.Bold16Black>EMAIL</Text.Bold16Black>
                            </FlexColumn>
                            <FlexColumn
                                style={{
                                        width: '15%'
                                    }}
                            >
                                <Text.Bold16Black>LAST LOGIN</Text.Bold16Black>
                            </FlexColumn>
                            {/* <FlexColumn
                                style={{
                                        width: '15%'
                                    }}
                            >
                                <Text.Bold16Black>LOGINS COUNT</Text.Bold16Black>
                            </FlexColumn> */}
                            <FlexColumn
                                style={{
                                        width: '15%'
                                    }}
                            >
                                <Text.Bold16Black>CREATED AT</Text.Bold16Black>
                            </FlexColumn>
                            <FlexColumn
                                style={{
                                        width: '15%'
                                    }}
                            >
                                <Text.Bold16Black>EMAIL VERIFIED</Text.Bold16Black>
                            </FlexColumn>
                            <FlexColumn
                                style={{
                                        width: '5%'
                                    }}
                            >
                                <Text.Bold16Black></Text.Bold16Black>
                            </FlexColumn>
                        
            
                    </FlexRow>

                        {authUsers.map((authUsers) => tableBody(authUsers))}

                </FlexColumn>
                <Paginate
                    page={page+1}
                    pages={pages}
                />
            </div>
                )}
                <Sidebar
                    open={open}
                    handleDrawerClose={handleDrawerClose}
                    userEmail={userEmail}
                    accessToken={accessToken}
                    authUser={getUserByEmail(userEmail)}
                />
            </Grid.SectionFull>
        </Content.SectionTop>

        }
    </Content>
  )
}

