import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button, General } from "../../../styled-components/dashboard";
import {  listWebsites } from '../../../actions/websiteActions'
import LoaderImage from "../../../components/LoaderImage";
import WebsiteComponent from "../../../components/websiteComponent";
import ErrorMessage from "../../../components/ErrorMessage";
import AddNewButton from "../../../components/AddNewButton";
import { FlexColumn, FlexRow } from "../../../styled-components/visualization/content/styles/content";
import { listCompanyDetails } from '../../../actions/companyActions'
import {  Welcome} from "../../../styled-components/visualization";
import {  COMPETITOR_DETAILS_RESET } from "../../../constants/competitorConstants";
import { WEBSITE_DETAILS_BY_ID_RESET } from "../../../constants/websiteConstants";
import { KEYWORD_DETAILS_RESET } from "../../../constants/keywordConstants";

export function KeywordsAdminContainer({ isSuperuser, name, email, isActive, accessToken}) {
  const dispatch = useDispatch()
  let history = useHistory()


  // websites = = all the websites added

  const websiteList = useSelector(state => state.websiteList)
  const {
      loading: loadingWebsitesList, 
      error: errorWebsitesList, 
      success: successWebsitesList, 
      websites
  } = websiteList

  // website = = websites created by the logged user
  const websiteDetailsByEmail = useSelector(state => state.websiteDetailsByEmail)
  const {
    //   loading: loadingWebsitesListByEmail, 
    //   error: errorWebsitesListByEmail, 
      success: successWebsitesListByEmail, 
    //   website
  } = websiteDetailsByEmail

  const companyDetails = useSelector(state => state.companyDetails)
  const {
      loading: loadingDetailsCompany, 
      error: errorDetailsCompany, 
      success: successDetailsCompany, 
      company: detailsCompany
  } = companyDetails

  const [actionNeeded, setActionNeeded] = useState(false)

  const SaveHandler = (webId) =>{
    dispatch({type: COMPETITOR_DETAILS_RESET}) 
    dispatch({type: WEBSITE_DETAILS_BY_ID_RESET}) 
    dispatch({type: KEYWORD_DETAILS_RESET}) 

    history.push(`/edit-website/${webId}`)
}   

  useEffect(()=>{
    if(accessToken && email) 
        {
            if(isActive=== undefined && isSuperuser === undefined){
            } 
            else{
                if(isSuperuser){
                    if(successDetailsCompany){
                        if(successWebsitesList){
                            websites.map((website) =>{
                                if(website.no_of_keywords === 0){
                                    setActionNeeded(true)
                                }
                            })
                        }
                        else 
                        if(!loadingWebsitesList)
                        {
                            dispatch(listWebsites(accessToken, email))
                        }


                        } 
                     else {
                        dispatch(listCompanyDetails(accessToken, email, name))
                    }
                } else{
                    history.push(`/`)
                }
            }
           
        }
    },[email,
        accessToken,  
        dispatch,
        successWebsitesList,
        successWebsitesListByEmail,

        successDetailsCompany,
      detailsCompany,
      actionNeeded,
      isActive,
      isSuperuser

    ])


  const websitesTable = () => {
    return(
        <FlexColumn>
       
            <FlexRow
                style={{
                    maxWidth: '1000px',
                  
                }}
            >
                <FlexColumn
                    style={{
                        width: '200px'
                    }}
                >
                    <Text.Bold16Black>
                        Website
                    </Text.Bold16Black>
                </FlexColumn>
                <FlexColumn
                style={{
                        width: '200px',
                        
                    }}
                >
                    <Text.Bold16Black>
                       Action required
                    </Text.Bold16Black>
                </FlexColumn>
               
            </FlexRow>
            {websites.map((website, i)=> (
                website.no_of_keywords === 0 &&
                <General.TableRow
                key={i} 
                    style={{
                        maxWidth: '1000px',
                        alignItems: 'center',
                   
                        }}
                    >
                    <FlexColumn
                        style={{
                            width: '200px',
                            marginTop: '20px',
                            marginBottom: '20px'
                        }}
                        >
                        <Text.Normal14Black>
                       
                            {website.website}
                        </Text.Normal14Black>

                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '200px',
                            justifyContent: 'center',
                            margin: '0'
                        }}
                    >
                    {website.language === '' || website.country === '' ? 
                    <Text.Bold16Black>Please choose the country and the language before adding the keywords in <span style={{color: '#C51852'}}>Keyword Profile</span> section</Text.Bold16Black>
                    :
                    <AddNewButton 
                    style={{
                        margin: '0',
                    }}
                        route={`/add-keywords-upload/${website.id}`}
                        text='Add Keywords' /> 
                    }
                    
                    </FlexColumn>
                    <FlexColumn>
                        <Button.Save 
                        style={{
                            width: 'auto',
                            margin: '0.5rem 0 0 0',
                            height: '40px',
                            padding: '0 10px',
                            background: 'transparent',
                            border: '2px solid rgb(197, 24, 82)',
                            color: '#222222',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            marginTop: '20px'
                        }}
                            onClick={() =>SaveHandler(website.id)}
                        >
                            Go to website
                        </Button.Save>
                    </FlexColumn>
                   
                </General.TableRow>
            ))}
        </FlexColumn>
        
    )
}

  return(
       <Content>
          {loadingWebsitesList || !successWebsitesList  ||
           loadingDetailsCompany || !successDetailsCompany
          ?
            <LoaderImage /> :
            errorWebsitesList || errorDetailsCompany ?
            <ErrorMessage /> :
            <Content.SectionTop  >
               
                    <Grid.SectionFull>
                        {websitesTable()}
                        
                    </Grid.SectionFull>

            <Welcome.FooterRightImageForAC 
            style={{
                right: '-50px'
            }} 
            src={require("../../../images/visualization/04.png").default} />
            </Content.SectionTop>
          }
          
          
      </Content>
  )
}
