import React from "react";


export function ProgressBarContainer({onlyOneSide=false,growthMax=0, growthValue=0}) {

  let barWidth = (growthValue/growthMax*100).toFixed(0);
  let hoverText = parseInt(growthValue) >= 0 ? 
  onlyOneSide ? parseInt(growthValue)  : '+'+parseInt(growthValue) 
  : parseInt(growthValue);
  // console.log(growthValue)
  // console.log(growthMax)
  const negativeProgressBar = () =>{
    return (
      <div style={{
          width:-barWidth+'%',
          background: '#FF4646',
          float:'right',
          cursor: 'pointer',
          height:'20px'}} title={hoverText}> 
      </div>
      )
  }
  

  const positiveProgressBar = () =>{
    return(<>
      {
      onlyOneSide ? 
      <div style={{
        width:barWidth>0 ? barWidth+'%' : "1%",
        background: '#841F5B',
        cursor: 'pointer',
        height:'20px'}} title={hoverText}>
      </div> 
      : 
      <div style={{
        width:barWidth>0 ? barWidth+'%' : "1%",
        background: '#3DA804',
        cursor: 'pointer',
        height:'20px'}} title={hoverText}>
      </div>
      }
     </>
    )
  }

  return (
    <>
    {onlyOneSide== true ? 
    <>
    {/* green bar */}
    <div  style={{width:'10%'}}> 
      { barWidth >= 0 ? positiveProgressBar(): ''}
    </div>
    </>
    : 
    <>
    {/* red bar */}
    <div  style={{width:'50%'}}>
      { barWidth < 0 ? negativeProgressBar(): ''}
    </div>

    {/* green bar */}
    <div  style={{width:'50%'}}> 
      { barWidth >= 0 ? positiveProgressBar(): ''}
    </div>
    </>
    }
    
    </>
  )
}
