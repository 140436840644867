export const ORGANISATION_LIST_REQUEST      = 'ORGANISATION_LIST_REQUEST'
export const ORGANISATION_LIST_SUCCESS      = 'ORGANISATION_LIST_SUCCESS'
export const ORGANISATION_LIST_FAIL         = 'ORGANISATION_LIST_FAIL'

export const ORGANISATION_DETAILS_REQUEST   = 'ORGANISATION_DETAILS_REQUEST'
export const ORGANISATION_DETAILS_SUCCESS   = 'ORGANISATION_DETAILS_SUCCESS'
export const ORGANISATION_DETAILS_FAIL      = 'ORGANISATION_DETAILS_FAIL'

export const ORGANISATION_DETAILS_BY_ID_REQUEST   = 'ORGANISATION_DETAILS_BY_ID_REQUEST'
export const ORGANISATION_DETAILS_BY_ID_SUCCESS   = 'ORGANISATION_DETAILS_BY_ID_SUCCESS'
export const ORGANISATION_DETAILS_BY_ID_FAIL      = 'ORGANISATION_DETAILS_BY_ID_FAIL'

export const ORGANISATION_CREATE_REQUEST    = 'ORGANISATION_CREATE_REQUEST'
export const ORGANISATION_CREATE_SUCCESS    = 'ORGANISATION_CREATE_SUCCESS'
export const ORGANISATION_CREATE_FAIL       = 'ORGANISATION_CREATE_FAIL'
export const ORGANISATION_CREATE_RESET      = 'ORGANISATION_CREATE_RESET'

export const ORGANISATION_UPDATE_REQUEST    = 'ORGANISATION_UPDATE_REQUEST'
export const ORGANISATION_UPDATE_SUCCESS    = 'ORGANISATION_UPDATE_SUCCESS'
export const ORGANISATION_UPDATE_FAIL       = 'ORGANISATION_UPDATE_FAIL'
export const ORGANISATION_UPDATE_RESET      = 'ORGANISATION_UPDATE_RESET'
