import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button } from "../../styled-components/dashboard";
import { useHistory } from "react-router-dom"
import Select from "../../components/Select";
import {  devices, languages2, countries, locationsLegend } from '../../constants/utils'
import InformationNotFilled from "../../components/InformationNotFilled";
import LoaderImage from "../../components/LoaderImage";
import { listWebsiteDetailsById, updateWebsite } from "../../actions/websiteActions";
import UpdateConfirmed from "../../components/UpdateConfirmed";
import { WEBSITE_DETAILS_BY_ID_RESET, WEBSITE_UPDATE_RESET } from "../../constants/websiteConstants";

export function AddKeywordsProfileContainer({ accessToken, email }) {
  const [informationFilled, setInformationFilled] = useState(0)
  const dispatch = useDispatch()
  const history = useHistory()
  const [locat, setLocat] = useState('Andorra')
  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('keywords')+17, 100))
  const [updateToggle, setUpdateToggle] =useState(false)
  const websiteDetailsById = useSelector(state => state.websiteDetailsById)
  const {loading: loadingDetailsByIdWebsite, 
      error: errorDetailsByIdWebsite, 
      success: successDetailsByIdWebsite, 
        website: detailsByIdWebsite
  } = websiteDetailsById

  const [device, setDevice] = useState(devices[0])
  const [language, setLanguage] = useState('')
  const [country, setCountry] = useState('')

    const websiteUpdate = useSelector(state => state.websiteUpdate)
    const {loading: loadingUpdateWebsite, 
        error: errorUpdateWebsite, 
        success: successUpdateWebsite, 

    } = websiteUpdate

     const addKeywordHandler = (e) => {
      e.preventDefault()
      if(language === '' || country === '') {
        setInformationFilled(1)
      } else{
 const website = {
            id: websiteId,
            device,
            language,
            country,
            email
    }
      dispatch(updateWebsite(accessToken, website, websiteId))
      setUpdateToggle(true)
  
      }
     
  }
  useEffect(() => {
    if(accessToken && email){
        if(successDetailsByIdWebsite){
          if(successUpdateWebsite){
            history.push(`/add-keywords-copy/${websiteId}`)
            dispatch({type: WEBSITE_DETAILS_BY_ID_RESET})
            dispatch({type: WEBSITE_UPDATE_RESET})
          }
        } else {
          if(!loadingDetailsByIdWebsite){
            dispatch(listWebsiteDetailsById(accessToken, websiteId, email))
          }
        }
    }
    

  }, [
    history,
    websiteId,
    successDetailsByIdWebsite,
    successUpdateWebsite
  ])

  const nextHandler = () => {
    history.push(`/add-keywords-copy/${websiteId}`)
    dispatch({type: WEBSITE_DETAILS_BY_ID_RESET})
  }
  
  return(
      <Content>
        {loadingDetailsByIdWebsite || loadingUpdateWebsite 
        ? <LoaderImage /> : 
        <>
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold40Black>
              Build your keyword profile for <span> </span>
              </Text.Bold40Black>
              <Text.Bold42Pink
              style={{
                  wordBreak: 'break-all',
                }}
              >
              {detailsByIdWebsite.website}<span> </span>
              </Text.Bold42Pink>
              <Text.Bold40Black>
                website
              </Text.Bold40Black>
              <Text.Normal14Black
              style={{marginTop: '26px'}}>
              Before the platform gets working, we need to build your website and Google Search profile.
              </Text.Normal14Black>
              <Text.Normal14Black
                style={{
                    marginTop: '15px'
                }}
              >
              Luminr will conduct its searches based on the Device, Country and Language you choose.
              </Text.Normal14Black>
              <Text.Normal14Black
                style={{
                    marginTop: '15px'
                }}
              >
                By default, we offer both Mobile & Desktop devices in order to properly deliver on our Total Search promise. 
              </Text.Normal14Black>
                
            </Grid.Section>
            <Grid.Section>
            <Text.Bold26Pink>
                Keywords details
            </Text.Bold26Pink>
            <Select param={device} setParam={setDevice} list={devices} placeholder='Choose device' title='Device' disabled={true}/>
            <Select param={language} setParam={setLanguage} list={languages2} placeholder='Choose language' title='Language'/>
            <Select param={country} setParam={setCountry} list={countries} placeholder='Choose country' title='Country'/>
            </Grid.Section>
          </Grid>
        </Content.SectionTop> 
        <Content.SectionBottom>
          
          <Content.ButtonsContainer
          style={{
              alignItems: 'center'
            }}
          >
          {/* <Text.Bold16Black>
                Coming soon ...  Go
              </Text.Bold16Black>
            <Button.Back
            onClick={backHandler}
            >
            Back
          </Button.Back> */}
          {successUpdateWebsite && !errorUpdateWebsite && <UpdateConfirmed updateToggle={updateToggle} setUpdateToggle={setUpdateToggle}
                  title={"Keywords profile has been saved"} subtitle={"Move to the next screen to add keywords"}/>}
          {informationFilled === 1 && <InformationNotFilled informationFilled={informationFilled} setInformationFilled={setInformationFilled}/>}
          {successUpdateWebsite ?
            <Button.Save 
              onClick={nextHandler}
              >
                  Next
            </Button.Save> :
            <Button.Save 
          onClick={addKeywordHandler}   >
              Save
            </Button.Save>
          }
         
          </Content.ButtonsContainer>
          
        </Content.SectionBottom>
        </>}
        
      </Content>
  )
}
