import React from 'react'
import { TemplateContainer } from '../../containers/dashboard/template'

export default function Home({accessToken, name, email, isActive, isSuperuser}) {
    return (
       <TemplateContainer pageName={'Home'} email={email} 
    name={name} 
    accessToken={accessToken}
    isActive={isActive}
    isSuperuser={isSuperuser}
    />)
}
