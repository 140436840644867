import React, {useEffect} from 'react'
import { MenuHeaderContainer } from '../../containers/visualization/MenuHeader'
import { CoverageContainer } from '../../containers/visualization/Coverage'
import { SectionsFooterContainer } from '../../containers/visualization/SectionsFooter';
import { FooterContainer } from '../../containers/visualization/Footer';
import { useHistory } from 'react-router-dom'

export default function Coverage({accessToken, name, email, isActive, isSuperuser, websiteId}) {
    let history = useHistory()
  
    useEffect(() => {
      if (accessToken ) {
          //if account is active
          if(isActive === undefined){

        }else{
            if(isActive ){
    
            }
            else {
                history.push(`/account-not-active`)
            }
        }
      }
  }, [
      accessToken,
      email,
      isActive,
      isSuperuser
  ])

    return (
        <>
        {isActive &&
    <div>
        <MenuHeaderContainer pageName={'Coverage'}  email={email} name={name} accessToken={accessToken} websiteId={websiteId}/>

        
         <CoverageContainer email={email} name={name} accessToken={accessToken} websiteId={websiteId}/>

        
        <SectionsFooterContainer pageName={'Coverage'}  email={email} name={name} accessToken={accessToken}/>
        <FooterContainer />
        {/* <NoWorkingPage /> */}

    </div>}
    </>
    )
}

