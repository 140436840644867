import React from "react"
import { Container, Menu, Menu2, MenuImg, MenuBackground, Save, Back, Reset, Pink, CloseModal, GoTo
} from "./styles/button"

export default function Button({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Button.Menu = function ButtonMenu({children, ...restProps}){
    return <Menu {...restProps}>{children}</Menu>   
}

Button.Menu2 = function ButtonMenu2({children, ...restProps}){
    return <Menu2 {...restProps}>{children}</Menu2>   
}

Button.MenuImg = function ButtonMenuImg({to, ...restProps}){
    return  <MenuImg {...restProps} />    
}

Button.MenuBackground = function ButtonMenuBackground({children, ...restProps}){
    return <MenuBackground {...restProps}>{children}</MenuBackground>   
}

Button.Save = function ButtonSave({children, ...restProps}){
    return <Save {...restProps}>{children}</Save>   
}

Button.Back = function ButtonBack({children, ...restProps}){
    return <Back {...restProps}>{children}</Back>   
}

Button.Reset = function ButtonReset({children, ...restProps}){
    return <Reset {...restProps}>{children}</Reset>   
}

Button.Pink = function ButtonPink({children, ...restProps}){
    return <Pink {...restProps}>{children}</Pink>   
}

Button.CloseModal = function ButtonCloseModal({children, ...restProps}){
    return <CloseModal {...restProps}>{children}</CloseModal>   
}

Button.GoTo = function ButtonGoTo({children, ...restProps}){
    return <GoTo {...restProps}>{children}</GoTo>   
}
