import axios from 'axios'
import {
    AUTH_USER_LIST_REQUEST,
    AUTH_USER_LIST_SUCCESS,
    AUTH_USER_LIST_FAIL,
} from '../../constants/loggingsystem/authUserConstants'

export const listAuthUsers =
    (accessToken, param = '') =>
    async (dispatch) => {
        try {
            dispatch({ type: AUTH_USER_LIST_REQUEST })

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            }

            const { data } = await axios.get(`/api/users${param}`, config)

            dispatch({
                type: AUTH_USER_LIST_SUCCESS,
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: AUTH_USER_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            })
        }
    }
