import React, { useState } from "react";
import { Content, Grid, Text, Button, General } from "../../styled-components/dashboard";

import { useHistory } from "react-router-dom"

export function AddKeywordsContainer({ accessToken, name, email }) {

  const history = useHistory()
  const backHandler = (e) => {
    history.push(`/add-website`)
}

  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('keywords')+9, 100))

  const nextHandler = (e) => {
    if(option === 1){
      history.push(`/add-keywords-copy/${websiteId}`)
      
    }
    if(option === 2){
      history.push(`/add-keywords-generate/${websiteId}`)
    }
    if(option === 3){
      history.push(`/add-keywords-upload/${websiteId}`)
    }

  }

  const [option, setOption] = useState(1)


  return(
      <Content>
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold40Black>
                Adding keywords to your website
              </Text.Bold40Black>
              <Text.Normal14Black
              style={{marginTop: '26px'}}>
                Before Luminr can get working, you need to upload your keywords specifications. If you are starting from scratch, please download the Luminr Excel template. For any help on how to set up or populate the template check out our Keyword Guide.
              </Text.Normal14Black>
              <Text.Normal14Black>
               Please note if you want to track a keyword across several domains, locations, languages, etc please include a new row for each variation.
              </Text.Normal14Black>
              <Text.Normal14Black>
                For more information on keyword grouping check out our <span style={{color: '#C51852', fontWeight: 'bold'}}> Keyword Help Guide. </span> 
              </Text.Normal14Black>
                
            </Grid.Section>
            <Grid.Section>
              {option === 1 ? 
                <General.RadioButtonItem
                  onClick = {() => setOption(1)}
                >
                <General.RadioButtonLeft>
                  <Text.Bold26Pink
                    style={{margin: '0'}}
                  >
                    Copy and paste
                  </Text.Bold26Pink>
                  <Text.Normal14Black
                  style={{margin: '0'}}
                  >
                    Directly paste in keywords from a spreadsheet or similar list
                  </Text.Normal14Black>
                </General.RadioButtonLeft>
                <General.RadioButtonRight>
                  <General.RadioButtonEmptyCircle>
                    <General.RadioButtonFullCircle></General.RadioButtonFullCircle>
                  </General.RadioButtonEmptyCircle>
                </General.RadioButtonRight>
              </General.RadioButtonItem> :
              <General.RadioButtonItem
                style={{border:'none'}}
                onClick = {() => setOption(1)}
              >
                <General.RadioButtonLeft>
                  <Text.Bold26Pink
                    style={{margin: '0'}}
                  >
                    Copy and paste
                  </Text.Bold26Pink>
                  <Text.Normal14Black
                  style={{margin: '0'}}
                  >
                    Directly paste in keywords from a spreadsheet or similar list
                  </Text.Normal14Black>
                </General.RadioButtonLeft>
                <General.RadioButtonRight>
                  <General.RadioButtonEmptyCircle>
                   
                  </General.RadioButtonEmptyCircle>
                </General.RadioButtonRight>
              </General.RadioButtonItem>
              
              }
             
              {option === 2 ? 
              <General.RadioButtonItem
              style={{border:'2px solid #F4865E' }}
              onClick = {() => setOption(2)}
              >
                <General.RadioButtonLeft>
                  <Text.Bold26Orange
                    style={{margin: '0'}}
                  >
                    Generate
                  </Text.Bold26Orange>
                  <Text.Normal14Black
                  style={{margin: '0'}}
                  >
                    Generate Keywords directly on Luminr
                  </Text.Normal14Black>
                </General.RadioButtonLeft>
                <General.RadioButtonRight>
                  <General.RadioButtonEmptyCircle
                    style={{border:'2px solid #F4865E' }}
                  >
                    <General.RadioButtonFullCircle
                      style={{background: '#F4865E',
                      border:'2px solid #F4865E'
                      }}
                    ></General.RadioButtonFullCircle>
                  </General.RadioButtonEmptyCircle>
                </General.RadioButtonRight>
              </General.RadioButtonItem> : 
              <General.RadioButtonItem
              style={{border:'none' }}
              onClick = {() => setOption(2)}
              >
                <General.RadioButtonLeft>
                  <Text.Bold26Orange
                    style={{margin: '0'}}
                  >
                    Generate
                  </Text.Bold26Orange>
                  <Text.Normal14Black
                  style={{margin: '0'}}
                  >
                    Generate Keywords directly on Luminr
                  </Text.Normal14Black>
                </General.RadioButtonLeft>
                <General.RadioButtonRight>
                  <General.RadioButtonEmptyCircle
                    style={{border:'2px solid #F4865E' }}
                  >
                  </General.RadioButtonEmptyCircle>
                </General.RadioButtonRight>
              </General.RadioButtonItem> 
              }

              {option === 3 ? 
              <General.RadioButtonItem
              style={{border:'2px solid #841E5A' }}
              onClick = {() => setOption(3)}
              >
                <General.RadioButtonLeft>
                  <Text.Bold26Purple
                    style={{margin: '0'}}
                  >
                    Upload a file
                  </Text.Bold26Purple>
                  <Text.Normal14Black
                  style={{margin: '0'}}
                  >
                    Import contacts from a CSV or tab-eliminated TXT file.
                  </Text.Normal14Black>
                </General.RadioButtonLeft>
                <General.RadioButtonRight>
                  <General.RadioButtonEmptyCircle
                    style={{border:'2px solid #841E5A' }}
                  >
                    <General.RadioButtonFullCircle
                      style={{background: '#841E5A',
                      border:'2px solid #841E5A'
                      }}
                    ></General.RadioButtonFullCircle>
                  </General.RadioButtonEmptyCircle>
                </General.RadioButtonRight>
              </General.RadioButtonItem> :
              <General.RadioButtonItem
              style={{border:'none' }}
              onClick = {() => setOption(3)}
              >
                <General.RadioButtonLeft>
                  <Text.Bold26Purple
                    style={{margin: '0'}}
                  >
                    Upload a file
                  </Text.Bold26Purple>
                  <Text.Normal14Black
                  style={{margin: '0'}}
                  >
                    Import contacts from a CSV or tab-eliminated TXT file.
                  </Text.Normal14Black>
                </General.RadioButtonLeft>
                <General.RadioButtonRight>
                  <General.RadioButtonEmptyCircle
                    style={{border:'2px solid #841E5A' }}
                  >
                  </General.RadioButtonEmptyCircle>
                </General.RadioButtonRight>
              </General.RadioButtonItem>
              }
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          
          <Content.ButtonsContainer>
            
            {/* <Button.Back
            onClick={backHandler}
            >
            Back
          </Button.Back> */}
          <Button.Save 
          onClick={nextHandler}
          >
              Next
            </Button.Save>
          </Content.ButtonsContainer>
          
        </Content.SectionBottom>
      </Content>
  )
}
