import React, {  useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Content, General, Grid, Text } from "../../styled-components/dashboard";
import * as ROUTES from "../../constants/routes/routes"
import { Table } from '../../styled-components/dashboard'
import { useHistory } from "react-router-dom"
import { listCompanyDetails, deleteCompany } from '../../actions/companyActions'
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import AddNewButton from "../../components/AddNewButton";
import { FlexColumn, FlexRow } from "../../styled-components/visualization/content/styles/content";
import { COMPANY_DELETE_RESET, COMPANY_DETAILS_BY_ID_RESET} from '../../constants/companyConstants'
import { WEBSITE_DETAILS_RESET} from '../../constants/websiteConstants'
import Modal from 'react-modal';

export function CompaniesContainer({ accessToken, name, email }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const companyDetails = useSelector(state => state.companyDetails)
  const {
      loading: loadingDetailsCompany, 
      error: errorDetailsCompany, 
      success: successDetailsCompany, 
      company: detailsCompany
  } = companyDetails

  const companyDelete = useSelector(state => state.companyDelete)
  const {
      loading: loadingDeleteCompany, 
      error: errorDeleteCompany, 
      success: successDeleteCompany, 
      company: deleteCompanyMessage
  } = companyDelete

  useEffect(()=>{
  if(accessToken && email)  
      {
      if(successDetailsCompany){


          } else {
              dispatch(listCompanyDetails(accessToken, email, name))
          }
      } 
      if(successDeleteCompany){
        dispatch({type: COMPANY_DELETE_RESET})
        dispatch(listCompanyDetails(accessToken, email, name))
        
      }
  },[email,
      accessToken,  
      dispatch,
      successDetailsCompany,
      successDeleteCompany,
      detailsCompany
  ])

  const handlerGoToEdit = (id) => {
    dispatch({type: COMPANY_DETAILS_BY_ID_RESET})
    dispatch({type: WEBSITE_DETAILS_RESET})

    history.push(`/edit-company/${id}`)
    }

    // const handlerGoToDelete = (id) => {
    // dispatch({type: COMPANY_DETAILS_BY_ID_RESET})

    // history.push(`/delete-company/${id}`)

    // } 



    const companiesTable = () => {
    return(
        <FlexColumn>
            <FlexRow
                style={{
                    justifyContent: 'space-between',
                    maxWidth: '1000px',
                    marginLeft: '-10px',
                    padding: '10px'
                }}
            >
                <FlexColumn
                    style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Company Name
                    </Text.Bold16Black>
                </FlexColumn>
                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Nr of users
                    </Text.Bold16Black>
                </FlexColumn>
                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Active
                    </Text.Bold16Black>
                </FlexColumn>

                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Actions
                    </Text.Bold16Black>
                </FlexColumn>
            </FlexRow>
            {detailsCompany.map((company)=> (
                <General.TableRow
                style={{
                    justifyContent: 'space-between',
                    maxWidth: '1000px',
                    }}
                >
                <FlexColumn
                    style={{
                        width: '15%'
                    }}
                    >
                    <Text.Normal14Black>
                        {company.name}
                    </Text.Normal14Black>

                </FlexColumn>
                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                <Text.Normal14Black>
                        {company.ocuppiedSlots}
                    </Text.Normal14Black>
                </FlexColumn>
                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Normal14Black>
                        Yes
                    </Text.Normal14Black>
                </FlexColumn>
                
                <FlexRow
                style={{
                        width: '15%'
                    }}
                >
                <Table.IconLink
                onClick={() => handlerGoToEdit(company.id)} 
                alt="edit"
                src={require("../../images/icon_edit_pink.svg").default}
                />
                <Table.IconLink
                    onClick={() => openDeleteModal(company.name,company.id)}
                    alt="delete"
                    src={require("../../images/icon_delete_pink.svg").default} 
                    
                    
                />
            
                </FlexRow>
                {modalDelete(company.id)}
            </General.TableRow>
            
            ))}
            <AddNewButton route={ROUTES.ADD_COMPANY} text='Add Company' />
        </FlexColumn>
        
    )
    }

    //MODAL DELETE
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [companyId, setCompanyId] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');

  const customStylesDelete = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '450px',
      padding: '3%'
    },
  };

  function openDeleteModal(c_name, c_id) {
    setCompanyId(c_id) // we need to store the state, although it will always delete the laste element from list
    setCompanyName(c_name)
    setDeleteModalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
  }
  const deleteCompanyHandler = () => {
    setDeleteModalIsOpen(false);
    dispatch(deleteCompany(accessToken, companyId))
  }

  const modalDelete = (c_id) => {
    return(
            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={closeDeleteModal}
                style={customStylesDelete}
                ariaHideApp={false}
              >
              <Text.Bold40Black>
                Are you sure you want to remove
                </Text.Bold40Black> <span> </span>
                <Text.Bold42Pink>
                {companyName} 
                </Text.Bold42Pink> <span> </span>
                <Text.Bold40Black>
                from your companies list?
                </Text.Bold40Black>
                <FlexRow
                style={{
                  justifyContent: 'space-between',
                  marginTop: '30px'
                }}
            >
                <Button.Back
                  style={{
                    margin: '0'
                  }}
                  onClick={closeDeleteModal}
                >
                  Cancel
                </Button.Back>
                <Button.Save
                  onClick={()=>deleteCompanyHandler()}
                >
                  Delete
                </Button.Save>

            </FlexRow>
            </Modal>
    )
  }


    return(
        <Content>
            {loadingDetailsCompany || !successDetailsCompany || loadingDeleteCompany ?
                <LoaderImage /> :
                errorDetailsCompany || errorDeleteCompany ?
                <ErrorMessage /> :
                <Content.SectionTop>
                            {detailsCompany[0] ?
                                <Grid.SectionFull>
                                    {companiesTable()}
                                </Grid.SectionFull>
                            :
                                <Content.SectionTopWithBackground>
                                    <Content.FlexColumn className="centeredContent">
                                        <Text.Normal26Black>
                                            You are not tracking any websites in Luminr yet
                                        </Text.Normal26Black>
                                        <br/>
                                        <Text.Normal14Black>
                                            To get started add your company now. Then follow the steps for adding websites.
                                        </Text.Normal14Black>
                                        <AddNewButton route={ROUTES.ADD_COMPANY} text='Add Company' />
                                    </Content.FlexColumn>
                                </Content.SectionTopWithBackground>
                            }
                
                </Content.SectionTop>
            }
            
        </Content>
    )
}
