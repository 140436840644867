import React, { useState, useEffect } from "react";
import {  Content } from "../../../styled-components/visualization";
import Chart from "react-apexcharts";
import { nFormatter } from "../resultsComponents/serpScoreboard/sortingUtils";


export function KeywordRankingsOpportunitiesLineColumnContainer({bigKeywordRankingOppResults}) {

    const [monthlySearchesArray, ] = useState([])
    const [totalCTRArray, ] = useState([])

    const [keywordArray,] = useState([])

    const [firstRender, setFirstRender] = useState(0)

    const truncateString = (inputString, maxLength) => {
      if(inputString){
        if (inputString.length <= maxLength) {
          return [inputString];
        }
      
        let firstPart = inputString.substring(0, maxLength);
        let secondPart = inputString.substring(maxLength);
      
        // Find the last space in the first part to split on complete words
        const lastSpaceIndex = firstPart.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
          firstPart = firstPart.substring(0, lastSpaceIndex);
          secondPart = inputString.substring(lastSpaceIndex + 1);
        }
      
        if (secondPart.length > maxLength) {
          secondPart =  secondPart.substring(0,maxLength) + '..';
        }
      
        return [firstPart, secondPart];
      }
    }

    useEffect(() => { 
        if(firstRender === 0) {
          bigKeywordRankingOppResults.map((result, i) => {

                monthlySearchesArray.push(parseInt(result.search_volume))
                // const truncatedString = truncateString(result.keyword,18);
                keywordArray.push(result.keyword)
                let ctr  = ((result.total_search_ctr)*100).toFixed(2);
                totalCTRArray.push(ctr)

               
          })
          setFirstRender(firstRender+1)
        }
      },
      []) 


    var options = {
        series: [{
        name: 'Monthly Search Volume',
        type: 'column',
        data: monthlySearchesArray
      }, {
        name: 'Your TS CTR',
        type: 'line',
        data: totalCTRArray,
        
      }],
        chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            download: '<img src='+require("../../../images/visualization/download.svg").default+' width="20">'
          }
        }
      },
      stroke: {
        width: [0, 4]
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
     
      colors: ['#841E5A', '#341E5A'],
      xaxis: {
        title: {
          text: 'Keyword',
          offsetY: -2, //to show entire word (else is cutted on the bottom side)
        },
       
        type: 'category',
        categories: keywordArray,
        labels: {
            show: true,
             rotate: -45,
            maxWidth: -20,
            rotateAlways: true,
            trim: false,
            minHeight: 130,
            formatter: function (value) {
              const truncatedString = truncateString(value,25);

              return truncatedString;
            }
        }
      },
      yaxis: [{
        title: {
          text: 'Monthly Search Volume',
        },
        labels: {
          formatter: function (value) {
            return nFormatter(value)
          }
        },
      
      }, {
        opposite: true,
        title: {
          text: 'Your TS CTR'
        },
        labels: {
          show:true,
          formatter: function (value) {
          return value+'%';
          }
        }
      }],

      legend: {
        show: true,
        horizontalAlign: 'left',
        position: 'top',
        offsetX: 50,
      },
      tooltip: {
        enabled: true
      }
      
      };
  return (
      <Content.FlexColumn
      style={{
          //maxWidth: '1135px',
         // alignItems:'center'
         width: '100%',
         margin: '0 auto',
      }}
      >
        <Chart 
                    options={options}
                    series={options.series}
                    width="100%"
                    height='500px'

            />
      </Content.FlexColumn>
        
  )
}
