import React, { useState, useEffect } from 'react'
import { Signuptest } from '../../styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateProfile, listProfileDetails } from '../../actions/profileActions'
import '../../index.css' 
import LoaderImage from '../../components/LoaderImage'
import { PROFILE_UPDATE_RESET} from '../../constants/profileConstants'
import { Button } from '../../styled-components/dashboard/'

export function ImproveSerprFirstPageContainer({ children, accessToken }) {
    let history = useHistory()
    const dispatch = useDispatch()
    const { user, getAccessTokenSilently, logout } = useAuth0()

    const [userName, setUserName] = useState("")
    const hopingToAchieve = []
    const [otherOptionTyped, setOtherOptionTyped] = useState('')
    const [hopingToAchieveCheck, setHopingToAchieveCheck] = useState(false)

    const profileDetails = useSelector((state) => state.profileDetails);
    const {success, profile } = profileDetails;

    const profileUpdate = useSelector((state) => state.profileUpdate);
    const { 
      loading: loadingUpdateProfile,
      error: errorUpdateProfile,
      success: successUpdateProfile,
    } = profileUpdate;

    const [selectedPreferences, setSelectedPreferences] = useState({
      'Benchmark my team’s success': false,
      'Improve ROI measurement': false,
      'Enhance competitor analysis capabilities': false,
      'View my Google search performance': false,
      'View my performance on other platforms (Bing TikTok Amazon)': false,
      'Streamline reporting for stakeholders': false,
      'Identify actionable insights': false,
      'Stay ahead of competitors in search results': false,
      'Testing out Luminr`s USP': false
    });

    const [isOtherDisplayed, setIsOtherDisplayed] = useState(false);
    
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setPageWidth(window.innerWidth);
    };
      
    useEffect(() => {
            if (accessToken) {
                if (success){ 
                    setUserName(profile.name)
                    // when user add their prefferences for the first time
                    if(successUpdateProfile){
                      dispatch({type: PROFILE_UPDATE_RESET})
                      history.push(`/profile-metrics`)
                    }
                    
                    // if user try to access this page but he already addede their preferences
                    if(profile.hopingToAchieve) {
                      history.push(`/profile-metrics`)
                    }
                } else {
                  dispatch(listProfileDetails(accessToken, profile.userProfile));
                }
            }
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
    }, [
        user,
        accessToken,
        dispatch,
        success,
        profile,
        getAccessTokenSilently
    ])

    const handleOtherOption = (e) => {
      setOtherOptionTyped(e.target.value)
    }

    const updateHandler = async (e) => {
      e.preventDefault()
      
      const hopingToAchieve = Object.keys(selectedPreferences).filter(key => selectedPreferences[key] === true);
      if((Array.isArray(hopingToAchieve) && hopingToAchieve.length) === 0) {
        setHopingToAchieveCheck(true);
      }
      else {
        setHopingToAchieveCheck(false);
        if(otherOptionTyped) {
          if(isOtherDisplayed){
            hopingToAchieve.push(otherOptionTyped)
          } else {
            const index = hopingToAchieve.indexOf(otherOptionTyped);
            if (index > -1) { 
              hopingToAchieve.splice(index, 1); 
            }
          }
        }
  
        const data = {
          name: userName,
          email: profile.userEmail,
          hopingToAchieve: hopingToAchieve,
          handler: 'hopingToAchieve'
        }; 
        dispatch(updateProfile(accessToken, data));
      }
      
    };

    const [selectedOther, setSelectedOther] = useState("");
    let [overOther,setOverOther]=React.useState(false);
    
    const handlerClickOther = () => {
      if(selectedOther){
          setIsOtherDisplayed(false)
          setSelectedOther("");
      } else {
          setIsOtherDisplayed(true)  
          setSelectedOther("Other");   
      }
    }
    
    const ListItem = ({ name }) => {
        let [over,setOver]=React.useState(false);
        const selectedPreference = selectedPreferences[name]
        
        const onClick = () => {
            setSelectedPreferences({  ...selectedPreferences, [name]: !selectedPreference });    
            setHopingToAchieveCheck(false);
        };

        return (
          <>
            <li className={selectedPreference ? 'selected' : ''} onClick={onClick} onMouseOver={()=>setOver(true)} onMouseOut={()=>setOver(false)} >
              {name}
              {selectedPreference ? <img src={require("../../images/icon_tick_white.svg").default} alt="icon" className="tick-icon" /> : over ? 
              <img src={require("../../images/icon_tick_grey.svg").default} alt="icon" className="tick-icon" /> : ''}
            </li>
          </>
        );
      };

    const SelectList = ({ listItems }) => {

      return (
        <ul>
          { Object.entries(listItems).map( ([key, value]) => <ListItem key={key} name={key} /> ) }
        </ul>
      );
    };

    return (
        <Signuptest>
            {loadingUpdateProfile ? <LoaderImage height={'100vh'}/> : 
            <>
                <Signuptest.HeaderFrame>
                    <div>
                        <Signuptest.Logo
                            href="https://luminr.ai/"
                            alt="Logo"
                            src={require("../../images/luminr-logo.svg").default}
                        />
                    </div>
                    <div 
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        zIndex: '9999',
                        paddingRight: '135px'
                      }}
                    >
                        {/* <Signuptest.HeaderText id="login-btn-header" style={{paddingRight: '10px'}}>Log In</Signuptest.HeaderText>
                        <label 
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                            width: '40px',
                            height: '18px',
                            verticalAlign: 'middle',
                            margin: '0'
                          }}
                        >
                            <input type="checkbox"/>
                            <Signuptest.Slider></Signuptest.Slider>
                        </label>
                        <Signuptest.HeaderText id="create-btn-header">Create an account</Signuptest.HeaderText> */}
                        <Signuptest.HeaderText style={{paddingLeft: '20px'}}>
                            <a href="https://luminr.ai/">
                                Back to Luminr.ai
                            </a>
                        </Signuptest.HeaderText>
                        <Button.Menu onClick={() => logout()} style={{
                            position: 'absolute',
                            right: '20px',
                            width: '120px',
                            zIndex: '99',
                            right: '10%',
                            marginTop: '0'
                        }}>
                            <Button.MenuBackground>
                                <Button.MenuImg
                                    src={
                                        require('../../images/dashboard/left-arrow-menu-button.svg')
                                            .default
                                    }
                                />
                            </Button.MenuBackground>
                            Logout
                        </Button.Menu>
                    </div>
                </Signuptest.HeaderFrame>
                <Signuptest.Frame>
                    <Signuptest.Title>
                        Tell us about your goals
                    </Signuptest.Title>
                    <Signuptest.Text>
                        Please complete these questions to help us improve Luminr.
                    </Signuptest.Text>
                    <div style={{ width: 'calc(15rem + 15vw)', position: 'relative' }} >
                        <Signuptest.InputGroup>
                            <Signuptest.Label placeholder="Your full name">What are you hoping to achieve with Luminr? Select all that apply.</Signuptest.Label>
                        </Signuptest.InputGroup>
                        <Signuptest.Tooltip style={{top:pageWidth<1225 ? '59px' : '40px', display: hopingToAchieveCheck ? 'block' : 'none'}}>Please select all that apply!</Signuptest.Tooltip>
                        <div id="listSelect">
                            <SelectList listItems={selectedPreferences}  />
                        </div>
                        
                        <div id="listSelectOther">
                          <li className={selectedOther ? 'selected' : ''} onClick={handlerClickOther} onMouseOver={()=>setOverOther(true)} onMouseOut={()=>setOverOther(false)} >
                            Other
                            {selectedOther ? <img src={require("../../images/icon_tick_white.svg").default} alt="icon" className="tick-icon" /> : overOther ? 
                            <img src={require("../../images/icon_tick_grey.svg").default} alt="icon" className="tick-icon" /> : ''}
                          </li>
                        </div>
                        <Signuptest.Input
                            placeholder="Personal research"
                            type="text"
                            value={otherOptionTyped}
                            onChange={(e) => handleOtherOption(e)}
                            style={{
                              border: '2px solid #C51852',
                              color: '#000000',
                              display: isOtherDisplayed ? 'block' : 'none'
                            }}
                        /> 
         
                       
                        <Signuptest.ButtonGroup>
                            <Signuptest.ButtonNext onClick={updateHandler} >
                                Continue
                            </Signuptest.ButtonNext>
                        </Signuptest.ButtonGroup>
                    </div>
                </Signuptest.Frame>
                <Signuptest.FooterFrame>
                    © 2023 <a href="https://luminr.ai/">LUMINR</a>. | LUMINR Is Part Of <a href="https://tomorrowgroup.co/">Tomorrow Group Limited</a> | <a href="https://luminr.ai/cookie-policy/">Cookie Policy</a> | <a href="https://luminr.ai/privacy-policy/">Privacy Policy</a>
                </Signuptest.FooterFrame>
            </>
        }
            {children}
        </Signuptest>
    )
}
