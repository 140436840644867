import  axios from 'axios'
import { 
    PAYMENT_LIST_REQUEST,
    PAYMENT_LIST_SUCCESS,
    PAYMENT_LIST_FAIL,

    PAYMENT_DETAILS_REQUEST,
    PAYMENT_DETAILS_SUCCESS,
    PAYMENT_DETAILS_FAIL,
    
    PAYMENT_DETAILS_BY_ID_REQUEST,
    PAYMENT_DETAILS_BY_ID_SUCCESS,
    PAYMENT_DETAILS_BY_ID_FAIL,

    PAYMENT_CREATE_REQUEST,
    PAYMENT_CREATE_SUCCESS,
    PAYMENT_CREATE_FAIL,

    PAYMENT_UPDATE_STATUS_REQUEST,
    PAYMENT_UPDATE_STATUS_SUCCESS,
    PAYMENT_UPDATE_STATUS_FAIL,

    PAYMENT_DELETE_REQUEST,
    PAYMENT_DELETE_SUCCESS,
    PAYMENT_DELETE_FAIL,

 } from '../constants/paymentConstants'


 export const listPayments = (accessToken) => async (dispatch) =>{
    try {
        dispatch({type:PAYMENT_LIST_REQUEST})

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.get(
            `/api/payment/`,
            config
            )

        dispatch({
            type: PAYMENT_LIST_SUCCESS,
            payload: data
        })


    } catch(error){
        dispatch({
            type: PAYMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ?
            error.response.data.message 
            : error.message,
        })
    }
 }

 export const createPayment = (accessToken, payment) => async (dispatch) => {
    try {
       
        dispatch({
            type: PAYMENT_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.post(
            `/api/payment/create/${payment.email}`,
            payment,
            config
        )

        dispatch({
            type: PAYMENT_CREATE_SUCCESS,
            payload: data,
        })

        dispatch(listPaymentDetails(accessToken, payment.email))


    } catch (error) {
        dispatch({
            type: PAYMENT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updatePayment = (accessToken, payment, paymentId) => async (dispatch) => {
    try {
       
        dispatch({
            type: PAYMENT_UPDATE_STATUS_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/payment/update/${paymentId}`,
            payment,
            config
        )

        dispatch({
            type: PAYMENT_UPDATE_STATUS_SUCCESS,
            payload: data,
        })

        dispatch(listPaymentDetailsById(accessToken, paymentId, payment.email))

    } catch (error) {
        dispatch({
            type: PAYMENT_UPDATE_STATUS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listPaymentDetails = (accessToken, email) => async (dispatch) => {
    try{
        dispatch({ type: PAYMENT_DETAILS_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/payment/byEmail/${email}`,
            config
            )

        dispatch({ 
            type: PAYMENT_DETAILS_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: PAYMENT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 
export const listPaymentDetailsById = (accessToken, id, email) => async (dispatch) => {
    try{
        dispatch({ type: PAYMENT_DETAILS_BY_ID_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/payment/byId/${id}/${email}`,
            config,

            )

        dispatch({ 
            type: PAYMENT_DETAILS_BY_ID_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: PAYMENT_DETAILS_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const deletePayment = (accessToken, id) => async (dispatch) => {
    try{
        dispatch({ type: PAYMENT_DELETE_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.delete(
            `/api/payment/delete/${id}`,
            config
            )

        dispatch({ 
            type: PAYMENT_DELETE_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: PAYMENT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }