export const LOGS_NUMBER_REQUEST = 'LOGS_NUMBER_REQUEST'
export const LOGS_NUMBER_SUCCESS = 'LOGS_NUMBER_SUCCESS'
export const LOGS_NUMBER_FAIL = 'LOGS_NUMBER_FAIL'

export const ERRORS_NUMBER_REQUEST = 'ERRORS_NUMBER_REQUEST'
export const ERRORS_NUMBER_SUCCESS = 'ERRORS_NUMBER_SUCCESS'
export const ERRORS_NUMBER_FAIL = 'ERRORS_NUMBER_FAIL'

export const WARNINGS_NUMBER_REQUEST = 'WARNINGS_NUMBER_REQUEST'
export const WARNINGS_NUMBER_SUCCESS = 'WARNINGS_NUMBER_SUCCESS'
export const WARNINGS_NUMBER_FAIL = 'WARNINGS_NUMBER_FAIL'

export const INFO_SUCCESS_NUMBER_REQUEST = 'INFO_SUCCESS_NUMBER_REQUEST'
export const INFO_SUCCESS_NUMBER_SUCCESS = 'INFO_SUCCESS_NUMBER_SUCCESS'
export const INFO_SUCCESS_NUMBER_FAIL = 'INFO_SUCCESS_NUMBER_FAIL'
