import styled from "styled-components"
import {Link as ReachRouterLink} from "react-router-dom"

export const Container = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 76px;
    letter-spacing: 0;
    box-shadow: 0px 3px 10px #0000000B;
    padding: 0 2%;
    justify-content: space-between;

    @media (max-width:1199px) {
        // display: none;
        width: 1200px;
        overflow-x: auto;
    }
`
export const LogoContainer = styled.div`
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;

` 
export const MenuContainer = styled.div`

    width: 55%;
    display: flex;
    flex-direction: row;
    align-items: space-between;

`

export const MenuItemContainer =styled(ReachRouterLink)`
   
    width: 21%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    text-decoration: none;

    transition: width 2s;

    &:hover{
        text-decoration: none;
        background: #F7F7F7;
    }


`

export const SettingsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 17%;
`
export const Item = styled.p`
    size: 16px;

`

export const SerprLogoImg = styled.img`
    width: 90px;
    height: auto;
    object-fit: contain;

    &:hover{
        cursor:pointer;
    }
`
export const BetaLogoImg = styled.img`
    width: 25px;
    height: 15px;
    object-fit: cover;
    margin: auto;
`

export const CompanyLogoImg = styled.img`
    max-width: 153px;
    height: 25px;
    object-fit: cover;
    margin-bottom: 10px;
`

export const ItemImg = styled.img`
    width: 22px;
    height: 21px;
    object-fit: cover;
    margin-right: 10px;

`

export const ProfileImg = styled.img`
    width: 33px;
    height: 33px;
    object-fit: cover;
    margin: 0 2%;

`

export const SettingsImg = styled.img`
    // width: 25px;
    // height: 25px;
    object-fit: cover;
    margin-right: 5%;

`

export const BetaBorder = styled.div`
    width: 40px;
    height: 18px;
    border: 1px solid #1D1D1D;
    border-radius: 2px;
    margin-left: 3px;
    display: flex;
    align-items: center;
`

export const VerticalLine = styled.div`
    border-left: 1px solid #1D1D1D;
    height: 34px;
    margin-left: 2%;
    margin-right: 1%;
    opacity: 23%;
`

export const Link = styled(ReachRouterLink)`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-object;
    margin-right: 5%;

    &:hover {
        text-decoration: none;
    }

`

export const SubHeader = styled.div`
    background: #F7F7F7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 0;
    padding: 0 2%;
    @media (max-width:1199px) {
        // display: none;
        width: 1200px;
        overflow-x: auto;
    }
`

export const SubHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    width: 33%;
`