import React from 'react'
import {Link } from "react-router-dom"
import { useHistory } from 'react-router-dom'
import { Text } from ".././styled-components/dashboard";

function Paginate({
    pages,
    page,
    param = '',
    portfolio = '',
}) {
    let history = useHistory()

    if(param){
        param = param.split('?param=')[1].split('&')[0]
    }
    
    if(portfolio){
        portfolio = portfolio.split('&portfolio=')[1].split('&')[0]
    }
    return (
        pages > 1 && pages < 400 && (
            <div
                style ={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '1%',
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                {/* <img
                    style ={{cursor: 'pointer', visibility: page == 1 ? 'hidden': 'initial'}}
                    
                    src={require("../images/visualization/left_arrow.svg").default}
                /> */}
                <div style ={{
                    display: 'flex',
                    margin: 'auto'
                }}>
                {page > 10 &&
                        <Link
                            to={`${history.location.pathname}?param=${param}&portfolio=${portfolio}&page=${page - 10}`}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                        <div   
                            style={{
                                padding: '10px'
                            }}
                        
                        >
                            <Text.Normal14Black> {'<<'} </Text.Normal14Black>
                        </div>

                        </Link>
                    }
                    {page > 3 &&
                        <Link
                            to={`${history.location.pathname}?param=${param}&portfolio=${portfolio}&page=1`}
                            style={{
                                textDecoration: 'none',

                            }}
                        >
                        <div   
                            style={{
                                padding: '10px'
                            }}
                        
                        >
                            <Text.Normal14Black>1</Text.Normal14Black>
                        </div>

                            
                        </Link>
                    }
                    {page > 4 &&
                        <div   
                            style={{
                                padding: '10px'
                            }}
                        
                        >
                    <Text.Normal14Black> . . . </Text.Normal14Black>
                    </div>
                    }
                    {[...Array(pages).keys()].map((x) => 
                        <>
                        {x >= page - 2 && x < page + 2 && 
                        <Link
                            key={x + 1}
                            to={`${history.location.pathname}?param=${param}&portfolio=${portfolio}&page=${x + 1}`}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            {x+1 === page ? 
                            <div   
                                style={{
									padding: '10px',
									color: '#841E5A',
									fontWeight: 'bold'
								}}
                            
                                >
                                <Text.Normal14Black>{x + 1}</Text.Normal14Black>
                            </div> :
                            <div   
                                style={{
                                    padding: '10px'
                                }}
                            
                                >
                                <Text.Normal14Black>{x + 1}</Text.Normal14Black>
                            </div>
                            }
                            
                        </Link>}
                        </>
                    )}
                    {page < pages - 5 &&
                        <div   
                            style={{
                                padding: '10px'
                            }}
                        
                        >
                    <Text.Normal14Black> . . . </Text.Normal14Black>
                    </div>
                    }
                    {page < pages - 3  &&
                        <Link
                            to={`${history.location.pathname}?param=${param}&portfolio=${portfolio}&page=${pages}`}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                        <div   
                            style={{
                                padding: '10px'
                            }}
                        
                        >
                            <Text.Normal14Black>{pages}</Text.Normal14Black>
                        </div>

                            
                        </Link>
                    }
                    {page < pages - 10 &&
                        <Link
                            to={`${history.location.pathname}?param=${param}&portfolio=${portfolio}&page=${page + 10}`}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                        <div   
                            style={{
                                padding: '10px'
                            }}
                        
                        >
                            <p> {'>>'} </p>
                        </div>

                        </Link>
                    }
                </div>
                {/* <img
                    style ={{cursor: 'pointer', visibility: page == pages ? 'hidden': 'initial'}}
                 
                    src={require("../images/visualization/right_arrow.svg").default}
                /> */}
            </div>
            
        )
    )
}

export default Paginate


//  <Pagination.Item   
// active={x + 1 === page} 
                           
// >
// {x + 1}
// </Pagination.Item>