import {
    PERFORMANCE_LOGGING_REQUEST,
    PERFORMANCE_LOGGING_SUCCESS,
    PERFORMANCE_LOGGING_FAIL,
} from '../../constants/loggingsystem/performanceLoggingConstants'


export const createPerformanceLogReducer = (state = {}, action) => {
    switch (action.type) {
        case PERFORMANCE_LOGGING_REQUEST:
            return { loading: true }

        case PERFORMANCE_LOGGING_SUCCESS:
            return { loading: false, success: true, createPerformanceLog: action.payload }

        case PERFORMANCE_LOGGING_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}