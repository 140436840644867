import React from "react"
import { Container, LogoImg, Dashboard, Section, SectionTop, SectionButtom,
    Companies, Users, Organisation, Websites, Inbox, Email, Name, ProfileImg,PricingPage,
    ItemSelected, Item, DashboardSelected, MainItem, SecondaryItem 
} from "./styles/menu"

export default function Menu({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}



Menu.LogoImg = function MenuLogoImg({to, ...restProps}){
    return  <LogoImg {...restProps} />    
}

Menu.Section = function MenuSection({children, ...restProps}){
    return <Section {...restProps}>{children}</Section>   
}

Menu.SectionTop = function MenuSectionTop({children, ...restProps}){
    return <SectionTop {...restProps}>{children}</SectionTop>   
}

Menu.SectionButtom = function MenuSectionButtom({children, ...restProps}){
    return <SectionButtom {...restProps}>{children}</SectionButtom>   
}

Menu.Dashboard = function MenuDashboard({children, ...restProps}){
    return <Dashboard {...restProps}>{children}</Dashboard>   
}

Menu.DashboardSelected = function MenuDashboardSelected({children, ...restProps}){
    return <DashboardSelected {...restProps}>{children}</DashboardSelected>   
}

Menu.Inbox = function MenuInbox({children, ...restProps}){
    return <Inbox {...restProps}>{children}</Inbox>   
}

Menu.Pricing = function MenuPricing({children, ...restProps}){
    return <PricingPage {...restProps}>{children}</PricingPage>   
}

Menu.Companies = function MenuCompanies({children, ...restProps}){
    return <Companies {...restProps}>{children}</Companies>   
}

Menu.Websites = function MenuWebsites({children, ...restProps}){
    return <Websites {...restProps}>{children}</Websites>   
}

Menu.Users = function MenuUsers({children, ...restProps}){
    return <Users {...restProps}>{children}</Users>   
}

Menu.Item = function MenuItem({children, ...restProps}){
    return <Item {...restProps}>{children}</Item>   
}

Menu.ItemSelected = function MenuItemSelected({children, ...restProps}){
    return <ItemSelected {...restProps}>{children}</ItemSelected>   
}


Menu.Organisation = function MenuOrganisation({children, ...restProps}){
    return <Organisation {...restProps}>{children}</Organisation>   
}

Menu.Email = function MenuEmail({children, ...restProps}){
    return <Email {...restProps}>{children}</Email>   
}

Menu.Name = function MenuName({children, ...restProps}){
    return <Name {...restProps}>{children}</Name>   
}

Menu.ProfileImg = function MenuProfileImg({to, ...restProps}){
    return  <ProfileImg {...restProps} />    
}

Menu.MainItem = function MenuMainItem({children, ...restProps}){
    return <MainItem {...restProps}>{children}</MainItem>   
}

Menu.SecondaryItem = function MenuSecondaryItem({children, ...restProps}){
    return <SecondaryItem {...restProps}>{children}</SecondaryItem>   
}