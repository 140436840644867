import React from "react";
import { General } from '../../styled-components'
import { Home } from '../../styled-components/admin-console'
import {  Content, Grid } from "../../styled-components/dashboard";
import {  Welcome} from "../../styled-components/visualization";



export function SettingsContainer({ accessToken, name, email }) {
  return(
     <Content>
      <Content.SectionTop>
               
        <Grid.SectionFull>
          <Content.FlexRow>
          <div>
            <Home.LargeText>
                Advanced Settings
            </Home.LargeText>
            <Home.LargeText>
            Features are not
            </Home.LargeText>
            <Home.LargeText>
            available on v1
            </Home.LargeText>
            <Home.RedLine></Home.RedLine>
            <Home.SmallText
                style = {{marginTop: '20px'}}
            >
            For any help with the settings of your account please contact
            </Home.SmallText>
            <General.MailTo14Pink href="mailto:customerbraidr@braidr.ai">customerbraidr@braidr.ai </General.MailTo14Pink>
          </div>
        <Home.RightContainer>
            {/* <Home.Img  src={require("../../images/serpr-geometry-1.png").default} /> */}
        </Home.RightContainer>
        </Content.FlexRow>
        </Grid.SectionFull>
        <Welcome.FooterRightImage 
            style={{
                right: '-50px'
            }} 
            src={require("../../images/visualization/04.png").default} />
      </Content.SectionTop>
          
        
      </Content>
  )
}
 