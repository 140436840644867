import React, {useState, useEffect} from 'react'
import { Form, Button, Row, Col, Table} from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'

import { createProfile, listProfileDetails, updateProfile } from '../actions/profileActions'
import { createOrganisation, updateOrganisation, listOrganisationDetails } from '../actions/organisationActions'
import { createCompany, listCompanyDetails } from '../actions/companyActions'

import { COMPANY_UPDATE_RESET, COMPANY_DETAILS_BY_ID_RESET } from '../constants/companyConstants'
import { WEBSITE_DETAILS_RESET } from '../constants/websiteConstants'

function Api_test_screen( { history } ) {

    const dispatch = useDispatch()
    const [accessToken, setAccessToken] = useState('')
    const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    // user details
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [telephone, setTelephone] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [isSuperuser, setIsSuperuser] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isCompanyAdmin, setIsCompanyAdmin] = useState(false)
    const [isCompanyEmployee, setIsCompanyEmployee] = useState(false)

    const profileCreate = useSelector(state => state.profileCreate)
    const {
        //loading: loadingCreate, 
       // error: errorCreate, 
        success: successCreate, 
        profile: createdProfile
    } = profileCreate

    const profileDetails = useSelector(state => state.profileDetails)
    const {
        //loading, 
       // error, 
        success, profile} = profileDetails

    // Organisation details
    const [organisationId, setOrganisationId] = useState('')
    const [organisationName, setOrganisationName] = useState('')
    const [organisationAddress, setOrganisationAddress] = useState('')
    const [organisationTelephone, setOrganisationTelephone] = useState('')
    const [organisationCreatedBy, setOrganisationCreatedBy] = useState('')
    const [organisationCreatedAt, setOrganisationCreatedAt] = useState('')
    const [organisationModifiedAt, setOrganisationModifiedAt] = useState('')
    const [organisationModifiedBy, setOrganisationModifiedBy] = useState('')

    //const organisationCreate = useSelector(state => state.organisationCreate)
    // const {
    //     //loading: loadingCreateOrganisation, 
    //     //error: errorCreateOrganisation, 
    //     //success: successCreateOrganisation, 
    //     // organisation: createdOrganisation
    // } = organisationCreate

   // const organisationUpdate = useSelector(state => state.organisationUpdate)
    // const {
    //     //loading: loadingUpdateOrganisation, 
    //     //error: errorUpdateOrganisation, 
    //    // success: successUpdateOrganisation, 
    //     // organisation: cUpdateOrganisation
    // } = organisationUpdate

    const organisationDetails = useSelector(state => state.organisationDetails)
    const {
        //loading: loadingDetailsOrganisation, 
       // error: errorDetailsOrganisation, 
        success: successDetailsOrganisation, 
        organisation: detailsOrganisation
    } = organisationDetails

    //Company details
    //const [companyId, setCompanyId] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyTelephone, setCompanyTelephone] = useState('')

    //const companyCreate = useSelector(state => state.companyCreate)
    // const {
    //     //loading: loadingCreateCompany, 
    //     //error: errorCreateCompany, 
    //    // success: successCreateCompany, 
    //     // company: createdCompany
    // } = companyCreate

    const companyDetails = useSelector(state => state.companyDetails)
    const {
        //loading: loadingDetailsCompany, 
        //error: errorDetailsCompany, 
        success: successDetailsCompany, 
        company: detailsCompany
    } = companyDetails

    useEffect(()=>{
        const getAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getAccessToken()
        if(!user) {
            loginWithRedirect()
        } else {
            if(accessToken) {
                if(success) {
                    // if profile does not exist
                    if(profile.detail){
                        setName(user.name)
                        setEmail(user.email)
                    } else {
                        setName(profile.name)
                        setEmail(profile.email)
                        setTelephone(profile.telephone)
                        setIsSuperuser(profile.isSuperuser)
                        setIsAdmin(profile.isAdmin)
                        setIsCompanyAdmin(profile.isCompanyAdmin)
                        setIsCompanyEmployee(profile.isCompanyEmployee)
                        setIsActive(profile.isActive)

                        if(successDetailsOrganisation){
                            setOrganisationId(detailsOrganisation.id)
                            setOrganisationName(detailsOrganisation.name)
                            setOrganisationAddress(detailsOrganisation.address)
                            setOrganisationTelephone(detailsOrganisation.telephone)
                            setOrganisationCreatedBy(detailsOrganisation.createdBy)
                            setOrganisationCreatedAt(detailsOrganisation.createdAt)
                            setOrganisationModifiedBy(detailsOrganisation.modifiedBy)
                            setOrganisationModifiedAt(detailsOrganisation.modifiedAt)
                            if(successDetailsCompany){
                                //setCompanyId(detailsCompany.id)
                            } else {
                                dispatch(listCompanyDetails(accessToken, user.email))
                            }
                         } else {
                             dispatch(listOrganisationDetails(accessToken, user.email))
                         }
                        

                        
                    }
                    
                    
                } else {
                    dispatch(listProfileDetails(accessToken, user.email))
                   
                }
            }
        }
    },[user, 
        accessToken, 
        success, 
        createdProfile, 
        successCreate,
        dispatch,
        loginWithRedirect,
        profile,
        getAccessTokenSilently,
        detailsOrganisation,
        successDetailsOrganisation,
        successDetailsCompany
    ])

    //Functions

    //User functions
    const updateHandler = async (e) =>  {
        e.preventDefault()
        const data = {
            name: name,
            email: email,
            telephone: telephone
        }
        dispatch(updateProfile(accessToken, data))
    }

    const createProfileHandler = async (e) =>  {
        e.preventDefault()
        const data = {
            name: name,
            email: email,
            telephone: telephone
        }
        dispatch(createProfile(accessToken, data))
    }

    // Organisation functions
    const createOrganisationHandler = (e) => {
        e.preventDefault()
        const organisation = {
            name: organisationName,
            address: organisationAddress,
            telephone: organisationTelephone,
            email
        }
        dispatch(createOrganisation(accessToken, organisation))
    }

    const editOrganisationHandler = (e) => {
        e.preventDefault()
        const organisation = {
            id: organisationId,
            name: organisationName,
            address: organisationAddress,
            telephone: organisationTelephone,
            email
        }
        dispatch(updateOrganisation(accessToken, organisation))
    }

    // Company functions
    const createCompanyHandler = (e) => {
        e.preventDefault()
        const company = {
            name: companyName,
            address: companyAddress,
            telephone: companyTelephone,
            email,
            id : organisationId
        }
        dispatch(createCompany(accessToken, company))
    }

    const redirectCompanyHandler = (e, companyId) => {
        e.preventDefault()
        dispatch({type: COMPANY_DETAILS_BY_ID_RESET})
        dispatch({type: COMPANY_UPDATE_RESET})
        dispatch({type: WEBSITE_DETAILS_RESET})
        history.push(`/company/details/${companyId}`)
    }

    return (
        <div>
        <Row className="d-flex justify-content-between">
            <Col md={5}>
                <h2>User Profile</h2>
                <Form>
                    <Form.Group controlId='name'>
                        <Form.Label>
                            Name
                        </Form.Label>
                        <Form.Control
                            required
                            type='name'
                            value={name}
                            onChange={(e) =>setName(e.target.value)}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='email'>
                        <Form.Label>
                            Email Address
                        </Form.Label>
                        <Form.Control
                            required
                            type='email'
                            placeholder='Enter Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='telephone'>
                        <Form.Label>
                            Telephone
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Enter Telephone'
                            value={telephone}
                            onChange={(e) => setTelephone(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    {profile && <Form.Group controlId='status'>
                        <Form.Label>
                            Status
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder=''
                            readOnly
                            value={isActive ? 'Active account' : 'Account is not active'}
                        >
                        </Form.Control>
                    </Form.Group>}
                    {profile && <Form.Group controlId='isSuperuser'>
                        <Form.Label>
                            Role
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder=''
                            readOnly
                            value={
                                isSuperuser ? 'Superuser' 
                                : isAdmin ? 'Admin'
                                : isCompanyAdmin ? 'Company Admin'
                                : isCompanyEmployee ? 'Company Employee'
                                : 'No role was assigned for you'
                            }
                        >
                    </Form.Control>
                </Form.Group>}
                </Form>
                <Col md={5}>
                    {!profile.detail ? <Button
                        onClick={updateHandler}
                    variant='primary'
                    >
                    Update
                    </Button> : 
                    <Button
                        onClick={createProfileHandler}
                        variant='primary'
                    >
                    Create
                    </Button>
                    }  
                </Col>      
            </Col>
            <Col md={5}>
                <h2>
                    Organisation
                </h2>

                {profile.detail ? 
                <h4>Organisation missing</h4> :
                <Form>
                        <Form.Group controlId='organisationName'>
                            <Form.Label>
                                Name
                            </Form.Label>
                            <Form.Control
                                required
                                type='name'
                                placeholder='Enter Organisation Name'
                                value={organisationName}
                                onChange={(e) => setOrganisationName(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        
                        <Form.Group controlId='organisationAddress'>
                            <Form.Label>
                                Address
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Enter Organisation Address'
                                value={organisationAddress}
                                onChange={(e) => setOrganisationAddress(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='organisationTelephone'>
                            <Form.Label>
                                Telephone
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder='Enter Organisation Telephone'
                                value={organisationTelephone } 
                                onChange={(e) => setOrganisationTelephone(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        {organisationCreatedBy && <Form.Group controlId='organisationCreatedBy'>
                            <Form.Label>
                                Created By
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={organisationCreatedBy}
                                readOnly
                            >
                            </Form.Control>
                        </Form.Group>}
                        {organisationCreatedAt && <Form.Group controlId='organisationCreatedAt'>
                            <Form.Label>
                                Created At
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={organisationCreatedAt && organisationCreatedAt.substring(0, 10)}
                                readOnly
                            >
                            </Form.Control>
                        </Form.Group>}
                        {organisationModifiedBy && <Form.Group controlId='organisationModifiedBy'>
                            <Form.Label>
                                Modified By
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={organisationModifiedBy}
                                readOnly
                            >
                            </Form.Control>
                        </Form.Group>}
                        {organisationModifiedAt && <Form.Group controlId='organisationModifiedAt'>
                            <Form.Label>
                                Modified At
                            </Form.Label>
                            <Form.Control
                                required
                                type='text'
                                placeholder=''
                                value={organisationModifiedAt && organisationModifiedAt.substring(0, 10)}
                                readOnly
                            >
                            </Form.Control>
                        </Form.Group>}
                        
                        {!detailsOrganisation.detail ?
                        <Button
                            onClick={editOrganisationHandler}
                            variant='primary'
                        >
                            Edit Organisation
                        </Button>
                        :<Button
                            onClick={createOrganisationHandler}
                            variant='primary'
                        >
                            Create Organisation
                        </Button>}
                    </Form>
                }
            </Col>
            
        </Row>
        <br/>
        <br/>
        {/* ORGANISATION */}
        {profile && (profile.isActive && (profile.isSuperuser || profile.isAdmin || profile.isCompanyAdmin)) 
        && detailsOrganisation.name && 
        <Row className="d-flex justify-content-between">
            <Col md={5}>
                <h2> Add Company</h2>
                <Form>
                    <Form.Group controlId='companyName'>
                        <Form.Label>
                            Name
                        </Form.Label>
                        <Form.Control
                            required
                            type='name'
                            placeholder='Enter Company Name'
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    
                    <Form.Group controlId='companyaddress'>
                        <Form.Label>
                            Address
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Enter Company Address'
                            value={companyAddress}
                            onChange={(e) => setCompanyAddress(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='companyTelephone'>
                        <Form.Label>
                            Telephone
                        </Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder='Enter Company Telephone'
                            value={companyTelephone}
                            onChange={(e) => setCompanyTelephone(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Button
                        onClick={createCompanyHandler}
                        variant='primary'
                    >
                        Create Company
                    </Button>
                </Form>
            </Col>
            <Col md={5}>
                <h2>See your companies</h2>
                <Table striped bordered hover responsive className='table-sm'>
                    <thead >
                        <tr>
                            <th>Company Name</th>
                            <th>Created At</th>
                            <th></th>
                        </tr>
                    </thead>
                {detailsCompany.map((company, index) => (
                    <tbody key={index}>
                        <tr>
                            <td>{company.name}</td>
                            <td>{company.createdAt.substring(0, 10)}</td>
                            <td>
                            <Button 
                                variant='primary' 
                                className='btn-sm' 
                                onClick={(e) => redirectCompanyHandler(e, company.id)}
                            >
                                <i className='fas fa-edit' ></i> Edit
                            </Button>
                            </td>
                        </tr>
                    </tbody>
                ))}    
                </Table>
            </Col>
        </Row>}
        </div>
    )
}

export default Api_test_screen
