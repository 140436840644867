import React from "react"
import { Container, Text, TextContainer, Line } from "./styles/footer"

export default function Footer({children, ...restProps}){
    return <Container {...restProps}>{children}</Container>
}

Footer.TextContainer = function FooterTextContainer({children, ...restProps}){
    return <TextContainer {...restProps}>{children}</TextContainer>
}

Footer.Text = function FooterText({children, ...restProps}){
    return <Text {...restProps}>{children}</Text>
}

Footer.Line = function FooterLine({children, ...restProps}){
    return <Line {...restProps}>{children}</Line>
}