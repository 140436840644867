import React from "react";
import { Text} from "../../../styled-components/visualization";
import { FlexColumn, FlexRow } from "../../../styled-components/visualization/content/styles/content";

export function SectionsTitleContainer({
    firstTitle, 
    secondTitle, 
    description, 
    description2
}) {

  return (
    <>
    <FlexColumn
            style={{
            alignItems: 'center',
            marginTop: '12%'
            }}
    >
        <Text.Regular18Grey>
           {firstTitle}
        </Text.Regular18Grey>
        <Text.Medium40Black>
            {secondTitle}
        </Text.Medium40Black>
        <div
            style={{
            padding: description2 ?'2% 4%' : '2% 4% 5%',

            width: '100%'
            
            }}
        >
            <Text.Light16Black>
            {description}
            </Text.Light16Black>
        </div>
       {description2 && <div
            style={{
            padding: '0 4% 5%',
            width: '100%'
            
            }}
        >
            <Text.Light16Black>
            {description2}
            </Text.Light16Black>
        </div>}
    </FlexColumn>

          </>
  )
}
