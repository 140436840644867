import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    CHANGE_PASSWORD_RESET,

    SEND_EMAIL_VERIFICATION_REQUEST,
    SEND_EMAIL_VERIFICATION_SUCCESS,
    SEND_EMAIL_VERIFICATION_FAIL,
} from '../constants/emailSenderConstants'

export const changePasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return { loading: true }

        case CHANGE_PASSWORD_SUCCESS:
            return {
                loading: false,
                success: true,
                changePassword: action.payload,
            }

        case CHANGE_PASSWORD_FAIL:
            return { loading: false, error: action.payload }
        
        case CHANGE_PASSWORD_RESET: 
            return {}

        default:
            return state
    }
}

export const sendEmailVerificationReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_EMAIL_VERIFICATION_REQUEST:
            return { loading: true }

        case SEND_EMAIL_VERIFICATION_SUCCESS:
            return {
                loading: false,
                success: true,
                verification: action.payload,
            }

        case SEND_EMAIL_VERIFICATION_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}
