import React, {useState} from 'react'
import { Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

function SearchBox() {
    const [param, setParam] = useState('')

    let history = useHistory()

    const submitHandler = (e) => {
        e.preventDefault()
        if(param){
            history.push(`${history.location.pathname}?param=${param}&page=1`)
        } else {
            history.push(history.push(history.location.pathname))
        }
    }

    return (
        <Form onSubmit={submitHandler} inline>
            {/* <Form.Control
                type='text'
                name='q'
                onChange={(e) => setParam(e.target.value)}
            >
            </Form.Control> */}
            <input
                placeholder="Search..."
                type='text'
                name='q'
                onChange={(e) => setParam(e.target.value)}
                style={{
                    // position: 'absolute',
                    top: '107px',
                    right: '50px',
                    margin: '0',
                    height: '40px',
                    padding: '0 10px',
                    background: 'transparent',
                    border: '2px solid rgb(197, 24, 82)',
                    color: '#222222',
                    borderRadius: '4px'
                }}
            />
            {/* <Button.Save
                style={{height: '40px'}}
                onClick={()=>addUserHandler()}
                disabled={!emailIsValid || userEmail===''}
            >
                Search user
            </Button.Save> */}
            <Button
                type='submit'
                variant='outline-success'
                className='p-2'
                style={{    
                    width: 'auto',
                    height: '40px',
                    color: '#FFFFFF',
                    background: '#C51852',
                    borderRadius: '4px',
                    border: '2px solid #C51852',
                    marginLeft: '16px',
                    textTransform: 'capitalize'
                }}
            >
                Search user
            </Button>
        </Form>
    )
}

export default SearchBox
