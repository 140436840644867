import React, { useEffect,useState } from "react";

import { Text, Content } from "../../../styled-components/visualization";
import { CounterContainer } from "../utilComponents/Counter";
import { TitleContainer } from "../utilComponents/Title";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";
import LoaderImage from "../../../components/LoaderImage";
import { nFormatter } from "../resultsComponents/serpScoreboard/sortingUtils";
import { COLOR_PALETTE_1, COLOR_PALETTE_2, COLOR_PALETTE_3, COLOR_PALETTE_4, COLOR_PALETTE_5, COLOR_PALETTE_6, COLOR_PALETTE_7, COLOR_PALETTE_8 } from "../../../constants/theme/colours";


export function FeatureSnippetContainer({loadingFeaturedSnippet,successFeaturedSnippet,featuredSnippetOppResults,featuredSnippetOppTotalRows,pageNoFeatureSnippet,setPageNoFeatureSnippet}) {

    const [numberOfPages, setNumberOfPages] = useState(0);
    const [colorIntervals, setColorIntervals] = useState([])

     //sorting columns
    const [sortedResults, setSortedResults] = useState(featuredSnippetOppResults);
    const [sortConfig, setSortConfig] = useState({ key: 'snippet_rl_score', direction: 'descending' });//default table is sorted via Opportunity Score


    if(numberOfPages == 0 && featuredSnippetOppTotalRows!=0){
      setNumberOfPages(Math.ceil(featuredSnippetOppTotalRows/100))
    }
    const nextPage = () => {
      if (pageNoFeatureSnippet < numberOfPages) {
          setPageNoFeatureSnippet(pageNoFeatureSnippet + 1)
        }
    }

    const previousPage = () => {
        if (pageNoFeatureSnippet > 1) {
          setPageNoFeatureSnippet(pageNoFeatureSnippet -1)
        } 
    }

    const paginationLine = () =>{
        return (
            <Content.FlexRow
            style ={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '3%'
            }}
        >
        <Content.PaginationIcon
        onClick={()=>previousPage()}
        src={require("../../../images/visualization/left_arrow.svg").default}/>
        {pageNoFeatureSnippet !== numberOfPages ?
          <Text.Regular13Grey>{(pageNoFeatureSnippet-1)*100+1} - {pageNoFeatureSnippet*100} / {featuredSnippetOppTotalRows}</Text.Regular13Grey> :
          <Text.Regular13Grey>{(pageNoFeatureSnippet-1)*100+1} - {featuredSnippetOppTotalRows} / {featuredSnippetOppTotalRows}</Text.Regular13Grey>
        }
        
        <Content.PaginationIcon
        onClick={() =>nextPage()}
        src={require("../../../images/visualization/right_arrow.svg").default}/>
        </Content.FlexRow>
        )
    }


    const csvHeader = [
      { label: "Site", key: "google_domain" },
      { label: "Search Query", key: "keyword" },
      { label: "Device", key: "device" },
      { label: "Curent URL", key: "snippet_main_url" },
      { label: "Your Best Organic URL", key: "organic_client_url" },
      { label: "Your Best SEO Rank", key: "best_organic_rank" },
      { label: "Avg. Monthly Searches", key: "search_volume" },
      { label: "Opportunity Score", key: "snippet_rl_score" },
      { label: "Stability", key: "snippet_stability" }
     
    ];

    useEffect( ()=>{
       //find maxValue
      let maxValue = 0;
      if(featuredSnippetOppResults){
        if(featuredSnippetOppResults.length !=0){
          if(featuredSnippetOppResults.detail == undefined){
            featuredSnippetOppResults.map((result,i) => {
              if(result.snippet_rl_score > maxValue) maxValue = result.snippet_rl_score;
            })
            createMaxValueIntervals(maxValue);
          }
        }
      }
      setSortedResults(featuredSnippetOppResults)
      
    },[featuredSnippetOppResults]) 


    const createMaxValueIntervals = (maxVal) =>{
      // Calculate the size of each interval
       const intervalSize = maxVal / 8;

       // Create the intervals with colors
       const intervals = [];
       for (let i = 1; i <= 8; i++) { // 8 intervals
       const intervalStart = intervalSize * (i - 1);
       const intervalEnd = intervalSize * i;
       let color;
       switch (i) {
           case 1:
           color = COLOR_PALETTE_1;
           break;
           case 2:
           color = COLOR_PALETTE_2;
           break;
           case 3:
           color = COLOR_PALETTE_3;
           break;
           case 4:
           color = COLOR_PALETTE_4;
           break;
           case 5:
           color = COLOR_PALETTE_5;
           break;
           case 6:
           color = COLOR_PALETTE_6;
           break;
           case 7:
           color = COLOR_PALETTE_7;
           break;
           case 8:
           color = COLOR_PALETTE_8;
           break;
           default:
           color = 'black'; // or any default color
       }
       intervals.push({ start: intervalStart, end: intervalEnd, color });
       }

       setColorIntervals(intervals);
   }

  const sortTable = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    
    const sortedData = sortTable(sortedResults, key, direction);
    setSortedResults(sortedData);
    setSortConfig({ key, direction });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

    const table = () => {
        return( 
            <>
           <Content.FlexRow className={'sortableTable'}
        style={{
            justifyContent:'space-between',
            width: '100%'
        }}
      >
          <Content.FlexColumn  style={{ width: '42px'  }}> </Content.FlexColumn>
          <Text.Regular13Grey style={{width:'8%'}}>Site</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'10%'}}>Search Query</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'6%'}}>Device</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'23%'}}>Curent URL</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'23%'}}>Your Best Organic URL</Text.Regular13Grey>
          <Text.Regular13Grey style={{width:'5%', textAlign: 'center'}}>Your Best SEO Rank</Text.Regular13Grey>
          <Text.Regular13Grey className={sortConfig.key === 'search_volume' ? 'bold-text' : ''} style={{width:'5%', textAlign: 'center',cursor:'pointer'}} onClick={() => handleSort('search_volume')}>Avg. Monthly Searches <span>{renderSortArrow('search_volume')}</span></Text.Regular13Grey>
          <Text.Regular13Grey className={sortConfig.key === 'snippet_rl_score' ? 'bold-text' : ''} style={{width:'5%', textAlign: 'center',cursor:'pointer'}} onClick={() => handleSort('snippet_rl_score')} >Opportunity Score <span>{renderSortArrow('snippet_rl_score')}</span></Text.Regular13Grey>
          <Text.Regular13Grey className={sortConfig.key === 'snippet_stability' ? 'bold-text' : ''} style={{width:'5%', textAlign: 'center', marginRight: '10px',cursor:'pointer'}} onClick={() => handleSort('snippet_stability')} >Stability <span>{renderSortArrow('snippet_stability')}</span></Text.Regular13Grey>
      </Content.FlexRow>
       

      {sortedResults && 
      <Content.DropDownContainer
        style={{
            marginTop:'2%',
            height: '550px'
        }}
      >
        {sortedResults.map((result,i) => (
                <Content.HoverRow
                    style={{
                        alignItems: 'center',
                        marginTop: '0%'
                    }}
                >
                
                <Content.FlexColumn style={{ width: '30px' }}>
                <CounterContainer number={(pageNoFeatureSnippet-1)*100+i+1} />
                </Content.FlexColumn>
                    <Text.Regular14Black style={{width: '8%', overflowWrap: 'break-word'}}>{result.google_domain}</Text.Regular14Black>   
                    <Text.Regular14Black style={{width: '10%', overflowWrap: 'break-word'}}> {result.keyword} </Text.Regular14Black>
                    <Text.Regular14Black style={{width: '6%', overflowWrap: 'break-word'}}> {result.device} </Text.Regular14Black>
                    <Text.Light14BlackLink href={result.snippet_main_url} title={result.snippet_main_url} target="_blank" style={{width: '23%', overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}> {result.snippet_main_url} </Text.Light14BlackLink>
                    
                    {result.organic_client_url ? 
                      <Text.Light14BlackLink href={result.organic_client_url} title={result.organic_client_url} target="_blank" style={{width: '23%', overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}> {result.organic_client_url} </Text.Light14BlackLink>
                      :
                      <Text.Regular14Black style={{width: '23%', overflowWrap: 'break-word'}}>None available</Text.Regular14Black>
                    }
                    <Text.Regular14Black style={{width: '5%', textAlign: 'center'}}> {result.best_organic_rank ? result.best_organic_rank : '-'} </Text.Regular14Black>
                    <Text.Regular14Black style={{width: '5%', textAlign: 'center'}}> {result.search_volume<1000 ? (result.search_volume)?.toFixed(0) : nFormatter(result.search_volume) } </Text.Regular14Black>
                    
                    {
                      
                        colorIntervals.map((range, i) => (
                            result.snippet_rl_score > range.start && result.snippet_rl_score <= range.end && 
                             <Text.Regular14WhitePurpleLightBackground style={{width: '5%',background:range.color}}>
                                {nFormatter(result.snippet_rl_score)}%
                            </Text.Regular14WhitePurpleLightBackground> 
                        ))
                    }
                    {/* negative values that are not stored in colorIntervals */}
                    {
                       result.snippet_rl_score <=0 && 
                       <Text.Regular14WhitePurpleLightBackground style={{width: '5%',background:'black'}}>
                         {nFormatter(result.snippet_rl_score)}%
                       </Text.Regular14WhitePurpleLightBackground> 
                    }

                    <Text.Regular14Black style={{width: '5%', textAlign: 'center'}}> {(result.snippet_stability*100).toFixed(0)}% </Text.Regular14Black>
                    
                </Content.HoverRow>
        ))}
        
      </Content.DropDownContainer>}
      {paginationLine()}
      </>
        )
    }
return(
    <Content.FlexColumn
        style={{
                justifyContent: 'space-between',
                padding: '1%',
                background: 'white',
                width: '100%'
            }}
        >
     <TitleContainer 
         title='Featured Snippet Takeovers' 
         subtitle='Get more eyeballs on your site with Featured Snippet opportunities ordered by difficulty.'
         backdropTop = '175vw'    
         pages={insightsText.FeatureSnippetOpportunities[0]}
          p11={insightsText.FeatureSnippetOpportunities[1]}
          p21={insightsText.FeatureSnippetOpportunities[2]}
          p31={insightsText.FeatureSnippetOpportunities[3]}
          p41={insightsText.FeatureSnippetOpportunities[4]}
          assistantMessage={assistantText.FeatureSnippetOpportunities}          
          csvData={featuredSnippetOppResults}
          csvHeader={csvHeader}
         />
      {
        (loadingFeaturedSnippet || !successFeaturedSnippet) ?
          <LoaderImage height={'550px'} width={"100%"}/> 
          : 
          featuredSnippetOppResults.length != 0? 
              featuredSnippetOppResults.detail !== undefined ? 
                  <ResultsMissing details = {featuredSnippetOppResults.detail}/>
                    : 
                    <Content.FlexColumn
                      style={{
                        justifyContent:'space-between',
                        marginTop:'2%'
                      }}
                    >
                      {table()}
                    </Content.FlexColumn>
            : 
            <ResultsMissing details = {featuredSnippetOppResults.detail} missingOpportunitiesMessage={"No opportunities found"}/>
      }
 </Content.FlexColumn> 

)

}
