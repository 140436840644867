import React, { useState, useContext} from "react";
import { Text, Content, Button } from "../../../styled-components/visualization";
import { useHistory } from "react-router-dom"
import { TitleContainer } from "../utilComponents/Title";

import { DropDownFilterContainer } from "./DropDownFilter";
import { assistantText } from "../utilComponents/InsightsText";
import { DateFilterContainer } from "./DateFilter";
import { FilterContext } from "../../../contexts/FilterContext";
import { GET_FILTERS_RESET,
    BIG_KEYWORD_RANKING_RESET, 
    COMPETITOR_CHANNEL_RESET, 
    COMPETITOR_LANDSCAPE_RESET, 
    COMPETITOR_SWOT_HEATMAP_RESET, 
    CONTENT_EXPLORER_RESET, 
    FEATURED_SNIPPED_OPPORTUNITIES_RESET, 
    HVN_SCORE_RESET, 
    MONTHLY_MOVEMENTS_RESET, 
    RETRIEVE_SWOT_RESET, 
    SERP_COMPOSITION_RESET,
    SERP_COMPOSITION_INCLUDE_RESET,
    SERP_LEAGUE_RESET,

} from "../../../constants/visualization/dataScienceAPIsConstants";
import { useDispatch } from "react-redux";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { DropDownActiveFilterContainer } from "./DropDownActiveFilter";


 

export function FilterContainer({filtersResults, filter_criteria, setFilter_criteria, setFirstRender}) {
    const dispatch = useDispatch();
    const [filtersApplied, setFiltersApplied] = useState()
    const [toggleFocus, setToggleFocus] = useState(true)
    const [toggleLocalisation, setToggleLocalisation] = useState(true)
    const [toggleSerpr, setToggleSerpr] = useState(true)


    const {
        exclude_array_keyword_parent,
        exclude_array_keyword_child,
        exclude_array_keyword_portfolio,
        exclude_array_keyword_subportfolio,
        exclude_array_keyword,
        exclude_array_country,
        exclude_array_location,
        exclude_array_language,  
        exclude_array_device,
        exclude_array_domain,
        exclude_array_component_clean,
        exclude_array_brand,
        initial_array_keyword_parent,
        initial_array_keyword_child,
        initial_array_keyword_portfolio,
        initial_array_keyword_subportfolio,
        initial_array_keyword,
        initial_array_country,
        initial_array_location,
        initial_array_language,  
        initial_array_device,
        initial_array_domain,
        initial_array_component_clean,
        initial_array_brand
      } = useContext(FilterContext)

    const {setDate} = useContext(CompanyContext)

    const resetFilters = () => {
        dispatch({type: GET_FILTERS_RESET})

        dispatch({type: BIG_KEYWORD_RANKING_RESET})
        dispatch({type: SERP_COMPOSITION_RESET})
        dispatch({type: SERP_COMPOSITION_INCLUDE_RESET})
        dispatch({type: CONTENT_EXPLORER_RESET})
        dispatch({type: RETRIEVE_SWOT_RESET})
        dispatch({type: RETRIEVE_SWOT_RESET})
        dispatch({type: FEATURED_SNIPPED_OPPORTUNITIES_RESET}) 

        //COMPETITION
        dispatch({type: COMPETITOR_LANDSCAPE_RESET})
        dispatch({type: COMPETITOR_SWOT_HEATMAP_RESET})
        dispatch({type: SERP_LEAGUE_RESET})
        dispatch({type: MONTHLY_MOVEMENTS_RESET})
        dispatch({type: HVN_SCORE_RESET})
        dispatch({type: COMPETITOR_CHANNEL_RESET})

        exclude_array_keyword_parent.splice(0,exclude_array_keyword_parent.length)
        exclude_array_keyword_child.splice(0,exclude_array_keyword_child.length)
        exclude_array_keyword_portfolio.splice(0,exclude_array_keyword_portfolio.length)
        exclude_array_keyword_subportfolio.splice(0,exclude_array_keyword_subportfolio.length)
        exclude_array_keyword.splice(0,exclude_array_keyword.length)
        exclude_array_country.splice(0,exclude_array_country.length)
        exclude_array_location.splice(0,exclude_array_location.length)
        exclude_array_language.splice(0,exclude_array_language.length)
        exclude_array_device.splice(0,exclude_array_device.length)
        exclude_array_domain.splice(0,exclude_array_domain.length)
        exclude_array_component_clean.splice(0,exclude_array_component_clean.length)
        exclude_array_brand.splice(0,exclude_array_brand.length)

        initial_array_keyword_parent.splice(0,initial_array_keyword_parent.length)
        initial_array_keyword_child.splice(0,initial_array_keyword_child.length)
        initial_array_keyword_portfolio.splice(0,initial_array_keyword_portfolio.length)
        initial_array_keyword_subportfolio.splice(0,initial_array_keyword_subportfolio.length)
        initial_array_keyword.splice(0,initial_array_keyword.length)
        initial_array_country.splice(0,initial_array_country.length)
        initial_array_location.splice(0,initial_array_location.length)
        initial_array_language.splice(0,initial_array_language.length)
        initial_array_device.splice(0,initial_array_device.length)
        initial_array_domain.splice(0,initial_array_domain.length)
        initial_array_component_clean.splice(0,initial_array_component_clean.length)
        initial_array_brand.splice(0,initial_array_brand.length)


        setFirstRender(true)

        //reset calendar to previous 30 day
        if(filtersResults){
            if(filtersResults[0].latest){
                var lastAnalysisDate = filtersResults[0].latest;
                var lastAnalysis = new Date(lastAnalysisDate);
                var lastAnalysisPlusOne = new Date(lastAnalysis.setDate(lastAnalysis.getDate()+1)).toISOString().slice(0, 10);
                var lastMonth = new Date(lastAnalysis.setDate(lastAnalysis.getDate()-33)).toISOString().slice(0, 10);
                var dateT = [lastMonth, lastAnalysisPlusOne]
                setDate(dateT)
            }
        }
    }

    let history = useHistory()

    const setLayerResetFilters = () => {
        //console.log(history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100))
        window.dataLayer.push({
          event: "button_click",
          button_type: "reset",
          element_name: 'reset filter',
          page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
          project_name: JSON.parse(localStorage.getItem('websiteName'))
        });
        
      }

    const setLayerExpand = (dropdown_name) => {
    window.dataLayer.push({
        event: "filters_expand",
        filter_name: dropdown_name,
        page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
        project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
    
    }
   

  return (
    <Content.FlexColumn
        style={{
            justifyContent: 'space-between',
            padding: '1%'
            }}
    >
    
        <Content.FlexRow
            style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexAlign: 'center',
            height: '100px'
            }}
        >
            <Text.Medium22Black>Filter Results</Text.Medium22Black>
                
                <Button.Primary

                    onClick={()=>{
                        setLayerResetFilters()
                        resetFilters()
                    }
                   
                    }
                >
                    Reset filter
                </Button.Primary>
            {/* </Content.FlexRow> */}
        </Content.FlexRow>
        <Content.FlexRow
            style={{
                justifyContent: 'space-between',
                marginTop: '1%'
            }}
        >
           <DateFilterContainer filtersResults={filtersResults}/>
            <Content.FlexColumn
            style={{
                    width: '23%',

                }}
            >
            
                <TitleContainer title='Focus' type='small' 
                assistantMessage={assistantText.Focus}/>
                <Content.FlexRow 
                    className={"filter-row"} 
                    onClick={() => {
                        setToggleFocus(!toggleFocus)
                        setLayerExpand('focus')
                    } 
                    }
                >
                 <Text.Regular16Purple>Add new focus filter</Text.Regular16Purple>
                 {toggleFocus ?
                    <Content.Icon
                    src={require("../../../images/visualization/Expand_Filters.svg").default}/> 
                    :
                    <>
                    <Content.Icon
                    src={require("../../../images/visualization/Remove_Filters.svg").default}/>
                    </>
                   }
                </Content.FlexRow>

                { !toggleFocus && 
                <Content.FlexColumn
                style={{

                    position:'relative'
                }}
                >
                {filtersResults[0]  &&
                <>

                    {initial_array_keyword_parent.length !=0 &&
                    <DropDownFilterContainer 
                        title={'Keyword Parent'} 
                        type={'keyword_parent_'} 
                        filtersResults={filtersResults}
                        filter_criteria={filter_criteria}
                        setFilter_criteria={setFilter_criteria}
                        setFiltersApplied={setFiltersApplied}
                        filtersApplied={filtersApplied}
                    />
                    }

                    {initial_array_keyword_child.length !=0 &&
                    <DropDownFilterContainer title={'Keyword Child'} type={'keyword_child_'} filtersResults={filtersResults} filter_criteria={filter_criteria} setFiltersApplied={setFiltersApplied}
                        filtersApplied={filtersApplied}/>
                    }

                    {initial_array_keyword_portfolio.length !=0 &&
                        <DropDownFilterContainer title={'Keyword Portfolio'} type={'keyword_portfolio_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                        filtersApplied={filtersApplied}/>
                    }

                    {initial_array_keyword_subportfolio.length !=0 && 
                     <DropDownFilterContainer title={'Keyword Subportfolio'} type={'keyword_subportfolio_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                     filtersApplied={filtersApplied}/>
                    }
                   
                    <DropDownFilterContainer title={'Keywords'} type={'keyword_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                        filtersApplied={filtersApplied}/>
                    
                    {/* brand filter */}
                    <DropDownFilterContainer title={'Brand Terms'} type={'brand_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                    filtersApplied={filtersApplied}/>
                </>
                }
                </Content.FlexColumn>
                }
                {/* active filters */}
                {toggleFocus &&
                    <DropDownActiveFilterContainer 
                    type={'focus'} 
                    filter_criteria={filter_criteria}
                    setFilter_criteria={setFilter_criteria}
                    setFirstRender = {setFirstRender}
                />}

            </Content.FlexColumn>
            <Content.FlexColumn
            style={{
                    width: '23%'
                }}
            >
                <TitleContainer title='Localisation' type='small' 
                assistantMessage={assistantText.Localisation}
                />
                <Content.FlexRow 
                    className={"filter-row"} 
                    onClick={() => {
                        setLayerExpand('localisation')
                        setToggleLocalisation(!toggleLocalisation)
                        }
                    }
                >
                 <Text.Regular16Purple>Add new localisation filter</Text.Regular16Purple>
                 {toggleLocalisation ?
                    <Content.Icon
                    src={require("../../../images/visualization/Expand_Filters.svg").default}/> 
                    :
                    <>
                    <Content.Icon
                    src={require("../../../images/visualization/Remove_Filters.svg").default}/>
                    </>
                   }
                </Content.FlexRow>
                { !toggleLocalisation && 
                  <Content.FlexColumn
                  style={{
  
                      position:'relative'
                  }}
                  >
                  {filtersResults[0]  &&
                  <>
                      <DropDownFilterContainer title={'Markets'} type={'country_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                          filtersApplied={filtersApplied}/>
                      <DropDownFilterContainer title={'Google Domain'} type={'google_domain_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                          filtersApplied={filtersApplied}/>
                      <DropDownFilterContainer title={'Languages'} type={'language_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                          filtersApplied={filtersApplied}/>
                      <DropDownFilterContainer title={'Devices'} type={'device_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                          filtersApplied={filtersApplied}/>
                   </>}
                  </Content.FlexColumn>
                }
                {/* active filters */}
                {toggleLocalisation &&
                    <DropDownActiveFilterContainer 
                    type={'localisation'} 
                    filter_criteria={filter_criteria}
                    setFilter_criteria={setFilter_criteria}
                    setFirstRender = {setFirstRender}
                />}
              
            </Content.FlexColumn>
            <Content.FlexColumn
            style={{
                    width: '23%'
                }}
            >
                <TitleContainer title='SERP' type='small' 
                assistantMessage={assistantText.SERP}
                />
                <Content.FlexRow 
                    className={"filter-row"} 
                    onClick={() => {
                        setLayerExpand('serp')
                        setToggleSerpr(!toggleSerpr)
                        }
                    
                    }
                >
                 <Text.Regular16Purple>Add new SERP filter</Text.Regular16Purple>
                 {toggleSerpr ?
                    <Content.Icon
                        src={require("../../../images/visualization/Expand_Filters.svg").default}/> 
                        :
                        <>
                        <Content.Icon
                        src={require("../../../images/visualization/Remove_Filters.svg").default}/>
                        </>
                   }
                </Content.FlexRow>
                {!toggleSerpr && 
                 <Content.FlexColumn
                 style={{
 
                     position:'relative'
                 }}
                 >
                     <DropDownFilterContainer title={'Websites'} type={'domain_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                         filtersApplied={filtersApplied}/>
                     <DropDownFilterContainer title={'SERP Features'} type={'component_clean_'} filtersResults={filtersResults} filter_criteria={filter_criteria}setFiltersApplied={setFiltersApplied}
                         filtersApplied={filtersApplied}/>
                 </Content.FlexColumn>
                }
                 {/* active filters */}
                 {toggleSerpr &&
                    <DropDownActiveFilterContainer 
                    type={'serpr'} 
                    filter_criteria={filter_criteria}
                    setFilter_criteria={setFilter_criteria}
                    setFirstRender = {setFirstRender}
                />}
               
            </Content.FlexColumn>
           
        </Content.FlexRow>
       
      </Content.FlexColumn>
  )
}
