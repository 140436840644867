import React, { useState } from "react";
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import { Text, Content } from "../../../../styled-components/visualization";
import { CounterContainer } from "../../utilComponents/Counter";
import {

  extractMonthFromDate,

} from "./competitorMovementsUtils";

export const CompetitorMovementsTable = (props) => {
  const [numberOfPages] = useState(Math.ceil(props.resultsTotalRows / 100));
  const [currentPage, setCurrentPage] = useState(1);
  console.log(props)

  const nextPage = () => {
    if (props.pageNoCompetitorMovements < numberOfPages) {
      props.setPageNoCompetitorMovements(props.pageNoCompetitorMovements + 1)
    }
  };

  const previousPage = () => {
    if (props.pageNoCompetitorMovements > 1) {
      props.setPageNoCompetitorMovements(props.pageNoCompetitorMovements -1)
    }
  };
  const paginationLine = () =>{
    return (
        <Content.FlexRow
        style ={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '3%'
        }}
     >
     <Content.PaginationIcon
     onClick={()=>previousPage()}
     src={require("../../../../images/visualization/left_arrow.svg").default}/>
     {props.pageNoCompetitorMovements !== numberOfPages ?
      <Text.Regular13Grey>{(props.pageNoCompetitorMovements-1)*100+1} - {props.pageNoCompetitorMovements*100} / {props.resultsTotalRows}</Text.Regular13Grey> :
      <Text.Regular13Grey>{(props.pageNoCompetitorMovements-1)*100+1} - {props.resultsTotalRows} / {props.resultsTotalRows}</Text.Regular13Grey> // replace 2000 with max rows in bigquery
     }
     
     <Content.PaginationIcon
    onClick={() =>nextPage()}
     src={require("../../../../images/visualization/right_arrow.svg").default}/>
     </Content.FlexRow>
    )
  }

  const tableHeadTrackedCompetitors = ()=>{
    return (
      <Content.FlexRow
        style={{
          marginTop: "2%",
          justifyContent: "space-between",
          width: '99%',
          marginLeft: '5px'

        }}
      >
        <Content.FlexColumn
          style={{
            width: "30px",
          }}
        >
          <Text.Regular13Grey></Text.Regular13Grey>
        </Content.FlexColumn>
      
        {/* <Content.FlexColumn
          style={{
            width: "100%",
          }}
        >
          <Text.Regular13Grey>Website</Text.Regular13Grey>
        </Content.FlexColumn> */}
        
      </Content.FlexRow>
    )
  }

  const tableHead = () =>{
    return (
      <Content.FlexRow
        style={{
          marginTop: "2%",
          justifyContent: "space-between",
          width: '99%',
          marginLeft: '5px'

        }}
      >
        <Content.FlexColumn
          style={{
            width: "30px",
          }}
        >
          <Text.Regular13Grey></Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "9%",
          }}
        >
          <Text.Regular13Grey>Month</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "12%",
          }}
        >
          <Text.Regular13Grey>Website</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "15%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Main Group</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "11%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Subgroup</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "5%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Market</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Scale</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Movement</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Impact Score</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "5%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Keywords</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "5%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Avg. Position</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "5%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Previous</Text.Regular13Grey>
        </Content.FlexColumn>
      </Content.FlexRow>
    )
  }


  const tableBodyTrackedCompetitors = () =>{
    return (
        <Content.DropDownContainer  style={{maxHeight:'400px'}}>
        {props.results.map((row, i) => (
            <Content.HoverRow style={{alignItems:'center'}}>
              <Content.FlexColumn
                style={{
                  width: "30px",
                }}
              >
                <Content.FlexRow>
                  <CounterContainer number={(props.pageNoCompetitorMovements-1)*100+i+1}  />
                </Content.FlexRow>
              </Content.FlexColumn>
              
              <Content.FlexColumn
                style={{
                  width: "100%",
                  overflowWrap: "break-word",
                }}
              >
                <Text.Light14BlackLink href={'//'+ (row.domain ? row.domain : row.competitor_website)} target="_blank" style={{width: '100%', overflowWrap: 'break-word'}}> {(row.domain ? row.domain : row.competitor_website)} </Text.Light14BlackLink>
              </Content.FlexColumn>
      
            </Content.HoverRow>
        ))}
        
        </Content.DropDownContainer>
    )
  }

  const tableBody = () => {
    return (
      <Content.DropDownContainer  >
        {props.results.map((row, i) => (
            <Content.HoverRow>
              <Content.FlexColumn
                style={{
                  width: "30px",
                }}
              >
                <Content.FlexRow>
                  <CounterContainer number={(props.pageNoCompetitorMovements-1)*100+i+1}  />
                </Content.FlexRow>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "9%",
                }}
              >
                <Content.FlexRow>
                  <Text.Regular14Black>
                    {extractMonthFromDate(row.date_month)}
                  </Text.Regular14Black>
                </Content.FlexRow>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "12%",
                  overflowWrap: "break-word",
                }}
              >
                <Text.Light14BlackLink href={'//'+row.domain} target="_blank" style={{width: '100%', overflowWrap: 'break-word'}}> {row.domain} </Text.Light14BlackLink>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "15%",
                  textAlign: "center",
                  overflowWrap: "break-word",
                }}
              >
                <Text.Regular14Black>{row.keyword_group_1}</Text.Regular14Black>
              </Content.FlexColumn>

              <Content.FlexColumn
                style={{
                  width: "11%",
                  textAlign: "center",
                  overflowWrap: "break-word",
                }}
              >
                <Text.Regular14Black>{row.keyword_group_2}</Text.Regular14Black>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "5%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>{row.country}</Text.Regular14Black>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "10%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>{row.scale}</Text.Regular14Black>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "10%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>{row.category}</Text.Regular14Black>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "10%",
                  textAlign: "center",
                }}
              >
                <Content.FlexRow
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                    }}
                  >
                    <Text.Regular14Black>
                      {row.severity_score.toFixed(2)}
                    </Text.Regular14Black>
                  </div>
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <ProgressBar
                      variant="success"
                      now={row.severity_score.toFixed(2)}
                      //animated='true'
                      label=""
                      max="10"
                    />
                  </div>
                </Content.FlexRow>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "5%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>{row.keyword_count ? row.keyword_count : 0}</Text.Regular14Black>
              </Content.FlexColumn>

              <Content.FlexColumn
                style={{
                  width: "5%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>{row.avg_position ? row.avg_position.toFixed(2) : 0}</Text.Regular14Black>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "5%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>
                  {row.previous_avg_position ? row.previous_avg_position.toFixed(2) : "-"}
                </Text.Regular14Black>
              </Content.FlexColumn>
              </Content.HoverRow>
        ))}
        
      </Content.DropDownContainer>
    )
  }

  return (
   <>
      {props.trackedCompetitorsComponent ?

        <Content.FlexColumn style={{height:'350px'}}>
          <>
            {tableHeadTrackedCompetitors()}
            {tableBodyTrackedCompetitors()}
          </>
          {paginationLine()}
        </Content.FlexColumn>
        
        :
       
        <Content.FlexColumn style={{height:'450px'}}>
          <>
            {tableHead()}
            {tableBody()}
          </>
          {paginationLine()}
        </Content.FlexColumn>
        
      } 
    </>
  );
};
