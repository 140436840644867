export const SERPR_PURPLE = "#841F5B"
export const SERPR_BLACK = "#1D1D1D"
export const SERPR_GREY = "#F7F7F7"
export const GREEN = "#3DA804"
export const RED = "#FF4646"
export const GREY = "#767676"
export const S_ORANGE = "#F8855B"
export const S_DARK_BLUE = "#364DF8"
export const S_DARK_PINK = "#C51852"
export const S_PURPLE = "#9467FA"
export const S_SKY_BLUE = "#78D9F0"
export const S_YELLOW = "#FDDF34"
export const S_ROSE = "#FF5CE6"
export const S_HOT_PINK = "#EF008C"


export const TEXT_PURPLE = "#841E5A"
export const TEXT_DARK_BLACK = '#2E2E2E'
export const TEXT_DARK_PURPLE = '#140E26'
export const TEXT_GREY = '#707070' 


export const BUTTON_WHITE = '#F8F8F8'

export const phoneWidth = "600px"
export const macWidth = "868px"
export const windowsWidth = "1440px"
export const laptopWidth = "1024px"

export const devices = {
    mobile: `(max-width: ${phoneWidth})`,
    tablet: `(max-width: ${macWidth})`,
    laptop: `(max-width: ${laptopWidth})`,
    desktop: `(max-width: ${windowsWidth})`,
  };

