import {PORTFOLIO_LIST_REQUEST,
    PORTFOLIO_LIST_SUCCESS,
    PORTFOLIO_LIST_FAIL,
    PORTFOLIO_LIST_RESET,

    PORTFOLIO_DETAILS_REQUEST,
    PORTFOLIO_DETAILS_SUCCESS,
    PORTFOLIO_DETAILS_FAIL,
    PORTFOLIO_DETAILS_RESET,

    PORTFOLIO_DETAILS_BY_ID_REQUEST,
    PORTFOLIO_DETAILS_BY_ID_SUCCESS,
    PORTFOLIO_DETAILS_BY_ID_FAIL,
    PORTFOLIO_DETAILS_BY_ID_RESET,

    PORTFOLIO_CREATE_REQUEST,
    PORTFOLIO_CREATE_SUCCESS,
    PORTFOLIO_CREATE_FAIL,
    PORTFOLIO_CREATE_RESET,

    PORTFOLIO_UPDATE_REQUEST,
    PORTFOLIO_UPDATE_SUCCESS,
    PORTFOLIO_UPDATE_FAIL,
    PORTFOLIO_UPDATE_RESET,
 } from '../constants/portfolioConstants'

 export const portfolioListReducer =  (state = {portfolios:[]}, action) => {
    switch(action.type) {
        case PORTFOLIO_LIST_REQUEST:
            return {loading: true, requests:[]}

        case PORTFOLIO_LIST_SUCCESS:
            return {loading: false, portfolios: action.payload}

        case PORTFOLIO_LIST_FAIL:
            return {loading: false, error: action.payload }

        case PORTFOLIO_LIST_RESET: 
        return {loading:false, success:false, portfolio: {}}

        default:
            return state
    }
}

 export const portfolioCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case PORTFOLIO_CREATE_REQUEST: 
            return {loading:true}

        case PORTFOLIO_CREATE_SUCCESS: 
            return {loading:false, success: true, portfolio: action.payload}
        
        case PORTFOLIO_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case PORTFOLIO_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const portfolioUpdateReducer = ( state = { portfolio: {} }, action ) => {
    switch(action.type){
        case PORTFOLIO_UPDATE_REQUEST: 
            return {loading:true}

        case PORTFOLIO_UPDATE_SUCCESS: 
            return {loading:false, success: true, portfolio: action.payload}
        
        case PORTFOLIO_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case PORTFOLIO_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}


export const portfolioDetailsReducer = ( state = {portfolio :[]}, action ) => {
    switch(action.type){
        case PORTFOLIO_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case PORTFOLIO_DETAILS_SUCCESS: 
            return {loading:false, success:true,  portfolio: action.payload}
        
        case PORTFOLIO_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case PORTFOLIO_DETAILS_RESET: 
            return {loading:false, success:false, portfolio: []}

        default: 
            return state
    }
}

export const portfolioDetailsByIdReducer = ( state = {portfolio :{}}, action ) => {
    switch(action.type){
        case PORTFOLIO_DETAILS_BY_ID_REQUEST: 
            return {loading:true, ...state}

        case PORTFOLIO_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  portfolio: action.payload}
        
        case PORTFOLIO_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case PORTFOLIO_DETAILS_BY_ID_RESET: 
            return {loading:false, success:false, portfolio: {}}

        default: 
            return state
    }
}