import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button, General } from "../../styled-components/dashboard";
import { useHistory } from "react-router-dom"
import Select from "../../components/Select";
import InformationNotFilled from "../../components/InformationNotFilled";
import { COMPETITOR_DELETE_RESET} from '../../constants/competitorConstants'
import { WEBSITE_DETAILS_BY_EMAIL_RESET, WEBSITE_LIST_RESET } from "../../constants/websiteConstants";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { 
  createCompetitor,
  deleteCompetitor,
  listCompetitorDetails, 
} from '../../actions/competitorActions'
import { listWebsiteDetailsById, updateWebsite } from "../../actions/websiteActions";
import { listKeywordDetails } from "../../actions/keywordActions";
import { FlexColumn } from "../../styled-components/visualization/content/styles/content";
import AddNewButton from "../../components/AddNewButton";
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import { industries, business_model, devices, languages2, countries, frequencies, } from '../../constants/utils'
import Modal from 'react-modal';
import { FlexRow } from "../../styled-components/dashboard/content/styles/content";


export function EditWebsiteContainer({ accessToken, name, email, isSuperuser  }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const redirectCompaniesHandler = (e) => {
    history.push(`/websites`)
  }

  const [competitorUrl, setCompetitorUrl] = useState('')
  const [industry, setIndustry] = useState('')
  const [businessModel, setBusinessModel] = useState('')

  const [device, setDevice] = useState(devices[0])
  const [language, setLanguage] = useState('')
  const [country, setCountry] = useState('')

  const [nameIsValid, setNameIsValid] = useState(true);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const nameRegex = /^[A-Za-z0-9&'\-\. ]{1,128}$/;

  const [urlIsValid, setUrlIsValid] = useState(true);
  const [urlErrorMessage, setUrlErrorMessage] = useState('');

  const [urlCompetitorIsValid, setUrlCompetitorIsValid] = useState(true);
  const [urlCompetitorErrorMessage, setUrlCompetitorErrorMessage] = useState('');
  // const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const urlRegex = /^[(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]{0,20})*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?]{0,20}$/
  // const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]{1,20}$/;

  const [themeIsValid, setThemeIsValid] = useState(true);
  const [themeErrorMessage, setThemeErrorMessage] = useState('');
  const themeRegex = /^[A-Za-z0-9&'\.\-\,\s]{1,50}$/;

  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('keywords')+15, 100))
  const [informationFilled, setInformationFilled] = useState(0)

  const SaveHandler = (e) => {
    e.preventDefault()
    if(language === '' || country === '' || businessModel === '' || industry === ''){
      setInformationFilled(1)
    } else {
      const website= {
        id: websiteId,
        name: websiteName,
        website: websiteURL,
        theme: theme,
        frequency: frequency,
        trigger_wday: trigger_wday,
        trigger_mday: trigger_mday,
        slack_channel: 'missing',
        business_model: businessModel,
        industry: industry,
        language: language,
        country: country,
        active,
        email
    }
    dispatch(updateWebsite(accessToken, website, websiteId))
    if(!(competitors === '')){
      dispatch(createCompetitor(accessToken, competitors, email, websiteId))
    }   
    }
        
  }

  //Website info
  const [websiteName, setWebsiteName] = useState('')
  const [websiteURL, setWebsiteURL] = useState('')
  const [theme, setTheme] = useState('')
  const [active] = useState(true)
  const [frequency, setFrequency] = useState('')
  const [trigger_wday, setTrigger_wday] = useState('')
  const [trigger_mday, setTrigger_mday] = useState('')
  const [slack_channel, setSlack_channel] = useState('')
  const [no_of_keywords, setNo_of_keywords] = useState('')
  const [no_of_paid_keywords, setNo_of_paid_keywords] = useState('')

  // Competitor details
  const [competitors, setCompetitors] = useState('')


  const websiteDetailsById = useSelector(state => state.websiteDetailsById)
  const {loading: loadingDetailsByIdWebsite, 
      error: errorDetailsByIdWebsite, 
      success: successDetailsByIdWebsite, 
        website: detailsByIdWebsite
  } = websiteDetailsById

  const websiteUpdate = useSelector(state => state.websiteUpdate)
  const {loading: loadingUpdateWebsite, 
      error: errorUpdateWebsite, 
      success: successUpdateWebsite, 
  
  } = websiteUpdate

  const competitorDetails = useSelector(state => state.competitorDetails)
  const {loading: loadingDetailsCompetitor, 
      error: errorDetailsCompetitor, 
      success: successDetailsCompetitor, 
      competitor
  } = competitorDetails

  const competitorCreate = useSelector(state => state.competitorCreate)
  const {loading: loadingCreateCompetitor, 
      error: errorCreateCompetitor, 
      success: successCreateCompetitor, 
      // website: createdCompetitor
  } = competitorCreate

  const competitorDelete = useSelector(state => state.competitorDelete)
  const {loading: loadingDeleteCompetitor, 
      error: errorDeleteCompetitor, 
      success: successDeleteCompetitor, 
      competitor: competitorDeleteMessage
  } = competitorDelete

  const keywordDetails = useSelector(state => state.keywordDetails)
  const {loading: loadingDetailsKeyword, 
      error: errorDetailsKeyword, 
      success: successDetailsKeyword, 
      keyword
  } = keywordDetails

  function validateName(name) { 
    const isValidName = nameRegex.test(name);
    const errorMessageName = isValidName ? '' : 'Invalid name format';
    setNameIsValid(isValidName);
    setNameErrorMessage(errorMessageName);
  }

  function validateUrl(url) {
    const isValidUrl = urlRegex.test(url);
    const errorMessageUrl = isValidUrl ? '' : 'Invalid url format';
    setUrlIsValid(isValidUrl);
    setUrlErrorMessage(errorMessageUrl);


  }

  function checkCompetitorDuplication(url){
    const isDuplicate = competitor.some(c => c.competitor_website === url);
    const errorMessage = isDuplicate ? 'Competitor already exists' : '';
    setUrlCompetitorIsValid(!isDuplicate);
    setUrlCompetitorErrorMessage(errorMessage);
  }

  function validateUrlCompetitor(url) {
    const isValidCompetitorUrl = urlRegex.test(url);
    const errorMessageCompetitor = isValidCompetitorUrl ? '' : 'Invalid URL format';

    if(isValidCompetitorUrl){
      checkCompetitorDuplication(url)
    }else{
      setUrlCompetitorIsValid(isValidCompetitorUrl);
      setUrlCompetitorErrorMessage(errorMessageCompetitor);
    }
  }

  function validateTheme(theme) {
    const isValidTheme = themeRegex.test(theme);
    const errorMessageTheme = isValidTheme ? '' : 'Invalid theme format';
    setThemeIsValid(isValidTheme);
    setThemeErrorMessage(errorMessageTheme);
  }

  useEffect(()=>{
    if(accessToken && email && websiteId) {
      if(successDetailsByIdWebsite){
            setWebsiteName(detailsByIdWebsite.name)
            setWebsiteURL(detailsByIdWebsite.website)
            setTheme(detailsByIdWebsite.theme)
            setFrequency(detailsByIdWebsite.frequency)
            setTrigger_wday(detailsByIdWebsite.trigger_wday)
            setTrigger_mday(detailsByIdWebsite.trigger_mday)
            setNo_of_keywords(detailsByIdWebsite.no_of_keywords)
            setNo_of_paid_keywords(detailsByIdWebsite.no_of_paid_keywords)
            setIndustry(detailsByIdWebsite.industry)
            setBusinessModel(detailsByIdWebsite.business_model)
            setLanguage(detailsByIdWebsite.language)
            setCountry(detailsByIdWebsite.country)
            if(successUpdateWebsite){
              dispatch({type: WEBSITE_DETAILS_BY_EMAIL_RESET}) 
              dispatch({type: WEBSITE_LIST_RESET}) 
            }
            if(successDetailsKeyword){
            }else {
              if(!loadingDetailsKeyword){
                dispatch(listKeywordDetails(accessToken, websiteId))
              }
            }
            if(successDetailsCompetitor){
            }
            else{
              if(!loadingDetailsCompetitor)
              dispatch(listCompetitorDetails(accessToken, websiteId))
            }
          } else {
              dispatch(listWebsiteDetailsById(accessToken, websiteId, email))
          }
          if(successDeleteCompetitor){
            dispatch({type: COMPETITOR_DELETE_RESET})
            dispatch(listCompetitorDetails(accessToken, websiteId))
            
          }
      } 
  },[email,
      accessToken,  
      dispatch,
      successDetailsByIdWebsite,
      detailsByIdWebsite,
      websiteId,
      successDetailsCompetitor,
      errorDetailsCompetitor,
      successCreateCompetitor,
      successDeleteCompetitor,
      competitor,
      successDetailsKeyword,
      keyword,
      successUpdateWebsite
  ])

  const redirectKeywords = () => {

    history.push(`/keywords/${websiteId}`)
  }

  const createCompetitorHandler = (e) => {
    e.preventDefault()
    if(competitorUrl === '') {
        //setInformationFilled(1)
    } else {
        const competitor = {
            competitor_website: competitorUrl
        }
        dispatch(createCompetitor(accessToken, competitor, email, websiteId))
        setCompetitorUrl('')
    }
  }

  
  //MODAL DELETE
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [competitorId, setCompetitorId] = React.useState('');
  const [competitorName, setCompetitorName] = React.useState('');

  const customStylesDelete = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '450px',
      padding: '3%'
    },
  };

  function openDeleteModal(c_name, c_id) {
    setCompetitorId(c_id) // we need to store the state, although it will always delete the laste element from list
    setCompetitorName(c_name)
    setDeleteModalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
  }
  const deleteCompetitorHandler = (e,c_id) => {
    setDeleteModalIsOpen(false);
    dispatch(deleteCompetitor(accessToken, competitorId))
  }

  const modalDelete = (c_id) => {
    return(
            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={closeDeleteModal}
                style={customStylesDelete}
                ariaHideApp={false}
              >
              <Text.Bold40Black>
                Are you sure you want to remove
                </Text.Bold40Black> <span> </span>
                <Text.Bold42Pink>
                {competitorName} 
                </Text.Bold42Pink> <span> </span>
                <Text.Bold40Black>
                from your competitors list?
                </Text.Bold40Black>
                <FlexRow
                style={{
                  justifyContent: 'space-between',
                  marginTop: '30px'
                }}
            >
                <Button.Back
                  style={{
                    margin: '0'
                  }}
                  onClick={closeDeleteModal}
                >
                  Cancel
                </Button.Back>
                <Button.Save
                  onClick={(e)=>deleteCompetitorHandler(competitorId)}
                >
                  Delete
                </Button.Save>

            </FlexRow>
            </Modal>
    )
  }

  const listOfCompetitors = () => {
    return(
        <Content.FlexColumn>
            {competitor.map((c)=>(
                <Content.FlexRow
                style={{
                            marginTop: '15px'
                        }}
                >
                    <Text.Field14Black
                        
                        value={c.competitor_website}
                        disabled
                    >
                    </Text.Field14Black>
                    <div
                        style={{
                            width: '40px',
                            background: '#d4d4d4',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderTopRightRadius: '3px',
                            borderBottomRightRadius: '3px',
                            paddingLeft: '14px'
                        }}
                        onClick={(e)=>openDeleteModal(c.competitor_website, c.id)}
                    >
                        <p
                            style={{
                                display: 'block',
                                color: 'white',
                                margin: '0',
                       
                               
                                
                            }}
                        >x</p>
                        
                    </div>
                    {modalDelete(c.id)}
                </Content.FlexRow>
                
            ))}
        </Content.FlexColumn>
    )
  }

  return(
      <Content>
        {loadingDetailsByIdWebsite || !successDetailsByIdWebsite ||
        loadingDetailsCompetitor || !successDetailsCompetitor ||
        loadingDetailsKeyword || !successDetailsKeyword ||
        loadingUpdateWebsite || loadingCreateCompetitor ||
        // we need loading for succesDeleteCompetitor to empty the gap between delete dispatch and listCompetitorDetails
        loadingDeleteCompetitor || successDeleteCompetitor || 
        loadingCreateCompetitor
        ?
          <LoaderImage /> :
          errorDetailsByIdWebsite || errorUpdateWebsite || errorCreateCompetitor ||
          errorDetailsKeyword || errorDeleteCompetitor
           ? 
          <ErrorMessage /> :
          detailsByIdWebsite.detail ?
          <ErrorMessage message={detailsByIdWebsite.detail} /> :
          <>
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold26Pink>
                Basic Information
                <Text.Normal14Black>
                * indicates a required field.
                </Text.Normal14Black>
              </Text.Bold26Pink>
              {/* <Text.Bold16Black>
                Website name
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Website name name"
                type="text"
                value={websiteName}
                onChange={(e) => {
                  setWebsiteName(e.target.value);
                  validateName(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{nameErrorMessage}</span> */}
              <Text.Bold16Black>
                <Text.AsterixPurple>*</Text.AsterixPurple>
                Website URL
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="website.co.uk"
                type="text"
                value={websiteURL}
                onChange={(e) => {
                  setWebsiteURL(e.target.value);
                  validateUrl(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{urlErrorMessage}</span>
              {/* <Text.Bold16Black>
                Theme
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Custom theme for website"
                type="text"
                value={theme}
                onChange={(e) => {
                  setTheme(e.target.value);
                  validateTheme(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{themeErrorMessage}</span> */}
            {isSuperuser &&  
            <Select param={frequency} setParam={setFrequency} list={frequencies} placeholder='Choose frequency' title='Frequency'/>
            }
            {/* <Text.Bold16Black>
                Day of Week Trigger
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Custom theme for website"
                type="text"
                value={trigger_wday}
                disabled
              ></Text.Field14Black> */}
              <Select param={industry} setParam={setIndustry} list={industries} placeholder='Choose industry' title='Industry'/>
            <Select param={businessModel} setParam={setBusinessModel} list={business_model} placeholder='Choose business model' title='Business Model'/>
            {/* <Select param={trigger_wday} setParam={setTrigger_wday} list={days_of_week} placeholder='Choose day of week Trigger' title='Day of Week Trigger'/> */}
            {/* <Select param={trigger_mday} setParam={setTrigger_mday} list={days_of_month} placeholder='Choose day of month Trigger' title='Day of Month Trigger'/> */}
            {/* <Text.Bold16Black>
                Slack channel
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Name of the Slack channel"
                type="text"
                value={slack_channel}
                onChange={(e) => {
                  setSlack_channel(e.target.value);
                  validateSlackChannel(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{slackChannelErrorMessage}</span> */}
              <Text.Bold26Pink
                style={{marginTop: '1em'}}
              >
                Keyword profile
              </Text.Bold26Pink>
              <Select param={device} setParam={setDevice} list={devices} placeholder='Choose device' title='Device' disabled={true}/>
            <Select param={language} setParam={setLanguage} list={languages2} placeholder='Choose language' title='Language'/>
            <Select param={country} setParam={setCountry} list={countries} placeholder='Choose country' title='Country'/>
            </Grid.Section>
            <Grid.Section>
              <Text.Bold26Pink>
                Competitor Details
              </Text.Bold26Pink>
              <Text.Normal14Black>
                To add competitors please enter the Competitor Name and Competitor URL.
              </Text.Normal14Black>
              <Text.Normal14Black
                style={{
                    marginTop: '10px'
                }}
              >
                You can add up to 10 and edit them at anytime from your profile. 
              </Text.Normal14Black>
              {/* <Text.Bold16Black>
                Competitor Name
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Competitor name"
                type="text"
                value={competitorName}
                onChange={(e) => {
                  setCompetitorName(e.target.value);
                  validateName(e.target.value);
                }}
              ></Text.Field14Black> */}
              <Text.Bold16Black>
                Competitor Website URL
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="ex: website.co.uk"
                type="text"
                value={competitorUrl}
                onChange={(e) => {
                  setCompetitorUrl(e.target.value);
                  validateUrlCompetitor(e.target.value); 
                
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{urlCompetitorErrorMessage}</span>
              <Content.FlexRow
                style={{
                    justifyContent: 'flex-end',
                    marginTop: '15px'
                }}
              >
              <Button.Back
              disabled={!urlCompetitorIsValid}
              onClick={createCompetitorHandler}
              >
                Add
              </Button.Back>
              </Content.FlexRow>
              {listOfCompetitors()}
             
            </Grid.Section>
            <Grid.Section>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Text.Bold26Pink>Keywords</Text.Bold26Pink>
                <Text.Bold26Pink>{no_of_keywords} of 250</Text.Bold26Pink>
                {/* <Text.Bold26Pink>{no_of_keywords} of {no_of_paid_keywords}</Text.Bold26Pink> */}
              </div>
             
              <ProgressBar 
                    variant="success" 
                    now={(no_of_keywords*100)/250}
                    animated='true'
                    label={(no_of_keywords*100)/250+'%'}
                    />
             <Text.Normal14Black
                style={{
                  marginBottom: '10px',
                  marginTop: '5px'
                }}
             >
              {250-no_of_keywords} keywords remaining until your plan requires an upgrade.
             </Text.Normal14Black>
             {keyword[0] ? 
              <FlexColumn>
                {keyword.map((k,i) => (
                  i < 10 && <General.TableRow>
                  <Text.Normal14Black
                    style={{
                      width: '7%'
                    }}
                  >
                    {i+1}. 
                  </Text.Normal14Black>
                  <Text.Normal14Black>
                    {k.keyword}
                  </Text.Normal14Black>
                  </General.TableRow>
                ))}
                <Button.Save
                    style={{
                      width: 'fit-content',
                      margin: '0',
                      marginTop: '10px'
                    }}
                    onClick={()=>redirectKeywords()}
                >
                  View all keywords
                </Button.Save>
              </FlexColumn>
              : 
             <FlexColumn>
              <Text.Bold16Black> You haven't added any keyword yet.</Text.Bold16Black>
              {/* check if website contains infos that are needed for adding the keywords */}
              {detailsByIdWebsite.language==='' || detailsByIdWebsite.country===''? 
              <Text.Bold16Black>Please choose the country and the language before adding the keywords in <span style={{color: '#C51852'}}>Keyword Profile</span> section</Text.Bold16Black>
              :
              <AddNewButton route={`/add-keywords-copy/${websiteId}`} text='Add Keywords' />
              }
             </FlexColumn>
             }

            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
         <Content.ButtonsContainer>
            
            <Button.Back
            onClick={redirectCompaniesHandler}
            >
            Back
          </Button.Back>
          {informationFilled === 1 && <InformationNotFilled informationFilled={informationFilled} setInformationFilled={setInformationFilled}/>}
          <Button.Save 
          onClick={SaveHandler}
          disabled={!nameIsValid | !urlIsValid | !themeIsValid }
          >
              Save
            </Button.Save>
          </Content.ButtonsContainer>
          
        </Content.SectionBottom>
        </>
        }
      </Content>
  )
}
