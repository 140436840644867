import React from 'react'
import { Button } from '../styled-components/dashboard';

function AddNewButton({route='', text}) {
    return (
      <>
      {route === '' ? 
        <Button.Menu
                  style ={{width: 'fit-content',
                    paddingRight: '10px',
                    border: '#C51852 solid 2px',
                  }}
                
                >
                
                    <Button.MenuBackground
                      style={{
                        background: '#C51852',
                        border: '#C51852 solid 2px',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                        <p
                          style={{
                            display: 'block',
                            color:'white',
                            margin: '0',
                            fontSize: '26px'
                          }}
                        >+</p>
                       
                    </Button.MenuBackground>           
                    {text}
            </Button.Menu>
            :
            <Button.Menu
                  style ={{width: 'fit-content',
                    paddingRight: '10px',
                    border: '#C51852 solid 2px',
                  }}
                  to={route}
                >
                
                    <Button.MenuBackground
                      style={{
                        background: '#C51852',
                        border: '#C51852 solid 2px',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                        <p
                          style={{
                            display: 'block',
                            color:'white',
                            margin: '0',
                            fontSize: '26px'
                          }}
                        >+</p>
                       
                    </Button.MenuBackground>           
                    {text}
            </Button.Menu>
          }
          </>
    )
}

export default AddNewButton
