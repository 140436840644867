import React, { useState,useEffect } from "react";
import { Text, Content } from "../../../../styled-components/visualization";
import { CounterContainer } from "../../utilComponents/Counter";
import {
  BackgroundTableItemLight,
  nFormatter,
  SrePink,
} from "./sortingUtils";
import { COLOR_PALETTE_1, COLOR_PALETTE_2, COLOR_PALETTE_3, COLOR_PALETTE_4, COLOR_PALETTE_5, COLOR_PALETTE_6, COLOR_PALETTE_7, COLOR_PALETTE_8 } from "../../../../constants/theme/colours";


export const SerpScoreboardTable = (props) => {
  const [numberOfPages] = useState(Math.ceil(props.resultsTotalRows/100));
  const [colorIntervals, setColorIntervals] = useState([])

  const nextPage = () => {
    if (props.pageNoSerpLeague < numberOfPages) {
      props.setPageNoSerpLeague(props.pageNoSerpLeague + 1)
    }
  };

  const previousPage = () => {
    if (props.pageNoSerpLeague > 1) {
      props.setPageNoSerpLeague(props.pageNoSerpLeague -1)
    }
  };
  const paginationLine = () =>{
    return (
        <Content.FlexRow
        style ={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '3%'
        }}
     >
     <Content.PaginationIcon
     onClick={()=>previousPage()}
     src={require("../../../../images/visualization/left_arrow.svg").default}/>
     {props.pageNoSerpLeague !== numberOfPages ?
      <Text.Regular13Grey>{(props.pageNoSerpLeague-1)*100+1} - {props.pageNoSerpLeague*100} / {props.resultsTotalRows}</Text.Regular13Grey> :
      <Text.Regular13Grey>{(props.pageNoSerpLeague-1)*100+1} - {props.resultsTotalRows} / {props.resultsTotalRows}</Text.Regular13Grey> // replace 2000 with max rows in bigquery
     }
     
     <Content.PaginationIcon
    onClick={() =>nextPage()}
     src={require("../../../../images/visualization/right_arrow.svg").default}/>
     </Content.FlexRow>
    )
  }

  useEffect (()=>{
    let maxValue = 0;
    props.results.map((row, i) => {
      if(row.sre_percent_of_total > maxValue) maxValue = row.sre_percent_of_total;
    })
    createMaxValueIntervals(maxValue);

  },[props.results])

  const createMaxValueIntervals = (maxVal) =>{
    // Calculate the size of each interval
     const intervalSize = maxVal / 8;

     // Create the intervals with colors
     const intervals = [];
     for (let i = 1; i <= 8; i++) { // 8 intervals
     const intervalStart = intervalSize * (i - 1);
     const intervalEnd = intervalSize * i;
     let color;
     switch (i) {
         case 1:
         color = COLOR_PALETTE_1;
         break;
         case 2:
         color = COLOR_PALETTE_2;
         break;
         case 3:
         color = COLOR_PALETTE_3;
         break;
         case 4:
         color = COLOR_PALETTE_4;
         break;
         case 5:
         color = COLOR_PALETTE_5;
         break;
         case 6:
         color = COLOR_PALETTE_6;
         break;
         case 7:
         color = COLOR_PALETTE_7;
         break;
         case 8:
         color = COLOR_PALETTE_8;
         break;
         default:
         color = 'black'; // or any default color
     }
     intervals.push({ start: intervalStart, end: intervalEnd, color });
     }

   
     setColorIntervals(intervals);
  }

  return (
    <Content.FlexColumn style={{height:'450px'}} >

      {/* table head */}
      <Content.FlexRow
        style={{
          marginTop: "2%",
          justifyContent: "space-between",
          width: '99%',
          marginLeft: '5px'
        }}
      >
        <Content.FlexColumn
          style={{
            width: "30px",
          }}
        >
          <Text.Regular13Grey></Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
          }}
        >
          <Text.Regular13Grey>Website</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>SERP Real Estate Share</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Recent</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Bidding on Ads?</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey># SERP Features</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Avg. Organic Position</Text.Regular13Grey>
        </Content.FlexColumn>
        <Content.FlexColumn
          style={{
            width: "10%",
            textAlign: "center",
          }}
        >
          <Text.Regular13Grey>Keywords</Text.Regular13Grey>
        </Content.FlexColumn>
      </Content.FlexRow>
      {/* end table head */}

      {/* table body */}
      <Content.DropDownContainer >
        {props.results.map((row, i) => (
         
            <Content.HoverRow>
              <Content.FlexColumn
                style={{
                  width: "30px",
                }}
              >
                <Content.FlexRow>
                  <CounterContainer number={(props.pageNoSerpLeague-1)*100+i+1} />
                </Content.FlexRow>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "10%",
                }}
              >
                <Content.FlexRow>
                  <Text.Regular14Black style={{overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{row.domain}</Text.Regular14Black>
                </Content.FlexRow>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "10%",
                }}
              >


                {
                    colorIntervals.map((range, i) => (
                      row.sre_percent_of_total > range.start && row.sre_percent_of_total <= range.end && 
                          <Text.Regular14WhitePurpleLightBackground style={{background:range.color}}>
                            {nFormatter(row.sre_percent_of_total*100)}%
                        </Text.Regular14WhitePurpleLightBackground> 
                    ))
                }
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "10%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>
                  {nFormatter(row.sre_delta)}
                </Text.Regular14Black>
              </Content.FlexColumn>

              <Content.FlexColumn
                style={{
                  width: "10%",
                  textAlign: "center",
                }}
              >
                {row.ads_bidding ? (
                  <Text.Regular14WhitePurpleBackground>
                  {row.ads_bidding ? "true" : "false"}  
                  </Text.Regular14WhitePurpleBackground>
                    
                ) : (
                  <BackgroundTableItemLight>
                    {row.ads_bidding ? "true" : "false"}
                  </BackgroundTableItemLight>
                )}
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "10%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>
                  {row.serp_feature_count}
                </Text.Regular14Black>
              </Content.FlexColumn>
              <Content.FlexColumn
                style={{
                  width: "10%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>
                  {" "}
                  {row.avg_organic_position
                    ? nFormatter(row.avg_organic_position)
                    : "-"}
                </Text.Regular14Black>
              </Content.FlexColumn>
             
              <Content.FlexColumn
                style={{
                  width: "10%",
                  textAlign: "center",
                }}
              >
                <Text.Regular14Black>{ row.keyword_count < 1000 ? (row.keyword_count).toFixed(0) : nFormatter(row.keyword_count)}</Text.Regular14Black>
              </Content.FlexColumn>
              </Content.HoverRow>
           
        ))}
        
      </Content.DropDownContainer>
      {paginationLine()}
    
    </Content.FlexColumn >
  );
};
