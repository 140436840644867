import { 
    KEYWORD_LIST_REQUEST,
    KEYWORD_LIST_SUCCESS,
    KEYWORD_LIST_FAIL,
    KEYWORD_LIST_RESET,

    KEYWORD_DETAILS_REQUEST,
    KEYWORD_DETAILS_SUCCESS,
    KEYWORD_DETAILS_FAIL,
    KEYWORD_DETAILS_RESET,
    
    KEYWORD_DETAILS_WITHOUT_PAGINATION_REQUEST,
    KEYWORD_DETAILS_WITHOUT_PAGINATION_SUCCESS,
    KEYWORD_DETAILS_WITHOUT_PAGINATION_FAIL,
    KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET,

    KEYWORD_DETAILS_BY_ID_REQUEST,
    KEYWORD_DETAILS_BY_ID_SUCCESS,
    KEYWORD_DETAILS_BY_ID_FAIL,
    KEYWORD_DETAILS_BY_ID_RESET,

    KEYWORD_CREATE_REQUEST,
    KEYWORD_CREATE_SUCCESS,
    KEYWORD_CREATE_FAIL,
    KEYWORD_CREATE_RESET,

    KEYWORD_UPDATE_REQUEST,
    KEYWORD_UPDATE_SUCCESS,
    KEYWORD_UPDATE_FAIL,
    KEYWORD_UPDATE_RESET,

    KEYWORD_SORTED_REQUEST,
    KEYWORD_SORTED_SUCCESS,
    KEYWORD_SORTED_FAIL,
    KEYWORD_SORTED_RESET,

    KEYWORD_DELETE_REQUEST,
    KEYWORD_DELETE_SUCCESS,
    KEYWORD_DELETE_FAIL,
    KEYWORD_DELETE_RESET,
 } from '../constants/keywordConstants'

 export const keywordListReducer =  (state = {keywords:[]}, action) => {
    switch(action.type) {
        case KEYWORD_LIST_REQUEST:
            return {loading: true, requests:[]}

        case KEYWORD_LIST_SUCCESS:
            return {loading: false, keywords: action.payload}

        case KEYWORD_LIST_FAIL:
            return {loading: false, error: action.payload }

        case KEYWORD_LIST_RESET: 
            return {}

        default:
            return state
    }
}

 export const keywordCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case KEYWORD_CREATE_REQUEST: 
            return {loading:true}

        case KEYWORD_CREATE_SUCCESS: 
            return {loading:false, success: true, keyword: action.payload}
        
        case KEYWORD_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const keywordUpdateReducer = ( state = { keyword: {} }, action ) => {
    switch(action.type){
        case KEYWORD_UPDATE_REQUEST: 
            return {loading:true}

        case KEYWORD_UPDATE_SUCCESS: 
            return {loading:false, success: true, keyword: action.payload}
        
        case KEYWORD_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}


export const keywordDetailsReducer = ( state = {keyword :[]}, action ) => {
    switch(action.type){
        case KEYWORD_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_DETAILS_SUCCESS: 
            return {
                loading:false, success:true,  
                keyword: action.payload.keyword, 
                page:action.payload.page, 
                pages:action.payload.pages,
                numberOfKeywords: action.payload.numberOfKeywords
            }
        
        case KEYWORD_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_DETAILS_RESET: 
            return {success:false, keyword: []}

        default: 
            return state
    }
}

export const keywordDetailsWithoutPaginationReducer = ( state = {keywords :[]}, action ) => {
    switch(action.type){
        case KEYWORD_DETAILS_WITHOUT_PAGINATION_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_DETAILS_WITHOUT_PAGINATION_SUCCESS: 
            return {
                loading:false, success:true,  
                keywords: action.payload.keywords, 
            }
        
        case KEYWORD_DETAILS_WITHOUT_PAGINATION_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET: 
            return {success:false, keyword: []}

        default: 
            return state
    }
}

export const keywordDetailsByIdReducer = ( state = {keyword :{}}, action ) => {
    switch(action.type){
        case KEYWORD_DETAILS_BY_ID_REQUEST: 
            return {loading:true}

        case KEYWORD_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  keyword: action.payload}
        
        case KEYWORD_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_DETAILS_BY_ID_RESET: 
            return {loading:false, success:false, keyword: []}

        default: 
            return state
    }
}

export const keywordSortedDetailsReducer = ( state = {sortedKeywords :{}}, action ) => {
    switch(action.type){
        case KEYWORD_SORTED_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_SORTED_SUCCESS: 
            return {loading:false, success:true,  sortedKeywords: action.payload}
        
        case KEYWORD_SORTED_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_SORTED_RESET: 
            return {loading:false, success:false, sortedKeywords: []}

        default: 
            return state
    }
}


export const keywordDeleteReducer = ( state = {keyword :{}}, action ) => {
    switch(action.type){
        case KEYWORD_DELETE_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_DELETE_SUCCESS: 
            return {loading:false, success:true,  keyword: action.payload}
        
        case KEYWORD_DELETE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_DELETE_RESET: 
            return {loading:false, success:false, keyword: {}}

        default: 
            return state
    }
}