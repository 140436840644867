export const KEYWORD_MM_DETAILS_REQUEST= 'KEYWORD_MM_DETAILS_REQUEST'
export const KEYWORD_MM_DETAILS_SUCCESS= 'KEYWORD_MM_DETAILS_SUCCESS'
export const KEYWORD_MM_DETAILS_FAIL   = 'KEYWORD_MM_DETAILS_FAIL'
export const KEYWORD_MM_DETAILS_RESET   = 'KEYWORD_MM_DETAILS_RESET'

export const KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_REQUEST= 'KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_REQUEST'
export const KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_SUCCESS= 'KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_SUCCESS'
export const KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_FAIL   = 'KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_FAIL'
export const KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_RESET   = 'KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_RESET'

export const KEYWORD_MM_DETAILS_BY_ID_REQUEST   = 'KEYWORD_MM_DETAILS_BY_ID_REQUEST'
export const KEYWORD_MM_DETAILS_BY_ID_SUCCESS   = 'KEYWORD_MM_DETAILS_BY_ID_SUCCESS'
export const KEYWORD_MM_DETAILS_BY_ID_FAIL      = 'KEYWORD_MM_DETAILS_BY_ID_FAIL'
export const KEYWORD_MM_DETAILS_BY_ID_RESET     = 'KEYWORD_MM_DETAILS_BY_ID_RESET'

export const KEYWORD_MM_CREATE_REQUEST = 'KEYWORD_MM_CREATE_REQUEST'
export const KEYWORD_MM_CREATE_SUCCESS = 'KEYWORD_MM_CREATE_SUCCESS'
export const KEYWORD_MM_CREATE_FAIL    = 'KEYWORD_MM_CREATE_FAIL'
export const KEYWORD_MM_CREATE_RESET   = 'KEYWORD_MM_CREATE_RESET'

export const KEYWORD_MM_UPDATE_REQUEST = 'KEYWORD_MM_UPDATE_REQUEST'
export const KEYWORD_MM_UPDATE_SUCCESS = 'KEYWORD_MM_UPDATE_SUCCESS'
export const KEYWORD_MM_UPDATE_FAIL    = 'KEYWORD_MM_UPDATE_FAIL'
export const KEYWORD_MM_UPDATE_RESET   = 'KEYWORD_MM_UPDATE_RESET'


export const KEYWORD_MM_DELETE_REQUEST = 'KEYWORD_MM_DELETE_REQUEST'
export const KEYWORD_MM_DELETE_SUCCESS = 'KEYWORD_MM_DELETE_SUCCESS'
export const KEYWORD_MM_DELETE_FAIL    = 'KEYWORD_MM_DELETE_FAIL'
export const KEYWORD_MM_DELETE_RESET   = 'KEYWORD_MM_DELETE_RESET'
