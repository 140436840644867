import React from "react";
import { Text, Content, Welcome, Button, MenuHeader } from "../../../styled-components/visualization";
import {  FlexRow } from "../../../styled-components/visualization/content/styles/content";
import { Light16Black } from "../../../styled-components/visualization/text/styles/text";
import * as ROUTES from "../../../constants/routes/routes"
import {  useHistory } from 'react-router-dom'
import { SectionsTitleContainer } from "./SectionsTitle";


export function ThreeKeyInsightsContainer() {
    let history = useHistory()

    const goBenchmarks = () =>{
        history.push(ROUTES.VISUALIZATION_BENCHMARKS)
    }
    const goCoverage = () =>{
        history.push(ROUTES.VISUALIZATION_COVERAGE)
    }
    const goCompetition = () =>{
        history.push(ROUTES.VISUALIZATION_COMPETITION)
    }

    const big3container = (name, text1, text2, text3, text4) => {
        return(
          <>
        <Welcome.Big3Container
        >
          <Content.FlexRow
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '60px'
                }}
              >
              
                <Text.Regular18Purple
                  style={{
                    fontSize: '25px'
                  }}
                >
                  {name}
                </Text.Regular18Purple>
                {name === 'Industry Benchmarks' ? 
                <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../../images/visualization/Benchmarks_purple.svg").default}/> :
                  name === 'Coverage' ?
                  <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../../images/visualization/Coverage_purple.svg").default}/> :
                    <MenuHeader.ItemImg  
                    style ={{
                        marginLeft: '5%'
                    }}
                    src={require("../../../images/visualization/Competition_purple.svg").default}/> 
                  }
              </Content.FlexRow>
              
              <Light16Black
                style={{
                  marginTop: '40px'
                }}
              >
              {text1}
              </Light16Black>
              <Light16Black
              style={{
                marginTop: '15px'
                }}
              >
              ● {text2}
              </Light16Black>
              <Light16Black
              style={{
                marginTop: '15px'
                }}
              >
              ● {text3}
              </Light16Black>
              <Light16Black
              style={{
                marginTop: '15px'
                }}
              >
              ● {text4}
              </Light16Black>
              
              
            </Welcome.Big3Container>
         
            </>
            )
      }

  return (
    <>
    <SectionsTitleContainer 
      firstTitle='How it works'
      secondTitle='3 Key Insights'
      description='Your Luminr dashboard is populated based on your keywords, search queries, audience profiles, markets and competitors. We mimic user searches and analyse results to serve you and your team 100% actionable insights.'
      description2='A Total Search approach means Luminr delivers 3 key insights for brand market share not available elsewhere'
    />
        <FlexRow
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {big3container('Industry Benchmarks',
            'A breakdown of your performance compared to others within your industry niche.',
            'Transparent benchmarks tailored to brand niches.',
            'Up-to-date search trends per industry.',
            'What does "good" actually look like?'
          )}
          {big3container('Coverage',
            'Insights to grow your visibility and share-of-voice across all search channels.',
            'Any language. Any device. Anywhere in the world.',
            'Insights spanning all SERP features.',
            '360° view - how SEM channels work together for brand visibility across page one.'
            )}
          {big3container('Competitor Insights',
            'Competitive intelligence designed to help you compete with the successful players in your space.',
            'Monitor - Competitor movement and real time SWOTs',
            'Learn - Decontruct competitor channel and content strategy',
            'Action - Get ahead of new growers in your space'
            )}
          
          
        
          </FlexRow>
          <Content.FlexRow
            style={{
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
          <Welcome.Big3Container
            style={{
              paddingTop: '0'
            }}
          >
          <Button.Primary
          onClick={goBenchmarks}
            style={{
              width: '90%'
            }}
              >
              Let's go
            </Button.Primary>
          </Welcome.Big3Container>

          <Welcome.Big3Container
            style={{
              paddingTop: '0'
            }}
          >
          <Button.Primary
            style={{
              width: '90%'
            }}
            onClick={goCoverage}
              >
              Let's go
            </Button.Primary>
          </Welcome.Big3Container>

          <Welcome.Big3Container
            style={{
              paddingTop: '0'
            }}
          >
          <Button.Primary
            style={{
              width: '90%'
              
            }}
            onClick={goCompetition}
              >
              Let's go
            </Button.Primary>
          </Welcome.Big3Container>
           
          </Content.FlexRow>
    </>
  )
}
