import * as React from 'react';
import { FlexColumn, FlexRow } from '../../styled-components/visualization/content/styles/content';
import {  Text } from '../../styled-components/visualization';
import { useSelector, useDispatch } from "react-redux";
import { useState,useEffect } from 'react';
import { PricingCardContainer } from './pricingCard';
import { createPayment, updatePayment } from '../../actions/paymentActions';
import '../../utils/pricingStyle.css' 
import {keywordsText,locationsText,pricingDataBasic,pricingDataAdvanced,pricingDataEnterprise,commonDataBasic,commonDataAdvanced,commonDataEnterprise,generic} from '../../constants/pricingConstants.js' 
import $ from 'jquery'; 
import  axios from 'axios'
import LoaderImage from '../../components/LoaderImage';
import { createSubscription, listSubscriptionDetails, updateSubscription } from '../../actions/subscriptionActions.js';
import { Button, Grid } from '../../styled-components/dashboard/index.js';
import { Field14Black } from '../../styled-components/dashboard/text/styles/text.js';
import { $CombinedState } from 'redux';


export function PricingPageContainer({ accessToken, name, email }) {
  const dispatch = useDispatch();

  const [rangeKeywords, setRangeKeywords] = useState(null);
  const [rangeLocations, setRangeLocations] = useState(null);
  const [computedPrice, setComputedPrice] = useState("0");
  const [productId, setProductId] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [sentEmail, setSentEmail] = useState("");
  const [priceId, setPriceId] = useState("");
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [loading,setLoading]= useState(false);
  const [goToCheckout,setGoToCheckout]= useState(false);
  const [paymentStatus,setPaymentStatus]= useState('');
  const [subscriptionStatus,setSubscriptionStatus]= useState('');
  const [expiryDate,setExpiryDate] = useState('');
  const [paymentObject, setPaymentObject] = useState({
    email:email,
    subscription_id:'',
    subscription_name:''
  });
  const [subscriptionObject, setSubscriptionObject] = useState({
    email:email,
    subscription_name:''
  });

  const subscriptionDetails = useSelector(state => state.subscriptionDetails)
  const {loading: loadingSubscriptionDetails, 
      error: errorSubscriptionDetails, 
      success: successSubscriptionDetails, 
       subscription
  } = subscriptionDetails


  const RangeSlider = (text,getRangeState,setRangeState,min,max,step) => {
    return (
      <>
          <FlexRow className='justifyContentCenter'>
            <Text.Regular16Black>{text}</Text.Regular16Black>
          </FlexRow>

          <div className="rangeSlider">
            <div className="wrapper">
                <div className="content">
                  <div className="range">  
                  <input type="range" className="custom-range-type" id="range1" defaultValue={0} min={min} step={step} max={max}
                    onChange={(event) => setRangeState(event.target.value)}/>
                  </div>
                </div>
            </div>
          </div>
      </>
    );
  };

  const config = {
    headers: {
        'Content-type':'application/json',
        Authorization: `Bearer ${accessToken}`
    }
  }

  const createStripePrice = async () =>{
    try {
      
        let priceToSend = computedPrice;
        let subscriptionName = "Basic"
        if(productId == "prod_Os958XxFdPerOB"){
            subscriptionName = 'Advanced'
            priceToSend = Number(computedPrice +500)
        }
        const { data } = await axios.post(
            `/api/stripe_api/price`,
            {
              product_id: productId,
              amount: priceToSend,
            },
            config
        )
        if(data.id){
          setPriceId(data.id)

          setPaymentObject(prevState =>({
            ...prevState,
            price_id: data.id,
            price:data.unit_amount,
            keywords_number:rangeKeywords,
            markets_number:rangeLocations,
            order_date:data.created
          }));

          
          setSubscriptionObject(prevState =>({
            ...prevState,
            subscription_name:subscriptionName,
            price:data.unit_amount,
            keywords_number:rangeKeywords,
            markets_number:rangeLocations,
            order_date:data.created
          }));

        }
        //worksss!

    } catch (error) {
        return  error.response
    }

  }

  const  createCheckout= async()=>{
    try {
      let res = await axios.post(
        `/api/stripe_api/checkout`,
        {
          price_id: priceId
        },
        config
      )
      if(res.status == 206){//succes
        setPaymentObject(prevState =>({
          ...prevState,
          checkout_id: res.data.id,
          payment_status:res.data.payment_status
        }));
        setSubscriptionObject(prevState =>({
          ...prevState,
          checkout_id: res.data.id,
          payment_status:res.data.payment_status
        }));
        setCheckoutUrl(res.data.url)
    }    
    } catch (error) {
        return  error.response
    }
  }

  const createPaymentLog = async() => {
    if(paymentObject){
      dispatch(createPayment(accessToken, paymentObject))
      dispatch(listSubscriptionDetails(accessToken,email))
    }
  }

  const createSubscriptionLog = async() => {
    if(subscriptionObject){
      dispatch(createSubscription(accessToken, subscriptionObject))
    }
  }

  const checkPaymentSession = async() => {
    // Get the URL parameters
    var url_string = window.location.href; 
    var url = new URL(url_string);

    var parts = url_string.split("#");
    console.log(parts)
    if (parts.length > 1) {
      // Split the fragment identifier by '?' to get the query parameters
      var fragment = parts[1];
      var params = fragment.split("?");
  
      // Iterate through the query parameters to find the session_id
      for (var i = 0; i < params.length; i++) {
          var param = params[i];
          var keyValue = param.split("=");
          if (keyValue[0] === "session_id") {
              var sessionId = keyValue[1];
              setLoading(true)

              const { data } = await axios.get(
                `/api/stripe_api/session/`+sessionId,
                config
              )
              if(data){
                if(data.payment_status == 'paid'){
                  dispatch(listSubscriptionDetails(accessToken,email))
                  setPaymentStatus('paid')
                  
                }
              }
              break; // Exit the loop when session_id is found
          }
      }
      if(params == '/pricingPage'){
        dispatch(listSubscriptionDetails(accessToken,email))
      }
    }else{
    }
  }


  //add 1 month to subscription date (recurring date)
  const handleExpiryDate = (modifyDate)=>{
    // Convert string to Date object
    const originalDate = new Date(modifyDate);

    // Add 1 month
    const newDate = new Date(originalDate);
    newDate.setMonth(newDate.getMonth() + 1);

    // Format the new date as a string (optional)
    const formattedNewDate = newDate.toISOString().substring(0, 10);;
    setExpiryDate(formattedNewDate)
  }

  const bookACall = async(emailToSend) =>{
    try {
      let res = await axios.post(
        `/api/subscription/bookACall`,
        {
          email: emailToSend
        },
        config
      )
      if(res.data == 'Email sent!'){//succes
        setSentEmail(true)
      }    
    } catch (error) {
        return  error.response
    }
  }

  //custom plan - book a call
  useEffect (()=>{
    if(clientEmail){
      bookACall(clientEmail)
    }
  },[clientEmail])

  useEffect(()=>{
    if(priceId){
      createCheckout();
    }
  },[priceId])

  useEffect(()=>{
    if(checkoutUrl){
      createPaymentLog();
      dispatch(listSubscriptionDetails(accessToken,email))
    }
  },[checkoutUrl])

  useEffect(()=>{
    if(subscription && checkoutUrl){
      if(subscriptionStatus == ''){
        //insert subscription
        createSubscriptionLog();
        
       
      }else if (subscription.length >0) { //if already saved subscription
        setGoToCheckout(true)

      }
      setSubscriptionStatus('saved')
    }
  },[subscription,subscriptionStatus])


  useEffect(()=>{
    if(goToCheckout){
      window.location.href=checkoutUrl;
      setLoading(false)
    }

  },[goToCheckout])

  // -> On sliders change
  // -> here compute the price
  // -> call Stripe endpoints to create price item
  useEffect( ()=>{
    const newPrice = Number(rangeKeywords) + Number(rangeLocations);
    setComputedPrice(newPrice)
    if(productId){
      if(!rangeKeywords || !rangeLocations){
        $("#infoBar").html('Please select the keywords number and the locations!')
      }else{
        setLoading(true)
        createStripePrice()  
      }
    }
  },[rangeKeywords,rangeLocations,productId])


  

  // -> On paymentStatus changes
  // -> here the user return from the checkout page and we have to check if payment was done 
  // -> mark the subscription as 'paid'
  useEffect(()=>{
    if(paymentStatus == 'paid' && subscription[0]){
      if(subscription[0].payment_status != 'paid'){
        subscription[0].payment_status = 'paid';
        subscription[0].email = email;
        dispatch(updateSubscription(accessToken,subscription[0],subscription[0].id))
        setPaymentStatus('')
        setLoading(false)
         //send email
        
      }else{
        setLoading(false)
      }
    }
    if(subscription[0]){
      handleExpiryDate( subscription[0].modifiedAt)
      if(subscription[0].payment_status == 'paid'){
        window.location.href = "http://localhost:3000/#/pricingPage";
      }
    }
  },[paymentStatus,subscription])


  // -> On page load 
  // -> when the user completes the payment, check if a parameter (session_id) is in the url 
  // -> if the parameter is there, get the user subscription object, and update the payment_status
  useEffect(()=>{
    checkPaymentSession()
  },[])


  return (
    <div style={{height:'100%'}}>  
      {loading || loadingSubscriptionDetails ? 
        <LoaderImage /> 
        :

        successSubscriptionDetails && subscription && subscription[0] && subscription[0].payment_status == "paid" ?
        
          <Grid>
            <Grid.Section1>
              <Text.Bold30Black>
                Summary
              </Text.Bold30Black>

              <Text.Bold15Black>
                Subscription plan
              </Text.Bold15Black>
              <Field14Black value={'Monthly'} disabled></Field14Black>

              <Text.Bold15Black>
                Keywords number
              </Text.Bold15Black>
              <Field14Black value={subscription[0].keywords_number} disabled></Field14Black>

              <Text.Bold15Black>
                Locations
              </Text.Bold15Black>
              <Field14Black value={subscription[0].markets_number} disabled></Field14Black>

              <Text.Bold15Black>
                Price
              </Text.Bold15Black>
              <Field14Black value={subscription[0].price} disabled></Field14Black>

              <Text.Bold15Black>
                Payment date
              </Text.Bold15Black>
              <Field14Black value={(new Date(subscription[0].modifiedAt)).toISOString().substring(0, 10)} disabled></Field14Black>

              <Text.Bold15Black>
                Upcoming payout
              </Text.Bold15Black>
              <Field14Black value={expiryDate} disabled></Field14Black>

              <Button.Save 
                  style={{
                      width: 'auto',
                      margin: '0.5rem 0 0 0',
                      height: '40px',
                      padding: '0 10px',
                      background: 'transparent',
                      border: '2px solid rgb(197, 24, 82)',
                      color: '#222222',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginTop: '20px'
                  }}>
                      Download Invoice (beta)
                </Button.Save>



            </Grid.Section1>
            
            <Grid.Section2>
              <Text.Bold30Black>
                Overview
              </Text.Bold30Black>
            
             { subscription[0].subscription_name == "Basic" ?
                <PricingCardContainer
                priceValue={subscription[0].price}
                pricingData={pricingDataBasic}
                common={commonDataBasic}
                generic={generic}
                hidePayButton={true}
              >
              </PricingCardContainer>
              :
              <PricingCardContainer
                  priceValue={subscription[0].price}
                  pricingData={pricingDataAdvanced}
                  common={commonDataAdvanced}
                  generic={generic}
                  hidePayButton={true}
                >
                </PricingCardContainer>
            }

            </Grid.Section2>

            {/* inGrid.section2 sa apara cardul selectat mai devreme cu pretul pe el , si un sticker Active + perioada de expirare */}
            {/* buton Downlaod Invoice (v2) */}
          </Grid>

        
          

        
        :
        <div style={{padding:'2%', background:'#EDEDED', textAlign:'center'}}>
          <FlexColumn>
          <FlexRow className='justifyContentCenter'>
            <Text.Bold15Black>Choose the best plan to set your conversion rate optimization strategy for success</Text.Bold15Black>
          </FlexRow>
          <br/>

          {RangeSlider(keywordsText,rangeKeywords,setRangeKeywords,'500','2000','500')}

          <br/>

          {RangeSlider(locationsText,rangeLocations,setRangeLocations,'1','5','1')}
          <p id="infoBar"></p>
          
          <div className="container">

            <PricingCardContainer
              priceValue={computedPrice}
              pricingData={pricingDataBasic}
              common={commonDataBasic}
              generic={generic}
              setProductId={setProductId}
            >
            </PricingCardContainer>

            <PricingCardContainer
              priceValue={computedPrice+500}
              pricingData={pricingDataAdvanced}
              common={commonDataAdvanced}
              generic={generic}
              setProductId={setProductId}
            >
            </PricingCardContainer>

            <PricingCardContainer
              priceValue={0}
              pricingData={pricingDataEnterprise}
              common={commonDataEnterprise}
              generic={generic}
              setClientEmail={setClientEmail}
              sentEmail={sentEmail}
              enterprise={true}
            >
            </PricingCardContainer>

          </div>

          </FlexColumn>
        </div>

      }
       
    </div>
    
  );
}

