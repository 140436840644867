import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { listActionTypes, listLogTypes } from '../actions/loggingsystem/loggingSystemActions'
import Loader from '../components/Loader'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import { Text } from ".././styled-components/dashboard";

import { LOGS_LIST_BY_FILTER_RESET } from '../constants/loggingsystem/loggingSystemConstants'


const drawerWidth = 600

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}))

export default function PersistentDrawerRight(props) {
    const classes = useStyles()

    var pageSize = 3
    let history = useHistory()
    const dispatch = useDispatch()
    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
        useAuth0()
    const [accessToken, setAccessToken] = useState('')

    const open = props.open
    const authUser = props.authUser

    const listLastNLogs = useSelector((state) => state.listLastNLogs)
    const {
        loading: loadingListLastNLogs,
        // error: errorlistLastNLogs,
        // success: successlistLastNLogs,
        logs,
    } = listLastNLogs

    useEffect(() => {
        const getAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getAccessToken()
    }, [
        accessToken,
        dispatch,
        isAuthenticated,
        loginWithRedirect,
        getAccessTokenSilently,
    ])

    const handleButtonLogs = (e, email) => {
        e.preventDefault()
        dispatch({ type: LOGS_LIST_BY_FILTER_RESET })
        dispatch(listActionTypes(accessToken))
        dispatch(listLogTypes(accessToken))

        history.push(`/admin/logs/details/${email}`)
    }

    const list = (logs) => {
        return (
            <div>
                <ListItem key={logs.id} button className={classes.nested}>
                    <ListItemIcon>
                        {logs.logType === 'error' ? (
                            <FiberManualRecordIcon style={{ color: 'red' }} />
                        ) : logs.logType === 'warning' ? (
                            <FiberManualRecordIcon
                                style={{ color: 'yellow' }}
                            />
                        ) : logs.logType === 'info' ? (
                            <FiberManualRecordIcon />
                        ) : (
                            <FiberManualRecordIcon style={{ color: 'green' }} />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            new Intl.DateTimeFormat('en-GB', {
                                dateStyle: 'long',
                                timeStyle: 'short',
                            }).format(Date.parse(logs.createdAt)) +
                            ' — ' +
                            logs.logType
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {logs.actionType}
                                </Typography>
                                {' — '}
                                {logs.logMessage}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider />
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <CssBaseline />

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={props.handleDrawerClose}>
                        <ClearIcon />
                    </IconButton>
                    {logs.length >= pageSize ? (
                        <Typography>
                            <b>{props.userEmail}</b> -{' '}
                            <i>first {pageSize} logs</i>
                        </Typography>
                    ) : (
                        <Typography>
                            <b>{props.userEmail}</b>
                        </Typography>
                    )}
                </div>
                <Divider />

                {authUser ? 
                    (
                        <div style={{
                                paddingLeft: '9.5%'
                            }}
                        >
                            <Text.Bold16Black>Updated At</Text.Bold16Black>
                            <Text.Normal14Black>
                                {new Intl.DateTimeFormat('en-GB', {
                                    dateStyle: 'medium',
                                }).format(Date.parse(authUser.updated_at))}
                            </Text.Normal14Black>

                            <Text.Bold16Black>Last IP</Text.Bold16Black>
                            <Text.Normal14Black>{authUser.last_ip}</Text.Normal14Black>

                            <Text.Bold16Black>Identity Connection</Text.Bold16Black>
                            <Text.Normal14Black>{authUser.identities[0].connection}</Text.Normal14Black>

                            <Text.Bold16Black>Login Count</Text.Bold16Black>
                            <Text.Normal14Black>{authUser.logins_count}</Text.Normal14Black>
                        </div>
                     )
                : ('')}

                {/* {loadingListLastNLogs ? (
                    <Loader />
                ) : (
                    <List>{logs.reverse().map((logs) => list(logs))}</List>
                )} */}
                {logs.length >= pageSize ? (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={(e) => handleButtonLogs(e, props.userEmail)}
                        style={{ marginLeft: '382px' }}
                    >
                        View all logs
                    </Button>
                ) : (
                    ''
                )}
            </Drawer>
        </div>
    )
}
