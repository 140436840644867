import React, {useState, useEffect} from 'react'
import { Form, Button, Row, Col, Table} from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'
import { listProfileDetails } from '../actions/profileActions'

import { 
    listCompetitorDetails, 
    createCompetitor 
} from '../actions/competitorActions'


function Competitors_screen({match, history}) {

    const dispatch = useDispatch()
    const [accessToken, setAccessToken] = useState('')
    const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();


    //user details
    //const [email, setEmail] = useState('')

    const profileDetails = useSelector(state => state.profileDetails)
    const {
        //loading, 
       // error, 
        success, 
        profile} = profileDetails

     // Competitor details
     //const [competitorId, setCompetitorId] = useState('')
     const [competitorName, setCompetitorName] = useState('')
     const [competitorWebsite, setCompetitorWebsite] = useState('')
 
    //  const competitorCreate = useSelector(state => state.competitorCreate)
    //  const {//loading: loadingCreateCompetitor, 
    //     // error: errorCreateCompetitor, 
    //     // success: successCreateCompetitor, 
    //      // competitor: createCompetitor
    //  } = competitorCreate
 
     const competitorDetails = useSelector(state => state.competitorDetails)
     const {//loading: loadingDetailsCompetitor, 
         //error: errorDetailsCompetitor, 
         success: successDetailsCompetitor, 
         competitor
     } = competitorDetails

     useEffect(()=>{
        const getAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getAccessToken()
        if(!user) {
            loginWithRedirect()
        } else {
            if(accessToken) {
                if(success) {
                    //setEmail(profile.email)
                    
                    if(successDetailsCompetitor){
                        //setCompetitorId(competitor.id)
                        setCompetitorName(competitor.competitor_name)
                        setCompetitorWebsite(competitor.competitor_website)

                    } else{
                        dispatch(listCompetitorDetails(accessToken, match.params.websiteId))
                    }

                } else {
                    dispatch(listProfileDetails(accessToken, user.email))
                }
        }
    }
    },[user, 
        accessToken, 
        success, 
        successDetailsCompetitor,
        dispatch,
        loginWithRedirect,
        competitor,
        match,
        profile,
        getAccessTokenSilently,

    ])

    const redirectWebsiteHandler = (e) => {
        e.preventDefault()
        history.push(`/website/details/${match.params.websiteId}`)
    }

    const redirectCompetitorHandler = (e, id) => {
        e.preventDefault()
        history.push(`/competitor/edit/${id}`)
    }

    //Competitor functions
    const addCompetitorHandler = (e) => {
        e.preventDefault()
        const competitor = {
            competitor_name: competitorName,
            competitor_website: competitorWebsite,
        }
        dispatch(createCompetitor(accessToken, competitor, user.email, match.params.websiteId))
    }


    return (
        <>
        <Button 
            variant='secondary' 
            className='btn btn-light my-3'
            onClick={(e) => redirectWebsiteHandler(e)}
        >
            Go back
        </Button>
        <Row className="d-flex justify-content-between">
        <Col md={5}>
            
        
            <h3>Competitors</h3>
            <Form>
                <Form.Group controlId='competitorName'>
                    <Form.Label>
                        Competitor name
                    </Form.Label>
                    <Form.Control
                        required
                        type='name'
                        placeholder='Enter competitor name'
                        value={competitorName || ''}
                        onChange={(e) => setCompetitorName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId='competitorWebsite'>
                    <Form.Label>
                        Competitor website
                    </Form.Label>
                    <Form.Control
                        required
                        type='text'
                        placeholder='Enter competitor website'
                        value={competitorWebsite || ''}
                        onChange={(e) => setCompetitorWebsite(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>          
                <Button
                    variant='primary'
                    onClick={addCompetitorHandler}
                >
                    Add Competitor
                </Button> 
            </Form>
        </Col>
        <Col md={7}>
            <h3>Competitors List</h3>
            {(successDetailsCompetitor && !(competitor && competitor.length === 0)) ? 
                    <div>
                    <Table striped  hover responsive className='table-sm'>
                        <thead >
                            <tr>
                                <th>Competitor name</th>
                                <th>Competitor website</th>
                                <th>Created At</th>
                                <th></th>
                            </tr>
                        </thead>
                        {competitor.map((competitor, index) => (
                            <tbody key={index}>
                                <tr>
                                    <td>{competitor.competitor_name}</td>
                                    <td>{competitor.competitor_website}</td>
                                    <td>{competitor.createdAt.substring(0, 10)}</td>
                                    <td>
                                    <Button 
                                        variant='primary' 
                                        className='btn-sm' 
                                        onClick={(e) => redirectCompetitorHandler(e, competitor.id)}
                                    >
                                        <i className='fas fa-edit' ></i> Edit
                                    </Button>
                                    </td>
                                </tr>
                            </tbody>
                            ))} 
                    </Table>
                    
                    </div> :
                    <div>
                         <h3>Add a competitor</h3>
                    </div>
                }
        </Col>
        </Row>
        </>
    )
}

export default Competitors_screen
