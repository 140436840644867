import  axios from 'axios'
import { 
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    
    SEND_EMAIL_VERIFICATION_REQUEST,
    SEND_EMAIL_VERIFICATION_SUCCESS,
    SEND_EMAIL_VERIFICATION_FAIL
 } from '../constants/emailSenderConstants'

 
 export const changePasswordGetEmail = (accessToken, params) => async (dispatch) => {
    try {
        
        dispatch({
            type: CHANGE_PASSWORD_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.post(
            `https://serpr.eu.auth0.com/dbconnections/change_password`,
            params,
            config
        )

        dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CHANGE_PASSWORD_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

 export const sendEmailVerificationByEmail = (accessToken, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: SEND_EMAIL_VERIFICATION_REQUEST
        })
 
        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        const params = {
            "email":  email
          };
        const { data } = await axios.post(`/api/profile/sendVerificationEmailByEmail`, params, config) 

        dispatch({
            type: SEND_EMAIL_VERIFICATION_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SEND_EMAIL_VERIFICATION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
