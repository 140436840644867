export const PURPLE = "#901765"
export const PINK = "#C51852"
export const ORANGE = "#F75B2F"
export const BLACK = "#222222"
export const WHITE = "#FFFFFF"
export const GRAY = "#D4D4D4"
export const LIGHT_GRAY = "#EEEEEE"
export const SHADOW_GRAY = "#00000029"

// Color Palette
export const COLOR_PALETTE_1 = "#06071C"
export const COLOR_PALETTE_2 = "#3D1A42"
export const COLOR_PALETTE_3 = "#611F53"
export const COLOR_PALETTE_4 = "#981B5B"
export const COLOR_PALETTE_5 = "#CB1B4F"
export const COLOR_PALETTE_6 = "#EA443E"
export const COLOR_PALETTE_7 = "#F49664"
export const COLOR_PALETTE_8 = "#EFAE8B"