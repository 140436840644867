import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

export function TreeMapContainer({ serpCompositionResults }) {

  const [data] = useState([])
  const [firstRender, setFirstRender] = useState(0)

  useEffect(() => { 
    if(firstRender === 0) {
      serpCompositionResults.map((result, i) => {
        if(result.percent_of_serps_with_feature*100>0.7){
          data.push({x: result.serp_feature, 
            y: (result.percent_of_serps_with_feature*100).toFixed(2)
          })
        }
      })
      setFirstRender(firstRender+1)
      window.dispatchEvent(new Event('resize')); 

    }
    
    
  },
  []) 

    const series =  [
        {
          data
        }
      ]
    
      const options =  {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap',
          toolbar: {
            show: true,
            tools: {
              download: '<img src='+require("../../../images/visualization/download.svg").default+' width="20">'
            }
          }
        },
        
        plotOptions: {
          treemap: {
            enableShades: false,

            shadeIntensity: 0.1,
            reverseNegativeShade: false,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
            ranges: [{
                from: 0,
                to: 15,
                color: '#caa1b9',

            },
            {
                from: 15,
                to: 50,
                color: '#9c4a7a',
            },
            {
                from: 50,
                to: 100,
                color: '#841e5a',
            },
            
            ],
          }
        }
        },
      }

     

  return (
    <Chart 
              options={options}
              series={series}
              type='treemap'
              height={450}
              width='100%'
    />
  )
}
