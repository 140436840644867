import React from "react"
import { Container, Bold26Pink, Bold26Orange, Bold26Purple, Bold16Black,
    Field14Black, Bold16BlackOpacity, Field14BlackOpacity, Normal14Black,
    Normal14Purple, Flex, Icon16, Icon16Opacity, IconSelect, SelectText14Black,
    Bold40Black, Bold42Pink, Normal26Black, Bold26White, InputRegular16Black, AsterixPurple
} from "./styles/text"

export default function Text({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Text.Bold26Pink = function TextBold26Pink({children, ...restProps}){
    return <Bold26Pink {...restProps}>{children}</Bold26Pink>   
}

Text.Bold26Purple = function TextBold26Purple({children, ...restProps}){
    return <Bold26Purple {...restProps}>{children}</Bold26Purple>   
}

Text.Bold26White = function TextBold26White({children, ...restProps}){
    return <Bold26White {...restProps}>{children}</Bold26White>   
}

Text.Bold26Orange = function TextBold26Orange({children, ...restProps}){
    return <Bold26Orange {...restProps}>{children}</Bold26Orange>   
}

Text.Bold16Black = function TextBold16Black({children, ...restProps}){
    return <Bold16Black {...restProps}>{children}</Bold16Black>   
}

Text.Bold40Black = function TextBold40Black({children, ...restProps}){
    return <Bold40Black {...restProps}>{children}</Bold40Black>   
}

Text.Bold42Pink = function TextBold42Pink({children, ...restProps}){
    return <Bold42Pink {...restProps}>{children}</Bold42Pink>   
}


Text.Field14Black = function TextField14Black({children, ...restProps}){
    return <Field14Black {...restProps}>{children}</Field14Black>   
}

Text.Bold16BlackOpacity = function TextBold16BlackOpacity({children, ...restProps}){
    return <Bold16BlackOpacity {...restProps}>{children}</Bold16BlackOpacity>   
}

Text.Field14BlackOpacity = function TextField14BlackOpacity({children, ...restProps}){
    return <Field14BlackOpacity {...restProps}>{children}</Field14BlackOpacity>   
}

Text.Normal14Black = function TextNormal14Black({children, ...restProps}){
    return <Normal14Black {...restProps}>{children}</Normal14Black>   
}

Text.Normal26Black = function TextNormal26Black({children, ...restProps}){
    return <Normal26Black {...restProps}>{children}</Normal26Black>   
}

Text.Normal14Purple = function TextNormal14Purple({children, ...restProps}){
    return <Normal14Purple {...restProps}>{children}</Normal14Purple>   
}

Text.Flex = function TextFlex({children, ...restProps}){
    return <Flex {...restProps}>{children}</Flex>   
}

Text.Icon16 = function TextIcon16({children, ...restProps}){
    return <Icon16 {...restProps}>{children}</Icon16>   
}

Text.IconSelect = function TextIconSelect({children, ...restProps}){
    return <IconSelect {...restProps}>{children}</IconSelect>   
}

Text.Icon16Opacity = function TextIcon16Opacity({children, ...restProps}){
    return <Icon16Opacity {...restProps}>{children}</Icon16Opacity>   
}

Text.SelectText14Black = function TextSelectText14Black({children, ...restProps}){
    return <SelectText14Black {...restProps}>{children}</SelectText14Black>   
}

Text.InputRegular16Black = function TextInputRegular16Black({children, ...restProps}){
    return <InputRegular16Black {...restProps}>{children}</InputRegular16Black>   
}


Text.AsterixPurple = function TextAsterixPurple({children, ...restProps}){
    return <AsterixPurple {...restProps}>{children}</AsterixPurple>   
}