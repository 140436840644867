import { 
    COMPETITOR_LIST_REQUEST,
    COMPETITOR_LIST_SUCCESS,
    COMPETITOR_LIST_FAIL,
    COMPETITOR_LIST_RESET,

    COMPETITOR_DETAILS_REQUEST,
    COMPETITOR_DETAILS_SUCCESS,
    COMPETITOR_DETAILS_FAIL,
    COMPETITOR_DETAILS_RESET,

    COMPETITOR_DETAILS_BY_ID_REQUEST,
    COMPETITOR_DETAILS_BY_ID_SUCCESS,
    COMPETITOR_DETAILS_BY_ID_FAIL,
    COMPETITOR_DETAILS_BY_ID_RESET,

    COMPETITOR_CREATE_REQUEST,
    COMPETITOR_CREATE_SUCCESS,
    COMPETITOR_CREATE_FAIL,
    COMPETITOR_CREATE_RESET,

    COMPETITOR_UPDATE_REQUEST,
    COMPETITOR_UPDATE_SUCCESS,
    COMPETITOR_UPDATE_FAIL,
    COMPETITOR_UPDATE_RESET,

    COMPETITOR_DELETE_REQUEST,
    COMPETITOR_DELETE_SUCCESS,
    COMPETITOR_DELETE_FAIL,
    COMPETITOR_DELETE_RESET,
 } from '../constants/competitorConstants'

 export const competitorListReducer =  (state = {competitors:[]}, action) => {
    switch(action.type) {
        case COMPETITOR_LIST_REQUEST:
            return {loading: true, requests:[]}

        case COMPETITOR_LIST_SUCCESS:
            return {loading: false, competitors: action.payload}

        case COMPETITOR_LIST_FAIL:
            return {loading: false, error: action.payload }

        case COMPETITOR_LIST_RESET: 
        return {loading:false, success:false, competitor: {}}

        default:
            return state
    }
}

 export const competitorCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case COMPETITOR_CREATE_REQUEST: 
            return {loading:true}

        case COMPETITOR_CREATE_SUCCESS: 
            return {loading:false, success: true, competitor: action.payload}
        
        case COMPETITOR_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case COMPETITOR_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const competitorUpdateReducer = ( state = { competitor: {} }, action ) => {
    switch(action.type){
        case COMPETITOR_UPDATE_REQUEST: 
            return {loading:true}

        case COMPETITOR_UPDATE_SUCCESS: 
            return {loading:false, success: true, competitor: action.payload}
        
        case COMPETITOR_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case COMPETITOR_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}


export const competitorDetailsReducer = ( state = {competitor :[]}, action ) => {
    switch(action.type){
        case COMPETITOR_DETAILS_REQUEST: 
            return {loading:true}

        case COMPETITOR_DETAILS_SUCCESS: 
            return {loading:false, success:true,  competitor: action.payload}
        
        case COMPETITOR_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case COMPETITOR_DETAILS_RESET: 
            return {loading:false, success:false, competitor: []}

        default: 
            return state
    }
}

export const competitorDetailsByIdReducer = ( state = {competitor :{}}, action ) => {
    switch(action.type){
        case COMPETITOR_DETAILS_BY_ID_REQUEST: 
            return {loading:true, }

        case COMPETITOR_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  competitor: action.payload}
        
        case COMPETITOR_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case COMPETITOR_DETAILS_BY_ID_RESET: 
            return {loading:false, success:false, competitor: {}}

        default: 
            return state
    }
}

export const competitorDeleteReducer = (state = {competitor :[]}, action ) => {
    switch(action.type){
        case COMPETITOR_DELETE_REQUEST: 
            return {loading:true}

        case COMPETITOR_DELETE_SUCCESS: 
            return {loading:false, success:true,  competitor: action.payload}
        
        case COMPETITOR_DELETE_FAIL: 
            return {loading:false, error: action.payload}

        case COMPETITOR_DELETE_RESET: 
            return {loading:false, success:false, competitor: []}

        default: 
            return state
    }

}