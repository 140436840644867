import React from 'react'
import { TemplateContainer } from '../../containers/dashboard/template'

export default function DeleteCompany({accessToken, name, email, isActive, isSuperuser}) {
    return (
    <TemplateContainer pageName={'Delete Company'}  email={email} 
    name={name} 
    accessToken={accessToken}
    isActive={isActive}
    isSuperuser={isSuperuser}
    />)
}

