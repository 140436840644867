import React, { useState, useEffect } from "react";

import {  Content } from "../../../styled-components/visualization";

import { BiggestOpportunitiesBarStackedContainer } from "../charts/BiggestOpportunitiesBarStacked";
import { TitleContainer } from "../utilComponents/Title";

import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";
import LoaderImage from "../../../components/LoaderImage";
import ErrorMessage from "../../../components/ErrorMessage";

export function ChannelActivationStrategiesContainer({loadingCompetitorChannel,successCompetitorChannel,errorCompetitorChannel,competitorChannelResults}) {

  const [uniqueElements] = useState([]) 


   useEffect(() => { 
    if(successCompetitorChannel){
      if(competitorChannelResults[0]){
        uniqueElements.splice(0,uniqueElements.length)
      competitorChannelResults?.map(result => {
        if(uniqueElements.indexOf(result.domain) === -1) {
          uniqueElements.push(result.domain)
        }
      
      })
      }
      
    }
    
  },
  [competitorChannelResults,successCompetitorChannel,
  uniqueElements
  ])

  return (
    <Content.FlexColumn
    style={{
            justifyContent: 'space-between',
            padding: '1%',
            background: 'white'
        }}
    >
        <TitleContainer 
            title='Competitive Traffic Drivers'    
            subtitle='Unveil competitor search strategies with an in-depth breakdown of their channel activation approach.'
            backdropTop = '185vw'      
            pages={insightsText.ChannelActivationStrategies[0]}
            p11={insightsText.ChannelActivationStrategies[1]}
            p21={insightsText.ChannelActivationStrategies[2]}
            p31={insightsText.ChannelActivationStrategies[3]}
            p41={insightsText.ChannelActivationStrategies[4]}
            p51={insightsText.ChannelActivationStrategies[5]}
            assistantMessage={assistantText.ChannelActivationStrategies}
            />

          
      <Content.FlexColumn className={"justifyContentCenter"} >
      {
        loadingCompetitorChannel || !successCompetitorChannel 
        ? 
        <LoaderImage /> : 
        errorCompetitorChannel 
        ? 
        <ErrorMessage /> :
        competitorChannelResults[0] && competitorChannelResults.length !== 0  ? 
            <Content.FlexRow
            style={{
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                width: '100%'
              }}
            >
              <BiggestOpportunitiesBarStackedContainer competitorChannelResults={competitorChannelResults} uniqueElements={uniqueElements}/>
            </div>

            </Content.FlexRow>
        : 
          <>
          <ResultsMissing details = {competitorChannelResults.detail}/>
          </>
      }
      </Content.FlexColumn>

    </Content.FlexColumn>
  )
}
