import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
   height: 77px;
  letter-spacing: 0;
  box-shadow: 10px 0px 16px #00000014;
  width: 100%;
`;

export const SectionTop = styled.div`
  width: 100%;
  height: 79px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  box-shadow: 10px 0px 16px #00000014;
`;

export const SectionBottom = styled.div`
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // box-shadow: 10px 0px 16px #00000014;
`;

export const Path1 = styled.p`
  font-size: 14px;
  left: 121px;
  color: #989898;
  margin: 0;
  margin-left: 50px;
`;

export const Path2 = styled.p`
  font-size: 14px;
  left: 121px;
  color: #222222;
  margin: 0;
`;

export const PostHeaderText = styled.p`
  font-size: 50px;
  // font-family: tenon;
  font-weight: bold;
  color: #222222;
  margin: 0;
  margin-left: 50px;
`;

export const Icon = styled.img`
  width: 26px;
  height: 26px;

  &:hover {
    cursor: pointer;
    background-color: white;
  }
`;

export const IconContainer = styled.div`
  width: 20px;
  //comment to be removed when notification system is added
//  width: 208px;
  height: 78px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const EmailContainer = styled.button`
  margin: 0;
  background-color: inherit;
  font-size: 14px;
  border: none;
  img {
    width: 10px;
    height: 4px;
    margin-left: 24px;
  }
`;
export const NotificationNumber = styled.p`
  display: box;
  margin: 0;
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  margin-left: 6px;
  margin-right: 14px;
`;
