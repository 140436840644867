import React,{ useState } from "react";

import { Text, Content } from "../../../styled-components/visualization";
import { CounterContainer } from "../utilComponents/Counter";
import { TitleContainer } from "../utilComponents/Title";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Regular18Purple } from "../../../styled-components/visualization/text/styles/text";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";

export function IndustryNetPower100({serprScore100Results, industry}) {
    const csvHeader = [
        { label: "Website", key: "domain" },
        { label: "Domain Score", key: "serpr_score" }
    ];

    const table = () => {
        return(
            <Content.FlexColumn
                style={{
                    width: '55%',
                
                }}
            >
            <Content.FlexRow
                style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    padding: '10px'
                }}
            >
                <Content.FlexColumn
                    style={{
                        width: '30px'
                    }}
                >

                </Content.FlexColumn>

                <Content.FlexColumn
                    style={{
                        width: '40%'
                    }}
                >
                    <Text.Regular13Grey>Website</Text.Regular13Grey>
                </Content.FlexColumn>

                <Content.FlexColumn
                    style={{
                        width: '40%'
                    }}
                >
                    <Text.Regular13Grey>Domain Score</Text.Regular13Grey>
                </Content.FlexColumn>
            </Content.FlexRow>
            <Content.IndustryNetScoreScroll>
            {serprScore100Results?.map((result, i)=>(
                <Content.HoverRow
                key={i+1}
                >
                    <Content.FlexColumn
                        style={{
                            width: '30px'
                        }}
                    >
                      <CounterContainer  number={i+1} />     
                    </Content.FlexColumn>

                    <Content.FlexColumn
                        style={{
                            width: '40%'
                        }}
                    >
                    <Text.Regular14Black>{result.domain}</Text.Regular14Black>
                    </Content.FlexColumn>

                    <Content.FlexRow
                        style={{
                            width: '40%',
                            justifyContent: 'space-between'
                        }}
                    >
                    <div
                        style={{
                            width: '13%'
                        }}
                    >
                        <Text.Regular14Black>{result.serpr_score}</Text.Regular14Black>
                    </div>
                    <div
                        style={{
                                width: '85%'
                            }}
                    >
                    <ProgressBar 
                    variant="success" 
                    now={result.serpr_score}
                    //animated='true'
                    label='%'
                    />
                    </div>
                    
                    </Content.FlexRow>
                </Content.HoverRow>
            ))}
            
            </Content.IndustryNetScoreScroll>            
            </Content.FlexColumn>
        )
    }

    const howWeRank = () => {
        return(
            <Content.FlexColumn
            style={{
                    width: '25%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10%'
                }}
            >
                <Regular18Purple>How we rank</Regular18Purple>
                <div>

                </div>
                <Text.Regular14Black
                    style={{
                        textAlign: 'center',
                        marginTop: '5%'
                    }}
                >Websites are ranked by their ownership across your keywords and keywords tracked by related companies in Luminr.</Text.Regular14Black>
                <Text.Bold15Black
                    style={{
                        marginTop: '5%'
                    }}
                >
                    100
                </Text.Bold15Black>
                <Text.Regular14Black>
                = best performing website
                </Text.Regular14Black>
                <Text.Bold15Black
                    style={{
                        marginTop: '5%'
                    }}
                >
                    50
                </Text.Bold15Black>
                <Text.Regular14Black>
                = half as strong
                </Text.Regular14Black>
                <Text.Bold15Black
                    style={{
                        marginTop: '5%'
                    }}
                >
                    25
                </Text.Bold15Black>
                <Text.Regular14Black>
                = quarter as strong
                </Text.Regular14Black>
            </Content.FlexColumn>
        )
    }

    const Capitalize = (str) =>{
    return str.charAt(0).toUpperCase() + str.slice(1);
    }

  return (
    <Content.FlexColumn className={"justifyContentCenter"}
        style={{
            maxHeight: '490px',
            background: 'white',
            padding: '1%',
            marginTop: '3%'
        }}
    >
        <TitleContainer 
            title={Capitalize(industry) + ' Page One Power Players'}
            subtitle='See the Top 100 companies in your industry with the highest visibility on page one.'
            pages={insightsText.IndustryNetPower100forSportswear[0]}
            p11={insightsText.IndustryNetPower100forSportswear[1]}
            p21={insightsText.IndustryNetPower100forSportswear[2]}
            p31={insightsText.IndustryNetPower100forSportswear[3]}
            p41={insightsText.IndustryNetPower100forSportswear[4]}
            assistantMessage={assistantText.IndustryNetPower100forSportswear}
            csvData={serprScore100Results}
            csvHeader={csvHeader}

        />
        <Content.FlexRow
            style={{
                marginTop: '1%',
                justifyContent: 'space-between'
            }}
        >
            {serprScore100Results?.length != 0? 
            serprScore100Results?.detail !== undefined ? 
                <Content.FlexColumn style={{width:'75%'}}>
                    <ResultsMissing   details = {serprScore100Results?.detail}/>
                </Content.FlexColumn>
                
                 : 
                 table()
            :
            <Content.FlexColumn style={{width:'75%'}}>
                    <ResultsMissing   details = {serprScore100Results?.detail}/>
            </Content.FlexColumn>
            }
            {howWeRank()}
        </Content.FlexRow>
    </Content.FlexColumn>
  )
}
