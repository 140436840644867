import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Content, Grid, Text, Table, General } from "../../styled-components/dashboard";
import { deleteWebsite, listWebsiteDetailsByEmail } from '../../actions/websiteActions'
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import AddNewButton from "../../components/AddNewButton";
import { FlexColumn, FlexRow } from "../../styled-components/visualization/content/styles/content";
import Select from "../../components/Select";
import { listCompanyDetails } from '../../actions/companyActions'
import { useHistory } from "react-router-dom"
import { WEBSITE_DELETE_RESET, WEBSITE_DETAILS_BY_EMAIL_RESET, WEBSITE_DETAILS_BY_ID_RESET} from '../../constants/websiteConstants'
import { COMPETITOR_DETAILS_RESET} from '../../constants/competitorConstants'
import { KEYWORD_DETAILS_RESET, KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET} from '../../constants/keywordConstants'
import * as ROUTES from "../../constants/routes/routes"
import Modal from 'react-modal';


export function WebsitesContainer({ accessToken, email, name }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [company, setCompany] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [companyArray, setCompanyArray] = useState([])

  const websiteDetailsByEmail = useSelector(state => state.websiteDetailsByEmail)
  const {
      loading: loadingDetailsWebsiteByEmail, 
      error: errorDetailsWebsiteByEmail, 
      success: successDetailsWebsiteByEmail, 
      website: detailsWebsiteByEmail
  } = websiteDetailsByEmail

  const companyDetails = useSelector(state => state.companyDetails)
  const {
      loading: loadingDetailsCompany, 
      error: errorDetailsCompany, 
      success: successDetailsCompany, 
      company: detailsCompany
  } = companyDetails

  const websiteDelete = useSelector(state => state.websiteDelete)
  const {
      loading: loadingDeleteWebsite, 
      error: errorDeleteWebsite, 
      success: successDeleteWebsite, 
      website: deleteWebsiteMessage
  } = websiteDelete

  useEffect(()=>{
    if(detailsCompany[0]){
        detailsCompany.map((x) => {
            if(x.name===company){
             setCompanyId(`/add-website/${x.id}`)
            }
        })
      }
    if(accessToken && email) 
        {
            
            if(successDetailsCompany){
                
                if(successDetailsWebsiteByEmail){
                    if(!companyArray[0]){
                        if(detailsCompany[0]){
                            setCompany(detailsCompany[0].name)
                            detailsCompany.map((company) => (
                                setCompanyArray(prevArray => [...prevArray, company.name])
                            ))
                        }
                    }
                }
                else {
                        dispatch(listWebsiteDetailsByEmail(accessToken, email, name))
                    }
                } 
             else {
                dispatch(listCompanyDetails(accessToken, email, name))
            }
            if(successDeleteWebsite){
                
                dispatch({type: WEBSITE_DELETE_RESET})
                dispatch({type: WEBSITE_DETAILS_BY_EMAIL_RESET})
                dispatch(listWebsiteDetailsByEmail(accessToken, email, name))
                
              }
        }
    },[email,
        accessToken,  
        dispatch,
        successDetailsWebsiteByEmail,
        detailsWebsiteByEmail,
        successDetailsCompany,
        successDeleteWebsite,
      detailsCompany,
      company
    ])

    const handlerGoToEdit = (id) => {
        dispatch({type: WEBSITE_DETAILS_BY_ID_RESET})
        dispatch({type: COMPETITOR_DETAILS_RESET})
        dispatch({type: KEYWORD_DETAILS_RESET})
        dispatch({type: KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET})

        history.push(`/edit-website/${id}`)

    }

     //MODAL DELETE
    const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
    const [websiteId, setWebsiteId] = React.useState('');
    const [websiteName, setWebsiteName] = React.useState('');

    const customStylesDelete = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '450px',
        padding: '3%'
        },
    };

    function openDeleteModal(c_name, c_id) {
        setWebsiteId(c_id) // we need to store the state, although it will always delete the laste element from list
        setWebsiteName(c_name)
        setDeleteModalIsOpen(true);
    }

    function closeDeleteModal() {
        setDeleteModalIsOpen(false);
    }
    const deleteWebsiteHandler = () => {
        setDeleteModalIsOpen(false);
        dispatch(deleteWebsite(accessToken, websiteId))
    }

    const modalDelete = (c_id) => {
        return(
                <Modal
                    isOpen={deleteModalIsOpen}
                    onRequestClose={closeDeleteModal}
                    style={customStylesDelete}
                    ariaHideApp={false}
                >
                <Text.Bold40Black>
                    Are you sure you want to remove
                    </Text.Bold40Black> <span> </span>
                    <Text.Bold42Pink>
                    {websiteName} 
                    </Text.Bold42Pink> <span> </span>
                    <Text.Bold40Black>
                    from your websites list?
                    </Text.Bold40Black>
                    <FlexRow
                    style={{
                    justifyContent: 'space-between',
                    marginTop: '30px'
                    }}
                >
                    <Button.Back
                    style={{
                        margin: '0'
                    }}
                    onClick={closeDeleteModal}
                    >
                    Cancel
                    </Button.Back>
                    <Button.Save
                    onClick={()=>deleteWebsiteHandler()}
                    >
                    Delete
                    </Button.Save>

                </FlexRow>
                </Modal>
        )
    }

    const websitesTable = () => {
    return(
        <FlexColumn>
            <FlexRow
                style={{
                    justifyContent: 'space-between',
                    maxWidth: '1000px',
                    marginLeft: '-10px',
                    padding: '10px'
                }}
            >
                {/* <FlexColumn
                    style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Website Name
                    </Text.Bold16Black>
                </FlexColumn> */}
                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Website URL
                    </Text.Bold16Black>
                </FlexColumn>
                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Company
                    </Text.Bold16Black>
                </FlexColumn>
                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Active
                    </Text.Bold16Black>
                </FlexColumn>

                <FlexColumn
                style={{
                        width: '15%'
                    }}
                >
                    <Text.Bold16Black>
                        Actions
                    </Text.Bold16Black>
                </FlexColumn>
            </FlexRow>
            {detailsWebsiteByEmail.map((website, i)=> (
                <General.TableRow
                key={i} 
                    style={{
                        justifyContent: 'space-between',
                        maxWidth: '1000px',
                        }}
                    >
                    {/* <FlexColumn
                        style={{
                            width: '15%'
                        }}
                        >
                        <Text.Normal14Black>
                            {website.name}
                        </Text.Normal14Black>

                    </FlexColumn> */}
                    <FlexColumn
                    style={{
                            width: '15%'
                        }}
                    >
                    <Text.Normal14Black>
                            {website.website}
                        </Text.Normal14Black>
                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '15%'
                        }}
                    >
                       {website.company}
                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '15%'
                        }}
                    >
                    {website.active ? <Text.Normal14Black>
                           Yes
                        </Text.Normal14Black> :
                        <Text.Normal14Black>
                           No
                        </Text.Normal14Black> 
                        }
                    </FlexColumn>

                    <FlexRow
                    style={{
                            width: '15%'
                        }}
                    >
                    <Table.IconLink
                   
                    onClick={() => handlerGoToEdit(website.id)}    
                    alt="edit"
                    src={require("../../images/icon_edit_pink.svg").default}
                    

                />
                <Table.IconLink
                    onClick={() => openDeleteModal(website.website,website.id)}
                    
                    alt="delete"
                    src={require("../../images/icon_delete_pink.svg").default} 
                    
                    
                />
                    </FlexRow>
                    {modalDelete(website.id)}
                </General.TableRow>
            ))}
        </FlexColumn>
        
    )
    }
    const noCompanyContainer = () => {
        return(
            <Content.SectionTopWithBackground>
                <Content.FlexColumn className="centeredContent">
                    <Text.Normal26Black>
                        You are not tracking any websites in Luminr yet
                    </Text.Normal26Black>
                    <br/>
                    <Text.Normal14Black>
                        To get started add your company now. Then follow the steps for adding websites.
                    </Text.Normal14Black>
                    <AddNewButton route={ROUTES.ADD_COMPANY} text='Add Company' />
                </Content.FlexColumn>
            </Content.SectionTopWithBackground>
            )
    }

    const noWebsiteContainer = () => {
        return(
            <Content.SectionTopWithBackground>
                <Content.FlexColumn className="centeredContent" style={{top:'20%'}}>
                    <Text.Normal26Black>
                        You have not added any website yet.
                    </Text.Normal26Black>
                    <br/>
                    <Text.Normal14Black>
                            Please add your first new website.
                    </Text.Normal14Black>


                    {detailsCompany[0] &&
                        <FlexRow
                            style= {{
                                alignItems: 'center',
                            }}
                        >
                        <FlexColumn
                            style={{
                                marginTop: '2rem',
                                maxWidth: '300px',
                                width:'200px'
                            }}
                        >
                    
                        {detailsCompany[0] && <Select param={company} setParam={setCompany} list={companyArray} placeholder='Choose a company' title='Choose a company'/> }
                            <FlexRow style={{margin:'auto'}}>
                                    <AddNewButton route={companyId}text='Add Website' />

                            </FlexRow>
                        </FlexColumn>
                       
                         
                        </FlexRow>
                        }

                </Content.FlexColumn>
            </Content.SectionTopWithBackground>
            )
    }

  return(
       <Content>
          {loadingDetailsWebsiteByEmail || loadingDetailsCompany  || loadingDeleteWebsite || !successDetailsWebsiteByEmail || successDeleteWebsite
          ?
            <LoaderImage /> :
            errorDetailsWebsiteByEmail || errorDetailsCompany || errorDeleteWebsite ?
            <ErrorMessage /> :
            <Content.SectionTop>

                    { !detailsCompany[0] ?
                        <>{noCompanyContainer()}</>
                         :
                            
                        detailsWebsiteByEmail[0] ? 
                        <Grid.SectionFull >
                             {websitesTable()}
                             {detailsCompany[0] &&
                        <FlexRow
                            style= {{
                                alignItems: 'center',
                            }}
                        >
                        <FlexColumn
                            style={{
                                marginTop: '2rem',
                                maxWidth: '300px'
                            }}
                        >
                            <Text.Bold26Pink>
                                Add a new website
                            </Text.Bold26Pink>
                            <Text.Normal14Black
                                style={{
                                    marginBottom:'.2rem'
                                }}
                            >
                                Your new website must belong to a company. Please select a company from your list.
                            </Text.Normal14Black>
                        {detailsCompany[0] && <Select param={company} setParam={setCompany} list={companyArray} placeholder='Choose a company' title='Choose a company'/> }
                         <div>
                         <AddNewButton 
                        
                         route={companyId}
                         text='Add Website' />
                         </div>
                        </FlexColumn>
                       
                         
                        </FlexRow>
                        }
                        </Grid.SectionFull > :

                            <>{noWebsiteContainer()}</>
                            
                    }   
               
                    <Grid.SectionFull >
                        
                        
                        
                    </Grid.SectionFull>
            
            </Content.SectionTop>
          }
        
      </Content>
  )
}
