import  axios from 'axios'
import { 
    SUBSCRIPTION_LIST_REQUEST,
    SUBSCRIPTION_LIST_SUCCESS,
    SUBSCRIPTION_LIST_FAIL,

    SUBSCRIPTION_DETAILS_REQUEST,
    SUBSCRIPTION_DETAILS_SUCCESS,
    SUBSCRIPTION_DETAILS_FAIL,
    
    SUBSCRIPTION_DETAILS_BY_ID_REQUEST,
    SUBSCRIPTION_DETAILS_BY_ID_SUCCESS,
    SUBSCRIPTION_DETAILS_BY_ID_FAIL,

    SUBSCRIPTION_CREATE_REQUEST,
    SUBSCRIPTION_CREATE_SUCCESS,
    SUBSCRIPTION_CREATE_FAIL,

    SUBSCRIPTION_UPDATE_STATUS_REQUEST,
    SUBSCRIPTION_UPDATE_STATUS_SUCCESS,
    SUBSCRIPTION_UPDATE_STATUS_FAIL,

    SUBSCRIPTION_DELETE_REQUEST,
    SUBSCRIPTION_DELETE_SUCCESS,
    SUBSCRIPTION_DELETE_FAIL,

 } from '../constants/subscriptionConstants'


 export const listSubscriptions = (accessToken) => async (dispatch) =>{
    try {
        dispatch({type:SUBSCRIPTION_LIST_REQUEST})

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.get(
            `/api/subscription/`,
            config
            )

        dispatch({
            type: SUBSCRIPTION_LIST_SUCCESS,
            payload: data
        })


    } catch(error){
        dispatch({
            type: SUBSCRIPTION_LIST_FAIL,
            payload: error.response && error.response.data.message ?
            error.response.data.message 
            : error.message,
        })
    }
 }

 export const createSubscription = (accessToken, subscription) => async (dispatch) => {
    try {
       
        dispatch({
            type: SUBSCRIPTION_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.post(
            `/api/subscription/create/${subscription.email}`,
            subscription,
            config
        )

        dispatch({
            type: SUBSCRIPTION_CREATE_SUCCESS,
            payload: data,
        })

        dispatch(listSubscriptionDetails(accessToken, subscription.email))


    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateSubscription = (accessToken, subscription, subscriptionId) => async (dispatch) => {
    try {
       
        dispatch({
            type: SUBSCRIPTION_UPDATE_STATUS_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/subscription/update/${subscriptionId}`,
            subscription,
            config
        )

        dispatch({
            type: SUBSCRIPTION_UPDATE_STATUS_SUCCESS,
            payload: data,
        })

        //dispatch(listSubscriptionDetailsById(accessToken, subscriptionId, subscription.email))

    } catch (error) {
        dispatch({
            type: SUBSCRIPTION_UPDATE_STATUS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listSubscriptionDetails = (accessToken, email) => async (dispatch) => {
    try{
        dispatch({ type: SUBSCRIPTION_DETAILS_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/subscription/byEmail/${email}`,
            config
            )

        dispatch({ 
            type: SUBSCRIPTION_DETAILS_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: SUBSCRIPTION_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 
export const listSubscriptionDetailsById = (accessToken, id, email) => async (dispatch) => {
    try{
        dispatch({ type: SUBSCRIPTION_DETAILS_BY_ID_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/subscription/byId/${id}/${email}`,
            config,

            )

        dispatch({ 
            type: SUBSCRIPTION_DETAILS_BY_ID_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: SUBSCRIPTION_DETAILS_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const deleteSubscription = (accessToken, id) => async (dispatch) => {
    try{
        dispatch({ type: SUBSCRIPTION_DELETE_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.delete(
            `/api/subscription/delete/${id}`,
            config
            )

        dispatch({ 
            type: SUBSCRIPTION_DELETE_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: SUBSCRIPTION_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }