import styled from 'styled-components'


export const Container = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: left;
    width: 100%;
    padding: 34px 24px 0 0;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    margin-right: 30px;
`

export const Title = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`

export const Label = styled.input`
    display: block;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
`
