import React from 'react'
import {
    Background,
    Container,
    Logo,
    Title,
    ButtonGroup,
    ButtonBack,
    ButtonNext,
    Input,
    Text,
    LineUp,
    LineDown,
    LineBottom,
} from './styles/signup'
import { Link as ReactRouterLink } from 'react-router-dom'

export default function Signup({ bg = true, children, ...restProps }) {
    return bg ? <Background {...restProps}>{children}</Background> : children
}

Signup.Frame = function SignupFrame({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>
}

Signup.Logo = function SignupLogo({ to, ...restProps }) {
    return (
        <ReactRouterLink to={to}>
            <Logo {...restProps}></Logo>
        </ReactRouterLink>
    )
}

Signup.Title = function SignupTitle({ children, ...restProps }) {
    return <Title {...restProps}>{children}</Title>
}

Signup.ButtonGroup = function SignupButtonGroup({ children, ...restProps }) {
    return <ButtonGroup {...restProps}></ButtonGroup>
}

Signup.ButtonBack = function SignupButtonBack({ children, ...restProps }) {
    return <ButtonBack {...restProps}>{children}</ButtonBack>
}

Signup.ButtonNext = function SignupButtonNext({ children, ...restProps }) {
    return <ButtonNext {...restProps}>{children}</ButtonNext>
}

Signup.Input = function SignupInput({ children, ...restProps }) {
    return <Input {...restProps}>{children}</Input>
}

Signup.Text = function SignupText({ children, ...restProps }) {
    return <Text {...restProps}>{children}</Text>
}

Signup.LineUp = function SignupLineUp({ children, ...restProps }) {
    return <LineUp {...restProps}>{children}</LineUp>
}

Signup.LineDown = function SignupLineDown({ children, ...restProps }) {
    return <LineDown {...restProps}>{children}</LineDown>
}

Signup.LineBottom = function SignupLineBottom({ children, ...restProps }) {
    return <LineBottom {...restProps}>{children}</LineBottom>
}
