import React from "react"
import { Container, Body, Menu, Content, MenuOption, MenuOptionSelected,
    MenuHelp, MenuContainer, HelpButton, HelpButtonImg
} from "./styles/main"

export default function Main({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}


Main.Body = function MainBody({children, ...restProps}){
    return <Body {...restProps}>{children}</Body>   
}

Main.MenuContainer = function MainMenuContainer({children, ...restProps}){
    return <MenuContainer {...restProps}>{children}</MenuContainer>   
}

Main.Menu = function MainMenu({children, ...restProps}){
    return <Menu {...restProps}>{children}</Menu>   
}

Main.MenuHelp = function MainMenuHelp({children, ...restProps}){
    return <MenuHelp {...restProps}>{children}</MenuHelp>   
}

Main.MenuOption = function MainMenuOption({children, ...restProps}){
    return <MenuOption {...restProps}>{children}</MenuOption>   
}

Main.MenuOptionSelected = function MainMenuOptionSelected({children, ...restProps}){
    return <MenuOptionSelected {...restProps}>{children}</MenuOptionSelected>   
}

Main.Content = function MainContent({children, ...restProps}){
    return <Content {...restProps}>{children}</Content>   
}

Main.HelpButton = function MainHelpButton({children, ...restProps}){
    return <HelpButton {...restProps}>{children}</HelpButton>   
}

Main.HelpButtonImg = function MainHelpButtonImg({children, ...restProps}){
    return <HelpButtonImg {...restProps}>{children}</HelpButtonImg>   
}


