import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Loader from '../../components/Loader'
import Message from '../../components/Message'

import { listLogs } from '../../actions/loggingsystem/loggingSystemActions'


function LogsListScreen() {
    const dispatch = useDispatch()
    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
        useAuth0()
    const [accessToken, setAccessToken] = useState('')

    const profileDetails = useSelector((state) => state.profileDetails)
    const { success: successProfile, profile } = profileDetails

    const logsList = useSelector((state) => state.logsList)
    const {
        loading: loadingLogs,
        error: errorLogs,
        success: successLogs,
        logs,
    } = logsList

    useEffect(() => {
        const getAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getAccessToken()
        if (
            isAuthenticated &&
            profile &&
            (profile.isAdmin || profile.isSuperuser)
        ) {
            if (accessToken) if (!successLogs) dispatch(listLogs(accessToken))
        } else {
            loginWithRedirect()
        }
    }, [
        accessToken,
        successLogs,
        successProfile,
        dispatch,
        isAuthenticated,
        loginWithRedirect,
        profile,
        getAccessTokenSilently,
    ])


    const tableBody = (logs) => {
        return (
            <tr key={logs.id}>
                <td>{logs.userEmail}</td>
                <td>
                    {logs.logType === 'error' ? (
                        <i className="fas fa-circle" style={{ color: 'red' }}>
                            {' '}
                        </i>
                    ) : logs.logType === 'warning' ? (
                        <i
                            className="fas fa-circle"
                            style={{ color: 'yellow' }}
                        >
                            {' '}
                        </i>
                    ) : logs.logType === 'info' ? (
                        <i className="fas fa-circle"> </i>
                    ) : (
                        <i className="fas fa-circle" style={{ color: 'green' }}>
                            {' '}
                        </i>
                    )}{' '}
                    {logs.logType}
                </td>
                <td>{logs.actionType}</td>
                <td>{logs.logMessage}</td>
                <td>
                    {new Intl.DateTimeFormat('en-GB', {
                        dateStyle: 'long',
                        timeStyle: 'short',
                    }).format(Date.parse(logs.createdAt))}
                </td>
            </tr>
        )
    }

    return (
        <div>
            <h1>Logs Admin Screen</h1>
            <Link to="/admin" className="btn btn-light my-3">
                Go Back
            </Link>

            {loadingLogs ? (
                <Loader />
            ) : errorLogs ? (
                <Message variant="danger">{errorLogs}</Message>
            ) : (
                <Table striped bordered hover responsive className="table-sm">
                    <thead>
                        <tr>
                            <th>USER EMAIL</th>
                            <th>LOG TYPE</th>
                            <th>ACTION TYPE</th>
                            <th>LOG MESSAGE</th>
                            <th>CREATED AT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.reverse().map((logs) => tableBody(logs))}
                    </tbody>
                </Table>
            )}
        </div>
    )
}

export default LogsListScreen
