export const insightsText = {
    IndustryNetPower100forSportswear:[
         2,
        'Analyse the types of content the top scoring sites produce. What are their promotional tactics, or their social media strategies, for example.',
        'By understanding what is working well for them, you will gain valuable insights into what can work for your own marketing strategy.',
    ],
    IndustryAverageClickThroughRate:[
        2,
        'Having a benchmark for your industry CTR helps you understand how your search performance compares to the industry standard.',
        'Use this average to set realistic goals for search marketing and conversion targets',
    ],
    YourKeywordsPhrases:[
        1,
        'Insights coming soon',
    ],
    OverallSERPComposition:[
        3,
        'Deciding which SERP features to target?  Have a look at those that are the most common.',
        'If top-of-page ads are outshining organic results, it might be worth investing in a paid search campaign to reach your target audience.',
        'If rich results like videos, images or featured snippets are commonly shown in search results for your industry, you may want to create content that\'s optimised for these features to increase your visibility.',
    ],
    SERPRealEstateFingerprint:[
        2,
        'Explore the various rich results available to rank for in the SERP to boost your online presence.',
        'Ensure your site is properly set up to help Google rank your content: Structured Data Markup (https://developers.google.com/search/docs/advanced/structured-data/search-gallery)',
    ],
    //High-Volume CTR Booster
    KeywordRankingsOpportunities:[
        4,
        'Keywords where you are showing little to no CTR can signal opportunities for big traffic increases.',
        'Consider prioritising keywords with a lower-than-average CTR to find areas to optimise your content and ad campaigns for maximum impact.',
        'If the keywords displayed appear too generic to be realistic targets, use the filters to find the most lucrative ranking opportunities in reach.',
        'Identify high-performing keywords with above-average CTRs to build upon your existing strengths.',
    ],
    // Opportunities Radar
    SWOTAnalysis:[
        5,
        'This insight can help guide your organic and paid search strategies. Capitalise on your strengths while improving your weaknesses with the following tips:',
        'Areas with the highest CTRs show you where your digital strategy is working. Those with low CTR signal areas to build on.',
        'Learn from your strengths and consider using similar methods across other products, services, or topics where performance is not as strong.',
        'For example, if notice that you have a high CTR on keywords with low search volume, explore ways to expand your keyword list and capture more traffic.',
        'Or if you see that you have a low CTR on keywords with high search volume, consider optimising your metadata or experimenting with different ad copy to improve your performance.',
    ],
    // Top Content Opportunities
    ContentCreationOpportunities:[
        5,
        'Analyse which content types are most visible for your keywords and consider incorporating those formats into your content strategy.',
        'Consider refreshing any relevant and existing content for these successful keywords  or perhaps create new landing pages targeting those terms.',
        'Note gaps in content coverage (what your competitors aren’t offering) and fill it with your own relevant content.',
        'Look for opportunities to improve the relevance and quality of your content to rank higher in the SERPs for your targeted keywords.',
        'Monitor changes within competitor content and search rankings to stay up-to-date on the latest trends in your industry. A djust your strategy accordingly.',
    ],
    FeatureSnippetOpportunities:[
        3,
        'Focus your time on the top opportunities for maximum impact but first make sure your website supports Rich Results - check here.',
        'Create new, high-quality content that fills these gaps and targets the same keyword to increase the chances of ranking for the featured snippet.',
        'If you already have content ranking for the target keyword, optimise it for the Featured Snippet. Identify the most relevant section of the content and reformat it to better suit the featured snippet.',
    ],
    // Share of Search Leaderboard
    SerpScoreboard:[
        1,
        'Benchmark your own growth by tracking your commercial and SERP competitors month-on-month to see who you need to beat.',
    ],
    //Battle of the Brands
    CompetitorLandscape:[
        5,
        'How to take full advantage of the four quadrants your competitors are segmented into.',
        'Toughest Competitors: evaluate their content strategy and see if there are any opportunities to differentiate your own landing pages.',
        'Opportunistic Competitors: assess their keyword strategy and consider incorporating similar keywords into your own strategy.',
        'Rising Threats: keep an eye on their progress and try to outpace them in terms of content and backlinks.',
        'Under-the-Radar Competitors: you may be able to make quick gains by focusing on the keywords they\'re ranking for that you\'re not. Look for ways to capitalise on their weaknesses to gain more visibility across the SERPs.',
    ],
    CompetitorSWOTAnalysis:[
        4,
        'Look at the subgroups where you\'re struggling and see what strategies your top competitors are using. ',
        'Try conducting a content gap analysis to identify areas where your competitors have content that you don’t. Create new content of your own to fill those gaps.',
        'Focus on your own strengths. Use these to position yourself to compete effectively and differentiate your brand from your competitors.',
        'For example, you might create new content that further enhances your authority on that subject, or test and refine new ad copy to maximise clicks.',
    ],
    PaidSearchThreats:[
        2,
        'Use this to determine if you need more paid investment to compete with challengers  in your space.',
        'Or learn where you can potentially spend less by seeing if those ranking above you are too large to compete with - such as Amazon',
    ],
    ChannelActivationStrategies:[
        5,
        'Dissect competitor presence across search channels to refine your own search marketing strategy and budget allocation.',
        'If they are ranking well in organic but not using paid features, it might indicate an opportunity for you to focus on creating more high-quality content to better compete.',
        'If they are using paid search to drive traffic, investigate which keywords they\'re targeting and how much they\'re spending to inform your own paid search strategy.',
        'If a competitor is using Shopping Ads to promote their products, make sure your own product feed is optimised7 to take advantage of this feature.',
        'If they are leveraging video content to drive traffic, consider creating your own videos to promote on YouTube and other platforms.',
    ],
    TotalCTR:[
        1,
        'Insights coming soon',
    ],

    //Winners and Losers
    CompetitorMovements:[
        4,
        'If your competitors are dropping in the rankings, this could be a good opportunity to take advantage of their losses and gain ground.',
        'By seeing keywords where your competitors are bidding aggressively you can decide if you need to increase your bids to remain competitive.',
        'If a competitor is gaining ground with a particular strategy, such as producing high-quality content or paying for their visibility, is this something you can incorporate into your own strategy?',
        'Where competitors are losing ground in a particular keyword group, this could be a good opportunity for you to target that group with your own content or ads.',
    ],

}

export const assistantText = {
    IndustryNetPower100forSportswear:
    'SERP Real Estate is a metric used in Luminr to gauge a website\'s visibility on page one based on your keywords and keywords tracked by related companies. We take into account both organic and paid elements. \n\nThe higher the score, the greater the website\'s share of search traffic.\n\n100 = best performing website \n50 = half as strong \n25 = quarter as strong',
    
    IndustryAverageClickThroughRate:
    'The gauge ranges from 0% to X%. X is the highest CTR percentage held by any website we discovered in your industry. Your own CTR is represented by the line.',
    
    YourKeywordsPhrases:
    'Help coming soon',
    
    OverallSERPComposition:
    'There are typically 5-12 types of search features visible on each search. All have different levels of coverage and varying click-through rates. Hover over the heat map to see full details on each feature.',
    
    SERPRealEstateFingerprint:
    'This comparison helps you identify which SERP features your website might be missing out. Check if you\'re eligible to rank for rich results - check here.',
    
    //High-Volume CTR Booster
    KeywordRankingsOpportunities:
    'The default view is based on keywords with the highest monthly search volume in your market. Filter by keyword group to have more visibility across specific categories within your business.',
    
    // Opportunities Radar
    SWOTAnalysis:
    'This table compares Total Search CTR across your keyword groups so you can see your overall performance across products, segments, or audiences to find areas for growth.',
    
    //Top Content Opportunities
    ContentCreationOpportunities:
    ' Each square of the heatmap is sized based on the average monthly search volume of that keyword. Every row showcases a piece of content - clicking on the arrow to visit the source. \nClick on a row to update the heatmap to show which keywords searches users are searching for to find the content listed.',
    
    FeatureSnippetOpportunities:
    'The higher the Opportunity Score, the better your chance of replacing the current holder and getting your content to rank and the more worthwhile the opportunity. \nStability is how often the Featured Snippet appears in the search results. \nIf you have no URLs ranking, your website has no organic content ranking on page 1 Google for that keyword.',
    
    //Share of Search Leaderboard
    SerpScoreboard:
    'Use the \'Focus\'  filters at the top of the page to understand which websites are dominating different pockets of your portfolio. \nThis will help you understand who you are directly competing with for SERP Real Estate on different topics.',
    
    //Battle of the Brands
    CompetitorLandscape:
    'Luminr monitors the entire search space so you will see both commercial competitors and websites unknown to you. \n While not every site seems like a competitor on the surface, they appear on page one for your keywords so can be pulling traffic away from you. \nThe y axis is average positiosn and x axis how many keywords overlap with you anaysing them by how similar they are and how well they position in that overlap ',
    
    CompetitorSWOTAnalysis:
    'Expand the leftmost column to drill down further into each keyword group to learn all about your competitors’ strengths and weaknesses and figure out where they pose a threat to your search traffic. ',
    
    PaidSearchThreats:
    'Those with the highest Aggro Score are leading the race and are your biggest competition. \nEach website is scored against positions in the SERP, all keywords being tracked, and whether they are bidding on your brand name.',
    
    ChannelActivationStrategies:
    'This bar chart automatically shows the 10 websites with the highest visibility. \nApply filters to dive deeper into competitors\' strengths and weaknesses across your services, products, topics or location.',
    
    TotalCTR:
    'Help coming soon',
    
    //Winners and Losers
    CompetitorMovements:
    'Scoring is designed to showcase emerging trends and opportunities so you can optimise your own SEO and PPC campaigns to improve your rankings. \nImpact Score - the size or difficulty of the movement for that domain’s ranking keyword(s). The most significant movements will have the highest impact score. \nMajor Move - A very difficult or influential move such as improving from position 2 to 1. \nMinor Move - An "easier" move, for example, 20 to 19. \nNewcomers - They weren\'t on page one last month but are this month. \nDropout - No longer on page one but Luminr saw them last month.',
   
    DataRange:
    'Defaults to the last 30 days but for a deeper look into trends, we suggest trying the last 3 months. ',
    
    Focus:
    'Narrowing down by keywords or keyword groups provides a more focused look at how your digital marketing strategy is performing across specific queries.',
    
    Localisation:
    'Choose a specific location, domain or language to gain more targeted insights on performance within specific markets.',
    
    SERP:
    'Drill down by website to analyse specific competitors and gain insight into their strengths and weaknesses. Select SERP features to evaluate channel performance.',
    
    Searchesperformed:
    'Help coming soon',
    
    Rankingdomainsdiscovered:
    'Help coming soon',
    
    AverageCTR:
    'The way to benchmark how likely users are to click through to your site. Use this to compare your CTR performance to your industry. \n\nAre you below or above average? Have you improved since last month?',
    
    IndustryNetScore:
    'Help coming soon',
    
    SERPRealEstate:
    'This is a measurement to help you track your own month-on-month growth. We capture and analyse keywords, search volume and your domain’s search rankings to create this score. \n\nNote: There is no upper limit on this score similar to how there is no limit to the number of users searching for your keywords. The goal here is to be higher than your competition. \n\nWith no fixed upper limit on this score (just as there is no limit to potential keyword searches), the objective is to outperform your competitors.',
    
    SERPRScore:
    'This relates to the Power Players Domain Score. It shows how you rank compared to the top sites in your industry. A score of 100 goes to the strongest player, all other scores are relative to that. \n\nNote: By assigning a top-level industry here, not all domains will be companies you identify as direct competitors.',
}