import { 
    SUBSCRIPTION_LIST_REQUEST,
    SUBSCRIPTION_LIST_SUCCESS,
    SUBSCRIPTION_LIST_FAIL,

    SUBSCRIPTION_DETAILS_REQUEST,
    SUBSCRIPTION_DETAILS_SUCCESS,
    SUBSCRIPTION_DETAILS_FAIL,
    SUBSCRIPTION_DETAILS_RESET,

    SUBSCRIPTION_DETAILS_BY_ID_REQUEST,
    SUBSCRIPTION_DETAILS_BY_ID_SUCCESS,
    SUBSCRIPTION_DETAILS_BY_ID_FAIL,
    SUBSCRIPTION_DETAILS_BY_ID_RESET,

    SUBSCRIPTION_CREATE_REQUEST,
    SUBSCRIPTION_CREATE_SUCCESS,
    SUBSCRIPTION_CREATE_FAIL,
    SUBSCRIPTION_CREATE_RESET,

    SUBSCRIPTION_UPDATE_STATUS_REQUEST,
    SUBSCRIPTION_UPDATE_STATUS_SUCCESS,
    SUBSCRIPTION_UPDATE_STATUS_FAIL,
    SUBSCRIPTION_UPDATE_STATUS_RESET,

    SUBSCRIPTION_DELETE_REQUEST,
    SUBSCRIPTION_DELETE_SUCCESS,
    SUBSCRIPTION_DELETE_FAIL,
    SUBSCRIPTION_DELETE_RESET,

 } from '../constants/subscriptionConstants'

 export const subscriptionListReducer =  (state = {subscriptions:[]}, action) => {
    switch(action.type) {
        case SUBSCRIPTION_LIST_REQUEST:
            return {loading: true, subscriptions:[]}

        case SUBSCRIPTION_LIST_SUCCESS:
            return {loading: false, subscriptions: action.payload}

        case SUBSCRIPTION_LIST_FAIL:
            return {loading: false, error: action.payload }

        default:
            return state
    }
}

 export const subscriptionCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case SUBSCRIPTION_CREATE_REQUEST: 
            return {loading:true}

        case SUBSCRIPTION_CREATE_SUCCESS: 
            return {loading:false, success: true, subscription: action.payload}
        
        case SUBSCRIPTION_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case SUBSCRIPTION_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const subscriptionUpdateStatusReducer = ( state = { subscription: {} }, action ) => {
    switch(action.type){
        case SUBSCRIPTION_UPDATE_STATUS_REQUEST: 
            return {loading:true}

        case SUBSCRIPTION_UPDATE_STATUS_SUCCESS: 
            return {loading:false, success: true, subscription: action.payload}
        
        case SUBSCRIPTION_UPDATE_STATUS_FAIL: 
            return {loading:false, error: action.payload}

        case SUBSCRIPTION_UPDATE_STATUS_RESET: 
            return { subscription: {} }

        default: 
            return state
    }
}


export const subscriptionDetailsReducer = ( state = {subscription :[]}, action ) => {
    switch(action.type){
        case SUBSCRIPTION_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case SUBSCRIPTION_DETAILS_SUCCESS: 
            return {loading:false, success:true,  subscription: action.payload}
        
        case SUBSCRIPTION_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case SUBSCRIPTION_DETAILS_RESET: 
            return {}

        default: 
            return state
    }
}

export const subscriptionDetailsByIdReducer = ( state = {subscription :{}}, action ) => {
    switch(action.type){
        case SUBSCRIPTION_DETAILS_BY_ID_REQUEST: 
            return {loading:true, ...state}

        case SUBSCRIPTION_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  subscription: action.payload}
        
        case SUBSCRIPTION_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case SUBSCRIPTION_DETAILS_BY_ID_RESET: 
            return {}

        default: 
            return state
    }
}


export const subscriptionDeleteReducer = ( state = {subscription :{}}, action ) => {
    switch(action.type){
        case SUBSCRIPTION_DELETE_REQUEST: 
            return {loading:true, ...state}

        case SUBSCRIPTION_DELETE_SUCCESS: 
            return {loading:false, success:true,  subscription: action.payload}
        
        case SUBSCRIPTION_DELETE_FAIL: 
            return {loading:false, error: action.payload}

        case SUBSCRIPTION_DELETE_RESET: 
            return {loading:false, success:false, subscription: {}}

        default: 
            return state
    }
}