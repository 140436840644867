import React from "react"
import { 
    Container, 
    Text,  
    Search, 
    Button,
    DropDownContainer,
    DropDownHeader,
    DropDownList,
    ListItem,
    DropdownIcon,
    SearchContainer,
    SearchControl,
    SearchIcon
} from "./styles/subheader"

export default function SubHeader({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

SubHeader.Text = function SubHeaderText({children, ...restProps}){
    return <Text {...restProps}>{children}</Text>   
}


SubHeader.Search = function SubHeaderSearch({children, ...restProps}){
    return <Search {...restProps}>{children}</Search>   
}

SubHeader.Button = function SubHeaderButton({children, ...restProps}){
    return <Button {...restProps}>{children}</Button>   
}

// Dropdown
SubHeader.DropDownContainer = function SubHeaderDropDownContainer({ children, ...restProps }) {
    return <DropDownContainer {...restProps}>{children}</DropDownContainer>
}

SubHeader.DropDownHeader = function SubHeaderDropDownHeader({ children, ...restProps }) {
    return <DropDownHeader {...restProps}>{children}</DropDownHeader>
}

SubHeader.DropDownList = function SubHeaderDropDownList({ children, ...restProps }) {
    return <DropDownList {...restProps}>{children}</DropDownList>
}

SubHeader.ListItem = function SubHeaderListItem({ children, ...restProps }) {
    return <ListItem {...restProps}>{children}</ListItem>
}

SubHeader.DropdownIcon = function SubHeaderDropdownIcon({ children, ...restProps }) {
    return <DropdownIcon {...restProps}>{children}</DropdownIcon>
}

// Search
SubHeader.SearchContainer = function SubHeaderSearchContainer({ children, ...restProps }) {
    return <SearchContainer {...restProps}>{children}</SearchContainer>
}

SubHeader.SearchControl = function SubHeaderSearchControl({ children, ...restProps }) {
    return <SearchControl {...restProps}>{children}</SearchControl>
}

SubHeader.SearchIcon = function SubHeaderSearchIcon({ children, ...restProps }) {
    return <SearchIcon {...restProps}>{children}</SearchIcon>
}