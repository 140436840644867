import React from "react"
import { 
    Container, 
    LargeText, 
    RedLine, 
    SmallText, 
    Img, 
    RightContainer, 
    Label,
    TextField, 
    Button, 
    Icon
} from "./styles/home"

export default function Home({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Home.LargeText = function HomeLargeText({children, ...restProps}){
    return <LargeText {...restProps}>{children}</LargeText>   
}

Home.SmallText = function HomeSmallText({children, ...restProps}){
    return <SmallText {...restProps}>{children}</SmallText>   
}

Home.RedLine = function HomeRedLine({children, ...restProps}){
    return <RedLine {...restProps}>{children}</RedLine>   
}

Home.Img = function HomeImg({to, ...restProps}){
    return  <Img {...restProps} />    
}

Home.RightContainer = function HomeRightContainer({children, ...restProps}){
    return <RightContainer {...restProps}>{children}</RightContainer>   
}

Home.Label = function HomeLabel({children, ...restProps}){
    return <Label {...restProps}>{children}</Label>   
}

Home.TextField = function HomeTextField({children, ...restProps}){
    return <TextField {...restProps}>{children}</TextField>   
}

Home.Button = function HomeButton({children, ...restProps}){
    return <Button {...restProps}>{children}</Button>   
}

Home.Icon = function HomeIcon({children, ...restProps}){
    return <Icon {...restProps}>{children}</Icon>   
}