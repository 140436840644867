import React, { useState,useEffect } from "react";
import { Text, Content } from "../../../../styled-components/visualization";
import { CounterContainer } from "../../utilComponents/Counter";
import {
  nFormatter,

} from "./competitorUtils";
import { dateFormatter } from "../serpScoreboard/sortingUtils";


export const CompetitorTrafficDrivers = (props) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  
  const nextPage = () => {
    if (props.pageNoTrafficDrivers < numberOfPages) {
      props.setPageNoTrafficDrivers(props.pageNoTrafficDrivers + 1)
      }
  }

  const previousPage = () => {
      if (props.pageNoTrafficDrivers > 1) {
        props.setPageNoTrafficDrivers(props.pageNoTrafficDrivers -1)
      } 
  }

  const paginationLine = () =>{
    return (
        <Content.FlexRow
        style ={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '3%'
        }}
     >
     <Content.PaginationIcon
     onClick={()=>previousPage()}
     src={require("../../../../images/visualization/left_arrow.svg").default}/>
     {props.pageNoTrafficDrivers !== numberOfPages ?
      <Text.Regular13Grey>{(props.pageNoTrafficDrivers-1)*100+1} - {props.pageNoTrafficDrivers*100} / {props.total_rows}</Text.Regular13Grey> :
      <Text.Regular13Grey>{(props.pageNoTrafficDrivers-1)*100+1} - {props.total_rows} / {props.total_rows}</Text.Regular13Grey>
     }
     
     <Content.PaginationIcon
    onClick={() =>nextPage()}
     src={require("../../../../images/visualization/right_arrow.svg").default}/>
     </Content.FlexRow>
    )
  }

  useEffect(()=>{
    if(props.total_rows){
        if(numberOfPages == 0){
            setNumberOfPages(Math.ceil(props.total_rows/100))
          }
    }

  },[props.total_rows,props.rows])

  const handleCompanyWebsite = (url) =>{
    var hostname = url;
    //case http://url.com
     // Find and remove protocol
     if (url.indexOf("://") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    const hostnameArray = hostname.split('.');
    if (hostnameArray.length === 1) return hostname;
    let i = 0;
    for (i = hostnameArray.length - 1; i > -1; i--) {
        if (hostnameArray[i].length > 2 && i !== hostnameArray.length - 1) {
            break;
        }
    }
    let tldArray = [];
    for (let j = i; j < hostnameArray.length; j++) {
        tldArray.push(hostnameArray[j]);
    }
    // return tldArray.join('.');
    var topLevelDomain = tldArray.join('.');
    var lastChar = topLevelDomain.slice(-1)
    if(lastChar === "/"){
      topLevelDomain= topLevelDomain.substr(0, topLevelDomain.length - 1); //remove last char
    }
   
    return topLevelDomain;

  
  } 

  const table = () => {
    return(
        <> 
        <Content.FlexRow>
            <Text.Bold15Black>Traffic Drivers</Text.Bold15Black>
        </Content.FlexRow>
        <Content.FlexRow
          style={{
              justifyContent:'space-between',
              width: '99%',
              padding: '1%'
          }} 
        > 
            <Content.FlexColumn  style={{ width: '30px'  }}> </Content.FlexColumn>
            <Text.Regular13Grey style={{width:'10%'}}>Competitior</Text.Regular13Grey>
            <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Topic</Text.Regular13Grey>
            <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Subtopic</Text.Regular13Grey>
            <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Keyword</Text.Regular13Grey>
            <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>SERP Feature</Text.Regular13Grey>
            <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Landing Page</Text.Regular13Grey>
            <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Best Position</Text.Regular13Grey>
            <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Last Seen</Text.Regular13Grey>
            <Text.Regular13Grey style={{width:'10%', textAlign: 'center'}}>Search volume</Text.Regular13Grey>
        </Content.FlexRow>
        <Content.DropDownContainer
          style={{
              marginTop:'2%',
              height: '400px'
          }}
        >

        {props.rows.map((result,i)=>(
              <Content.HoverRow>
              <Content.FlexColumn style={{ width: '30px' }}>
              
                  {/* <CounterContainer number={(i+1)} /> */}
                  <CounterContainer number={(props.pageNoTrafficDrivers-1)*100+i+1} />
                  </Content.FlexColumn>
              <Text.Regular14Black style={{width: '10%',overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{handleCompanyWebsite(result.competitor_website)}</Text.Regular14Black>
              <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{result.keyword_parent}</Text.Regular14Black>
              <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{result.keyword_child}</Text.Regular14Black>
              <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{result.keyword}</Text.Regular14Black>
              <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{result.serp_feature}</Text.Regular14Black>
              <Text.Light14BlackLink  href={result.url} title={result.url} target="_blank" style={{width: '10%', overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}> {result.url} </Text.Light14BlackLink>
              <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{result.position}</Text.Regular14Black>
              <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{dateFormatter(result.timestamp,true)}</Text.Regular14Black>
              <Text.Regular14Black style={{width:'10%', textAlign: 'center'}}>{result.search_volume}</Text.Regular14Black>
              </Content.HoverRow>
        ))}
        
        </Content.DropDownContainer>
        {paginationLine()}
        </>
    )
}
  

  return (
    <Content.FlexColumn style={{
      width: '100%',
      display:'grid',
      overflow:'auto'
      }}
      >
      {table()}
    
     
    </Content.FlexColumn>
  );
};
