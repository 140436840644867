import React from "react"
import { Container, Section1, Section2, Section3, Opacity, SectionFull, Section,
    SectionWebsiteComponent, SectionWebsiteContainer, SectionWebsiteComponentBackground
} from "./styles/grid"

export default function Grid({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Grid.Section = function GridSection({children, ...restProps}){
    return <Section {...restProps}>{children}</Section>   
}



Grid.Section1 = function GridSection1({children, ...restProps}){
    return <Section1 {...restProps}>{children}</Section1>   
}

Grid.Section2 = function GridSection2({children, ...restProps}){
    return <Section2 {...restProps}>{children}</Section2>   
}

Grid.Section3 = function GridSection3({children, ...restProps}){
    return <Section3 {...restProps}>{children}</Section3>   
}

Grid.Opacity = function GridOpacity({children, ...restProps}){
    return <Opacity {...restProps}>{children}</Opacity>   
}

Grid.SectionFull = function GridSectionFull({children, ...restProps}){
    return <SectionFull {...restProps}>{children}</SectionFull>   
}

Grid.SectionWebsiteComponent = function GridSectionWebsiteComponent({children, ...restProps}){
    return <SectionWebsiteComponent {...restProps}>{children}</SectionWebsiteComponent>   
}

Grid.SectionWebsiteComponentBackground = function GridSectionWebsiteComponentBackground({children, ...restProps}){
    return <SectionWebsiteComponentBackground {...restProps}>{children}</SectionWebsiteComponentBackground>   
}

Grid.SectionWebsiteContainer = function GridSectionWebsiteContainer({children, ...restProps}){
    return <SectionWebsiteContainer {...restProps}>{children}</SectionWebsiteContainer>   
}

