import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text } from "../../styled-components/dashboard";

import { useHistory } from "react-router-dom"
import {  listCompanyDetailsById } from '../../actions/companyActions'


export function BulkAddUsersContainer({ accessToken, name, email }) {
  const dispatch = useDispatch()
  const history = useHistory()

  

  //Company details
  const [companyId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('website')+12, 100))
  const [companyName, setCompanyName] = useState('')

  const companyDetailsById = useSelector(state => state.companyDetailsById)
    const {//loading: loadingDetailsByIdCompany, 
        //error: errorDetailsByIdCompany, 
        success: successDetailsByIdCompany, 
        company
    } = companyDetailsById

  useEffect(() => {
    if (accessToken && email) {
      if(successDetailsByIdCompany){
        setCompanyName(company.name)
      } else{
          
          dispatch(listCompanyDetailsById(accessToken,  companyId))
      }
    }
  }, [ email, accessToken, successDetailsByIdCompany, dispatch, company, companyId ]);

  return(
      <Content>
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
            <Text.Bold40Black>
                Add a new user to <span> </span>
              </Text.Bold40Black>
              <Text.Bold42Pink
                
              >
                {companyName}
              </Text.Bold42Pink>
              <Text.Normal14Black
              style={{marginTop: '26px'}}>
                Luminr creates a new account for each user you add.
              </Text.Normal14Black>
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>

        </Content.SectionBottom>
      </Content>
  )
}
