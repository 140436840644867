import styled from 'styled-components/macro'
import * as pallete from '../../../constants/theme/colours'

export const Container = styled.div`
    display: ${(props) => (props.show ? 'block' : 'none')};
    position: fixed;
    background-color: #ffffffcc;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;

    transition: all 0.3s;

    & > div {
        width: 536px;
        position: fixed;
        z-index: 999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        box-shadow: 0px 14px 14px ${pallete.SHADOW_GRAY};
    }
`

export const Group = styled.div`
    padding: 24px;
    text-align: center;
`

export const Header = styled.div`
    font-family: tenon, sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: ${pallete.WHITE};
    text-align: center;
    letter-spacing: 0px;
    padding: 24px;
    background-color: ${pallete.PINK};
    text-transform: capitalize;
`

export const Text = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    padding-bottom: 24px;
    letter-spacing: 0px;
`

export const Footer = styled.div`
    padding: 1.5rem;
    display: flex;
    justify-content: flex-end;
    button:not(:last-child) {
        margin-right: 1rem;
    }
`
