import  axios from 'axios'
import { 
    KEYWORD_LIST_REQUEST,
    KEYWORD_LIST_SUCCESS,
    KEYWORD_LIST_FAIL,

    KEYWORD_DETAILS_REQUEST,
    KEYWORD_DETAILS_SUCCESS,
    KEYWORD_DETAILS_FAIL,

    KEYWORD_DETAILS_WITHOUT_PAGINATION_REQUEST,
    KEYWORD_DETAILS_WITHOUT_PAGINATION_SUCCESS,
    KEYWORD_DETAILS_WITHOUT_PAGINATION_FAIL,
    
    KEYWORD_DETAILS_BY_ID_REQUEST,
    KEYWORD_DETAILS_BY_ID_SUCCESS,
    KEYWORD_DETAILS_BY_ID_FAIL,

    KEYWORD_CREATE_REQUEST,
    KEYWORD_CREATE_SUCCESS,
    KEYWORD_CREATE_FAIL,

    KEYWORD_UPDATE_REQUEST,
    KEYWORD_UPDATE_SUCCESS,
    KEYWORD_UPDATE_FAIL,

    KEYWORD_SORTED_REQUEST,
    KEYWORD_SORTED_SUCCESS,
    KEYWORD_SORTED_FAIL,

    KEYWORD_DELETE_REQUEST,
    KEYWORD_DELETE_SUCCESS,
    KEYWORD_DELETE_FAIL,

 } from '../constants/keywordConstants'
import { WEBSITE_DETAILS_BY_ID_RESET } from '../constants/websiteConstants'


 export const listKeywords = (accessToken) => async (dispatch) =>{
    try {
        dispatch({type:KEYWORD_LIST_REQUEST})

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.get(
            `/api/keyword/`,
            config
            )

        dispatch({
            type: KEYWORD_LIST_SUCCESS,
            payload: data
        })


    } catch(error){
        dispatch({
            type: KEYWORD_LIST_FAIL,
            payload: error.response && error.response.data.message ?
            error.response.data.message 
            : error.message,
        })
    }
 }

 export const createKeyword = (accessToken, keyword, email, websiteId) => async (dispatch) => {
    try {
       
        dispatch({
            type: KEYWORD_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.post(
            `/api/keyword/create/${email}/${websiteId}`,
            keyword,
            config
        )

        dispatch({
            type: KEYWORD_CREATE_SUCCESS,
            payload: data,
        })

        dispatch(listKeywordDetails(accessToken, websiteId))
        //reset kwd number 
        dispatch({type: WEBSITE_DETAILS_BY_ID_RESET})   



    } catch (error) {
        dispatch({
            type: KEYWORD_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateKeyword = (accessToken, keyword, websiteId) => async (dispatch) => {
    try {
       
        dispatch({
            type: KEYWORD_UPDATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/keyword/update/${keyword.id}`,
            keyword,
            config
        )

        dispatch({
            type: KEYWORD_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch(listKeywordDetails(accessToken, websiteId))

    } catch (error) {
        dispatch({
            type: KEYWORD_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listKeywordDetails = (accessToken, websiteId, param = '') => async (dispatch) => {
    try{
        dispatch({ type: KEYWORD_DETAILS_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/keyword/byWebsite/${websiteId}${param}`,
            config
            )

        dispatch({ 
            type: KEYWORD_DETAILS_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: KEYWORD_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const listKeywordDetailsWithoutPagination = (accessToken, websiteId) => async (dispatch) => {
    try{
        dispatch({ type: KEYWORD_DETAILS_WITHOUT_PAGINATION_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/keyword/byWebsiteWithoutPagination/${websiteId}`,
            config
            )

        dispatch({ 
            type: KEYWORD_DETAILS_WITHOUT_PAGINATION_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: KEYWORD_DETAILS_WITHOUT_PAGINATION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }
 
export const listKeywordDetailsById = (accessToken, id) => async (dispatch) => {
    try{
        dispatch({ type: KEYWORD_DETAILS_BY_ID_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/keyword/${id}`,
            config
            )

        dispatch({ 
            type: KEYWORD_DETAILS_BY_ID_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: KEYWORD_DETAILS_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const listKeywordSortedDetails = (accessToken, websiteId, param = '') => async (dispatch) => {
    try{
        dispatch({ type: KEYWORD_SORTED_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/keyword/sortedKeywords/${websiteId}`,
            config
            )

        dispatch({ 
            type: KEYWORD_SORTED_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: KEYWORD_SORTED_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }


 export const deleteKeyword = (accessToken, id,websiteId) => async (dispatch) => {
    try{
        dispatch({ type: KEYWORD_DELETE_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/keyword/delete/${id}/${websiteId}`,
            config
            )

        dispatch({ 
            type: KEYWORD_DELETE_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: KEYWORD_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }