import React,{useState,useEffect} from "react";
import {  Content } from "../../../../styled-components/visualization";
import { TitleContainer } from "../../utilComponents/Title";
import { CompetitorSwotTable } from "./CompetitorSwotTable";
import LoaderImage from "../../../../components/LoaderImage";
import ErrorMessage from "../../../../components/ErrorMessage";
import { assistantText, insightsText } from "../../utilComponents/InsightsText";
import ResultsMissing from "../../../../components/ResultsMissing";
import { CompetitorTrafficDrivers } from "./CompetitorTrafficDrivers";


export function CompetitorSwotContainer({ 
  loadingCompetitorSwotHeatmap,successCompetitorSwotHeatmap,errorCompetitorSwotHeatmap,
  competitorSwotHeatmapResults,extendParent,setExtendParent,extendChild,setExtendChild,
  setPageNoTrafficDrivers,selectedTopic,setSelectedTopic,selectedCompetitor,setSelectedCompetitor,trafficDrivers}) {

  const [uniqueColumns, setUniqueColumn] = useState([]);
  const [uniqueRows, setUniqueRows] = useState([]) 
  const [k1, setKg1] = useState([]);
  const [k2, setKg2] = useState([]);
  const [k3, setKg3] = useState([]);

  useEffect(() => { 
    const column =[]; const kg1=[]; const kg2=[];  const kg3=[];
    const sortedColumns = [];
    if(successCompetitorSwotHeatmap && competitorSwotHeatmapResults.length != 0 ){
        if(competitorSwotHeatmapResults.detail == undefined){
          competitorSwotHeatmapResults.map((result, i) => {
                //store column in a new array
                if(result.dim2 && (column.indexOf('Keyword Child') === -1) )
                  column.push('Keyword Child')

                if(result.dim3 && (column.indexOf('Keyword Portofolio') === -1))
                  column.push('Keyword Portofolio')
                
                if(sortedColumns.indexOf(result.domain) === -1) {
                  sortedColumns.push(result.domain)
                }  
                if( result.dim1 && kg1.indexOf(result.dim1) === -1) {
                  kg1.push(result.dim1) //unique parent array
                } 
                if( result.dim2) {
                  kg2.push([result.dim1, result.dim2]) //not unique group parent-child array
                } 
                if( result.dim3) {
                  kg3.push([result.dim1,result.dim2,result.dim3]) // not unique group parenct-child-nephew array
                }

            });
            const array3 = column.concat(sortedColumns.sort()); //keep the same columns order
            setUniqueColumn(array3)
            setKg1(kg1)

            //set unique groups
            let set  = new Set(kg2.map(JSON.stringify));
            let arr2 = Array.from(set).map(JSON.parse);
            setKg2(arr2)

            let set3  = new Set(kg3.map(JSON.stringify));
            let arr3 = Array.from(set3).map(JSON.parse);
            setKg3(arr3)
        }
    }
  },
  [competitorSwotHeatmapResults]) 

  useEffect( ()=>{
    if(successCompetitorSwotHeatmap && uniqueColumns.length !=0  && competitorSwotHeatmapResults.length != 0){
      const overRow = []; //create array with values for every row
      competitorSwotHeatmapResults.map((resultR, i) => {
        const row =[];
        let startingPoint=0; // skip the extended column from getting '-' in rows
        resultR.dim1 && row.push(resultR.dim1);
        if(resultR.dim2){ //extended parent
          row.push(resultR.dim2);
          startingPoint = 1;
        }
        if(resultR.dim3){//extended child
          resultR.dim3 && row.push(resultR.dim3);
          startingPoint = 2;

        }
      
        uniqueColumns.map((resultC, i) => {
          //store values in a new array
          if(i >= startingPoint){
            if(resultR.domain == resultC){
              row.push(resultR.avg_total_search_ctr)
            }else{
              row.push('-')
            }
          }
        });
        overRow.push(row)        
      });
      
      let allRows=[]; // every column has a value on a different row .. we want a single row for with every column
      if(k3.length != 0){//if extended child
        k3.map((res,k) =>{
          const finalLine = res; // here res is an array
          const valuesForRes = overRow.filter(line=>line[0]===res[0]&& line[1]===res[1] && line[2]==res[2])
          valuesForRes.map((val,p)=>{
            val.forEach((element, index) => {
             if ( index >1 && element !=="-") finalLine[index]=element
             else if (!finalLine[index])  {finalLine[index]="-"}
            });
          })
          allRows.push(finalLine)
        })
      }else if(k2.length != 0){//if extended parent
        k2.map((res,k) =>{
          const finalLine = res; // here res is an array
          const valuesForRes = overRow.filter(line=>line[0]===res[0]&& line[1]===res[1])
          valuesForRes.map((val,p)=>{
            val.forEach((element, index) => {
             if ( index >0 && element !=="-") finalLine[index]=element
             else if (!finalLine[index])  {finalLine[index]="-"}
            });
          })
          allRows.push(finalLine)
        })
      }else{
        k1.map((res,k) =>{
          const finalLine = [res]; //here res is a string(not an array)
          const valuesForRes = overRow.filter(line=>line[0]===res)
          valuesForRes.map((val,p)=>{
            val.forEach((element, index) => {
              if ( index>0 && element !=="-") finalLine[index]=element
              else if (!finalLine[index])  {finalLine[index]="-"}
            });
          })
          allRows.push(finalLine)
        })
      }
      setUniqueRows(allRows.sort())
    }

  },[uniqueColumns,k1])

  return (
    <Content.FlexColumn
      style={{
        justifyContent: "space-between",
        padding: "1%",
        background: "white",
      }}
    >

      <TitleContainer 
      title="Competitor Positioning Analysis" 
      subtitle='Uncover your competitive edge through a deeper understanding of your competitive landscape.'
      backdropTop = '85vw' 
      pages={insightsText.CompetitorSWOTAnalysis[0]}
      p11={insightsText.CompetitorSWOTAnalysis[1]}
      p21={insightsText.CompetitorSWOTAnalysis[2]}
      p31={insightsText.CompetitorSWOTAnalysis[3]}
      p41={insightsText.CompetitorSWOTAnalysis[4]}
      assistantMessage={assistantText.CompetitorSWOTAnalysis}

      />
      <Content.FlexColumn className={"justifyContentCenter"} >
      {
        loadingCompetitorSwotHeatmap || !successCompetitorSwotHeatmap 
              ? 
              <LoaderImage height={'450px'}/> : 
              errorCompetitorSwotHeatmap 
              ? 
              <ErrorMessage /> :
              competitorSwotHeatmapResults.length != 0 ? 
                competitorSwotHeatmapResults.detail == undefined ?
                  <CompetitorSwotTable results={competitorSwotHeatmapResults} columns={uniqueColumns} rows={uniqueRows}
                    extendParent={extendParent} setExtendParent={setExtendParent}
                    extendChild={extendChild} setExtendChild={setExtendChild} 
                    selectedTopic={selectedTopic} setSelectedTopic={setSelectedTopic}
                    setSelectedCompetitor={setSelectedCompetitor}
                    selectedCompetitor={selectedCompetitor}
                    /> 
                  : <ResultsMissing details = {competitorSwotHeatmapResults.detail}/>
              : 
              <ResultsMissing details = {competitorSwotHeatmapResults.detail}/>
      }
      </Content.FlexColumn>

      {/* show Traffic Drivers Table only if  there are results for Competitor Positioning Analysis*/}
      {
        successCompetitorSwotHeatmap 
        ? 
           competitorSwotHeatmapResults.length != 0  && competitorSwotHeatmapResults.detail == undefined && 
              <Content.FlexColumn style={{height:'auto',paddingTop:'30px'}}>
                {
                  trafficDrivers.loading || !trafficDrivers.success
                        ? 
                        <LoaderImage height={'550px'}/> : 
                        trafficDrivers.error 
                        ? 
                        <ErrorMessage /> :
                        trafficDrivers.results.length != 0 ? 
                        trafficDrivers.results.detail == undefined ?
                            <CompetitorTrafficDrivers style={{height:'550px'}} rows={trafficDrivers.results}  
                            total_rows={trafficDrivers.total_rows} 
                            pageNoTrafficDrivers={trafficDrivers.pageNoTrafficDrivers}
                            setPageNoTrafficDrivers ={setPageNoTrafficDrivers}
                              /> 
                            : <ResultsMissing details = {trafficDrivers.results.detail}/>
                        : 
                        <ResultsMissing details = {trafficDrivers.results.detail}/>
                }
                </Content.FlexColumn>
        : <></>
        
      }

      
      

    </Content.FlexColumn>
  );
}
