import {
    LOGS_NUMBER_REQUEST,
    LOGS_NUMBER_SUCCESS,
    LOGS_NUMBER_FAIL,

    ERRORS_NUMBER_REQUEST,
    ERRORS_NUMBER_SUCCESS,
    ERRORS_NUMBER_FAIL,

    WARNINGS_NUMBER_REQUEST,
    WARNINGS_NUMBER_SUCCESS,
    WARNINGS_NUMBER_FAIL,

    INFO_SUCCESS_NUMBER_REQUEST,
    INFO_SUCCESS_NUMBER_SUCCESS,
    INFO_SUCCESS_NUMBER_FAIL,
} from '../../constants/loggingsystem/statisticsConstants'

export const numberLogsReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGS_NUMBER_REQUEST:
            return { loading: true }

        case LOGS_NUMBER_SUCCESS:
            return {
                loading: false,
                success: true,
                nrLogsThisMonth: action.payload.nrLogsThisMonth,
                percentLog: action.payload.percentLog,
                linkWord: action.payload.linkWord,
            }

        case LOGS_NUMBER_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const numberErrorsReducer = (state = {}, action) => {
    switch (action.type) {
        case ERRORS_NUMBER_REQUEST:
            return { loading: true }

        case ERRORS_NUMBER_SUCCESS:
            return {
                loading: false,
                success: true,
                nrErrorThisMonth: action.payload.nrErrorThisMonth,
                percentError: action.payload.percentError,
                linkWord: action.payload.linkWord,
            }

        case ERRORS_NUMBER_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const numberWarningsReducer = (state = {}, action) => {
    switch (action.type) {
        case WARNINGS_NUMBER_REQUEST:
            return { loading: true }

        case WARNINGS_NUMBER_SUCCESS:
            return {
                loading: false,
                success: true,
                nrWarningThisMonth: action.payload.nrWarningThisMonth,
                percentWarning: action.payload.percentWarning,
                linkWord: action.payload.linkWord,
            }

        case WARNINGS_NUMBER_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const numberInfoSuccessReducer = (state = {}, action) => {
    switch (action.type) {
        case INFO_SUCCESS_NUMBER_REQUEST:
            return { loading: true }

        case INFO_SUCCESS_NUMBER_SUCCESS:
            return {
                loading: false,
                success: true,
                nrInfoThisMonth: action.payload.nrInfoThisMonth,
                nrSuccessThisMonth: action.payload.nrSuccessThisMonth,
            }

        case INFO_SUCCESS_NUMBER_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}
