import React from "react";
import { Text, Content, MenuHeader } from "../../../styled-components/visualization";
import { FlexColumn, FlexRow } from "../../../styled-components/visualization/content/styles/content";

export function IntroContainer() {

  return (
    <>
        <FlexRow
              style={{
                justifyContent: 'space-between',
              }}
          >
            <FlexColumn
              style={{
                width: '45%'
              }}
            >
              <Text.Regular18Grey>
                Intro
              </Text.Regular18Grey>
              <Text.Medium40Black>
                How Luminr Works
              </Text.Medium40Black>
              <Text.Light20Black
                style={{
                  marginTop: '5%'
                }}
              >
              During the onboarding process, your keywords and search queries are fed into Luminr along with your audience profiles, markets and competitors. Luminr then mimics user searches and analyses results to serve you and your team 100% actionable insights.  </Text.Light20Black>
            </FlexColumn>
            <FlexColumn
            style={{
                width: '45%'
              }}
            >
            
            </FlexColumn>
          </FlexRow>
          <FlexRow
              style={{
                justifyContent: 'space-between'
              }}
          >
            <FlexColumn
              style={{
                  width: '45%'
                }}
              >
              <Text.Light20Black
                  style={{
                    marginTop: '5%'
                  }}
                >
                As we are still in the early days of the platform, you and your brand have a unique chance to be part of building the revolutionary SERP intelligence tool. We welcome all ideas, feedback and suggestions. Please contact <span style={{fontWeight: 'bold'}}>James</span>  (Head of Innovation) or <span style={{fontWeight: 'bold'}}>Emily</span> (Head of Product) to get in touch.
                </Text.Light20Black>
              </FlexColumn>
              <FlexColumn
              style={{
                  width: '45%',
                  flexDirection: 'row',
                  alignItems: 'flex-end'
                }}
              >
              <Content.FlexColumn>
              <FlexRow
                style={{
                  alignItems: 'center'
                }}
              >
              <MenuHeader.ItemImg  

                    src={require("../../../images/visualization/mail.png").default}/>

              <Text.Light20BlackLink href="mailto:james.wolman@luminr.ai"
              style={{
            
                }}>
                  james.wolman@luminr.ai
              </Text.Light20BlackLink>
              
              </FlexRow>
              <FlexRow
                style={{
                  alignItems: 'center'
                }}
                >
                <MenuHeader.ItemImg  
                style={{
                    marginTop: '10%'
                  }}
                      src={require("../../../images/visualization/mail.png").default}/>
                <Text.Light20BlackLink href="mailto:emily.white@luminr.ai"
                style={{
                    marginTop: '10%'
                  }}>
                    emily.white@luminr.ai
                </Text.Light20BlackLink>
              </FlexRow>
              </Content.FlexColumn>
              </FlexColumn>
          </FlexRow>
          </>
  )
}
