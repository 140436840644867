import React from 'react'
import { Spinner } from 'react-bootstrap'
import { General } from '../styled-components/dashboard'

function Loader() {
    return (
        <General.Loader>
         
        <Spinner
            animation="border"
            role="status"
            style={{
                height:'36px',
                width: '36px',
                margin: 'auto',
                display: 'block',
                color: 'white',
               // marginTop: '10px'
            }}
        >
            <span className='sr-only'>Loading...</span>
        </Spinner>
        </General.Loader>
    )
}

export default Loader
