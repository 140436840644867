import React from "react";
import {  Content } from "../../../styled-components/visualization";
import { KeywordRankingsOpportunitiesLineColumnContainer } from "../charts/KeywordRankingsOpportunitiesLineColumn";
import { TitleContainer } from "../utilComponents/Title";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";

export function KeywordRankingsOpportunitiesContainer({bigKeywordRankingOppResults}) {

  return (  
    <Content.FlexColumn
    style={{
            justifyContent: 'space-between',
            padding: '1%',
            background: 'white'
        }}
    >
        <TitleContainer 
            title='High-Volume CTR Booster' 
            subtitle = 'Discover hidden keyword opportunities within your CTR strengths and weaknesses to maximise your website traffic.'
            backdropTop = '50vw'    
            pages={insightsText.KeywordRankingsOpportunities[0]}
            p11={insightsText.KeywordRankingsOpportunities[1]}
            p21={insightsText.KeywordRankingsOpportunities[2]}
            p31={insightsText.KeywordRankingsOpportunities[3]}
            p41={insightsText.KeywordRankingsOpportunities[4]}
            assistantMessage={assistantText.KeywordRankingsOpportunities}

            />
        
        {bigKeywordRankingOppResults.length != 0? 
            bigKeywordRankingOppResults.detail !== undefined ? 
                <ResultsMissing details = {bigKeywordRankingOppResults.detail}/>
                 : 
                 <Content.FlexColumn style={{
                    marginTop: '1%'
                    }}>
                     <KeywordRankingsOpportunitiesLineColumnContainer bigKeywordRankingOppResults={bigKeywordRankingOppResults}/>
                 </Content.FlexColumn>
            :
            <ResultsMissing details = {bigKeywordRankingOppResults.detail}/>
        }
    
       
    </Content.FlexColumn>
  )
}
