export const WEBSITE_LIST_REQUEST   = 'WEBSITE_LIST_REQUEST'
export const WEBSITE_LIST_SUCCESS   = 'WEBSITE_LIST_SUCCESS'
export const WEBSITE_LIST_FAIL      = 'WEBSITE_LIST_FAIL'
export const WEBSITE_LIST_RESET     = 'WEBSITE_LIST_RESET'

export const WEBSITE_DETAILS_REQUEST= 'WEBSITE_DETAILS_REQUEST'
export const WEBSITE_DETAILS_SUCCESS= 'WEBSITE_DETAILS_SUCCESS'
export const WEBSITE_DETAILS_FAIL   = 'WEBSITE_DETAILS_FAIL'
export const WEBSITE_DETAILS_RESET   = 'WEBSITE_DETAILS_RESET'

export const WEBSITE_DETAILS_BY_ID_REQUEST   = 'WEBSITE_DETAILS_BY_ID_REQUEST'
export const WEBSITE_DETAILS_BY_ID_SUCCESS   = 'WEBSITE_DETAILS_BY_ID_SUCCESS'
export const WEBSITE_DETAILS_BY_ID_FAIL      = 'WEBSITE_DETAILS_BY_ID_FAIL'
export const WEBSITE_DETAILS_BY_ID_RESET     = 'WEBSITE_DETAILS_BY_ID_RESET'

export const WEBSITE_DETAILS_BY_EMAIL_REQUEST   = 'WEBSITE_DETAILS_BY_EMAIL_REQUEST'
export const WEBSITE_DETAILS_BY_EMAIL_SUCCESS   = 'WEBSITE_DETAILS_BY_EMAIL_SUCCESS'
export const WEBSITE_DETAILS_BY_EMAIL_FAIL      = 'WEBSITE_DETAILS_BY_EMAIL_FAIL'
export const WEBSITE_DETAILS_BY_EMAIL_RESET     = 'WEBSITE_DETAILS_BY_EMAIL_RESET'

export const WEBSITE_CREATE_REQUEST = 'WEBSITE_CREATE_REQUEST'
export const WEBSITE_CREATE_SUCCESS = 'WEBSITE_CREATE_SUCCESS'
export const WEBSITE_CREATE_FAIL    = 'WEBSITE_CREATE_FAIL'
export const WEBSITE_CREATE_RESET   = 'WEBSITE_CREATE_RESET'

export const WEBSITE_UPDATE_REQUEST = 'WEBSITE_UPDATE_REQUEST'
export const WEBSITE_UPDATE_SUCCESS = 'WEBSITE_UPDATE_SUCCESS'
export const WEBSITE_UPDATE_FAIL    = 'WEBSITE_UPDATE_FAIL'
export const WEBSITE_UPDATE_RESET   = 'WEBSITE_UPDATE_RESET'

export const WEBSITE_DELETE_REQUEST = 'WEBSITE_DELETE_REQUEST'
export const WEBSITE_DELETE_SUCCESS = 'WEBSITE_DELETE_SUCCESS'
export const WEBSITE_DELETE_FAIL    = 'WEBSITE_DELETE_FAIL'
export const WEBSITE_DELETE_RESET   = 'WEBSITE_DELETE_RESET'
