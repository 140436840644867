export const PORTFOLIO_LIST_REQUEST   = 'PORTFOLIO_LIST_REQUEST'
export const PORTFOLIO_LIST_SUCCESS   = 'PORTFOLIO_LIST_SUCCESS'
export const PORTFOLIO_LIST_FAIL      = 'PORTFOLIO_LIST_FAIL'
export const PORTFOLIO_LIST_RESET     = 'PORTFOLIO_LIST_RESET'

export const PORTFOLIO_DETAILS_REQUEST= 'PORTFOLIO_DETAILS_REQUEST'
export const PORTFOLIO_DETAILS_SUCCESS= 'PORTFOLIO_DETAILS_SUCCESS'
export const PORTFOLIO_DETAILS_FAIL   = 'PORTFOLIO_DETAILS_FAIL'
export const PORTFOLIO_DETAILS_RESET   = 'PORTFOLIO_DETAILS_RESET'

export const PORTFOLIO_DETAILS_BY_ID_REQUEST   = 'PORTFOLIO_COMPETITOR_BY_ID_REQUEST'
export const PORTFOLIO_DETAILS_BY_ID_SUCCESS   = 'PORTFOLIO_COMPETITOR_BY_ID_SUCCESS'
export const PORTFOLIO_DETAILS_BY_ID_FAIL      = 'PORTFOLIO_COMPETITOR_BY_ID_FAIL'
export const PORTFOLIO_DETAILS_BY_ID_RESET     = 'PORTFOLIO_COMPETITOR_BY_ID_RESET'

export const PORTFOLIO_CREATE_REQUEST = 'PORTFOLIO_CREATE_REQUEST'
export const PORTFOLIO_CREATE_SUCCESS = 'PORTFOLIO_CREATE_SUCCESS'
export const PORTFOLIO_CREATE_FAIL    = 'PORTFOLIO_CREATE_FAIL'
export const PORTFOLIO_CREATE_RESET   = 'PORTFOLIO_CREATE_RESET'

export const PORTFOLIO_UPDATE_REQUEST = 'PORTFOLIO_UPDATE_REQUEST'
export const PORTFOLIO_UPDATE_SUCCESS = 'PORTFOLIO_UPDATE_SUCCESS'
export const PORTFOLIO_UPDATE_FAIL    = 'PORTFOLIO_UPDATE_FAIL'
export const PORTFOLIO_UPDATE_RESET   = 'PORTFOLIO_UPDATE_RESET'
