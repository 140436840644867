import React from "react";

import { Content, Grid, Text, Button, Menu, Header } from "../../styled-components/dashboard";
import { useHistory } from "react-router-dom"

import Modal from 'react-modal';

export function DeleteWebsiteContainer({ accessToken, name, email }) {
  const history = useHistory()
  const redirectCompaniesHandler = (e) => {
    history.push(`/websites`)
}


const [modalIsOpen, setModalIsOpen] = React.useState(false);

 
  const DeleteHandler = (e) => {
    setModalIsOpen(true)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
     // height: '40%',
      width: '28%',
      display: 'flex',
      flexDirection: 'column',
      padding: '36px'
    },
  };



  const closeModal = (e) => {

    setModalIsOpen(false)
  }

  return(
      <Content>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
      {/* //  <button onClick={closeModal}>close</button> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px'
          }}
        >
          <Menu.LogoImg  
          style = {{
            width: '82px',
            height: '20px'
            
          }}
          src={require("../../images/dashboard/serpr-logo-2.svg").default} />
          <Header.Icon 
          onClick={closeModal}
          src={require("../../images/dashboard/+.png").default} />

        </div>
          <Text.Bold40Black
            style={{fontSize: '50px'}}
          >
            Please confirm once more before you delete
          </Text.Bold40Black>
          <Button.Save
            style={{
              margin: '0',
              marginTop: '20px'
            }}
          >
            Delete
          </Button.Save>
      </Modal>
        <Content.SectionTop>
        
          <Grid>
            <Grid.Section>
            <Text.Bold40Black>
                Are you sure you want to <span> </span>
              </Text.Bold40Black>
              <Text.Bold42Pink
                
              >
                remove Braidr website <span> </span>
              </Text.Bold42Pink>
              <Text.Bold40Black>
                from Luminr?
              </Text.Bold40Black>
              <Text.Bold16Black
                style={{marginTop: '26px'}}
              >
                To remove your Website, type <span style={{color: '#C51852'}}>DELETE</span> bellow.
              </Text.Bold16Black>
              <Text.Field14Black
                placeholder="Type delete"
                type="text"
                //value={userName}
                //onChange={(e) => setUserName(e.target.value)}
              >

              </Text.Field14Black>
              <Content.ButtonsContainer>
            
            <Button.Back
            onClick={redirectCompaniesHandler}
            style={{margin: '0'}}
            >
            Back
          </Button.Back>
          <Button.Save 
          onClick={DeleteHandler}
          >
              Delete
            </Button.Save>
          </Content.ButtonsContainer>
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
      </Content>
  )
}
