import React, {useState, useEffect} from "react";

import {Text, General, Button } from "../styled-components/dashboard";


import {
  BackDrop,
  BackDropAbsolute,
} from "../styled-components/general/styles/general";

function UniqueName({uniqueNameToggle, setUniqueNameToggle}) {
  
  const [modalBackdrop, setModalBackdrop] = useState(false);


  const cancel = () => {
    setUniqueNameToggle(!uniqueNameToggle)
    setModalBackdrop(false);
    
  }

  useEffect(() => {
    if(uniqueNameToggle){
      setModalBackdrop(true)
    }
  }, [uniqueNameToggle])
  

    return (
        <>
        {modalBackdrop ? (
        <BackDropAbsolute
          style={{
            
          }}
          onClick={() =>cancel()}
        />
      ) : (
        <BackDrop style={{position: 'fixed'}}/>
      )}
        {uniqueNameToggle &&

<General.Loader>
              {/* purple part */}
            <div style={{padding: '16px 26px'}}>
              <Text.Flex
                style={{
                    alignItems: 'center'
                }}
              >
                <Text.Bold26Orange
                  style= {{color: 'white', marginBottom: '0', marginRight: '20px'}}
                >
                  Action failed
                </Text.Bold26Orange>
                <Button.CloseModal onClick={() =>cancel()}>x</Button.CloseModal>
              </Text.Flex>

            </div>


            {/* white part */}
            <div className="subtitleActionModal">
              <Text.Normal14Black
                style = {{color: 'black'}}
              > &bull; 
                Company already exist with this name
              </Text.Normal14Black> 
            </div>
        </General.Loader>

       }
        </>
    )
}

export default UniqueName
