import React from "react"
import { Container, SelectContainer, SelectHeader, SelectList, SelectListItem,
    ResetPassword, Loader,SelectListPink, SelectListItemPink, TextArea, RadioButtonItem,
    RadioButtonLeft, RadioButtonRight, RadioButtonEmptyCircle, RadioButtonFullCircle,
    InputCSV, TableRow, Icon22
} from "./styles/general"

export default function General({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}


General.SelectContainer = function GeneralSelectContainer({children, ...restProps}){
    return <SelectContainer {...restProps}>{children}</SelectContainer>   
}

General.SelectHeader = function GeneralSelectHeader({children, ...restProps}){
    return <SelectHeader {...restProps}>{children}</SelectHeader>   
}

General.SelectList = function GeneralSelectList({children, ...restProps}){
    return <SelectList {...restProps}>{children}</SelectList>   
}

General.TableRow = function GeneralTableRow({children, ...restProps}){
    return <TableRow {...restProps}>{children}</TableRow>   
}

General.SelectListPink = function GeneralSelectListPink({children, ...restProps}){
    return <SelectListPink {...restProps}>{children}</SelectListPink>   
}

General.SelectListItem = function GeneralSelectListItem({children, ...restProps}){
    return <SelectListItem {...restProps}>{children}</SelectListItem>   
}

General.SelectListItemPink = function GeneralSelectListItemPink({children, ...restProps}){
    return <SelectListItemPink {...restProps}>{children}</SelectListItemPink>   
}

General.ResetPassword = function GeneralResetPassword({children, ...restProps}){
    return <ResetPassword {...restProps}>{children}</ResetPassword>   
}

General.Loader = function GeneralLoader({children, ...restProps}){
    return <Loader {...restProps}>{children}</Loader>   
}

General.TextArea = function GeneralTextArea({children, ...restProps}){
    return <TextArea {...restProps}>{children}</TextArea>   
}

General.InputCSV = function GeneralInputCSV({children, ...restProps}){
    return <InputCSV {...restProps}>{children}</InputCSV>   
}


General.RadioButtonItem = function GeneralRadioButtonItem({children, ...restProps}){
    return <RadioButtonItem {...restProps}>{children}</RadioButtonItem>   
}

General.RadioButtonLeft = function GeneralRadioButtonLeft({children, ...restProps}){
    return <RadioButtonLeft {...restProps}>{children}</RadioButtonLeft>   
}

General.RadioButtonRight = function GeneralRadioButtonRight({children, ...restProps}){
    return <RadioButtonRight {...restProps}>{children}</RadioButtonRight>   
}

General.RadioButtonEmptyCircle = function GeneralRadioButtonEmptyCircle({children, ...restProps}){
    return <RadioButtonEmptyCircle {...restProps}>{children}</RadioButtonEmptyCircle>   
}

General.RadioButtonFullCircle = function GeneralRadioButtonFullCircle({children, ...restProps}){
    return <RadioButtonFullCircle {...restProps}>{children}</RadioButtonFullCircle>   
}

General.Icon22 = function GeneralIcon22({children, ...restProps}){
    return <Icon22 {...restProps}>{children}</Icon22>   
}
