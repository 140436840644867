import  axios from 'axios'
import { 
    COMPETITOR_LIST_REQUEST,
    COMPETITOR_LIST_SUCCESS,
    COMPETITOR_LIST_FAIL,

    COMPETITOR_DETAILS_REQUEST,
    COMPETITOR_DETAILS_SUCCESS,
    COMPETITOR_DETAILS_FAIL,
    
    COMPETITOR_DETAILS_BY_ID_REQUEST,
    COMPETITOR_DETAILS_BY_ID_SUCCESS,
    COMPETITOR_DETAILS_BY_ID_FAIL,

    COMPETITOR_CREATE_REQUEST,
    COMPETITOR_CREATE_SUCCESS,
    COMPETITOR_CREATE_FAIL,

    COMPETITOR_UPDATE_REQUEST,
    COMPETITOR_UPDATE_SUCCESS,
    COMPETITOR_UPDATE_FAIL,

    COMPETITOR_DELETE_REQUEST,
    COMPETITOR_DELETE_SUCCESS,
    COMPETITOR_DELETE_FAIL,
 } from '../constants/competitorConstants'


 export const listCompetitors = (accessToken) => async (dispatch) =>{
    try {
        dispatch({type:COMPETITOR_LIST_REQUEST})

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.get(
            `/api/competitor/`,
            config
            )

        dispatch({
            type: COMPETITOR_LIST_SUCCESS,
            payload: data
        })


    } catch(error){
        dispatch({
            type: COMPETITOR_LIST_FAIL,
            payload: error.response && error.response.data.message ?
            error.response.data.message 
            : error.message,
        })
    }
 }

 export const createCompetitor = (accessToken, competitor, email, websiteId) => async (dispatch) => {
    try {
       
        dispatch({
            type: COMPETITOR_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.post(
            `/api/competitor/create/${email}/${websiteId}`,
            competitor,
            config
        )

        dispatch({
            type: COMPETITOR_CREATE_SUCCESS,
            payload: data,
        })

        dispatch(listCompetitorDetails(accessToken, websiteId))


    } catch (error) {
        dispatch({
            type: COMPETITOR_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateCompetitor = (accessToken, competitor, websiteId) => async (dispatch) => {
    try {
       
        dispatch({
            type: COMPETITOR_UPDATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/competitor/update/${competitor.id}`,
            competitor,
            config
        )

        dispatch({
            type: COMPETITOR_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch(listCompetitorDetails(accessToken, websiteId))

    } catch (error) {
        dispatch({
            type: COMPETITOR_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listCompetitorDetails = (accessToken, websiteId) => async (dispatch) => {
    try{
        dispatch({ type: COMPETITOR_DETAILS_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/competitor/byWebsite/${websiteId}`,
            config
            )

        dispatch({ 
            type: COMPETITOR_DETAILS_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: COMPETITOR_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 
export const listCompetitorDetailsById = (accessToken, id) => async (dispatch) => {
    try{
        dispatch({ type: COMPETITOR_DETAILS_BY_ID_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/competitor/${id}`,
            config
            )

        dispatch({ 
            type: COMPETITOR_DETAILS_BY_ID_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: COMPETITOR_DETAILS_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const deleteCompetitor = (accessToken, id) => async (dispatch) => {
    try{
        dispatch({ type: COMPETITOR_DELETE_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.delete(
            `/api/competitor/delete/${id}`,
            config
            )

        dispatch({ 
            type: COMPETITOR_DELETE_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: COMPETITOR_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }