import React from "react";
import { Text, Button, Welcome} from "../../../styled-components/visualization";
import { FlexColumn, FlexRow } from "../../../styled-components/visualization/content/styles/content";
import {  useHistory } from 'react-router-dom'
import * as ROUTES from "../../../constants/routes/routes"
export function FooterContainer() {
  
  let history = useHistory()
  const goBenchmarks = () =>{
    history.push(ROUTES.VISUALIZATION_BENCHMARKS)
  }
  const goCoverage = () =>{
    history.push(ROUTES.VISUALIZATION_COVERAGE)
  }
  const goCompetition = () =>{
    history.push(ROUTES.VISUALIZATION_COMPETITION)
  }

  function handleFAQ(){
    window.open('https://luminr.ai/faq/', '_blank');
}

  return (
    <>
    <FlexColumn
        style={{
          alignItems: 'center',
          marginTop: '12%'
        }}
      >
        <Text.Regular18Grey>
          Done
        </Text.Regular18Grey>
        <Text.Medium40Black>
          You're all set!
        </Text.Medium40Black>
        <div
          style={{
            padding: '2% 4%',
            textAlign:'center'
          }}
        >
          <Text.Light16Black>
          That's everything! You are now ready to make the most of your new dashboard. 
          If you need any help or want to book a session with our experts, contact us <Text.Light14BlackLink style={{fontSize:'16px', textDecoration:'underline'}} href="mailTo:contact@luminr.ai">here</Text.Light14BlackLink>.
          </Text.Light16Black>
        </div>

        <FlexRow style={{
          width:'70%',
          justifyContent:"space-between"
      }}>
          <Button.Primary
            style={{
              marginTop: '4%'
            }}
            onClick={goBenchmarks}
          >
            Benchmarks
          </Button.Primary>

          <Button.Primary
            style={{
              marginTop: '4%'
            }}
            onClick={goCoverage}
          >
            Explore Coverage
          </Button.Primary>

          <Button.Primary
            style={{
              marginTop: '4%'
            }}
            onClick={goCompetition}
          >
            Explore Competition
          </Button.Primary>
        </FlexRow>
       
        <Text.Regular18DarkBlackBorder
        onClick={handleFAQ}
          style={{
            marginTop: '4%',
            marginBottom: '25%',
            cursor: 'pointer'
          }}
        >
          Visit Frequently Asked Question
        </Text.Regular18DarkBlackBorder>

      </FlexColumn>
      <Welcome.FooterLeftImage  src={require("../../../images/visualization/01.png").default} />
      <Welcome.FooterRightImage  src={require("../../../images/visualization/04.png").default} />
          </>
  )
}
