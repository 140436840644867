import React, { useEffect,useState } from "react";

import Chart from "react-apexcharts";

export function CompetitorLandscapeBubbleChartContainer({ companyDomain,competitorLandscapeResults}) {
  const [chartData, setCharDataState] = useState([])
  const [maxValueX, setmaxValueX] = useState(10)
  const [maxValueY, setmaxValueY] = useState(1)
  var arrType = [];

  var trafficDriversOrder = ['Organic Results','Ads (Top-of-Page)','Inline Videos','Top Stories Link','Twitter Results','Knowledge Panel (GMB)','Shopping Results','People Also Ask','Inline Images','Inline Images Suggested Searches'];

  function orderForColor(currentOrder){
    var arrayFeature = currentOrder;
    var indexMap = [];
    trafficDriversOrder.forEach((value, index) => {
      var foundSimilar = 0;
      arrayFeature.forEach((value2, index2) => {
        if(value == value2){
          indexMap[index] = value2;
          foundSimilar = 1;
        }
      });
      if(foundSimilar != 1){
        indexMap[index] = 1;
      }
      
    });
    arrayFeature.forEach((value3, index3) => {
      if(indexMap.indexOf(value3) === -1 ) { 
        var indexOfOne = indexMap.indexOf(1);
        indexMap[indexOfOne] = value3;
        //remove used non-match value
        arrayFeature.splice(index3,1)
      }
    });
    return indexMap;
  }

  useEffect(() => {
    let test = [];
    let XmaxValue = 1;
    let YmaxValue = 1;
    competitorLandscapeResults?.map((result, i) => {
        if(arrType.indexOf(result.serp_feature) === -1 && result.domain != '') { //add onlu unique elements to the array
          arrType.push(result.serp_feature);
        }
    })
    var orderedArray = orderForColor(arrType);
    orderedArray.map((type, i)=>{
      var numberArray = [];
      var webSite = '';
      var counts = [];
      competitorLandscapeResults.map((result, i) => {
        if(type == result.serp_feature){
          // counts[result.domain] = counts[result.domain] ? counts[result.domain] +1 : 1; 
          // var occurences = counts[result.domain] * 10;
          if(result.keyword_overlap > XmaxValue) {            
            XmaxValue = result.keyword_overlap;
            setmaxValueX(XmaxValue);
          }
          if(result.average_position_on_page_one > YmaxValue) {            
            YmaxValue = result.average_position_on_page_one.toFixed(2);
            setmaxValueY(YmaxValue);
          }
          var titleDomain = result.domain;
          var titleFeature = type;
          
          if(titleDomain){
            const arrData  = { x:result.keyword_overlap, y:result.average_position_on_page_one.toFixed(2), z:parseInt(result.record_count) , domain:titleDomain, feature:titleFeature};
            numberArray.push(arrData);
            webSite = result.domain;
            
          }
        }
      })

      test[i] = {
        name: type,
        Website: webSite,
        data : numberArray
      }
      setCharDataState(test)
    })

    
  }, [competitorLandscapeResults])

  const series2Model= [
    {
      name: 'Organic Results',
      Website: 'puma.com',
      data: [[2 ,3, 5,'puma','ss'], [10, 11, 15,'puma','ss'], [2 ,27, 12,'puma','ss'], [10, 11, 4,'puma','ss']]
    },
    {
      name: 'Ad (Bottom-of-Page',
      Website: 'puma.com',
      data: [[2 ,21, 5,'puma'], [10, 51, 15,'puma'], [2 ,45, 5,'puma'], [10, 11, 15,'puma']]
    }]


  const options= {
    chart: {
        height: 350,
        type: 'bubble',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            download: '<img src='+require("../../../images/visualization/download.svg").default+' width="20">'
          }
        }   
    },
    dataLabels: {
      enabled: true,
      formatter: function(value, { seriesIndex, dataPointIndex, w }) {
        const bubbleItem = w.config.series[seriesIndex].data[dataPointIndex];
        if(bubbleItem){
          const bubbleDomain = bubbleItem.domain ? bubbleItem.domain : '';
          if(bubbleDomain == companyDomain){
            return bubbleDomain ;
          }
        }
      },
      style: {
        colors: ['#000000']
      }
    },
    fill: {
        opacity: 0.8
    },
    xaxis: {
        title: {
          text: 'Keyword Overlap'
        },
        min:0,
        tickAmount: 7,
        type: 'numeric',
        forceNiceScale: false,
        logarithmic: false, 
        // tickPlacement: 'on',
        reversed: false,
        
    },
  
    yaxis: {
      title: {
        text: 'Avg. Position'
      },
      floating: false,
      decimalsInFloat: 2,  
      reversed: true,
      min:1,
      max:5,  
      tickAmount: 9,
      forceNiceScale:false,
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: 0
      },
      axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0
        },
      labels: {
        offsetX: -5,
      }
    },
    // z-axis -- bubble size
    plotOptions: {
      bubble: {
        zScaling: true,
        minBubbleRadius: 10,
        maxBubbleRadius: 150,
        
      }
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return '<ul style="list-style: none; padding:15px 10px 0px 10px" ' +
        '<li>Website: <b>' + data.domain + '</b></li>' +
        '<li>SERP Feature: <b>' + data.feature + '</b></li>' +
        '<br>'+
        '<li>Keyword Overlap: <b>' + data.x + '</b></li>' +
        '<li>Avg. Position: <b>' + data.y + '</b></li>' +
        '<li>Record Count: <b>' + data.z  + '</b></li>' +
        '</ul>';
      }
    },
    colors: ['#841F5B', '#F8855B', '#364DF8', '#C51852', '#854EFF', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0','#9467FA', '#78D9F0', '#FDDF34', '#FF5CE6', '#EF008C'],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
      showForZeroSeries :false,
      showForNullSeries :false,
      markers: {
        radius: 2,
        fillColors:['#841F5B', '#F8855B', '#364DF8', '#C51852', '#854EFF', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0','#9467FA', '#78D9F0', '#FDDF34', '#FF5CE6', '#EF008C'],
      }
    },
    annotations: {
      yaxis: [
        {
          y: 3,
          borderColor: '#707070',
          label: {
            borderColor: '#F7F7F7',
            style: {
              fontSize: '14px',
              color: '#707070',
              background: '#F7F7F7'
            },
            text: 'Median'
          }
        },
        {
          y: 1,
          borderColor: '#F7F7F7',
          label: {
            text: 'Toughest Competitors',
            borderColor: '#F7F7F7',
            style: {
              color: '#707070',
              background: '#F7F7F7'
            },
            offsetY: 20,
          }
        },
        {
          y: 5,
          borderColor: '#F7F7F7',
          label: {
            text: 'Rising Threats',
            borderColor: '#F7F7F7',
            style: {
              color: '#707070',
              background: '#F7F7F7'
            }
          }
        },
        {
          y: 1,
          borderColor: '#F7F7F7',
          label: {
            text: 'Opportunistic Competitors',
            borderColor: '#F7F7F7',
            style: {
              color: '#707070',
              background: '#F7F7F7'
            },
            offsetY:20,
            offsetX:150,
            position: 'left',
            orientation: 'horizontal'
          }
        },
      ],
      xaxis: [
        {
          x: maxValueX/2,
          borderColor: '#707070',
          label: {
            borderColor: '#F7F7F7',
            style: {
              fontSize: '14px',
              color: '#707070',
              background: '#F7F7F7'
            },
            text: 'Percentile 90'
          }
        },
        {
          y: 1,
          borderColor: '#F7F7F7',
          label: {
            text: 'Under-the-Radar Competitors',
            borderColor: '#F7F7F7',
            style: {
              color: '#707070',
              background: '#F7F7F7'
            },
            offsetY: -3,
            offsetX:100,
            position: 'right',
            orientation: 'horizontal'
          }
        },

      ]
    } 
  }

  return (
   <Chart 
              options={options}
              series={chartData}
              type='bubble'
              height={450}
              width='100%'
    />
  )
}
