import React, {useState, useEffect} from 'react'
import { Form, Button, Row, Col, Table} from 'react-bootstrap'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'
import { listProfileDetails } from '../actions/profileActions'

import { 
    listKeywordDetails, 
    listKeywordSortedDetails
} from '../actions/keywordActions'

import { 
    KEYWORD_DETAILS_RESET, 
} from '../constants/keywordConstants'

// import { 
//     ITEM_DETAILS_RESET, 
// } from '../constants/itemConstants'

// import { 
//     CATEGORY_DETAILS_RESET, 
// } from '../constants/categoryConstants'


import { 
    listCategoryDetails, 
    createCategory 
} from '../actions/categoryActions'

import { 
    listItemDetails, 
   // createItem 
} from '../actions/itemActions'


import Loader from '../components/Loader'
//import Message from '../components/Message'
import PaginateCategorizer from '../components/PaginateCategorizer'

//import { devices, verticals, types, locations } from '../constants/utils'


function Categorizer_updated_screen({match, history}) {

    const dispatch = useDispatch()
    const [accessToken, setAccessToken] = useState('')
    const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    
    let param = history.location.search
    //console.log(param)

    const [paramPrev, setParamPrev] = useState('')

    //user details
   // const [email, setEmail] = useState('')

    const profileDetails = useSelector(state => state.profileDetails)
    const {
        //loading, 
       // error, 
        success, profile} = profileDetails

    // Category details
    //const [categoryId, setCategoryId] = useState('')
    const [categoryValue, setCategoryValue] = useState('')
    //const [categoryType, setCategoryType] = useState('')
   // const [categorySelected, setCategorySelected] = useState('')

    // Item details
    // const [itemId, setItemId] = useState('')
    // const [itemValue, setItemValue] = useState('')
    // const [itemSelected, setItemSelected] = useState('')
 
    //Keyword details
    // const [keywordId, setKeywordId] = useState('')
    // const [keywordValue, setKeywordValue] = useState('')
    // const [device, setDevice] = useState(devices[0])
    // const [brand, setBrand] = useState(false)
    // const [google_domain, setGoogle_domain] = useState(0)
    // const [language, setLanguage] = useState(0)
    // const [location, setLocation] = useState(0)
    // const [locationArray, setLocationArray] = useState([locations[0].id])
    // const [locationIds, setLocationIds] = useState([])
    // const [country, setCountry] = useState(0)
    // const [vertical, setVertical] = useState(verticals[0])
    // const [type, setType] = useState(types[0])
    // const [keywordActive, setKeywordActive] = useState(false)
    // const [seo_group, setSeo_group] = useState('')
    // const [keywordText, setKeywordText] = useState([])
    // const [keywordTextChanged, setKeywordTextChanged] = useState(false)
    // const [pageURL, setPageURL] = useState('')

    // const [keywordCreatedBy, setKeywordCreatedBy] = useState('')
    // const [keywordCreatedAt, setKeywordCreatedAt] = useState('')
    // const [keywordModifiedAt, setKeywordModifiedAt] = useState('')
    // const [keywordModifiedBy, setKeywordModifiedBy] = useState('')

    const keywordDetails = useSelector(state => state.keywordDetails)
    const {loading: loadingDetailsKeyword, 
      //  error: errorDetailsKeyword, 
        success: successDetailsKeyword, 
        keyword, page, pages
    } = keywordDetails

    //sortedKeywords details
    const [sortedKeywordsValue, setSortedKeywordsValue] = useState({})
    const keywordSortedDetails = useSelector(state => state.keywordSortedDetails)
    const {
        // loading: loadingSortedDetailsKeyword, 
       // error: errorSortedDetailsKeyword, 
        success: successSortedDetailsKeyword, 
        sortedKeywords
    } = keywordSortedDetails

    //Category details
    const categoryDetails = useSelector(state => state.categoryDetails)
     const {//loading: loadingDetailsCategory, 
         //error: errorDetailsCategory, 
         success: successDetailsCategory, 
         category
     } = categoryDetails

    //Item details
    const itemDetails = useSelector(state => state.itemDetails)
    const {//loading: loadingDetailsItem, 
        //error: errorDetailsItem, 
        success: successDetailsItem, 
        item
    } = itemDetails

    useEffect(()=>{
        const getAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        getAccessToken()
        if(!user) {
            loginWithRedirect()
        } else {
            if(accessToken) {
                if(success) {
                    //setEmail(profile.email)
                        if(successDetailsKeyword){
                            if(param !== paramPrev){
                                setParamPrev(param)
                                dispatch({type: KEYWORD_DETAILS_RESET})   
                            }
                            // setKeywordId(keyword.id)
                            // setKeywordValue(keyword.keyword)
                            // setDevice(keyword.device)
                            // setBrand(keyword.brand)
                            // setGoogle_domain(keyword.google_domain)
                            // setLanguage(keyword.language)
                            // setLocation(keyword.location)
                            // setCountry(keyword.country)
                            // setVertical(keyword.vertical)
                            // setType(keyword.type)
                            // setKeywordActive(keyword.active)
                            // setSeo_group(keyword.seo_group)

                            // setKeywordCreatedBy(keyword.createdBy)
                            // setKeywordCreatedAt(keyword.createdAt)
                            // setKeywordModifiedBy(keyword.modifiedBy)
                            // setKeywordModifiedAt(keyword.modifiedAt)
                            if(successSortedDetailsKeyword){
                                setSortedKeywordsValue(sortedKeywords)
                                if(successDetailsCategory && category[0]){
                                    //setCategorySelected(category[0].category)
                                    
                                    //setCategoryId(category.id)
                                    if(successDetailsItem) {
                                        if(item && item.length>0){
                                            //setItemSelected(item[0].item)
                                        }
                                        
                                    }
                                    else {
                                        dispatch(listItemDetails(accessToken, category[0].category))
                                    }
                                }
                                else{
                                    dispatch(listCategoryDetails(accessToken, match.params.websiteId))
                                }
                            }
                            else{
                                dispatch(listKeywordSortedDetails(accessToken, match.params.websiteId))
                            }
                        }else{
                            dispatch(listKeywordDetails(accessToken, match.params.websiteId, param))
                        }
                } else {
                    dispatch(listProfileDetails(accessToken, user.email))
                }
            }
        }
    },[user, 
        accessToken, 
        success, 
        successDetailsKeyword,
        successSortedDetailsKeyword,
        dispatch,
        loginWithRedirect,
        keyword,
        match,
        profile,
        getAccessTokenSilently,
        successDetailsCategory,
        successDetailsItem,
        category,
        item,
        param,
        paramPrev,
        sortedKeywords
    ])

    const redirectWebsiteHandler = (e) => {
        e.preventDefault()
        history.push(`/website/details/${match.params.websiteId}`)
    }

    //Parent functions
    const addCategoryHandler = (e, type) => {
        e.preventDefault()
        const category = {
            category: categoryValue,
            type: type
        }
        dispatch(createCategory(accessToken, category, user.email, match.params.websiteId))
        setCategoryValue('')
    }

    // const addItemHandler = (e) => {
    //     e.preventDefault()
    //     const item ={
    //         item: itemValue
    //     } 
        
    //     dispatch(createItem(accessToken, item, user.email, parentSelected))
    //     setPortfolioSelected(portfolioValue)
    // }
    
    return (
         <>
        <Button 
            variant='secondary' 
            className='btn btn-light my-3'
            onClick={(e) => redirectWebsiteHandler(e)}
        >
            Go back
        </Button>
        <h3>Categorizer</h3>
        <br/>
        <Row className="d-flex justify-content-between">    
            <Col md={2}>
            {loadingDetailsKeyword && <Loader />}
            <Table striped  hover responsive className='table-sm'>
                <thead >
                    <tr>
                        <th>Keyword</th>
                    </tr>
                </thead>
                {keyword && keyword.map((k,index) => (
                    <tbody key={index}>
                        <tr>
                            <td>{k.keyword}</td>
                        </tr>
                    </tbody>
                ))}
                
            </Table>
            <PaginateCategorizer page={page} pages={pages} param={param} />
            </Col>
            <Col md={2}>
                <Table striped  hover className='table-sm'>
                    <thead >
                        <tr>
                            <th>Word</th>
                            <th>Freq</th>
                        </tr>
                        
                    </thead>
                    {sortedKeywordsValue && Object.entries(sortedKeywordsValue).map((key, value) => (
                        <tbody key={key}>
                            {key[1][0] > 11 &&
                            <tr>
                                <td>{key[1][1]}</td>
                                <td>{key[1][0]}</td>
                            </tr>}
                        </tbody>
                    ))}

                </Table>
            </Col>
            <Col md={7}>
            <div style={{padding:'4px', background:'lightGray'}}>
                <Row   className="d-flex justify-content-between">
                    <Col md={12}>
                        <h4 >Parent</h4>
                    </Col>
                        <br/>
                        <br/>
                    <Col md={6}>
                        <h5>Search for</h5>
                    </Col>
                    <Col md={6}>
                        <h5>Return</h5>
                    </Col>
                    
                    <Col md={6}>
                        <Form>
                            <Form.Group controlId='categoryValue'>
                                <Form.Label>
                                    Add Word
                                </Form.Label>
                                <Form.Control
                                    required
                                    type='text'
                                    placeholder='Enter a new category'
                                    value={categoryValue || ''}
                                    onChange={(e) => setCategoryValue(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>  
                            <Button
                                variant='primary'
                                onClick={(e) => addCategoryHandler(e, 'parent')}
                            >
                                Add Word
                            </Button> 
                        </Form>
                    </Col>
                    <br/>
                    <br/>
                    <Col md={6}>
                    <div style={{background:'white'}}>
                        <Form>
                            <Form.Group controlId='categoryValue'>
                                <Form.Label>
                                    da
                                </Form.Label>
                            </Form.Group>
                        </Form>
                    </div>
                    <Form>
                            <Form.Group controlId='categoryValue'>
                                <Form.Label>
                                    Add Category
                                </Form.Label>
                                <Form.Control
                                    required
                                    type='text'
                                    placeholder='Enter a new category'
                                    value={categoryValue || ''}
                                    onChange={(e) => setCategoryValue(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>  
                            <Button
                                variant='primary'
                                onClick={(e) => addCategoryHandler(e, 'parent')}
                            >
                                Add Category
                            </Button> 
                        </Form>
                    </Col>
                </Row>
                <br/>

                </div>
            </Col>
        </Row>
        </>
    )
}

export default Categorizer_updated_screen
