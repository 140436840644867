import React from "react";
import {
  Container,
  DivFlex,
  Button,
  SecondaryButton,
  Text14,
  MailTo14Pink,
  Span14pink,
  Label,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  ListItem,
  DropdownIcon,
  TitleField,
  BackDropAbsolute,
  BackDrop,
} from "./styles/general";

export default function General({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

General.DivFlex = function MainDivFlex({ children, ...restProps }) {
  return <DivFlex {...restProps}>{children}</DivFlex>;
};

General.Button = function MainButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

General.SecondaryButton = function GeneralSecondaryButton({
  children,
  ...restProps
}) {
  return <SecondaryButton {...restProps}>{children}</SecondaryButton>;
};

General.Text14 = function MainText14({ children, ...restProps }) {
  return <Text14 {...restProps}>{children}</Text14>;
};

General.Span14pink = function MainSpan14pink({ children, ...restProps }) {
  return <Span14pink {...restProps}>{children}</Span14pink>;
};

General.Label = function GeneralLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

General.MailTo14Pink = function MainMailTo14Pink({ children, ...restProps }) {
  return <MailTo14Pink {...restProps}>{children}</MailTo14Pink>;
};

// Dropdown
General.DropDownContainer = function GeneralDropDownContainer({
  children,
  ...restProps
}) {
  return <DropDownContainer {...restProps}>{children}</DropDownContainer>;
};

General.DropDownHeader = function GeneralDropDownHeader({
  children,
  ...restProps
}) {
  return <DropDownHeader {...restProps}>{children}</DropDownHeader>;
};

General.DropDownList = function GeneralDropDownList({
  children,
  ...restProps
}) {
  return <DropDownList {...restProps}>{children}</DropDownList>;
};

General.ListItem = function GeneralListItem({ children, ...restProps }) {
  return <ListItem {...restProps}>{children}</ListItem>;
};

General.DropdownIcon = function GeneralDropdownIcon({
  children,
  ...restProps
}) {
  return <DropdownIcon {...restProps}>{children}</DropdownIcon>;
};

General.TitleField = function GeneralTitleField({ children, ...restProps }) {
  return <TitleField {...restProps}>{children}</TitleField>;
};

General.BackDrop = function GeneralBackDrop({ children, ...restProps }) {
  return <BackDrop {...restProps}>{children}</BackDrop>;
};
General.BackDropAbsolute = function GeneralBackDropAbsolute({
  children,
  ...restProps
}) {
  return <BackDropAbsolute {...restProps}>{children}</BackDropAbsolute>;
};
