export const PROFILE_LIST_REQUEST   = 'PROFILE_LIST_REQUEST'
export const PROFILE_LIST_SUCCESS   = 'PROFILE_LIST_SUCCESS'
export const PROFILE_LIST_FAIL      = 'PROFILE_LIST_FAIL'
export const PROFILE_LIST_RESET      = 'PROFILE_LIST_RESET'

export const PROFILE_DETAILS_REQUEST    = 'PROFILE_DETAILS_REQUEST'
export const PROFILE_DETAILS_SUCCESS    = 'PROFILE_DETAILS_SUCCESS'
export const PROFILE_DETAILS_FAIL       = 'PROFILE_DETAILS_FAIL'
export const PROFILE_DETAILS_RESET      = 'PROFILE_DETAILS_RESET'

export const PROFILE_DETAILS_BY_ORGANISATION_EMAIL_REQUEST    = 'PROFILE_DETAILS_BY_ORGANISATION_EMAIL_REQUEST'
export const PROFILE_DETAILS_BY_ORGANISATION_EMAIL_SUCCESS    = 'PROFILE_DETAILS_BY_ORGANISATION_EMAIL_SUCCESS'
export const PROFILE_DETAILS_BY_ORGANISATION_EMAIL_FAIL       = 'PROFILE_DETAILS_BY_ORGANISATION_EMAIL_FAIL'
export const PROFILE_DETAILS_BY_ORGANISATION_EMAIL_RESET      = 'PROFILE_DETAILS_BY_ORGANISATION_EMAIL_RESET'

export const PROFILE_CREATE_REQUEST = 'PROFILE_CREATE_REQUEST'
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS'
export const PROFILE_CREATE_FAIL    = 'PROFILE_CREATE_FAIL'
export const PROFILE_CREATE_RESET   = 'PROFILE_CREATE_RESET'

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAIL    = 'PROFILE_UPDATE_FAIL'
export const PROFILE_UPDATE_RESET   = 'PROFILE_UPDATE_RESET'

export const PROFILE_RIGHTS_UPDATE_REQUEST = 'PROFILE_RIGHTS_UPDATE_REQUEST'
export const PROFILE_RIGHTS_UPDATE_SUCCESS = 'PROFILE_RIGHTS_UPDATE_SUCCESS'
export const PROFILE_RIGHTS_UPDATE_FAIL    = 'PROFILE_RIGHTS_UPDATE_FAIL'
export const PROFILE_RIGHTS_UPDATE_RESET   = 'PROFILE_RIGHTS_UPDATE_RESET'

export const PROFILE_UPDATE_BY_ADMIN_REQUEST = 'PROFILE_UPDATE_BY_ADMIN_REQUEST'
export const PROFILE_UPDATE_BY_ADMIN_SUCCESS = 'PROFILE_UPDATE_BY_ADMIN_SUCCESS'
export const PROFILE_UPDATE_BY_ADMIN_FAIL    = 'PROFILE_UPDATE_BY_ADMIN_FAIL'
export const PROFILE_UPDATE_BY_ADMIN_RESET   = 'PROFILE_UPDATE_BY_ADMIN_RESET'