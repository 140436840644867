export const COMPANY_LIST_REQUEST   = 'COMPANY_LIST_REQUEST'
export const COMPANY_LIST_SUCCESS   = 'COMPANY_LIST_SUCCESS'
export const COMPANY_LIST_FAIL      = 'COMPANY_LIST_FAIL'

export const COMPANY_DETAILS_REQUEST= 'COMPANY_DETAILS_REQUEST'
export const COMPANY_DETAILS_SUCCESS= 'COMPANY_DETAILS_SUCCESS'
export const COMPANY_DETAILS_FAIL   = 'COMPANY_DETAILS_FAIL'
export const COMPANY_DETAILS_RESET  = 'COMPANY_DETAILS_RESET'

export const COMPANY_DETAILS_BY_ID_REQUEST   = 'COMPANY_DETAILS_BY_ID_REQUEST'
export const COMPANY_DETAILS_BY_ID_SUCCESS   = 'COMPANY_DETAILS_BY_ID_SUCCESS'
export const COMPANY_DETAILS_BY_ID_FAIL      = 'COMPANY_DETAILS_BY_ID_FAIL'
export const COMPANY_DETAILS_BY_ID_RESET     = 'COMPANY_DETAILS_BY_ID_RESET'

export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST'
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS'
export const COMPANY_CREATE_FAIL    = 'COMPANY_CREATE_FAIL'
export const COMPANY_CREATE_RESET   = 'COMPANY_CREATE_RESET'

export const COMPANY_UPDATE_REQUEST = 'COMPANY_UPDATE_REQUEST'
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS'
export const COMPANY_UPDATE_FAIL    = 'COMPANY_UPDATE_FAIL'
export const COMPANY_UPDATE_RESET   = 'COMPANY_UPDATE_RESET'

export const COMPANY_DELETE_REQUEST = 'COMPANY_DELETE_REQUEST'
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS'
export const COMPANY_DELETE_FAIL    = 'COMPANY_DELETE_FAIL'
export const COMPANY_DELETE_RESET   = 'COMPANY_DELETE_RESET'

