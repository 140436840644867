import React from 'react'
import {AccountNotActiveContainer } from '../../containers/signup/accountNotActive'


export default function AccountNotActive({accessToken, name, email, isActive, isSuperuser}) {
    return (
        <AccountNotActiveContainer 
        accessToken={accessToken} 
        name={name} 
        email={email} 
        isActive={isActive}
        isSuperuser={isSuperuser}
        />
    )
    
}
