import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button, General } from "../../styled-components/dashboard";
import { listWebsiteDetailsByEmail} from '../../actions/websiteActions'
import LoaderImage from "../../components/LoaderImage";
import WebsiteComponent from "../../components/websiteComponent";
import ErrorMessage from "../../components/ErrorMessage";
import AddNewButton from "../../components/AddNewButton";
import { FlexColumn, FlexRow } from "../../styled-components/visualization/content/styles/content";
import { listCompanyDetails } from '../../actions/companyActions'
import * as ROUTES from "../../constants/routes/routes"
import {  Welcome} from "../../styled-components/visualization";
import Select from "../../components/Select";
import {  COMPETITOR_DETAILS_RESET } from "../../constants/competitorConstants";
import { WEBSITE_DETAILS_BY_ID_RESET } from "../../constants/websiteConstants";
import { KEYWORD_DETAILS_RESET } from "../../constants/keywordConstants";

export function HomeContainer({ accessToken, email, name }) {
  const dispatch = useDispatch()
  const [company, setCompany] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [companyArray, setCompanyArray] = useState([])
  let history = useHistory()

  const websiteDetailsByEmail = useSelector(state => state.websiteDetailsByEmail)
  const {
      loading: loadingDetailsWebsiteByEmail, 
      error: errorDetailsWebsiteByEmail, 
      success: successDetailsWebsiteByEmail, 
      website: detailsWebsiteByEmail
  } = websiteDetailsByEmail

  const companyDetails = useSelector(state => state.companyDetails)
  const {
      loading: loadingDetailsCompany, 
      error: errorDetailsCompany, 
      success: successDetailsCompany, 
      company: detailsCompany
  } = companyDetails

  const SaveHandler = (webId) =>{
    dispatch({type: COMPETITOR_DETAILS_RESET}) 
    dispatch({type: WEBSITE_DETAILS_BY_ID_RESET}) 
    dispatch({type: KEYWORD_DETAILS_RESET}) 

    history.push(`/edit-website/${webId}`)
}   

  const [actionNeeded, setActionNeeded] = useState(false)
  const [dashboardExist, setDashboardExist] = useState(false)

  const GoToWebsite = (webId) =>{
    dispatch({type: COMPETITOR_DETAILS_RESET}) 
    dispatch({type: WEBSITE_DETAILS_BY_ID_RESET}) 
    dispatch({type: KEYWORD_DETAILS_RESET}) 

    history.push(`/edit-website/${webId}`)
}

  useEffect(()=>{
    if(detailsCompany[0]){
        detailsCompany.map((x) => {
            if(x.name===company){
             setCompanyId(`/add-website/${x.id}`)
            }
        })
      }
    if(accessToken && email) 
        {
            if(successDetailsCompany){
                
                if(successDetailsWebsiteByEmail){
                    detailsWebsiteByEmail.map((website) =>{
                        if(website.no_of_keywords === 0){
                            setActionNeeded(true)
                        } else {
                            setDashboardExist(true)
                        }
                    })

                    if(!companyArray[0]){
                        if(detailsCompany[0]){
                            setCompany(detailsCompany[0].name)
                            detailsCompany.map((company) => (
                                setCompanyArray(prevArray => [...prevArray, company.name])
                            ))
                        }
                    }
                }
                else 
                if(!loadingDetailsWebsiteByEmail)
                {
                    dispatch(listWebsiteDetailsByEmail(accessToken, email, name))
                }
                } 
             else {
                dispatch(listCompanyDetails(accessToken, email, name))
            }
        }
    },[email,
        accessToken,  
        dispatch,
        successDetailsWebsiteByEmail,
        detailsWebsiteByEmail,
        successDetailsCompany,
      detailsCompany,
      actionNeeded,
      company

    ])

  const websitesTable = (website) => {
    return(
        <FlexColumn>
       
            <FlexRow
                style={{
                    maxWidth: '1000px',
                  
                }}
            >
                <FlexColumn
                    style={{
                        width: '200px'
                    }}
                >
                    <Text.Bold16Black>
                        Website
                    </Text.Bold16Black>
                </FlexColumn>
                <FlexColumn
                style={{
                        width: '200px'
                    }}
                >
                    <Text.Bold16Black>
                       Action required
                    </Text.Bold16Black>
                </FlexColumn>
               
            </FlexRow>
            {detailsWebsiteByEmail.map((website, i)=> (
                website.no_of_keywords === 0 &&
                <General.TableRow
                key={i} 
                    style={{
                        maxWidth: '1000px',
                        alignItems: 'center',
                   
                        }}
                    >
                    <FlexColumn
                        style={{
                            width: '200px'
                        }}
                        >
                        <Text.Normal14Black>
                       
                            {website.website}
                        </Text.Normal14Black>

                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '200px'
                        }}
                    >
                    {/* check if website contains infos that are needed for adding the keywords */}
                    {website.language=== '' || website.country === '' ?
                    <Text.Bold16Black>Please choose the country and the language before adding the keywords in <span style={{color: '#C51852'}}>Keyword Profile</span> section</Text.Bold16Black>
                    :
                    <AddNewButton 
                        route={`/add-keywords-copy/${website.id}`}
                        text='Add Keywords' /> 
                    }
                   
                    </FlexColumn>
                    {(website.language=== '' || website.country === '') &&
                   <FlexColumn
                   style={{
                            width: '200px'
                        }}
                   >
                   <Button.Save 
                        style={{
                            width: 'auto',
                            margin: '0.5rem 0 0 0',
                            height: '40px',
                            padding: '0 10px',
                            background: 'transparent',
                            border: '2px solid rgb(197, 24, 82)',
                            color: '#222222',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            marginTop: '20px',
                            marginLeft: '20px'
                        }}
                            onClick={() =>SaveHandler(website.id)}
                        >
                            Go to website
                        </Button.Save>
                   </FlexColumn>}
                </General.TableRow>
            ))}
        </FlexColumn>
        
    )
}
    const noCompanyContainer = () => {
        return(
            <>
            <Text.Normal26Black>
                You have not added any company yet.
            </Text.Normal26Black>
            <Text.Normal14Black>
                Please add your first new company.
            </Text.Normal14Black>
            <AddNewButton route={ROUTES.ADD_COMPANY} text='Add Company' />
            </>
            )
    }
    const noWebsiteContainer = () => {
        return(
            <Content.SectionTopWithBackground>
                <Content.FlexColumn className="centeredContent" style={{top:'20%'}}>
                    <Text.Normal26Black>
                        You have not added any website yet.
                    </Text.Normal26Black>
                    <br/>
                    <Text.Normal14Black>
                            Please add your first new website.
                    </Text.Normal14Black>


                    {detailsCompany[0] &&
                        <FlexRow
                            style= {{
                                alignItems: 'center',
                            }}
                        >
                        <FlexColumn
                            style={{
                                marginTop: '2rem',
                                maxWidth: '300px',
                                width:'200px',
                            }}
                        >
                    
                        {detailsCompany[0] && <Select param={company} setParam={setCompany} list={companyArray} placeholder='Choose a company' title='Choose a company'/> }
                            <FlexRow style={{margin:'auto'}}>
                                <AddNewButton route={companyId}text='Add Website' />

                            </FlexRow>
                        </FlexColumn>
                        </FlexRow>
                        }

                </Content.FlexColumn>
            </Content.SectionTopWithBackground>
            )
    }

  return(
       <Content       >
          {loadingDetailsWebsiteByEmail || !successDetailsWebsiteByEmail  ||
           loadingDetailsCompany || !successDetailsCompany
          ?
            <LoaderImage /> :
            errorDetailsWebsiteByEmail || errorDetailsCompany ?
            <ErrorMessage /> :
            <>
            {!detailsCompany[0] ? 
            <Content.SectionTopWithBackground>
            <Content.FlexColumn className="centeredContent">
                <Text.Normal26Black>
                    You are not tracking any websites in Luminr yet
                </Text.Normal26Black>
                <br/>
                <Text.Normal14Black>
                    To get started add your company now. Then follow the steps for adding websites.
                </Text.Normal14Black>
                <AddNewButton route={ROUTES.ADD_COMPANY} text='Add Company' />
            </Content.FlexColumn>
            </Content.SectionTopWithBackground>
            
            : 
            
            <Content.SectionTop  >
               
                    <Grid.SectionFull style={{height:'100%'}}>
                        { !detailsCompany[0] ?
                        <>{noCompanyContainer()}</>
                         :
                            
                        detailsWebsiteByEmail[0]  ? 
                        <>
                            {dashboardExist && 
                            <div
                            style={{
                                 
                                 marginBottom: '4rem'
                             }}
                            >
                            <Text.Bold16Black>
                                Luminr Dashboards
                            </Text.Bold16Black>
                            <Text.Normal14Black
                                style={{
                                    marginBottom:'2rem'
                                }}
                            >
                                Access all your website Luminr Dashboards from this page.
                            </Text.Normal14Black>

                            <Grid.SectionWebsiteContainer
                                style={{
                                    maxWidth: "850px"
                                }}
                            >
                                {/* Display the array from end to beggining */}
                            {detailsWebsiteByEmail.slice(0).reverse().map((website, i)=> (
                                website.no_of_keywords !== 0 &&
                                <div key={website.id}>
                                <WebsiteComponent 
                                websiteId = {website.id}
                                websiteName={website.website}
                                websiteUrl={website.website}
                                websiteTheme={website.theme}
                                companyIndustry={website.industry}
                                country = {website.country}
                                />
                                <Button.GoTo 
                                    onClick={() =>GoToWebsite(website.id)}
                                >
                                    Edit website
                            </Button.GoTo>
                                </div>
                            ))}
                           
                            
                           </Grid.SectionWebsiteContainer>
                           
                           </div>
                        }
                           {actionNeeded && 
                           <>
                           <Text.Bold16Black>
                            Action required
                           </Text.Bold16Black>
                           <Text.Normal14Black>
                                Add all information needed for your website in order to get the full power of Luminr.
                            </Text.Normal14Black>
                           {websitesTable()}
                           </>
                        }
                        </> :
                            <>{noWebsiteContainer()}</>
                        }

                        
                        
                    </Grid.SectionFull>

            <Welcome.FooterRightImageForAC 
            style={{
                right: '-50px',
                zIndex:'-1'
            }} 
            src={require("../../images/visualization/04.png").default} />
            </Content.SectionTop>
        }
            </>
          }
          
        
      </Content>
  )
}
