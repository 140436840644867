import React, {  useEffect, useState } from 'react'
import { Signuptest } from '../../styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import '../../index.css' 
import {  Field14Black } from "../../styled-components/dashboard/text/styles/text";
import { Button, Text } from '../../styled-components/dashboard'
import {
    updateProfileRights,
  } from "../../actions/profileActions";
import { useSelector, useDispatch } from "react-redux";
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from '../../components/ErrorMessage'

export function AccountNotActiveContainer({ children, accessToken, name, email, isActive, isSuperuser }) {
    let history = useHistory()
    const dispatch = useDispatch()
    const { logout } = useAuth0()

    const [key, setKey] = useState('')
    const [incorrectKey, setIncorrectKey] = useState(false)

    const profileRightsUpdate = useSelector((state) => state.profileRightsUpdate);
    const { 
        loading: loadingUpdateProfile,
        error: errorUpdateProfile,
        success: successUpdateProfile,
    } = profileRightsUpdate;

    useEffect(() => {
        if (accessToken  ) {
            //if account is active

            if(isActive=== undefined && isSuperuser === undefined){
            
            } else{

                  if(isActive){
                    history.push(`/`)
                  } 
            } 
            
        }
    }, [
        accessToken,
        dispatch,
        history,
        isActive,
        isSuperuser,
        successUpdateProfile
    ])

    const sendUniqueKey = () => {
        if(key === 'complexPassword'){
            const data = {
                isActive: true,
                isSuperuser: true,
                isAdmin: false,
                isCompanyAdmin: false,
                isCompanyEmployee: false,
                email
              };
              dispatch(updateProfileRights(accessToken, data));
        } else {
            setIncorrectKey(true)
            setTimeout(() => {
                setIncorrectKey(false);
              }, 3000);
        }
    }


    return (
        <Signuptest>
            {loadingUpdateProfile ? 
            <LoaderImage height={'100vh'}/> : 
            errorUpdateProfile ? 
            <ErrorMessage /> :
            <>
            <Signuptest.Frame>
                <Signuptest.Logo
                  //  to={ROUTES.HOME}
                    alt="Logo"
                    src={require("../../images/luminr-logo.svg").default}
                    
                />
                <div onClick={()=>logout()}>
                <Signuptest.IconLink
                   // to={ROUTES.HOME}
                    alt="Help"
                    src={require("../../images/logout.svg").default}
                    
                />
                </div>
                <Signuptest.Title
                    style={{
                        width: '50%',
                        marginTop: '1em'
                    }}
                >
                    Your account has to be approved by{' '}
                    <Signuptest.MainColor>LUMINR Team</Signuptest.MainColor>
                </Signuptest.Title>
                
                <Signuptest.Text
                style={{
                        marginBottom: '0px'
                    }}
                >
                    You will be notified by e-mail when your account becomes active.
                </Signuptest.Text>
                <Signuptest.Text
                    style={{
                        marginTop: '20px'
                    }}
                >
                It could take up to 24h.
                </Signuptest.Text>
                <Signuptest.Title
                    style={{
                        width: '50%',
                        marginTop: '1em'
                    }}
                >
                    Or... use your{' '}
                    <Signuptest.MainColor>Unique Key</Signuptest.MainColor>
                </Signuptest.Title>
                <Field14Black 
                style={{
                    width: '300px',
                    margin: '1em'
                }}
                placeholder="Enter your unique key"
                type="text"
                value={key}
                onChange={(e) => {
                  setKey(e.target.value);

                }}
              ></Field14Black>
              <Button.Save   
                onClick={sendUniqueKey}
              >
                Send
              </Button.Save>
              {incorrectKey &&
              <Text.Normal14Black
                style={{
                    color: 'red',
                    marginTop: '1.5em'
                }}
              >
                Your key is incorrect
              </Text.Normal14Black>}
            </Signuptest.Frame>
            </>
            }
   
        </Signuptest>
    )
}
