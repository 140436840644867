import React from "react"
import { Container, LogoImg, Path1, Path2, PostHeader, PostHeaderText,
    LaunchButton, LaunchButtonImg, Icon, IconContainer, EmailContainer
} from "./styles/header"

export default function Header({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Header.LogoImg = function HeaderLogoImg({to, ...restProps}){
    return  <LogoImg {...restProps} />    
}

Header.Path1 = function HeaderPath1({children, ...restProps}){
    return <Path1 {...restProps}>{children}</Path1>   
}

Header.Path2 = function HeaderPath2({children, ...restProps}){
    return <Path2 {...restProps}>{children}</Path2>   
}

Header.PostHeader = function HeaderPostHeader({children, ...restProps}){
    return <PostHeader {...restProps}>{children}</PostHeader>   
}

Header.PostHeaderText = function HeaderPostHeaderText({children, ...restProps}){
    return <PostHeaderText {...restProps}>{children}</PostHeaderText>   
}

Header.LaunchButton = function HeaderLaunchButton({children, ...restProps}){
    return <LaunchButton {...restProps}>{children}</LaunchButton>   
}

Header.LaunchButtonImg = function HeaderLaunchButtonImg({children, ...restProps}){
    return <LaunchButtonImg {...restProps}>{children}</LaunchButtonImg>   
}

Header.IconContainer = function HeaderIconContainer({children, ...restProps}){
    return <IconContainer {...restProps}>{children}</IconContainer>   
}

Header.Icon = function HeaderIcon({children, ...restProps}){
    return <Icon {...restProps}>{children}</Icon>   
}

Header.EmailContainer = function HeaderEmailContainer({children, ...restProps}){
    return <EmailContainer {...restProps}>{children}</EmailContainer>   
}