import * as React from 'react';
import { FlexColumn, FlexRow } from '../../styled-components/visualization/content/styles/content';
import { useState,useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Text } from '../../styled-components/dashboard';
import { emailRegex } from '../../constants/regex/regex';


export  function PricingCardContainer({priceValue,pricingData,common,generic, setProductId,setClientEmail,sentEmail=false,hidePayButton=false,enterprise=false}) {
	const price = priceValue.toString().split('.'),dollar = price[0]
	let	cent = price[1] || "00";
	const [email, setEmail] = useState(null);
	const [emailIsValid, setEmailIsValid] = useState(null);
	const [emailErrorMessage, setEmailErrorMessage] = useState('');
	const [loading ,setLoading] = useState(false)


	function validateEmail(email) {
		if (emailRegex.test(email)) {
		  setEmailIsValid(true);
		  setEmailErrorMessage('');
		} else {
		  setEmailIsValid(false);
		  setEmailErrorMessage('Invalid email format');
		}
	  }

	const handlePayButton = (e) => {
		e.preventDefault();
		setProductId(pricingData.product_id)
		
	};

	const handleBookButton = (e) => {
		e.preventDefault();

		if(emailIsValid){
			setLoading(true)
			setClientEmail(email)
		}else{
			setEmailErrorMessage('Please provide an email address');
		}
	};

	useEffect(()=>{
		setLoading(false)
		setEmail('')
	},[sentEmail])

	const normalCard = () =>{
		return (
			<div className={"pricingCard " + (pricingData.title == "Advanced" ? 'featured' : '')}>

				<div className="title">
					{pricingData.title}
				</div>

				<div className="cardP">

					<h2 className="price">
						<span className="price__currency">£</span>
						<span className="price__dollar">{dollar}</span>.
						<span className="price__cent">{cent}</span>
					</h2>

					<p className="price-desc">{generic.priceDesc}</p>

						<p className="price-overview">{pricingData.priceOverview}</p>

						<p className="description">{common.description}</p>

				</div>
				{hidePayButton ?
				<div className="paidTag">Paid</div>
				:
				<a className={'bttn bttn'} href="" onClick={(e)=>handlePayButton(e)}>{generic.ctaText}</a>
				
				}
				
			</div>
		  );
	}

	const enterpriseCard = () =>{
		return (
			<div className={"pricingCard enterprise"}>

				<div className="title">
					{pricingData.title}
				</div>

				<div className="cardP">
					<p className="price-overview">{pricingData.priceOverview}</p>

					<p className="description">{common.description}</p>

				</div>

				<Text.Bold16Black>
					<Text.AsterixPurple>*</Text.AsterixPurple>
					Email
              	</Text.Bold16Black>

				<Text.Field14Black 
					style={{margin:"0 auto", width:'90%'}}
					placeholder="Enter email address"
					type="text"
					value={email}
					onChange={(e) => {
						validateEmail(e.target.value)
						setEmail(e.target.value);
					}}
				></Text.Field14Black>
              	
				{sentEmail ?
					<span  style={{fontSize:'13px', color: 'green' }}>Email sent!</span>
					:
					<span  style={{fontSize:'13px', color: 'red' }}>{emailErrorMessage}</span>
				}
				{!loading && <a className={'bttn bttn'} href="" onClick={(e)=>handleBookButton(e)}>Book a call</a>}
				
				
			</div>
		  );
	}


	return (
		<>
			{enterprise ?
				enterpriseCard()
				:
				normalCard()
			}
		</>
		
	);
}

