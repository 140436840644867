import {
    LOGGING_SYSTEM_REQUEST,
    LOGGING_SYSTEM_SUCCESS,
    LOGGING_SYSTEM_FAIL,

    LOGS_LIST_REQUEST,
    LOGS_LIST_SUCCESS,
    LOGS_LIST_FAIL,

    LOGS_LIST_BY_EMAIL_REQUEST,
    LOGS_LIST_BY_EMAIL_SUCCESS,
    LOGS_LIST_BY_EMAIL_FAIL,
    LOGS_LIST_BY_EMAIL_RESET,

    LIST_LAST_LOGS_REQUEST,
    LIST_LAST_LOGS_SUCCESS,
    LIST_LAST_LOGS_FAIL,
    LIST_LAST_LOGS_RESET,

    LOGS_LIST_BY_FILTER_REQUEST,
    LOGS_LIST_BY_FILTER_SUCCESS,
    LOGS_LIST_BY_FILTER_FAIL,
    LOGS_LIST_BY_FILTER_RESET,

    ACTION_TYPE_REQUEST,
    ACTION_TYPE_SUCCESS,
    ACTION_TYPE_FAIL,
    ACTION_TYPE_RESET,

    LOG_TYPE_REQUEST,
    LOG_TYPE_SUCCESS,
    LOG_TYPE_FAIL,
    LOG_TYPE_RESET,
} from '../../constants/loggingsystem/loggingSystemConstants'


export const createLogReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGGING_SYSTEM_REQUEST:
            return { loading: true }

        case LOGGING_SYSTEM_SUCCESS:
            return { loading: false, success: true, createLog: action.payload }

        case LOGGING_SYSTEM_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const logsListReducer = (state = { logs: [] }, action) => {
    switch (action.type) {
        case LOGS_LIST_REQUEST:
            return { loading: true, logs: [] }

        case LOGS_LIST_SUCCESS:
            return { loading: false, logs: action.payload }

        case LOGS_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const logsListByEmailReducer = (state = { logs: [] }, action) => {
    switch (action.type) {
        case LOGS_LIST_BY_EMAIL_REQUEST:
            return { loading: true, ...state }

        case LOGS_LIST_BY_EMAIL_SUCCESS:
            return { loading: false, success: true, logs: action.payload }

        case LOGS_LIST_BY_EMAIL_FAIL:
            return { loading: false, error: action.payload }

        case LOGS_LIST_BY_EMAIL_RESET:
            return { logs: [] }

        default:
            return state
    }
}

export const listLastNLogsReducer = (state = { logs: [] }, action) => {
    switch (action.type) {
        case LIST_LAST_LOGS_REQUEST:
            return { loading: true, ...state }

        case LIST_LAST_LOGS_SUCCESS:
            return { loading: false, logs: action.payload }

        case LIST_LAST_LOGS_FAIL:
            return { loading: false, error: action.payload }

        case LIST_LAST_LOGS_RESET:
            return { logs: [] }

        default:
            return state
    }
}

export const logsListByFilterReducer = (state = { logs: [] }, action) => {
    switch (action.type) {
        case LOGS_LIST_BY_FILTER_REQUEST:
            return { loading: true, ...state }

        case LOGS_LIST_BY_FILTER_SUCCESS:
            //return { loading: false, success: true, logs: action.payload }
            return {
                loading: false,
                logs: action.payload.logs,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case LOGS_LIST_BY_FILTER_FAIL:
            return { loading: false, error: action.payload }

        case LOGS_LIST_BY_FILTER_RESET:
            return { logs: [] }

        default:
            return state
    }
}

export const actionTypeReducer = (state = { actionTypes: [] }, action) => {
    switch (action.type) {
        case ACTION_TYPE_REQUEST:
            return { loading: true, actionTypes: [] }

        case ACTION_TYPE_SUCCESS:
            return { loading: false, actionTypes: action.payload }

        case ACTION_TYPE_FAIL:
            return { loading: false, error: action.payload }

        case ACTION_TYPE_RESET:
            return { actionTypes: [] }

        default:
            return state
    }
}

export const logTypeReducer = (state = { logTypes: [] }, action) => {
    switch (action.type) {
        case LOG_TYPE_REQUEST:
            return { loading: true, logTypes: [] }

        case LOG_TYPE_SUCCESS:
            return { loading: false, logTypes: action.payload }

        case LOG_TYPE_FAIL:
            return { loading: false, error: action.payload }

        case LOG_TYPE_RESET:
            return { logTypes: [] }

        default:
            return state
    }
}
