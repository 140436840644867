import React from "react";

import { Text, Content } from "../../../../styled-components/visualization";
import { TitleContainer } from "../../utilComponents/Title";

import { Regular18Purple } from "../../../../styled-components/visualization/text/styles/text";
import { DropDownFilterContainer } from "../../filters/DropDownFilter";
import { GoogleSummariesTable } from "./GoogleSummariesTable";

export function SerpSummariesContainer({ serpMockupResults }) {


  return (
    <Content.FlexColumn>

        <>
          <TitleContainer title="Serp Summaries" />
          <Content.FlexRow
            style={{
              justifyContent: "space-between",
            }}
          >
            {/*left side */}
            <Content.FlexColumn
              style={{
                padding: "5%",
                width: "50%",
              }}
            >
              <Regular18Purple>Choose a competitor:</Regular18Purple>
              {/* dropdown filter */}
              <Content.FlexRow
                style={{
                  position: "relative",
                  paddingTop: "5%",
                }}
              >
                <DropDownFilterContainer
                  title={"Domain: "}
                  fullWidth={"true"}
                />
              </Content.FlexRow>

              <Content.FlexRow
                style={{
                  justifyContent: "space-between",
                  paddingTop: "5%",
                  textAlign: "center",
                }}
              >
                <Content.FlexColumn
                  style={{
                    justifyContent: "space-between",
                    padding: "2% 6%",
                    background: "white",
                  }}
                >
                  <Regular18Purple>SERP Features</Regular18Purple>
                  <Text.Bold50Black>7</Text.Bold50Black>
                </Content.FlexColumn>

                <Content.FlexColumn
                  style={{
                    justifyContent: "space-between",
                    padding: "2% 6%",
                    background: "white",
                  }}
                >
                  <Regular18Purple>Ranking Keywords</Regular18Purple>
                  <Text.Bold50Black>2.534</Text.Bold50Black>
                </Content.FlexColumn>
              </Content.FlexRow>

              <GoogleSummariesTable
                results={serpMockupResults}
              ></GoogleSummariesTable>
            </Content.FlexColumn>

            {/* right side */}
            <Content.FlexColumn
              style={{
                padding: "5%",
                width: "50%",
              }}
            >
              <Regular18Purple>You :</Regular18Purple>
              <Content.FlexRow
                style={{
                  justifyContent: "space-between",
                  paddingTop: "5%",
                  textAlign: "center",
                }}
              >
                <Content.FlexColumn
                  style={{
                    justifyContent: "space-between",
                    padding: "2% 6%",
                    background: "white",
                  }}
                >
                  <Regular18Purple>SERP Features</Regular18Purple>
                  <Text.Bold50Black>7</Text.Bold50Black>
                </Content.FlexColumn>

                <Content.FlexColumn
                  style={{
                    justifyContent: "space-between",
                    padding: "2% 6%",
                    background: "white",
                  }}
                >
                  <Regular18Purple>Ranking Keywords</Regular18Purple>
                  <Text.Bold50Black>2.534</Text.Bold50Black>
                </Content.FlexColumn>
              </Content.FlexRow>

              {/* dropdown filter disabled */}
              <Content.FlexRow
                style={{
                  position: "relative",
                  paddingTop: "5%",
                  visibility: "hidden",
                }}
              >
                <DropDownFilterContainer
                  title={"Domain: "}
                  fullWidth={"true"}
                />
              </Content.FlexRow>
              <GoogleSummariesTable
                results={serpMockupResults}
              ></GoogleSummariesTable>
            </Content.FlexColumn>
          </Content.FlexRow>
        </>
      )
    </Content.FlexColumn>
  );
}
