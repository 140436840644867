import React, { useState, useEffect, useContext } from "react";
import { assistantText } from "../utilComponents/InsightsText";
import {
  Text, 
  Content,
  Button,
} from "../../../styled-components/visualization";
import { useHistory } from "react-router-dom"
import Calendar from 'react-calendar';
import './Calendar.css';
import { TitleContainer } from "../utilComponents/Title";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { useDispatch } from "react-redux";
import { 
    BIG_KEYWORD_RANKING_RESET, 
    COMPETITOR_CHANNEL_RESET, 
    COMPETITOR_LANDSCAPE_RESET, 
    COMPETITOR_SWOT_HEATMAP_RESET, 
    CONTENT_EXPLORER_RESET, 
    FEATURED_SNIPPED_OPPORTUNITIES_RESET, 
    HVN_SCORE_RESET, 
    MONTHLY_MOVEMENTS_RESET, 
    RETRIEVE_SWOT_RESET, 
    SERP_COMPOSITION_RESET, 
    SERP_COMPOSITION_INCLUDE_RESET,
    SERP_LEAGUE_RESET,

} from "../../../constants/visualization/dataScienceAPIsConstants";
import {
    BackDrop,
    BackDropAbsolute,
  } from "../../../styled-components/general/styles/general";
  
 


export const DateFilterContainer = ({filtersResults}) => {
    const dispatch = useDispatch();
    let history = useHistory()

    const {
        date, dateFilterApplied
    } = useContext(CompanyContext);


    const [dateFormat, setDateFormat] = useState(new Date(date[0]));
    const [toggleCalendar, setToggleCalendar] = useState(true)

    const [dateEarliest, setDateEarliest] = useState('')
    const [dateLatest, setDateLatest] = useState('')
    const [modalBackdrop, setModalBackdrop] = useState(false);
    const [currentDate, setCurrentDate] = useState(null);
    
    useEffect(() => {
        if (filtersResults[0].latest) {
            const newDate = new Date(filtersResults[0].latest);
            newDate.setDate(newDate.getDate() + 1);
            setCurrentDate(newDate);
          }
    }, [filtersResults])
    
    const calendar = () => {

     return(
     <div>

      <Calendar 
        onChange={(dateFormat)=>setDateFormat(dateFormat)} 
        value={dateFormat}
        returnValue="range"
        selectRange='true'
        locale="gb-GB"
        minDate={new Date(filtersResults[0].earliest)}
        maxDate={currentDate}

      />
    </div>
     )
 }

    useEffect(() => {
        setDateEarliest(date[0])
        setDateLatest(date[1])
    }, [])
    
    const selectDate = () => {
        dateFilterApplied.push([dateFormat[0].toISOString().substring(0,10),dateFormat[1].toISOString().substring(0,10)])
    
        setDateEarliest(dateFormat[0].toISOString().substring(0,10))
        setDateLatest(dateFormat[1].toISOString().substring(0,10))
        //empty array
        date.splice(0,date.length)
        date.push(dateFormat[0].toISOString().substring(0,10))
        date.push(dateFormat[1].toISOString().substring(0,10))
        setToggleCalendar(!toggleCalendar)

        dispatch({type: BIG_KEYWORD_RANKING_RESET})
        dispatch({type: SERP_COMPOSITION_RESET})
        dispatch({type: SERP_COMPOSITION_INCLUDE_RESET})
        dispatch({type: CONTENT_EXPLORER_RESET})
        dispatch({type: RETRIEVE_SWOT_RESET})
        dispatch({type: RETRIEVE_SWOT_RESET})
        dispatch({type: FEATURED_SNIPPED_OPPORTUNITIES_RESET})

        //COMPETITION
        dispatch({type: COMPETITOR_LANDSCAPE_RESET})
        dispatch({type: COMPETITOR_SWOT_HEATMAP_RESET})
        dispatch({type: SERP_LEAGUE_RESET})
        dispatch({type: MONTHLY_MOVEMENTS_RESET})
        dispatch({type: HVN_SCORE_RESET})
        dispatch({type: COMPETITOR_CHANNEL_RESET})


    }

    const cancel = () => {
        setToggleCalendar(!toggleCalendar)
        setModalBackdrop(false);
        
    }

    const setLayerDateExpand = () => {
        window.dataLayer.push({
          event: "filters_expand",
          filter_name: 'data range',
          page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
          project_name: JSON.parse(localStorage.getItem('websiteName'))
        });
        
      }

    const setLayerDateSelect = () => {
        const dateRange = {
            earliest: filtersResults[0].earliest,
            latest: filtersResults[0].latest,
          };
        window.dataLayer.push({
            event: "dropdown_select",
            dropdown_name: 'data range',
            dropdown_selected: dateRange,
            page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
            project_name: JSON.parse(localStorage.getItem('websiteName'))
        });
        
      }

  return (
    <>
    {modalBackdrop ? (
        <BackDropAbsolute
          onClick={() =>cancel()}
        />
      ) : (
        <BackDrop style={{position: 'fixed'}}/>
      )}
            <Content.FlexColumn
                style={{
                    width: '23%',
                    
                }}
            >
                
                <TitleContainer 
                title='Date Range' 
                type='small' 
                assistantMessage={assistantText.DataRange}
                />
                <div
                    style={{
                        position: 'relative'
                    }}
                >
                {!toggleCalendar &&  <Content.AbsoluteDiv
                            style={{
                                left: '0',
                                top: '95%',
                                zIndex: '99',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                boxShadow: '2px 2px 2px lightgray'

                            }}
                        >
                        <Content.Triangle src={require("../../../images/visualization/triangle_white.svg").default}/>

                         {calendar()}
                         <Content.FlexRow
                         style={{
                             backgroundColor: 'white',
                             width: '100%',
                             padding: '3%',
                             justifyContent: 'space-between'
                         }}
                            >
                        <Button.Light
                         style={{
                             width: '45%'
                         }}
                         onClick={()=>cancel()}
                        >
                            Cancel
                        </Button.Light>
                        <Button.Primary
                        style={{
                             width: '45%'
                         }}
                         onClick={() => {
                            setLayerDateSelect()
                            selectDate()
                            }
                         }
                        >
                            Apply
                        </Button.Primary>
                        </Content.FlexRow>
                        </Content.AbsoluteDiv>}
                <Content.DataRangeHeader 
                onClick={() => {
                    setModalBackdrop(true)
                    setToggleCalendar(!toggleCalendar)
                    setLayerDateExpand()
                    
                    }
                }
                >     
                 <Text.Regular16Purple>{dateEarliest + ' - ' + dateLatest}</Text.Regular16Purple>
                  {toggleCalendar ?
                    <Content.Icon
                        onClick={() => setToggleCalendar(!toggleCalendar)}
                        src={require("../../../images/visualization/Arrow.svg").default}/> 
                        :
                        <>
                        <Content.Icon
                        style={{
                            transform: 'rotate(180deg)'
                        }}
                        onClick={() => setToggleCalendar(!toggleCalendar)}
                        src={require("../../../images/visualization/Arrow.svg").default}/>
                       
                        
                        </>
                   }
          
                </Content.DataRangeHeader>
                </div>
            </Content.FlexColumn>
            </>
  )
}
