import React from "react";

import { Text } from "../../../styled-components/visualization";

export function CounterContainer({number, backgroundColor='#EFEFEF'}) {

  return (
        <div
            style={{
                width: '20px',
                height: '20px',
                background: backgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
               // marginRight: '7%',
                padding: '4%'
            }}
        >
            <Text.Regular14Black
                style={{
                    fontSize: '10px',
                    color: '#1D1D1D'
                }}
            >
                {number}
            </Text.Regular14Black>
        </div>


  )
}
