import React, { createContext, useState, useEffect } from 'react'


export const FilterContext = createContext()
export default function FilterContextProvider(props)  {
    const [param, setParam] = useState('din context')
    const [firstRender, setFirstRender] = useState(true)

    var exclude_array_keyword_parent = []
    var exclude_array_keyword_child = []
    var exclude_array_keyword_portfolio = []
    var exclude_array_keyword_subportfolio = []
    var exclude_array_keyword = []
    var exclude_array_country = []
    var exclude_array_location = []
    var exclude_array_language = []
    var exclude_array_device = []
    var exclude_array_domain = []
    var exclude_array_component_clean = []
    var exclude_array_brand = []

    var initial_array_keyword_parent = []
    var initial_array_keyword_child = []
    var initial_array_keyword_portfolio = []
    var initial_array_keyword_subportfolio = []
    var initial_array_keyword = []
    var initial_array_country = []
    var initial_array_location = []
    var initial_array_language = []
    var initial_array_device = []
    var initial_array_domain = []
    var initial_array_component_clean = []
    var initial_array_brand = ['1', '0']

    var filter_criteria_initial = [
        {
          "mode": 'exclude',
          'dimension': 'keyword_parent',
          "elements": exclude_array_keyword_parent
        },
        {
          'mode': 'exclude',
          'dimension': 'keyword_child',
          "elements": exclude_array_keyword_child
        },
        {
          'mode': 'exclude',
          'dimension': 'keyword_portfolio',
          "elements": exclude_array_keyword_portfolio
        },
        {
          'mode': 'exclude',
          'dimension': 'keyword_subportfolio',
          "elements": exclude_array_keyword_subportfolio
        },
        {
          'mode': 'exclude',
          'dimension': 'keyword',
          "elements": exclude_array_keyword
        },
        {
          'mode': 'exclude',
          'dimension': 'country',
          "elements": exclude_array_country
        },
        {
          'mode': 'exclude',
          'dimension': 'location',
          "elements": exclude_array_location
        },
        {
          'mode': 'exclude',
          'dimension': 'language',
          "elements": exclude_array_language
        },
        {
          'mode': 'exclude',
          'dimension': 'device',
          "elements": exclude_array_device
        },
        {
          'mode': 'exclude',
          'dimension': 'domain',
          "elements": exclude_array_domain
        },
        {
          'mode': 'exclude',
          'dimension': 'component_clean',
          "elements": exclude_array_component_clean
        },
        {
          'mode': 'exclude',
          'dimension': 'brand',
          "elements": exclude_array_brand
        },
        
      ] 

     const [filter_criteria, setFilter_criteria] = useState(filter_criteria_initial)


  
    return (
         <FilterContext.Provider 
         value={{
            filter_criteria,setFilter_criteria,
            exclude_array_keyword_parent,
            exclude_array_keyword_child,
            exclude_array_keyword_portfolio,
            exclude_array_keyword_subportfolio,
            exclude_array_keyword,
            exclude_array_country,
            exclude_array_location,
            exclude_array_language,
            exclude_array_device,
            exclude_array_domain,
            exclude_array_component_clean,
            exclude_array_brand,
            initial_array_keyword_parent,
            initial_array_keyword_child,
            initial_array_keyword_portfolio,
            initial_array_keyword_subportfolio,
            initial_array_keyword,
            initial_array_country,
            initial_array_location,
            initial_array_language,
            initial_array_device,
            initial_array_domain,
            initial_array_component_clean,
            initial_array_brand,
            // date,
            firstRender, setFirstRender,
            param, setParam,
            // companyDomain, setCompanyDomain
         }}
         >
               {props.children}
         </FilterContext.Provider>
    )
}
