import React from 'react'
import { TemplateContainer } from '../../containers/dashboard/template'

export default function Billing({accessToken, name, email, isActive, isSuperuser}) {
    return (
    <TemplateContainer pageName={'Billing'}  email={email} 
    name={name} 
    accessToken={accessToken}
    isActive={isActive}
    isSuperuser={isSuperuser}
    />)
}

