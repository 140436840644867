import React from 'react'
import {
    Background,
    Container,
    ContainerHeader,
    ContainerFooter,
    Logo,
    IconLink,
    Title,
    MainColor,
    Text,
    HeaderText,
    Slider,
    ButtonGroup,
    ButtonBack,
    ButtonNext,
    InputGroup,
    InputContainer,
    Label,
    Input,
    Textarea,
    Select,
    SelectList,
    Tooltip,
    Border,
    LineUp,
    Dropdown,
    LineDown,
    LineBottom,
    DropDownContainer,
    DropDownHeader,
    DropDownListContainer,
    DropDownList,
    ListItem,
    DropdownIcon,
    Link
} from './styles/signuptest'
import { Link as ReactRouterLink } from 'react-router-dom'

export default function Signuptest({ children, ...restProps }) {
    return <Background {...restProps}>{children}</Background>
}

Signuptest.HeaderFrame = function SignupTestHeaderFrame({ children, ...restProps }) {
    return <ContainerHeader {...restProps}>{children}</ContainerHeader>
}

Signuptest.FooterFrame = function SignupTestFooterFrame({ children, ...restProps }) {
    return <ContainerFooter {...restProps}>{children}</ContainerFooter>
}

Signuptest.Frame = function SignupTestFrame({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>
}

Signuptest.IconLink = function SignupTestIconLink({ to, ...restProps }) {
    return (
        <ReactRouterLink to={to}>
            <IconLink {...restProps}></IconLink>
        </ReactRouterLink>
    )
}

Signuptest.Logo = function SignupTestLogo({ to, ...restProps }) {
    return (
        <ReactRouterLink to={to}>
            <Logo {...restProps}></Logo>
        </ReactRouterLink>
    )
}

Signuptest.Title = function SignupTestTitle({ children, ...restProps }) {
    return <Title {...restProps}>{children}</Title>
}

Signuptest.MainColor = function SignuptestMainColor({ children, ...restProps }) {
    return <MainColor {...restProps}>{children}</MainColor>;
}

Signuptest.Text = function SignuptestText({ children, ...restProps }) {
    return <Text {...restProps}>{children}</Text>;
}

Signuptest.HeaderText = function SignuptestHeaderext({ children, ...restProps }) {
    return <HeaderText {...restProps}>{children}</HeaderText>;
}

Signuptest.Slider = function SignuptestSlider({ children, ...restProps }) {
    return <Slider {...restProps}>{children}</Slider>;
}

Signuptest.ButtonGroup = function SignupTestButtonGroup({ children, ...restProps }) {
    return <ButtonGroup {...restProps}>{children}</ButtonGroup>
}

Signuptest.ButtonBack = function SignupTestButtonBack({ children, ...restProps }) {
    return <ButtonBack {...restProps}>{children}</ButtonBack>
}

Signuptest.ButtonNext = function SignupTestButtonNext({ children, ...restProps }) {
    return <ButtonNext {...restProps}>{children}</ButtonNext>
}

Signuptest.InputGroup = function SignupTestInputGroup({ children, ...restProps }) {
    return <InputGroup {...restProps}>{children}</InputGroup>
}

Signuptest.InputContainer = function SignupTestInputContainer({ children, ...restProps }) {
    return <InputContainer {...restProps}>{children}</InputContainer>
}

Signuptest.Label = function SignupTestLabel({ children, ...restProps }) {
    return <Label {...restProps}>{children}</Label>
}

Signuptest.Input = function SignupTestInput({ children, ...restProps }) {
    return (
    <Border >
        <Input {...restProps}>{children}</Input>
    </Border>
    );
}

Signuptest.Textarea = function SignuptestTextarea({ children, ...restProps }) {
    return <Textarea {...restProps}>{children}</Textarea>;
}

Signuptest.Select = function SignuptestSelect({ children, ...restProps }) {
    return <Select {...restProps}>{children}</Select>;
}

Signuptest.SelectList = function SignuptestSelectList({ children, ...restProps }) {
    return <SelectList {...restProps}>{children}</SelectList>;
}

Signuptest.LineUp = function SignupTestLineUp({ children, ...restProps }) {
    return <LineUp {...restProps}>{children}</LineUp>
}

Signuptest.Tooltip = function SignupTestTooltip({ children, ...restProps }) {
    return <Tooltip {...restProps}>{children}</Tooltip>
}

Signuptest.Dropdown = function SignupTestDropdown({ children, ...restProps }) {
    return <Dropdown {...restProps}>{children}</Dropdown>
}

Signuptest.LineDown = function SignupTestLineDown({ children, ...restProps }) {
    return <LineDown {...restProps}>{children}</LineDown>
}

Signuptest.LineBottom = function SignupTestLineBottom({ children, ...restProps }) {
    return <LineBottom {...restProps}>{children}</LineBottom>
}

// Dropdown
Signuptest.DropDownContainer = function SignupTestDropDownContainer({ children, ...restProps }) {
    return <DropDownContainer {...restProps}>{children}</DropDownContainer>
}

Signuptest.DropDownHeader = function SignupTestDropDownHeader({ children, ...restProps }) {
    return <DropDownHeader {...restProps}>{children}</DropDownHeader>
}

Signuptest.DropDownListContainer = function SignupTestDropDownListContainer({ children, ...restProps }) {
    return <DropDownListContainer {...restProps}>{children}</DropDownListContainer>
}

Signuptest.DropDownList = function SignupTestDropDownList({ children, ...restProps }) {
    return <DropDownList {...restProps}>{children}</DropDownList>
}

Signuptest.ListItem = function SignupTestListItem({ children, ...restProps }) {
    return <ListItem {...restProps}>{children}</ListItem>
}

Signuptest.DropdownIcon = function SignupTestDropdownIcon({ children, ...restProps }) {
    return <DropdownIcon {...restProps}>{children}</DropdownIcon>
}

Signuptest.Link = function SignupTestLink({ children, ...restProps }) {
    return <Link {...restProps}>{children}</Link>
}