export const SUBSCRIPTION_LIST_REQUEST      = 'SUBSCRIPTION_LIST_REQUEST'
export const SUBSCRIPTION_LIST_SUCCESS      = 'SUBSCRIPTION_LIST_SUCCESS'
export const SUBSCRIPTION_LIST_FAIL         = 'SUBSCRIPTION_LIST_FAIL'
export const SUBSCRIPTION_LIST_RESET         = 'SUBSCRIPTION_LIST_RESET'

export const SUBSCRIPTION_DETAILS_REQUEST   = 'SUBSCRIPTION_DETAILS_REQUEST'
export const SUBSCRIPTION_DETAILS_SUCCESS   = 'SUBSCRIPTION_DETAILS_SUCCESS'
export const SUBSCRIPTION_DETAILS_FAIL      = 'SUBSCRIPTION_DETAILS_FAIL'
export const SUBSCRIPTION_DETAILS_RESET      = 'SUBSCRIPTION_DETAILS_RESET'

export const SUBSCRIPTION_DETAILS_BY_ID_REQUEST   = 'SUBSCRIPTION_DETAILS_BY_ID_REQUEST'
export const SUBSCRIPTION_DETAILS_BY_ID_SUCCESS   = 'SUBSCRIPTION_DETAILS_BY_ID_SUCCESS'
export const SUBSCRIPTION_DETAILS_BY_ID_FAIL      = 'SUBSCRIPTION_DETAILS_BY_ID_FAIL'
export const SUBSCRIPTION_DETAILS_BY_ID_RESET      = 'SUBSCRIPTION_DETAILS_BY_ID_RESET'

export const SUBSCRIPTION_CREATE_REQUEST    = 'SUBSCRIPTION_CREATE_REQUEST'
export const SUBSCRIPTION_CREATE_SUCCESS    = 'SUBSCRIPTION_CREATE_SUCCESS'
export const SUBSCRIPTION_CREATE_FAIL       = 'SUBSCRIPTION_CREATE_FAIL'
export const SUBSCRIPTION_CREATE_RESET      = 'SUBSCRIPTION_CREATE_RESET'

export const SUBSCRIPTION_UPDATE_STATUS_REQUEST    = 'SUBSCRIPTION_UPDATE_STATUS_REQUEST'
export const SUBSCRIPTION_UPDATE_STATUS_SUCCESS    = 'SUBSCRIPTION_UPDATE_STATUS_SUCCESS'
export const SUBSCRIPTION_UPDATE_STATUS_FAIL       = 'SUBSCRIPTION_UPDATE_STATUS_FAIL'
export const SUBSCRIPTION_UPDATE_STATUS_RESET      = 'SUBSCRIPTION_UPDATE_STATUS_RESET'

export const SUBSCRIPTION_DELETE_REQUEST    = 'SUBSCRIPTION_DELETE_REQUEST'
export const SUBSCRIPTION_DELETE_SUCCESS    = 'SUBSCRIPTION_DELETE_SUCCESS'
export const SUBSCRIPTION_DELETE_FAIL       = 'SUBSCRIPTION_DELETE_FAIL'
export const SUBSCRIPTION_DELETE_RESET      = 'SUBSCRIPTION_DELETE_RESET'