import styled from "styled-components";
import * as pallete from "../../../constants/theme/colours";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  letter-spacing: 0;
`;

export const DivFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Button = styled.button`
  display: block;
  padding: 14px 32px;
  border-radius: 4px;
  border: 2px solid #c51852;
  background: inherit;
  transition: 0.3s;
  transition-timing-function: ease-out;
  font-family: tenon, sans-serif;
  font-size: 18px;
  width: fit-content;

  &:hover {
    color: #ffffff;
    background: #c51852;
  }
`;

export const SecondaryButton = styled.button`
  display: block;
  padding: 14px 32px;
  border-radius: 4px;
  border: 2px solid ${pallete.GRAY};
  transition: 0.3s;
  transition-timing-function: ease-out;
  background-color: ${pallete.WHITE};
  font-family: tenon, sans-serif;
  font-size: 18px;
  width: fit-content;
  margin-right: 24px;

  &:hover {
    background-color: ${pallete.LIGHT_GRAY};
  }
`;

export const Text14 = styled.p`
  font-size: 14px;
  color: #222222;
  display: block;
  margin-bottom: 20px;
`;

export const Span14pink = styled.span`
  font-size: 14px;
  color: #c51852;
`;

export const Label = styled.input`
  display: block;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 312px;
  margin-top: 20px;
  font-size: 14px;
  outline: none;

  ::placeholder {
    color: ${pallete.BLACK};
  }
`;

// Dropdown
export const DropDownContainer = styled.div`
  z-index: 1;
`;

export const DropDownHeader = styled.div`
  width: 312px;
  display: block;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 20px;
  font-size: 14px;
  position: relative;
`;

export const DropDownList = styled.ul`
  width: 312px;
  position: absolute;
  border: 1px solid #d4d4d4;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: left;
  height: 152px;
  overflow: auto;
  z-index: 99;

  /* width */
  ::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${pallete.LIGHT_GRAY};
    border-right: 10px #f6f6f6 solid;
    border-top: 10px #f6f6f6 solid;
    border-bottom: 10px #f6f6f6 solid;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${pallete.PINK};
    border-right: 10px #f6f6f6 solid;
    border-top: 10px #f6f6f6 solid;
    border-bottom: 10px #f6f6f6 solid;
    background-clip: padding-box;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #f6f6f6;
  cursor: pointer;
`;

export const DropdownIcon = styled.img`
  position: absolute;
  right: 19px;
  top: 14px;
`;

export const TitleField = styled.p`
  font-size: 16px;
  color: #222222;
  display: block;
  margin-bottom: 12px;
  font-weight: bold;
`;

export const MailTo14Pink = styled.a`
  font-size: 14px;
  color: #c51852;
  text-decoration: none;

  &:hover {
    color: #c51852;
  }
`;
export const BackDrop = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 0px;
  width: 0px;
  background: transparent;
`;
export const BackDropAbsolute = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: transparent;
  cursor: default;
  position:fixed;
`;

