import React from "react"
import { Container, Column, Title, Label} from "./styles/profile"

export default function Profile({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}


Profile.Column = function ProfileColumn({children, ...restProps}){
    return <Column {...restProps}>{children}</Column>   
}

Profile.Title = function ProfileTitle({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>   
}

Profile.Label = function ProfileLabel({children, ...restProps}){
    return <Label {...restProps}>{children}</Label>   
}