import { Route } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from 'react-redux'
import { listProfileDetails } from '../actions/profileActions'
import LoaderImage from '../components/LoaderImage'
import { useHistory } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    let history = useHistory()

    const profileDetails = useSelector((state) => state.profileDetails)
    const { success, profile, loading } = profileDetails
    //user details
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isActive, setIsActive] = useState()
    const [isSuperuser, setIsSuperuser] = useState()
    const [websiteId, setWebsiteId] = useState()
    const [userEmail, setUserEmail] = useState()
    //const [isSuperuser, setIsSuperuser] = useState(false)
    //const [isAdmin, setIsAdmin] = useState(false)
    // const [isCompanyAdmin, setIsCompanyAdmin] = useState(false)
    // const [isCompanyEmployee, setIsCompanyEmployee] = useState(false)

    const {user, loginWithRedirect, isLoading, isAuthenticated, getAccessTokenSilently} = useAuth0()
    const [accessToken, setAccessToken] = useState('')

    useEffect(() => {
        const getAccessToken = async () => {
            const token = await getAccessTokenSilently()
            setAccessToken(token)
        }
        if (!isLoading)
            if (isAuthenticated) {
                getAccessToken()
                if (accessToken) {
                    if (success) {
                        setEmail(profile.email)
                        setName(profile.name)
                        setIsSuperuser(profile.isSuperuser)
                        setIsActive(profile.isActive)
                        setWebsiteId(profile.telephone)
                        setUserEmail(profile.userEmail)
                        // setIsAdmin(profile.isAdmin)
                        // setIsCompanyAdmin(profile.isCompanyAdmin)
                        // setIsCompanyEmployee(profile.isCompanyEmployee)
                    } else {
                        dispatch(listProfileDetails(accessToken, user.email))
                    }
                    if (!user.email_verified) {
                        history.push('/verify-users-email')
                    }
                } 
            } else {
                loginWithRedirect()
            }
    }, [
        isLoading,
        accessToken,
        isAuthenticated,
        success,
        dispatch,
        profile,
        user,
        getAccessTokenSilently,
        loginWithRedirect,
    ])

    return loading || !success ? (
        <LoaderImage height={'100vh'} />
    ) : (
        isAuthenticated && (
            <Route
                {...rest}
                render={(props) => (
                    <Component
                        {...props}
                        accessToken={accessToken}
                        user={user}
                        name={name}
                        email={email}
                        userEmail={userEmail}
                        isActive={isActive}
                        isSuperuser={isSuperuser}
                        websiteId={websiteId}
                    />
                )}
            />
        )
    )
}

export default PrivateRoute
