import React, { useState, useEffect } from 'react'
import { Signuptest } from '../../styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateProfile, listProfileDetails } from '../../actions/profileActions'
import '../../index.css' 
import LoaderImage from '../../components/LoaderImage'
import { metrics } from '../../constants/utils'
import { Content } from '../../styled-components/dashboard';
import { Button } from '../../styled-components/dashboard/'

export function ImproveSerprSecondPageContainer({ children, accessToken }) {
    let history = useHistory()
    const dispatch = useDispatch()
    const { user, getAccessTokenSilently, logout } = useAuth0()

    const [userName, setUserName] = useState("")
    const [mostImportantMetric, setMostImportantMetric] = useState("");
    const [challengesToShare, setChallengesToShare] = useState("");

    const profileDetails = useSelector((state) => state.profileDetails);
    const {success, profile } = profileDetails;

    const profileUpdate = useSelector((state) => state.profileUpdate);
    const { 
      loading: loadingUpdateProfile,
      error: errorUpdateProfile,
      success: successUpdateProfile,
    } = profileUpdate;

    const [over,setOver]=React.useState(null);
    
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setPageWidth(window.innerWidth);
    };

    useEffect(() => {
        if (accessToken) {
            if (success){ 
                setUserName(profile.name)
                setMostImportantMetric(profile.mostImportantMetric);
                setChallengesToShare(profile.challengesToShare);
                if(successUpdateProfile){
                    history.push(`/`)
                }
                if(profile.mostImportantMetric || profile.challengesToShare) {
                    history.push(`/`)
                  }
            } else {
              dispatch(listProfileDetails(accessToken, profile.userProfile));
            }
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [
        user,
        accessToken,
        dispatch,
        success,
        profile,
        getAccessTokenSilently
    ])

    const [chosenMetric, setChosenMetric] = useState('')
    const [chosenMetricCheck, setChosenMetricCheck] = useState(false)
    const [challengesToShareCheck, setChallengesToShareCheck] = useState(false)

    const [isOpen, setIsOpen] = useState(false)

    const toggleDropdown = (e) => {
        e.target.style.borderColor = '#C51852'
        e.target.style.color = '#222222'
        if (e.target.childNodes[0].data === 'Which metric is most important to you?' && isOpen) {
            e.target.style.borderColor = '#D4D4D4'
            e.target.style.color = '#D4D4D4'
            e.target.childNodes[1].style.backgroundColor = '#D4D4D4'
        } 
        setIsOpen(!isOpen)
    }
    const onOptionClicked = (e, i, metric) => {
        setMostImportantMetric(metric)
        setChosenMetric(metric)
        setIsOpen(false)
        setChosenMetricCheck(false)
    }

    const updateHandler = async (e) => {
        e.preventDefault()
        
        if(chosenMetric && challengesToShare) {
            setChosenMetricCheck(false);
            setChallengesToShareCheck(false);
            const data = {
                name: userName,
                email: profile.userEmail,
                mostImportantMetric,
                challengesToShare,
                handler: 'mostImportantMetric'
              }; 
            
            dispatch(updateProfile(accessToken, data));
        } else if(chosenMetric && !challengesToShare) {
            setChosenMetricCheck(false);  
            setChallengesToShareCheck(true);  
        } else if(!chosenMetric && challengesToShare) {
            setChosenMetricCheck(true);  
            setChallengesToShareCheck(false);  
        } else {
            setChosenMetricCheck(true);  
            setChallengesToShareCheck(true);
        }

    }


    return (
        <Signuptest>
            {loadingUpdateProfile ? <LoaderImage height={'100vh'}/> : 
            <>
                <Signuptest.HeaderFrame>
                    <div>
                        <Signuptest.Logo
                            href="https://luminr.ai/"
                            alt="Logo"
                            src={require("../../images/luminr-logo.svg").default} 
                        />
                    </div>
                    <div 
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        zIndex: '9999',
                        paddingRight: '135px'
                      }}
                    >
                        {/* <Signuptest.HeaderText id="login-btn-header" style={{paddingRight: '10px'}}>Log In</Signuptest.HeaderText>
                        <label 
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                            width: '40px',
                            height: '18px',
                            verticalAlign: 'middle',
                            margin: '0'
                          }}
                        >
                            <input type="checkbox"/>
                            <Signuptest.Slider></Signuptest.Slider>
                        </label>
                        <Signuptest.HeaderText id="create-btn-header">Create an account</Signuptest.HeaderText> */}
                        <Signuptest.HeaderText style={{paddingLeft: '20px'}}>
                            <a href="https://luminr.ai/">
                                Back to Luminr.ai
                            </a>
                        </Signuptest.HeaderText>
                        <Button.Menu onClick={() => logout()} style={{
                            position: 'absolute',
                            right: '20px',
                            width: '120px',
                            zIndex: '99',
                            right: '10%',
                            marginTop: '0'
                        }}>
                            <Button.MenuBackground>
                                <Button.MenuImg
                                    src={
                                        require('../../images/dashboard/left-arrow-menu-button.svg')
                                            .default
                                    }
                                />
                            </Button.MenuBackground>
                            Logout
                        </Button.Menu>
                    </div>
                </Signuptest.HeaderFrame>
                <Signuptest.Frame>
                    <Signuptest.Title>
                        Tell us about your goals
                    </Signuptest.Title>
                    <Signuptest.Text>
                        Complete some personal details to get started.
                    </Signuptest.Text>

                    <form style={{ width: 'calc(15rem + 15vw)' }} onSubmit={updateHandler}>
                        <Signuptest.DropDownContainer style={{ position: 'relative' }}>
                            <Signuptest.Label placeholder="Which metric is most important to you?">Which metric is most important to you?</Signuptest.Label>
                            <Signuptest.DropDownHeader onClick={toggleDropdown} >
                                {chosenMetric || 'Which metric is most important to you?'}
                                <i className={isOpen ? 'fa fa-dropdown fa-angle-up' : 'fa fa-dropdown fa-angle-down'}></i>
                            </Signuptest.DropDownHeader>
                            <Signuptest.Tooltip style={{top:'29px', display: chosenMetricCheck ? 'block' : 'none'}}>Please select an option!</Signuptest.Tooltip>

                            {isOpen && (
                                <Content.Relative className={isOpen ? 'dropdown-content' : '' }>
                                    <Signuptest.DropDownListContainer>
                                        <Signuptest.DropDownList required>
                                            {metrics.map((metric, i) => (
                                                <Signuptest.ListItem
                                                    onClick={(e) =>
                                                        onOptionClicked(e, i, metric)
                                                    }
                                                    key={i}
                                                    value={metric}
                                                    onMouseOver={()=>setOver(i)} 
                                                    onMouseOut={()=>setOver(null)}
                                                >
                                                    {metric}
                                                    {over == i ? <img src={require("../../images/icon_tick_grey.svg").default} alt="icon" className="tick-icon" /> : ''}
                                                </Signuptest.ListItem>
                                            ))}
                                        </Signuptest.DropDownList>
                                    </Signuptest.DropDownListContainer>
                                </Content.Relative>
                            )}
                                
                        </Signuptest.DropDownContainer>
                        <Signuptest.InputGroup style={{ position: 'relative' }}>
                            <Signuptest.Label placeholder="Are you facing any specific challenges or have any relevant information to share? 
                                This information will help us improve your experience and tailor your demo.">
                                Are you facing any specific challenges or have any relevant information to share? 
                                This information will help us improve your experience and tailor your demo.
                            </Signuptest.Label>
                            <Signuptest.Tooltip style={{top:pageWidth<1837 ? '69px' : '50px', display: challengesToShareCheck ? 'block' : 'none'}}>Please compleate!</Signuptest.Tooltip>
                            <Signuptest.Textarea
                                placeholder="Are you facing any specific challenges or have any relevant information to share? This information will help us improve your experience and tailor your demo."
                                type="text"
                                onChange={(e) => {
                                    setChallengesToShare(e.target.value);
                                    setChallengesToShareCheck(false);
                                }}
                            />
                        </Signuptest.InputGroup>

                        <Signuptest.ButtonGroup>
                            <Signuptest.ButtonNext type="submit" >
                                Continue
                            </Signuptest.ButtonNext>
                        </Signuptest.ButtonGroup>
                    </form>
                </Signuptest.Frame>
                <Signuptest.FooterFrame>
                    © 2023 <a href="https://luminr.ai/">LUMINR</a>. | LUMINR Is Part Of <a href="https://tomorrowgroup.co/">Tomorrow Group Limited</a> | <a href="https://luminr.ai/cookie-policy/">Cookie Policy</a> | <a href="https://luminr.ai/privacy-policy/">Privacy Policy</a>
                </Signuptest.FooterFrame>
            </>
        }
            {children}
        </Signuptest>
    )
}
