import React from "react";
import Chart from "react-apexcharts";
import { Text, Content } from "../../../styled-components/visualization";

export function IndustryAverageRadialChartContainer({industryResult, variant}) {
    var options = {
        series: [(industryResult*100).toFixed(2)],
        chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true
        },
        toolbar: {
          show: true,
          tools: {
            download: '<img src='+require("../../../images/visualization/download.svg").default+' width="20">'
          }
        }
      }, 
      
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2
            },
            hollow: {
                size: '50%'
            },
            track: {
                show: true,
                startAngle: 0,
                endAngle: 20,
                 background: '#f2f2f2',
              strokeWidth: '97%',

            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '30px'
            }
          }
        }
      },
      grid: {
        padding: {
          top: -10
        }
      },

      xaxis: {
        max: 40,
        position: 'top',
        range: [0, 20]
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        },
        colors: ['#841E5A']
      },
    //   labels: ['Average CTR'],
      dataLabels: {
        enabled: true,
        style: {
            colors: ['#333']
        },
        offsetX: 30
      },
    };

  return (
    <Content.FlexColumn
        style={{
            position: 'relative',
            margin:'10px'
        }}
    >
        <Chart 
                options={options}
                series={options.series}
                type="radialBar"
                width="100%"
                height='400px'
        />
        
        <Content.FlexColumn
            style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '60%',
                marginLeft: '0px'
            }}
        >
        <Text.Regular18DarkBlackBorder
            style={{
                color: '#841E5A',
                textAlign: 'center',
                border: 'none'
            }}
        >
            {variant}
          </Text.Regular18DarkBlackBorder>
        </Content.FlexColumn>
    </Content.FlexColumn>
  )
}
