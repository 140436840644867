import React, { useState, useEffect } from "react";

import { Text, Content } from "../../../styled-components/visualization";
import {  Regular18Purple } from "../../../styled-components/visualization/text/styles/text";
import { HelpAssistantContainer } from ".././HelpAssitant";
import { InsightsContainer } from "../utilComponents/Insights";
import { TreeMapContainer } from ".././charts/treemap";

import { BackDrop, BackDropAbsolute } from "../../../styled-components/general/styles/general";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import { TitleContainer } from "../utilComponents/Title";
import ResultsMissing from "../../../components/ResultsMissing";


export function OverallCompositionContainer({serpCompositionResults}) {

    const [toggleComposition, setToggleComposition] = useState(false)
    const [toggleCompositionInsights, setToggleCompositionInsights] = useState(false)
 
    const [modalBackdrop, setModalBackdrop] = useState(false);


    var sorted = []

    const [arraySorted, setArraySorted] = useState([])
    useEffect(() => { 

      if(serpCompositionResults.length != 0){
          if(serpCompositionResults.detail == undefined){
            sorted = [...serpCompositionResults].sort((a, b) => {
              return b.percent_of_serps_with_feature - a.percent_of_serps_with_feature;
            });
          }
      }               
      
      setArraySorted(sorted)
      },
    [
     // arraySorted

    ])

    const csvHeader = [
      { label: "Component", key: "serp_feature" },
      { label: "Proportion of all search results", key: "percent_of_serps_with_feature" }
    ];

    const infoContainer = () => {
      return(
        <>
        {serpCompositionResults.length != 0 &&
       
         <Content.SerpCompositionTable
          style={{
            marginTop: '6%'
          }}
         >
            {arraySorted.map((result,i) => (
              <Content.FlexRow
                style={{
                  justifyContent:'space-between',
                  marginBottom: '14px',
                  marginRight: '3%'
                  
                }}
              >
                <Text.Regular13Grey>
                {result.serp_feature}
                </Text.Regular13Grey>
                <Text.Regular14Black>
                  {(result.percent_of_serps_with_feature*100).toFixed(2)}%
                </Text.Regular14Black>
              </Content.FlexRow>
            ))}
          </Content.SerpCompositionTable>
        }
          </>
      )
    }


  return (
        <Content.FlexColumn
          style={{
            width: '100%',
            background:'white',
            padding: '1%'
          }}
        >
          
          {modalBackdrop ? (
              <BackDropAbsolute 
                onClick={() => {
                  setToggleComposition(false);
                  setToggleCompositionInsights(false);
                  setModalBackdrop(false);
                }}
              />
            ) : (
              <BackDrop />
            )}
        {/* Title */}
          <TitleContainer
            title='SERP Feature Landscape'
            subtitle='Optimise your search strategy for the SERP features appearing most often for industry keywords.'
            pages={insightsText.OverallSERPComposition[0]}
            p11={insightsText.OverallSERPComposition[1]}
            p21={insightsText.OverallSERPComposition[2]}
            p31={insightsText.OverallSERPComposition[3]}
            p41={insightsText.OverallSERPComposition[4]}
            assistantMessage={assistantText.OverallSERPComposition}
            backdropTop='85vw'
            csvData={arraySorted}
            csvHeader={csvHeader}
            /> 
         
           {/* Chart */}
            
            <Content.FlexRow 
              style={{
                justifyContent: 'center',
                marginTop: '1%'
              }}
            >
              {serpCompositionResults.length != 0? 
                serpCompositionResults.detail !== undefined ? 
                    <ResultsMissing details = {serpCompositionResults.detail}/>
                    :
                    <>
                      <div style={{ width: '70%',marginRight:'5%' }}>
                        <TreeMapContainer serpCompositionResults={serpCompositionResults}/>
                      </div>
                      <Content.FlexColumn style={{  width:'25%' }}>
                        {infoContainer()}
                      </Content.FlexColumn>
                    </> 
                :
                <ResultsMissing details = {serpCompositionResults.detail}/>
            }
            </Content.FlexRow>
           </Content.FlexColumn>
  )
}
