export const READ_RANKINGS_REQUEST   = 'READ_RANKINGS_REQUEST'
export const READ_RANKINGS_SUCCESS   = 'READ_RANKINGS_SUCCESS'
export const READ_RANKINGS_FAIL      = 'READ_RANKINGS_FAIL'
export const READ_RANKINGS_RESET     = 'READ_RANKINGS_RESET'

export const GET_FILTERS_REQUEST   = 'GET_FILTERS_REQUEST'
export const GET_FILTERS_SUCCESS   = 'GET_FILTERS_SUCCESS'
export const GET_FILTERS_FAIL      = 'GET_FILTERS_FAIL'
export const GET_FILTERS_RESET     = 'GET_FILTERS_RESET'

export const READ_SRE_REQUEST   = 'READ_SRE_REQUEST'
export const READ_SRE_SUCCESS   = 'READ_SRE_SUCCESS'
export const READ_SRE_FAIL      = 'READ_SRE_FAIL'
export const READ_SRE_RESET     = 'READ_SRE_RESET'

export const RETRIEVE_SWOT_REQUEST   = 'RETRIEVE_SWOT_REQUEST'
export const RETRIEVE_SWOT_SUCCESS   = 'RETRIEVE_SWOT_SUCCESS'
export const RETRIEVE_SWOT_FAIL      = 'RETRIEVE_SWOT_FAIL'
export const RETRIEVE_SWOT_RESET     = 'RETRIEVE_SWOT_RESET'

export const CONTENT_EXPLORER_REQUEST   = 'CONTENT_EXPLORER_REQUEST'
export const CONTENT_EXPLORER_SUCCESS   = 'CONTENT_EXPLORER_SUCCESS'
export const CONTENT_EXPLORER_FAIL      = 'CONTENT_EXPLORER_FAIL'
export const CONTENT_EXPLORER_RESET     = 'CONTENT_EXPLORER_RESET'

export const SERP_LEAGUE_REQUEST   = 'SERP_LEAGUE_REQUEST'
export const SERP_LEAGUE_SUCCESS   = 'SERP_LEAGUE_SUCCESS'
export const SERP_LEAGUE_FAIL      = 'SERP_LEAGUE_FAIL'
export const SERP_LEAGUE_RESET     = 'SERP_LEAGUE_RESET'

export const INDUSTRY_BENCHMARKS_POWER_REQUEST   = 'INDUSTRY_BENCHMARKS_POWER_REQUEST'
export const INDUSTRY_BENCHMARKS_POWER_SUCCESS   = 'INDUSTRY_BENCHMARKS_POWER_SUCCESS'
export const INDUSTRY_BENCHMARKS_POWER_FAIL      = 'INDUSTRY_BENCHMARKS_POWER_FAIL'
export const INDUSTRY_BENCHMARKS_POWER_RESET     = 'INDUSTRY_BENCHMARKS_POWER_RESET'

export const INDUSTRY_BENCHMARKS_STATS_REQUEST   = 'INDUSTRY_BENCHMARKS_STATS_REQUEST'
export const INDUSTRY_BENCHMARKS_STATS_SUCCESS   = 'INDUSTRY_BENCHMARKS_STATS_SUCCESS'
export const INDUSTRY_BENCHMARKS_STATS_FAIL      = 'INDUSTRY_BENCHMARKS_STATS_FAIL'
export const INDUSTRY_BENCHMARKS_STATS_RESET     = 'INDUSTRY_BENCHMARKS_STATS_RESET'

export const SERP_COMPOSITION_REQUEST   = 'SERP_COMPOSITION_REQUEST'
export const SERP_COMPOSITION_SUCCESS   = 'SERP_COMPOSITION_SUCCESS'
export const SERP_COMPOSITION_FAIL      = 'SERP_COMPOSITION_FAIL'
export const SERP_COMPOSITION_RESET     = 'SERP_COMPOSITION_RESET'

export const SERP_COMPOSITION_INCLUDE_REQUEST   = 'SERP_COMPOSITION_INCLUDE_REQUEST'
export const SERP_COMPOSITION_INCLUDE_SUCCESS   = 'SERP_COMPOSITION_INCLUDE_SUCCESS'
export const SERP_COMPOSITION_INCLUDE_FAIL      = 'SERP_COMPOSITION_INCLUDE_FAIL'
export const SERP_COMPOSITION_INCLUDE_RESET     = 'SERP_COMPOSITION_INCLUDE_RESET'

export const SERP_COMPOSITION_FOR_BENCHMARKS_REQUEST   = 'SERP_COMPOSITION_FOR_BENCHMARKS_REQUEST'
export const SERP_COMPOSITION_FOR_BENCHMARKS_SUCCESS   = 'SERP_COMPOSITION_FOR_BENCHMARKS_SUCCESS'
export const SERP_COMPOSITION_FOR_BENCHMARKS_FAIL      = 'SERP_COMPOSITION_FOR_BENCHMARKS_FAIL'
export const SERP_COMPOSITION_FOR_BENCHMARKS_RESET     = 'SERP_COMPOSITION_FOR_BENCHMARKS_RESET'


export const SERP_REAL_ESTATE_SCORE_REQUEST   = 'SERP_REAL_ESTATE_SCORE_REQUEST'
export const SERP_REAL_ESTATE_SCORE_SUCCESS   = 'SERP_REAL_ESTATE_SCORE_SUCCESS'
export const SERP_REAL_ESTATE_SCORE_FAIL      = 'SERP_REAL_ESTATE_SCORE_FAIL'
export const SERP_REAL_ESTATE_SCORE_RESET     = 'SERP_REAL_ESTATE_SCORE_RESET'

export const COMPETITOR_SWOT_HEATMAP_REQUEST   = 'COMPETITOR_SWOT_HEATMAP_REQUEST'
export const COMPETITOR_SWOT_HEATMAP_SUCCESS   = 'COMPETITOR_SWOT_HEATMAP_SUCCESS'
export const COMPETITOR_SWOT_HEATMAP_FAIL      = 'COMPETITOR_SWOT_HEATMAP_FAIL'
export const COMPETITOR_SWOT_HEATMAP_RESET     = 'COMPETITOR_SWOT_HEATMAP_RESET'

export const COMPETITOR_SWOT_LANDING_PAGES_REQUEST   = 'COMPETITOR_SWOT_LANDING_PAGES_REQUEST'
export const COMPETITOR_SWOT_LANDING_PAGES_SUCCESS   = 'COMPETITOR_SWOT_LANDING_PAGES_SUCCESS'
export const COMPETITOR_SWOT_LANDING_PAGES_FAIL      = 'COMPETITOR_SWOT_LANDING_PAGES_FAIL'
export const COMPETITOR_SWOT_LANDING_PAGES_RESET     = 'COMPETITOR_SWOT_LANDING_PAGES_RESET'

export const HVN_SCORE_REQUEST   = 'HVN_SCORE_REQUEST'
export const HVN_SCORE_SUCCESS   = 'HVN_SCORE_SUCCESS'
export const HVN_SCORE_FAIL      = 'HVN_SCORE_FAIL'
export const HVN_SCORE_RESET     = 'HVN_SCORE_RESET'

export const TOTAL_SEARCH_CTR_REQUEST   = 'TOTAL_SEARCH_CTR_REQUEST'
export const TOTAL_SEARCH_CTR_SUCCESS   = 'TOTAL_SEARCH_CTR_SUCCESS'
export const TOTAL_SEARCH_CTR_FAIL      = 'TOTAL_SEARCH_CTR_FAIL'
export const TOTAL_SEARCH_CTR_RESET     = 'TOTAL_SEARCH_CTR_RESET'

export const BIG_KEYWORD_RANKING_REQUEST   = 'BIG_KEYWORD_RANKING_REQUEST'
export const BIG_KEYWORD_RANKING_SUCCESS   = 'BIG_KEYWORD_RANKING_SUCCESS'
export const BIG_KEYWORD_RANKING_FAIL      = 'BIG_KEYWORD_RANKING_FAIL'
export const BIG_KEYWORD_RANKING_RESET     = 'BIG_KEYWORD_RANKING_RESET'

export const COMPETITOR_LANDSCAPE_REQUEST   = 'COMPETITOR_LANDSCAPE_REQUEST'
export const COMPETITOR_LANDSCAPE_SUCCESS   = 'COMPETITOR_LANDSCAPE_SUCCESS'
export const COMPETITOR_LANDSCAPE_FAIL      = 'COMPETITOR_LANDSCAPE_FAIL'
export const COMPETITOR_LANDSCAPE_RESET     = 'COMPETITOR_LANDSCAPE_RESET'

export const FEATURED_SNIPPED_OPPORTUNITIES_REQUEST   = 'FEATURED_SNIPPED_OPPORTUNITIES_REQUEST'
export const FEATURED_SNIPPED_OPPORTUNITIES_SUCCESS   = 'FEATURED_SNIPPED_OPPORTUNITIES_SUCCESS'
export const FEATURED_SNIPPED_OPPORTUNITIES_FAIL      = 'FEATURED_SNIPPED_OPPORTUNITIES_FAIL'
export const FEATURED_SNIPPED_OPPORTUNITIES_RESET     = 'FEATURED_SNIPPED_OPPORTUNITIES_RESET'

export const MONTHLY_MOVEMENTS_REQUEST   = 'MONTHLY_MOVEMENTS_REQUEST'
export const MONTHLY_MOVEMENTS_SUCCESS   = 'MONTHLY_MOVEMENTS_SUCCESS'
export const MONTHLY_MOVEMENTS_FAIL      = 'MONTHLY_MOVEMENTS_FAIL'
export const MONTHLY_MOVEMENTS_RESET     = 'MONTHLY_MOVEMENTS_RESET'

export const MONTHLY_MOVEMENTS_NUMBER_REQUEST   = 'MONTHLY_MOVEMENTS_NUMBER_REQUEST'
export const MONTHLY_MOVEMENTS_NUMBER_SUCCESS   = 'MONTHLY_MOVEMENTS_NUMBER_SUCCESS'
export const MONTHLY_MOVEMENTS_NUMBER_FAIL      = 'MONTHLY_MOVEMENTS_NUMBER_FAIL'
export const MONTHLY_MOVEMENTS_NUMBER_RESET     = 'MONTHLY_MOVEMENTS_NUMBER_RESET'

export const INDUSTRY_BENCHMARKS_CHANNEL_REQUEST   = 'INDUSTRY_BENCHMARKS_CHANNEL_REQUEST'
export const INDUSTRY_BENCHMARKS_CHANNEL_SUCCESS   = 'INDUSTRY_BENCHMARKS_CHANNEL_SUCCESS'
export const INDUSTRY_BENCHMARKS_CHANNEL_FAIL      = 'INDUSTRY_BENCHMARKS_CHANNEL_FAIL'
export const INDUSTRY_BENCHMARKS_CHANNEL_RESET     = 'INDUSTRY_BENCHMARKS_CHANNEL_RESET'

export const TRACKING_SUMMARY_REQUEST   = 'TRACKING_SUMMARY_REQUEST'
export const TRACKING_SUMMARY_SUCCESS   = 'TRACKING_SUMMARY_SUCCESS'
export const TRACKING_SUMMARY_FAIL      = 'TRACKING_SUMMARY_FAIL'
export const TRACKING_SUMMARY_RESET     = 'TRACKING_SUMMARY_RESET'

export const COMPETITOR_CHANNEL_REQUEST = 'COMPETITOR_CHANNEL_REQUEST'
export const COMPETITOR_CHANNEL_SUCCESS = 'COMPETITOR_CHANNEL_SUCCESS'
export const COMPETITOR_CHANNEL_FAIL    = 'COMPETITOR_CHANNEL_FAIL'
export const COMPETITOR_CHANNEL_RESET   = 'COMPETITOR_CHANNEL_RESET'

export const SERP_MOCKUP_REQUEST = 'SERP_MOCKUP_REQUEST'
export const SERP_MOCKUP_SUCCESS = 'SERP_MOCKUP_SUCCESS'
export const SERP_MOCKUP_FAIL    = 'SERP_MOCKUP_FAIL'
export const SERP_MOCKUP_RESET   = 'SERP_MOCKUP_RESET'

export const WEBSITE_TRACKING_STATUS_REQUEST = 'WEBSITE_TRACKING_STATUS_REQUEST'
export const WEBSITE_TRACKING_STATUS_SUCCESS = 'WEBSITE_TRACKING_STATUS_SUCCESS'
export const WEBSITE_TRACKING_STATUS_FAIL    = 'WEBSITE_TRACKING_STATUS_FAIL'
export const WEBSITE_TRACKING_STATUS_RESET   = 'WEBSITE_TRACKING_STATUS_RESET'

export const SERPR_SCORE_100_REQUEST = 'SERPR_SCORE_100_REQUEST'
export const SERPR_SCORE_100_SUCCESS = 'SERPR_SCORE_100_SUCCESS'
export const SERPR_SCORE_100_FAIL    = 'SERPR_SCORE_100_FAIL'
export const SERPR_SCORE_100_RESET   = 'SERPR_SCORE_100_RESET'

export const SERPR_SCORE_REQUEST = 'SERPR_SCORE_REQUEST'
export const SERPR_SCORE_SUCCESS = 'SERPR_SCORE_SUCCESS'
export const SERPR_SCORE_FAIL    = 'SERPR_SCORE_FAIL'
export const SERPR_SCORE_RESET   = 'SERPR_SCORE_RESET'