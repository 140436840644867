import React, {  useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../../styled-components/dashboard/";
import { useHistory } from "react-router-dom";
import { Content } from "../../styled-components/visualization";

import {
  listOrganisationDetails,
} from "../../actions/organisationActions";
import { AccountMenuContainer } from "../visualization/utilComponents/AccountMenu";
import { useState } from "react";
import { BackDrop, BackDropAbsolute } from "../../styled-components/general/styles/general";
export function HeaderContainer({

  pageName,
  email,
  name,
  accessToken,


}) {
  const dispatch = useDispatch();
  let history = useHistory();

  const organisationDetails = useSelector((state) => state.organisationDetails);
  const [toggleAccountMenu, setToggleAccountMenu] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);



  const {
    //loading: loadingDetailsOrganisation,
    //error: errorDetailsOrganisation,
    success: successDetailsOrganisation,
    organisation,
  } = organisationDetails;

  useEffect(() => {
    if (name === undefined || name === '') {
      history.push("/profile-sign-up");
    } else {
      if (accessToken && email) {
        if (successDetailsOrganisation) {
          if (organisation.detail === "Organisation does not exist") {
            history.push("/create-organisation");
          }
        } else {
          dispatch(listOrganisationDetails(accessToken, email));
        }
      }
    }
  }, [
    history, 
    name,
    organisation,
    successDetailsOrganisation,
    accessToken,
    email,
    dispatch
  ]);

  return (
    <>
      <Header>
        <Header.SectionTop
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Content.FlexRow>
          {pageName === 'Home' ?
            <Header.PostHeaderText>Dashboards</Header.PostHeaderText> :
          <Header.PostHeaderText>{pageName}</Header.PostHeaderText>
        }
            
            
          </Content.FlexRow>
          <Content.FlexRow>
          {modalBackdrop ? (
            <BackDropAbsolute
              onClick={() => {
                setToggleAccountMenu(false);
                setModalBackdrop(false);
              }}
            />
          ) : (
            <BackDrop />
          )}
            {toggleAccountMenu ? (
              <div style={{position:'relative', top:'26px'}}>
                <Header.EmailContainer
                  onClick={() => setToggleAccountMenu(!toggleAccountMenu)}
                >
                  {name}
                  <img
                    src={require("../../images/icon_up_arrow_pink.svg").default}
                    alt="Icon up arrow"
                  />
                </Header.EmailContainer>
                <AccountMenuContainer active={true} dashboardStyle={true} />
              </div>
             
            ) : (
              <Header.EmailContainer
                onClick={() => {setToggleAccountMenu(!toggleAccountMenu);setModalBackdrop(true);}}
                style={{ backgroundColor: "#ffffff" }}
              >
                {name}

                <img
                  src={require("../../images/icon_down_arrow_pink.svg").default}
                  alt="Icon down arrow"
                />
              </Header.EmailContainer>
            )}
           {/* Guarded until notification system is added */}
            <Header.IconContainer>
              {/* <Header.Icon
                src={
                  require("../../images/dashboard/icon-notification-dash.svg")
                    .default
                }
              />
              <Header.NotificationNumber>0</Header.NotificationNumber>
              <Header.Icon
                src={
                  require("../../images/dashboard/icon-email-dash.svg").default
                }
              />
              <Header.NotificationNumber>0</Header.NotificationNumber> */}
            </Header.IconContainer>
          </Content.FlexRow>
        </Header.SectionTop>
        {/* <Header.SectionBottom>
        {pageName === 'Home' ?
            <Header.PostHeaderText>Dashboards</Header.PostHeaderText> :
          <Header.PostHeaderText>{pageName}</Header.PostHeaderText>
        }
        </Header.SectionBottom> */}
      </Header>
    </>
  );
}
