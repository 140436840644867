import styled from "styled-components";
import * as pallete from "../../../../constants/theme/visualization/colours";

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};

export const nFormatter = (num) => {
  if (num >= 1000000000000 || num <= -1000000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + " T";
  }
  if (num >= 1000000000 || num <= -1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + " B";
  }
  if (num >= 1000000 || num <= -1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + " M";
  }
  if (num >= 1000 || num <= -1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + " K";
  }
  if (num < 1000) {
    return num?.toFixed(2);
  }
  return num;
};

const monthNames = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December"
];
const dayOfWeekNames = [
  "Sunday", "Monday", "Tuesday",
  "Wednesday", "Thursday", "Friday", "Saturday"
];

export const padNumber = (number, prependChar, count)=> {
  var out = '' + number; var i;
  if (number < Math.pow(10, count))
    while (out.length < ('' + Math.pow(10, count)).length) out = prependChar + out;
  
  return out;
}
export const dateFormatter = (date,displayHours = false) =>{
  date = new Date(date)
  var months = [
    'jan.', 'feb.', 'mar.', 'apr.', 'may', 'jun.', 'jul.', 'aug.', 'sep.', 'oct.', 
    'nov.', 'dec.'
  ];

  if(displayHours){
    return '' + padNumber(date.getDate(), '0', 1) + 
    ' ' + months[date.getMonth()] + ' '
      + date.getFullYear() 
      +' ' 
      + '' + padNumber(date.getHours(), '0', 1) + ":" 
      + '' + padNumber(date.getMinutes(), '0', 1) + ":" 
      + '' + padNumber(date.getSeconds(), '0', 1) 
      ;
  }else{

  }
  return '' + padNumber(date.getDate(), '0', 1) + 
  ' ' + months[date.getMonth()] + ' '
    + date.getFullYear() 
    ;
}



// style
export const COLUMN_BLUE = "#364DF8";
export const COLUMN_LIGHT = "#F7F7F7";
export const COLUMN_EXTRA_RED = "#F66269";
export const COLUMN_MED_RED = "#FCD3D5";
export const COLUMN_LIGHT_RED = "#FDDDDF";
export const COLUMN_PINK = "#FEF3F3";
export const TABLE_HEAD = "#F3F3F3";

export const BackgroundTableItemBlue = styled.p`
  background-color: #841e5a;
  margin-bottom: 0px;
  color: white;
  font-size: 14px;
  height: 30px !important;
  line-height: 30px;
  text-align: center;
`;
export const BackgroundTableItemLight = styled.p`
  background-color: ${COLUMN_LIGHT};
  font-size: 14px;
  color: ${pallete.SERPR_BLACK};
  margin-bottom: 0px;
  height: 30px !important;
  line-height: 30px;
  text-align: center;
`;

export const SreRed = styled.p`
  font-size: 14px;
  background-color: ${COLUMN_EXTRA_RED};
  margin-bottom: 0px;
  height: 30px !important;
  line-height: 30px;
  text-align: center;
`;
export const SreMidRed = styled.p`
  font-size: 14px;
  background-color: #9c4a7a;
  margin-bottom: 0px;
  height: 30px !important;
  line-height: 30px;
  text-align: center;
`;
export const SreLightRed = styled.p`
  font-size: 14px;
  background-color: #caa1b9;
  margin-bottom: 0px;
  height: 30px !important;
  line-height: 30px;
  text-align: center;
`;
export const SrePink = styled.p`
  font-size: 14px;
  background-color: ${COLUMN_PINK};
  margin-bottom: 0px;
  height: 30px !important;
  line-height: 30px;
  text-align: center;
`;

export const TableHeadBold = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 0px;
  text-transform: initial;
  text-align: center;
`;
