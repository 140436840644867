import React from "react";
import {  Content } from "../../../../styled-components/visualization";

import { HorizontalPanelComponent } from "./HorizontalPanel";
import {
  GoogleImg,
  GoogleSearchImg,

  HorizontalLineGrey,
} from "./seprSummariesUtils";
import { VerticalPanelComponent } from "./VerticalPanel copy";

export const GoogleSummariesTable = (props) => {
  // const [numberOfPages, setNumberOfPages] = useState(
  //   Math.ceil(props.results.length / 100)
  // );
  // const [currentPage, setCurrentPage] = useState(1);

  // const nextPage = () => {
  //   if (currentPage < numberOfPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const previousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  return (
    <Content.FlexColumn
      style={{
        padding: "3%",
        background: "white",
        marginTop: "3%",
        height: "auto",
      }}
    >
      {/* google head */}
      <Content.FlexRow
        style={{
          width: "100%",
          padding: "1%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <GoogleImg
          src={require("../../../../utils/images/googleImg.png").default}
        />
        <GoogleSearchImg
          src={require("../../../../utils/images/googleSearchImg.PNG").default}
        />
      </Content.FlexRow>

      <HorizontalLineGrey />

      {/* first row */}
      <Content.FlexRow>
        <Content.FlexColumn style={{ width: "60%" }}>
          {props.results.map((row, i) => (
            <>{i < 2 ? <HorizontalPanelComponent row={row} /> : ""}</>
          ))}
        </Content.FlexColumn>

        <Content.FlexColumn
          style={{
            margin: "0px 2% 2% 2%",
            border: "1px solid rgb(222,222,222)",
            borderRadius: "10px",
            width: "40%",
            justifyContent: "center",
          }}
        >
          {props.results.map((row, i) => (
            <>{i === 2 ? <VerticalPanelComponent row={row} /> : ""}</>
          ))}
        </Content.FlexColumn>
      </Content.FlexRow>
      {/* second row */}
      <Content.FlexColumn>
        {props.results.map((row, i) => (
          <>{i > 2 ? <HorizontalPanelComponent row={row} /> : ""}</>
        ))}
      </Content.FlexColumn>
    </Content.FlexColumn>
  );
};
