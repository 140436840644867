import React, {useState} from "react";
import { Text} from "../../../styled-components/visualization";
import { FlexColumn, FlexRow } from "../../../styled-components/visualization/content/styles/content";
import { YourKeywordsContainer } from "../benchmarksComponents/YourKeywords";
import { YourKeywordsFromDbContainer } from "../benchmarksComponents/YourKeywordsFromDb";
import { dateFormatter } from "../resultsComponents/serpScoreboard/sortingUtils";
import { SectionsTitleContainer } from "./SectionsTitle";

export function SummaryContainer({trackingSummaryResults, websiteTrackingStatusResults}) {

  //line info

  const infoContent = (title, value) => {
    return(
        <FlexColumn
            style={{
                width: '45%',
                background: 'white',
                padding: '20px',
                minHeight: '150px',
                justifyContent: 'space-between'
            }}
        >
            <Text.Regular18Purple
                style={{
                    textAlign:'center'
                }}
            >{title}</Text.Regular18Purple>
            <Text.Bold30Black
                style={{
                    textAlign: 'center'
                }}
            >
                {value}
            </Text.Bold30Black>
        </FlexColumn>
    )
  }

  return (
    <>
    <SectionsTitleContainer 
        firstTitle='Your Dashboard'
        secondTitle='Summary'
        description='Here is an overview of your Luminr dashboard and keywords.'
    />
    <FlexRow
        style={{
            justifyContent: 'space-between',
            width: '100%'
        }}
    >
        <FlexColumn
            style={{
                width: '55%',
                justifyContent: 'space-between'
            }}
        >
            <FlexRow
                style={{
                    justifyContent: 'space-between'
                }}
            >
                {websiteTrackingStatusResults.status === 'ready'  ? infoContent(
                    'Google Searches performed so far', 
                    trackingSummaryResults[0].searches_performed_so_far.toLocaleString()
                ) : 
                infoContent(
                    'Google Searches performed so far', 
                    'no data'
                )
                }
                {websiteTrackingStatusResults.status === 'ready' ? infoContent(
                    'Your SERPs were last analysed on',
                    dateFormatter(trackingSummaryResults[0].last_analysis_date)
                    
                ) : 
                infoContent(
                    'Google Searches performed so far', 
                    'no data'
                )}
            </FlexRow>
            <FlexRow
                style={{
                        justifyContent: 'space-between'
                    }}
            >
                {websiteTrackingStatusResults.status === 'ready' ? infoContent(
                    'Website domains detected so far',
                    trackingSummaryResults[0].ranking_domains_discovered.toLocaleString()
                    
                ) : 
                infoContent(
                    'Google Searches performed so far', 
                    'no data'
                )}
                {websiteTrackingStatusResults.status === 'ready' ? infoContent(
                    'Your brand has been tracked since',
                    dateFormatter(trackingSummaryResults[0].tracking_date)
                ) : 
                infoContent(
                    'Google Searches performed so far', 
                    'no data'
                )}
            </FlexRow>
            <FlexRow
                style={{
                    justifyContent: 'space-between'
                }}
            >
                {websiteTrackingStatusResults.status === 'ready' ? infoContent(
                    'Markets being monitored',
                    trackingSummaryResults[0].markets
                
                ) : 
                infoContent(
                    'Google Searches performed so far', 
                    'no data'
                )}
                {infoContent(
                    'Devices being tracked',
                    1
                
                )}
            </FlexRow>
        </FlexColumn>
        <FlexColumn
        style={{
                width: '40%'
            }}
        >
        {websiteTrackingStatusResults.status === 'ready' ?
        <YourKeywordsContainer 
            keywords={trackingSummaryResults[0].keywords}
          />
          :
          <YourKeywordsFromDbContainer
            
          />
          }
        </FlexColumn>
    </FlexRow>

          </>
  )
}
