import React, {useState, useEffect} from "react";
import {emailRegex} from '../../../constants/regex/regex'
import { useHistory } from 'react-router-dom'
import { createProfile, listProfiles, updateProfileByAdmin } from '../../../actions/profileActions'
import { useSelector, useDispatch } from "react-redux";
import { Content, General, Grid, Button,  Text } from "../../../styled-components/dashboard";
import LoaderImage from "../../../components/LoaderImage";
import ErrorMessage from "../../../components/ErrorMessage";
import { FlexColumn, FlexRow } from "../../../styled-components/visualization/content/styles/content";
import { PROFILE_LIST_RESET, PROFILE_UPDATE_BY_ADMIN_RESET } from "../../../constants/profileConstants";


export function UserAdminContainer({isSuperuser, name, email, isActive, accessToken}) {
    let history = useHistory()
    const dispatch = useDispatch()

    const [userEmail, setUserEmail] = useState('')
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    const [numberOfPages, setNumberOfPages] = useState(0);
    const [notEmptyKeywordsWebsites, setNotEmptyKeywordsWebsites] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    
    const profileList = useSelector(state => state.profileList)
    const {
      loading: loadingProfileList, 
      error: errorProfileList, 
      success: successProfileList, 
      profiles: profiles
  } = profileList

  const profileUpdateByAdmin = useSelector(state => state.profileUpdateByAdmin)
    const {
      loading: loadingProfileUpdateByAdmin, 
      error: errorProfileUpdateByAdmin, 
      success: successProfileUpdateByAdmin, 
      profileUpdatedByAdmin: profileUpdatedByAdmin
  } = profileUpdateByAdmin

  const profileCreate = useSelector((state) => state.profileCreate)
    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
        profile: createdProfile,
    } = profileCreate

  function validateEmail(email) {
    if (emailRegex.test(email)) {
      setEmailIsValid(true);
      setEmailErrorMessage('');
    } else {
      setEmailIsValid(false);
      setEmailErrorMessage('Invalid email format');
    }
  }

  const addUserHandler = () => {
    const data = {
        name: name,
        email: email,
        userEmail: userEmail,
        handler: 'bySuperuser'
    }
    dispatch(createProfile(accessToken, data))
  }

    useEffect(() => {
        if (accessToken  ) {
            //if account is active
      
            if(isActive=== undefined && isSuperuser === undefined){
            } 
            else{
             if(isSuperuser){
                if(successProfileList){
                    if(successProfileUpdateByAdmin ){
                        dispatch({type: PROFILE_UPDATE_BY_ADMIN_RESET})
                        dispatch({type: PROFILE_LIST_RESET})
                    }

                } else 
                    if(!loadingProfileList)
                {
                    dispatch(listProfiles(accessToken, email))
                }
             } else{
                history.push(`/`)
             }
            }}

    }, [
        accessToken,
        email,
        isActive,
        isSuperuser,
        dispatch,
        successProfileList,
        successCreate,
        successProfileUpdateByAdmin
    ])

    const handleTickBoxActive = (id, userEmail, isActive, isSuperuser, isAdmin, isCompanyAdmin, isCompanyEmployee) => {
        const user = {
            isActive: !isActive,
            isSuperuser,
            isAdmin,
            isCompanyAdmin: !isCompanyAdmin,
            isCompanyEmployee,
            email: userEmail
          };
          //following if statement forbids to change your own status
          if(!(userEmail === email))
            dispatch(updateProfileByAdmin(accessToken, user));
    }

    const getFormattedDate = (timestamp) => {
        const dateObject = new Date(timestamp);
        const formattedTimestamp = dateObject.toISOString().slice(0, 16).replace('T', ' - ');
        return formattedTimestamp;
    }

    const sortedProfiles = profiles.slice().sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
    });

    const handleSearch = (event) => {
        const query = event.target.value;
        const filteredItems = profiles.filter((profile) => profile.name.toLowerCase().includes(query.toLowerCase()));
        setSearchQuery(query);
        setFilteredItems(filteredItems);   
    };

    const sortedFilteredItems = filteredItems.slice().sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
    });
    

    const profilesTablePart = (profile) => {
        return(
                    <General.TableRow
                    style={{
                        justifyContent: 'space-between',
                        maxWidth: '1000px',
                        }}
                    >
                    <FlexColumn
                        style={{
                            width: '15%'
                        }}
                        >
                        {profile.name ? 
                        <Text.Normal14Black>
                            {profile.name}
                        </Text.Normal14Black> :
                        <Text.Normal14Black
                            style={{
                                fontWeight: 'bold'
                            }}
                        >
                            Not accepted
                        </Text.Normal14Black>
                    }
                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '26%'
                        }}
                    >
                    <Text.Normal14Black>
                            {profile.userEmail}
                        </Text.Normal14Black>
                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '10%',
                            
                        }}
                    >
                       <Content.TickBox
                       onClick={()=>handleTickBoxActive(profile.id, profile.email, profile.isActive, profile.isSuperuser, profile.isAdmin, profile.isCompanyAdmin, profile.isCompanyEmployee)}
                       >
                        {profile.isActive &&
                        <Content.Icon
                            src={
                            require("../../../images/visualization/Tick_2.svg")
                                .default
                            }
                        />}
                       </Content.TickBox>
                        
                    </FlexColumn>
                    {/* <FlexColumn
                    style={{
                            width: '13%'
                        }}
                    >
                    <Content.TickBox
                        style={{
                            cursor: 'not-allowed'
                        }}
                    >
                    {profile.isSuperuser &&
                        <Content.Icon
                        style={{
                            cursor: 'not-allowed'
                        }}
                            src={
                            require("../../../images/visualization/Tick_2.svg")
                                .default
                            }
                        />}
                    </Content.TickBox>
                    </FlexColumn> */}

                    {/* <FlexRow
                    style={{
                            width: '16%'
                        }}
                    >
                    <Content.TickBox
                    style={{
                            cursor: 'not-allowed'
                        }}
                    >
                    {profile.isCompanyAdmin &&
                        <Content.Icon
                        style={{
                            cursor: 'not-allowed'
                        }}
                            src={
                            require("../../../images/visualization/Tick_2.svg")
                                .default
                            }
                        />}
                       </Content.TickBox>
                    </FlexRow> */}

                    <FlexColumn
                    style={{
                            width: '16%'
                        }}
                    >
                    <Text.Normal14Black>
                        {getFormattedDate(profile.createdAt)}
                        </Text.Normal14Black>
                    </FlexColumn>
                
                    </General.TableRow>
               
        )
    }

    const profilesTable = () => {
        return(
            <FlexColumn>
            <FlexRow
                    style={{
                        justifyContent: 'space-between',
                        maxWidth: '1000px',
                        marginLeft: '-10px',
                        padding: '10px',
                        alignItems:'center'
                    }}
                >
                    <FlexColumn
                        style={{
                            width: '15%'
                        }}
                    >
                        <Text.Bold16Black>
                            User Name
                        </Text.Bold16Black>
                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '26%'
                        }}
                    >
                        <Text.Bold16Black>
                            Email
                        </Text.Bold16Black>
                    </FlexColumn>
                    <FlexColumn
                    style={{
                            width: '10%'
                        }}
                    >
                        <Text.Bold16Black>
                           Active
                        </Text.Bold16Black>
                    </FlexColumn>
                    {/* <FlexColumn
                    style={{
                            width: '13%'
                        }}
                    >
                        <Text.Bold16Black>
                            Superuser
                        </Text.Bold16Black>
                    </FlexColumn> */}

                    {/* <FlexColumn
                    style={{
                            width: '16%'
                        }}
                    >
                        <Text.Bold16Black>
                            Company Admin
                        </Text.Bold16Black>
                    </FlexColumn> */}
                    <FlexColumn
                    style={{
                            width: '16%'
                        }}
                    >
                        <Text.Bold16Black>
                            Created At
                        </Text.Bold16Black>
                    </FlexColumn>
                </FlexRow>
                {searchQuery? sortedFilteredItems.map((profile) => (
                    profilesTablePart(profile)
                )) : sortedProfiles
                    .slice((currentPage - 1) * 10, currentPage * 10)
                    .map((profile) => (profilesTablePart(profile)
                ))}
            </FlexColumn>
        )
    }

    if(profiles !== undefined){
        if(profiles.length != 0 && numberOfPages == 0){
            setNumberOfPages( Math.ceil(profiles.length/ 10))
        }
    }
      
    const nextPage = () => {
        if (currentPage < numberOfPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const paginationLine = () =>{
        return (
            <div
            style ={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '3%',
                display: 'flex',
                flexDirection: 'row'
            }}
            >
                <img
                    style ={{cursor: 'pointer', visibility: currentPage == 1 ? 'hidden': 'initial'}}
                    onClick={()=>previousPage()}
                    src={require("../../../images/visualization/left_arrow.svg").default}
                />
                {currentPage !== numberOfPages ?
                    <Text.Normal14Black>{(currentPage-1)*10+1} - {currentPage*10} / {profiles.length}</Text.Normal14Black> :
                    <Text.Normal14Black>{(currentPage-1)*10+1} - {profiles.length} / {profiles.length}</Text.Normal14Black>
                }
                
                <img
                    style ={{cursor: 'pointer', visibility: currentPage == numberOfPages ? 'hidden': 'initial'}}
                    onClick={() =>nextPage()}
                    src={require("../../../images/visualization/right_arrow.svg").default}
                />
            </div>
        )
    }

  return (
    <Content>
        {loadingProfileList || !successProfileList ||
        loadingProfileUpdateByAdmin ?

        <LoaderImage /> :
        errorProfileList || errorProfileUpdateByAdmin ? 
        <ErrorMessage /> :
        <Content.SectionTop>
            <Grid.SectionFull>
                <Content.FlexColumn>
                    <Text.Bold26Pink  >
                      Invite a new client to Luminr Platform
                  </Text.Bold26Pink>
                  <Content.FlexRow
                    style={{
                        maxWidth: '500px',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Content.FlexColumn
                    style={{
                        width: '60%',
                        height: 'fit-content'
                    }}
                    >
                        <Text.Field14Black 
                        
                        placeholder="Enter user email"
                        type="text"
                        value={userEmail}
                        onChange={(e) => {
                        setUserEmail(e.target.value);
                        validateEmail(e.target.value);
                            }}
                        ></Text.Field14Black>
                    </Content.FlexColumn>
                    <Button.Save
                    style={{height: '40px'}}
                    onClick={()=>addUserHandler()}
                    disabled={!emailIsValid || userEmail===''}
                    >
                        Add User
                    </Button.Save>
                </Content.FlexRow>
                <span style={{ color: 'red' }}>{emailErrorMessage}</span>
                {createdProfile && createdProfile.detail && 
              <Text.Bold26Orange
                style={{
                    fontWeight: 'normal',
                    color: 'red',
                    marginTop: '1em'
                }}
            >
                {createdProfile.detail}
              </Text.Bold26Orange>
              }
              {createdProfile && createdProfile.userEmail &&
                <Text.Bold26Orange
                style={{
                    fontWeight: 'normal',
                    color: 'green',
                    marginTop: '1em'
                }}
            >
                New account was created for {createdProfile.userEmail}
              </Text.Bold26Orange>
              }
              <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{
                        position: 'absolute',
                        top: '107px',
                        right: '50px',
                        margin: '0',
                        height: '40px',
                        padding: '0 10px',
                        background: 'transparent',
                        border: '2px solid rgb(197, 24, 82)',
                        color: '#222222',
                        borderRadius: '4px'
                    }}
                />
                </Content.FlexColumn>
                {profiles[0] && 
                    profilesTable()
                }
                {searchQuery ? '' : paginationLine()}
            </Grid.SectionFull>
        </Content.SectionTop>

        }
    </Content>
  )
}

