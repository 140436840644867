import React from 'react'
import { FlexRow } from "../styled-components/visualization/content/styles/content";

function LoaderImage({height,width}) {
  const loaderHeight=  height || 'inherit';
  const loaderWidth=  width || '';

    return (
        <FlexRow
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: loaderHeight,
        width:loaderWidth
      }}
    >
      <img 
        style={{
          width: '100px'
        }}
      src={require("../images/dashboard/preloader-a.png").default} alt="Icon down arrow"/>
    </FlexRow>
    )
}

export default LoaderImage
