import React from "react"
import { Container, SectionTop, SectionBottom, Path1, Path2, 
    PostHeaderText, Icon, IconContainer, NotificationNumber, 
    EmailContainer
} from "./styles/header"

export default function Header({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Header.SectionTop = function HeaderSectionTop({children, ...restProps}){
    return <SectionTop {...restProps}>{children}</SectionTop>   
}

Header.SectionBottom = function HeaderSectionBottom({children, ...restProps}){
    return <SectionBottom {...restProps}>{children}</SectionBottom>   
}

Header.Path1 = function HeaderPath1({children, ...restProps}){
    return <Path1 {...restProps}>{children}</Path1>   
}

Header.Path2 = function HeaderPath2({children, ...restProps}){
    return <Path2 {...restProps}>{children}</Path2>   
}

Header.PostHeaderText = function HeaderPostHeaderText({children, ...restProps}){
    return <PostHeaderText {...restProps}>{children}</PostHeaderText>   
}

Header.IconContainer = function HeaderIconContainer({children, ...restProps}){
    return <IconContainer {...restProps}>{children}</IconContainer>   
}

Header.Icon = function HeaderIcon({children, ...restProps}){
    return <Icon {...restProps}>{children}</Icon>   
}

Header.EmailContainer = function HeaderEmailContainer({children, ...restProps}){
    return <EmailContainer {...restProps}>{children}</EmailContainer>   
}

Header.NotificationNumber = function HeaderNotificationNumber({children, ...restProps}){
    return <NotificationNumber {...restProps}>{children}</NotificationNumber>   
}