import React from 'react'

import { ImproveSerprSecondPageContainer } from '../../containers/signup/improveSerprSecondPage'

export default function ImproveSerprSecondPage({accessToken, name, email, isActive, isSuperuser}) {
    return <ImproveSerprSecondPageContainer 
    accessToken={accessToken} 
    name={name} 
    email={email} 
    isActive={isActive}
    isSuperuser={isSuperuser}
    />
}
