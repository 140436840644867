import React, {useState, useEffect} from "react";

import {Text, General, Button } from "../styled-components/dashboard";

import {
  BackDrop,
  BackDropAbsolute,
} from "../styled-components/general/styles/general";

function InformationNotFilled({informationFilled, setInformationFilled}) {

  const [modalBackdrop, setModalBackdrop] = useState(false);


  const cancel = () => {
    setInformationFilled(2)
    setModalBackdrop(false);
    
  }

  useEffect(() => {
    if(informationFilled ===1){
      setModalBackdrop(true)
    }
  }, [informationFilled])
  


    return (
        <>
        {modalBackdrop ? (
        <BackDropAbsolute
          style={{
            
          }}
          onClick={() =>cancel()}
        />
      ) : (
        <BackDrop style={{position: 'fixed'}}/>
      )}
        {informationFilled === 1 &&
        <General.Loader>
            {/* purple part */}
            <div style={{padding: '16px 26px'}}>
              <Text.Flex
                style={{
                    alignItems: 'center'
                }}
              >
                <Text.Bold26Orange
                  style= {{color: 'white', marginBottom: '0', marginRight: '20px', maxWidth:'70%'}}
                >
                  Information not complete
                </Text.Bold26Orange>
                <Button.CloseModal onClick={() =>cancel()}>x</Button.CloseModal>
              </Text.Flex>

            </div>


            {/* white part */}
            <div className="subtitleActionModal">
              <Text.Normal14Black
                style = {{color: 'black'}}
              > &bull; 
                Please fill all fields
              </Text.Normal14Black> 
            </div>
        </General.Loader>}
        </>
    )
}

export default InformationNotFilled
