import React from 'react'
import { Content, Text } from '../../styled-components/visualization';
import { Home } from '../../styled-components/admin-console';
import { General } from '../../styled-components';

function NoWorkingPage() {
  return (
    <Content.NoWorkingPageContainer>
    <Content.FlexRow
        style={{
            padding: '10%'
        }}
    >
        <div>
                
            <Text.Bold30Black>
             LUMINR platform does not support at this moment a lower width than 1200px.
            </Text.Bold30Black>

            <Home.RedLine></Home.RedLine>
            <Home.SmallText
                style = {{marginTop: '20px'}}
            >
                For any questions please contact:
            </Home.SmallText>
            <General.MailTo14Pink href="mailto:adrian.pavel@braidr.ai">adrian.pavel@braidr.ai </General.MailTo14Pink>
        </div>
        <Home.RightContainer>
            <Home.Img  src={require("../../images/serpr-geometry-1.png").default} alt="serp"/>
        </Home.RightContainer>
    </Content.FlexRow>
    </Content.NoWorkingPageContainer>
  )
}

export default NoWorkingPage