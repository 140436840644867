import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button, General  } from "../../styled-components/dashboard";
import { useHistory } from "react-router-dom"
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import UpdateConfirmed from "../../components/UpdateConfirmed";
import InformationNotFilled from "../../components/InformationNotFilled";
import LoaderImage from "../../components/LoaderImage";
import {COMPETITOR_CREATE_RESET} from '../../constants/competitorConstants'
import UniqueName from "../../components/UniqueName";
import { urlRegex} from '../../constants/regex/regex'
import { createCompetitor, listCompetitorDetails,deleteCompetitor } from "../../actions/competitorActions";
import { listWebsiteDetailsById } from "../../actions/websiteActions";
import { COMPETITOR_DELETE_RESET} from '../../constants/competitorConstants'
import Modal from 'react-modal';
import { FlexRow } from "../../styled-components/dashboard/content/styles/content";


export function AddCompetitorsContainer({ accessToken, email }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('competitors')+12, 100))

  const [informationFilled, setInformationFilled] = useState(0)

  const [updateToggle, setUpdateToggle] =useState(false)
  const [uniqueNameToggle, setUniqueNameToggle] =useState(false)

  const [competitorUrl, setCompetitorUrl] = useState('')

  const competitorCreate = useSelector(state => state.competitorCreate)
  const {loading: loadingCreateCompetitor, 
        error: errorCreateCompetitor, 
        success: successCreateCompetitor, 
        website: createdCompetitor
    } = competitorCreate

  const competitorDetails = useSelector(state => state.competitorDetails)
  const {loading: loadingDetailsCompetitor, 
      error: errorDetailsCompetitor, 
      success: successDetailsCompetitor, 
      competitor
  } = competitorDetails 

  const websiteDetailsById = useSelector(state => state.websiteDetailsById)
  const {loading: loadingDetailsByIdWebsite, 
      error: errorDetailsByIdWebsite, 
      success: successDetailsByIdWebsite, 
        website: detailsByIdWebsite
  } = websiteDetailsById

  const competitorDelete = useSelector(state => state.competitorDelete)
  const {loading: loadingDeleteCompetitor, 
      error: errorDeleteCompetitor, 
      success: successDeleteCompetitor, 
      competitor: competitorDeleteMessage
  } = competitorDelete

  const nextHandler = () => {
     history.push(`/add-keywords-profile/${websiteId}`)
     dispatch({type: COMPETITOR_CREATE_RESET}) 
  }


  const createCompetitorHandler = (e) => {
    e.preventDefault()
    if(competitorUrl === '') {
        setInformationFilled(1)
    } else {
        const competitor = {
            competitor_website: competitorUrl
        }
        dispatch(createCompetitor(accessToken, competitor, email, websiteId))
        setCompetitorUrl('')
    }
    setUpdateToggle(true)
  }

  const [urlIsValid, setUrlIsValid] = useState(true);
  const [urlErrorMessage, setUrlErrorMessage] = useState('');

//   const [nameIsValid, setNameIsValid] = useState(true);
//   const [nameErrorMessage, setNameErrorMessage] = useState('');

//   function validateName(name) {
//     if (nameRegex.test(name)) {
//       setNameIsValid(true);
//       setNameErrorMessage('');
//     } else {
//       setNameIsValid(false);
//       setNameErrorMessage('Invalid name format');
//     }
//   }

  function validateUrl(url) {
    if (urlRegex.test(url)) {
      setUrlIsValid(true);
      setUrlErrorMessage('');
    } else {
      setUrlIsValid(false);
      setUrlErrorMessage('Invalid url format');
    }
  }

  useEffect(() => {
    if (accessToken && email) {
        if(successDetailsCompetitor){ 
          }
          else{
            if(!loadingDetailsCompetitor){
                dispatch(listCompetitorDetails(accessToken, websiteId))
            }
          }
        if(successDetailsByIdWebsite){
        } else {
          if(!loadingDetailsByIdWebsite){
            dispatch(listWebsiteDetailsById(accessToken, websiteId, email))
          }
        }
        if(successDeleteCompetitor){
          dispatch({type: COMPETITOR_DELETE_RESET})
          dispatch(listCompetitorDetails(accessToken, websiteId))
          
        }
    }
  }, [
    accessToken,
    dispatch,
    successDetailsCompetitor,
    successDetailsByIdWebsite,
    successDeleteCompetitor,
    email
  ])


  //MODAL DELETE
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [competitorId, setCompetitorId] = React.useState('');
  const [competitorName, setCompetitorName] = React.useState('');

  const customStylesDelete = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '450px',
      padding: '3%'
    },
  };

  function openDeleteModal(c_name, c_id) {
    setCompetitorId(c_id) // we need to store the state, although it will always delete the laste element from list
    setCompetitorName(c_name)
    setDeleteModalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
  }
  const deleteCompetitorHandler = () => {
    setDeleteModalIsOpen(false);
    dispatch(deleteCompetitor(accessToken, competitorId))
  }

  const modalDelete = (c_id) => {
    return(
            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={closeDeleteModal}
                style={customStylesDelete}
                ariaHideApp={false}
              >
              <Text.Bold40Black>
                Are you sure you want to remove
                </Text.Bold40Black> <span> </span>
                <Text.Bold42Pink>
                {competitorName} 
                </Text.Bold42Pink> <span> </span>
                <Text.Bold40Black>
                from your competitors list?
                </Text.Bold40Black>
                <FlexRow
                style={{
                  justifyContent: 'space-between',
                  marginTop: '30px'
                }}
            >
                <Button.Back
                  style={{
                    margin: '0'
                  }}
                  onClick={closeDeleteModal}
                >
                  Cancel
                </Button.Back>
                <Button.Save
                  onClick={()=>deleteCompetitorHandler()}
                >
                  Delete
                </Button.Save>

            </FlexRow>
            </Modal>
    )
  }

  const listOfCompetitors = () => {
    return(
        <Content.FlexColumn>
            {competitor.map((c)=>(
                <Content.FlexRow
                style={{
                            marginTop: '15px'
                        }}
                >
                    <Text.Field14Black
                        
                        value={c.competitor_website}
                        disabled
                    >
                    </Text.Field14Black>
                    <div
                        style={{
                            width: '40px',
                            background: '#d4d4d4',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderTopRightRadius: '3px',
                            borderBottomRightRadius: '3px',
                            paddingLeft: '14px'
                        }}
                        onClick={(e)=>openDeleteModal(c.competitor_website, c.id)}
                    >
                        <p
                            style={{
                                display: 'block',
                                color: 'white',
                                margin: '0',
                       
                               
                                
                            }}
                        >x</p>
                    </div>
                    {modalDelete(c.id)}
                </Content.FlexRow>
                
            ))}
        </Content.FlexColumn>
    )
  }


  return(
    <>
      <Content>
        {loadingCreateCompetitor || loadingDetailsCompetitor  || loadingDetailsByIdWebsite || loadingDeleteCompetitor ?
        <LoaderImage /> :
        errorCreateCompetitor || errorDetailsCompetitor || errorDetailsByIdWebsite || errorDeleteCompetitor ?
        <ErrorMessage /> :
        <>
        
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold40Black>
                Add competitors to your <span> </span>
              </Text.Bold40Black>
              <Text.Bold42Pink
                style={{
                  wordBreak: 'break-all',
                }}
              >
              {detailsByIdWebsite.website}<span> </span>
              </Text.Bold42Pink>
              <Text.Bold40Black>
                website
              </Text.Bold40Black>
              <Text.Normal14Black
              style={{marginTop: '26px'}}>
              One of Luminr’s main features is the competitor intelligence and tracking offered across your dashboard. 

              </Text.Normal14Black>
              <Text.Normal14Black
              style={{marginTop: '15px'}}
              >
               
                Providing your <span style={{color: '#C51852',  fontWeight: 'bold'}}>Competitor Details</span> ensures we are always tracking your commercial competitors regardless where they are appearing in the SERPs. 
              </Text.Normal14Black>
                
            </Grid.Section>       
            <Grid.Section>
              <Text.Bold26Pink>
                Competitor Details
              </Text.Bold26Pink>
              <Text.Normal14Black>
                To add competitors please enter the Competitor Name and Competitor URL.
              </Text.Normal14Black>
              <Text.Normal14Black
                style={{
                    marginTop: '10px'
                }}
              >
                You can add up to 10 and edit them at anytime from your profile. 
              </Text.Normal14Black>
              {/* <Text.Bold16Black>
                Competitor Name
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Competitor name"
                type="text"
                value={competitorName}
                onChange={(e) => {
                  setCompetitorName(e.target.value);
                  validateName(e.target.value);
                }}
              ></Text.Field14Black> */}
              <Text.Bold16Black>
                Competitor Website URL
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="ex: website.co.uk"
                type="text"
                value={competitorUrl}
                onChange={(e) => {
                  setCompetitorUrl(e.target.value);
                  validateUrl(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{urlErrorMessage}</span>
              <Content.FlexRow
                style={{
                    justifyContent: 'flex-end',
                    marginTop: '15px'
                }}
              >
              <Button.Back
              disabled={!urlIsValid}
              onClick={createCompetitorHandler}
              >
                Add
              </Button.Back>
              </Content.FlexRow>
              {listOfCompetitors()}
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          {loadingCreateCompetitor ?
            <Loader /> :
      
         
          <Content.ButtonsContainer>
            
            {/* <Button.Back
            onClick={redirectCompaniesHandler}
            >
            Back
          </Button.Back> */}
          {errorCreateCompetitor && <UniqueName uniqueNameToggle={uniqueNameToggle} setUniqueNameToggle={setUniqueNameToggle} />} 
          {successCreateCompetitor && !errorCreateCompetitor && <UpdateConfirmed updateToggle={updateToggle} setUpdateToggle={setUpdateToggle}/>}
          {informationFilled === 1 && <InformationNotFilled informationFilled={informationFilled} setInformationFilled={setInformationFilled}/>}
            <Button.Save 
              onClick={nextHandler}
              >
                  Next
            </Button.Save> 
           
          
          </Content.ButtonsContainer>
        }
        </Content.SectionBottom>
        </>
      }
      </Content>
      </>
  )
}
