import React, { useState } from 'react'
import { Signuptest } from '../styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '../styled-components/dashboard/'
import { sendEmailVerificationByEmail } from '../actions/emailSenderActions'
import { useDispatch } from 'react-redux'

export default function VerifyUsersEmail({accessToken}) {
    const { user, logout } = useAuth0()
    const dispatch = useDispatch()

    const [emailSent, setEmailSent] = useState('none');

    const sendEmailVerificationByEmailHandler = async (e) => {
        e.preventDefault()
        setEmailSent("block");
        dispatch(sendEmailVerificationByEmail(accessToken, user.email))   
    }

    return (
        <Signuptest
        >
            <Button.Menu onClick={() => logout()} style={{
                position: 'absolute',
                right: '20px',
                width: '120px',
                zIndex: '99',
            }}>
                <Button.MenuBackground>
                    <Button.MenuImg
                        src={
                            require('../images/dashboard/left-arrow-menu-button.svg')
                                .default
                        }
                    />
                </Button.MenuBackground>
                Logout
            </Button.Menu>
            <Signuptest.Frame style={{
                padding: '100px 0 0 0',
                minHeight: '0vh',
            }}>
                <Signuptest.Logo
                    //  to={ROUTES.HOME}
                    alt="Logo"
                    src={require('../images/luminr-logo.svg').default}
                />
                <Signuptest.Title
                    style={{
                        width: '40%',
                        margin: '1em 0',
                    }}
                >
                    Verify your email
                </Signuptest.Title>

                <Signuptest.Text>
                    You’re almost there! 
                </Signuptest.Text>
                <Signuptest.Text
                    style={{
                        margin: '0 30%',
                    }}
                >
                    We have sent you an email to verify your account. Please confirm your email to start using Luminr.
                </Signuptest.Text>
                <Signuptest.Text
                    style={{
                        margin: '0 25%',
                        borderTop: '1px solid #22222240',
                        width: '50%',
                    }}
                ></Signuptest.Text>
                <Signuptest.Text
                    style={{
                        margin: '0 25%',
                        color: '#22222260',
                    }}
                >
                    If you did not receive an email from support@luminr.ai{' '}
                    <a
                        onClick={sendEmailVerificationByEmailHandler}
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: '#22222260',
                        }}
                    >
                        click here to resend.
                    </a>
                </Signuptest.Text>
                <Signuptest.Text
                    style={{
                        margin: '0 30%',
                        color: '#22222260',
                        display: emailSent,
                    }}
                >
                    A new email is coming!
                </Signuptest.Text>
            </Signuptest.Frame>
        </Signuptest>
    )
}
