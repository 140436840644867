import React, {useEffect} from "react";

import {Text, General, Button } from "../styled-components/dashboard";

function UpdateConfirmed({updateToggle, setUpdateToggle, title='', subtitle=''}) {


  const cancel = () => {
    setUpdateToggle(!updateToggle)
  }

  useEffect(() => {

  }, [updateToggle])

    return (
        <>

        {updateToggle &&
        <General.Loader>
              {/* purple part */}
            <div style={{padding: '16px 26px'}}>
              <Text.Flex
                style={{
                    alignItems: 'center'
                }}
              >
                <Text.Bold26Orange
                  style= {{color: 'white', marginBottom: '0', marginRight: '20px', maxWidth:'70%'}}
                >
                  {title ? title : 'Update Confirmed'}
                </Text.Bold26Orange>
                <Button.CloseModal onClick={() =>cancel()}>x</Button.CloseModal>
              </Text.Flex>

            </div>


            {/* white part */}
            <div className="subtitleActionModal">
              <Text.Normal14Black
                style = {{color: 'black'}}
              > &bull;&nbsp; {subtitle ? subtitle : 'Information has been updated and saved'}
              </Text.Normal14Black> 
            </div>
        </General.Loader>}
        </>
    )
}

export default UpdateConfirmed
