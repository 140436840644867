import React, { useState,useEffect } from "react";
import { Text, Content } from "../../../../styled-components/visualization";
import { CounterContainer } from "../../utilComponents/Counter";
import {nFormatter} from "./competitorUtils";
import { COLOR_PALETTE_1, COLOR_PALETTE_2, COLOR_PALETTE_3, COLOR_PALETTE_4, COLOR_PALETTE_5, COLOR_PALETTE_6, COLOR_PALETTE_7, COLOR_PALETTE_8 } from "../../../../constants/theme/colours";


export const CompetitorSwotTable = (props) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [colorIntervals, setColorIntervals] = useState([])

  if(props.rows.length != 0 && numberOfPages == 0){
    setNumberOfPages( Math.ceil(props.rows.length/ 15))
  }
  
  const [currentPage, setCurrentPage] = useState(1);
  const nextPage = () => {
    if (currentPage < numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const paginationLine = () =>{
    return (
        <Content.FlexRow
        style ={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '3%'
        }}
     >
     <Content.PaginationIcon
     onClick={()=>previousPage()}
     src={require("../../../../images/visualization/left_arrow.svg").default}/>
     {currentPage !== numberOfPages ?
      <Text.Regular13Grey>{(currentPage-1)*15+1} - {currentPage*15} / {props.rows.length}</Text.Regular13Grey> :
      <Text.Regular13Grey>{(currentPage-1)*15+1} - {props.rows.length} / {props.rows.length}</Text.Regular13Grey>
     }
     
     <Content.PaginationIcon
    onClick={() =>nextPage()}
     src={require("../../../../images/visualization/right_arrow.svg").default}/>
     </Content.FlexRow>
    )
  }
  

  const handleExtend = (e)=>{
    props.setExtendParent(!props.extendParent);
    props.setExtendChild(true);
  }
  const handleExtendChild = (e)=>{
    props.setExtendChild(!props.extendChild);
  }
  const uniqueP = [];
  const singleParent = (e,j)=> {
      if(uniqueP.indexOf(e) === -1) {
          uniqueP.push(e)
          return (
            <Text.Regular14Black style={{minWidth:'170px',maxWidth:'171px', textAlign: 'center'}}>{e}</Text.Regular14Black> 
              )
      }else{
          return (
            <Text.Regular14Black style={{minWidth:'170px',maxWidth:'171px', textAlign: 'center'}}></Text.Regular14Black> 
              )
      }
  }
  useEffect (()=>{
    let maxValue = 0;
    props.rows.map((row, i) => {
      row.map((value, j) => {
        if (typeof row[j] == "number") {
          if(row[j] > maxValue) maxValue = row[j];
        }
      })
    })
    createMaxValueIntervals(maxValue);

    if(props.rows.length != 0 ){
      setNumberOfPages( Math.ceil(props.rows.length/ 15))
    }
  },[props.rows])

  const createMaxValueIntervals = (maxVal) =>{
    // Calculate the size of each interval
     const intervalSize = maxVal / 8;

     // Create the intervals with colors
     const intervals = [];
     for (let i = 1; i <= 8; i++) { // 8 intervals
     const intervalStart = intervalSize * (i - 1);
     const intervalEnd = intervalSize * i;
     let color;
     switch (i) {
         case 1:
         color = COLOR_PALETTE_1;
         break;
         case 2:
         color = COLOR_PALETTE_2;
         break;
         case 3:
         color = COLOR_PALETTE_3;
         break;
         case 4:
         color = COLOR_PALETTE_4;
         break;
         case 5:
         color = COLOR_PALETTE_5;
         break;
         case 6:
         color = COLOR_PALETTE_6;
         break;
         case 7:
         color = COLOR_PALETTE_7;
         break;
         case 8:
         color = COLOR_PALETTE_8;
         break;
         default:
         color = 'black'; // or any default color
     }
     intervals.push({ start: intervalStart, end: intervalEnd, color });
     }

     setColorIntervals(intervals);
    }

  return (
    <Content.FlexColumn style={{
      width: '100%',
      display:'grid',
      overflow:'auto'
      }}
      >
      {/* table head */}
      {/* table metric  */}
      <Content.FlexRow style={{
          width: '99%',
          marginTop: "2%",
          padding:'8px',
          justifyContent:'end',
          backgroundColor:'#F7F7F7'
          }}>
            <Text.Regular14Black style={{fontSize:'12px',fontWeight:'bold'}}> Company / Avg. Total Search CTR</Text.Regular14Black>
            
          </Content.FlexRow>
      
      <Content.FlexRow
        style={{
          justifyContent: "start",
          paddingTop:'5px'
        }}
      >
        <Text.Regular13Grey style={{minWidth:'20px', textAlign:'center'}}>  </Text.Regular13Grey>
        <Content.FlexColumn 
        // className={"freezedColumn1"}
          style={{
            width: "170px",
          }}
        >
          <Text.Regular13Grey style={{minWidth:'170px', textAlign:'center'}}>   
            <Content.TickBox style={{cursor:'pointer', display:'inline-flex',width:'20px',fontSize:'15px'}}
              onClick={()=>handleExtend()}>
                {props.extendParent ? '+' : '-'} 
              </Content.TickBox> Keyword Parent
          </Text.Regular13Grey>
        </Content.FlexColumn>

        {props.columns.map((col1,k)=>( 
                k==0 && !props.extendParent ?  
                <Content.FlexColumn 
                // className={"freezedColumn2"}
                  style={{
                    width: "130px",
                    textAlign: "left",
                  }}
                >
                  <Text.Regular13Grey style={{textAlign:'left'}}>   
                    <Content.TickBox style={{cursor:'pointer', display:'inline-flex',width:'20px',fontSize:'15px'}}
                      onClick={()=>handleExtendChild()}>
                        {props.extendChild ? '+' : '-'} 
                      </Content.TickBox> {col1}
                  </Text.Regular13Grey>
                </Content.FlexColumn>
                
                :

                <Content.FlexColumn
                  style={{
                    width: "100px",
                    wordBreak: 'break-word'
                  }}
                >
                  <Text.Regular13Grey style={{minWidth:'100px', textAlign: 'center',margin:'1px'}}>{col1}</Text.Regular13Grey>
                </Content.FlexColumn>  
          ))}

      </Content.FlexRow>
      {/* end table head */}

      {/* table body */}
      <Content.DropDownContainer      
          style={{
            height: '350px',
            overflowX:'hidden'
          }}
      >
        {props.rows.map((row, i) => (
          ((i) >= (currentPage-1)*15 && (i)<currentPage*15) ? 
          <Content.HoverRow
          style={{justifyContent:'start'}}
          className={props.selectedTopic != '' ? 
              (props.selectedTopic[0] != row[0] || (props.selectedTopic[1] && props.selectedTopic[1] != row[1]) ? 'inactiveRow' : 'selectedRow') : ''}
                    onClick={() => 
                      {
                        if(props.selectedTopic[0] != row[0]){
                          props.setSelectedTopic([row[0]])
                        }else{
                          props.setSelectedTopic([]) ;
                          props.setSelectedCompetitor([])
                        }
                         //ar trebui golit doar daca si competitor!= de cel selectat deja
                        if(row[1].length > 1 && typeof row[1] == 'string'){
                          props.selectedTopic[1] != row[1] ? props.setSelectedTopic([row[0],row[1]]) : props.setSelectedTopic([])
                        }
                        
                      }
                    }
          >
          <Content.FlexColumn style={{ width: '20px' }}> 
            <CounterContainer number={i+1} />
            
          </Content.FlexColumn>

          {row.map((val,j)=>(
            j==0 ?  
            <Content.FlexColumn
              style={{
                width: "170px",
                textAlign: "center",
                cursor:'pointer'
              }}
            >
              {singleParent(row[j],j)}
            </Content.FlexColumn>
            
            :
            !props.extendParent && j==1 ?
            <Content.FlexColumn 
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              <Text.Regular14Black style={{minWidth:'130px',maxWidth:'100px', textAlign: 'center', cursor:'pointer'}}>{row[j]}</Text.Regular14Black>
            </Content.FlexColumn>
            :
            !props.extendParent && !props.extendChild && j==2 ?
            <Content.FlexColumn
              style={{
                width: "100px",
                textAlign: "center",
              }}
            >
              <Text.Regular14Black style={{minWidth:'100px',maxWidth:'100px', textAlign: 'center' , cursor:'pointer'}}>{row[j]}</Text.Regular14Black>
            </Content.FlexColumn>
            :

            <Content.FlexColumn
              style={{
                width: "100px",
                cursor:'pointer'
              }}
              onClick={()=>{
                if(props.columns[j-1] != undefined){
                  props.setSelectedCompetitor(props.columns[j-1])
                }else{
                  props.setSelectedCompetitor('')
                }
              }}
            >
                {
                typeof row[j] == "number" ?

                  colorIntervals.map((range, i) => (
                    row[j] > range.start && row[j] <= range.end && 
                    <Text.Regular14WhitePurpleLightBackground style={{width: "98px",margin:'1px',background:range.color}}>
                        {nFormatter(row[j])}
                    </Text.Regular14WhitePurpleLightBackground> 
                  ))

              
                
                
                :
                <Text.Regular14Black style={{minWidth:'98px', textAlign: 'center', margin:'1px'}}>-</Text.Regular14Black>
                }
            </Content.FlexColumn>  

          ))}

          </Content.HoverRow>
            :null
        ))}
        
      </Content.DropDownContainer>
      {paginationLine()}
     
    </Content.FlexColumn>
  );
};
