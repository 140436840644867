import { 
    PROFILE_LIST_REQUEST,
    PROFILE_LIST_SUCCESS,
    PROFILE_LIST_FAIL,
    PROFILE_LIST_RESET,

    PROFILE_DETAILS_REQUEST,
    PROFILE_DETAILS_SUCCESS,
    PROFILE_DETAILS_FAIL,
    PROFILE_DETAILS_RESET,

    PROFILE_DETAILS_BY_ORGANISATION_EMAIL_REQUEST,
    PROFILE_DETAILS_BY_ORGANISATION_EMAIL_SUCCESS,
    PROFILE_DETAILS_BY_ORGANISATION_EMAIL_FAIL,
    PROFILE_DETAILS_BY_ORGANISATION_EMAIL_RESET,


    PROFILE_CREATE_REQUEST,
    PROFILE_CREATE_SUCCESS,
    PROFILE_CREATE_FAIL,
    PROFILE_CREATE_RESET,

    PROFILE_UPDATE_REQUEST,
    PROFILE_UPDATE_SUCCESS,
    PROFILE_UPDATE_FAIL,
    PROFILE_UPDATE_RESET,
    
    PROFILE_RIGHTS_UPDATE_REQUEST,
    PROFILE_RIGHTS_UPDATE_SUCCESS,
    PROFILE_RIGHTS_UPDATE_FAIL,
    PROFILE_RIGHTS_UPDATE_RESET,

    PROFILE_UPDATE_BY_ADMIN_REQUEST,
    PROFILE_UPDATE_BY_ADMIN_SUCCESS,
    PROFILE_UPDATE_BY_ADMIN_FAIL,
    PROFILE_UPDATE_BY_ADMIN_RESET,
 } from '../constants/profileConstants'


export const profileListReducer =  (state = {profiles:[]}, action) => {
    switch(action.type) {
        case PROFILE_LIST_REQUEST:
            return {loading: true, profiles:[]}

        case PROFILE_LIST_SUCCESS:
            return {success: true, loading: false, profiles: action.payload}

        case PROFILE_LIST_FAIL:
            return {loading: false, error: action.payload }
        
        case PROFILE_LIST_RESET: 
            return { profiles: [], loading:false, success: false}

        default:
            return state
    }
}

export const profileDetailsReducer =  (state = {profile: {}}, action) => {
    switch(action.type) {
        case PROFILE_DETAILS_REQUEST:
            return {loading: true, ...state}

        case PROFILE_DETAILS_SUCCESS:
            return {success: true, loading: false, profile: action.payload}

        case PROFILE_DETAILS_FAIL:
            return {loading: false, error: action.payload }

        case PROFILE_DETAILS_RESET: 
            return {success: false, loading: false}


        default:
            return state
    }
}

export const profileDetailsByOrganisationEmailReducer =  (state = {profile: []}, action) => {
    switch(action.type) {
        case PROFILE_DETAILS_BY_ORGANISATION_EMAIL_REQUEST:
            return {loading: true, ...state}

        case PROFILE_DETAILS_BY_ORGANISATION_EMAIL_SUCCESS:
            return {success: true, loading: false, profiles: action.payload}

        case PROFILE_DETAILS_BY_ORGANISATION_EMAIL_FAIL:
            return {loading: false, error: action.payload }

        case PROFILE_DETAILS_BY_ORGANISATION_EMAIL_RESET: 
            return {profiles: [], success: false, loading: false}


        default:
            return state
    }
}

export const profileCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case PROFILE_CREATE_REQUEST: 
            return {loading:true}

        case PROFILE_CREATE_SUCCESS: 
            return {loading:false, success: true, profile: action.payload}
        
        case PROFILE_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case PROFILE_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const profileUpdateReducer = ( state = { profile: {} }, action ) => {
    switch(action.type){
        case PROFILE_UPDATE_REQUEST: 
            return {loading:true}

        case PROFILE_UPDATE_SUCCESS: 
            return {loading:false, success: true, profile: action.payload}
        
        case PROFILE_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case PROFILE_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}

export const profileRightsUpdateReducer = ( state = { profile: {} }, action ) => {
    switch(action.type){
        case PROFILE_RIGHTS_UPDATE_REQUEST: 
            return {loading:true}

        case PROFILE_RIGHTS_UPDATE_SUCCESS: 
            return {loading:false, success: true, profile: action.payload}
        
        case PROFILE_RIGHTS_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case PROFILE_RIGHTS_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}


export const profileUpdateByAdminReducer = ( state = { profileUpdatedByAdmin: {} }, action ) => {
    switch(action.type){
        case PROFILE_UPDATE_BY_ADMIN_REQUEST: 
            return {loading:true}

        case PROFILE_UPDATE_BY_ADMIN_SUCCESS: 
            return {loading:false, success: true, profileUpdatedByAdmin: action.payload}
        
        case PROFILE_UPDATE_BY_ADMIN_FAIL: 
            return {loading:false, error: action.payload}

        case PROFILE_UPDATE_BY_ADMIN_RESET: 
            return { profileUpdatedByAdmin: {}, Loading:false, success: false}

        default: 
            return state
    }
}