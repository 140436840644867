import styled from 'styled-components'
import * as pallete from '../../../../constants/theme/colours'

export const StyledTable = styled.table`
    margin: 22px 0;
`

export const THead = styled.thead``

export const TBody = styled.tbody`
    font-size: 14px;
    // font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    text-align: left;
`

export const TR = styled.tr`
    &:hover {
        background: #F6F6F6;
        box-shadow: inset 3px 0px ${pallete.PINK};
    }
`

export const TRHead = styled.tr`
`

export const TH = styled.th`
    padding: 0 0 6.5px;
    width: 10%;
    font-size: 16px;
    font-family: tenon,sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    
    &:first-child {
          
        width: 1.5%;
    }
`

export const TD = styled.td` 
    padding: 6.5px 0;
`

export const IconLink = styled.img`
    padding-right: 12px;
`
