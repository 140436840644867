import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button, General } from "../../styled-components/dashboard";
import {  industries } from '../../constants/utils'
import { useHistory } from "react-router-dom"
import { listCompanyDetailsById, updateCompany } from "../../actions/companyActions";
import { listWebsiteDetails } from "../../actions/websiteActions";
import { FlexColumn, FlexRow } from "../../styled-components/visualization/content/styles/content";
import { WEBSITE_DETAILS_BY_ID_RESET} from '../../constants/websiteConstants'
import { COMPETITOR_DETAILS_RESET} from '../../constants/competitorConstants'
import { KEYWORD_DETAILS_RESET, KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET} from '../../constants/keywordConstants'
import AddNewButton from "../../components/AddNewButton";
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import Select from "../../components/Select";
import {phoneNumberRegex, nameRegex, addressRegex} from '../../constants/regex/regex'

export function EditCompanyContainer({ accessToken, name, email }) {
  const dispatch = useDispatch() 
  const history = useHistory()
  const redirectCompaniesHandler = (e) => {
    history.push(`/companies`)
} 
  const [companyId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('company')+8, 100))
  
  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyTelephone, setCompanyTelephone] = useState('')
  const [companyVatNumber, setCompanyVatNumber] = useState('')

  const [industry, setIndustry] = useState('')

  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const [addressIsValid, setAddressIsValid] = useState(true);
  const [addressErrorMessage, setAddressErrorMessage] = useState('');

  const [nameIsValid, setNameIsValid] = useState(true);
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const [vatNumberIsValid, setVatNumberIsValid] = useState(true);
  const [vatNumberErrorMessage, setVatNumberErrorMessage] = useState('');
  

  const companyDetailsById = useSelector(state => state.companyDetailsById)
    const {loading: loadingDetailsByIdCompany, 
        error: errorDetailsByIdCompany, 
        success: successDetailsByIdCompany, 
         company
    } = companyDetailsById

    const companyUpdate = useSelector(state => state.companyUpdate)
    const {loading: loadingUpdateCompany, 
        error: errorUpdateCompany, 
        success: successUpdateCompany, 

    } = companyUpdate

    const websiteDetails = useSelector(state => state.websiteDetails)
    const {loading: loadingDetailsWebsite, 
        error: errorDetailsWebsite, 
        success: successDetailsWebsite, 
        website
    } = websiteDetails

    function validatePhoneNumber(phoneNumber) {
      if (phoneNumberRegex.test(phoneNumber)) {
        setPhoneIsValid(true);
        setPhoneErrorMessage('');
      } else {
        setPhoneIsValid(false);
        setPhoneErrorMessage('Invalid phone number format');
      }
    }
  
    function validateAddress(address) {
      if (addressRegex.test(address)) {
        setAddressIsValid(true);
        setAddressErrorMessage('');
      } else {
        setAddressIsValid(false);
        setAddressErrorMessage('Invalid address format');
      }
    }
  
    function validateName(name) {
      if (nameRegex.test(name)) {
        setNameIsValid(true);
        setNameErrorMessage('');
      } else {
        setNameIsValid(false);
        setNameErrorMessage('Invalid name format');
      }
    }

    // function validateVatNumber(vatNumber) {
    //   if (nameRegex.test(vatNumber)) {
    //     setVatNumberIsValid(true);
    //     setVatNumberErrorMessage('');
    //   } else {
    //     setVatNumberIsValid(false);
    //     setVatNumberErrorMessage('Invalid VAT Number format');
    //   }
    // }


    useEffect(()=>{
      if(accessToken && email && companyId) 
          {
          if(successDetailsByIdCompany){
             setCompanyAddress(company.address)
             setCompanyName(company.name)
             setCompanyTelephone(company.telephone)
            //  setCompanyVatNumber(company.vatNumber)
             setIndustry(company.industry)
             
             if(successDetailsWebsite){
             }
             else{
              dispatch(listWebsiteDetails(accessToken, companyId))
             }
                
              } else {
                  dispatch(listCompanyDetailsById(accessToken, companyId, email))
              }
          } 
      },[email,
          accessToken,  
          dispatch,
          successDetailsByIdCompany,
          company,
          companyId,
          successDetailsWebsite,
          website,
          successUpdateCompany
      ])


const SaveHandler = (e) => {
  e.preventDefault()
        const company = {
            name: companyName,
            address: companyAddress,
            telephone: companyTelephone,
            industry: industry,
            vatNumber: companyVatNumber ? companyVatNumber : 'missing',
            email,
        }
    dispatch(updateCompany(accessToken, company, companyId))
}

  const websiteHandler = (websiteId) => {
    dispatch({type: WEBSITE_DETAILS_BY_ID_RESET})
    dispatch({type: KEYWORD_DETAILS_RESET})
    dispatch({type: KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET})
    dispatch({type: COMPETITOR_DETAILS_RESET})
    history.push(`/edit-website/${websiteId}`)
  }

  const tableWebsites = () => {
    return(
      <FlexColumn>
        {website[0] &&
          website.map((w) => (
            <General.TableRow
              style={{
                justifyContent: 'space-between',
                marginTop: '5px',
                alignItems:'center'
              }}
            >
              <Text.Bold16Black>
                {w.website}
              </Text.Bold16Black>
              <Button.Back
                onClick={() => websiteHandler(w.id)}
              >
                Visit
              </Button.Back>
            </General.TableRow>
          ))
        }
      </FlexColumn>
    )
  }

  const noWebsiteContainer = () => {
    return(
      <>
        <Text.Bold16Black >
            You have not added any website yet.
        </Text.Bold16Black>
        <FlexRow
            style= {{
                alignItems: 'center',
            }}
       >
              <AddNewButton 
              
              route={`/add-website/${companyId}`}
              text='Add Website' />
      
            </FlexRow> 
      </>
    )
  }

  return(
      <Content>
        {loadingDetailsByIdCompany || !successDetailsByIdCompany ||
        loadingDetailsWebsite || !successDetailsWebsite ||
        loadingUpdateCompany 
        
        ?
        <LoaderImage /> :
        errorDetailsByIdCompany || errorDetailsWebsite || errorUpdateCompany ?
        <ErrorMessage /> :
        company.detail ?
        <p><ErrorMessage message={company.detail}/></p> :

      <>
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold26Pink>
                Basic Information
                <Text.Normal14Black>
                * indicates a required field.
                </Text.Normal14Black>
              </Text.Bold26Pink>
              <Text.Bold16Black>
                <Text.AsterixPurple>*</Text.AsterixPurple>
                Company Name
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Company name"
                type="text"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  validateName(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{nameErrorMessage}</span>
              <Text.Bold16Black>
                {/* <Text.AsterixPurple>*</Text.AsterixPurple> */}
                Address
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Address"
                type="text"
                value={companyAddress}
                onChange={(e) => {
                  setCompanyAddress(e.target.value);
                  validateAddress(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{addressErrorMessage}</span>
              <Text.Bold16Black>
                {/* <Text.AsterixPurple>*</Text.AsterixPurple> */}
                Phone Number
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Phone Number"
                type="text"
                value={companyTelephone}
                onChange={(e) => {
                  setCompanyTelephone(e.target.value);
                  validatePhoneNumber(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{phoneErrorMessage}</span>
              {/* <Select param={industry} setParam={setIndustry} list={industries} placeholder='Choose industry' title='Industry' /> */}
              {/* <Text.Bold16Black>
                <Text.AsterixPurple>*</Text.AsterixPurple>
                VAT Number
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="VAT Number"
                type="text"
                value={companyVatNumber}
                onChange={(e) => {
                  setCompanyVatNumber(e.target.value); */}
                  {/* validateVatNumber(e.target.value); */}
                {/* }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{vatNumberErrorMessage}</span> */}
            </Grid.Section>
            <Grid.Section>
              <Text.Bold26Pink>
                Company Websites
              </Text.Bold26Pink>
              {website[0] === undefined && 
              <>{noWebsiteContainer()}</>
            
              }
              {tableWebsites()}
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          
          <Content.ButtonsContainer>
            
            <Button.Back
            onClick={redirectCompaniesHandler}
            >
            Back
          </Button.Back>
          <Button.Save 
          onClick={SaveHandler}
          disabled={!phoneIsValid | !addressIsValid | !nameIsValid | !vatNumberIsValid}
          >
              Save
            </Button.Save>
          </Content.ButtonsContainer>
          
        </Content.SectionBottom>
        </>
      }
      </Content>
  )
}
