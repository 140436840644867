import React, { useEffect,useState,useContext } from "react";
import {  Content, Text } from "../../../../styled-components/visualization";
import { TitleContainer } from "../../utilComponents/Title";
import { assistantText, insightsText } from "../../utilComponents/InsightsText";
import {
  CompetitorMovementsTable,
} from "./CompetitorMovementsTable";
import ResultsMissing from "../../../../components/ResultsMissing";
import LoaderImage from "../../../../components/LoaderImage";
import ErrorMessage from "../../../../components/ErrorMessage";
import { CompetitorMovementsContext } from "../../../../contexts/CompetitorMovementsContext";

export function CompetitorMovementsContainer({loadingMonthlySeoPpc,successMonthlySeoPpc,errorMonthlySeoPpc,monthlySeoPpcCompetitorResults,monthlySeoPpcCompetitorResultsTotalRows,pageNoCompetitorMovements,setPageNoCompetitorMovements }) {

  const [changeInFilters, setChangeInFilters] = useState(false)
  const [allMovements, setAllMovements] = useState(true)
  const [allScales, setAllScales] = useState(true)

  const [helper, setHelper] = useState(0)
  const csvHeader = [
    { label: "Month", key: "date_month" },
    { label: "Website", key: "domain" },
    { label: "Audience", key: "keyword_group_1" },
    { label: "Brand", key: "keyword_group_2" },
    { label: "Market", key: "country" },
    { label: "Scale", key: "scale" },
    { label: "Movement", key: "category" },
    { label: "Impact Score", key: "severity_score" },
    { label: "Keywords", key: "keyword_count" },
    { label: "Avg. Position", key: "avg_position" },
    { label: "Previous", key: "previous_avg_position" },
  ];

  const {
    serpFeatureFilterArray,
    movementsFilterArray,
    scaleFilterArray,
    filterSerpFeature,setFilterSerpFeature,
    filterMovements, setFilterMovements,
    filterScale,setFilterScale
  } = useContext(CompetitorMovementsContext);

  const handleTickBox = (filter,type) => {

    //first column
    if(type == 'feature'){
        const index = filterSerpFeature.indexOf(filter)
        if(filterSerpFeature.indexOf(filter) === -1){
          setFilterSerpFeature([filter])
        }
    }

    //second column
    if(type == 'movements'){
      const index2 = filterMovements.indexOf(filter)
      let filterMovementsCopy = [...filterMovements]; // this is a deep copy() ( not to copy the adress)
      if(filterMovementsCopy.indexOf(filter) === -1){
        filterMovementsCopy.push(filter)
      }else{
        if(allMovements == false){
          setAllMovements(!allMovements)
        }
        if(filterMovementsCopy.length>1){
          filterMovementsCopy.splice(index2, 1)
          //daca se debifeaza si ultimul bifat=> trebuie sa se bifeze inapoi toate optiunile
        }
      }
      setFilterMovements(filterMovementsCopy)
    }
   
     //third column
     if(type == 'scale'){
          const index3 = filterScale.indexOf(filter)
          let filterScaleCopy = [...filterScale];
        if(filterScaleCopy.indexOf(filter) === -1){
          filterScaleCopy.push(filter)
        }else{
          if(allScales == false){
            setAllScales(!allScales)
          }
          if(filterScaleCopy.length>1){
            filterScaleCopy.splice(index3, 1)
            //daca se debifeaza si ultimul bifat=> trebuie sa se bifeze inapoi toate optiunile
          }
        }
        setFilterScale(filterScaleCopy)
     }
    
    setChangeInFilters(true);
    setPageNoCompetitorMovements(1);//reset pagination if any other filter is selected
    setHelper(helper+1)
    
  }

  const handleTickBoxSelectAll = (type) => {
    //second column
    if(type == 'movements'){
      let filterMovementsAll = [...filterMovements];
      movementsFilterArray.map((item)=>{
        const indexM = filterMovementsAll.indexOf(item);
        if(allMovements == true){
          if(indexM == -1){
            filterMovementsAll.push(item)
          }
        }else{
          if(indexM !== -1){
            filterMovementsAll.splice(indexM,1)
          }
        }
        
      })
      setAllMovements(!allMovements)
      setFilterMovements(filterMovementsAll)
    }
    
   
     //third column
     if(type == 'scale'){
      let filterScaleAll = [...filterScale];
      scaleFilterArray.map((item)=>{
        const indexS = filterScaleAll.indexOf(item);
        if(allScales == true){
          if(indexS == -1){
            filterScaleAll.push(item)
          }
        }else{
          if(indexS !== -1){
            filterScaleAll.splice(indexS,1)
          }
        }
        
      })
      setAllScales(!allScales)
      setFilterScale(filterScaleAll)
    }
     


    setChangeInFilters(true);
    setPageNoCompetitorMovements(1);
    setHelper(helper+1)
    
  }

  const dropdownItem = (filter,type, notTicked=false) => {
    return(
        <>
          <Content.DropDownItemContainer style={{justifyContent:'left'}}>
            <Content.FlexRow
              style={{
                marginRight:'5px'
              }}
            >
              <Content.TickBox
              onClick={()=>handleTickBox(filter,type)}
              style={{
                
              }}
              >
              {notTicked &&
                <Content.Icon
                  style={{
                    width: "10px",
                    height: "10px",
                    margin: "0",
                  }}
                  src={
                    require("../../../../images/visualization/Tick_2.svg")
                      .default
                  }
                /> }
              </Content.TickBox>
            </Content.FlexRow>

            <Text.Regular16Black> {filter}</Text.Regular16Black>
          </Content.DropDownItemContainer>
              
        </>
    )
  }


  return (
    <Content.FlexColumn id="winners-loosers-component-id"
      style={{
        justifyContent: "space-between",
        padding: "1%",
        background: "white",
      }}
    >
        <TitleContainer 
        title="Winners and Losers" 
        subtitle="Discover the winning strategies of your competitors with monthly updates from page one"
        backdropTop = '225vw'   
        pages={insightsText.CompetitorMovements[0]}
        p11={insightsText.CompetitorMovements[1]}
        p21={insightsText.CompetitorMovements[2]}
        p31={insightsText.CompetitorMovements[3]}
        p41={insightsText.CompetitorMovements[4]}
        p51={insightsText.CompetitorMovements[5]}
        p61={insightsText.CompetitorMovements[6]}
        assistantMessage={assistantText.CompetitorMovements}
        csvData={monthlySeoPpcCompetitorResults}
        csvHeader={csvHeader}
        />
       
       {/* here 3 columns for filters */}
       <Content.FlexRow style={{justifyContent: "space-between",width: "60%",padding:"40px"}}>
          <Content.FlexColumn>
            <Text.Bold16Purple>SERP Feature</Text.Bold16Purple> 
            {serpFeatureFilterArray.map((filter,i)=>
            (filterSerpFeature?.indexOf(filter) == -1) ?
            dropdownItem(filter,'feature', false) : dropdownItem(filter,'feature', true)
            )}
          </Content.FlexColumn>

          <Content.FlexColumn>
            <Content.DropDownItemContainer>
              <Content.TickBox
                onClick={()=>handleTickBoxSelectAll('movements')}
              
                    >
              {!allMovements && <Content.Icon
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "0",
                          }}
                          src={
                            require("../../../../images/visualization/Tick_2.svg")
                              .default
                          }
                        />}
                </Content.TickBox>
              <Text.Bold16Purple>Movement</Text.Bold16Purple> 
            </Content.DropDownItemContainer>
          
            {movementsFilterArray.map((filter,i)=>
            (filterMovements?.indexOf(filter) == -1) ?
            dropdownItem(filter,'movements', false) : dropdownItem(filter,'movements', true)
            )}
          </Content.FlexColumn>

          <Content.FlexColumn>
          <Content.DropDownItemContainer>
              <Content.TickBox
                onClick={()=>handleTickBoxSelectAll('scale')}
              
                    >
              {!allScales && <Content.Icon
                          style={{
                            width: "10px",
                            height: "10px",
                            margin: "0",
                          }}
                          src={
                            require("../../../../images/visualization/Tick_2.svg")
                              .default
                          }
                        />}
                </Content.TickBox>
              <Text.Bold16Purple>Scale</Text.Bold16Purple> 
            </Content.DropDownItemContainer>
           
            {scaleFilterArray.map((filter,i)=>
            (filterScale?.indexOf(filter) == -1) ?
            dropdownItem(filter,'scale', false) : dropdownItem(filter,'scale', true)
            )}
          </Content.FlexColumn>
       </Content.FlexRow>
       {/* end here 3 columns for filters */}

       <Content.FlexColumn className={"justifyContentCenter"} >
        {
            loadingMonthlySeoPpc || !successMonthlySeoPpc
            ? 
            <LoaderImage height={'430px'}/> : 
            errorMonthlySeoPpc 
            ? errorMonthlySeoPpc == 'hideTable' ?<ResultsMissing message="Check back after a month to see which brands are climbing through the SERPs and which are falling"/> :
            <ErrorMessage /> :
            monthlySeoPpcCompetitorResults.length != 0 ? 
            (monthlySeoPpcCompetitorResults.detail == undefined && monthlySeoPpcCompetitorResults.exception == undefined ) ? 
            <CompetitorMovementsTable results={monthlySeoPpcCompetitorResults} resultsTotalRows={monthlySeoPpcCompetitorResultsTotalRows} pageNoCompetitorMovements={pageNoCompetitorMovements} setPageNoCompetitorMovements={setPageNoCompetitorMovements} /> : <ResultsMissing details = {monthlySeoPpcCompetitorResults.detail}/>
            : 
            <ResultsMissing details = {monthlySeoPpcCompetitorResults.detail}/>
          }
       </Content.FlexColumn>
       
    </Content.FlexColumn>
  );
}
