import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { dateFormatter } from "../resultsComponents/serpScoreboard/sortingUtils";


export function PaidSearchRealTimeChartContainer({hvnScoreResults}) {

  const [array1] = useState([])
  const [firstRender] = useState(0)
  
  useEffect(() => { 
    if(firstRender === 0) {
      hvnScoreResults.map((result, i) => {
          if(i===0){
            result.time_series_data?.map((res, i )=> {
              array1.push(res.avg_desktop_position?.toFixed(2))
            })
          }
      })
    }
  }, 

  [])

    var options = {
        series: [
        {
          name: hvnScoreResults[0] ? hvnScoreResults[0].domain : '',
          data: [
            hvnScoreResults[0] && hvnScoreResults[0].time_series_data[0] ? hvnScoreResults[0].time_series_data[0].avg_desktop_position ? hvnScoreResults[0].time_series_data[0].avg_desktop_position.toFixed(2): '' : '', 
            hvnScoreResults[0] && hvnScoreResults[0].time_series_data[1] ? hvnScoreResults[0].time_series_data[1].avg_desktop_position ? hvnScoreResults[0].time_series_data[1].avg_desktop_position.toFixed(2): '' : '', 
            hvnScoreResults[0] && hvnScoreResults[0].time_series_data[2] ? hvnScoreResults[0].time_series_data[2].avg_desktop_position ? hvnScoreResults[0].time_series_data[2].avg_desktop_position.toFixed(2): '' : '', 
            hvnScoreResults[0] && hvnScoreResults[0].time_series_data[3] ? hvnScoreResults[0].time_series_data[3].avg_desktop_position ? hvnScoreResults[0].time_series_data[3].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[0] && hvnScoreResults[0].time_series_data[4] ? hvnScoreResults[0].time_series_data[4].avg_desktop_position ? hvnScoreResults[0].time_series_data[4].avg_desktop_position.toFixed(2) : '' : '', 
          ]
        },
        {
          name: hvnScoreResults[1] ? hvnScoreResults[1].domain : '',
          data: [
            hvnScoreResults[1] && hvnScoreResults[1].time_series_data[0] ? hvnScoreResults[1].time_series_data[0].avg_desktop_position ? hvnScoreResults[1].time_series_data[0].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[1] && hvnScoreResults[1].time_series_data[1] ? hvnScoreResults[1].time_series_data[1].avg_desktop_position ? hvnScoreResults[1].time_series_data[1].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[1] && hvnScoreResults[1].time_series_data[2] ? hvnScoreResults[1].time_series_data[2].avg_desktop_position ? hvnScoreResults[1].time_series_data[2].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[1] && hvnScoreResults[1].time_series_data[3] ? hvnScoreResults[1].time_series_data[3].avg_desktop_position ? hvnScoreResults[1].time_series_data[3].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[1] && hvnScoreResults[1].time_series_data[4] ? hvnScoreResults[1].time_series_data[4].avg_desktop_position ? hvnScoreResults[1].time_series_data[4].avg_desktop_position.toFixed(2) : '' : '', 
          ]
        },
        {
          name: hvnScoreResults[2] ? hvnScoreResults[2].domain : '',
          data: [
            hvnScoreResults[2] && hvnScoreResults[2].time_series_data[0] ? hvnScoreResults[2].time_series_data[0].avg_desktop_position ? hvnScoreResults[2].time_series_data[0].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[2] && hvnScoreResults[2].time_series_data[1] ? hvnScoreResults[2].time_series_data[1].avg_desktop_position ? hvnScoreResults[2].time_series_data[1].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[2] && hvnScoreResults[2].time_series_data[2] ? hvnScoreResults[2].time_series_data[2].avg_desktop_position ? hvnScoreResults[2].time_series_data[2].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[2] && hvnScoreResults[2].time_series_data[3] ? hvnScoreResults[2].time_series_data[3].avg_desktop_position ? hvnScoreResults[2].time_series_data[3].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[2] && hvnScoreResults[2].time_series_data[4] ? hvnScoreResults[2].time_series_data[4].avg_desktop_position ? hvnScoreResults[2].time_series_data[4].avg_desktop_position.toFixed(2) : '' : '', 
          ]
        },
        {
          name: hvnScoreResults[3] ? hvnScoreResults[3].domain: '',
          data: [
            hvnScoreResults[3] && hvnScoreResults[3].time_series_data[0] ? hvnScoreResults[3].time_series_data[0].avg_desktop_position ? hvnScoreResults[3].time_series_data[0].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[3] && hvnScoreResults[3].time_series_data[1] ? hvnScoreResults[3].time_series_data[1].avg_desktop_position ? hvnScoreResults[3].time_series_data[1].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[3] && hvnScoreResults[3].time_series_data[2] ? hvnScoreResults[3].time_series_data[2].avg_desktop_position ? hvnScoreResults[3].time_series_data[2].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[3] && hvnScoreResults[3].time_series_data[3] ? hvnScoreResults[3].time_series_data[3].avg_desktop_position ? hvnScoreResults[3].time_series_data[3].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[3] && hvnScoreResults[3].time_series_data[4] ? hvnScoreResults[3].time_series_data[4].avg_desktop_position ? hvnScoreResults[3].time_series_data[4].avg_desktop_position.toFixed(2) : '' : '', 
          ]
        },
        {
          name: hvnScoreResults[4] ? hvnScoreResults[4].domain: '',
          data: [
            hvnScoreResults[4] && hvnScoreResults[4].time_series_data[0] ? hvnScoreResults[4].time_series_data[0].avg_desktop_position ? hvnScoreResults[4].time_series_data[0].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[4] && hvnScoreResults[4].time_series_data[1] ? hvnScoreResults[4].time_series_data[1].avg_desktop_position ? hvnScoreResults[4].time_series_data[1].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[4] && hvnScoreResults[4].time_series_data[2] ? hvnScoreResults[4].time_series_data[2].avg_desktop_position ? hvnScoreResults[4].time_series_data[2].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[4] && hvnScoreResults[4].time_series_data[3] ? hvnScoreResults[4].time_series_data[3].avg_desktop_position ? hvnScoreResults[4].time_series_data[3].avg_desktop_position.toFixed(2) : '' : '', 
            hvnScoreResults[4] && hvnScoreResults[4].time_series_data[4] ? hvnScoreResults[4].time_series_data[4].avg_desktop_position ? hvnScoreResults[4].time_series_data[4].avg_desktop_position.toFixed(2) : '' : '', 
          ]
        },
      ],
        chart: {
        height: 450,
        type: 'line',
        // dropShadow: {
        //   enabled: true,
        //   color: '#000',
        //   top: 18,
        //   left: 7,
        //   blur: 10,
        //   opacity: 0.2
        // },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            download: '<img src='+require("../../../images/visualization/download.svg").default+' width="20">'
          }
        }
      },
      colors: ['#841F5B', '#F8855B', '#364DF8', '#C51852', '#854EFF', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0','#9467FA', '#78D9F0', '#FDDF34', '#FF5CE6', '#EF008C'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,      
    },
      title: {
        text: 'Average ad position over time',
        align: 'left',

      },
      xaxis: {
        categories: [
          hvnScoreResults[0].time_series_data[0] ? hvnScoreResults[0].time_series_data[0].day : '', 
          hvnScoreResults[0].time_series_data[1] ? hvnScoreResults[0].time_series_data[1].day : '', 
          hvnScoreResults[0].time_series_data[2] ? hvnScoreResults[0].time_series_data[2].day : '', 
          hvnScoreResults[0].time_series_data[3] ? hvnScoreResults[0].time_series_data[3].day : '', 
          hvnScoreResults[0].time_series_data[4] ? hvnScoreResults[0].time_series_data[4].day : ''
        ],
        title: {
          text: 'Date',
        },
        labels: {
          formatter: function (value) {
            return dateFormatter(value)
          }
        },
      },
      yaxis: {
       title: {
         text: 'Ad position'
       },
        min: 1,
        max: 3,
        reversed:true
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        //floating: true,
        offsetY: 5,
        offsetX: -5,
        markers: {
          radius: 2,
        }
      }
      };

   
  return (
        <Chart 
                    options={options}
                    series={options.series}
                    width="100%"
                    height='500px'

            />
  )
}
