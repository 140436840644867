import React,{useState,useEffect} from "react";

import { Text, Content, Button } from "../../../styled-components/visualization";
import { CounterContainer } from "../utilComponents/Counter";
import { TitleContainer } from "../utilComponents/Title";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";
import LoaderImage from "../../../components/LoaderImage";
import { COLOR_PALETTE_1, COLOR_PALETTE_2, COLOR_PALETTE_3, COLOR_PALETTE_4, COLOR_PALETTE_5, COLOR_PALETTE_6, COLOR_PALETTE_7, COLOR_PALETTE_8 } from "../../../constants/theme/colours";

export function SwotAnalysisContainer({initialSWOTResults,loadingRetrieveSWOT,successRetrieveSWOT,errorRetrieveSWOT,retrieveSWOTResults,extendParent,setExtendParent}) {
    const [numberOfPages,setNumberOfPages] = useState(Math.ceil(retrieveSWOTResults?.length/15))
    const [currentPage, setCurrentPage] = useState(1)
    const [colorIntervals, setColorIntervals] = useState([])
    const nextPage = () => {
        if(currentPage<numberOfPages){
          setCurrentPage(currentPage+1)
        }   
      }

    const previousPage = () => {
    if(currentPage>1){
        setCurrentPage(currentPage-1)
    }   
    }
 
    const csvHeader = [
        { label: "Shoe", key: "dim1" },
        { label: "Tennis", key: "" },
        { label: "undefined", key: "avg_total_search_ctr" },
        { label: "Basketball", key: "" },
        { label: "Running", key: "" },
        { label: "Football", key: "" },
        { label: "Golf", key: "" }
    ];

    const [highlightedRow, setHighlightedRow] = useState(-1);
    const [highlightedColumn, setHighlightedColumn] = useState(-1);

    function handleCellHover(row, col) {
        setHighlightedRow(row);
        setHighlightedColumn(col);
    }

    const handleExtend = (e)=>{
        setExtendParent(!extendParent);
    }
    
    const [uniqueColumns, setUniqueColumn] = useState([]);
    const [uniqueRows, setUniqueRows] = useState([]) 
    const [k1, setKg1] = useState([]);
    const [k2, setKg2] = useState([]);

    useEffect(() => { 
        const column =[]; const kg1=[]; const kg2=[];  const kg3=[];
        const sortedColumns = [];
        let maxValue = 0;
        if(successRetrieveSWOT){
            if(retrieveSWOTResults.length != 0){
                if(retrieveSWOTResults.detail == undefined){
                    setNumberOfPages(Math.ceil(retrieveSWOTResults?.length/15));

                    retrieveSWOTResults.map((result, i) => {
    
                        //find maxValue
                        if(result.avg_total_search_ctr > maxValue) maxValue = result.avg_total_search_ctr;

                        //store column in a new array
                        if(extendParent){
                            if(sortedColumns.indexOf(result.dim2) === -1) {
                                sortedColumns.push(result.dim2)
                            } 
                        }else{
                            if(sortedColumns.indexOf(result.dim3) === -1) {
                                sortedColumns.push(result.dim3)
                            } 
                            if(result.dim2 && (column.indexOf('Insight') === -1) )
                            column.push('Insight')
                        }
                        
                        if( result.dim1 && kg1.indexOf(result.dim1) === -1) {
                        kg1.push(result.dim1) //unique parent array
                        } 
                        if( result.dim3) {//extended parent
                        kg2.push([result.dim1, result.dim2]) //not unique group parent-child array
                        } 
                        
    
                    });
                    const array3 = column.concat(sortedColumns.sort()); //keep the same columns order
                    setUniqueColumn(array3)
                    setKg1(kg1)
    
                    //set unique groups
                    let set  = new Set(kg2.map(JSON.stringify));
                    let arr2 = Array.from(set).map(JSON.parse);
                    setKg2(arr2)
    
                
                }
            }
            createMaxValueIntervals(maxValue);
        }
        
    },
    [retrieveSWOTResults,initialSWOTResults]) 

    useEffect( ()=>{
        if(uniqueColumns.length !=0  && retrieveSWOTResults?.length != 0){
        const overRow = []; //create array with values for every row
        retrieveSWOTResults?.map((resultR, i) => {
            const row =[];
            let startingPoint=0; // skip the extended column from getting '-' in rows
            resultR.dim1 && row.push(resultR.dim1);
            if(resultR.dim3){ //extended parent
            row.push(resultR.dim2);
            startingPoint = 1;
            }
        
        
            uniqueColumns.map((resultC, i) => {
            //store values in a new array
            if(i >= startingPoint){
                if(extendParent){
                    if(resultR.dim2 == resultC){
                        row.push(resultR.avg_total_search_ctr)
                        }else{
                        row.push('-')
                        }
                }else{
                    if(resultR.dim3== resultC){
                        row.push(resultR.avg_total_search_ctr)
                        }else{
                        row.push('-')
                        }
                }
                
            }
            });
        
            overRow.push(row)        
        });
        
        let allRows=[]; // every column has a value on a different row .. we want a single row for with every column
        if(k2.length != 0){//if extended parent
            k2.map((res,k) =>{
            const finalLine = res; // here res is an array
            const valuesForRes = overRow.filter(line=>line[0]===res[0]&& line[1]===res[1])
            valuesForRes.map((val,p)=>{
                val.forEach((element, index) => {
                if ( index >0 && element !=="-") finalLine[index]=element
                else if (!finalLine[index])  {finalLine[index]="-"}
                });
            })
            allRows.push(finalLine)
            })
        }else{
            k1.map((res,k) =>{
            const finalLine = [res]; //here res is a string(not an array)
            const valuesForRes = overRow.filter(line=>line[0]===res)
            valuesForRes.map((val,p)=>{
                val.forEach((element, index) => {
                if ( index>0 && element !=="-") finalLine[index]=element
                else if (!finalLine[index])  {finalLine[index]="-"}
                });
            })
            allRows.push(finalLine)
            })
        }
        setUniqueRows(allRows.sort())
        }

    },[uniqueColumns,k1])

    const nFormatter = (num) => {
        if (num < 1000) {
          num = num * 100;
          return num?.toFixed(2) + " %";
        }
        return num;
    };
    const nFormatterToInt = (num) => {
        if (num < 1000) {
          num = num * 100;
          return num?.toFixed(0) + " %";
        }
        return num;
    };

    const uniqueP = [];
    const singleParent = (e,i)=> {
        if(uniqueP.indexOf(e) === -1) {
            uniqueP.push(e)
            return (
                <Text.Regular14Black style={{minWidth:'170px',maxWidth:'170px' }}>{e}</Text.Regular14Black>
                )
        }else{
            return (
                <Text.Regular14Black style={{minWidth:'170px' }}></Text.Regular14Black>
                )
        }
        
    }
    
    const createMaxValueIntervals = (maxVal) =>{
       // Calculate the size of each interval
        const intervalSize = maxVal / 8;

        // Create the intervals with colors
        const intervals = [];
        for (let i = 1; i <= 8; i++) { // 8 intervals
        const intervalStart = intervalSize * (i - 1);
        const intervalEnd = intervalSize * i;
        let color;
        switch (i) {
            case 1:
            color = COLOR_PALETTE_1;
            break;
            case 2:
            color = COLOR_PALETTE_2;
            break;
            case 3:
            color = COLOR_PALETTE_3;
            break;
            case 4:
            color = COLOR_PALETTE_4;
            break;
            case 5:
            color = COLOR_PALETTE_5;
            break;
            case 6:
            color = COLOR_PALETTE_6;
            break;
            case 7:
            color = COLOR_PALETTE_7;
            break;
            case 8:
            color = COLOR_PALETTE_8;
            break;
            default:
            color = 'black'; // or any default color
        }
        intervals.push({ start: intervalStart, end: intervalEnd, color });
        }

        
        setColorIntervals(intervals);
    }


    const paginationLine = () =>{
        return (
            <Content.FlexRow
            style ={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '20px'
            }}
         >
         <Content.PaginationIcon
         onClick={()=>previousPage()}
         src={require("../../../images/visualization/left_arrow.svg").default}/>
         {currentPage !== numberOfPages ?
          <Text.Regular13Grey>{(currentPage-1)*15+1} - {currentPage*15} / {uniqueRows.length}</Text.Regular13Grey> :
          <Text.Regular13Grey>{(currentPage-1)*15+1} - {uniqueRows.length} / {uniqueRows.length}</Text.Regular13Grey>
         }
         
         <Content.PaginationIcon
        onClick={() =>nextPage()}
         src={require("../../../images/visualization/right_arrow.svg").default}/>
         </Content.FlexRow>
        )
    }
    
    const table = () => {
        return(
            < > 
            {/* color palet */}
            <Content.FlexRow style={{ marginBottom: '20px'}}>
            {
                colorIntervals.map((range, i) => (
                <Content.FlexRow style={{ paddingRight: '15px',fontSize:'13px' }}>
                    <Content.FlexColumn style={{ width: '20px',marginRight:'3px' }}>
                        <CounterContainer backgroundColor={range.color} />
                    </Content.FlexColumn>
                    
                    {nFormatterToInt(range.start) + ' - '+ nFormatterToInt(range.end)}
                </Content.FlexRow> 
            ))
            }
                
            </Content.FlexRow>

            {/* header row */}
            <Content.FlexRow
            style={{
                width: '99%',
                padding: '2px',
                
            }} 
            > 
            <Text.Regular13Grey style={{minWidth:'20px'}}>  </Text.Regular13Grey>

            <Text.Regular13Grey style={{minWidth:'170px', textAlign:'center'}}>   
    
            <Content.TickBox style={{cursor:'pointer', display:'inline-flex',width:'20px',fontSize:'15px'}}
              onClick={()=>handleExtend()}>
                {extendParent ? '+' : '-'} </Content.TickBox> Keyword Parent</Text.Regular13Grey>
            
            {uniqueColumns.map((col1,k)=>( 
                k==0 && !extendParent ?  
                <Content.FlexColumn
                  style={{
                    width: "100px",
                    textAlign: "left",
                    
                  }}
                >
                  <Text.Regular13Grey style={{minWidth:'100px', textAlign: 'center'}}>{col1}</Text.Regular13Grey>
                </Content.FlexColumn>
                :
                <Content.FlexColumn
                  style={{
                    width: "100px",
                    backgroundColor:
                            (k+1) === highlightedColumn
                              ? '#F7F7F7'
                              : 'white',
                  }}
                >
                  <Text.Regular13Grey style={{minWidth:'100px', textAlign: 'center', margin:'1px'}}>{col1}</Text.Regular13Grey>
                </Content.FlexColumn>  
            ))}
            </Content.FlexRow>
            {/*end header row  */}
      
            {/* body containter */}
             {/* table body */}
            <Content.DropDownContainer      
                style={{
                    height: '465px',
                    overflow:'auto'
                }}
            >
                {uniqueRows.map((row, i) => (
                ((i) >= (currentPage-1)*15 && (i)<currentPage*15) ? 
                <Content.FlexRow key={i} style={{margin:'2px'}}>  
                    <Content.FlexColumn style={{ width: '20px',justifyContent:'center'}}>
                        <CounterContainer number={i+1}  />
                        
                    </Content.FlexColumn>

                    {row.map((val,j)=>(
                        j==0 ?  
                        <Content.FlexColumn 
                        key={j}
                        onMouseEnter={() => handleCellHover(i, j)}
                        onMouseLeave={() => {
                            setHighlightedRow(-1);
                            setHighlightedColumn(-1);
                        }}
                        style={{
                            width: "170px",
                            textAlign: "center",
                            justifyContent:'center',
                            backgroundColor:
                            i === highlightedRow || j === highlightedColumn
                              ? '#F7F7F7'
                              : 'white',
                        }}
                        >
                        {singleParent(row[j],j)}
                        </Content.FlexColumn>
                        
                        :
                        !extendParent && j==1 ?
                        <Content.FlexColumn 
                        key={j}
                        onMouseEnter={() => handleCellHover(i, j)}
                        onMouseLeave={() => {
                            setHighlightedRow(-1);
                            setHighlightedColumn(-1);
                        }}
                        style={{
                            width: "100px",
                            textAlign: "center",
                            backgroundColor:
                            i === highlightedRow || j === highlightedColumn
                              ? '#F7F7F7'
                              : 'white',
                        }}
                        >
                        <Text.Regular14Black style={{minWidth:'100px',maxWidth:'100px', textAlign: 'center'}}>{row[j]}</Text.Regular14Black>
                        </Content.FlexColumn>
                        :

                        <Content.FlexColumn 
                        key={j}
                        onMouseEnter={() => handleCellHover(i, j)}
                        onMouseLeave={() => {
                            setHighlightedRow(-1);
                            setHighlightedColumn(-1);
                        }}
                        style={{
                            width: "100px",
                            backgroundColor:
                            i === highlightedRow || j === highlightedColumn
                              ? '#F7F7F7'
                              : 'white',
                        }}
                        >
                            {
                            typeof row[j] == "number" ?

                            colorIntervals.map((range, i) => (
                                row[j] > range.start && row[j] <= range.end && 
                                 <Text.Regular14WhitePurpleLightBackground style={{margin:'1px', background:range.color}}>
                                    {nFormatter(row[j])}
                                </Text.Regular14WhitePurpleLightBackground> 
                            ))
                            :
                            <Text.Regular14Black style={{minWidth:'96px', textAlign: 'center', margin:'1px'}}>-</Text.Regular14Black>
                            }
                        </Content.FlexColumn>  

                    ))}

                </Content.FlexRow>
                    :null
                ))}
                
            </Content.DropDownContainer>
            {paginationLine()}
        </>
        )
    }

    const strongestTopics = () => {
        return(
        <Content.FlexColumn
            
        >
            <Text.Regular18Purple>Strongest Topics</Text.Regular18Purple>
            <Text.Regular13Grey
            style={{
                marginTop: '1%',
                marginBottom: '2%'
            }}
        >
            These are your strongest topics, where you have the highest page one presence:
        </Text.Regular13Grey>
        {initialSWOTResults[0] && initialSWOTResults.length !=0 ?
            initialSWOTResults.sort((a,b) => b.avg_total_search_ctr - a.avg_total_search_ctr) && //sort descending
            initialSWOTResults?.map((swot,i)=>(
            
            i<5 && 
            <>
            <Content.FlexRow
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: '5%'
                }}
            >
                {swot.dim2 !== 'undefined' ?
                <Text.Regular15Black style={{margin: '0', width: '35%', textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}title={swot.dim2}>{swot.dim2}</Text.Regular15Black>
                : <Text.Regular13Grey style={{margin: '0', width: '35%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}title={swot.dim2}>{swot.dim2}</Text.Regular13Grey>
                }
                <Text.Regular15Black style={{margin: '0', width: '35%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}title={swot.dim1}>{swot.dim1}</Text.Regular15Black>
                <Text.Regular15Black style={{margin: '0', width: '20%', textAlign: 'right'}}>{((swot.avg_total_search_ctr && swot.avg_total_search_ctr.toFixed(3))*100).toFixed(1)}%</Text.Regular15Black>
            </Content.FlexRow>
            <Content.HorizontalLineGrey 
                style={{
                    margin: '.5%'
                }}
            />
            </>
            ))
            :
            <ResultsMissing details = {'noimage'}/>
            }
        </Content.FlexColumn>
        )
        
    }

    const potentialToGrow = () => {
        return(
        <Content.FlexColumn
        style={{
               marginTop: '25px'
            }}
        >
        <Text.Regular18Purple>Potential to grow</Text.Regular18Purple>
        <Text.Regular13Grey
            style={{
                marginTop: '1%',
                marginBottom: '2%'
            }}
        >
            These are keyword groups where you have your "foot in the door" and the potential to grow:
        </Text.Regular13Grey>
        {initialSWOTResults[0] && initialSWOTResults.length !=0 ?
         initialSWOTResults.sort((a,b) => a.avg_total_search_ctr - b.avg_total_search_ctr) && //sort ascending
         initialSWOTResults?.map((swot,i)=>(
            
            i<5 && 
            <>
            <Content.FlexRow
                style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: '5%'
                }}
            >
                {swot.dim2 !== 'undefined' ?
                <Text.Regular15Black style={{margin: '0', width: '35%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}} title={swot.dim2}>{swot.dim2}</Text.Regular15Black>
                : <Text.Regular13Grey style={{margin: '0', width: '35%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}} title={swot.dim2}>{swot.dim2}</Text.Regular13Grey>
                }
                <Text.Regular15Black style={{margin: '0', width: '35%',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}} title={swot.dim1}>{swot.dim1}</Text.Regular15Black>
                <Text.Regular15Black style={{margin: '0', width: '25%', textAlign: 'right'}}>{((swot.avg_total_search_ctr && swot.avg_total_search_ctr.toFixed(3))*100).toFixed(1)}%</Text.Regular15Black>
            </Content.FlexRow>
            <Content.HorizontalLineGrey 
                style={{
                    margin: '.5%'
                }}
            />
            </>
            ))
            :
            <ResultsMissing details = {'noimage'}/>
        }
        </Content.FlexColumn>
        )
       
    }

  return (
    <Content.FlexColumn
    style={{
            justifyContent: 'space-between',
            padding: '1%',
            background: 'white'
        }}
    >
        <TitleContainer 
            title='Opportunities Radar'    
            subtitle='Pinpoint strengths, weaknesses, and untapped opportunities to discover hidden potential in seconds.' 
            backdropTop = '85vw'        
            pages={insightsText.SWOTAnalysis[0]}
            p11={insightsText.SWOTAnalysis[1]}
            p21={insightsText.SWOTAnalysis[2]}
            p31={insightsText.SWOTAnalysis[3]}
            p41={insightsText.SWOTAnalysis[4]}
            p51={insightsText.SWOTAnalysis[5]}
            assistantMessage={assistantText.SWOTAnalysis}
            csvData={retrieveSWOTResults}
            csvHeader={csvHeader}
            />
        <Content.FlexRow
            style={{
                justifyContent: 'space-between',
                height:"auto",
            }}
        >
            {
                loadingRetrieveSWOT || !successRetrieveSWOT ?
                <LoaderImage height={'550px'} width={"100%"}/> 
                : 
                retrieveSWOTResults.length != 0? 
                    retrieveSWOTResults.detail !== undefined ? 
                        <Content.FlexColumn className={"justifyContentCenter"} style={{width:'100%'}}>
                            <ResultsMissing details = {retrieveSWOTResults.detail}/>
                        </Content.FlexColumn>
                        : 
                        <Content.FlexRow className={"fullWidth"}>
                            <Content.FlexColumn
                                    style={{
                                    width: '25%',
                                    padding: '10px'
                                }}
                            >
                                { (successRetrieveSWOT && initialSWOTResults.length !=0) && strongestTopics()}
                                { (successRetrieveSWOT && initialSWOTResults.length !=0) && potentialToGrow()}
                            </Content.FlexColumn>

                            <Content.FlexColumn
                                style={{
                                    width: '70%',
                                    overflowX:'auto',
                                    display:'grid',
                                    padding: '10px',
                                    height:'630px'
                                    }}
                                    >
                                    {table()}
                            </Content.FlexColumn>
                        </Content.FlexRow>
                       
                :
                <Content.FlexColumn className={"justifyContentCenter"}
                    style={{
                        width: '100%',
                    }}
                    >
                        <ResultsMissing 
                            details = {retrieveSWOTResults.detail}/>
                </Content.FlexColumn>
            }
           
            
        </Content.FlexRow>
       
       
    </Content.FlexColumn>
  )
}
