import React, { useState } from "react";
import { BackDrop, BackDropAbsolute } from "../../../styled-components/general/styles/general";

import { Text, Content } from "../../../styled-components/visualization";
import { Regular18Purple } from "../../../styled-components/visualization/text/styles/text";
import { HelpAssistantContainer } from ".././HelpAssitant";
import { CounterContainer } from "../utilComponents/Counter";
import { InsightsContainer } from "../utilComponents/Insights";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import { TitleContainer } from "../utilComponents/Title";

export function YourKeywordsContainer({keywords}) {

    const [toggleYourKeywords, setToggleYourKeywords] = useState(false)
    const [toggleYourKeywordsInsights, setToggleYourKeywordsInsights] = useState(false)

    const [numberOfPages] = useState(Math.ceil(keywords.length/100))
    const [currentPage, setCurrentPage] = useState(1)
 
    const [modalBackdrop, setModalBackdrop] = useState(false);


    const nextPage = () => {
      if(currentPage<numberOfPages){
        setCurrentPage(currentPage+1)
      }   
    }

    const previousPage = () => {
      if(currentPage>1){
        setCurrentPage(currentPage-1)
      }   
    }
    const csvHeader = [
      { label: "Keyword", key: "name" }
    ];

    const keywordsList = () => {
      return(
      <Content.KeywordsContainer
        style={{
          marginTop: '3%'
        }}
      >
        {keywords.map((keyword, i) => (
                            (i >= (currentPage-1)*100 && i<currentPage*100) ?
                            <Content.HoverRow 
                                  style={{
                                    justifyContent: 'flex-start'
                                }}>
                              <Content.FlexColumn
                                  style={{
                                      justifyContent:'center',
                                      marginRight:'10px'
                                  }}
                              >
                                <CounterContainer  number={i+1} />     
                              </Content.FlexColumn>

                              <Content.FlexColumn
                                  style={{
                                      width: 'auto'
                                  }}
                              >
                                <Text.Regular16Black key={i} >{keyword}</Text.Regular16Black> 
                              </Content.FlexColumn>

                        
                              
                            </Content.HoverRow>
                          :
                            null
                        ))}

      </Content.KeywordsContainer>
      )
    }

    let csvData = [];
    keywords.map((keyword, i) => {
      const obj = {name: keyword};
      csvData.push(obj);
    })

  return (
     <Content.FlexColumn
        style={{
            width: '100%',
            justifyContent: 'space-between',
            padding: '20px',
            background: 'white'
          }}
        >
        {/* Title */}

        <TitleContainer 
            title='Your Keywords & Phrases'
            subtitle=''
            pages={insightsText.YourKeywordsPhrases[0]}
            p11={insightsText.YourKeywordsPhrases[1]}
            p21={insightsText.YourKeywordsPhrases[2]}
            p31={insightsText.YourKeywordsPhrases[3]}
            p41={insightsText.YourKeywordsPhrases[4]}
            assistantMessage={assistantText.YourKeywordsPhrases}
            csvData={csvData}
            csvHeader={csvHeader}
            /> 
           {/* Your keywords */}
           {keywordsList()}
           <Content.FlexRow
              style ={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '3%'
              }}
           >
           <Content.PaginationIcon
           onClick={()=>previousPage()}
           src={require("../../../images/visualization/left_arrow.svg").default}/>
           {currentPage !== numberOfPages ?
            <Text.Regular13Grey>{(currentPage-1)*100+1} - {currentPage*100} / {keywords.length}</Text.Regular13Grey> :
            <Text.Regular13Grey>{(currentPage-1)*100+1} - {keywords.length} / {keywords.length}</Text.Regular13Grey>
           }
           
           <Content.PaginationIcon
          onClick={() =>nextPage()}
           src={require("../../../images/visualization/right_arrow.svg").default}/>
           </Content.FlexRow>
            
        </Content.FlexColumn>
  )
}
