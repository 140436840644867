import React from "react"
import { Container, Primary, Light, Dark, PrimaryLink
} from "./styles/button"

export default function Button({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Button.Primary = function ButtonPrimary({children, ...restProps}){
    return <Primary {...restProps}>{children}</Primary>   
}

Button.PrimaryLink = function ButtonPrimaryLink({children, ...restProps}){
    return <PrimaryLink {...restProps}>{children}</PrimaryLink>   
}

Button.Light = function ButtonLight({children, ...restProps}){
    return <Light {...restProps}>{children}</Light>   
}

Button.Dark = function ButtonDark({children, ...restProps}){
    return <Dark {...restProps}>{children}</Dark>   
}