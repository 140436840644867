import { 
    KEYWORD_MM_DETAILS_REQUEST,
    KEYWORD_MM_DETAILS_SUCCESS,
    KEYWORD_MM_DETAILS_FAIL,
    KEYWORD_MM_DETAILS_RESET,
    
    KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_REQUEST,
    KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_SUCCESS,
    KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_FAIL,
    KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_RESET,

    KEYWORD_MM_DETAILS_BY_ID_REQUEST,
    KEYWORD_MM_DETAILS_BY_ID_SUCCESS,
    KEYWORD_MM_DETAILS_BY_ID_FAIL,
    KEYWORD_MM_DETAILS_BY_ID_RESET,

    KEYWORD_MM_CREATE_REQUEST,
    KEYWORD_MM_CREATE_SUCCESS,
    KEYWORD_MM_CREATE_FAIL,
    KEYWORD_MM_CREATE_RESET,

    KEYWORD_MM_UPDATE_REQUEST,
    KEYWORD_MM_UPDATE_SUCCESS,
    KEYWORD_MM_UPDATE_FAIL,
    KEYWORD_MM_UPDATE_RESET,

    KEYWORD_MM_DELETE_REQUEST,
    KEYWORD_MM_DELETE_SUCCESS,
    KEYWORD_MM_DELETE_FAIL,
    KEYWORD_MM_DELETE_RESET,
 } from '../constants/keyword_mmConstants'


 export const keyword_mmCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case KEYWORD_MM_CREATE_REQUEST: 
            return {loading:true}

        case KEYWORD_MM_CREATE_SUCCESS: 
            return {loading:false, success: true, keyword: action.payload}
        
        case KEYWORD_MM_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_MM_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const keyword_mmUpdateReducer = ( state = { keyword: {} }, action ) => {
    switch(action.type){
        case KEYWORD_MM_UPDATE_REQUEST: 
            return {loading:true}

        case KEYWORD_MM_UPDATE_SUCCESS: 
            return {loading:false, success: true, keyword: action.payload}
        
        case KEYWORD_MM_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_MM_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}


export const keyword_mmDetailsReducer = ( state = {keyword :[]}, action ) => {
    switch(action.type){
        case KEYWORD_MM_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_MM_DETAILS_SUCCESS: 
            return {
                loading:false, success:true,  
                keyword: action.payload.keyword, 
                page:action.payload.page, 
                pages:action.payload.pages,
                numberOfKeywords: action.payload.numberOfKeywords
            }
        
        case KEYWORD_MM_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_MM_DETAILS_RESET: 
            return {success:false, keyword: []}

        default: 
            return state
    }
}

export const keyword_mmDetailsWithoutPaginationReducer = ( state = {keywords :[]}, action ) => {
    switch(action.type){
        case KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_SUCCESS: 
            return {
                loading:false, success:true,  
                keywords: action.payload.keywords, 
            }
        
        case KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_MM_DETAILS_WITHOUT_PAGINATION_RESET: 
            return {success:false, keyword: []}

        default: 
            return state
    }
}

export const keyword_mmDetailsByIdReducer = ( state = {keyword :{}}, action ) => {
    switch(action.type){
        case KEYWORD_MM_DETAILS_BY_ID_REQUEST: 
            return {loading:true}

        case KEYWORD_MM_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  keyword: action.payload}
        
        case KEYWORD_MM_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_MM_DETAILS_BY_ID_RESET: 
            return {loading:false, success:false, keyword: []}

        default: 
            return state
    }
}


export const keyword_mmDeleteReducer = ( state = {keyword :{}}, action ) => {
    switch(action.type){
        case KEYWORD_MM_DELETE_REQUEST: 
            return {loading:true, ...state}

        case KEYWORD_MM_DELETE_SUCCESS: 
            return {loading:false, success:true,  keyword: action.payload}
        
        case KEYWORD_MM_DELETE_FAIL: 
            return {loading:false, error: action.payload}

        case KEYWORD_MM_DELETE_RESET: 
            return {loading:false, success:false, keyword: {}}

        default: 
            return state
    }
}