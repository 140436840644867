import React from 'react'
import { Text } from '../styled-components/visualization';

function ErrorMessage({message=''}) {
    return (
        <>
        {message === '' ?
        <Text.Medium30Red
            style={{
                padding: '1em'
            }}
        >
            Server Error! Please contact adrian.pavel@braidr.ai
        </Text.Medium30Red> :
        <Text.Medium30Red
            style={{
                padding: '1em'
            }}
        >
            {message}
        </Text.Medium30Red>
        }
        </>
        
    )
}

export default ErrorMessage
