import styled from "styled-components"
import * as pallete from '../../../../constants/theme/colours'


export const Container = styled.div`
    background: #ffffff;
    color: #222222;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    letter-spacing: 0;   
    
   
`


export const Menu = styled.div`
    width: 15%;     
    height: 100%;
    background: white;
    box-shadow: 10px 0px 16px #0000001A;

//     &:hover{
//         cursor:pointer;
//     }
`

export const Main = styled.div`
    width: 85%;     
    height: 100%;
    
    
    display: flex;
    flex-direction: column;
    // /background: red;

`

export const Header = styled.div`
    position: fixed;
    top: 0;
    left: 15%;
    width: 100%;
    height: 152px;

`

export const Footer = styled.div`
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 15%;


`

export const Content = styled.div`
    width: 100%;     
    height: 100%;
    overflow:auto;
    position: fixed;
    top: 152px;
    // bottom: 50px;
   
    
    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 2px 0px 0px ${pallete.SHADOW_GRAY};
        background: ${pallete.LIGHT_GRAY};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 2px 0px 0px ${pallete.SHADOW_GRAY};
        background: ${pallete.PINK};
    }

`