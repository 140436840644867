import  axios from 'axios'
import { 
    PROFILE_LIST_REQUEST,
    PROFILE_LIST_SUCCESS,
    PROFILE_LIST_FAIL,

    PROFILE_DETAILS_REQUEST,
    PROFILE_DETAILS_SUCCESS,
    PROFILE_DETAILS_FAIL,

    PROFILE_DETAILS_BY_ORGANISATION_EMAIL_REQUEST,
    PROFILE_DETAILS_BY_ORGANISATION_EMAIL_SUCCESS,
    PROFILE_DETAILS_BY_ORGANISATION_EMAIL_FAIL,
    
    
    PROFILE_CREATE_REQUEST,
    PROFILE_CREATE_SUCCESS,
    PROFILE_CREATE_FAIL,

    PROFILE_UPDATE_REQUEST,
    PROFILE_UPDATE_SUCCESS,
    PROFILE_UPDATE_FAIL,
    
    PROFILE_RIGHTS_UPDATE_REQUEST,
    PROFILE_RIGHTS_UPDATE_SUCCESS,
    PROFILE_RIGHTS_UPDATE_FAIL,

    PROFILE_UPDATE_BY_ADMIN_REQUEST,
    PROFILE_UPDATE_BY_ADMIN_SUCCESS,
    PROFILE_UPDATE_BY_ADMIN_FAIL,
 } from '../constants/profileConstants'

 export const listProfiles = (accessToken, email) => async (dispatch) =>{
    try {
        dispatch({type:PROFILE_LIST_REQUEST})
        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.get(
            `/api/profile/`,
            config
            )
 
        dispatch({
            type: PROFILE_LIST_SUCCESS,
            payload: data
        })


    } catch(error){
        dispatch({
            type: PROFILE_LIST_FAIL,
            payload: error.response && error.response.data.message ?
            error.response.data.message 
            : error.message,
        })
    }
 }

 export const listProfileDetails = (accessToken, email) => async (dispatch) => {
    try{
        dispatch({ type: PROFILE_DETAILS_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/profile/byEmail/${email}`,
            config
        )

        dispatch({ 
            type: PROFILE_DETAILS_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: PROFILE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const listProfileDetailsByOrganisationEmail = (accessToken, email) => async (dispatch) => {
    try{
        dispatch({ type: PROFILE_DETAILS_BY_ORGANISATION_EMAIL_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/profile/byOrganisation/email/${email}`,
            config
        )

        dispatch({ 
            type: PROFILE_DETAILS_BY_ORGANISATION_EMAIL_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: PROFILE_DETAILS_BY_ORGANISATION_EMAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }



 export const createProfile = (accessToken, user) => async (dispatch) => {
    try {
       
        dispatch({
            type: PROFILE_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.post(
            `/api/profile/create`,
            user,
            config
        )

        dispatch({
            type: PROFILE_CREATE_SUCCESS,
            payload: data,
        })

        if(user.handler==='byAdmin'){
            dispatch(listProfileDetailsByOrganisationEmail(accessToken, user.email))
        }

        if(user.handler==='normal'){
            dispatch(listProfileDetails(accessToken, user.email))
        }
        if(user.handler==='bySuperuser'){
            dispatch(listProfiles(accessToken, user.email))
        }
       


    } catch (error) {
        dispatch({
            type: PROFILE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateProfile = (accessToken, user) => async (dispatch) => {
   try {
      
       dispatch({
           type: PROFILE_UPDATE_REQUEST
       })

       const config = {
           headers: {
               'Content-type':'application/json',
               Authorization: `Bearer ${accessToken}`
           }
       }
       
       const { data } = await axios.put(
           `/api/profile/update/${user.email}`,
           user,
           config
       )

       dispatch({
           type: PROFILE_UPDATE_SUCCESS,
           payload: data,
       })

       dispatch({
        type: PROFILE_DETAILS_SUCCESS,
        payload: data
    })


   } catch (error) {
       dispatch({
           type: PROFILE_UPDATE_FAIL,
           payload: error.response && error.response.data.detail
               ? error.response.data.detail
               : error.message,
       })
   }
}

export const updateProfileRights = (accessToken, user) => async (dispatch) => {
    try {
       
        dispatch({
            type: PROFILE_RIGHTS_UPDATE_REQUEST
        })
 
        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/profile/updateRights/${user.email}`,
            user,
            config
        )
 
        dispatch({
            type: PROFILE_RIGHTS_UPDATE_SUCCESS,
            payload: data,
        })
 
        dispatch({
         type: PROFILE_DETAILS_SUCCESS,
         payload: data
     })
 
 
    } catch (error) {
        dispatch({
            type: PROFILE_RIGHTS_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }


export const updateProfileByAdmin = (accessToken, user) => async (dispatch) => {
    try {
       
        dispatch({
            type: PROFILE_UPDATE_BY_ADMIN_REQUEST
        })
 
        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/profile/update/byAdmin/${user.email}`,
            user,
            config
        )
 
        dispatch({
            type: PROFILE_UPDATE_BY_ADMIN_SUCCESS,
            payload: data,
        })
 
 
 
    } catch (error) {
        dispatch({
            type: PROFILE_UPDATE_BY_ADMIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }