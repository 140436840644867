import  axios from 'axios'
import { 
    WEBSITE_LIST_REQUEST,
    WEBSITE_LIST_SUCCESS,
    WEBSITE_LIST_FAIL,

    WEBSITE_DETAILS_REQUEST,
    WEBSITE_DETAILS_SUCCESS,
    WEBSITE_DETAILS_FAIL,
    
    WEBSITE_DETAILS_BY_ID_REQUEST,
    WEBSITE_DETAILS_BY_ID_SUCCESS,
    WEBSITE_DETAILS_BY_ID_FAIL,

    WEBSITE_DETAILS_BY_EMAIL_REQUEST,
    WEBSITE_DETAILS_BY_EMAIL_SUCCESS,
    WEBSITE_DETAILS_BY_EMAIL_FAIL,

    WEBSITE_CREATE_REQUEST,
    WEBSITE_CREATE_SUCCESS,
    WEBSITE_CREATE_FAIL,

    WEBSITE_UPDATE_REQUEST,
    WEBSITE_UPDATE_SUCCESS,
    WEBSITE_UPDATE_FAIL,

    WEBSITE_DELETE_REQUEST,
    WEBSITE_DELETE_SUCCESS,
    WEBSITE_DELETE_FAIL,
 } from '../constants/websiteConstants'


 export const listWebsites = (accessToken) => async (dispatch) =>{
    try {
        dispatch({type:WEBSITE_LIST_REQUEST})

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.get(
            `/api/website/`,
            config
            )

        dispatch({
            type: WEBSITE_LIST_SUCCESS,
            payload: data
        })


    } catch(error){
        dispatch({
            type: WEBSITE_LIST_FAIL,
            payload: error.response && error.response.data.message ?
            error.response.data.message 
            : error.message,
        })
    }
 }

 export const createWebsite = (accessToken, website, email, companyId) => async (dispatch) => {
    try {
       
        dispatch({
            type: WEBSITE_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.post(
            `/api/website/create/${email}/${companyId}`,
            website,
            config
        )

        dispatch({
            type: WEBSITE_CREATE_SUCCESS,
            payload: data,
        })

        dispatch(listWebsiteDetails(accessToken, companyId))


    } catch (error) {
        dispatch({
            type: WEBSITE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateWebsite = (accessToken, website, websiteCompanyId) => async (dispatch) => {
    try {
       
        dispatch({
            type: WEBSITE_UPDATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/website/update/${website.id}`,
            website,
            config
        )

        dispatch({
            type: WEBSITE_UPDATE_SUCCESS,
            payload: data,
        })

         dispatch(listWebsiteDetailsById(accessToken, websiteCompanyId, website.email))

    } catch (error) {
        dispatch({
            type: WEBSITE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listWebsiteDetails = (accessToken, companyId) => async (dispatch) => {
    try{
        dispatch({ type: WEBSITE_DETAILS_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/website/byCompany/${companyId}`,
            config
            )

        dispatch({ 
            type: WEBSITE_DETAILS_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: WEBSITE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 
export const listWebsiteDetailsById = (accessToken, id, email) => async (dispatch) => {
    try{
        dispatch({ type: WEBSITE_DETAILS_BY_ID_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/website/byId/${id}/${email}`,
            config
            )

        dispatch({ 
            type: WEBSITE_DETAILS_BY_ID_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: WEBSITE_DETAILS_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const listWebsiteDetailsByEmail = (accessToken, email, name) => async (dispatch) => {
    try{
        dispatch({ type: WEBSITE_DETAILS_BY_EMAIL_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/website/byEmail/${email}/${name}`,
            config
            )
        dispatch({ 
            type: WEBSITE_DETAILS_BY_EMAIL_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: WEBSITE_DETAILS_BY_EMAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const deleteWebsite = (accessToken, id) => async (dispatch) => {
    try{
        dispatch({ type: WEBSITE_DELETE_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.delete(
            `/api/website/delete/${id}`,
            config
            )

        dispatch({ 
            type: WEBSITE_DELETE_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: WEBSITE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }