import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button} from "../../styled-components/dashboard";
import { industries, business_model } from '../../constants/utils'
import { useHistory } from "react-router-dom"
import {  listCompanyDetailsById } from '../../actions/companyActions'
import { createWebsite } from "../../actions/websiteActions";
import Select from "../../components/Select";
import UpdateConfirmed from "../../components/UpdateConfirmed";
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import { COMPANY_DETAILS_BY_ID_RESET} from '../../constants/companyConstants'
import { WEBSITE_DETAILS_BY_EMAIL_RESET} from '../../constants/websiteConstants'
import LoaderImage from "../../components/LoaderImage";
import InformationNotFilled from "../../components/InformationNotFilled";
import { urlRegex} from '../../constants/regex/regex'

import {WEBSITE_CREATE_RESET} from '../../constants/websiteConstants'
import {COMPETITOR_CREATE_RESET, COMPETITOR_DETAILS_RESET, COMPETITOR_LIST_RESET} from '../../constants/competitorConstants'


export function AddWebsiteContainer( { accessToken, name, email }) {
  const dispatch = useDispatch()
  const history = useHistory()


  const [urlIsValid, setUrlIsValid] = useState(true);
  const [urlErrorMessage, setUrlErrorMessage] = useState('');

  const [informationFilled, setInformationFilled] = useState(0)
  const [updateToggle, setUpdateToggle] =useState(false)
  //Company details
  const [companyId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('website')+8, 100))
  const [companyName, setCompanyName] = useState('')

  const companyDetailsById = useSelector(state => state.companyDetailsById)
    const {loading: loadingDetailsByIdCompany, 
        error: errorDetailsByIdCompany, 
        success: successDetailsByIdCompany, 
        company
    } = companyDetailsById

  //Website info
  // const [websiteName, setWebsiteName] = useState('')
  const [websiteURL, setWebsiteURL] = useState('')
  // const [theme, setTheme] = useState('')
  // const [active, setActive] = useState(false)
  const [industry, setIndustry] = useState('')
  const [businessModel, setBusinessModel] = useState('')
  // const [trigger_mday, setTrigger_mday] = useState('1')

  const websiteCreate = useSelector(state => state.websiteCreate)
    const {loading: loadingCreateWebsite, 
        error: errorCreateWebsite, 
        success: successCreateWebsite, 
         website: createdWebsite
    } = websiteCreate


  const nextHandler = () => {
    
    history.push(`/add-competitors/${createdWebsite.id}`)
    dispatch({type: COMPETITOR_CREATE_RESET}) 
    dispatch({type: COMPETITOR_DETAILS_RESET}) 
    dispatch({type: WEBSITE_CREATE_RESET}) 
  }

  const handleCompanyWebsite = (url) =>{
    var hostname = url;
    //case http://url.com
     // Find and remove protocol
     if (url.indexOf("://") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    const hostnameArray = hostname.split('.');
    if (hostnameArray.length === 1) return hostname;
    let i = 0;
    for (i = hostnameArray.length - 1; i > -1; i--) {
        if (hostnameArray[i].length > 2 && i !== hostnameArray.length - 1) {
            break;
        }
    }
    let tldArray = [];
    for (let j = i; j < hostnameArray.length; j++) {
        tldArray.push(hostnameArray[j]);
    }
    // return tldArray.join('.');
    var topLevelDomain = tldArray.join('.');
    var lastChar = topLevelDomain.slice(-1)
    if(lastChar === "/"){
      topLevelDomain= topLevelDomain.substr(0, topLevelDomain.length - 1); //remove last char
    }
   
    return topLevelDomain;

  
  } 


  const createWebsiteHandler = (e) => {
    e.preventDefault()

    if(websiteURL === ''  || industry === '' || businessModel === ''
   ){
      setInformationFilled(1)
    }
    else{
      const website= {
       // name: websiteName,
        website: handleCompanyWebsite(websiteURL),
        industry: industry,
        businessModel: businessModel
       // theme: theme,
        // frequency: frequency,
        // trigger_wday: trigger_wday,
        // trigger_mday: trigger_mday,
        // slack_channel: 'missing'
          }
         
      dispatch(createWebsite(accessToken, website, email, companyId))
      dispatch({type: WEBSITE_DETAILS_BY_EMAIL_RESET})
      setUpdateToggle(true)
     

    }
       
  }

  const [firstRender, setFirstRender] = useState(1)

  // function validateName(name) {
  //   if (nameRegex.test(name)) {
  //     setNameIsValid(true);
  //     setNameErrorMessage('');
  //   } else {
  //     setNameIsValid(false);
  //     setNameErrorMessage('Invalid name format');
  //   }
  // }

  function validateUrl(url) {
    if (urlRegex.test(url)) {
      setUrlIsValid(true);
      setUrlErrorMessage('');
    } else {
      setUrlIsValid(false);
      setUrlErrorMessage('Invalid url format');
    }
  }

  // function validateTheme(theme) {
  //   if (themeRegex.test(theme)) {
  //     setThemeIsValid(true);
  //     setThemeErrorMessage('');
  //   } else {
  //     setThemeIsValid(false);
  //     setThemeErrorMessage('Invalid theme format');
  //   }
  // }


  useEffect(() => {

    if(firstRender===1){
      dispatch({type: COMPANY_DETAILS_BY_ID_RESET})
      setFirstRender(firstRender+1)
    }
    
    if (accessToken && email) {
      if(successDetailsByIdCompany && !loadingDetailsByIdCompany){
        setCompanyName(company.name)
        if(successCreateWebsite){
          history.push(`/add-competitors/${createdWebsite.id}`)
          dispatch({type: COMPETITOR_CREATE_RESET}) 
          dispatch({type: COMPETITOR_DETAILS_RESET}) 
          dispatch({type: WEBSITE_CREATE_RESET}) 
          dispatch({type: COMPANY_DETAILS_BY_ID_RESET}) 
        }
      } else{
          
          dispatch(listCompanyDetailsById(accessToken,  companyId, email))
      }
    } 
  }, [ email, 
    accessToken, 
    successDetailsByIdCompany, 
    dispatch, 
    company, 
    successCreateWebsite,
    companyId,
    createdWebsite,
    firstRender,
    successCreateWebsite

  ]);

  return(
      <Content>
        {loadingDetailsByIdCompany || !successDetailsByIdCompany ||
        loadingCreateWebsite || successCreateWebsite
        ?
        <LoaderImage /> :
        errorDetailsByIdCompany ? 
        <ErrorMessage /> : 
        company.detail ? 
        <ErrorMessage message={company.detail} /> :
        <>
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold40Black>
                Add a new website to <span> </span>
              </Text.Bold40Black>
              <Text.Bold42Pink
                
              >
                {companyName}
              </Text.Bold42Pink>
              <Text.Normal14Black
              style={{marginTop: '26px'}}>
              Each website has its own dashboard. 
              </Text.Normal14Black>
              <Text.Normal14Black
                style={{
                  marginTop: '15px'
                }}
              >
              Setting up a website is very easy all we require are your website details, competitor information and your keywords. 
              </Text.Normal14Black>
              <Text.Normal14Black
              style={{color: '#C51852', fontWeight: 'bold', marginTop: '15px'}}
              >
              Basic Information
              </Text.Normal14Black>
              <Text.Normal14Black>
              This gives us everything we need to know to create the basic template of your dashboard. 
              </Text.Normal14Black>
                
            </Grid.Section>
            <Grid.Section>
              <Text.Bold26Pink>
                Basic Information
                <Text.Normal14Black>
                * indicates a required field.
                </Text.Normal14Black>
              </Text.Bold26Pink>
              {/* <Text.Bold16Black>
                Website name
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Website name name"
                type="text"
                value={websiteName}
                onChange={(e) => {
                  setWebsiteName(e.target.value);
                  validateName(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{nameErrorMessage}</span> */}
              <Text.Bold16Black>
                <Text.AsterixPurple>*</Text.AsterixPurple>
                Website URL
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="website.co.uk"
                type="text"
                value={websiteURL}
                onChange={(e) => {
                  setWebsiteURL(e.target.value);
                  validateUrl(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{urlErrorMessage}</span>
              {/* <Text.Bold16Black>
                Theme
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Custom theme for website"
                type="text"
                value={theme}
                onChange={(e) => {
                  setTheme(e.target.value);
                  validateTheme(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{themeErrorMessage}</span> */}
              
            <Select param={industry} setParam={setIndustry} list={industries} placeholder='Choose industry' title='Industry'/>
            <Select param={businessModel} setParam={setBusinessModel} list={business_model} placeholder='Choose business model' title='Business Model'/>
            {/* <Select param={trigger_mday} setParam={setTrigger_mday} list={days_of_month} placeholder='Choose day of month Trigger' title='Day of Month Trigger'/> */}
            {/* <Text.Bold16Black>
                Slack channel
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Name of the Slack channel"
                type="text"
                value={slack_channel}
                onChange={(e) => {
                  setSlack_channel(e.target.value);
                  validateSlackChannel(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{slackChannelErrorMessage}</span> */}
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
        {loadingCreateWebsite ? 
            <Loader /> :
            errorCreateWebsite ?
            <ErrorMessage /> : 
        
          <Content.ButtonsContainer>
          {/* {successCreateWebsite && <UpdateConfirmed updateToggle={updateToggle} setUpdateToggle={setUpdateToggle} 
                                  title={"Website has been saved"} subtitle={"Move to the next screen to add competitors"}/>} */}
          {informationFilled === 1 && <InformationNotFilled informationFilled={informationFilled} setInformationFilled={setInformationFilled}/>}
          {successCreateWebsite ? 
            <Button.Save 
              onClick={nextHandler}
              >
                  Next
            </Button.Save> :
            <Button.Save 
          onClick={createWebsiteHandler}
          disabled={!urlIsValid}
          >
              Save and Continue
            </Button.Save>
          }
          
          </Content.ButtonsContainer>
        }
        </Content.SectionBottom>
        </>
      }
      </Content>
  )
}
