import React, { useState } from "react";

import { roles, verticals, types } from '../../constants/utils'

import { Button, Content, General, Grid, Text } from "../../styled-components/dashboard";

import {  Field14Black } from "../../styled-components/dashboard/text/styles/text";
import { useHistory } from "react-router-dom"

export function AddKeywordsGenerateContainer({ accessToken, name, email }) {

  const history = useHistory() 
  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('keywords')+18, 100))
  const backHandler = (e) => {
    history.push(`/add-keywords/${websiteId}`)
  }

  const nextHandler = (e) => {
    history.push(`/add-keywords-success`)
  }


 


  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)


  const [isOpenVertical, setIsOpenVertical] = useState(false)
  const [selectedOptionVertical, setSelectedOptionVertical] = useState(null)

  const [isOpenType, setIsOpenType] = useState(false)
  const [selectedOptionType, setSelectedOptionType] = useState(null)

  const toggling = (e) => {
    setIsOpen(!isOpen)
}

const onOptionClicked = (e) => {
  setSelectedOption(e)
  setIsOpen(false)
}

const onOptionClickedVertical = (e) => {
  setSelectedOptionVertical(e)
  setIsOpenVertical(false)
}

const onOptionClickedType = (e) => {
  setSelectedOptionType(e)
  setIsOpenType(false)
}


const togglingVertical = (e) => {
  setIsOpenVertical(!isOpenVertical)
}

const togglingType = (e) => {
  setIsOpenType(!isOpenType)
}
  return(
      <Content
        
      >
        <Content.SectionTop
        style={{
          opacity: '.5',
          pointerEvents:'none'
        }}
        >
          <Grid
            style={{backgroundSize: '0% 6px'}}
          >
            <Grid.Section1>
              <Text.Bold16Black>
                Keyword Text
              </Text.Bold16Black>
              <Field14Black 
                placeholder="Enter keyword text"
                type="text"
              ></Field14Black>
              
              <Text.Bold16Black>
                Page URL
              </Text.Bold16Black>
              <Field14Black 
                placeholder="Enter page URL"
                type="text"
              />
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Location
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'London, England, UK'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-pink.png").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
             
              
            </Grid.Section1>
            
            <Grid.Section2>
            <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Language
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'English'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-purple.png").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Country
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'England'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-purple.png").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Devices
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'Desktop'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-purple.png").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Brand?
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'Yes'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-purple.png").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Google Domain
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'google.co.uk'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-purple.png").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
            </Grid.Section2>
            <Grid.Section3>
            <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Vertical
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={togglingVertical}>
                    <Text.Flex>
                        {selectedOptionVertical || 'Arts and Entertainment'}

                      {isOpenVertical ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-orange.svg").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpenVertical && (
                      <General.SelectList>
                        {verticals.map((vertical) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClickedVertical(vertical)
                              }
                              key={Math.random()}
                              value={vertical}
                          >
                          {vertical}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Type
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={togglingType}>
                    <Text.Flex>
                        {selectedOptionType || 'B2B'}

                      {isOpenType ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-orange.svg").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpenType && (
                      <General.SelectList>
                        {types.map((type) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClickedType(type)
                              }
                              key={Math.random()}
                              value={type}
                          >
                          {type}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Active?
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'Yes'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-orange.svg").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  SEO keyword group
                </Text.Bold16Black>

              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'Enter SEO keyword group'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-orange.svg").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
            </Grid.Section3>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          
          <Content.ButtonsContainer
            style={{
              alignItems: 'center'
            }}
          >
          <Text.Bold16Black>
                Coming soon ...  Go
              </Text.Bold16Black>
            <Button.Back
            onClick={backHandler}
            >
            Back
          </Button.Back>
          <Button.Save 
          onClick={nextHandler}
          style={{
          opacity: '.5',
          pointerEvents:'none'
        }}
          >
              Submit
            </Button.Save>
          </Content.ButtonsContainer>
          
        </Content.SectionBottom>
      </Content>  
  )
}
