import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { roles, companies } from '../../constants/utils'

import { Button, Content, General, Grid, Text } from "../../styled-components/dashboard";

import {
  listProfileDetails,
  updateProfile,
} from "../../actions/profileActions";
import { ButtonsContainer } from "../../styled-components/dashboard/content/styles/content";
import {  Field14Black } from "../../styled-components/dashboard/text/styles/text";
import Loader from "../../components/Loader";
import { changePasswordGetEmail } from "../../actions/emailSenderActions";
import {phoneNumberRegex, nameRegex} from '../../constants/regex/regex'
import UpdateConfirmed from "../../components/UpdateConfirmed";

export function ProfileContainer({ accessToken, name, email }) {
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  // user details
  const [userName, setUserName] = useState("")
  const [telephone, setTelephone] = useState("");
  // const [isActive, setIsActive] = useState(false)
  // const [isSuperuser, setIsSuperuser] = useState(false)
  // const [isAdmin, setIsAdmin] = useState(false)
  // const [isCompanyAdmin, setIsCompanyAdmin] = useState(false)
  // const [isCompanyEmployee, setIsCompanyEmployee] = useState(false)

  const [jobTitle, setJobTitle] = useState('')
  const [industry, setIndustry] = useState('')
  const [location, setLocation] = useState('Romania')
  const [identityProvider, setIdentityProvider] = useState('')
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const profileDetails = useSelector((state) => state.profileDetails);
  const {success, profile } = profileDetails;

  const organisationDetails = useSelector((state) => state.organisationDetails);
  const { 
   // loading: loadingDetailsOrganisation, 
   // error: errorDetailsOrganisation, 
    success: successDetailsOrganisation, 
    organisation } = organisationDetails;


  const profileUpdate = useSelector((state) => state.profileUpdate);
  const { 
    loading: loadingUpdateProfile,
    error: errorUpdateProfile,
    success: successUpdateProfile,
  } = profileUpdate;

  const [nameIsValid, setNameIsValid] = useState(true);
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const [jobTitleIsValid, setJobTitleIsValid] = useState(true);
  const [jobTitleErrorMessage, setJobTitleErrorMessage] = useState('');

  const [industryIsValid, setIndustryIsValid] = useState(true);
  const [industryErrorMessage, setIndustryErrorMessage] = useState('');

  function validateName(name) {
    if (nameRegex.test(name)) {
      setNameIsValid(true);
      setNameErrorMessage('');
    } else {
      setNameIsValid(false);
      setNameErrorMessage('Invalid name format');
    }
  }

  function validatePhoneNumber(phoneNumber) {
    if (phoneNumberRegex.test(phoneNumber)) {
      setPhoneIsValid(true);
      setPhoneErrorMessage('');
    } else {
      setPhoneIsValid(false);
      setPhoneErrorMessage('Invalid phone number format');
    }
  }

  function validateJobTitle(jobTitle) {
    if (nameRegex.test(jobTitle)) {
      setJobTitleIsValid(true);
      setJobTitleErrorMessage('');
    } else {
      setJobTitleIsValid(false);
      setJobTitleErrorMessage('Invalid Job Title format');
    }
  }
  const [updateToggle, setUpdateToggle] =useState(false)

  useEffect(() => {
     
    if (accessToken) {
      if (success){ 
          setTelephone(profile.telephone);
          setUserName(profile.name)
          setJobTitle(profile.jobTitle)
          setIdentityProvider(user.sub.substring(0, user.sub.indexOf('|')))
          if(successDetailsOrganisation){
            setIndustry(organisation.industry)
          }
      } else {
        dispatch(listProfileDetails(accessToken, profile.userProfile));
      }
    } else{
    }
  }, [user, accessToken, success, dispatch, profile, getAccessTokenSilently, successDetailsOrganisation]);

  //User functions
  const updateHandler = async (e) => {
    e.preventDefault();
    const data = {
      name: userName,
      email: profile.userEmail,
      telephone: telephone,
    };
    dispatch(updateProfile(accessToken, data));
    setUpdateToggle(true)
  
    setTimeout(() => {
      setUpdateToggle(false);
    }, 2000);


  };


  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const [isOpenCompany, setIsOpenCompany] = useState(false)
  const [selectedOptionCompany, setSelectedOptionCompany] = useState(null)

  const toggling = (e) => {
    setIsOpen(!isOpen)
}

const onOptionClicked = (e) => {
  setSelectedOption(e)
  setIsOpen(false)
}

const togglingCompany = (e) => {
  setIsOpenCompany(!isOpenCompany)
}

const onOptionClickedCompany = (e) => {
setSelectedOptionCompany(e)
setIsOpenCompany(false)
}

// CHANGE PASSWORD

const [changePasswordModal, setChangePasswordModal] = useState(true)

const toggleChangePassword = (e) => {
    e.preventDefault();
    const params = {
      email: profile.userEmail,
      connection: process.env.REACT_APP_AUTH_DB_CONNECTION,
    };
    dispatch(changePasswordGetEmail(accessToken, params));
    setChangePasswordModal(!changePasswordModal)
}

  return(
      <Content>
        <Content.SectionTop>
          <Grid
            style={{backgroundSize: '0% 6px'}}
          >
            <Grid.Section1>
              <Text.Bold26Pink>
                About me
              </Text.Bold26Pink>
              <Text.Bold16Black>
                Full Name
              </Text.Bold16Black>
              <Field14Black 
                placeholder="Full Name"
                type="text"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                  validateName(e.target.value);
                }}
              ></Field14Black>
              <span style={{ color: 'red' }}>{nameErrorMessage}</span>
              
              <Text.Bold16Black>
                Phone Number
              </Text.Bold16Black>
              <Field14Black 
                placeholder="Enter your phone no."
                type="text"
                value={telephone}
                onChange={(e) => {
                  setTelephone(e.target.value);
                  validatePhoneNumber(e.target.value);
                }}
              />
              <span style={{ color: 'red' }}>{phoneErrorMessage}</span>
              <Text.Bold16Black>
                Job Title
              </Text.Bold16Black>
              <Field14Black 
                placeholder="Enter your job title"
                type="text"
                value={jobTitle}
                onChange={(e) => {
                  setJobTitle(e.target.value);
                  validateJobTitle(e.target.value);
                }}
              />
              <span style={{ color: 'red' }}>{jobTitleErrorMessage}</span>
              {/* <Text.Bold16Black>
                Industry
              </Text.Bold16Black>
              <Field14Black 
                placeholder="Enter your industry"
                type="text"
                value={industry}
                readonly
              />
              <span style={{ color: 'red' }}>{industryErrorMessage}</span> */}
              
            </Grid.Section1>
            {changePasswordModal ?
            <Grid.Section2>
              <Text.Bold26Purple>
                Account Details
              </Text.Bold26Purple>
              <Text.Flex>
                <Text.Bold16BlackOpacity
                  style ={{margin: '0'}}
                >
                  Email Address
                </Text.Bold16BlackOpacity>
                <Text.Icon16Opacity src={require("../../images/dashboard/padlock.png").default} />
              </Text.Flex>
              <Text.Field14BlackOpacity
                value={profile.userEmail}
                disabled
              >
              </Text.Field14BlackOpacity>
              <Text.Normal14Black>
                  To change your email address, please contact<span>&nbsp;</span>
                  <Text.Normal14Purple href="mailto:support@luminr.ai">
                  support@luminr.ai
                  </Text.Normal14Purple>
              </Text.Normal14Black>
              {/* <Text.Bold16Black>
                Location
              </Text.Bold16Black> */}
              {/* <Text.Field14Black
                placeholder="Location here"
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
              </Text.Field14Black> */}
              <Text.Bold16Black>
                Timezone
              </Text.Bold16Black>
              <Text.Field14Black
                type="text"
                disabled
                value={timezone}
          
              >
              
                
              </Text.Field14Black> 
              {identityProvider == "auth0" ? 
                <>
                  <Text.Normal14Black>
                      To change your password, please press button bellow.
                  </Text.Normal14Black>
                  <Button.Reset
                    onClick={toggleChangePassword}
                    style={{
                      marginTop: '10px'
                    }}
                  >
                    Reset Password
                  </Button.Reset>
                  
                  </>  : "" }
            </Grid.Section2> :
            <Grid.Section2>
              <General.ResetPassword>
                <Text.Flex>
                  <Text.Bold26Orange
                    style={{color: 'white', margin: '0'}}
                  >
                    Reset Password
                  </Text.Bold26Orange>
                  <Text.Icon16 
                  onClick={()=>setChangePasswordModal(!changePasswordModal)}
                  style = {{width: '26px', height: '26px'}}
                  src={require("../../images/dashboard/close.png").default} />
                </Text.Flex>
                <Text.Normal14Black
                  style ={{color: 'white'}}
                >
                  An email will be sent to {email} with instructions on how to change your password.
                </Text.Normal14Black>
                <Text.Normal14Black
                  style ={{color: 'white'}}
                >
                  If you don't get an email: Check your Spam or Bulk Mail folders.
                  Add serpr@serpr.ai to your address book
                </Text.Normal14Black>
                <Button.Reset
                  style = {{ color: '#222222', background: 'white'}}
                  onClick={toggleChangePassword}
                >
                  Reset Password
                </Button.Reset>
              </General.ResetPassword>
            </Grid.Section2>
            }
           {false && <Grid.Section3>
              <Text.Bold26Orange>
                Permissions
              </Text.Bold26Orange>
              <Text.Bold16Black>
                Coming soon ...
              </Text.Bold16Black>
              {false &&
              <>
              <Text.Flex>
                <Text.Bold16Black
                style ={{margin: '0'}}
                >
                  Your Role
                </Text.Bold16Black>
                <Text.Icon16 src={require("../../images/dashboard/edit.png").default} />
              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={toggling}>
                    <Text.Flex>
                        {selectedOption || 'Roles'}

                      {isOpen ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-orange.svg").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpen && (
                      <General.SelectList>
                        {roles.map((role) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClicked(role)
                              }
                              key={Math.random()}
                              value={role}
                          >
                          {role}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              <Text.Flex>
                <Text.Bold16Black
             
                >
                  Your Companies
                </Text.Bold16Black>
                <Text.Icon16 src={require("../../images/dashboard/edit.png").default} />
              </Text.Flex>
              <General.SelectContainer>
                  <General.SelectHeader onClick={togglingCompany}>
                    <Text.Flex>
                        {selectedOptionCompany || 'Companies'}

                      {isOpenCompany ? 
                      <Text.IconSelect src={require("../../images/dashboard/up-arrow-orange.svg").default} /> :
                      <Text.IconSelect src={require("../../images/dashboard/down-arrow-orange.svg").default} />
                      }
                    </Text.Flex>
                  </General.SelectHeader>
                    {isOpenCompany && (
                      <General.SelectList>
                        {companies.map((company) => (
                          <General.SelectListItem
                              onClick={(e) =>
                                  onOptionClickedCompany(company)
                              }
                              key={Math.random()}
                              value={company}
                          >
                          {company}
                          </General.SelectListItem>
                        ))}
                      </General.SelectList>

                    )}
              </General.SelectContainer>
              </>
              }
            </Grid.Section3>}
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          {loadingUpdateProfile ?
          <Loader /> :
          <ButtonsContainer>
          {successUpdateProfile && !errorUpdateProfile && <UpdateConfirmed updateToggle={updateToggle} setUpdateToggle={setUpdateToggle}/>}
            {/* <Button.Back>
            Back
          </Button.Back> */}
          <Button.Save onClick={updateHandler} disabled={!phoneIsValid | !nameIsValid | !jobTitleIsValid | !industryIsValid}>
              Save
            </Button.Save>
          </ButtonsContainer>
        }
        </Content.SectionBottom>

      </Content>  
  )
}
