export const PAYMENT_LIST_REQUEST      = 'PAYMENT_LIST_REQUEST'
export const PAYMENT_LIST_SUCCESS      = 'PAYMENT_LIST_SUCCESS'
export const PAYMENT_LIST_FAIL         = 'PAYMENT_LIST_FAIL'
export const PAYMENT_LIST_RESET         = 'PAYMENT_LIST_RESET'

export const PAYMENT_DETAILS_REQUEST   = 'PAYMENT_DETAILS_REQUEST'
export const PAYMENT_DETAILS_SUCCESS   = 'PAYMENT_DETAILS_SUCCESS'
export const PAYMENT_DETAILS_FAIL      = 'PAYMENT_DETAILS_FAIL'
export const PAYMENT_DETAILS_RESET      = 'PAYMENT_DETAILS_RESET'

export const PAYMENT_DETAILS_BY_ID_REQUEST   = 'PAYMENT_DETAILS_BY_ID_REQUEST'
export const PAYMENT_DETAILS_BY_ID_SUCCESS   = 'PAYMENT_DETAILS_BY_ID_SUCCESS'
export const PAYMENT_DETAILS_BY_ID_FAIL      = 'PAYMENT_DETAILS_BY_ID_FAIL'
export const PAYMENT_DETAILS_BY_ID_RESET      = 'PAYMENT_DETAILS_BY_ID_RESET'

export const PAYMENT_CREATE_REQUEST    = 'PAYMENT_CREATE_REQUEST'
export const PAYMENT_CREATE_SUCCESS    = 'PAYMENT_CREATE_SUCCESS'
export const PAYMENT_CREATE_FAIL       = 'PAYMENT_CREATE_FAIL'
export const PAYMENT_CREATE_RESET      = 'PAYMENT_CREATE_RESET'

export const PAYMENT_UPDATE_STATUS_REQUEST    = 'PAYMENT_UPDATE_STATUS_REQUEST'
export const PAYMENT_UPDATE_STATUS_SUCCESS    = 'PAYMENT_UPDATE_STATUS_SUCCESS'
export const PAYMENT_UPDATE_STATUS_FAIL       = 'PAYMENT_UPDATE_STATUS_FAIL'
export const PAYMENT_UPDATE_STATUS_RESET      = 'PAYMENT_UPDATE_STATUS_RESET'

export const PAYMENT_DELETE_REQUEST    = 'PAYMENT_DELETE_REQUEST'
export const PAYMENT_DELETE_SUCCESS    = 'PAYMENT_DELETE_SUCCESS'
export const PAYMENT_DELETE_FAIL       = 'PAYMENT_DELETE_FAIL'
export const PAYMENT_DELETE_RESET      = 'PAYMENT_DELETE_RESET'