import styled from 'styled-components/macro'
import * as pallete from '../../../constants/theme/colours'

export const Background = styled.div`
    display: flex;
    flex-direction: column;
    background: url('../../../images/signup-bg-red-gray.svg') center no-repeat;
    background-size: auto;

    // @media (max-width: 1030px) {
    //     background: url('/images/bg-dashes.png') center no-repeat,
    //     url('/images/dashed-pink.svg') top right 150px no-repeat;
    //     background-size: auto, 20px;
    // }
`

export const Container = styled.div`
    display: flex;
    padding: 80px 0 80px;
    margin: auto;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 1000px) {
        padding: 70px 30px;
    }
`

export const Logo = styled.img`
    height: 22px;

    @media (max-width: 768px) {
        height: 38px;
    }
`

export const Title = styled.h1`
    font-size: 52px;
    font-family: tenon bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: ${pallete.BLACK};
    padding: 20px 0 40px;

    @media (max-width: 768px) {
        font-size: 88px;
        line-height: 94px;
    }
`

export const ButtonGroup = styled.div`
    text-align: center;
    display: inline-block;
    position: absolute;
    top: 90%;
    left: 50%;
`

export const ButtonBack = styled.div`
    font-size: 18px;
    font-family: tenon regular, sans-serif;
    font-style: normal;
    background-color: ${pallete.PINK};
    display: block;
    color: white;
    padding: 18px 38px 18px 38px;
    cursor: pointer;
    margin-right: 382px;
    margin-top: 660px;
    position: absolute;

    &:hover {
        background: #f40612;
    }

    @media (max-width: 570px) {
        margin-top: 800px;
    }
`

export const ButtonNext = styled(ButtonBack)`
    background-color: ${pallete.GRAY};
    opacity: 50%;
    margin-left: 767px;
    position: absolute;
    display:inline &:hover {
        opacity: 100%;
    }
`
export const Input = styled.input`
    font-size: 17px;
    font-family: Open Sans Regular;
    font-weight: normal;
    // margin-bottom: 30px;
    text-align: center;
    padding: 54px;
    // user-select: none;
    // padding: 15px;
    border: 1.5px solid ${pallete.PINK};
    width: 500px;
    height: 80px;
    // position: fixed;
`

export const Text = styled.div`
    font-size: 17px;
    font-family: tenon bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 20px;
    background-color: ${pallete.WHITE};
    border: 1.5px solid ${pallete.PINK};
    width: 500px;
    height: 300px;
    margin: 0;
`

export const LineUp = styled.div`
    border-left: 1px solid ${pallete.PINK};
    height: 40px;
`

export const LineDown = styled.div`
    border-left: 1px solid ${pallete.PINK};
    height: 220px;
    position: relative;
`

export const LineBottom = styled.div`
    border-bottom: 10px solid ${pallete.PINK};
    width: 500px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        border-bottom: 10px solid white;
        border-left: 10px solid ${pallete.PINK};
        width: 0;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        border-bottom: 10px solid white;
        border-right: 10px solid ${pallete.PINK};
        width: 0;
    }
`
