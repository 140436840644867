import styled from "styled-components"



export const Container = styled.div`
    display: flex;
   height: 100%;
   min-width: 1000px;
    flex-direction: column;
   // background: red;
    justify-content: space-between;
`


export const SectionTop = styled.div`
height: 100%;

position: relative;
overflow: hidden;

// height: -webkit-calc(100% - 80px);
// height: -moz-calc(100% - 80px);
// height: calc(100% - 80px);

   

`

export const SectionTopWithBackground = styled.div`
height: 100%;
position: relative;
    overflow: hidden;
    background-image: url(${require('../../../../images/dashboard/EMPTY-DASH-BG-compressed.png').default});
    background-size: cover;
// height: -webkit-calc(100% - 80px);
// height: -moz-calc(100% - 80px);
// height: calc(100% - 80px);
   

`

export const SectionBottom = styled.div`
    height: 80px;
   // background: blue;
    padding: 16px 50px; 
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    box-shadow: 0px 0px 10px #00000029;

`

export const ButtonsContainer = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
`

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
`

export const Relative = styled.div`
    position: relative;
`

export const Absolute = styled.div`
    position: absolute;
    z-index: 100;
    width: 100%;
    background: #F6F6F6;
    box-shadow: 2px 2px 2px lightgray;
`

export const TickBox = styled.div`
    width: 20px;
    height: 20px;
    border: 1px solid #d1d1d1;
    margin-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    &:hover{
        cursor: pointer;
    }
`

export const Icon = styled.img`
    width: 10px;
    height: 10px;
    margin: 0;

    &:hover {
        cursor: pointer;
    }
`