import React, {useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterContainer } from "./filters/Filter";
import {  Content } from "../../styled-components/visualization";
import { FingerprintContainer } from "./coverageComponents/Fingerprint";
import { getSerpComposition, bigKeywordRankingOpp, retrieveSWOT, getContentExplorer, featuredSnippetOpp, getFilters, getSerpCompositionInclude} from "../../actions/visualization/dataScienceAPIsActions";
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import { KeywordRankingsOpportunitiesContainer, } from "./coverageComponents/KeywordRankingsOpportunities";
import { SwotAnalysisContainer } from "./coverageComponents/SwotAnalysis"; 
import { ContentCreationOpportunitiesContainer2 } from "./coverageComponents/contentCreationOpportunities2";
import { FeatureSnippetContainer } from "./coverageComponents/FeatureSnippet";
import { FilterContext } from "../../contexts/FilterContext";
import { CompanyContext } from "../../contexts/CompanyContext";
import { useHistory } from "react-router-dom";
 

export function CoverageContainer({ accessToken, email, websiteId}) {
  const dispatch = useDispatch() 
  let history = useHistory();
  var paramsFilters,paramsSerpCompositionInclude,paramsSerpComposition,paramsBigKeywordRanking,paramsRetrieveSWOT,paramsContentExplorer,paramsFeaturedSnippet;
  const [companyContextReady, setCompanyContextReady] = useState(false);
  const [extendParent, setExtendParent] = useState(false);//== false : means that Opportunities Radar will be extended by Audience
  const [firstRender, setFirstRender] = useState(true)
  const [initialSWOTResults, setInitialSWOTResults] = useState([]);
  const [pageNoFeatureSnippet, setPageNoFeatureSnippet] = useState(1);


  const websiteTrackingStatus = useSelector((state) => state.websiteTrackingStatus)
  const {
      loading: loadingWebsiteTrackingStatus,
      error: errorWebsiteTrackingStatus,
      success: successWebsiteTrackingStatus,
      websiteTrackingStatusResults,
  } = websiteTrackingStatus

  const {filter_criteria,setFilter_criteria,
    initial_array_keyword_parent,
    initial_array_keyword_child,
    initial_array_keyword_portfolio,
    initial_array_keyword_subportfolio,
    initial_array_keyword,
    initial_array_country,
    initial_array_location,
    initial_array_language,  
    initial_array_device,
    initial_array_domain,
    initial_array_component_clean
} = useContext(FilterContext);

  const {date, companyDomain, companyId4Api,companyIndustry} = useContext(CompanyContext);

  useEffect( ()=>{
    if(date && companyDomain && companyId4Api && companyIndustry){
      setCompanyContextReady(true)
    }
  },[date,companyDomain, companyId4Api,companyIndustry])

  // Filters
  const filters = useSelector((state) => state.filters)
  const {
      loading: loadingFilters,
      error: errorFilters,
      success: successFilters, 
      filtersResults,
  } = filters

  const trackingSummaryVar = useSelector((state) => state.trackingSummary)
  const {
      loading: loadingTrackingSummary,
      error: errorTrackingSummary,
      success: successTrackingSummary,
      trackingSummaryResults,
  } = trackingSummaryVar

  // 7. Overall SERP Composition
  const serpCompositionInclude = useSelector((state) => state.serpCompositionInclude)
  const {
      loading: loadingSerpCompositionInclude,
      error: errorSerpCompositionInclude,
      success: successSerpCompositionInclude,
      serpCompositionIncludeResults,
  } = serpCompositionInclude

  const serpComposition = useSelector((state) => state.serpComposition)
  const {
      loading: loadingSerpComposition,
      error: errorSerpComposition,
      success: successSerpComposition,
      serpCompositionResults,
  } = serpComposition

  // 13. BIG KEYWORD RANKING OPPORTUNITIES
  const bigKeywordRanking = useSelector((state) => state.bigKeywordRanking)
  const {
      loading: loadingBigKeywordRanking,
      error: errorBigKeywordRanking,
      success: successBigKeywordRanking,
      bigKeywordRankingOppResults,
  } = bigKeywordRanking

   // 4. RETRIEVE SWOT MAP
   const SWOT = useSelector((state) => state.SWOT)
   const {
       loading: loadingRetrieveSWOT,
       error: errorRetrieveSWOT,
       success: successRetrieveSWOT,
       retrieveSWOTResults,
   } = SWOT

   // 5. Content Explorer
   const contentExplorer = useSelector((state) => state.contentExplorer)
   const {
       loading: loadingContentExplorer,
       error: errorContentExplorer,
       success: successContentExplorer,
       contentExplorerResults,
   } = contentExplorer

    // 15. FEATURED SNIPPET OPPORTUNITIES
    const featuredSnippet = useSelector((state) => state.featuredSnippet)
    const {
        loading: loadingFeaturedSnippet,
        error: errorFeaturedSnippet,
        success: successFeaturedSnippet,
        featuredSnippetOppResults,
        featuredSnippetOppTotalRows,
    } = featuredSnippet

    
  useEffect(() => { 
    window.scrollTo(0, 0);
    if (accessToken && email){
      if(companyContextReady ){
        // if(companyId4Api==='1'){
        //   history.push("/");
        // } 
         paramsFilters = {
          "company_id": companyId4Api,
          "start_date": date[0],
          "end_date": date[1],
          "filter_criteria": filter_criteria
        }
         paramsSerpCompositionInclude = {
          "industry": companyIndustry,
          "domain":companyDomain,
          "start_date": date[0],
          "end_date": date[1],
          "filter_criteria": filter_criteria,
          "client": "include"
        }
         paramsSerpComposition = {
          "industry": companyIndustry,
          "domain":companyDomain,
          "start_date": date[0],
          "end_date": date[1],
          "filter_criteria": filter_criteria,
          "client": "exclude"
        }
       
         paramsBigKeywordRanking = {
          "company_id": companyId4Api,
          "start_date": date[0],
          "end_date": date[1],
          "limit": 20,
          "filter_criteria": filter_criteria
        }
         paramsRetrieveSWOT = {
          //  "first_dimension": extendParent ? 'keyword_group_1' : 'keyword_group_2',
          //  "second_dimension": "keyword_group_3",
          "slices" : extendParent ? ['keyword_group_1','keyword_group_3'] :['keyword_group_1','keyword_group_2','keyword_group_3'] ,
           "company_id": companyId4Api,
           "start_date": date[0],
           "end_date": date[1],
           "limit": 20,
           "filter_criteria": filter_criteria
        }
         paramsContentExplorer = {
          "company_id": companyId4Api,
          "start_date": date[0],
          "end_date": date[1],
          "sort_by": "serp_real_estate",
          "limit": 20,
          "filter_criteria": filter_criteria
        }
         paramsFeaturedSnippet = {
          "company_id": companyId4Api,
          "start_date": date[0],
          "end_date": date[1],
          "page_no": pageNoFeatureSnippet,
          "max_results":100,
          "lowest_position": 5,
          "keyword_overlap_percentile": 90,
          "position_split": 3,
          "filter_criteria": filter_criteria
        }
      }

      
      if(successBigKeywordRanking){
      }
      else{
        if(!loadingBigKeywordRanking && paramsBigKeywordRanking){
          dispatch(bigKeywordRankingOpp(accessToken, paramsBigKeywordRanking, email))
        }
      }
      if(successSerpComposition){
      }else{
        if(!loadingSerpComposition && paramsSerpComposition){
          dispatch(getSerpComposition(accessToken, paramsSerpComposition, email))
        }
      }
      

      if(successSerpCompositionInclude){
      }else{
        if(!loadingSerpCompositionInclude && paramsSerpCompositionInclude){
          dispatch(getSerpCompositionInclude(accessToken, paramsSerpCompositionInclude, email))
        }
      }
     

     if(successRetrieveSWOT){
      setInitialSWOTResults(retrieveSWOTResults)
    }else{
      if(!loadingRetrieveSWOT && paramsRetrieveSWOT){
        dispatch(retrieveSWOT(accessToken, paramsRetrieveSWOT, email))
      }
    }

    if(successContentExplorer){
    }else{
      if(!loadingContentExplorer && paramsContentExplorer){
        dispatch(getContentExplorer(accessToken, paramsContentExplorer, email))
      }
    }

    if(successFeaturedSnippet){
    }else{
      if(!loadingFeaturedSnippet && paramsFeaturedSnippet){
        dispatch(featuredSnippetOpp(accessToken, paramsFeaturedSnippet, email))
      }
    }

    if(successFilters ){

      if(successWebsiteTrackingStatus && websiteTrackingStatusResults.status === 'ready' && filtersResults[0]){
      if(firstRender && initial_array_keyword_parent[0] === undefined ){
      setFirstRender(false)
      filtersResults[0].focus__keyword_parent.map((f, i) =>(
       initial_array_keyword_parent.push(f)
      ))

      filtersResults[0].focus__keyword_child.map((f, i) =>(
        initial_array_keyword_child.push(f)
       ))

       filtersResults[0].focus__keyword_portfolio.map((f, i) =>(
        initial_array_keyword_portfolio.push(f)
       ))

       filtersResults[0].focus__keyword_subportfolio.map((f, i) =>(
        initial_array_keyword_subportfolio.push(f)
       ))

       filtersResults[0].focus__keyword.map((f, i) =>(
        initial_array_keyword.push(f)
       ))

       filtersResults[0].localisation__country.map((f, i) =>(
        initial_array_country.push(f)
       ))

       filtersResults[0].localisation__language.map((f, i) =>(
        initial_array_language.push(f)
       ))

       filtersResults[0].localisation__google_domain.map((f, i) =>(
        initial_array_location.push(f)
       ))

       filtersResults[0].localisation__device.map((f, i) =>(
        initial_array_device.push(f)
       ))

       filtersResults[0].serp__domain.map((f, i) =>(
        initial_array_domain.push(f)
       ))

       filtersResults[0].serp__component_clean.map((f, i) =>(
        initial_array_component_clean.push(f)
       ))
     
    }
  }
    }else{
      if(!loadingFilters && paramsFilters){
        dispatch(getFilters(accessToken, paramsFilters, email))
      }
    }

    } 
   },
   [
    accessToken, 
    successSerpComposition,
    successBigKeywordRanking,
    successContentExplorer,
    successFilters,
    dispatch, 
    email,
    firstRender,
    filter_criteria[0].elements[0],
    successTrackingSummary,
    successWebsiteTrackingStatus,
    companyContextReady
  ])
  
  useEffect( ()=>{
    if(successRetrieveSWOT){
      setInitialSWOTResults(retrieveSWOTResults)
    }
  },[successRetrieveSWOT])
 
  useEffect(() => { 
    paramsRetrieveSWOT = {
      "slices" : extendParent ? ['keyword_group_1','keyword_group_3'] :['keyword_group_1','keyword_group_2','keyword_group_3'] ,
       "company_id": companyId4Api,
       "start_date": date[0],
       "end_date": date[1],
       "limit": 20,
       "filter_criteria": filter_criteria
    }
    if(companyContextReady && paramsRetrieveSWOT){
      dispatch(retrieveSWOT(accessToken, paramsRetrieveSWOT, email))
    }
   },
   [
    extendParent
  ])
  

  //pagination Feature snippet Opportunities
  useEffect( ()=>{
    paramsFeaturedSnippet = {
      "company_id": companyId4Api,
      "start_date": date[0],
      "end_date": date[1],
      "page_no": pageNoFeatureSnippet,
      "max_results":100,
      "lowest_position": 5,
      "keyword_overlap_percentile": 90,
      "position_split": 3,
      "filter_criteria": filter_criteria
    }
    if(pageNoFeatureSnippet && companyContextReady){
      dispatch(featuredSnippetOpp(accessToken, paramsFeaturedSnippet, email))
    }

  },[pageNoFeatureSnippet] )

  return ( 
    <Content>
      {loadingSerpComposition || !successSerpComposition ||
      loadingSerpCompositionInclude || !successSerpCompositionInclude ||
      loadingBigKeywordRanking || !successBigKeywordRanking ||
      loadingContentExplorer || !successContentExplorer ||
      loadingFilters || !successFilters

      ? 
      <LoaderImage height={'85vh'}/> : 
      errorSerpComposition || errorSerpCompositionInclude || errorBigKeywordRanking  || errorContentExplorer || errorFilters
      ? 
      <ErrorMessage /> :
      <>
      {filtersResults[0] && filtersResults.detail===undefined && websiteTrackingStatusResults.status==='ready' &&
          <FilterContainer 
          filtersResults={filtersResults} 
          filter_criteria={filter_criteria}
          setFirstRender={setFirstRender}
         setFilter_criteria={setFilter_criteria}
          />
          }
        <Content.FlexColumn
          style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
          <FingerprintContainer serpCompositionResults={serpCompositionResults} serpCompositionIncludeResults={serpCompositionIncludeResults} />
        
        </Content.FlexColumn>
        <Content.FlexColumn
        style={{
            justifyContent: 'space-between',
            marginTop: '3%',
          }}
        >
        {/* High-Volume CTR Booster */}
        <KeywordRankingsOpportunitiesContainer bigKeywordRankingOppResults={bigKeywordRankingOppResults}/> 
        </Content.FlexColumn>
        <Content.FlexColumn
        style={{
            justifyContent: 'space-between',
            marginTop: '3%',
            maxHeight:'720px',
            overflow:'hidden'
          }}
        >
          {/* Opportunities Radar */}
          <SwotAnalysisContainer 
          initialSWOTResults={initialSWOTResults}
          loadingRetrieveSWOT={loadingRetrieveSWOT} 
          successRetrieveSWOT={successRetrieveSWOT}  
          errorRetrieveSWOT={errorRetrieveSWOT}
          retrieveSWOTResults={retrieveSWOTResults} 
          extendParent={extendParent} setExtendParent={setExtendParent}/> 
          
        
        
        
        </Content.FlexColumn>
        <Content.FlexRow
          style={{
              marginTop: '3%',
            }}
        >
        {/* Top Content Opportunities */}
        <ContentCreationOpportunitiesContainer2 contentExplorerResults={contentExplorerResults}/>
        
        </Content.FlexRow>
        <Content.FlexRow
          style={{
              marginTop: '3%',
            }}
        >
        {/* Featured Snippet Takeovers */}
        <FeatureSnippetContainer 
        loadingFeaturedSnippet={loadingFeaturedSnippet}
        successFeaturedSnippet={successFeaturedSnippet}
        featuredSnippetOppResults={featuredSnippetOppResults} 
        featuredSnippetOppTotalRows = {featuredSnippetOppTotalRows}
        pageNoFeatureSnippet={pageNoFeatureSnippet}
        setPageNoFeatureSnippet={setPageNoFeatureSnippet}
        />
        
        </Content.FlexRow>
      </>
      }
      
    </Content>
  )
}
