import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { 
    profileListReducer,
    profileCreateReducer,
    profileDetailsReducer,
    profileDetailsByOrganisationEmailReducer,
    profileUpdateReducer,
    profileRightsUpdateReducer,
    profileUpdateByAdminReducer,
} from './reducers/profileReducers'

import { 
    organisationCreateReducer,
    organisationDetailsReducer,
    organisationDetailsByIdReducer,
    organisationUpdateReducer,
    organisationListReducer,
 } from './reducers/organisationReducers'

 import { 
    companyCreateReducer,
    companyDetailsReducer,
    companyDetailsByIdReducer,
    companyUpdateReducer,
    companyListReducer,
    companyDeleteReducer,
 } from './reducers/companyReducers'

 import { 
    websiteCreateReducer,
    websiteDetailsReducer,
    websiteDetailsByIdReducer,
    websiteDetailsByEmailReducer,
    websiteUpdateReducer,
    websiteListReducer,
    websiteDeleteReducer,
 } from './reducers/websiteReducers'

 import { 
    competitorCreateReducer,
    competitorDetailsReducer,
    competitorDetailsByIdReducer,
    competitorUpdateReducer,
    competitorListReducer,
    competitorDeleteReducer,
 } from './reducers/competitorReducers'

 import { 
    keywordCreateReducer,
    keywordDetailsReducer,
    keywordDetailsWithoutPaginationReducer,
    keywordDetailsByIdReducer,
    keywordUpdateReducer,
    keywordListReducer,
    keywordSortedDetailsReducer,
    keywordDeleteReducer,
 } from './reducers/keywordReducers'

 import { 
   keyword_mmCreateReducer,
   keyword_mmDetailsReducer,
   keyword_mmDetailsWithoutPaginationReducer,
   keyword_mmDetailsByIdReducer,
   keyword_mmUpdateReducer,
   keyword_mmDeleteReducer,
} from './reducers/keyword_mmReducers'

import { 
   keywordProfileCreateReducer,
   keywordProfileDetailsReducer,
   keywordProfileDetailsByIdReducer,
   keywordProfileUpdateReducer,
   keywordProfileDeleteReducer,
} from './reducers/keywordProfileReducers'

 import { 
   generateKeywordsReducer
} from './reducers/generateKeywordsReducers'

import { 
   parentCreateReducer,
   parentDetailsReducer,
   parentDetailsByIdReducer,
   parentUpdateReducer,
   parentListReducer,
} from './reducers/parentReducers'

import { 
   portfolioCreateReducer,
   portfolioDetailsReducer,
   portfolioDetailsByIdReducer,
   portfolioUpdateReducer,
   portfolioListReducer,
} from './reducers/portfolioReducers'

import { 
   paymentCreateReducer,
   paymentDetailsReducer,
   paymentDetailsByIdReducer,
   paymentUpdateStatusReducer,
   paymentListReducer,
} from './reducers/paymentReducers'

import { 
   subscriptionCreateReducer,
   subscriptionDetailsReducer,
   subscriptionDetailsByIdReducer,
   subscriptionUpdateStatusReducer,
   subscriptionListReducer,
} from './reducers/subscriptionReducers'

// import { 
//    categoryCreateReducer,
//    categoryDetailsReducer,
//    categoryDetailsByIdReducer,
//    categoryUpdateReducer,
//    categoryListReducer,
// } from './reducers/categoryReducers'

// import { 
//    itemCreateReducer,
//    itemDetailsReducer,
//    itemDetailsByIdReducer,
//    itemUpdateReducer,
//    itemListReducer,
// } from './reducers/itemReducers'

import {
    createLogReducer,
    logsListReducer,
    logsListByEmailReducer,
    listLastNLogsReducer,
    logsListByFilterReducer,
    actionTypeReducer,
    logTypeReducer
} from './reducers/loggingsystem/loggingSystemReducers'

import {
   createPerformanceLogReducer
} from './reducers/loggingsystem/performanceLoggingReducers'

import {
    numberLogsReducer,
    numberErrorsReducer,
    numberWarningsReducer,
    numberInfoSuccessReducer,
} from './reducers/loggingsystem/statisticsReducers'

import { authUserListReducer } from './reducers/loggingsystem/authUserReducers'

import {  
   changePasswordReducer, 
   sendEmailVerificationReducer 
} from './reducers/emailSenderReducers'

import { 
   serpLeagueReducer,
   readRankingsReducer,
   readSREReducer,
   retrieveSWOTReducer,
   contentExplorerReducer,
   industryBenchmarksPowerReducer,
   industryBenchmarksStatsReducer,
   serpCompositionReducer,
   serpCompositionIncludeReducer,
   serpCompositionForBenchmarksReducer,
   serpRealEstateScoreReducer,
   competitorSwotHeatmapReducer,
   competitorSwotLandingPagesReducer,
   hvnScoreReducer,
   totalSearchCtrReducer,
   bigKeywordRankingOppReducer,
   competitorLandscapeReducer,
   featuredSnippetOppReducer,
   monthlySeoPpcCompetitorReducer,
   monthlySeoPpcCompetitorNumberReducer,
   industryBenchmarksChannelReducer,
   trackingSummaryReducer,
   competitorChannelReducer,
   serpMockupReducer,
   getFiltersReducer,
   getWebsiteTrackingStatusReducer,
   getSerprScore100Reducer,
   getSerprScoreReducer
} from './reducers/visualization/dataScienceAPIsReducers'


const reducer = combineReducers({
    profileList: profileListReducer,
    profileCreate: profileCreateReducer,
    profileDetails: profileDetailsReducer,
    profileDetailsByOrganisationEmail: profileDetailsByOrganisationEmailReducer,
    profileUpdate: profileUpdateReducer,
    profileRightsUpdate: profileRightsUpdateReducer,
    profileUpdateByAdmin: profileUpdateByAdminReducer,

    organisationCreate: organisationCreateReducer,
    organisationUpdate: organisationUpdateReducer,
    organisationDetails: organisationDetailsReducer,
    organisationDetailsById: organisationDetailsByIdReducer,
    organisationList: organisationListReducer,

    companyCreate: companyCreateReducer,
    companyUpdate: companyUpdateReducer,
    companyDetails: companyDetailsReducer,
    companyDetailsById: companyDetailsByIdReducer,
    companyList: companyListReducer,
    companyDelete: companyDeleteReducer,

    websiteCreate: websiteCreateReducer,
    websiteUpdate: websiteUpdateReducer,
    websiteDetails: websiteDetailsReducer,
    websiteDetailsById: websiteDetailsByIdReducer,
    websiteDetailsByEmail: websiteDetailsByEmailReducer,
    websiteList: websiteListReducer,
    websiteDelete: websiteDeleteReducer,

    competitorCreate: competitorCreateReducer,
    competitorUpdate: competitorUpdateReducer,
    competitorDetails: competitorDetailsReducer,
    competitorDetailsById: competitorDetailsByIdReducer,
    competitorList: competitorListReducer,
    competitorDelete: competitorDeleteReducer,

    keywordCreate: keywordCreateReducer,
    keywordUpdate: keywordUpdateReducer,
    keywordDetails: keywordDetailsReducer,
    keywordDetailsWithoutPagination: keywordDetailsWithoutPaginationReducer,
    keywordSortedDetails: keywordSortedDetailsReducer,
    keywordDetailsById: keywordDetailsByIdReducer,
    keywordList: keywordListReducer,
    keywordDelete: keywordDeleteReducer,

    keyword_mmCreate: keyword_mmCreateReducer,
    keyword_mmUpdate: keyword_mmUpdateReducer,
    keyword_mmDetails: keyword_mmDetailsReducer,
    keyword_mmDetailsWithoutPagination: keyword_mmDetailsWithoutPaginationReducer,
    keyword_mmDetailsById: keyword_mmDetailsByIdReducer,
    keyword_mmDelete: keyword_mmDeleteReducer,

    keywordProfileCreate: keywordProfileCreateReducer,
    keywordProfileUpdate: keywordProfileUpdateReducer,
    keywordProfileDetails: keywordProfileDetailsReducer,
    keywordProfileDetailsById: keywordProfileDetailsByIdReducer,
    keywordProfileDelete: keywordProfileDeleteReducer,

    generateKeywordsList: generateKeywordsReducer ,

    parentCreate: parentCreateReducer,
    parentUpdate: parentUpdateReducer,
    parentDetails: parentDetailsReducer,
    parentDetailsById: parentDetailsByIdReducer,
    parentList: parentListReducer,

    portfolioCreate: portfolioCreateReducer,
    portfolioUpdate: portfolioUpdateReducer,
    portfolioDetails: portfolioDetailsReducer,
    portfolioDetailsById: portfolioDetailsByIdReducer,
    portfolioList: portfolioListReducer,

    paymentCreate: paymentCreateReducer,
    paymentUpdateStatus: paymentUpdateStatusReducer,
    paymentDetails: paymentDetailsReducer,
    paymentDetailsById: paymentDetailsByIdReducer,
    paymentList: paymentListReducer,

    subscriptionCreate: subscriptionCreateReducer,
    subscriptionUpdateStatus: subscriptionUpdateStatusReducer,
    subscriptionDetails: subscriptionDetailsReducer,
    subscriptionDetailsById: subscriptionDetailsByIdReducer,
    subscriptionList: subscriptionListReducer,

    authUsersList: authUserListReducer,

    //logging system
    createLog: createLogReducer,
    logsList: logsListReducer,
    logsListByEmail: logsListByEmailReducer,
    listLastNLogs: listLastNLogsReducer,
    logsListByFilter: logsListByFilterReducer,
    actionTypeList: actionTypeReducer,
    logTypeList: logTypeReducer,

    numberLogs: numberLogsReducer,
    numberErrors: numberErrorsReducer,
    numberWarnings: numberWarningsReducer,
    numberInfoSuccess: numberInfoSuccessReducer,

    createPerformanceLog: createPerformanceLogReducer,

    changePassword: changePasswordReducer,
    sendEmailVerification: sendEmailVerificationReducer,

    //visualization
   serpLeague: serpLeagueReducer,
   Rankings: readRankingsReducer,
   filters: getFiltersReducer,
   SRE: readSREReducer,
   SWOT: retrieveSWOTReducer,
   contentExplorer: contentExplorerReducer,
   industryBenchmarksPower: industryBenchmarksPowerReducer,
   industryBenchmarksStats: industryBenchmarksStatsReducer,
   serpComposition: serpCompositionReducer,
   serpCompositionInclude: serpCompositionIncludeReducer,
   serpCompositionForBenchmarks: serpCompositionForBenchmarksReducer,
   serpRealEstateScore: serpRealEstateScoreReducer,
   swotHeatmap: competitorSwotHeatmapReducer,
   swotLandingPages: competitorSwotLandingPagesReducer,
   hvnScore: hvnScoreReducer,
   searchCtr: totalSearchCtrReducer,
   bigKeywordRanking: bigKeywordRankingOppReducer,
   competitorLandscape: competitorLandscapeReducer,
   featuredSnippet: featuredSnippetOppReducer,
   monthlySeoPpc: monthlySeoPpcCompetitorReducer,
   monthlySeoPpcNumber: monthlySeoPpcCompetitorNumberReducer,
   industryBenchmarksChannel: industryBenchmarksChannelReducer,
   trackingSummary: trackingSummaryReducer,
   competitorChannel: competitorChannelReducer,
   serpMockup: serpMockupReducer,
   websiteTrackingStatus: getWebsiteTrackingStatusReducer,
   serprScore100:getSerprScore100Reducer,
   serprScore:getSerprScoreReducer
})

const initialState = {}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store