import React from "react"
import { Container, Bold50Black, Medium40Purple, Medium30Black, Medium22Black,
    Regular18Purple, Regular16Black, Regular13Grey, Regular18PurpleBorder,
    Medium12BlackOpacity20, Medium31BlackOpacity20, Regular18Grey, Medium40Black,
    Light20DarkPurple, Regular18DarkBlackBorder, GreenRegular14, RedRegular14,
    Regular14Black, Bold30Black, Regular15White, Bold15White, Regular12White,
    Bold15Black, Regular15Black, Regular16Purple, InputRegular16Black,
    Medium30Green, Medium30Red, Medium66White, Light20White, Light20Black, Light16Black,
    Regular14WhitePurpleBackground, Regular14WhitePurpleLightBackground, Regular14WhitePurpleMediumBackground,
    Regular16White, Light20BlackLink, Bold16Purple, Light14BlackLink
} from "./styles/text"

export default function Text({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Text.Bold50Black = function TextBold50Black({children, ...restProps}){
    return <Bold50Black {...restProps}>{children}</Bold50Black>   
}

Text.Bold30Black = function TextBold30Black({children, ...restProps}){
    return <Bold30Black {...restProps}>{children}</Bold30Black>   
}

Text.Bold15Black = function TextBold15Black({children, ...restProps}){
    return <Bold15Black {...restProps}>{children}</Bold15Black>   
}

Text.Bold15White = function TextBold15White({children, ...restProps}){
    return <Bold15White {...restProps}>{children}</Bold15White>   
}

Text.Bold16Purple = function TextBold16Purple ({children, ...restProps}){
    return <Bold16Purple  {...restProps}>{children}</Bold16Purple>   
}

Text.Medium40Purple = function TextMedium40Purple({children, ...restProps}){
    return <Medium40Purple {...restProps}>{children}</Medium40Purple>   
}

Text.Medium30Black = function TextMedium30Black({children, ...restProps}){
    return <Medium30Black {...restProps}>{children}</Medium30Black>   
}

Text.Medium30Green = function TextMedium30Green({children, ...restProps}){
    return <Medium30Green {...restProps}>{children}</Medium30Green>   
}

Text.Medium30Red = function TextMedium30Red({children, ...restProps}){
    return <Medium30Red {...restProps}>{children}</Medium30Red>   
}

Text.Medium66White = function TextMedium66White({children, ...restProps}){
    return <Medium66White {...restProps}>{children}</Medium66White>   
}


Text.Medium22Black = function TextMedium22Black({children, ...restProps}){
    return <Medium22Black {...restProps}>{children}</Medium22Black>   
}

Text.Regular18Purple = function TextRegular18Purple({children, ...restProps}){
    return <Regular18Purple {...restProps}>{children}</Regular18Purple>   
}


Text.Regular16Black = function TextRegular16Black({children, ...restProps}){
    return <Regular16Black {...restProps}>{children}</Regular16Black>   
}

Text.Regular16Purple = function TextRegular16Purple({children, ...restProps}){
    return <Regular16Purple {...restProps}>{children}</Regular16Purple>   
}

Text.Regular15Black = function TextRegular15Black({children, ...restProps}){
    return <Regular15Black {...restProps}>{children}</Regular15Black>   
}

Text.Regular15White = function TextRegular15White({children, ...restProps}){
    return <Regular15White {...restProps}>{children}</Regular15White>   
}

Text.Regular12White = function TextRegular12White({children, ...restProps}){
    return <Regular12White {...restProps}>{children}</Regular12White>   
}

Text.Regular16White = function TextRegular16White({children, ...restProps}){
    return <Regular16White {...restProps}>{children}</Regular16White>   
}

Text.Regular14Black = function TextRegular14Black({children, ...restProps}){
    return <Regular14Black {...restProps}>{children}</Regular14Black>   
}

Text.Regular14WhitePurpleBackground = function TextRegular14WhitePurpleBackground({children, ...restProps}){
    return <Regular14WhitePurpleBackground {...restProps}>{children}</Regular14WhitePurpleBackground>   
}

Text.Regular14WhitePurpleMediumBackground = function TextRegular14WhitePurpleMediumBackground({children, ...restProps}){
    return <Regular14WhitePurpleMediumBackground {...restProps}>{children}</Regular14WhitePurpleMediumBackground>   
}

Text.Regular14WhitePurpleLightBackground = function TextRegular14WhitePurpleLightBackground({children, ...restProps}){
    return <Regular14WhitePurpleLightBackground {...restProps}>{children}</Regular14WhitePurpleLightBackground>   
}




Text.Regular13Grey = function TextRegular13Grey({children, ...restProps}){
    return <Regular13Grey {...restProps}>{children}</Regular13Grey>   
}


Text.Regular18PurpleBorder = function TextRegular18PurpleBorder({children, ...restProps}){
    return <Regular18PurpleBorder {...restProps}>{children}</Regular18PurpleBorder>   
}


Text.Medium12BlackOpacity20 = function TextMedium12BlackOpacity20({children, ...restProps}){
    return <Medium12BlackOpacity20 {...restProps}>{children}</Medium12BlackOpacity20>   
}


Text.Medium31BlackOpacity20 = function TextMedium31BlackOpacity20({children, ...restProps}){
    return <Medium31BlackOpacity20 {...restProps}>{children}</Medium31BlackOpacity20>   
}


Text.Regular18Grey = function TextRegular18Grey({children, ...restProps}){
    return <Regular18Grey {...restProps}>{children}</Regular18Grey>   
}


Text.Medium40Black = function TextMedium40Black({children, ...restProps}){
    return <Medium40Black {...restProps}>{children}</Medium40Black>   
}


Text.Light20DarkPurple = function TextLight20DarkPurple({children, ...restProps}){
    return <Light20DarkPurple {...restProps}>{children}</Light20DarkPurple>   
}

Text.Light20White = function TextLight20White({children, ...restProps}){
    return <Light20White {...restProps}>{children}</Light20White>   
}

Text.Light16Black = function TextLight16Black({children, ...restProps}){
    return <Light16Black {...restProps}>{children}</Light16Black>   
}

Text.Light20Black = function TextLight20Black({children, ...restProps}){
    return <Light20Black {...restProps}>{children}</Light20Black>   
}

Text.Light20BlackLink = function TextLight20BlackLink({children, ...restProps}){
    return <Light20BlackLink {...restProps}>{children}</Light20BlackLink>   
}
Text.Light14BlackLink = function TextLight14BlackLink({children, ...restProps}){
    return <Light14BlackLink {...restProps}>{children}</Light14BlackLink>   
}

Text.Regular18DarkBlackBorder = function TextRegular18DarkBlackBorder({children, ...restProps}){
    return <Regular18DarkBlackBorder {...restProps}>{children}</Regular18DarkBlackBorder>   
}

Text.GreenRegular14 = function TextGreenRegular14({children, ...restProps}){
    return <GreenRegular14 {...restProps}>{children}</GreenRegular14>   
}

Text.RedRegular14 = function TextRedRegular14({children, ...restProps}){
    return <RedRegular14 {...restProps}>{children}</RedRegular14>   
}

Text.InputRegular16Black = function TextInputRegular16Black({children, ...restProps}){
    return <InputRegular16Black {...restProps}>{children}</InputRegular16Black>   
}

