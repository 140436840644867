import React from "react"
import { Container, Menu, Main, Header, Footer, Content
} from "./styles/template"

export default function Template({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Template.Menu = function TemplateMenu({children, ...restProps}){
    return <Menu {...restProps}>{children}</Menu>   
}

Template.Header = function TemplateHeader({children, ...restProps}){
    return <Header {...restProps}>{children}</Header>   
}

Template.Footer = function TemplateFooter({children, ...restProps}){
    return <Footer {...restProps}>{children}</Footer>   
}

Template.Main = function TemplateMain({children, ...restProps}){
    return <Main {...restProps}>{children}</Main>   
}

Template.Content = function TemplateContent({children, ...restProps}){
    return <Content {...restProps}>{children}</Content>   
}