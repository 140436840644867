import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTES from "../../constants/routes/routes";
import { useHistory } from "react-router-dom";
import {
  Text,
  MenuHeader,
} from "../../styled-components/visualization";
import { trackingSummary } from "../../actions/visualization/dataScienceAPIsActions";
import { AccountMenuContainer } from "./utilComponents/AccountMenu";
import {
  BackDrop,
  BackDropAbsolute,
} from "../../styled-components/general/styles/general";
import { MenuItemContainer } from "../../styled-components/visualization/menuHeader/styles/menuHeader";
import { CompanyContext } from "../../contexts/CompanyContext";
import { getWebsiteTrackingStatus } from "../../actions/visualization/dataScienceAPIsActions";
import { listKeywordDetailsWithoutPagination } from "../../actions/keywordActions";
import { Home } from '../../styled-components/admin-console'
import {  Content, Grid } from "../../styled-components/dashboard";
import {  Welcome} from "../../styled-components/visualization";

export function MenuHeaderContainer({
  pageName,
  email,
  name,
  accessToken,
}) {
  let history = useHistory();
  const dispatch = useDispatch();
  const { setCompanyDomain, 
    companyId4Api, 
    websiteName,
   // websiteTheme,
    companyIndustry,
    setCompanyId4Api,
    setWebsiteName,
    //setWebsiteTheme,
    setCompanyIndustry

  } = useContext(CompanyContext);
  const { date, setDate, onboardingDate, setOnboardingDate } = useContext(CompanyContext);
  const [companyContextReady, setCompanyContextReady] = useState(false);

  const websiteTrackingStatus = useSelector((state) => state.websiteTrackingStatus)
  const {
      loading: loadingWebsiteTrackingStatus,
      error: errorWebsiteTrackingStatus,
      success: successWebsiteTrackingStatus,
      websiteTrackingStatusResults,
  } = websiteTrackingStatus

  const keywordDetailsWithoutPagination = useSelector(state => state.keywordDetailsWithoutPagination)
  const {loading: loadingDetailsWithoutPaginationKeyword, 
      error: errorDetailsWithoutPaginationKeyword, 
      success: successDetailsWithoutPaginationKeyword, 
      keywords
  } = keywordDetailsWithoutPagination
 

  useEffect( ()=>{
    if(companyId4Api && companyIndustry){
      setCompanyContextReady(true)
    }
  },[ companyId4Api,   companyIndustry])


  const handleCompanyDomain = (url) =>{
    var hostname = url;
    //case http://url.com
     // Find and remove protocol
     if (url.indexOf("://") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    const hostnameArray = hostname.split('.');
    if (hostnameArray.length === 1) return hostname;
    let i = 0;
    for (i = hostnameArray.length - 1; i > -1; i--) {
        if (hostnameArray[i].length > 2 && i !== hostnameArray.length - 1) {
            break;
        }
    }
    let tldArray = [];
    for (let j = i; j < hostnameArray.length; j++) {
        tldArray.push(hostnameArray[j]);
    }
    // return tldArray.join('.');
    var topLevelDomain = tldArray.join('.');
    var lastChar = topLevelDomain.slice(-1)
    if(lastChar === "/"){
      topLevelDomain= topLevelDomain.substr(0, topLevelDomain.length - 1); //remove last char
    }

   

    setCompanyDomain(topLevelDomain)
  } 

  const handleAnalysisDate = (analysisDate) =>{
    var lastAnalysis = new Date(analysisDate);
    //Big query shows data from last analysis date + 1
    var lastAnalysisPlusOne = new Date(lastAnalysis.setDate(lastAnalysis.getDate()+1)).toISOString().slice(0, 10);
    //bring results from the past 30 days
    var lastMonth = new Date(lastAnalysis.setDate(lastAnalysis.getDate()-33)).toISOString().slice(0, 10);
    var dateT = [lastMonth, lastAnalysisPlusOne]
    if(! date[0]){ // do not save last analysis date if user already entered a custom date
      setDate(dateT)
    }
  }

  const handleOnboardingDate = (onboardingDate) =>{
    if(onboardingDate){ 
      setOnboardingDate(onboardingDate)
    }
  }

  // 17. Tracking Summary
  const trackingSummaryVar = useSelector((state) => state.trackingSummary);
  const {
    loading: loadingTrackingSummary,
  //  error: errorTrackingSummary,
    success: successTrackingSummary,
    trackingSummaryResults,
  } = trackingSummaryVar;
  

  var paramsTrackingSummary, paramsWebsiteTrackingStatus

  const [toggleAccountMenu, setToggleAccountMenu] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);

  useEffect(() => {
    if (name === undefined) {
      history.push("/profile-sign-up");
    } else {
      if (accessToken && email) { 
        if(companyContextReady){
          if(companyId4Api==='1'){
            if(JSON.parse(localStorage.getItem('companyId4Api')) &&
         //   JSON.parse(localStorage.getItem('websiteTheme')) &&
            JSON.parse(localStorage.getItem('websiteName')) &&
            JSON.parse(localStorage.getItem('companyIndustry')) 
            ){
              setCompanyId4Api(JSON.parse(localStorage.getItem('companyId4Api')))
              setCompanyIndustry(JSON.parse(localStorage.getItem('companyIndustry')))
              setWebsiteName(JSON.parse(localStorage.getItem('websiteName')))
              //setWebsiteTheme(JSON.parse(localStorage.getItem('websiteTheme')))
            }else {
              history.push("/");
            }
            
          } else{

         

          paramsTrackingSummary = {
            company_id: companyId4Api,
            market: "Market",
            mode: "include",
            dimension: "device",
            elements: ["mobile", "desktop"],
          };

          paramsWebsiteTrackingStatus = {
            "company_id": companyId4Api,
            }
        }
        if(companyId4Api > 1000000000){
          if(successDetailsWithoutPaginationKeyword){
          } else {
            if(!loadingDetailsWithoutPaginationKeyword){
              dispatch(listKeywordDetailsWithoutPagination(accessToken, companyId4Api/1000000000))
            }
          }
        }

        if(successWebsiteTrackingStatus){
          if(websiteTrackingStatusResults.status === 'ready') {
            
          }
        }
        else{
          if(!loadingWebsiteTrackingStatus && paramsWebsiteTrackingStatus){
            dispatch(getWebsiteTrackingStatus(accessToken, paramsWebsiteTrackingStatus))
          }
        }
       
        if (successTrackingSummary) {
          if(trackingSummaryResults[0]){
            handleCompanyDomain(trackingSummaryResults[0].website)
            handleAnalysisDate(trackingSummaryResults[0].last_analysis_date)
            handleOnboardingDate(trackingSummaryResults[0].tracking_date)
          } else {
            setCompanyDomain('1')
          }
         

        } else {
            if (!loadingTrackingSummary && paramsTrackingSummary){
              dispatch(trackingSummary(accessToken, paramsTrackingSummary, email));
            }   
      } }
     }
      
  
    }
  }, [history, name, dispatch, accessToken, email,  
    successTrackingSummary,companyContextReady, successWebsiteTrackingStatus, companyId4Api,
    successDetailsWithoutPaginationKeyword, paramsTrackingSummary, paramsWebsiteTrackingStatus
  ]);

  const goHome = async (e) => {
    history.push(`/`)
  }

  const dashboardNotReady = () =>{
    return (
      <BackDropAbsolute>
        <Content.SectionTop className={'overlayComponent'}>
                
          <Grid.SectionFull >
            <Content.FlexRow style={{justifyContent:'center'}}>
            <div style={{top:'25%', position:'absolute'}}>
              <Home.LargeText>
                We are busy preparing
              </Home.LargeText>
              <Home.LargeText>
                your Luminr Dashboard
              </Home.LargeText>
              <Home.RedLine></Home.RedLine>
              <Home.SmallText
                  style = {{marginTop: '20px'}}
              >
              You will receive an email from us when you are ready to go (usually between 24-48 hours).
              </Home.SmallText>
            </div>

            <Welcome.FooterRightImage 
              style={{
                  right: '-50px'
              }} 
              src={require("../../images/visualization/04.png").default} />
            </Content.FlexRow>
            
          </Grid.SectionFull>
          

              
        </Content.SectionTop>
      </BackDropAbsolute>
    )
  }


  return (
    <>
      <MenuHeader className={"stickyHeader"}>
        <MenuHeader.LogoContainer>
          <MenuHeader.SerprLogoImg 
          onClick = {goHome}
            src={require("../../images/dashboard/luminr-logo-black.svg").default}
          />
        
          {/* <MenuHeader.VerticalLine></MenuHeader.VerticalLine>
          <MenuHeader.CompanyLogoImg
            src={require(`../../images/visualization/companyLogos/${companyNameForLogo}Logo.svg`).default}
          /> */}
        </MenuHeader.LogoContainer>
       
        <MenuHeader.MenuContainer>
        {pageName === 'Welcome' ? 
          <MenuItemContainer to={ROUTES.VISUALIZATION_SUMMARY}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_SUMMARY}>
              <MenuHeader.ItemImg
                src={require("../../images/visualization/Summary_Purple.svg").default}
              />
              <Text.Regular16Purple>Welcome</Text.Regular16Purple>
            </MenuHeader.Link>
          </MenuItemContainer> :
          <MenuItemContainer to={ROUTES.VISUALIZATION_SUMMARY}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_SUMMARY}>
              <MenuHeader.ItemImg
                src={require("../../images/visualization/Summary.svg").default}
              />
              <Text.Regular16Black>Welcome</Text.Regular16Black>
            </MenuHeader.Link>
          </MenuItemContainer>
          }
          {pageName === 'Benchmarks' ?
          <MenuItemContainer to={ROUTES.VISUALIZATION_BENCHMARKS}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_BENCHMARKS}>
              <MenuHeader.ItemImg
                style={{
                  marginLeft: "5%",
                }}
                src={require("../../images/visualization/Benchmarks_purple.svg").default}
              />
              <Text.Regular16Purple>Benchmarks</Text.Regular16Purple>
            </MenuHeader.Link>
          </MenuItemContainer> :
          <MenuItemContainer to={ROUTES.VISUALIZATION_BENCHMARKS}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_BENCHMARKS}>
              <MenuHeader.ItemImg
                style={{
                  marginLeft: "5%",
                }}
                src={require("../../images/visualization/Benchmarks.svg").default}
              />
              <Text.Regular16Black>Benchmarks</Text.Regular16Black>
            </MenuHeader.Link>
          </MenuItemContainer>
          }
          {pageName === 'Coverage' ? 
          <MenuItemContainer to={ROUTES.VISUALIZATION_COVERAGE}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_COVERAGE}>
              <MenuHeader.ItemImg
                style={{
                  marginLeft: "5%",
                }}
                src={require("../../images/visualization/Coverage_purple.svg").default}
              />
              <Text.Regular16Purple>Coverage</Text.Regular16Purple>
            </MenuHeader.Link>
          </MenuItemContainer> :
          <MenuItemContainer to={ROUTES.VISUALIZATION_COVERAGE}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_COVERAGE}>
              <MenuHeader.ItemImg
                style={{
                  marginLeft: "5%",
                }}
                src={require("../../images/visualization/Coverage.svg").default}
              />
              <Text.Regular16Black>Coverage</Text.Regular16Black>
            </MenuHeader.Link>
          </MenuItemContainer>
        }
        {pageName === 'Competition' ? 
          <MenuItemContainer to={ROUTES.VISUALIZATION_COMPETITION}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_COMPETITION}>
              <MenuHeader.ItemImg
                style={{
                  marginLeft: "5%",
                }}
                src={
                  require("../../images/visualization/Competition_purple.svg").default
                }
              />
              <Text.Regular16Purple>Competition</Text.Regular16Purple>
            </MenuHeader.Link>
          </MenuItemContainer> :
          <MenuItemContainer to={ROUTES.VISUALIZATION_COMPETITION}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_COMPETITION}>
              <MenuHeader.ItemImg
                style={{
                  marginLeft: "5%",
                }}
                src={
                  require("../../images/visualization/Competition.svg").default
                }
              />
              <Text.Regular16Black>Competition</Text.Regular16Black>
            </MenuHeader.Link>
          </MenuItemContainer>

        }
        {/* {activeResults && pageName === 'Results' ? 
          <MenuItemContainer to={ROUTES.VISUALIZATION_RESULTS}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_RESULTS}>
              <MenuHeader.ItemImg
                style={{
                  marginLeft: "5%",
                }}
                src={require("../../images/visualization/Results_purple.svg").default}
              />
              <Text.Regular16Purple>Results</Text.Regular16Purple>
            </MenuHeader.Link>
          </MenuItemContainer> :
          <MenuItemContainer to={ROUTES.VISUALIZATION_RESULTS}>
            <MenuHeader.Link to={ROUTES.VISUALIZATION_RESULTS}>
              <MenuHeader.ItemImg
                style={{
                  marginLeft: "5%",
                }}
                src={require("../../images/visualization/Results.svg").default}
              />
              <Text.Regular16Black>Results</Text.Regular16Black>
            </MenuHeader.Link>
          </MenuItemContainer>
        } */}
        </MenuHeader.MenuContainer>
        <MenuHeader.SettingsContainer
          style={{
            justifyContent: "flex-end",

            cursor: 'pointer',
            width: 'fit-content',
            whiteSpace: 'nowrap'
          }}
        >
          {modalBackdrop ? (
            <BackDropAbsolute
              onClick={() => {
                setToggleAccountMenu(false);
                setModalBackdrop(false);
              }}
            />
          ) : (
            <BackDrop />
          )}

      {toggleAccountMenu ? 
          <Text.Regular16Black
            onClick={() => {
              setToggleAccountMenu(!toggleAccountMenu);
              setModalBackdrop(true);
            }}
            style={{
              cursor: "pointer",
              position:'relative',
              marginRight:'5px',
            }}
          >
            {name}
          </Text.Regular16Black> 
          :
          <Text.Regular16Black
          onClick={() => {
            setToggleAccountMenu(!toggleAccountMenu);
            setModalBackdrop(true);
          }}
          style={{
            cursor: "pointer",
            marginRight:'5px',
          }}
          >
            {name}
          </Text.Regular16Black>
        }
        {toggleAccountMenu ? 
          <div style={{position:'relative'}}>
            
            <div 
              style={{
                // display: "contents",
                cursor: "pointer",
                position:'relative'
              }}
              onClick={() => {
                  setToggleAccountMenu(!toggleAccountMenu);
                  setModalBackdrop(true);
                }}
              >
            {/* <MenuHeader.ProfileImg src={require("../../images/visualization/profileIcon.png").default}/> */}
            <MenuHeader.ProfileImg
              style={{
                width: "10px",
                height: "15px",
                margin: "0 0 0 5px",
                padding: "5px 0",
              }}
              src={
                toggleAccountMenu
                  ? require("../../images/icon_up_arrow_pink.svg").default
                  : require("../../images/icon_down_arrow_pink.svg").default
              }
            />
            </div>
            <AccountMenuContainer active={true} />
          </div>
         : 
          
            <div 
              style={{
                // display: "contents",
                cursor: "pointer",
                position:'relative'
              }}
              onClick={() => {
                  setToggleAccountMenu(!toggleAccountMenu);
                  setModalBackdrop(true);
                }}
              >
            {/* <MenuHeader.ProfileImg src={require("../../images/visualization/profileIcon.png").default}/> */}
            <MenuHeader.ProfileImg
              style={{
                width: "10px",
                height: "15px",
                margin: "0 0 0 5px",
                padding: "5px 0",
              }}
              src={
                toggleAccountMenu
                  ? require("../../images/icon_up_arrow_pink.svg").default
                  : require("../../images/icon_down_arrow_pink.svg").default
              }
            />
            </div>
         
          }
          
       

        </MenuHeader.SettingsContainer>
        
      </MenuHeader>
      {successWebsiteTrackingStatus && websiteTrackingStatusResults.status !== 'ready' &&
        <MenuHeader.SubHeader>
  
          {/* <MenuHeader.SubHeaderContainer
            style={{
              width: '50%'
            }}
          >
            <Text.Regular13Grey>Your dashboard is being prepared.</Text.Regular13Grey>
          </MenuHeader.SubHeaderContainer> */}

          {dashboardNotReady()}


          {websiteTrackingStatusResults.estimated_ready_at &&
          <MenuHeader.SubHeaderContainer
          style={{
              width: '50%',
              justifyContent: 'flex-end'
            }}
          >
            <Text.Regular13Grey  >Estimated time: {websiteTrackingStatusResults.estimated_ready_at}</Text.Regular13Grey>
          </MenuHeader.SubHeaderContainer>}
          
        </MenuHeader.SubHeader>

        }
      {loadingTrackingSummary ||
        !successTrackingSummary ||
        (pageName !== "Welcome" && (
          <MenuHeader.SubHeader>
            <MenuHeader.SubHeaderContainer>
              <Text.Regular13Grey>{websiteName}</Text.Regular13Grey>
              <MenuHeader.VerticalLine
                style={{
                  height: "50%",
                }}
              ></MenuHeader.VerticalLine>

              <Text.Regular13Grey>{pageName}</Text.Regular13Grey>
            </MenuHeader.SubHeaderContainer>
            <MenuHeader.SubHeaderContainer>
              <Text.Regular13Grey>
                Insights for {trackingSummaryResults[0] && trackingSummaryResults[0].keywords.length}{" "}
                keywords over {trackingSummaryResults[0] && trackingSummaryResults[0].markets} markets and 1
                language
              </Text.Regular13Grey>
            </MenuHeader.SubHeaderContainer>
            <MenuHeader.SubHeaderContainer
              style={{
                justifyContent: "flex-end",
              }}
            >
              {trackingSummaryResults[0] && trackingSummaryResults[0].last_analysis_date ?
              <Text.Regular13Grey>
                Last Analysed: {trackingSummaryResults[0].last_analysis_date}
              </Text.Regular13Grey> : 
              <Text.Regular13Grey>
                Last Analysed: no data
              </Text.Regular13Grey>
              }
            </MenuHeader.SubHeaderContainer>
          </MenuHeader.SubHeader>
        ))}
        
        

    </>
  );
}
