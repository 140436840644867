import React, { useEffect } from "react";
import { Text, Content } from "../../../styled-components/visualization";
import Chart from "react-apexcharts";


export function FingerPrintRadialChartContainer({variant, dataArray, labelsArray, colorOptions}) {

    useEffect(() => { 
          window.dispatchEvent(new Event('resize')); 
      },
      [])

    var options = {
        series: dataArray, 
        chart: {
        type: 'donut',
        toolbar: {
            show: true,
            tools: {
              download: '<img src='+require("../../../images/visualization/download.svg").default+' width="20">'
            }
          }
      },
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     chart: {
      //       width: 200
      //     },
      //     legend: {
      //       position: 'center'
      //     }
      //   }
      // }],
      labels: labelsArray,
      colors: colorOptions,
      dataLabels: {
        enabled: false,

      },
      legend: {
        position: 'right',
        horizontalAlign: 'top',
        verticalAlign: 'top',
        offsetX: -20,
        offsetY: 10,
        width: 150,
       // height: 300,
        markers: {
            radius:2,
            fillColors: colorOptions,
        }
      },
      tooltip: {
        enabled: true
      }
      };

   

  return (
        <Content.FlexColumn
            style={{
                position: 'relative'
            }}
        >
            <Chart 
                    options={options}
                    series={options.series}
                    type="donut"
                // width="100%"
                    height='400px'
                
            />
            <Content.FingerprintCharts>
            <Text.Regular18DarkBlackBorder
                style={{
                    maxWidth: '100px',
                    textAlign: 'center',
                    border: 'none'
                }}
            >
                {variant}
              </Text.Regular18DarkBlackBorder>
            </Content.FingerprintCharts>
        </Content.FlexColumn>
        
  )
}
