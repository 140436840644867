import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button } from "../../styled-components/dashboard";

import { useHistory } from "react-router-dom"

import {KEYWORD_CREATE_RESET, KEYWORD_DETAILS_RESET, KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET} from '../../constants/keywordConstants'
import {WEBSITE_DETAILS_BY_EMAIL_RESET} from '../../constants/websiteConstants'



export function AddKeywordsSuccessContainer({ accessToken, name, email }) {
  const dispatch = useDispatch()
  const history = useHistory()
  
  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('keywords')+17, 100))

  const nextHandler = (e) => {
    history.push(`/`)
    
  }
  const backHandler = (e) => {
    history.push(`/keywords/${websiteId}`)
    

}

  const keywordCreate = useSelector(state => state.keywordCreate)
     const {//loading: loadingCreateKeyword, 
         //error: errorCreateKeyword, 
         success: successCreateKeyword, 
         // keyword: createKeyword
     } = keywordCreate

  useEffect(() => {
    dispatch({type: KEYWORD_CREATE_RESET}) 
    dispatch({type: KEYWORD_DETAILS_RESET})
    dispatch({type: KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET})
    dispatch({type: WEBSITE_DETAILS_BY_EMAIL_RESET})

  }, [
    successCreateKeyword,
    dispatch
  ])

  return(
      <Content>
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold42Pink>
                Congratulations <span> </span>
              </Text.Bold42Pink>
              <Text.Bold40Black>
                your keywords have successfully added to your website
              </Text.Bold40Black>
              <Text.Normal14Black
              style={{marginTop: '26px'}}>
                It will take around 72 hours before you see the keyword data populate in your dashboard
              </Text.Normal14Black>
              
                
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          
          <Content.ButtonsContainer>
            
            <Button.Back
            onClick={backHandler}
            style={{width: '192px'}}
            >
            View Keywords
          </Button.Back>
          <Button.Save 
          onClick={nextHandler}
          style={{width: '192px'}}
          >
              View your dashboards
            </Button.Save>
          </Content.ButtonsContainer>
          
        </Content.SectionBottom>
      </Content>
  )
}
