import React from "react"
import { StyledTable, THead, TBody, TH, TR, TRHead, TD, IconLink } from "./styles/table"
import { Link as ReactRouterLink } from 'react-router-dom'


export default function Table({children, ...restProps}) {
    return (
        <StyledTable {...restProps}>{children}</StyledTable>
    )
}

Table.Head = function TableHead({children, ...restProps}){
    return <THead {...restProps}>{children}</THead>   
}

Table.Body = function TableBody({children, ...restProps}){
    return <TBody {...restProps}>{children}</TBody>   
}

Table.TH = function TableTH({children, ...restProps}){
    return <TH {...restProps}>{children}</TH>   
}

Table.TR = function TableTR({children, ...restProps}){
    return <TR {...restProps}>{children}</TR>   
}

Table.TRHead = function TableTRHead({children, ...restProps}){
    return <TRHead {...restProps}>{children}</TRHead>   
}

Table.TD = function TableTD({children, ...restProps}){
    return <TD {...restProps}>{children}</TD>   
}

Table.IconLink = function TableIconLink({ to, ...restProps }) {
    return (
        <ReactRouterLink to={{pathname: to, restProps}}>
            <IconLink {...restProps}></IconLink>
        </ReactRouterLink>
    )
}