import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Content, General, Grid, Table, Text } from "../../styled-components/dashboard";
import { FlexColumn, FlexRow } from "../../styled-components/visualization/content/styles/content";
import { listKeywordDetails, listKeywordDetailsById, updateKeyword, listKeywordDetailsWithoutPagination,deleteKeyword } from "../../actions/keywordActions";
import { useHistory } from "react-router-dom"
import Paginate from '../../components/Paginate'
import { KEYWORD_DETAILS_RESET, KEYWORD_DETAILS_BY_ID_RESET, KEYWORD_DELETE_RESET} from '../../constants/keywordConstants'
import Modal from 'react-modal';
import Select from "../../components/Select";
import {  devices, verticals, brands, google_domains, languages2, countries, types, locations2 } from '../../constants/utils'
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { CSVLink } from "react-csv";

import { listWebsiteDetailsById } from "../../actions/websiteActions";
import { WEBSITE_DETAILS_BY_ID_RESET } from "../../constants/websiteConstants";

const customStyles = {
  content: {
    width: 'fit-content',
    padding: '3%',
    minWidth: '450px',
    overflowY:'auto !important',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',

  },
};

const customStylesDelete = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '450px',
    padding: '3%'
  },
};


export function KeywordsContainer({ accessToken, name, email }) {
  const dispatch = useDispatch()
  const history = useHistory()
  
  let param = history.location.search

    // Keyword Details
    const [keywordValue, setKeywordValue] = useState('')

    const [device, setDevice] = useState(devices[0])
    const [brand, setBrand] = useState(brands[0])
    const [google_domain, setGoogle_domain] = useState(google_domains[0])
    const [language, setLanguage] = useState(languages2[0])
    const [location, setLocation] = useState(locations2[0])
    const [country, setCountry] = useState(countries[0])
    const [vertical, setVertical] = useState(verticals[0])
    const [type, setType] = useState(types[0])
    const [keywordActive, setKeywordActive] = useState(brands[0])
    const [seo_group, setSeo_group] = useState('')

  const [websiteId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('keywords')+9, 100))
  const [keywordId, setKeywordId] = useState('')

  const [downloadBoolean, setDownloadBoolean] = useState(false)


  const keywordDetails = useSelector(state => state.keywordDetails)
    const {loading: loadingDetailsKeyword, 
        error: errorDetailsKeyword, 
        success: successDetailsKeyword, 
        keyword, page, pages
    } = keywordDetails

    const keywordDetailsWithoutPagination = useSelector(state => state.keywordDetailsWithoutPagination)
    const {loading: loadingDetailsWithoutPaginationKeyword, 
        error: errorDetailsWithoutPaginationKeyword, 
        success: successDetailsWithoutPaginationKeyword, 
        keywords
    } = keywordDetailsWithoutPagination

    const keywordDetailsById = useSelector(state => state.keywordDetailsById)
    const {loading: loadingDetailsKeywordById, 
        error: errorDetailsKeywordById, 
        success: successDetailsKeywordById, 
        keyword: keywordById
    } = keywordDetailsById

    const websiteDetailsById = useSelector(state => state.websiteDetailsById)
    const {loading: loadingDetailsByIdWebsite, 
        error: errorDetailsByIdWebsite, 
        success: successDetailsByIdWebsite, 
         website: detailsByIdWebsite
    } = websiteDetailsById

    const keywordUpdate = useSelector(state => state.keywordUpdate)
    const {loading: loadingUpdateKeyword, 
        error: errorUpdateKeyword, 
        success: successUpdateKeyword, 
   
    } = keywordUpdate

    const keywordDelete = useSelector(state => state.keywordDelete)
    const {
        loading: loadingDeleteKeyword, 
        error: errorDeleteKeyword, 
        success: successDeleteKeyword, 
        keyword: deleteKeywordMessage
    } = keywordDelete


    // fetch keywords byId
    const [paramKeywords, setParamKeywords] = useState(false)

    const [paramPrev, setParamPrev] = useState('')

    const csvHeader = [
      { label: "keyword", key: "keyword" },
      { label: "device", key: "device" },
      { label: "brand", key: "brand" },
      { label: "google_domain", key: "google_domain" },
      { label: "language", key: "language" },
      { label: "location", key: "location" },
      { label: "country", key: "country" },
      { label: "vertical", key: "vertical" },
      { label: "type", key: "type" },
      { label: "active", key: "active" },
      { label: "seo_group", key: "seo_group" },
    ];
    
    const csvFilename ='keywords.csv';
    let csvReport ={
      data: keywords,
                headers: csvHeader,
                filename: csvFilename
    }

  useEffect(()=>{
    if(accessToken && email && websiteId) 
        {

            if(successDetailsWithoutPaginationKeyword){
              setDownloadBoolean(false)
              
                csvReport = {
                data: keywords,
                headers: csvHeader,
                filename: csvFilename
              };

            } else{
              if(!loadingDetailsWithoutPaginationKeyword){
                dispatch(listKeywordDetailsWithoutPagination(accessToken, websiteId))
              }
            }
         

          if(successDetailsByIdWebsite){
            if(successDetailsKeyword){
              if(param !== paramPrev){
                setParamPrev(param)
                dispatch({type: KEYWORD_DETAILS_RESET})   
            }
              if(paramKeywords) {
                
                if(successDetailsKeywordById ){
  
                  setParamKeywords(false)
                  setKeywordValue(keywordById.keyword)
                  setDevice(keywordById.device)
                  setBrand(keywordById.brand)
                  setGoogle_domain(keywordById.google_domain)
                  setLanguage(keywordById.language)
                  setLocation(keywordById.location)
                  setCountry(keywordById.country)
                  setVertical(keywordById.vertical)
                  setType(keywordById.type)
                  setKeywordActive(keywordById.active)
                  setSeo_group(keywordById.seo_group)
                }
                else{
                  if(!loadingDetailsKeywordById){
                    dispatch(listKeywordDetailsById(accessToken, keywordId))
                  }
                 
                }
                
              }
            }else {
              dispatch(listKeywordDetails(accessToken, websiteId, param))
            }
          } else {
            dispatch(listWebsiteDetailsById(accessToken, websiteId, email))
          }
          
          if(successDeleteKeyword){
            dispatch({type: KEYWORD_DELETE_RESET})
            dispatch({type: KEYWORD_DETAILS_RESET})
            dispatch(listKeywordDetails(accessToken, websiteId, param))
          }
        
        } 
    },[email,
        accessToken,  
        dispatch,
        websiteId,
        successDetailsKeyword,
        keyword,
        param,
        paramPrev,
        paramKeywords,
        successDetailsKeywordById,
        keywordById,
        successDetailsByIdWebsite,
        successDetailsWithoutPaginationKeyword,
        downloadBoolean,
        successDeleteKeyword
    ])
 

    //MODAL EDIT
    const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);

    function openEditModal() {
      setEditModalIsOpen(true);
  }

    function closeEditModal() {
      setEditModalIsOpen(false);
    }

    const handlerEdit = (id) =>{
      setKeywordId(id)
      dispatch({type: KEYWORD_DETAILS_BY_ID_RESET})  
      setParamKeywords(true)
      
      openEditModal()
      
    }

    const updateKeywordsHandler = () =>{
      const keyword = {
        id: keywordId,
        keyword: keywordValue,
        device,
        brand,
        google_domain,
        language,
        location,
        country,
        vertical,
        type,
        active: keywordActive,
        seo_group,
        email
}

  dispatch(updateKeyword(accessToken, keyword, websiteId))
    }
    
     //MODAL DELETE
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const customStylesDelete = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '450px',
      padding: '3%'
    },
  };

  function openDeleteModal(k_id,k_name) {
    setKeywordId(k_id) // we need to store the state, although it will always delete the last element from list
    setKeywordValue(k_name)
    setDeleteModalIsOpen(true);
  }

  function closeDeleteModal() {
    setDeleteModalIsOpen(false);
  }
  const deleteKeywordHandler = () => {
    setDeleteModalIsOpen(false);
    dispatch({type: KEYWORD_DETAILS_RESET})
    //reset the number of keywords displayed
    dispatch({type: WEBSITE_DETAILS_BY_ID_RESET})   
    dispatch(deleteKeyword(accessToken, keywordId,websiteId))
  }

  const modalDelete = (c_id) => {
    return(
            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={closeDeleteModal}
                style={customStylesDelete}
                ariaHideApp={false}
              >
              <Text.Bold40Black>
                Are you sure you want to remove
                </Text.Bold40Black> <span> </span>
                <Text.Bold42Pink>
                {keywordValue} 
                </Text.Bold42Pink> <span> </span>
                <Text.Bold40Black>
                from your keywords list?
                </Text.Bold40Black>
                <FlexRow
                style={{
                  justifyContent: 'space-between',
                  marginTop: '30px'
                }}
            >
                <Button.Back
                  style={{
                    margin: '0'
                  }}
                  onClick={closeDeleteModal}
                >
                  Cancel
                </Button.Back>
                <Button.Save
                  onClick={()=>deleteKeywordHandler()}
                >
                  Delete
                </Button.Save>

            </FlexRow>
            </Modal>
    )
  }

    const goToAddKeywords = () => {
      history.push(`/add-keywords-copy/${websiteId}`)
    }
  
    const downloadKeywords = () => {
      setDownloadBoolean(true)
    }
   

  const keywordsTable = () => {
    return(
      <FlexColumn
      style={{
             // minWidth: '1200px'
            }}
      >
      
        <FlexRow
          style={{
            justifyContent: 'space-between',
            marginTop:'10px',
            padding: '10px',
            marginLeft: '-10px'
          }}
        >
          <Text.Bold16Black
            style={{
              width: '10%'
            }}
          >
            Keyword
          </Text.Bold16Black>
          {/* <Text.Bold16Black
            style={{
              width: '6%'
            }}
          >
            Device
          </Text.Bold16Black> */}

          {/* <Text.Bold16Black
            style={{
              width: '4%'
            }}
          >
            Brand
          </Text.Bold16Black> */}
          <Text.Bold16Black
            style={{
              width: '6%'
            }}
          >
            Google domain
          </Text.Bold16Black>
          {/* <Text.Bold16Black
            style={{
              width: '6%'
            }}
          >
            Language
          </Text.Bold16Black> */}
          <Text.Bold16Black
            style={{
              width: '10%'
            }}
          >
          
            Location
          </Text.Bold16Black>
          {/* <Text.Bold16Black
            style={{
              width: '7%'
            }}
          >
          
            Country
          </Text.Bold16Black> */}
          <Text.Bold16Black
            style={{
              width: '10%'
            }}
          >
            Vertical
          </Text.Bold16Black>
          <Text.Bold16Black
            style={{
              width: '4%'
            }}
          >
            Type
          </Text.Bold16Black>
          {/* <Text.Bold16Black
            style={{
              width: '4%'
            }}
          >
            Active
          </Text.Bold16Black> */}
          <Text.Bold16Black
            style={{
              width: '20%'
            }}
          >
            SEO group
          </Text.Bold16Black>
          <Text.Bold16Black
            style={{
              width: '4%'
            }}
          >
            Actions
          </Text.Bold16Black>
        </FlexRow>
        {keyword.map((k,i ) => (
          <General.TableRow
            style={{
              marginTop: '0',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Text.Normal14Black
              style={{
                width: '10%'
              }}
            >
              {k.keyword}
            </Text.Normal14Black>
            {/* <Text.Normal14Black
              style={{
                width: '6%'
              }}
            >
              {k.device}
            </Text.Normal14Black> */}
            {/* <Text.Normal14Black
              style={{
                width: '4%'
              }}
            >
              {k.brand}
            </Text.Normal14Black> */}
            <Text.Normal14Black
              style={{
                width: '6%'
              }}
            >
              {k.google_domain}
            </Text.Normal14Black>
            {/* <Text.Normal14Black
              style={{
                width: '6%'
              }}
            >
              {k.language}
            </Text.Normal14Black> */}
            <Text.Normal14Black
              style={{
                width: '10%'
              }}
            >
              {k.location}
            </Text.Normal14Black>
            {/* <Text.Normal14Black
              style={{
                width: '7%'
              }}
            >
              {k.country}
            </Text.Normal14Black> */}
            <Text.Normal14Black
              style={{
                width: '10%'
              }}
            >
              {k.vertical}
            </Text.Normal14Black>
            <Text.Normal14Black
              style={{
                width: '4%'
              }}
            >
              {k.type}
            </Text.Normal14Black>
            {/* <Text.Normal14Black
              style={{
                width: '4%'
              }}
            >
              {k.active}
            </Text.Normal14Black> */}
            <Text.Normal14Black
              style={{
                width: '20%'
              }}
            >
              {k.seo_group}
            </Text.Normal14Black>
            <Text.Normal14Black
              style={{
                width: '4%'
              }}
            >
            <FlexRow
              style={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
            <Table.IconLink
                   style={{
                     marginLeft: '5px'
                   }}
                   onClick={() => handlerEdit(k.id)}    
                   alt="edit"
                   src={require("../../images/icon_edit_pink.svg").default}
                   

               />
               <Table.IconLink
                    onClick={() => openDeleteModal(k.id, k.keyword)}  
                   alt="delete"
                   src={require("../../images/icon_delete_pink.svg").default} 
                   
                   
               />
               </FlexRow>
               {modalEdit(k.id)}
               {modalDelete(k.id)}
            </Text.Normal14Black>

            </General.TableRow>
        ))}
        <FlexRow
              style={{
                alignItems:'center',
                justifyContent: 'center'
              }}
        >
   
          <Paginate page={page} pages={pages} />
        </FlexRow>
        
      </FlexColumn>

    )
  }

  const modalEdit = () => {
    return(

      <FlexRow
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto'
        }}
      >
      <Modal
                isOpen={editModalIsOpen}
                onRequestClose={closeEditModal}
                style={customStyles}
                ariaHideApp={false}
              >
        {loadingUpdateKeyword || loadingDetailsKeywordById ||
        loadingDetailsWithoutPaginationKeyword
        ? <LoaderImage/> :
        <>
        <Text.Bold26Pink>
                Keywords details
            </Text.Bold26Pink> 
            <Text.Bold16Black>
              Keyword
            </Text.Bold16Black>
            <Text.Field14Black 
              placeholder="keyword"
              type="text"
              value={keywordValue}
              onChange={(e) => setKeywordValue(e.target.value)}
            ></Text.Field14Black>  
            <Select param={device} setParam={setDevice} list={devices} placeholder='Choose device' title='Device'/>
            <Select param={brand} setParam={setBrand} list={brands} placeholder='Choose either True or False' title='Brand'/>
            <Select param={google_domain} setParam={setGoogle_domain} list={google_domains} placeholder='Choose Google Domain' title='Google Domain'/>
            <Select param={language} setParam={setLanguage} list={languages2} placeholder='Choose language' title='Language'/>
            <Select param={location} setParam={setLocation} list={locations2} placeholder='Choose location' title='Location'/>
            <Select param={country} setParam={setCountry} list={countries} placeholder='Choose country' title='Country'/>
            <Select param={vertical} setParam={setVertical} list={verticals} placeholder='Choose vertical' title='Vertical'/>
            <Select param={type} setParam={setType} list={types} placeholder='Choose type' title='Type'/>
            <Select param={keywordActive} setParam={setKeywordActive} list={brands} placeholder='Choose either active or not' title='Active?'/>
            <FlexRow
                style={{
                  justifyContent: 'space-between'
                }}
            >
                <Button.Back
                  style={{
                    margin: '0'
                  }}
                  onClick={closeEditModal}
                >
                  Cancel
                </Button.Back>
                <Button.Save
                  onClick={updateKeywordsHandler}
                >
                  Save
                </Button.Save>

            </FlexRow>
        </>
         }
          
            </Modal>
            </FlexRow>
            
    )
  }


  return(
      <Content
        style={{
         // minWidth: '1300px'
        }}
      >
        {loadingDetailsKeyword || !successDetailsKeyword || loadingDeleteKeyword
        ?
        <LoaderImage /> :
        errorDetailsKeyword || errorDetailsKeywordById || errorDetailsByIdWebsite || errorDeleteKeyword ?
        <ErrorMessage /> :
        detailsByIdWebsite.detail ? 
        <ErrorMessage message={detailsByIdWebsite.detail} /> :

       
        <Content.SectionTop  >

          <Grid.SectionFull  
          >
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center'

              }}
            >
              <Content.FlexColumn>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Text.Bold26Pink>Keywords</Text.Bold26Pink>
                <Text.Bold26Pink>{detailsByIdWebsite.no_of_keywords} of 250</Text.Bold26Pink>
                </div>
                <ProgressBar 
                    variant="success" 
                    now={(detailsByIdWebsite.no_of_keywords*100)/250}
                    animated='true'
                    label={(detailsByIdWebsite.no_of_keywords*100)/250+'%'}
                    />
             <Text.Normal14Black
                style={{
                  marginBottom: '10px',
                  marginTop: '5px'
                }}
             >
               {250-detailsByIdWebsite.no_of_keywords} keywords remaining until your plan requires an upgrade.
             </Text.Normal14Black>
             </Content.FlexColumn>
             {keywords &&
              <CSVLink {...csvReport}>
             <Button.Save 
              onClick={downloadKeywords}
              style={{
                cursor: downloadBoolean ? '' : 'pointer',
                width: 'fit-content',
                padding: '0 20px',
                margin: '0'
                
                }}
              >
              Download keywords
            </Button.Save>
            </CSVLink>}
             <Button.Save 
              onClick={goToAddKeywords}
              style={{
                
                width: 'fit-content',
                padding: '0 20px',
                margin: '0'
                
                }}
              >
              Re-add new keywords
            </Button.Save>
            </Content.FlexRow>
             {keyword[0] &&<>
              {keywordsTable()}
             </>  }
             </Grid.SectionFull>
        </Content.SectionTop>
      }
      </Content>
  )
}
