import React from "react";
import { Text, Welcome } from "../../../styled-components/visualization";

export function HeaderContainer({name}) {

  return (
      <Welcome.PurpleDiv>
        <div>
          <Text.Medium66White>Hi {name}!</Text.Medium66White>
          <Text.Medium66White>Welcome to your Luminr Dashboard.</Text.Medium66White>
          <div
            style={{
              marginTop:'5%',
              paddingRight: '10%' 
            }}
          >
            <Text.Light20White
            >Below is a brief breakdown of how Luminr works, and how best to get the most of out your new dashboard, fast. </Text.Light20White>
            <Text.Light20White
              style={{
                marginTop: '2%'
              }}
            >
            We recommend giving the intro a spin so you can confidently make the most of your new Luminr dashboard tool. Learn all about our unique insights and measurements so your website can grow across page one search. 
            </Text.Light20White>
          </div>
          
        </div>
        <Welcome.SerprImage  src={require("../../../images/visualization/01.png").default} />
      </Welcome.PurpleDiv>
  )
}
