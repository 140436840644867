import  axios from 'axios'
import { 
    COMPANY_LIST_REQUEST,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_FAIL,

    COMPANY_DETAILS_REQUEST,
    COMPANY_DETAILS_SUCCESS,
    COMPANY_DETAILS_FAIL,
    
    COMPANY_DETAILS_BY_ID_REQUEST,
    COMPANY_DETAILS_BY_ID_SUCCESS,
    COMPANY_DETAILS_BY_ID_FAIL,

    COMPANY_CREATE_REQUEST,
    COMPANY_CREATE_SUCCESS,
    COMPANY_CREATE_FAIL,

    COMPANY_UPDATE_REQUEST,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_UPDATE_FAIL,

    COMPANY_DELETE_REQUEST,
    COMPANY_DELETE_SUCCESS,
    COMPANY_DELETE_FAIL,

 } from '../constants/companyConstants'


 export const listCompanies = (accessToken) => async (dispatch) =>{
    try {
        dispatch({type:COMPANY_LIST_REQUEST})

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.get(
            `/api/company/`,
            config
            )

        dispatch({
            type: COMPANY_LIST_SUCCESS,
            payload: data
        })


    } catch(error){
        dispatch({
            type: COMPANY_LIST_FAIL,
            payload: error.response && error.response.data.message ?
            error.response.data.message 
            : error.message,
        })
    }
 }

 export const createCompany = (accessToken, company) => async (dispatch) => {
    try {
       
        dispatch({
            type: COMPANY_CREATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.post(
            `/api/company/create/${company.email}/${company.id}`,
            company,
            config
        )

        dispatch({
            type: COMPANY_CREATE_SUCCESS,
            payload: data,
        })

        dispatch(listCompanyDetails(accessToken, company.email, company.userName))


    } catch (error) {
        dispatch({
            type: COMPANY_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateCompany = (accessToken, company, companyId) => async (dispatch) => {
    try {
       
        dispatch({
            type: COMPANY_UPDATE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const { data } = await axios.put(
            `/api/company/update/${companyId}`,
            company,
            config
        )

        dispatch({
            type: COMPANY_UPDATE_SUCCESS,
            payload: data,
        })

        dispatch(listCompanyDetailsById(accessToken, companyId, company.email))

    } catch (error) {
        dispatch({
            type: COMPANY_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listCompanyDetails = (accessToken, email, name) => async (dispatch) => {
    try{
        dispatch({ type: COMPANY_DETAILS_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/company/byEmail/${email}/${name}`,
            config
            )

        dispatch({ 
            type: COMPANY_DETAILS_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: COMPANY_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 
export const listCompanyDetailsById = (accessToken, id, email) => async (dispatch) => {
    try{
        dispatch({ type: COMPANY_DETAILS_BY_ID_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.get(
            `/api/company/byId/${id}/${email}`,
            config,

            )

        dispatch({ 
            type: COMPANY_DETAILS_BY_ID_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: COMPANY_DETAILS_BY_ID_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }

 export const deleteCompany = (accessToken, id) => async (dispatch) => {
    try{
        dispatch({ type: COMPANY_DELETE_REQUEST })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }

        const { data } = await axios.delete(
            `/api/company/delete/${id}`,
            config
            )

        dispatch({ 
            type: COMPANY_DELETE_SUCCESS,
            payload: data 
        })
    }catch(error){
        dispatch({
            type: COMPANY_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
 }