import React, {useState, useEffect}  from "react";

import { Content, Grid, Text, Button, Menu, Header } from "../../styled-components/dashboard";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import { listCompanyDetailsById } from "../../actions/companyActions";

import Modal from 'react-modal';
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";

export function DeleteCompanyContainer({ accessToken, name, email }) {
  const dispatch = useDispatch() 
  const history = useHistory()

  const redirectCompaniesHandler = (e) => {
    history.push(`/companies`)
}
  const [companyId] = useState(history.location.pathname.substring(history.location.pathname.indexOf('company')+8, 100))
 
  const companyDetailsById = useSelector(state => state.companyDetailsById)
  const {loading: loadingDetailsByIdCompany, 
      error: errorDetailsByIdCompany, 
      success: successDetailsByIdCompany, 
      company
  } = companyDetailsById

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [deleteInput, setDeleteInput] = useState('')

  useEffect(() => {
    if(accessToken && email && companyId) 
          {
          if(successDetailsByIdCompany){
          }
          else{
            dispatch(listCompanyDetailsById(accessToken, companyId))
          }
        }
  }, [companyId,
    
  ])
  

  const DeleteHandler = (e) => {
      setModalIsOpen(true)

  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
     // height: '40%',
      width: '28%',
      display: 'flex',
      flexDirection: 'column',
      padding: '36px'
    },
  };

  const ModalContainer = () => {
    return(
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px'
          }}
        >
          <Menu.LogoImg  
          style = {{
            width: '82px',
            height: '20px'
            
          }}
          src={require("../../images/dashboard/serpr-logo-2.svg").default} />
          <Header.Icon 
          onClick={closeModal}
          src={require("../../images/dashboard/+.png").default} />

        </div>
        {deleteInput === 'DELETE' ? 
        <>
          <Text.Bold40Black
            style={{fontSize: '50px'}}
          >
            Please confirm once more before you delete
          </Text.Bold40Black> 
          <Button.Save
            style={{
              margin: '0',
              marginTop: '20px'
            }}
          >
            Delete
          </Button.Save>
          </>
          : 
          <Text.Bold40Black
            style={{fontSize: '50px'}}
          >
            You have not typed DELETE.
          </Text.Bold40Black> 
          }
      </Modal>
    )
  }



  const closeModal = (e) => {

    setModalIsOpen(false)
  }

  return(
      <Content>
      {ModalContainer()}
      {loadingDetailsByIdCompany ? 
      <LoaderImage/> : 
      errorDetailsByIdCompany ?
      <ErrorMessage /> :
      <>
        <Content.SectionTop>
        
          <Grid>
            <Grid.Section>
            <Text.Bold40Black>
                Are you sure you want to <span> </span>
              </Text.Bold40Black>
             {company &&<Text.Bold42Pink
                
              >
                remove {company.name} <span> </span>
              </Text.Bold42Pink>} 
              <Text.Bold40Black>
                from Luminr?
              </Text.Bold40Black>
              <Text.Bold16Black
                style={{marginTop: '26px'}}
              >
                To remove your Company, type <span style={{color: '#C51852'}}>DELETE</span> bellow.
              </Text.Bold16Black>
              <Text.Field14Black
                placeholder="Type delete"
                type="text"
                value={deleteInput}
                onChange={(e) => setDeleteInput(e.target.value)}
              >

              </Text.Field14Black>
              <Content.ButtonsContainer>
            
            <Button.Back
            onClick={redirectCompaniesHandler}
            style={{margin: '0'}}
            >
            Back
          </Button.Back>
          <Button.Save 
          onClick={DeleteHandler}
          >
              Delete
            </Button.Save>
          </Content.ButtonsContainer>
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        </>
      }
      </Content>
  )
}
