import { 
    GENERATE_KEYWORDS_REQUEST,
    GENERATE_KEYWORDS_SUCCESS,
    GENERATE_KEYWORDS_FAIL,
    GENERATE_KEYWORDS_RESET,

 } from '../constants/generateKeywordsConstants'

 export const generateKeywordsReducer =  (state = {generatedKeywords:[]}, action) => {
    switch(action.type) {
        case GENERATE_KEYWORDS_REQUEST:
            return {loading: true, requests:[]}

        case GENERATE_KEYWORDS_SUCCESS:
            return {loading: false, success: true, generatedKeywords: action.payload}

        case GENERATE_KEYWORDS_FAIL:
            return {loading: false, error: action.payload }

        case GENERATE_KEYWORDS_RESET: 
            return {}

        default:
            return state
    }
}