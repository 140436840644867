import React, { useState,useEffect } from "react";
import { Text, Content } from "../../../styled-components/visualization";
import { ContentCreationOpportunitiesTableContainer } from "../charts/ContentCreationOpportunitiesTable";
import { ContentExplorerTreeMapContainer } from "../charts/ContentExplorerTreemap";
import { TitleContainer } from "../utilComponents/Title";
import { assistantText, insightsText } from "../utilComponents/InsightsText";

import ResultsMissing from "../../../components/ResultsMissing";
import { nFormatter } from "../resultsComponents/serpScoreboard/sortingUtils";
import { COLOR_PALETTE_1, COLOR_PALETTE_2, COLOR_PALETTE_3, COLOR_PALETTE_4, COLOR_PALETTE_5, COLOR_PALETTE_6, COLOR_PALETTE_7, COLOR_PALETTE_8 } from "../../../constants/theme/colours";


export function ContentCreationOpportunitiesContainer2({contentExplorerResults}) {
  const [selectedContentUrl, setSelectedContentUrl] = useState('');
  const [colorIntervals, setColorIntervals] = useState([])
  const [topContentCreation, setTopContentCreation] = useState([])
  const [fastestContentCreation, setFastestContentCreation] = useState([])

  const csvHeader = [
    { label: "Content", key: "domain" },
    { label: "Parent Group", key: "keyword_parent" },
    { label: "Current", key: "serp_real_estate_current" },
    { label: "Previous", key: "serp_real_estate_previous_period" }
  ];
  var topContentCreationVal = [];
  var fastestContentCreationVal = [];
  useEffect( ()=>{
    if(contentExplorerResults) {
      if(contentExplorerResults.length !=0 && typeof(contentExplorerResults) ==='object'){

        if(contentExplorerResults.detail == undefined){
          topContentCreationVal = [...contentExplorerResults].sort((a, b) => {
            return b.serp_real_estate_current - a.serp_real_estate_current;
          });
          setTopContentCreation(topContentCreationVal)
    
          fastestContentCreationVal = [...contentExplorerResults].sort((a, b) => {
            return b.growth - a.growth;
          });
          setFastestContentCreation(fastestContentCreationVal)
        }
      }
    }
  

  },[contentExplorerResults])

  const createMaxValueIntervals = (maxVal) =>{
    // Calculate the size of each interval
     const intervalSize = maxVal / 8;

     // Create the intervals with colors
     const intervals = [];
     for (let i = 1; i <= 8; i++) { // 8 intervals
     const intervalStart = intervalSize * (i - 1);
     const intervalEnd = intervalSize * i;
     let color;
     switch (i) {
         case 1:
         color = COLOR_PALETTE_1;
         break;
         case 2:
         color = COLOR_PALETTE_2;
         break;
         case 3:
         color = COLOR_PALETTE_3;
         break;
         case 4:
         color = COLOR_PALETTE_4;
         break;
         case 5:
         color = COLOR_PALETTE_5;
         break;
         case 6:
         color = COLOR_PALETTE_6;
         break;
         case 7:
         color = COLOR_PALETTE_7;
         break;
         case 8:
         color = COLOR_PALETTE_8;
         break;
         default:
         color = 'black'; // or any default color
     }
     intervals.push({ start: intervalStart, end: intervalEnd, color });
     }

     setColorIntervals(intervals);
  }

  useEffect( ()=>{
    if(topContentCreation.length != 0){
      const maxValue = topContentCreation[0].serp_real_estate_current;
      createMaxValueIntervals(maxValue);
    }

  },[topContentCreation])

 

  return(
      <Content.FlexColumn
      style={{
              justifyContent: 'space-between', 
              padding: '1%',
              background: 'white',
              width: '100%'
          }}
      >
      <TitleContainer 
          title='Top Content Opportunities'   
          subtitle='Identify top-performing landing pages for your keywords and use them as inspiration for your own content.'
          backdropTop = '135vw'         
          pages={insightsText.ContentCreationOpportunities[0]}
            p11={insightsText.ContentCreationOpportunities[1]}
            p21={insightsText.ContentCreationOpportunities[2]}
            p31={insightsText.ContentCreationOpportunities[3]}
            p41={insightsText.ContentCreationOpportunities[4]}
            p51={insightsText.ContentCreationOpportunities[5]}
            assistantMessage={assistantText.ContentCreationOpportunities}
            csvData={contentExplorerResults}
            csvHeader={csvHeader}
          />

      {contentExplorerResults.length != 0? 
            contentExplorerResults.detail !== undefined ? 
                <ResultsMissing details = {contentExplorerResults.detail}/>
                 : 
                 <Content.FlexRow
        style={{
          justifyContent:'space-between',
          marginTop:'2%'
        }}
      >
        <Content.FlexColumn
          style={{
            width:'35%'
          }}
        >
        {topContentCreation && topContentCreation[0]!==undefined &&
          <Content.FlexRow
            style={{
              justifyContent:'space-between',
              alignItems:'center'
            }}
          >
          {topContentCreation && topContentCreation[0].domain  &&
            <Text.Medium30Green>{nFormatter(topContentCreation[0].serp_real_estate_current)}</Text.Medium30Green>}
            <a href={'//'+topContentCreation[0].domain} target="_blank"><Content.Icon src={require("../../../images/visualization/Arrow_2.svg").default}/></a>
          </Content.FlexRow>
        }
          {topContentCreation && topContentCreation[0] &&
          <>
            <Text.Regular16Black>
              Top: {topContentCreation[0].domain}
            </Text.Regular16Black>
            <Content.HorizontalLineGrey />
          </>
          }
          
          <Content.FlexRow
            style={{
              justifyContent:'space-between',
              alignItems:'center'
            }}
          >
          {fastestContentCreation && fastestContentCreation[0]!==undefined && 
            <Text.Medium30Green>{nFormatter(fastestContentCreation[0].growth)}</Text.Medium30Green>}
            {topContentCreation[0]!==undefined && <a href={'//'+fastestContentCreation[0].domain} target="_blank"><Content.Icon src={require("../../../images/visualization/Arrow_2.svg").default}/></a>}
          </Content.FlexRow>
          {fastestContentCreation[0] !==undefined && 
          <>
            <Text.Regular16Black>
              Fastest: {fastestContentCreation[0].domain}
            </Text.Regular16Black>
            <Content.HorizontalLineGrey />
          </>
          }
          
          <ContentExplorerTreeMapContainer selectedContentUrl={selectedContentUrl} contentExplorerResults={contentExplorerResults}/>
        </Content.FlexColumn>
        <div
          style={{
            width: '60%'
          }}
        >
          {contentExplorerResults && <ContentCreationOpportunitiesTableContainer colorIntervals={colorIntervals} selectedContentUrl={selectedContentUrl} setSelectedContentUrl={setSelectedContentUrl} contentExplorerResults={contentExplorerResults}/>}
        </div>
        
                 </Content.FlexRow>

            :
            <ResultsMissing details = {contentExplorerResults.detail}/>
      }
      
      
      
  </Content.FlexColumn> 

  )

}
