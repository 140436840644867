import React, {useEffect} from 'react'
import { MenuHeaderContainer } from '../../containers/visualization/MenuHeader'
import { CompetitionContainer } from '../../containers/visualization/Competition'
import { SectionsFooterContainer } from '../../containers/visualization/SectionsFooter';
import { FooterContainer } from '../../containers/visualization/Footer';
import { useHistory } from 'react-router-dom'
import CompetitorMovementsProvider from '../../contexts/CompetitorMovementsContext';

export default function Competition({accessToken, name, email, isActive, isSuperuser, websiteId}) {
    let history = useHistory()
  
    useEffect(() => {
      if (accessToken) {
          //if account is active
          if(isActive === undefined){

        }else{
            if(isActive ){
    
            }
            else {
                history.push(`/account-not-active`)
            }
        }
      }
  }, [
      accessToken,
      email,
      isActive,
      isSuperuser
  ])

    return (
        <>
        {isActive &&
    <div>

        <MenuHeaderContainer pageName={'Competition'}  email={email} name={name} accessToken={accessToken} websiteId={websiteId}/>

        <CompetitorMovementsProvider>
            <CompetitionContainer email={email} name={name} accessToken={accessToken} websiteId={websiteId}/>
        </CompetitorMovementsProvider>
        

        <SectionsFooterContainer pageName={'Competition'}  email={email} name={name} accessToken={accessToken}/>
        <FooterContainer />
        {/* <NoWorkingPage /> */}
    </div>}
    </>
    )
}

