import {
    AUTH_USER_LIST_REQUEST,
    AUTH_USER_LIST_SUCCESS,
    AUTH_USER_LIST_FAIL,
    AUTH_USER_LIST_RESET
} from '../../constants/loggingsystem/authUserConstants'

export const authUserListReducer = (state = { authUsers: [] }, action) => {
    switch (action.type) {
        case AUTH_USER_LIST_REQUEST:
            return { loading: true, success: false, authUsers: [] }

        case AUTH_USER_LIST_SUCCESS:
            return {
                loading: false,
                success: true,
                authUsers: action.payload.users,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case AUTH_USER_LIST_FAIL:
            return { loading: false, error: action.payload } 

        case AUTH_USER_LIST_RESET: 
            return {loading:false, success:false, authUsers: {}}

        default:
            return state
    }
}
