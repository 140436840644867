import styled from "styled-components"
import {Link as ReachRouterLink} from "react-router-dom"

export const Container = styled.div`
    background: #D4D4D4D4;
    display: flex;
    flex-direction: row;
    
    height: 50px;
    width: 100%;     
    align-items: center;
    justify-content: center;

`

export const TextContainer = styled.div` 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    align-items: left;

`

export const Text = styled.p`
    text-decoration: none;
    font-size: 12px;
    color: #222222;
    opacity: 100%;
    display: block;
    margin: 0;
    
    &:hover {
        text-decoration: none;    
        color: inherit;  
        cursor: pointer;
    }
`

export const Line = styled.p`
    font-size: 12px;
    margin: 0;
    display: block;

`