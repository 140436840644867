import React from 'react'

import { CreateOrganizationContainer } from '../../containers/signup/createOrganization'

export default function CreateOrganization({accessToken, name, email, isActive, isSuperuser}) {
    return <CreateOrganizationContainer 
    accessToken={accessToken} 
    name={name} 
    email={email} 
    isActive={isActive}
    isSuperuser={isSuperuser}
    />
}
