import React from 'react'
import { TemplateContainer } from '../../containers/dashboard/template'

export default function Websites({accessToken, name, email, isActive, isSuperuser}) {
    return (
    <TemplateContainer pageName={'Websites'} email={email} 
    name={name} 
    accessToken={accessToken}
    isActive={isActive}
    isSuperuser={isSuperuser}
    />)
}

