import React from "react";

import { Text, Content, Button } from "../../../styled-components/visualization";

export function AssistantContainer({name}) {

  return (
    <Content.FlexRow
        style={{
            padding: '2% 1% 2% 2%',
            background: '#EEEEEE',
            marginTop: '3%',
            position: 'relative',
            justifyContent: 'space-between'
        }}
    >
        <div
            style={{
                width: '17%'
            }}
        >

        </div>
        <img 
        alt='blocks'
            style={{
                position: 'absolute',
                display: 'block',
                width: '20%',
                height:'15em',
                top: '-20px'

            }}
            src={require("../../../images/visualization/blocks.svg").default}/>
        <Content.FlexColumn
            style={{
                width: '27%',
                justifyContent: 'center'
            }}
        >
            <Text.Medium40Purple>Hi {name}!</Text.Medium40Purple>
            <Text.Medium40Purple>
                Welcome to your Luminr Dashboard.
            </Text.Medium40Purple>
        </Content.FlexColumn>
        <Content.FlexRow
        style={{
                width: '30%',
                alignItems: 'center'
            }}>
        <Text.Regular16Black   >
        Want to make the most of Luminr with some expert help? Our platforms team is on hand to guide you through your dashboard to find insights and opportunities that will help grow your search presence and beat out the competition.
        </Text.Regular16Black>
        </Content.FlexRow>
        <Content.FlexColumn
            style={{
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                width: '8%'
            }}
        >
        <div></div>
            {/* <img 
                alt='visualization'
                style={{
                    width: '20px',
                    display: 'block'
                }}
            src={require("../../../images/visualization/x.svg").default}/> */}
            <Button.PrimaryLink
            href="mailto:emily.white@luminr.ai"
            >
                Get in touch
            </Button.PrimaryLink>
        </Content.FlexColumn>
    </Content.FlexRow>
  )
}
