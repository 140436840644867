import React, {useContext, useEffect, useState} from 'react'
import { Content, Text } from '../styled-components/visualization';
import { FilterContext } from '../contexts/FilterContext';
import { CompanyContext } from '../contexts/CompanyContext';

function ResultsMissing({details=undefined, message='', missingOpportunitiesMessage=''}) {

    const [activeFilters, setActiveFilters] = useState(false)

    const {dateFilterApplied} = useContext(CompanyContext)
    const {filter_criteria,
      } = useContext(FilterContext)


    //filters are applied when filter_criteria has items with 'elements'.length != 0
    useEffect( ()=>{
        if(filter_criteria){
            filter_criteria.map( (item)=>{
                const filterElements = item.elements;
                if(filterElements.length !== 0){

                    setActiveFilters(true)
                }
            })
        }
        if(dateFilterApplied.length !== 0){
            setActiveFilters(true)
        }

    },[filter_criteria,dateFilterApplied]) 


    const resetFilters = ()=>{
        window.location.reload(); // reloading the page, will reset all filters
    }
   
    return (
        <>
        {activeFilters ?
            <Text.Regular14Black
                style={{
                    padding: '1em',
                    textAlign:'center',
                    width:'auto'
                }}
            >
                 {details !== 'noimage' &&  <Content.Icon style={{
                        width: "10em",
                        height: "10em",
                        margin: "0 0 10px 0",
                      }}
                    src={require("../images/visualization/NoResults.svg").default}/>
                    }
                    
                <Text.Bold15Black>No results found</Text.Bold15Black>
                <button style={{background:'none', border:'none', color:'#C51852', textDecoration:'underline'}}  onClick={()=>resetFilters()} >Update</button> or 
                <button style={{background:'none', border:'none', color:'#C51852', textDecoration:'underline'}}   onClick={()=>resetFilters()}>reset</button> your filters to see more data.
                {message}
                {/* {details.details !== undefined ? details.details : ''} */}
            </Text.Regular14Black>
            :
            <Text.Regular14Black
                style={{
                    padding: '1em',
                    textAlign:'center',
                    width:'auto'
                }}
            >
                 {details !== 'noimage' && <Content.Icon style={{
                        width: "10em",
                        height: "10em",
                        margin: "0 0 10px 0",
                      }}
                    src={require("../images/visualization/NoData.svg").default}/>
                    }

                {missingOpportunitiesMessage !== '' ?
                    <Text.Bold15Black>{missingOpportunitiesMessage}</Text.Bold15Black>
                    :
                    <>
                    <Text.Bold15Black>No data found</Text.Bold15Black>
                    There is no data to populate this chart.
                    {message}
                    </>
                }
                
            </Text.Regular14Black>
        }
        </>
       
    )
}

export default ResultsMissing
