
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Text, 
  Content,
  Button,
} from "../../../styled-components/visualization";
import { FilterContext } from "../../../contexts/FilterContext";
import { GET_FILTERS_RESET,
  BIG_KEYWORD_RANKING_RESET, 
  COMPETITOR_CHANNEL_RESET, 
  COMPETITOR_LANDSCAPE_RESET, 
  COMPETITOR_SWOT_HEATMAP_RESET, 
  CONTENT_EXPLORER_RESET, 
  FEATURED_SNIPPED_OPPORTUNITIES_RESET, 
  HVN_SCORE_RESET, 
  MONTHLY_MOVEMENTS_RESET, 
  RETRIEVE_SWOT_RESET, 
  SERP_COMPOSITION_RESET,
  SERP_COMPOSITION_INCLUDE_RESET,
  SERP_LEAGUE_RESET,
} from "../../../constants/visualization/dataScienceAPIsConstants";





export const DropDownActiveFilterContainer = ({ type,  filter_criteria,setFilter_criteria,setFirstRender}) => {
  const dispatch = useDispatch();
  const [activeFiltersArr, setActiveFiltersArr] = useState([])

  const {
    exclude_array_keyword_parent,
    exclude_array_keyword_child,
    exclude_array_keyword_portfolio,
    exclude_array_keyword_subportfolio,
    exclude_array_keyword,
    exclude_array_country,
    exclude_array_location,
    exclude_array_language,  
    exclude_array_device,
    exclude_array_domain,
    exclude_array_component_clean,

  } = useContext(FilterContext)

   //store selected filters 

     //filters are applied when filter_criteria has items with 'elements'.length != 0
     useEffect( ()=>{
      if(filter_criteria){

          let filter_criteria_arr =[];
          if(type == 'focus'){
              filter_criteria_arr = filter_criteria.slice(0, 5);
          }else if( type == 'localisation'){
              filter_criteria_arr = filter_criteria.slice(5, 9);
          }else if(type == 'serpr'){
              filter_criteria_arr = filter_criteria.slice(9,11);
          }else{
              filter_criteria_arr = filter_criteria;
          }

          filter_criteria_arr.map( (item)=>{
              const filterElements = item.elements;
              if(filterElements.length != 0){
                // Split the input string into an array of words
                const wordsArray = item.dimension.split('_');

                // Capitalize the first letter of each word and join them with a space
                const transformedDimension = wordsArray.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                const capitalizedMode =item.mode.charAt(0).toUpperCase()+ item.mode.slice(1)

                let transformedString = '';
                if(filterElements.length > 2){
                   transformedString = filterElements.length;
                }else{
                   transformedString = filterElements.join(', ');
                }
                
                setActiveFiltersArr((prevActiveFiltersArr) => [...prevActiveFiltersArr, {'originalDimension':item.dimension,  'dimension':transformedDimension, 'mode':capitalizedMode, 'elements':transformedString}]);
              }
          })
      }
      
  },[filter_criteria]) 

  

  const resetFilter = (dimension) =>{
    if(filter_criteria){
      // filter_criteria.map ((item)=>{
      //   if(item.dimension == dimension){
      //     item.elements = []; // reset filter
      //   }
      // })
      // setFilter_criteria (filter_criteria);
      //dispatch 
      resetComponents(dimension);
    }
  }

  const resetComponents = (filterType) => {
    dispatch({type: GET_FILTERS_RESET})

    dispatch({type: BIG_KEYWORD_RANKING_RESET})
    dispatch({type: SERP_COMPOSITION_RESET})
    dispatch({type: SERP_COMPOSITION_INCLUDE_RESET})
    dispatch({type: CONTENT_EXPLORER_RESET})
    dispatch({type: RETRIEVE_SWOT_RESET})
    dispatch({type: RETRIEVE_SWOT_RESET})
    dispatch({type: FEATURED_SNIPPED_OPPORTUNITIES_RESET}) 

    //COMPETITION
    dispatch({type: COMPETITOR_LANDSCAPE_RESET})
    dispatch({type: COMPETITOR_SWOT_HEATMAP_RESET})
    dispatch({type: SERP_LEAGUE_RESET})
    dispatch({type: MONTHLY_MOVEMENTS_RESET})
    dispatch({type: HVN_SCORE_RESET})
    dispatch({type: COMPETITOR_CHANNEL_RESET})


    if(filterType == 'keyword_parent'){
      exclude_array_keyword_parent.splice(0,exclude_array_keyword_parent.length)
    }

    if(filterType == 'keyword_child'){
      exclude_array_keyword_child.splice(0,exclude_array_keyword_child.length)

    }
    if(filterType == 'keyword_portfolio'){
      exclude_array_keyword_portfolio.splice(0,exclude_array_keyword_portfolio.length)

    }
    if(filterType == 'keyword_subportfolio'){
      exclude_array_keyword_subportfolio.splice(0,exclude_array_keyword_subportfolio.length)

    }
    if(filterType == 'keyword'){
      exclude_array_keyword.splice(0,exclude_array_keyword.length)

    }
    if(filterType == 'country'){
      exclude_array_country.splice(0,exclude_array_country.length)

    }
    if(filterType == 'location'){
      exclude_array_location.splice(0,exclude_array_location.length)

    }
    if(filterType == 'language'){
      exclude_array_language.splice(0,exclude_array_language.length)

    }
    if(filterType == 'device'){
      exclude_array_device.splice(0,exclude_array_device.length)

    }
    if(filterType == 'domain'){
      exclude_array_domain.splice(0,exclude_array_domain.length)

    }
    if(filterType == ''){
      exclude_array_component_clean.splice(0,exclude_array_component_clean.length)

    }
    setFirstRender(true)
  }



  return (
    <Content.FlexColumn
    style={{
        position:'relative'
    }}
    >

      {/* for each on filr */}
      {activeFiltersArr.map((item)=>(
      
          <Content.FlexRow
                style={{
                        background: "white",
                        padding: "10px",
                        marginTop: "1.5%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor:'pointer',
                        position: 'relative',
                      }}
              >              
                  <Text.Regular14Black>Select {item.dimension} : {item.mode} {item.elements}</Text.Regular14Black>
                  <Content.Icon className={"closeIcon"}
                        onClick={() => resetFilter(item.originalDimension)}
                        src={require("../../../images/visualization/x.svg").default}/>

                
        </Content.FlexRow>
      ))}
      
    
    </Content.FlexColumn>
     
  );
};
