import React from "react";
import { Text} from "../../../styled-components/visualization";
import { FlexColumn, FlexRow } from "../../../styled-components/visualization/content/styles/content";
import { SectionsTitleContainer } from "./SectionsTitle";

export function DashboardFeaturesContainer() {

    const feautureContainer = (title, text) => {
        return(
          <FlexColumn>
            <Text.Regular18Purple
            style={{
                    fontSize: '25px'
                  }}
            >
              {title}
            </Text.Regular18Purple>
            <Text.Light16Black
              style={{
                marginTop: '4%'
              }}
            >
              {text}
            </Text.Light16Black>
          </FlexColumn>
          
        )
      }

  return (
    <>
    <SectionsTitleContainer 
    firstTitle='How it works'
    secondTitle='Dashboard Features'
    description='We created Luminr, a global search intelligence platform to evaluate every pixel of page one, discovering patterns, opportunities, and insights. Below are some main features and measurements to help you get the most out of the platform and help your team transform raw challenging data into actionable insights.'

    />
      <FlexColumn
              style={{
                width: '100%'
              }}
      >
        <FlexRow
              style={{
                justifyContent: 'space-between',
                width: '100%'
              }}
        >
          <FlexColumn
              style={{
                width: '45%'
              }}
          >
            {/* <Content.ExampleFeatureContainer>
            
            </Content.ExampleFeatureContainer>     */}
            {feautureContainer('Insights/Pro Tips', 'Advice is dotted around the dashboard to help you act on what you see. These might be guidance on how to quickly interpret the data or where you can to turn insight into opportunity.')}
          </FlexColumn>
          <FlexColumn
          style={{
                width: '45%'
              }}
          >
            {/* <Content.ExampleFeatureContainer>
            
            </Content.ExampleFeatureContainer> */}
            {feautureContainer('Luminr Score', 'Our global measure of how effective your brand is in capturing traffic from page one of search. This will be your overall score for Total Search performance that you should aim to push as high as possible to maximise your websites ability to capture relevant traffic.')}    
          </FlexColumn>
          
        </FlexRow>
        <FlexRow
              style={{
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '4%'
              }}
        >
          <FlexColumn
              style={{
                width: '45%'
              }}
          >
            {/* <Content.ExampleFeatureContainer>
            
            </Content.ExampleFeatureContainer>     */}
            {feautureContainer('SERP Real Estate', 'Luminrs proprietary measure of Total Search visibility across page one of Google (also available for Baidu, Bing and Yandex). Its not just about SEO visibility or PPC impression share - Luminr measures your brand ownership across every pixel of page one.')}
          </FlexColumn>
          <FlexColumn
          style={{
                width: '45%'
              }}
          >
            {/* <Content.ExampleFeatureContainer>
            
            </Content.ExampleFeatureContainer> */}
            {feautureContainer('Total Search', 'When SEO and PPC are used as growth tactics they can become siloed and even compete to drive the most value. Total Search is a data-led approach to aligning marketing activities toward a shared goal. "TS CTR" is your Total Search Click-Through Rate.')}    
          </FlexColumn>
          
        </FlexRow>
      </FlexColumn>
      </>
  )
}
