import React from "react";
import { useSelector } from "react-redux";
import { Text, Content} from "../../../styled-components/visualization";
import { IndustryAverageRadialChartContainer } from "../charts/IndustryAverageRadialChart";

import { TitleContainer } from "../utilComponents/Title";
import { assistantText, insightsText } from "../utilComponents/InsightsText";
import ResultsMissing from "../../../components/ResultsMissing";

export function IndustryAverageContainer({companyIndustry,industryBenchmarksStatsResults}) {

  const trackingSummaryVar = useSelector((state) => state.trackingSummary)
  const {
      loading: loadingTrackingSummary,
      error: errorTrackingSummary,
      success: successTrackingSummary,
      trackingSummaryResults,
  } = trackingSummaryVar

  const componentTitle = companyIndustry ? 
    companyIndustry.charAt(0).toUpperCase() + companyIndustry.slice(1)+' Average Total Search CTR' 
    : 'Industry Average Total Search CTR';

  return (
    <Content.FlexColumn
        style={{
            width: '100%',
            justifyContent: 'space-between',
            padding: '1%',
            background: 'white',
        }}
    >
        <TitleContainer  
            title = {componentTitle}
            subtitle='Benchmark your Total Search CTR against industry averages.'
            pages={insightsText.IndustryAverageClickThroughRate[0]}
            p11={insightsText.IndustryAverageClickThroughRate[1]}
            p21={insightsText.IndustryAverageClickThroughRate[2]}
            p31={insightsText.IndustryAverageClickThroughRate[3]}
            p41={insightsText.IndustryAverageClickThroughRate[4]}
            assistantMessage={assistantText.IndustryAverageClickThroughRate}
            />
        <Content.FlexRow>
          <div
            style={{
              width: '65%',
              marginRight:'2%',
              marginTop:'2%',
              display:'flex',
              justifyContent:'space-around'
            }}
          >

          {industryBenchmarksStatsResults.length != 0? 
              industryBenchmarksStatsResults.detail !== undefined ? 
                  <ResultsMissing details = {industryBenchmarksStatsResults.detail}/>
                  : 
                  <>
                    <IndustryAverageRadialChartContainer industryResult={industryBenchmarksStatsResults[0].industry_avg_ctr} variant=' Industry Average CTR' />  
                    <IndustryAverageRadialChartContainer industryResult={industryBenchmarksStatsResults[0].weighted_avg_total_search_ctr} variant=' Website Average CTR' />  

                  </>
              :
              <ResultsMissing details = {industryBenchmarksStatsResults.detail}/>
          }


            
          </div>
          <Content.FlexColumn
            style={{
              width: '41%'
            }}
          >
              <Text.Medium30Black>
                {industryBenchmarksStatsResults[0] && industryBenchmarksStatsResults[0].num_websites  }
              </Text.Medium30Black>
              <Text.Regular16Black>
              Websites competing with you for page one real estate. These sites contribute to the Industry Average Total Search CTR score.
              </Text.Regular16Black>
             
              <Content.HorizontalLineGrey />

              <Text.Medium30Black style={{overflowWrap:"break-word"}}>
                {industryBenchmarksStatsResults[0] && (industryBenchmarksStatsResults[0].industry_max_ctr * 100) .toFixed(1) + '%' }
              </Text.Medium30Black>
              <Text.Regular16Black>
              Is the highest Total Search CTR within the industry. It is owned by:
              </Text.Regular16Black>
              <Text.Medium30Black style={{overflowWrap:"break-word"}}>
                {industryBenchmarksStatsResults[0] && industryBenchmarksStatsResults[0].top_website }
              </Text.Medium30Black>
              <Content.HorizontalLineGrey />
           
          </Content.FlexColumn>

        </Content.FlexRow>
       
    </Content.FlexColumn>
  )
}
