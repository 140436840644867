import React from "react"
import { 
    Container, 
    Column,
    Title, 
    Label
} from "./styles/users"

export default function Users({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

Users.Column = function UsersColumn({children, ...restProps}){
    return <Column {...restProps}>{children}</Column>   
}

Users.Title = function UsersTitle({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>   
}

Users.Label = function UsersLabel({children, ...restProps}){
    return <Label {...restProps}>{children}</Label>   
}
