import styled from "styled-components"
import * as pallete from '../../../../constants/theme/visualization/colours'


export const Container = styled.div`

`

export const Primary = styled.button`
    display: block;
    color: white;
    background: ${pallete.SERPR_PURPLE};
    font-size: 18px;
    width: 168px;
    padding: 13px 0;
    border: none;
    border-radius: 4px;

    transition: 0.3s;
    transition-timing-function: ease-out;

    
    &:hover{
        color: ${pallete.SERPR_PURPLE};
        background: ${pallete.BUTTON_WHITE};
    }

    &:active {
        color: white;
        background: #6F194C;
    }
`

export const PrimaryLink = styled.a`
    display: block;
    color: white;
    background: ${pallete.SERPR_PURPLE};
    font-size: 18px;
    width: 168px;
    padding: 13px 0;
    border: none;
    border-radius: 4px;

    transition: 0.3s;
    transition-timing-function: ease-out;
    text-decoration: none;
    text-align: center;

    
    &:hover{
        color: ${pallete.SERPR_PURPLE};
        background: ${pallete.BUTTON_WHITE};
        text-decoration: none;
    }

    &:active {
        color: white;
        background: #6F194C;
    }
`

export const Light = styled.button`
    display: block;
    color: ${pallete.SERPR_BLACK};
    background: #F7F7F7;
    font-size: 18px;
    width: 168px;
    padding: 13px 0;
    border: none;
    border-radius: 4px;

    transition: 0.3s;
    transition-timing-function: ease-out;

    
    &:hover{
        background: ${pallete.SERPR_PURPLE};
        color: ${pallete.BUTTON_WHITE};
    }

    &:active {
        color: white;
        background: #6F194C;
    }

`

export const Dark = styled.button`
    display: block;
    color: white;
    background: ${pallete.SERPR_BLACK};
    font-size: 18px;
    width: 168px;
    padding: 13px 0;
    border: none;
    border-radius: 4px;

    transition: 0.3s;
    transition-timing-function: ease-out;

    
    &:hover{
        background: #676767;
    }

    &:active {
        background: #000000;
    }
`