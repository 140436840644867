import styled from "styled-components"
//import {Link as ReachRouterLink} from "react-router-dom"

export const Container = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    align-items: left;
    width: 100%;
    padding: 34px 24px;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;

`

export const Title = styled.p`
    font-size: 18px;
    color: #C51852;
    font-weight: bold;
    margin-bottom: 24px;
`

export const Label = styled.input`
    display: block;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 14px;
`