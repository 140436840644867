export const COMPETITOR_LIST_REQUEST   = 'COMPETITOR_LIST_REQUEST'
export const COMPETITOR_LIST_SUCCESS   = 'COMPETITOR_LIST_SUCCESS'
export const COMPETITOR_LIST_FAIL      = 'COMPETITOR_LIST_FAIL'
export const COMPETITOR_LIST_RESET     = 'COMPETITOR_LIST_RESET'

export const COMPETITOR_DETAILS_REQUEST= 'COMPETITOR_DETAILS_REQUEST'
export const COMPETITOR_DETAILS_SUCCESS= 'COMPETITOR_DETAILS_SUCCESS'
export const COMPETITOR_DETAILS_FAIL   = 'COMPETITOR_DETAILS_FAIL'
export const COMPETITOR_DETAILS_RESET   = 'COMPETITOR_DETAILS_RESET'

export const COMPETITOR_DETAILS_BY_ID_REQUEST   = 'COMPETITOR_COMPETITOR_BY_ID_REQUEST'
export const COMPETITOR_DETAILS_BY_ID_SUCCESS   = 'COMPETITOR_COMPETITOR_BY_ID_SUCCESS'
export const COMPETITOR_DETAILS_BY_ID_FAIL      = 'COMPETITOR_COMPETITOR_BY_ID_FAIL'
export const COMPETITOR_DETAILS_BY_ID_RESET     = 'COMPETITOR_COMPETITOR_BY_ID_RESET'

export const COMPETITOR_CREATE_REQUEST = 'COMPETITOR_CREATE_REQUEST'
export const COMPETITOR_CREATE_SUCCESS = 'COMPETITOR_CREATE_SUCCESS'
export const COMPETITOR_CREATE_FAIL    = 'COMPETITOR_CREATE_FAIL'
export const COMPETITOR_CREATE_RESET   = 'COMPETITOR_CREATE_RESET'

export const COMPETITOR_UPDATE_REQUEST = 'COMPETITOR_UPDATE_REQUEST'
export const COMPETITOR_UPDATE_SUCCESS = 'COMPETITOR_UPDATE_SUCCESS'
export const COMPETITOR_UPDATE_FAIL    = 'COMPETITOR_UPDATE_FAIL'
export const COMPETITOR_UPDATE_RESET   = 'COMPETITOR_UPDATE_RESET'

export const COMPETITOR_DELETE_REQUEST = 'COMPETITOR_DELETE_REQUEST'
export const COMPETITOR_DELETE_SUCCESS = 'COMPETITOR_DELETE_SUCCESS'
export const COMPETITOR_DELETE_FAIL    = 'COMPETITOR_DELETE_FAIL'
export const COMPETITOR_DELETE_RESET   = 'COMPETITOR_DELETE_RESET'