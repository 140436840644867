import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button } from "../../styled-components/dashboard";
import { industries } from '../../constants/utils'
import { useHistory } from "react-router-dom"
import {phoneNumberRegex, nameRegex, addressRegex} from '../../constants/regex/regex'
import { 
  listOrganisationDetails,
} from '../../actions/organisationActions'
import {createCompany} from '../../actions/companyActions'
import Loader from "../../components/Loader";
import ErrorMessage from "../../components/ErrorMessage";
import UpdateConfirmed from "../../components/UpdateConfirmed";
import InformationNotFilled from "../../components/InformationNotFilled";
import LoaderImage from "../../components/LoaderImage";
import UniqueName from "../../components/UniqueName";

import {COMPANY_CREATE_RESET} from '../../constants/companyConstants'


export function AddCompanyContainer({ accessToken, name, email }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')
  const [companyTelephone, setCompanyTelephone] = useState('')
  const [companyVatNumber, setCompanyVatNumber] = useState('')
  const [industry, setIndustry] = useState('')

  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const [addressIsValid, setAddressIsValid] = useState(true);
  const [addressErrorMessage, setAddressErrorMessage] = useState('');

  const [nameIsValid, setNameIsValid] = useState(true);
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const [vatNumberIsValid, setVatNumberIsValid] = useState(true);
  const [vatNumberErrorMessage, setVatNumberErrorMessage] = useState('');

  const [informationFilled, setInformationFilled] = useState(0)

  const [updateToggle, setUpdateToggle] =useState(false)
  const [uniqueNameToggle, setUniqueNameToggle] =useState(false)

      // Organisation details
    const [organisationId, setOrganisationId] = useState('')
    const organisationDetails = useSelector(
      (state) => state.organisationDetails
    )
    const {
      loading: loadingDetailsOrganisation,
      error: errorDetailsOrganisation,
      success: successDetailsOrganisation,
        organisation: detailsOrganisation,
    } = organisationDetails

    const companyCreate = useSelector(
      (state) => state.companyCreate 
    )

    const {
      loading: loadingCompanyCreate,
      error: errorCompanyCreate,
      success: successCompanyCreate,
      company
  } = companyCreate


  const nextHandler = () => {
     history.push(`/add-website/${company.id}`)
     dispatch({type: COMPANY_CREATE_RESET}) 
  }

  const createCompanyHandler = (e) => {
    e.preventDefault()
    if(companyName === ''
    ) {
      setInformationFilled(1)
    } else{
      const company = {
        name: companyName,
        userName: name,
        address: companyAddress,
        telephone: companyTelephone,
        id: organisationId,
        industry: industry,
        vatNumber: companyVatNumber ? companyVatNumber : 'missing',
        email,
    }
    dispatch(createCompany(accessToken, company))
    setUpdateToggle(true)
    }
    // setTimeout(() => {
    //   setUpdateToggle(false);
    // }, 2000);

       
  }

  function validatePhoneNumber(phoneNumber) {
    if (phoneNumberRegex.test(phoneNumber)) {
      setPhoneIsValid(true);
      setPhoneErrorMessage('');
    } else {
      setPhoneIsValid(false);
      setPhoneErrorMessage('Invalid phone number format');
    }
  }

  function validateAddress(address) {
    if (addressRegex.test(address)) {
      setAddressIsValid(true);
      setAddressErrorMessage('');
    } else {
      setAddressIsValid(false);
      setAddressErrorMessage('Invalid address format');
    }
  }

  function validateName(name) {
    if (nameRegex.test(name)) {
      setNameIsValid(true);
      setNameErrorMessage('');
    } else {
      setNameIsValid(false);
      setNameErrorMessage('Invalid name format');
    }
  }

  function validateVatNumber(vatNumber) {
    if (nameRegex.test(vatNumber)) {
      setVatNumberIsValid(true);
      setVatNumberErrorMessage('');
    } else {
      setVatNumberIsValid(false);
      setVatNumberErrorMessage('Invalid VAT Number format');
    }
  }


  useEffect(() => {
    if (accessToken && email) {
      if(errorCompanyCreate){
        setUniqueNameToggle(true)
      }
        if (successDetailsOrganisation) {
            setOrganisationId(detailsOrganisation.id)
            if(successCompanyCreate){
              history.push(`/add-website/${company.id}`)
              dispatch({type: COMPANY_CREATE_RESET}) 

            }
        } else {
            dispatch(
                listOrganisationDetails(accessToken, email)
            )
        }
    }
  }, [
    accessToken,
    dispatch,
    detailsOrganisation,
    successDetailsOrganisation,
    errorCompanyCreate,
    email,
    successCompanyCreate
  ])


  return(
    <>
      <Content>
        {loadingDetailsOrganisation || !successDetailsOrganisation  ||
        loadingCompanyCreate || successCompanyCreate
        
        ?
        <LoaderImage /> :
        errorDetailsOrganisation ?
        <ErrorMessage /> :
        <>
        
        <Content.SectionTop>
          <Grid>
            <Grid.Section>
              <Text.Bold40Black>
                To get started add a company to <span> </span>
              </Text.Bold40Black>
              <Text.Bold42Pink
                
              >
                Luminr
              </Text.Bold42Pink>
              <Text.Normal14Black
              style={{marginTop: '26px'}}>
                Companies on Luminr have to be created before adding any users or websites to your account.
              </Text.Normal14Black>
              <Text.Normal14Black>
                All users are added at a company level.
              </Text.Normal14Black>
              <Text.Normal14Black>
                A company can have multiple websites under it. This is provided the flexibility to create dashboards for each website or brand that sits within your company.
              </Text.Normal14Black>
                
            </Grid.Section>
            <Grid.Section>
              <Text.Bold26Pink>
                Basic Information
                <Text.Normal14Black>
                * indicates a required field.
                </Text.Normal14Black>
              </Text.Bold26Pink>
              
              <Text.Bold16Black>
                Company Name
                <Text.AsterixPurple>*</Text.AsterixPurple>
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Company name"
                type="text"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  validateName(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{nameErrorMessage}</span>
              <Text.Bold16Black>
                Address
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Address"
                type="text"
                value={companyAddress}
                onChange={(e) => {
                  setCompanyAddress(e.target.value);
                  validateAddress(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{addressErrorMessage}</span>
              {/* <Text.Bold16Black>
                Phone Number
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Phone Number"
                type="text"
                value={companyTelephone}
                onChange={(e) => {
                  setCompanyTelephone(e.target.value);
                  validatePhoneNumber(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{phoneErrorMessage}</span> */}
             {/* <Select param={industry} setParam={setIndustry} list={industries} placeholder='Choose industry' title='Industry' /> */}
              {/* <Text.Bold16Black>
                VAT Number  */}
                {/* <span style={{fontStyle: 'italic', fontWeight: 'normal', color: '#C51852'}}>*</span> */}
              {/* </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="VAT Number"
                type="text"
                value={companyVatNumber}
                onChange={(e) => {
                  setCompanyVatNumber(e.target.value);
                 // validateVatNumber(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{vatNumberErrorMessage}</span> */}
            </Grid.Section>
          </Grid>
        </Content.SectionTop>
        <Content.SectionBottom>
          {loadingCompanyCreate ?
            <Loader /> :
      
         
          <Content.ButtonsContainer>
            
            {/* <Button.Back
            onClick={redirectCompaniesHandler}
            >
            Back
          </Button.Back> */}
          {errorCompanyCreate && <UniqueName uniqueNameToggle={uniqueNameToggle} setUniqueNameToggle={setUniqueNameToggle} />} 
          {/* {successCompanyCreate && !errorCompanyCreate && 
            <UpdateConfirmed updateToggle={updateToggle} setUpdateToggle={setUpdateToggle} title={"Company has been saved"} subtitle={"Move to the next screen to begin creating your website"}/>} */}
          {informationFilled === 1 && <InformationNotFilled informationFilled={informationFilled} setInformationFilled={setInformationFilled}/>}
          {successCompanyCreate ? 
            <Button.Save 
              onClick={nextHandler}
              >
                  Next
            </Button.Save> :
            <Button.Save 
              disabled={!phoneIsValid | !addressIsValid | !nameIsValid | !vatNumberIsValid}
              onClick={createCompanyHandler}
               >
              Save and Continue
            </Button.Save>
            } 
          
          </Content.ButtonsContainer>
        }
        </Content.SectionBottom>
        </>
      }
      </Content>
      </>
  )
}
