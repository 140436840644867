import React from 'react'
import {
    Container,
    Group,
    Header,
    Text,
    Footer
} from './styles/modalsignup'


export default function ModalSignup({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>
}

ModalSignup.Group = function ModalSignupGroup({ children, ...restProps }) {
    return <Group {...restProps}>{children}</Group>
}

ModalSignup.Header = function ModalSignupHeader({ children, ...restProps }) {
    return <Header {...restProps}>{children}</Header>
}

ModalSignup.Text = function ModalSignupText({ children, ...restProps }) {
    return <Text {...restProps}>{children}</Text>
}

ModalSignup.Footer = function ModalSignupFooter({ children, ...restProps }) {
    return <Footer {...restProps}>{children}</Footer>
}
