export const KEYWORD_LIST_REQUEST   = 'KEYWORD_LIST_REQUEST'
export const KEYWORD_LIST_SUCCESS   = 'KEYWORD_LIST_SUCCESS'
export const KEYWORD_LIST_FAIL      = 'KEYWORD_LIST_FAIL'
export const KEYWORD_LIST_RESET     = 'KEYWORD_LIST_RESET'

export const KEYWORD_DETAILS_REQUEST= 'KEYWORD_DETAILS_REQUEST'
export const KEYWORD_DETAILS_SUCCESS= 'KEYWORD_DETAILS_SUCCESS'
export const KEYWORD_DETAILS_FAIL   = 'KEYWORD_DETAILS_FAIL'
export const KEYWORD_DETAILS_RESET   = 'KEYWORD_DETAILS_RESET'

export const KEYWORD_DETAILS_WITHOUT_PAGINATION_REQUEST= 'KEYWORD_DETAILS_WITHOUT_PAGINATION_REQUEST'
export const KEYWORD_DETAILS_WITHOUT_PAGINATION_SUCCESS= 'KEYWORD_DETAILS_WITHOUT_PAGINATION_SUCCESS'
export const KEYWORD_DETAILS_WITHOUT_PAGINATION_FAIL   = 'KEYWORD_DETAILS_WITHOUT_PAGINATION_FAIL'
export const KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET   = 'KEYWORD_DETAILS_WITHOUT_PAGINATION_RESET'

export const KEYWORD_DETAILS_BY_ID_REQUEST   = 'KEYWORD_DETAILS_BY_ID_REQUEST'
export const KEYWORD_DETAILS_BY_ID_SUCCESS   = 'KEYWORD_DETAILS_BY_ID_SUCCESS'
export const KEYWORD_DETAILS_BY_ID_FAIL      = 'KEYWORD_DETAILS_BY_ID_FAIL'
export const KEYWORD_DETAILS_BY_ID_RESET     = 'KEYWORD_DETAILS_BY_ID_RESET'

export const KEYWORD_CREATE_REQUEST = 'KEYWORD_CREATE_REQUEST'
export const KEYWORD_CREATE_SUCCESS = 'KEYWORD_CREATE_SUCCESS'
export const KEYWORD_CREATE_FAIL    = 'KEYWORD_CREATE_FAIL'
export const KEYWORD_CREATE_RESET   = 'KEYWORD_CREATE_RESET'

export const KEYWORD_UPDATE_REQUEST = 'KEYWORD_UPDATE_REQUEST'
export const KEYWORD_UPDATE_SUCCESS = 'KEYWORD_UPDATE_SUCCESS'
export const KEYWORD_UPDATE_FAIL    = 'KEYWORD_UPDATE_FAIL'
export const KEYWORD_UPDATE_RESET   = 'KEYWORD_UPDATE_RESET'

export const KEYWORD_SORTED_REQUEST = 'KEYWORD_SORTED_REQUEST'
export const KEYWORD_SORTED_SUCCESS = 'KEYWORD_SORTED_SUCCESS'
export const KEYWORD_SORTED_FAIL    = 'KEYWORD_SORTED_FAIL'
export const KEYWORD_SORTED_RESET   = 'KEYWORD_SORTED_RESET'

export const KEYWORD_DELETE_REQUEST = 'KEYWORD_DELETE_REQUEST'
export const KEYWORD_DELETE_SUCCESS = 'KEYWORD_DELETE_SUCCESS'
export const KEYWORD_DELETE_FAIL    = 'KEYWORD_DELETE_FAIL'
export const KEYWORD_DELETE_RESET   = 'KEYWORD_DELETE_RESET'
