import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { industries } from '../../constants/utils'
import { Content, Grid, Text, Button } from "../../styled-components/dashboard";
import {  updateOrganisation, listOrganisationDetails } from '../../actions/organisationActions'
import LoaderImage from "../../components/LoaderImage";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../components/Loader";
import UpdateConfirmed from "../../components/UpdateConfirmed";
import Select from "../../components/Select";
import {phoneNumberRegex, nameRegex, addressRegex} from '../../constants/regex/regex'



export function OrganisationContainer({ accessToken, name, email }) {
  const dispatch = useDispatch();

  const [organisationId, setOrganisationId] = useState('')
  const [organisationName, setOrganisationName] = useState('')
  const [organisationAdress, setOrganisationAdress] = useState('')
  const [organisationTelephone, setOrganisationTelephone] = useState('')
  const [organisationIndustries, setOrganisationIndustries] = useState('')

  const [updateToggle, setUpdateToggle] =useState(false)

  const organisationDetails = useSelector((state) => state.organisationDetails);

  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const [addressIsValid, setAddressIsValid] = useState(true);
  const [addressErrorMessage, setAddressErrorMessage] = useState('');


  const [nameIsValid, setNameIsValid] = useState(true);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
 

  const { 
        loading: loadingDetailsOrganisation, 
        error: errorDetailsOrganisation, 
        success: successDetailsOrganisation, 
        organisation } = organisationDetails;

  const organisationUpdate = useSelector(state => state.organisationUpdate)
    const {
        loading: loadingUpdateOrganisation, 
        error: errorUpdateOrganisation, 
       success: successUpdateOrganisation, 
      //  organisation: UpdateOrganisation
    } = organisationUpdate

    const editOrganisationHandler = (e) => {
      e.preventDefault()
      const organisation = {
          id: organisationId,
          name: organisationName,
          address: organisationAdress,
          telephone: organisationTelephone,
          industry: organisationIndustries,
          email
      }
      dispatch(updateOrganisation(accessToken, organisation))
      setUpdateToggle(true)
  }

  function validatePhoneNumber(phoneNumber) {
    if (phoneNumberRegex.test(phoneNumber)) {
      setPhoneIsValid(true);
      setPhoneErrorMessage('');
    } else {
      setPhoneIsValid(false);
      setPhoneErrorMessage('Invalid phone number format');
    }
  }

  function validateAddress(address) {
    if (addressRegex.test(address)) {
      setAddressIsValid(true);
      setAddressErrorMessage('');
    } else {
      setAddressIsValid(false);
      setAddressErrorMessage('Invalid address format');
    }
  }

  function validateName(name) {
    if (nameRegex.test(name)) {
      setNameIsValid(true);
      setNameErrorMessage('');
    } else {
      setNameIsValid(false);
      setNameErrorMessage('Invalid name format');
    }
  }


  useEffect(() => {
      
    if (accessToken && email) {
      if (successDetailsOrganisation){ 
          setOrganisationId(organisation.id)
          setOrganisationName(organisation.name);
          setOrganisationAdress(organisation.address);
          setOrganisationTelephone(organisation.telephone);
          setOrganisationIndustries(organisation.industry);
      } else {
        dispatch(listOrganisationDetails(accessToken, email)) 
      }
    } else{
    }
  }, [ email, accessToken, successDetailsOrganisation, dispatch, organisation]);

  return(
    <Content>
    {loadingDetailsOrganisation || !successDetailsOrganisation ? 
    <LoaderImage />
    :
    errorDetailsOrganisation || errorUpdateOrganisation ? 
    <ErrorMessage /> :
    <>
      <Content.SectionTop>
      <Grid
      style={{backgroundSize: '0% 6px'}}
      > 
            <Grid.Section1>
              <Text.Bold26Pink>
                Basic Information
                <Text.Normal14Black>
                * indicates a required field.
                </Text.Normal14Black>
              </Text.Bold26Pink>
              <Text.Bold16Black>
                <Text.AsterixPurple>*</Text.AsterixPurple>
                Organisation Name
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Organisation name"
                type="text"
                value={organisationName}
                onChange={(e) => {
                  setOrganisationName(e.target.value);
                  validateName(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{nameErrorMessage}</span>
              <Text.Bold16Black>
                <Text.AsterixPurple>*</Text.AsterixPurple>
                Address
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Organisation Address"
                type="text"
                value={organisationAdress}
                onChange={(e) => {
                  setOrganisationAdress(e.target.value);
                  validateAddress(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{addressErrorMessage}</span>
              <Select param={organisationIndustries} setParam={setOrganisationIndustries} list={industries} placeholder='Choose industry' title='Industry' />
              <Text.Bold16Black>
                <Text.AsterixPurple>*</Text.AsterixPurple>
                Phone Number
              </Text.Bold16Black>
              <Text.Field14Black 
                placeholder="Phone Number"
                type="text"
                value={organisationTelephone}
                onChange={(e) => {
                  setOrganisationTelephone(e.target.value);
                  validatePhoneNumber(e.target.value);
                }}
              ></Text.Field14Black>
              <span style={{ color: 'red' }}>{phoneErrorMessage}</span>
              
            </Grid.Section1>
            <Grid.Section2>
              <Text.Bold26Pink>
                Organisation Settings
              </Text.Bold26Pink>
              <Text.Bold16Black>
                Coming soon ...
              </Text.Bold16Black>
            </Grid.Section2>
          </Grid>
      </Content.SectionTop>
      <Content.SectionBottom>
        {loadingUpdateOrganisation && 
            <Loader />
          }
          {successUpdateOrganisation &&
            <UpdateConfirmed updateToggle={updateToggle} setUpdateToggle={setUpdateToggle}/>
          }
          
          <Content.ButtonsContainer>
            
            {/* <Button.Back>
            Back
          </Button.Back> */}
          <Button.Save disabled={!phoneIsValid | !addressIsValid | !nameIsValid} onClick={editOrganisationHandler}>
              Save
            </Button.Save>
          </Content.ButtonsContainer>
          
      </Content.SectionBottom>
      </>
  }
    </Content>
    
    
  )
}
