import styled from "styled-components"

export const Container = styled.div`
    background: #F7F7F7;
    display: flex;
    flex-direction: column;
    letter-spacing: 0;
    padding: 0 6%;
    padding-bottom: 4%; 
    min-height: 80vh;

    @media (max-width:1199px) {
        // display: none;
        width: 1200px;
        overflow-x: auto;
    }
`

export const NoWorkingPageContainer = styled.div`

    @media (min-width:1200px) {
        display: none
    }


`
const phoneWidth = "600px"
export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`

export const Overflow = styled.div`
    @media (max-width:1199px) {
        width: 1200px;
        overflow-x: auto;
    }
`

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    
    //horizontal scrollbar style
    ::-webkit-scrollbar{
        height: 4px;
        width: 4px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background:inherit;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb:horizontal {
        -webkit-box-shadow: inset 1px 0px 0px #F6F6F6;
        background: #D8D8D8;
    }
`
export const FingerprintCharts = styled(FlexColumn)`
    position: absolute;
    height: 30%;
    width: 30%;
    justify-content: center;
    align-items: center;
    margin-top: 19%;
    margin-left: 18%;

    @media (min-width:1801px) {
        margin-top: 23%;
        margin-left: 21%;
    }
    @media (max-width:1301px) {
        margin-top: 17%;
        margin-left: 14%;
    }
`

export const DataRangeHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 4%;
    justify-content: space-between;
    align-items: center;
    background: #f2ecef;
    padding: 2.5%;
  
    &:hover{
        cursor: pointer;
    }
`

export const Icon = styled.img`
    margin-left: 15px;
    width: 25px;
    height: 25px;

    &:hover {
        cursor: pointer;
    }
`

export const PaginationIcon = styled.img`

    &:hover {
        cursor: pointer;
    }
`

export const HelpAssistant = styled.div`
    background: #3B0E28 ;
    box-shadow: 0px 3px 20px #00000017;
    padding: 30px;
    width: 300px;
    opacity: 94%;
    z-index: 100;
`

export const Insights = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 3px 20px #00000017;
    padding: 30px;
    width: 300px;
    z-index: 100;
    position:absolute;
`

export const RelativeDiv = styled.div`
    position: relative;
    display:flex;
   // align-items: center;

`

export const AbsoluteDiv = styled.div`
    position: absolute;
    top: 29px;
    left: -123px;
`

export const Triangle = styled.img`
    height: 25px;
    width: 25px;
    margin-bottom: -7px;
`

export const InsightsIcon = styled.img`
    margin-right: 6px;
    width: 12px;
    height: 19px;
`

export const InsightsIconContainer = styled.div`
    background: #841F5B;
    border-radius: 100px;
    padding: 0 10px;
    height: 25px;
    display: flex;
    align-items: center;
    cursor: pointer
`

export const KeywordsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow: auto;
    margin: 0;

    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background:inherit;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 1px 0px 0px #F6F6F6;
        background: #D8D8D8;
    }

`

export const DropDownContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;
    margin: 0;
    margin-top: 15px;

    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background:inherit;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 1px 0px 0px #F6F6F6;
        background: #D8D8D8;
    }

`

export const HorizontalLineGrey = styled.hr`
    border-top: 4px solid #707070,
    transform: matrix(0, 1, -1, 0, 0, 0);
    opacity: 0.23;
    width:100%;

`

export const ExampleFeatureContainer = styled.div`
    height: 150px;
    background: white;

`

export const SerpCompositionTable = styled.div`
    overflow: auto;
    height: 430px;
    marginTop: 20px;

    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background:inherit;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 1px 0px 0px #F6F6F6;
        background: #D8D8D8;
    }
`

export const IndustryNetScoreScroll = styled.div`
    overflow: auto;
    height: 300px;


    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background:inherit;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 1px 0px 0px #F6F6F6;
        background: #D8D8D8;
    }
`

export const HoverRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    width: 100%;
    padding: 6px;


    &:hover {
        background: #F7F7F7;
    }
`

export const FilterContainer = styled.div`
    width: inherit;
    padding: 10px;
    background: white;
    z-index: 101;
    box-shadow: 0px 3px 20px #00000017;

`

export const TickBox = styled.div`
    width: 20px;
    height: 20px;
    border: 1px solid #d1d1d1;
    margin-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    &:hover{
        cursor: pointer;
    }
`

export const DropDownItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 2px;

    transition: 0.1s;
        transition-timing-function: ease-out;

    &:hover{
        background: #F7F7F7;
    }
`

export const OnlyContainer = styled.div`
    display: none;
    
 
    ${DropDownItemContainer}:hover  & {
        display: block;
        height: 20px;
        padding: 5px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        background: #f1eaee;
        color:#841e5a;
        transition: 0.3s;
        transition-timing-function: ease-out;
        cursor: pointer;

        &:hover {
            background: #841e5a;
            color:#f1eaee;
        }
        
        //
    }
    
`