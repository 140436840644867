import React from "react";
import {  Content } from "../../../../styled-components/visualization";
import { TitleContainer } from "../../utilComponents/Title";
import { SerpScoreboardTable } from "./SerpScoreboardTable";

import { assistantText, insightsText } from "../../utilComponents/InsightsText";
import ResultsMissing from "../../../../components/ResultsMissing";
import LoaderImage from "../../../../components/LoaderImage";
import ErrorMessage from "../../../../components/ErrorMessage";

export function SerpScoreboardContainer({ serpLeagueResults,serpLeagueResultsTotalRows,successSerpLeague,errorSerpLeague,loadingSerpLeague,pageNoSerpLeague, setPageNoSerpLeague }) {
  const csvHeader = [
    { label: "Website", key: "domain" },
    { label: "SERP Real Estate", key: "sre" },
    { label: "Recent", key: "sre_delta" },
    { label: "Bidding on Ads?", key: "ads_bidding" },
    { label: "Paid Real Estate", key: "paid_sre" },
    { label: "# SERP Features", key: "serp_feature_count" },
    { label: "Avg. Organic Position", key: "avg_organic_position" },
    { label: "Position Volatility", key: "position_volatility" },
    { label: "# Queries", key: "keyword_count" }
   
  ];

  return (
    <Content.FlexColumn
      style={{
        justifyContent: "space-between",
        padding: "1%",
        background: "white",
      }}
    >
          <TitleContainer 
          title="Share of Search Leaderboard"
          subtitle='Know your enemy: the ultimate SERP league table.'
          backdropTop = '20vw'  
          pages={insightsText.SerpScoreboard[0]}
          p11={insightsText.SerpScoreboard[1]}
          p21={insightsText.SerpScoreboard[2]}
          p31={insightsText.SerpScoreboard[3]}
          p41={insightsText.SerpScoreboard[4]}
          assistantMessage={assistantText.SerpScoreboard}
          csvData={serpLeagueResults}
          csvHeader={csvHeader}

          />
          <Content.FlexColumn className={"justifyContentCenter"} >
          {
              loadingSerpLeague || !successSerpLeague 
              ? 
              <LoaderImage  height={'450px'}/> : 
              errorSerpLeague 
              ? 
              <ErrorMessage /> :
              serpLeagueResults.length != 0 ? 
                serpLeagueResults.detail == undefined ? 
                  <SerpScoreboardTable  results={serpLeagueResults} resultsTotalRows={serpLeagueResultsTotalRows} pageNoSerpLeague={pageNoSerpLeague} setPageNoSerpLeague={setPageNoSerpLeague}/> : <ResultsMissing details = {serpLeagueResults.detail}/>
              : 
              <ResultsMissing details = {serpLeagueResults.detail}/>
              
            }

          </Content.FlexColumn>
    </Content.FlexColumn>
  );
}
