import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  Text, 
  Content,
} from "../../../styled-components/visualization";
import { useHistory } from "react-router-dom"
import { FilterContext } from "../../../contexts/FilterContext";
import { GET_FILTERS_RESET,
  BIG_KEYWORD_RANKING_RESET, 
  COMPETITOR_CHANNEL_RESET, 
  COMPETITOR_LANDSCAPE_RESET, 
  COMPETITOR_SWOT_HEATMAP_RESET, 
  CONTENT_EXPLORER_RESET, 
  FEATURED_SNIPPED_OPPORTUNITIES_RESET, 
  HVN_SCORE_RESET, 
  MONTHLY_MOVEMENTS_RESET, 
  RETRIEVE_SWOT_RESET, 
  SERP_COMPOSITION_RESET,
  SERP_COMPOSITION_INCLUDE_RESET,
  SERP_LEAGUE_RESET,
} from "../../../constants/visualization/dataScienceAPIsConstants";
import {
  BackDrop,
  BackDropAbsolute,
} from "../../../styled-components/general/styles/general";




export const DropDownFilterContainer = ({title, type, filtersResults, setFiltersApplied,}) => {
  
  const dispatch = useDispatch();
  const {
    exclude_array_keyword_parent,
    exclude_array_keyword_child,
    exclude_array_keyword_portfolio,
    exclude_array_keyword_subportfolio,
    exclude_array_keyword,
    exclude_array_country,
    exclude_array_location,
    exclude_array_language,  
    exclude_array_device,
    exclude_array_domain,
    exclude_array_component_clean,
    exclude_array_brand,

    initial_array_keyword_parent,
    initial_array_keyword_child,
    initial_array_keyword_portfolio,
    initial_array_keyword_subportfolio,
    initial_array_keyword,
    initial_array_country,
    initial_array_location,
    initial_array_language,  
    initial_array_device,
    initial_array_domain,
    initial_array_component_clean,
    initial_array_brand,

    firstRender
  } = useContext(FilterContext)

  const [toggle, setToggle] = useState(true);
  const [searchParameter, setSearchParameter] = useState("");
  const [helper, setHelper] = useState(1)
  const [modalBackdrop, setModalBackdrop] = useState(false);

  const [changeInFilters, setChangeInFilters] = useState(false)

  let history = useHistory()

  const setLayerDropdownExpand = () => {
    window.dataLayer.push({
      event: "dropdown_expand",
      dropdown_name: title.toLowerCase(),
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
  }

  const setLayerDropdownSelected = () => {
    window.dataLayer.push({
      event: "filters_applied",
      dropdown_name: title.toLowerCase(),
      dropdown_selected: filtersResults,
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
    
  }

  const setLayerDropdownTickBox = (filter) => {
    window.dataLayer.push({
      event: "dropdown_tickbox",
      dropdown_name: title.toLowerCase(),
      dropdown_selected: filter.toLowerCase(),
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
  }

  const setLayerDropdownSelectAll = () => {
    window.dataLayer.push({
      event: "dropdown_selectall",
      dropdown_name: title.toLowerCase(),
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
  }

  const setLayerDropdownOnly = (filter) => {
    window.dataLayer.push({
      event: "dropdown_only",
      dropdown_name: title.toLowerCase(),
      dropdown_selected: filter.toLowerCase(),
      page_type: history.location.pathname.substring(history.location.pathname.indexOf('visualization')+14, 100),
      project_name: JSON.parse(localStorage.getItem('websiteName'))
    });
  }
  

  useEffect(() => {
    //filter by brand
    if(filtersResults[0]){
      filtersResults[0].focus__brand= ['1','0']
    }

  }, [helper,
    exclude_array_keyword_parent,
    exclude_array_keyword_child,
    exclude_array_keyword_portfolio,
    exclude_array_keyword_subportfolio,
    exclude_array_keyword,
    exclude_array_country,
    exclude_array_location,
    exclude_array_language,
    exclude_array_device,
    exclude_array_domain,
    exclude_array_component_clean,
    exclude_array_brand,
    initial_array_keyword_parent,
    initial_array_keyword_child,
    initial_array_keyword_portfolio,
    initial_array_keyword_subportfolio,
    initial_array_keyword,
    initial_array_country,
    initial_array_location,
    initial_array_language,
    initial_array_device,
    initial_array_domain,
    initial_array_component_clean,
    initial_array_brand,
  firstRender]);


  const search = (value) => {
    setSearchParameter(value.toLowerCase());
  };

  const handleTickBox = (filter, type) => {
    setLayerDropdownTickBox(filter)
    if(type === 'keyword_parent_'){
      if(exclude_array_keyword_parent.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_keyword_parent.indexOf(filter)
        if(index > -1){
          exclude_array_keyword_parent.splice(index, 1)
          filtersResults[0].focus__keyword_parent.push(filter)
        }
      } else 
      {
        if(filtersResults[0].focus__keyword_parent.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_keyword_parent.push(filter)
          const index = filtersResults[0].focus__keyword_parent.indexOf(filter)
          if(index > -1){
            filtersResults[0].focus__keyword_parent.splice(index, 1)
          }
        }
       
      }
    }
   
    if(type === 'keyword_child_'){
      if(exclude_array_keyword_child.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_keyword_child.indexOf(filter)
        if(index > -1){
          filtersResults[0].focus__keyword_child.push(filter)
          exclude_array_keyword_child.splice(index, 1)
        }
      } else {
        if(filtersResults[0].focus__keyword_child.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_keyword_child.push(filter)
          const index = filtersResults[0].focus__keyword_child.indexOf(filter)
          if(index > -1){
            filtersResults[0].focus__keyword_child.splice(index, 1)
          }
        }
      }
    }

    if(type === 'keyword_portfolio_'){
      if(exclude_array_keyword_portfolio.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_keyword_portfolio.indexOf(filter)
        if(index > -1){
          filtersResults[0].focus__keyword_portfolio.push(filter)
          exclude_array_keyword_portfolio.splice(index, 1)
        }
      } else {
        if(filtersResults[0].focus__keyword_portfolio.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_keyword_portfolio.push(filter)
          const index = filtersResults[0].focus__keyword_portfolio.indexOf(filter)
          if(index > -1){
            filtersResults[0].focus__keyword_portfolio.splice(index, 1)
          }
        }
      }
    }

    if(type === 'keyword_subportfolio_'){
      if(exclude_array_keyword_subportfolio.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_keyword_subportfolio.indexOf(filter)
        if(index > -1){
          filtersResults[0].focus__keyword_subportfolio.push(filter)
          exclude_array_keyword_subportfolio.splice(index, 1)
        }
      } else {
        if(filtersResults[0].focus__keyword_subportfolio.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_keyword_subportfolio.push(filter)
          const index = filtersResults[0].focus__keyword_subportfolio.indexOf(filter)
          if(index > -1){
            filtersResults[0].focus__keyword_subportfolio.splice(index, 1)
          }
        }
      }
    }

    if(type === 'keyword_'){
      if(exclude_array_keyword.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_keyword.indexOf(filter)
        if(index > -1){
          filtersResults[0].focus__keyword.push(filter)
          exclude_array_keyword.splice(index, 1)
        }
      } else {
        if(filtersResults[0].focus__keyword.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_keyword.push(filter)
          const index = filtersResults[0].focus__keyword.indexOf(filter)
          if(index > -1){
            filtersResults[0].focus__keyword.splice(index, 1)
          }
        }
      }
    }
    if(type === 'brand_'){
      if(exclude_array_brand.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_brand.indexOf(filter)
        if(index > -1){
          filtersResults[0].focus__brand.push(filter)
          exclude_array_brand.splice(index, 1)
        }
      } else {
        if(filtersResults[0].focus__brand.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_brand.push(filter)
          const index = filtersResults[0].focus__brand.indexOf(filter)
          if(index > -1){
            filtersResults[0].focus__brand.splice(index, 1)
          }
        }
      }
    }

    if(type === 'country_'){
      if(exclude_array_country.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_country.indexOf(filter)
        if(index > -1){
          filtersResults[0].localisation__country.push(filter)
          exclude_array_country.splice(index, 1)
        }
      } else {
        if(filtersResults[0].localisation__country.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_country.push(filter)
          const index = filtersResults[0].localisation__country.indexOf(filter)
          if(index > -1){
            filtersResults[0].localisation__country.splice(index, 1)
          }
        }
      }
    }

    if(type === 'google_domain_'){
      if(exclude_array_location.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_location.indexOf(filter)
        if(index > -1){
          exclude_array_location.splice(index, 1)
          filtersResults[0].localisation__google_domain.push(filter)
        }
      } else {
        if(filtersResults[0].localisation__google_domain.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_location.push(filter)
          const index = filtersResults[0].localisation__google_domain.indexOf(filter)
          if(index > -1){
            filtersResults[0].localisation__google_domain.splice(index, 1)
          }
        }
      }
    }

    if(type === 'language_'){
      if(exclude_array_language.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_language.indexOf(filter)
        if(index > -1){
          filtersResults[0].localisation__language.push(filter)
          exclude_array_language.splice(index, 1)
        }
      } else {
        if(filtersResults[0].localisation__language.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_language.push(filter)
          const index = filtersResults[0].localisation__language.indexOf(filter)
          if(index > -1){
            filtersResults[0].localisation__language.splice(index, 1)
          }
        }
      }
    }

    if(type === 'device_'){
      if(exclude_array_device.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_device.indexOf(filter)
        if(index > -1){
          filtersResults[0].localisation__device.push(filter)
          exclude_array_device.splice(index, 1)
        }
      } else {
        if(filtersResults[0].localisation__device.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_device.push(filter)
          const index = filtersResults[0].localisation__device.indexOf(filter)
          if(index > -1){
            filtersResults[0].localisation__device.splice(index, 1)
          }
        }
      }
    }

    if(type === 'domain_'){
      if(exclude_array_domain.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_domain.indexOf(filter)
        if(index > -1){
          filtersResults[0].serp__domain.push(filter)
          exclude_array_domain.splice(index, 1)
        }
      } else {
        if(filtersResults[0].serp__domain.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_domain.push(filter)
          const index = filtersResults[0].serp__domain.indexOf(filter)
          if(index > -1){
            filtersResults[0].serp__domain.splice(index, 1)
          }
        }
      }
    }

    if(type === 'component_clean_'){
      if(exclude_array_component_clean.indexOf(filter) > -1){
        setHelper(helper+1)
        setChangeInFilters(true)
        const index = exclude_array_component_clean.indexOf(filter)
        if(index > -1){
          filtersResults[0].serp__component_clean.push(filter)
          exclude_array_component_clean.splice(index, 1)
        }
      } else {
        if(filtersResults[0].serp__component_clean.length > 1 ){
          setHelper(helper+1)
          setChangeInFilters(true)
          exclude_array_component_clean.push(filter)
          const index = filtersResults[0].serp__component_clean.indexOf(filter)
          if(index > -1){
            filtersResults[0].serp__component_clean.splice(index, 1)
          }
        }
      }
    }

  }

  const handleOnly = (type, filter) => {
    setHelper(helper+1)
    setLayerDropdownOnly(filter)
    if(type==='keyword_parent_'){
      exclude_array_keyword_parent.splice(0,exclude_array_keyword_parent.length)
      initial_array_keyword_parent.map((f, i) =>(
        exclude_array_keyword_parent.push(f)
      ))
      const index = exclude_array_keyword_parent.indexOf(filter)
      exclude_array_keyword_parent.splice(index, 1)

      filtersResults[0].focus__keyword_parent.splice(0,filtersResults[0].focus__keyword_parent.length)
      filtersResults[0].focus__keyword_parent.push(filter)
      setChangeInFilters(true)
    }
    if(type==='keyword_child_'){
      exclude_array_keyword_child.splice(0,exclude_array_keyword_child.length)
      initial_array_keyword_child.map((f, i) =>(
        exclude_array_keyword_child.push(f)
      ))
      const index = exclude_array_keyword_child.indexOf(filter)
      exclude_array_keyword_child.splice(index, 1)

      filtersResults[0].focus__keyword_child.splice(0,filtersResults[0].focus__keyword_child.length)
      filtersResults[0].focus__keyword_child.push(filter)
      setChangeInFilters(true)
    }
    if(type==='keyword_portfolio_'){
      exclude_array_keyword_portfolio.splice(0,exclude_array_keyword_portfolio.length)
      initial_array_keyword_portfolio.map((f, i) =>(
        exclude_array_keyword_portfolio.push(f)
      ))
      const index = exclude_array_keyword_portfolio.indexOf(filter)
      exclude_array_keyword_portfolio.splice(index, 1)

      filtersResults[0].focus__keyword_portfolio.splice(0,filtersResults[0].focus__keyword_portfolio.length)
      filtersResults[0].focus__keyword_portfolio.push(filter)
      setChangeInFilters(true)
    }
    if(type==='keyword_subportfolio_'){
      exclude_array_keyword_subportfolio.splice(0,exclude_array_keyword_subportfolio.length)
      initial_array_keyword_subportfolio.map((f, i) =>(
        exclude_array_keyword_subportfolio.push(f)
      ))
      const index = exclude_array_keyword_subportfolio.indexOf(filter)
      exclude_array_keyword_subportfolio.splice(index, 1)

      filtersResults[0].focus__keyword_subportfolio.splice(0,filtersResults[0].focus__keyword_subportfolio.length)
      filtersResults[0].focus__keyword_subportfolio.push(filter)
      setChangeInFilters(true)
    }
    if(type==='keyword_'){
      exclude_array_keyword.splice(0,exclude_array_keyword.length)
      initial_array_keyword.map((f, i) =>(
        exclude_array_keyword.push(f)
      ))
      const index = exclude_array_keyword.indexOf(filter)
      exclude_array_keyword.splice(index, 1)

      filtersResults[0].focus__keyword.splice(0,filtersResults[0].focus__keyword.length)
      filtersResults[0].focus__keyword.push(filter)
      setChangeInFilters(true)
    }
    if(type==='brand_'){
      exclude_array_brand.splice(0,exclude_array_brand.length)
      initial_array_brand.map((f, i) =>(
        exclude_array_brand.push(f)
      ))
      const index = exclude_array_brand.indexOf(filter)
      exclude_array_brand.splice(index, 1)

      filtersResults[0].focus__brand.splice(0,filtersResults[0].focus__brand.length)
      filtersResults[0].focus__brand.push(filter)
      setChangeInFilters(true)
    }
    if(type==='country_'){
      exclude_array_country.splice(0,exclude_array_country.length)
      initial_array_country.map((f, i) =>(
        exclude_array_country.push(f)
      ))
      const index = exclude_array_country.indexOf(filter)
      exclude_array_country.splice(index, 1)

      filtersResults[0].localisation__country.splice(0,filtersResults[0].localisation__country.length)
      filtersResults[0].localisation__country.push(filter)
      setChangeInFilters(true)
    }
    
    if(type==='google_domain_'){
      exclude_array_location.splice(0,exclude_array_location.length)
      initial_array_location.map((f, i) =>(
        exclude_array_location.push(f)
      ))
      const index = exclude_array_location.indexOf(filter)
      exclude_array_location.splice(index, 1)

      filtersResults[0].localisation__google_domain.splice(0,filtersResults[0].localisation__google_domain.length)
      filtersResults[0].localisation__google_domain.push(filter)
      setChangeInFilters(true)
    }
    if(type==='language_'){
      exclude_array_language.splice(0,exclude_array_language.length)
      initial_array_language.map((f, i) =>(
        exclude_array_language.push(f)
      ))
      const index = exclude_array_language.indexOf(filter)
      exclude_array_language.splice(index, 1)

      filtersResults[0].localisation__language.splice(0,filtersResults[0].localisation__language.length)
      filtersResults[0].localisation__language.push(filter)
      setChangeInFilters(true)
    }


    if(type==='device_'){
      exclude_array_device.splice(0,exclude_array_device.length)
      initial_array_device.map((f, i) =>(
        exclude_array_device.push(f)
      ))
      const index = exclude_array_device.indexOf(filter)
      exclude_array_device.splice(index, 1)

      filtersResults[0].localisation__device.splice(0,filtersResults[0].localisation__device.length)
      filtersResults[0].localisation__device.push(filter)
      setChangeInFilters(true)
    }

    if(type==='domain_'){
      exclude_array_domain.splice(0,exclude_array_domain.length)
      initial_array_domain.map((f, i) =>(
        exclude_array_domain.push(f)
      ))
      const index = exclude_array_domain.indexOf(filter)
      exclude_array_domain.splice(index, 1)

      filtersResults[0].serp__domain.splice(0,filtersResults[0].serp__domain.length)
      filtersResults[0].serp__domain.push(filter)
      setChangeInFilters(true)
    }

    if(type==='component_clean_'){
      exclude_array_component_clean.splice(0,exclude_array_component_clean.length)
      initial_array_component_clean.map((f, i) =>(
        exclude_array_component_clean.push(f)
      ))
      const index = exclude_array_component_clean.indexOf(filter)
      exclude_array_component_clean.splice(index, 1)

      filtersResults[0].serp__component_clean.splice(0,filtersResults[0].serp__component_clean.length)
      filtersResults[0].serp__component_clean.push(filter)
      setChangeInFilters(true)
    }
    
  }

 const handleApply = () => {
  if(changeInFilters) {
    dispatch({type: GET_FILTERS_RESET})

    dispatch({type: BIG_KEYWORD_RANKING_RESET})
    dispatch({type: SERP_COMPOSITION_RESET})
    dispatch({type: SERP_COMPOSITION_INCLUDE_RESET})
    dispatch({type: CONTENT_EXPLORER_RESET})
    dispatch({type: RETRIEVE_SWOT_RESET})
    dispatch({type: RETRIEVE_SWOT_RESET})
    dispatch({type: FEATURED_SNIPPED_OPPORTUNITIES_RESET})

    //COMPETITION
    dispatch({type: COMPETITOR_LANDSCAPE_RESET})
    dispatch({type: COMPETITOR_SWOT_HEATMAP_RESET})
    dispatch({type: SERP_LEAGUE_RESET})
    dispatch({type: MONTHLY_MOVEMENTS_RESET})
    dispatch({type: HVN_SCORE_RESET})
    dispatch({type: COMPETITOR_CHANNEL_RESET})

    setLayerDropdownSelected()
  }


    
    setChangeInFilters(false)
    setToggle(!toggle)
    setModalBackdrop(false)
    setHelper(helper+1) 
    setFiltersApplied(false)
  }

  const handleTickBoxSelectAll = (type) => {
    setLayerDropdownSelectAll()
    setHelper(helper+1)
    setChangeInFilters(true)
    if( type === 'keyword_parent_' ){
      if(exclude_array_keyword_parent[0]){
        //empty array
        

        exclude_array_keyword_parent.map(((filter, i) =>{
          filtersResults[0].focus__keyword_parent.push(filter)
        }))
        exclude_array_keyword_parent.splice(0,exclude_array_keyword_parent.length)

      } 
    }
    if( type === 'keyword_child_' ){
      if(exclude_array_keyword_child[0]){
        //empty array
      

        exclude_array_keyword_child.map(((filter, i) =>{
          filtersResults[0].focus__keyword_child.push(filter)
        }))
        exclude_array_keyword_child.splice(0,exclude_array_keyword_child.length)
      } 
    }
    if( type === 'keyword_portfolio_' ){
      if(exclude_array_keyword_portfolio[0]){
        //empty array
       
        exclude_array_keyword_portfolio.map(((filter, i) =>{
          filtersResults[0].focus__keyword_portfolio.push(filter)
        }))
        exclude_array_keyword_portfolio.splice(0,exclude_array_keyword_portfolio.length)
      } 
    }
    if( type === 'keyword_subportfolio_' ){
      if(exclude_array_keyword_subportfolio[0]){
        //empty array
       
        exclude_array_keyword_subportfolio.map(((filter, i) =>{
          filtersResults[0].focus__keyword_subportfolio.push(filter)
        }))
        exclude_array_keyword_subportfolio.splice(0,exclude_array_keyword_subportfolio.length)

      } 
    }
    if( type === 'keyword_' ){
      if(exclude_array_keyword[0]){
        //empty array
       
        exclude_array_keyword.map(((filter, i) =>{
          filtersResults[0].focus__keyword.push(filter)
        }))
        exclude_array_keyword.splice(0,exclude_array_keyword.length)
      } 
    }
    if( type === 'brand_' ){
      if(exclude_array_brand[0]){
        //empty array
       
        exclude_array_brand.map(((filter, i) =>{
          filtersResults[0].focus__brand.push(filter)
        }))
        exclude_array_brand.splice(0,exclude_array_brand.length)
      } 
    }
    if( type === 'country_' ){
      if(exclude_array_country[0]){
        //empty array
       
        exclude_array_country.map(((filter, i) =>{
          filtersResults[0].localisation__country.push(filter)
        }))
        exclude_array_country.splice(0,exclude_array_country.length)
      } 
    }
    if( type === 'google_domain_' ){
      if(exclude_array_location[0]){
        //empty array
       
        exclude_array_location.map(((filter, i) =>{
          filtersResults[0].localisation__google_domain.push(filter)
        }))
        exclude_array_location.splice(0,exclude_array_location.length)
      } 
    }
    if( type === 'language_' ){
      if(exclude_array_language[0]){
        //empty array
        
        exclude_array_language.map(((filter, i) =>{
          filtersResults[0].localisation__language.push(filter)
        }))
        exclude_array_language.splice(0,exclude_array_language.length)
      } 
    }
    if( type === 'device_' ){
      if(exclude_array_device[0]){
        //empty array
       
        exclude_array_device.map(((filter, i) =>{
          filtersResults[0].localisation__device.push(filter)
        }))
        exclude_array_device.splice(0,exclude_array_device.length)
      } 
    }
    if( type === 'domain_' ){
      if(exclude_array_domain[0]){
        //empty array
       
        exclude_array_domain.map(((filter, i) =>{
          filtersResults[0].serp__domain.push(filter)
        }))
        exclude_array_domain.splice(0,exclude_array_domain.length)
      } 
    }
    if( type === 'component_clean_' ){
      if(exclude_array_component_clean[0]){
        //empty array
        
        exclude_array_component_clean.map(((filter, i) =>{
          filtersResults[0].serp__component_clean.push(filter)
        }))
        exclude_array_component_clean.splice(0,exclude_array_component_clean.length)
      } 
    }
  }

  const dropdownItem = (filter, notTicked=false) => {
    return(
        <>
              {searchParameter !== "" ? (
                filter.toLowerCase().startsWith(searchParameter) && (
                  <Content.DropDownItemContainer>
                  <Text.Regular16Black>{filter}</Text.Regular16Black>
                <Content.FlexRow
                  style={{
                    marginRight:'3px'
                  }}
                >
                <Content.OnlyContainer>
                    <p
                      style={{
                        fontSize: '10px',
                        margin: '0'
                      }}
                      onClick={()=>{handleOnly(type, filter)}}
                    >ONLY</p>
                  </Content.OnlyContainer>
                  <Content.TickBox
                  onClick={()=>handleTickBox(filter, type)}
                  style={{
                    
                  }}
                  >
                  {notTicked &&
                    <Content.Icon
                      style={{
                        width: "10px",
                        height: "10px",
                        margin: "0",
                      }}
                      src={
                        require("../../../images/visualization/Tick_2.svg")
                          .default
                      }
                    /> }
                  </Content.TickBox>
                  </Content.FlexRow>
                    </Content.DropDownItemContainer>
                )
              ) : (

              
                <Content.DropDownItemContainer>  

                {type == "domain_" ?
                <Text.Light14BlackLink href={'//'+filter} title={filter} target="_blank" style={{width: '100%', overflow: 'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}> {filter} </Text.Light14BlackLink>
                :
                type == "brand_" ? 
                <Text.Regular16Black>{filter=='1' ? 'Branded':'Unbranded'}</Text.Regular16Black> 
                :
                <Text.Regular16Black>{filter}</Text.Regular16Black>}

              

                <Content.FlexRow
                  style={{
                    marginRight:'3px'
                  }}
                >
                <Content.OnlyContainer>
                    <p
                      style={{
                        fontSize: '10px',
                        margin: '0'
                      }}
                      onClick={()=>{handleOnly(type, filter)}}
                    >ONLY</p>
                  </Content.OnlyContainer>
                  <Content.TickBox
                  onClick={()=>handleTickBox(filter, type)}
                  style={{
                    
                  }}
                  >
                  {notTicked &&
                    <Content.Icon
                      style={{
                        width: "10px",
                        height: "10px",
                        margin: "0",
                      }}
                      src={
                        require("../../../images/visualization/Tick_2.svg")
                          .default
                      }
                    /> }
                  </Content.TickBox>
                  </Content.FlexRow>
                  </Content.DropDownItemContainer>
              )}
            </>
    )
  }

  

  const dropdown = () => {
    return (
      <Content.FilterContainer >
        <div >
          <Content.FlexRow
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
          <Content.Icon
              style={{
                width: "15px",
                height: "15px",
                margin: '0',
                marginRight: '10px'
              }}
              src={require("../../../images/visualization/Search.svg").default}
            />

            <Text.InputRegular16Black
              placeholder="Type to search"
              value={searchParameter}
              onChange={(e) => search(e.target.value)}
            />
            
            
          </Content.FlexRow>
        </div>

        <Content.DropDownContainer>
          {/* FOCUS */}
          {type === 'keyword_parent_' && 
          <>
          {searchParameter === '' && 
          <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px',
                alignItems: 'center'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_keyword_parent[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
        }
          {initial_array_keyword_parent.map((filter,i)=>
            !(exclude_array_keyword_parent.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].focus__keyword_parent.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
          </>
          }
          {type === 'keyword_child_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_keyword_child[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }
          {initial_array_keyword_child.map((filter,i)=>
            !(exclude_array_keyword_child.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : filtersResults[0].focus__keyword_child.indexOf(filter) > -1 &&
            dropdownItem(filter, true)
          )}
            </>
          }
          {type === 'keyword_portfolio_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_keyword_portfolio[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }
          {initial_array_keyword_portfolio.map((filter,i)=>
            !(exclude_array_keyword_portfolio.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].focus__keyword_portfolio.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
            </>
          }

          
          {type === 'keyword_subportfolio_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_keyword_subportfolio[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }
          {initial_array_keyword_subportfolio.map((filter,i)=>
            !(exclude_array_keyword_subportfolio.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].focus__keyword_subportfolio.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
            </>
          }


          {type === 'keyword_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_keyword[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }
          {initial_array_keyword.map((filter,i)=>
            !(exclude_array_keyword.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].focus__keyword.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
            </>
          }

          {type === 'brand_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_brand[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }

          {initial_array_brand.map((filter,i)=>
            !(exclude_array_brand.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].focus__brand.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
            </>
          } 

          {/* Localisation */}
          {type === 'country_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_country[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }
          {initial_array_country.map((filter,i)=>
            !(exclude_array_country.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].localisation__country.indexOf(filter) == -1) &&
            dropdownItem(filter, true) 
          )}
            </>
          }
          {type === 'google_domain_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_location[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }
          {initial_array_location.map((filter,i)=>
            !(exclude_array_location.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].localisation__google_domain.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
            </>
          }
          {type === 'language_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_language[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }
          {initial_array_language.map((filter,i)=>
            !(exclude_array_language.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].localisation__language.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
            </>
          }
          {type === 'device_' && 
          <>
          {searchParameter === '' && 
          <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_device[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
          }
          {initial_array_device.map((filter,i)=>
            !(exclude_array_device.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].localisation__device.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
          </>
          }

          {/* SERP */}
          {type === 'domain_' && 
            <>
            {searchParameter === '' && 
            <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_domain[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
            }
          {initial_array_domain.map((filter,i)=>
            !(exclude_array_domain.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].serp__domain.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
            </>
          }
          {type === 'component_clean_' && 
          <>
          {searchParameter === '' && 
          <Content.FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft:'2px'
              }}
          >
            <Text.Regular16Purple>Select All</Text.Regular16Purple> 

            <Content.TickBox
              onClick={()=>handleTickBoxSelectAll(type)}
             
                  >
             {!exclude_array_component_clean[0] && <Content.Icon
                        style={{
                          width: "10px",
                          height: "10px",
                          margin: "0",
                        }}
                        src={
                          require("../../../images/visualization/Tick_2.svg")
                            .default
                        }
                      />}
              </Content.TickBox>
          </Content.FlexRow>
          }
          {initial_array_component_clean.map((filter,i)=>
            !(exclude_array_component_clean.indexOf(filter) == -1) ?
            dropdownItem(filter, false) : !(filtersResults[0].serp__component_clean.indexOf(filter) == -1) &&
            dropdownItem(filter, true)
          )}
          </>
          }
        </Content.DropDownContainer>
      
      </Content.FilterContainer>
    );
  };

  const openDropDown = () => {
    if(toggle){
      setLayerDropdownExpand()
      setToggle(!toggle)
      setModalBackdrop(true);
    }
  }

  return (
    <>
    {modalBackdrop ? (
              <BackDropAbsolute
                onClick={() => handleApply()}
              />
            ) : (
              <BackDrop />
            )}
    <Content.FlexRow
      style={{
              background: "white",
              padding: "10px",
              marginTop: "1.5%",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: toggle && 'pointer',
              position: 'relative',
            }}
            onClick={() => openDropDown()}
    >
    
        <Text.Regular16Black
            style={{
              fontWeight: !toggle && 'bold',
            }}
        >{title}</Text.Regular16Black>
        
      
      {toggle ? (
        <Content.Icon
          style={{
            width: "15px",
            height: "15px",
          }}
          onClick={() => {setToggle(!toggle)
                        
          }}
          src={require("../../../images/visualization/Arrow.svg").default}
        />
      ) : (
        <>
          <Content.Icon
            style={{
              width: "15px",
              height: "15px",
              transform: "rotate(180deg)",
            }}
            onClick={() => {setToggle(!toggle)
              setModalBackdrop(true);
            }}
            src={require("../../../images/visualization/Arrow.svg").default}
            
          />
          <Content.FlexColumn
      style={{
        position: 'absolute',
        zIndex: "99",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        top: '56px',
        left: '0'

      }}
    >
      {dropdown()}
    </Content.FlexColumn>
         
        </>
      )}
    </Content.FlexRow>
    {/* {!toggle &&
    } */}
    </>
  );
};
