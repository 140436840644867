import { 
    ORGANISATION_LIST_REQUEST,
    ORGANISATION_LIST_SUCCESS,
    ORGANISATION_LIST_FAIL,

    ORGANISATION_DETAILS_REQUEST,
    ORGANISATION_DETAILS_SUCCESS,
    ORGANISATION_DETAILS_FAIL,
 
    ORGANISATION_DETAILS_BY_ID_REQUEST,
    ORGANISATION_DETAILS_BY_ID_SUCCESS,
    ORGANISATION_DETAILS_BY_ID_FAIL,

    ORGANISATION_CREATE_REQUEST,
    ORGANISATION_CREATE_SUCCESS,
    ORGANISATION_CREATE_FAIL,
    ORGANISATION_CREATE_RESET,

    ORGANISATION_UPDATE_REQUEST,
    ORGANISATION_UPDATE_SUCCESS,
    ORGANISATION_UPDATE_FAIL,
    ORGANISATION_UPDATE_RESET,
 } from '../constants/organisationConstants'

 export const organisationListReducer =  (state = {organisations:[]}, action) => {
    switch(action.type) {
        case ORGANISATION_LIST_REQUEST:
            return {loading: true, requests:[]}

        case ORGANISATION_LIST_SUCCESS:
            return {loading: false, organisations: action.payload}

        case ORGANISATION_LIST_FAIL:
            return {loading: false, error: action.payload }

        default:
            return state
    }
}

 export const organisationCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case ORGANISATION_CREATE_REQUEST: 
            return {loading:true}

        case ORGANISATION_CREATE_SUCCESS: 
            return {loading:false, success: true, organisation: action.payload}
        
        case ORGANISATION_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case ORGANISATION_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const organisationUpdateReducer = ( state = { organisation: {} }, action ) => {
    switch(action.type){
        case ORGANISATION_UPDATE_REQUEST: 
            return {loading:true}

        case ORGANISATION_UPDATE_SUCCESS: 
            return {loading:false, success: true, organisation: action.payload}
        
        case ORGANISATION_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case ORGANISATION_UPDATE_RESET: 
            return { organisation: {} }

        default: 
            return state
    }
}


export const organisationDetailsReducer = ( state = {organisation :{}}, action ) => {
    switch(action.type){
        case ORGANISATION_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case ORGANISATION_DETAILS_SUCCESS: 
            return {loading:false, success:true,  organisation: action.payload}
        
        case ORGANISATION_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        default: 
            return state
    }
}

export const organisationDetailsByIdReducer = ( state = {organisation :{}}, action ) => {
    switch(action.type){
        case ORGANISATION_DETAILS_BY_ID_REQUEST: 
            return {loading:true, ...state}

        case ORGANISATION_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  organisation: action.payload}
        
        case ORGANISATION_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        default: 
            return state
    }
}

