import { 
    WEBSITE_LIST_REQUEST,
    WEBSITE_LIST_SUCCESS,
    WEBSITE_LIST_FAIL,
    WEBSITE_LIST_RESET,

    WEBSITE_DETAILS_REQUEST,
    WEBSITE_DETAILS_SUCCESS,
    WEBSITE_DETAILS_FAIL,
    WEBSITE_DETAILS_RESET,

    WEBSITE_DETAILS_BY_ID_REQUEST,
    WEBSITE_DETAILS_BY_ID_SUCCESS,
    WEBSITE_DETAILS_BY_ID_FAIL,
    WEBSITE_DETAILS_BY_ID_RESET,

    WEBSITE_DETAILS_BY_EMAIL_REQUEST,
    WEBSITE_DETAILS_BY_EMAIL_SUCCESS,
    WEBSITE_DETAILS_BY_EMAIL_FAIL,
    WEBSITE_DETAILS_BY_EMAIL_RESET,

    WEBSITE_CREATE_REQUEST,
    WEBSITE_CREATE_SUCCESS,
    WEBSITE_CREATE_FAIL,
    WEBSITE_CREATE_RESET,

    WEBSITE_UPDATE_REQUEST,
    WEBSITE_UPDATE_SUCCESS,
    WEBSITE_UPDATE_FAIL,
    WEBSITE_UPDATE_RESET,

    WEBSITE_DELETE_REQUEST,
    WEBSITE_DELETE_SUCCESS,
    WEBSITE_DELETE_FAIL,
    WEBSITE_DELETE_RESET,
 } from '../constants/websiteConstants'

 export const websiteListReducer =  (state = {websites:[]}, action) => {
    switch(action.type) {
        case WEBSITE_LIST_REQUEST:
            return {loading: true, requests:[]}

        case WEBSITE_LIST_SUCCESS:
            return {loading: false, success: true, websites: action.payload}

        case WEBSITE_LIST_FAIL:
            return {loading: false, error: action.payload }

        case WEBSITE_LIST_RESET: 
            return {}

        default:
            return state
    }
}

 export const websiteCreateReducer = ( state = {}, action ) => {
    switch(action.type){
        case WEBSITE_CREATE_REQUEST: 
            return {loading:true}

        case WEBSITE_CREATE_SUCCESS: 
            return {loading:false, success: true, website: action.payload}
        
        case WEBSITE_CREATE_FAIL: 
            return {loading:false, error: action.payload}

        case WEBSITE_CREATE_RESET: 
            return {}

        default: 
            return state
    }
}


export const websiteUpdateReducer = ( state = { website: {} }, action ) => {
    switch(action.type){
        case WEBSITE_UPDATE_REQUEST: 
            return {loading:true}

        case WEBSITE_UPDATE_SUCCESS: 
            return {loading:false, success: true, website: action.payload}
        
        case WEBSITE_UPDATE_FAIL: 
            return {loading:false, error: action.payload}

        case WEBSITE_UPDATE_RESET: 
            return { product: {} }

        default: 
            return state
    }
}


export const websiteDetailsReducer = ( state = {website :[]}, action ) => {
    switch(action.type){
        case WEBSITE_DETAILS_REQUEST: 
            return {loading:true, ...state}

        case WEBSITE_DETAILS_SUCCESS: 
            return {loading:false, success:true,  website: action.payload}
        
        case WEBSITE_DETAILS_FAIL: 
            return {loading:false, error: action.payload}

        case WEBSITE_DETAILS_RESET: 
            return {loading:false, success:false, website: {}}

        default: 
            return state
    }
}

export const websiteDetailsByIdReducer = ( state = {website :{}}, action ) => {
    switch(action.type){
        case WEBSITE_DETAILS_BY_ID_REQUEST: 
            return {loading:true, ...state}

        case WEBSITE_DETAILS_BY_ID_SUCCESS: 
            return {loading:false, success:true,  website: action.payload}
        
        case WEBSITE_DETAILS_BY_ID_FAIL: 
            return {loading:false, error: action.payload}

        case WEBSITE_DETAILS_BY_ID_RESET: 
            return {loading:false, success:false, website: {}}

        default: 
            return state
    }
}

export const websiteDetailsByEmailReducer = ( state = {website :{}}, action ) => {
    switch(action.type){
        case WEBSITE_DETAILS_BY_EMAIL_REQUEST: 
            return {loading:true, ...state}

        case WEBSITE_DETAILS_BY_EMAIL_SUCCESS: 
            return {loading:false, success:true,  website: action.payload}
        
        case WEBSITE_DETAILS_BY_EMAIL_FAIL: 
            return {loading:false, error: action.payload}

        case WEBSITE_DETAILS_BY_EMAIL_RESET: 
            return {loading:false, success:false, website: {}}

        default: 
            return state
    }
}


export const websiteDeleteReducer = ( state = {website :{}}, action ) => {
    switch(action.type){
        case WEBSITE_DELETE_REQUEST: 
            return {loading:true, ...state}

        case WEBSITE_DELETE_SUCCESS: 
            return {loading:false, success:true,  website: action.payload}
        
        case WEBSITE_DELETE_FAIL: 
            return {loading:false, error: action.payload}

        case WEBSITE_DELETE_RESET: 
            return {loading:false, success:false, website: {}}

        default: 
            return state
    }
}