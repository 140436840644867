import React, {useEffect} from 'react'
import { MenuHeaderContainer } from '../../containers/visualization/MenuHeader'
import { BenchmarksContainer } from '../../containers/visualization/Benchmarks'

import { SectionsFooterContainer } from '../../containers/visualization/SectionsFooter';
import { FooterContainer } from '../../containers/visualization/Footer';
import { useHistory } from 'react-router-dom'



export default function Benchmarks({accessToken, name, email, isActive, isSuperuser, websiteId}) {
    let history = useHistory()
  
    useEffect(() => {
      if (accessToken ) {
          //if account is active
         if(isActive === undefined){

         }else{
            if(isActive ){
              }
              else {
                  history.push(`/account-not-active`)
              }
         }
          
      }
  }, [
      accessToken,
      email,
      isActive,
  ])
    return (
    <>
      {isActive &&
    <div
        style={{
            minHeight: '100vh' 
        }}
    >
        <MenuHeaderContainer pageName={'Benchmarks'}  email={email} name={name} accessToken={accessToken} websiteId={websiteId}/>

        <BenchmarksContainer email={email} name={name} accessToken={accessToken} websiteId={websiteId}/>

        <SectionsFooterContainer pageName={'Benchmarks'}  email={email} name={name} accessToken={accessToken}/>
        <FooterContainer />
        {/* <NoWorkingPage /> */}
    </div>}
    </> 
    )
}

