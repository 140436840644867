import React, { useState, useEffect } from 'react'
import { Signuptest } from '../../styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createProfile, listProfileDetails,  updateProfile } from '../../actions/profileActions'
import '../../index.css' 
import LoaderImage from '../../components/LoaderImage'
import {
    createOrganisation,
} from '../../actions/organisationActions'
import { Button } from '../../styled-components/dashboard/'

export function CreateProfileContainer({ children, accessToken }) {
    let history = useHistory()
    const dispatch = useDispatch()
    const { user, logout } = useAuth0()

    // user details
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [jobTitle, setJobTitle] = useState('')
     const [isActive, setIsActive] = useState('false')


    const profileCreate = useSelector((state) => state.profileCreate)
    const {
         loading: loadingCreate,
        // error: errorCreate,
        success: successCreate,
        profile: createdProfile,
    } = profileCreate

    const organisationCreate = useSelector((state) => state.organisationCreate)
    const {
        loading: loadingCreateOrganisation,
      //  error: errorCreateOrganisation,
        success: successCreateOrganisation,
         organisation: createdOrganisation
    } = organisationCreate

    const profileDetails = useSelector((state) => state.profileDetails)
    const { success, loading, profile } = profileDetails

    const profileUpdate = useSelector((state) => state.profileUpdate);
    const { 
      loading: loadingUpdateProfile,
      error: errorUpdateProfile,
      success: successUpdateProfile,
    } = profileUpdate;

    useEffect(() => {

            if (accessToken) {
                if(successCreateOrganisation){
                    history.push(`/profile-goals`)
                }
                if(successCreate && !successCreateOrganisation){
                    const organisation = {
                        name: email,
                        industry: 'industry',
                        email,
                    }
                    dispatch(createOrganisation(accessToken, organisation))
                }
                if (success) {
                    if (profile.detail) {
                        setEmail(user.email)
                        
                    } else {
                        if(profile.name){
                            history.push(`/profile-goals`)
                        }
                        // setName(profile.name)
                        // setJobTitle(profile.jobTitle) 
                    }
                    
                } else {
                    dispatch(listProfileDetails(accessToken, user.email))
                }
            }
        
    }, [
        user,
        accessToken,
        success,
        createdProfile,
        successCreate,
        dispatch,
        profile,
        history,
        successCreateOrganisation
    ])

    //User functions
    const createProfileHandler = async (e) => {
        e.preventDefault()
        // user invite
        if(profile.userEmail){
            const data = {
                name: name,
                email: profile.userEmail,
                jobTitle: jobTitle
              };
              dispatch(updateProfile(accessToken, data));
        } else {
            // create profile + organisation
            const data = {
                name: name,
                email: email,
                userEmail: email,
                jobTitle: jobTitle,
                handler: 'normal'
            }
            dispatch(createProfile(accessToken, data))
    
            
        }
        

    }

    const handleName = (e) => {
        setName(e.target.value)
        setIsActive(!isActive)
    }
    return (
        <Signuptest>
            {loading || loadingUpdateProfile || loadingCreate | loadingCreateOrganisation ? <LoaderImage height={'100vh'}/> : 
            <>
                <Signuptest.HeaderFrame>
                    <div>
                        <Signuptest.Logo
                            href="https://luminr.ai/"
                            alt="Logo"
                            src={require("../../images/luminr-logo.svg").default}
                            
                        />
                    </div>
                    <div 
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        zIndex: '9999',
                        paddingRight: '135px'
                      }}
                    >
                        {/* <Signuptest.HeaderText id="login-btn-header" style={{paddingRight: '10px'}}>Log In</Signuptest.HeaderText>
                        <label 
                          style={{
                            position: 'relative',
                            display: 'inline-block',
                            width: '40px',
                            height: '18px',
                            verticalAlign: 'middle',
                            margin: '0'
                          }}
                        >
                            <input type="checkbox"/>
                            <Signuptest.Slider></Signuptest.Slider>
                        </label>
                        <Signuptest.HeaderText id="create-btn-header">Create an account</Signuptest.HeaderText> */}
                        <Signuptest.HeaderText style={{paddingLeft: '20px'}}>
                            <a href="https://luminr.ai/">
                                Back to Luminr.ai
                            </a>
                        </Signuptest.HeaderText>
                        <Button.Menu onClick={() => logout()} style={{
                            position: 'absolute',
                            right: '20px',
                            width: '120px',
                            zIndex: '99',
                            right: '10%',
                            marginTop: '0'
                        }}>
                            <Button.MenuBackground>
                                <Button.MenuImg
                                    src={
                                        require('../../images/dashboard/left-arrow-menu-button.svg')
                                            .default
                                    }
                                />
                            </Button.MenuBackground>
                            Logout
                        </Button.Menu>
                    </div>
                </Signuptest.HeaderFrame>
                <Signuptest.Frame>
                    {/* <Signuptest.Logo
                    //  to={ROUTES.HOME}
                        alt="Logo"
                        src={require("../../images/luminr-logo.svg").default}
                        
                    />
                    <div onClick={()=>logout()}>
                    <Signuptest.IconLink
                    // to={ROUTES.HOME}
                        alt="Help"
                        src={require("../../images/logout.svg").default}
                        
                    />
                    </div> */}
                    <Signuptest.Title>
                        Tell us about yourself
                    </Signuptest.Title>
                    <Signuptest.Text>
                        Complete some personal details to get started.
                    </Signuptest.Text>

                    <form style={{ width: 'calc(15rem + 15vw)' }} onSubmit={createProfileHandler}>
                        <Signuptest.InputGroup>
                            {/* <Signuptest.LineUp id='lineUp' isActive={isActive} /> */}
                            <Signuptest.Label placeholder="Your full name">Your full name</Signuptest.Label>
                            <Signuptest.Input
                                placeholder="Full Name"
                                required
                                type="text"
                                title=""
                                value={name}
                                // onChange={(e) => setName(e.target.value)}
                                onChange={(e) => handleName(e)}
                                //onFocus={(e)=> setColor('#C51852')}
                                // onBlur={(e)=> setColor('#D4D4D4')}
                                // onblur={blurLine}
                            />
                        </Signuptest.InputGroup>

                        <Signuptest.InputGroup>
                            {/* <Signuptest.LineUp /> */}
                            <Signuptest.Label placeholder="Your job title">Your job title</Signuptest.Label>
                            <Signuptest.Input
                                placeholder="Job Title"
                                required
                                type="text"
                                title=""
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                            />
                        </Signuptest.InputGroup>

                        <Signuptest.ButtonGroup>
                            <Signuptest.ButtonNext type="submit">
                                Continue
                            </Signuptest.ButtonNext>
                        </Signuptest.ButtonGroup>
                    </form>
                </Signuptest.Frame>
                <Signuptest.FooterFrame>
                    © 2023 <a href="https://luminr.ai/">LUMINR</a>. | LUMINR Is Part Of <a href="https://tomorrowgroup.co/">Tomorrow Group Limited</a> | <a href="https://luminr.ai/cookie-policy/">Cookie Policy</a> | <a href="https://luminr.ai/privacy-policy/">Privacy Policy</a>
                </Signuptest.FooterFrame>
            </>
        }
            {children}
        </Signuptest>
    )
}
