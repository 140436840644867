import React from "react"
import { Container, Item, SerprLogoImg, BetaLogoImg, BetaBorder, VerticalLine,
    CompanyLogoImg, LogoContainer, MenuContainer, SettingsContainer, ItemImg,
    ProfileImg, SettingsImg, Link, SubHeader, SubHeaderContainer, MenuItemContainer
} from "./styles/menuHeader"

export default function MenuHeader({children, ...restProps}) {
    return (
        <Container {...restProps}>{children}</Container>
    )
}

MenuHeader.LogoContainer = function MenuHeaderLogoContainer({children, ...restProps}){
    return <LogoContainer {...restProps}>{children}</LogoContainer>   
}

MenuHeader.MenuContainer = function MenuHeaderMenuContainer({children, ...restProps}){
    return <MenuContainer {...restProps}>{children}</MenuContainer>   
}

MenuHeader.MenuItemContainer = function MenuHeaderMenuItemContainer({children, ...restProps}){
    return <MenuItemContainer {...restProps}>{children}</MenuItemContainer>   
}

MenuHeader.SettingsContainer = function MenuHeaderSettingsContainer({children, ...restProps}){
    return <SettingsContainer {...restProps}>{children}</SettingsContainer>   
}

MenuHeader.ItemImg = function MenuHeaderItemImg({to, ...restProps}){
    return  <ItemImg {...restProps} />    
}

MenuHeader.ProfileImg = function MenuHeaderProfileImg({to, ...restProps}){
    return  <ProfileImg {...restProps} />    
}

MenuHeader.Item = function MenuHeaderItem({children, ...restProps}){
    return <Item {...restProps}>{children}</Item>   
}

MenuHeader.SerprLogoImg = function MenuHeaderSerprLogoImg({to, ...restProps}){
    return  <SerprLogoImg {...restProps} />    
}

MenuHeader.BetaLogoImg = function MenuHeaderBetaLogoImg({to, ...restProps}){
    return  <BetaLogoImg {...restProps} />    
}

MenuHeader.CompanyLogoImg = function MenuHeaderCompanyLogoImg({to, ...restProps}){
    return  <CompanyLogoImg {...restProps} />    
}

MenuHeader.SettingsImg = function MenuHeaderSettingsImg({to, ...restProps}){
    return  <SettingsImg {...restProps} />    
}

MenuHeader.BetaBorder = function MenuHeaderBetaBorder({children, ...restProps}){
    return <BetaBorder {...restProps}>{children}</BetaBorder>   
}

MenuHeader.VerticalLine = function MenuHeaderVerticalLine({children, ...restProps}){
    return <VerticalLine {...restProps}>{children}</VerticalLine>   
}

MenuHeader.Link = function MenuHeaderLink({children, ...restProps}){
    return <Link {...restProps}>{children}</Link>   
}

MenuHeader.SubHeader = function MenuHeaderSubHeader({children, ...restProps}){
    return <SubHeader {...restProps}>{children}</SubHeader>   
}

MenuHeader.SubHeaderContainer = function MenuHeaderSubHeaderContainer({children, ...restProps}){
    return <SubHeaderContainer {...restProps}>{children}</SubHeaderContainer>   
}
