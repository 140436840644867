import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

export function BiggestOpportunitiesBarStackedContainer({competitorChannelResults, uniqueElements}) {

  var organicValues =             [0,0,0,0,0,0,0,0,0,0]
  var adsValues =                 [0,0,0,0,0,0,0,0,0,0]
  var inlineVideosValues =        [0,0,0,0,0,0,0,0,0,0]
  var topStoriesValues =          [0,0,0,0,0,0,0,0,0,0]
  var twitterValues =             [0,0,0,0,0,0,0,0,0,0]
  var knowledgePanelValues =      [0,0,0,0,0,0,0,0,0,0]
  var shoppingValues =            [0,0,0,0,0,0,0,0,0,0]
  var peopleAlsoAskValues  =      [0,0,0,0,0,0,0,0,0,0]
  var inlineImagesValues =        [0,0,0,0,0,0,0,0,0,0]
  var inlineImagesSuggestedValues=[0,0,0,0,0,0,0,0,0,0]

  const [firstRender, setFirstRender] = useState(0)

  useEffect(() => { 
      uniqueElements.map((element, i) => {
        competitorChannelResults.map(result => {
          if(element === result.domain && result.serp_feature === "Organic Results"){
            organicValues[i]=result.sre.toFixed(2)
            setFirstRender(5)
          }
          if(element === result.domain && result.serp_feature === "Ads (Top-Of-Page)"){
            adsValues[i]=result.sre.toFixed(2)
          }
          if(element === result.domain && result.serp_feature === "Inline Videos"){
            inlineVideosValues[i]=result.sre.toFixed(2)
          }
          if(element === result.domain && result.serp_feature === "Top Stories"){
            topStoriesValues[i]=result.sre.toFixed(2)
          }
          if(element === result.domain && result.serp_feature === "Twitter Results"){
            twitterValues[i]=result.sre.toFixed(2)
          }
          if(element === result.domain && result.serp_feature === "Knowledge Panel (GMB)"){
            knowledgePanelValues[i]=result.sre.toFixed(2)
          }
          if(element === result.domain && result.serp_feature === "Shopping Results"){
            shoppingValues[i]=result.sre.toFixed(2)
          }
          if(element === result.domain && result.serp_feature === "People Also Ask"){
            peopleAlsoAskValues[i]=result.sre.toFixed(2)
          }
          if(element === result.domain && result.serp_feature === "Inline Images"){
            inlineImagesValues[i]=result.sre.toFixed(2)
          }
          if(element === result.domain && result.serp_feature === "Inline Images Suggested Searches"){
            inlineImagesSuggestedValues[i]=result.sre.toFixed(2)
           
          }
        }) 
      })
      setFirstRender(1)      

      window.dispatchEvent(new Event('resize')); 

      
  },
  [ 
   firstRender,
   organicValues,
   organicValues,      
  adsValues,          
  inlineVideosValues, 
  topStoriesValues,   
  twitterValues,      
  knowledgePanelValues, 
  shoppingValues,     
  peopleAlsoAskValues,  
  inlineImagesValues, 
  inlineImagesSuggestedValues,
  competitorChannelResults,
  uniqueElements
  ])


    var options = {
        series: [{
        name: 'Organic Results', 
        data: organicValues
      }, {
        name: 'Ads (Top-of-Page)',
        data: adsValues
      },
      {
        name: 'Inline Videos',
        data: inlineVideosValues
      },
      {
        name: 'Top Stories',
        data: topStoriesValues
      },
      {
        name: 'Twitter Results',
        data: twitterValues
      },
      {
        name: 'Knowledge Panel (GMB)',
        data: knowledgePanelValues
      },
      {
        name: 'Shopping Results',
        data: shoppingValues
      },
      {
        name: 'People Also Ask',
        data: peopleAlsoAskValues
      },
      {
        name: 'Inline Images',
        data: inlineImagesValues
      },
      {
        name: 'Inline Images Suggested Searches',
        data: inlineImagesSuggestedValues
      },
    ],
        chart: {
        type: 'bar',
        height: 450,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            download: '<img src='+require("../../../images/visualization/download.svg").default+' width="20">'
          }
        },
      },

      dataLabels: {
        enabled: true,
      },
      tooltip: {
        y: {
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            return w.globals.seriesPercent[seriesIndex][dataPointIndex].toFixed(0) + '%'
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: uniqueElements,
        title: {
          text: 'Share of SERP Real Estate',
          offsetX: 0,
          offsetY: 0,
          style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-xaxis-title',
            },
        },
      },
      colors: ['#841F5B', '#F8855B', '#364DF8', '#C51852', '#854EFF', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0','#9467FA', '#78D9F0', '#FDDF34', '#FF5CE6', '#EF008C'],
      fill: {
        colors: ['#841F5B', '#F8855B', '#364DF8', '#C51852', '#854EFF', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0','#9467FA', '#78D9F0', '#FDDF34', '#FF5CE6', '#EF008C']
      },
      legend: {
        position: 'right',
        horizontalAlign: 'left',
        offsetX: -30,
        markers: {
            fillColors:['#841F5B', '#F8855B', '#364DF8', '#C51852', '#854EFF', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0','#9467FA', '#78D9F0', '#FDDF34', '#FF5CE6', '#EF008C'],
        }
      }
      };
  return (
    <>
    {organicValues[0] !== 0 || organicValues[1] !== 0}
        <Chart
                options={options}
                series={options.series}
                type="bar"
                width="98%"
                height='450px'               

        />
      </>
  )
}
