import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LoaderImage from "../../../components/LoaderImage";

import { Text, Content } from "../../../styled-components/visualization";

import { assistantText, insightsText } from "../utilComponents/InsightsText";
import { TitleContainer } from "../utilComponents/Title";

export function YourKeywordsFromDbContainer({}) {

    const keywordDetailsWithoutPagination = useSelector(state => state.keywordDetailsWithoutPagination)
    const {loading: loadingDetailsWithoutPaginationKeyword, 
        error: errorDetailsWithoutPaginationKeyword, 
        success: successDetailsWithoutPaginationKeyword, 
        keywords
    } = keywordDetailsWithoutPagination

    const [numberOfPages, setNumberOfPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [firstRender, setFirstRender] = useState(0)
    const [helper, setHelper] = useState(0)

    const csvHeader = [
      { label: "keyword", key: "keyword" },
      { label: "device", key: "device" },
      { label: "brand", key: "brand" },
      { label: "google_domain", key: "google_domain" },
      { label: "language", key: "language" },
      { label: "location", key: "location" },
      { label: "country", key: "country" },
      { label: "vertical", key: "vertical" },
      { label: "type", key: "type" },
      { label: "active", key: "active" },
      { label: "seo_group", key: "seo_group" },
    ];
    
    const csvData = [];

    useEffect(() => {
      if(successDetailsWithoutPaginationKeyword && firstRender === 0 ){
        setNumberOfPages(Math.ceil(keywords.length/100))
        // CSV stuff
    
        setFirstRender(1)
        keywords.map((keyword, i) => {
          const obj = {name: keyword.keyword};
          csvData.push(obj);
          if(i===keywords.length-1){
            setHelper(1)
          }
        })

      }

    }, [successDetailsWithoutPaginationKeyword, csvData, helper])


    const nextPage = () => {
      if(currentPage<numberOfPages){
        setCurrentPage(currentPage+1)
      }   
    }

    const previousPage = () => {
      if(currentPage>1){
        setCurrentPage(currentPage-1)
      }   
    }


    

  return (
    <>
    

   <>
     <Content.FlexColumn
        style={{
            width: '100%',
            justifyContent: 'space-between',
            padding: '20px',
            background: 'white'
          }}
        >
        {/* Title */}
         { keywords.length>0 &&
         <>
        <TitleContainer 
            title='Your Keywords & Phrases'
            pages={insightsText.YourKeywordsPhrases[0]}
            p11={insightsText.YourKeywordsPhrases[1]}
            p21={insightsText.YourKeywordsPhrases[2]}
            p31={insightsText.YourKeywordsPhrases[3]}
            p41={insightsText.YourKeywordsPhrases[4]}
            assistantMessage={assistantText.IndustryNetPower100forSportswear}
            csvData={keywords}
            csvHeader={csvHeader}
            />
            </>
            }

           {/* Your keywords */}
           {loadingDetailsWithoutPaginationKeyword || !successDetailsWithoutPaginationKeyword ?
            <LoaderImage height={'small'}   /> :
            <>
           <Content.KeywordsContainer
           style={{
            marginTop: '3%'
          }}
           >
           {keywords.map(( key, i)=>(
            (i >= (currentPage-1)*100 && i<currentPage*100) ?
                <Text.Regular16Black  >{key.keyword}</Text.Regular16Black> :
                null

            ))}

            </Content.KeywordsContainer>
            <Content.FlexRow
              style ={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '3%'
              }}
           >
           <Content.PaginationIcon
           onClick={()=>previousPage()}
           src={require("../../../images/visualization/left_arrow.svg").default}/>
           {currentPage !== numberOfPages ?
            <Text.Regular13Grey>{(currentPage-1)*100+1} - {currentPage*100} / {keywords.length}</Text.Regular13Grey> :
            <Text.Regular13Grey>{(currentPage-1)*100+1} - {keywords.length} / {keywords.length}</Text.Regular13Grey>
           }
           
           <Content.PaginationIcon
          onClick={() =>nextPage()}
           src={require("../../../images/visualization/right_arrow.svg").default}/>

           </Content.FlexRow>
            </>}

        </Content.FlexColumn>
        </>
         
         </>
  )
}
