//DASHBOARD Routes
export const HOME = '/'
export const ADD_COMPANY = '/add-company'
export const ADD_COMPETITORS = '/add-competitors/:companyId'
export const ADD_WEBSITE = '/add-website/:companyId'
export const ADD_KEYWORDS = '/add-keywords/:websiteId'
export const ADD_KEYWORDS_PROFILE = '/add-keywords-profile/:websiteId'
export const ADD_KEYWORDS_COPY = '/add-keywords-copy/:websiteId'
export const ADD_KEYWORDS_SUCCESS = '/add-keywords-success/:websiteId'
export const ADD_KEYWORDS_UPLOAD = '/add-keywords-upload/:websiteId'
export const ADD_KEYWORDS_GENERATE = '/add-keywords-generate/:websiteId'
export const BILLING = '/billing'
export const BULK_ADD_USERS = '/add-users/:companyId'
export const COMPANIES = '/companies'
export const DELETE_COMPANY = '/delete-company/:companyId'
export const DELETE_WEBSITE = '/delete-website/:websiteId'
export const DELETE_USER = '/delete-user/:userId'
export const EDIT_COMPANY = '/edit-company/:companyId'
export const EDIT_KEYWORDS = '/edit-keywords/:keywordId'
export const EDIT_USER = '/edit-user/:userId'
export const EDIT_WEBSITE = '/edit-website/:websiteId'
export const INVITE_USERS = '/invite-user'
export const INBOX = '/inbox'
export const KEYWORDS = '/keywords/:websiteId'
export const ORGANISATION = '/organisation'
export const ORGANISATION_SECURITY = '/organisation-security'
export const PROFILE = '/profile'
export const PRICING = '/pricingPage'
export const SETTINGS = '/settings'
export const USERS = '/users'
export const WEBSITES = '/websites'


// Superuser routes
export const USERS_ADMIN = '/users-admin'
export const SERPR_DASHBOARDS = '/dashboards-admin'
export const KEYWORDS_ADMIN = '/keywords-admin'
export const USER_PREFERENCES = '/users-preferences'
export const LIST_USERS_AUTH0 = '/users-auth0'

export const ACCOUNT_NOT_ACTIVE = '/account-not-active'
export const VERIFY_USERS_EMAIL = '/verify-users-email'




//Signup Pages Routes
export const CREATEPROFILE = '/profile-sign-up'
export const CREATEORGANIZATION = '/create-organisation'
export const CREATECOMPANY = '/create-company'
export const IMPROVE_SERPR_FIRST_PAGE = '/profile-goals'
export const IMPROVE_SERPR_SECOND_PAGE = '/profile-metrics'

//ADMIN CONSOLE Routes
export const ADMIN_CONSOLE_HOME = '/admin-console/home'
export const ADMIN_CONSOLE_USERS = '/admin-console/users'
export const ADMIN_CONSOLE_INVITE_USER = '/admin-console/invite-user'
export const ADMIN_CONSOLE_EDIT_USER = '/admin-console/edit-user'
export const ADMIN_CONSOLE_DELETE_USER = '/admin-console/delete-user'
export const ADMIN_CONSOLE_PROFILE = '/admin-console/profile'
export const ADMIN_CONSOLE_SETTINGS = '/admin-console/settings'
export const ADMIN_CONSOLE_BILLING = '/admin-console/billing'
export const ADMIN_CONSOLE_ORGANISATION = '/admin-console/organisation'

export const ADMIN_CONSOLE_COMPANIES = '/admin-console/companies'
export const ADMIN_CONSOLE_ADD_COMPANY = '/admin-console/add-company'
export const ADMIN_CONSOLE_EDIT_COMPANY = '/admin-console/edit-company'
export const ADMIN_CONSOLE_DELETE_COMPANY = '/admin-console/delete-company'
export const ADMIN_CONSOLE_SECURITY = '/admin-console/security'
export const ADMIN_CONSOLE_HELP = '/admin-console/help'


//Visualization
export const VISUALIZATION_HOME = '/visualization/home'
export const VISUALIZATION_COMPONENTS = '/visualization/components'
export const VISUALIZATION_SUMMARY = '/visualization/summary'
export const VISUALIZATION_BENCHMARKS = '/visualization/benchmarks'
export const VISUALIZATION_COVERAGE = '/visualization/coverage'
export const VISUALIZATION_COMPETITION = '/visualization/competition'
export const VISUALIZATION_RESULTS = '/visualization/results'


