export const LOGGING_SYSTEM_REQUEST = 'LOGGING_SYSTEM_REQUEST'
export const LOGGING_SYSTEM_SUCCESS = 'LOGGING_SYSTEM_SUCCESS'
export const LOGGING_SYSTEM_FAIL = 'LOGGING_SYSTEM_FAIL'

export const LOGS_LIST_REQUEST = 'LOGS_LIST_REQUEST'
export const LOGS_LIST_SUCCESS = 'LOGS_LIST_SUCCESS'
export const LOGS_LIST_FAIL = 'LOGS_LIST_FAIL'

export const LOGS_LIST_BY_EMAIL_REQUEST = 'LOGS_LIST_BY_EMAIL_REQUEST'
export const LOGS_LIST_BY_EMAIL_SUCCESS = 'LOGS_LIST_BY_EMAIL_SUCCESS'
export const LOGS_LIST_BY_EMAIL_FAIL = 'LOGS_LIST_BY_EMAIL_FAIL'
export const LOGS_LIST_BY_EMAIL_RESET = 'LOGS_LIST_BY_EMAIL_RESET'

export const LIST_LAST_LOGS_REQUEST = 'LIST_LAST_LOGS_REQUEST'
export const LIST_LAST_LOGS_SUCCESS = 'LIST_LAST_LOGS_SUCCESS'
export const LIST_LAST_LOGS_FAIL = 'LIST_LAST_LOGS_FAIL'
export const LIST_LAST_LOGS_RESET = 'LIST_LAST_LOGS_RESET'

export const LOGS_LIST_BY_FILTER_REQUEST = 'LOGS_LIST_BY_FILTER_REQUEST'
export const LOGS_LIST_BY_FILTER_SUCCESS = 'LOGS_LIST_BY_FILTER_SUCCESS'
export const LOGS_LIST_BY_FILTER_FAIL = 'LOGS_LIST_BY_FILTER_FAIL'
export const LOGS_LIST_BY_FILTER_RESET = 'LOGS_LIST_BY_FILTER_RESET'

export const ACTION_TYPE_REQUEST = 'ACTION_TYPE_REQUEST'
export const ACTION_TYPE_SUCCESS = 'ACTION_TYPE_SUCCESS'
export const ACTION_TYPE_FAIL = 'ACTION_TYPE_FAIL'
export const ACTION_TYPE_RESET = 'ACTION_TYPE_RESET'

export const LOG_TYPE_REQUEST = 'LOG_TYPE_REQUEST'
export const LOG_TYPE_SUCCESS = 'LOG_TYPE_SUCCESS'
export const LOG_TYPE_FAIL = 'LOG_TYPE_FAIL'
export const LOG_TYPE_RESET = 'LOG_TYPE_RESET'
