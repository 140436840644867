import React, {useEffect} from 'react'
import { MenuHeaderContainer } from '../../containers/visualization/MenuHeader'
import { WelcomeContainer } from '../../containers/visualization/Welcome'
import { FooterContainer } from '../../containers/visualization/Footer';
import { useHistory } from 'react-router-dom'


export default function Summary({accessToken, name, email, isActive, isSuperuser, websiteId}) {
    let history = useHistory()
  
    useEffect(() => {
      if (accessToken) {
          //if account is active
          if(isActive === undefined){

        }else{
            if(isActive ){
    
            }
            else {
                history.push(`/account-not-active`)
            }
        }
      }
  }, [
      accessToken,
      email,
      isActive,
      isSuperuser
  ])
    return (
        <>
        {isActive && 
    <div>
        <MenuHeaderContainer pageName={'Welcome'}  email={email} name={name} accessToken={accessToken} websiteId={websiteId}/>
        <WelcomeContainer email={email} name={name} accessToken={accessToken} websiteId={websiteId}/>
        <FooterContainer />
        {/* <NoWorkingPage /> */}
    </div>}
    </>
    )
}
