import React, { createContext, useState, useEffect } from 'react'


export const CompanyContext = createContext()
export default function CompanyContextProvider(props)  {
  
    //new state for company domain
    const [companyDomain, setCompanyDomain] = useState('');
    const [onboardingDate, setOnboardingDate] = useState('');
    const [date, setDate] = useState([,]);

    var dateFilterApplied = [];

    const [companyId4Api, setCompanyId4Api] = useState('1')
    const [companyIndustry, setCompanyIndustry] = useState('1')
    const [websiteTheme, setWebsiteTheme] = useState('1')
    const [websiteName, setWebsiteName] = useState('1')
    



    //format YYYY-mm-dd  ->  toISOString().slice(0, 10)
    // var currentDate = new Date();
    // var todayDate = new Date().toISOString().slice(0, 10); 
    // //bring results from the past 30 days
    // var lastMonth = new Date(currentDate.setDate(currentDate.getDate()-30)).toISOString().slice(0, 10);
    // var dateT = [lastMonth, todayDate]
    // const [date, setDate] = useState(dateT)
    

  
    return (
         <CompanyContext.Provider 
         value={{
            date, setDate,
            dateFilterApplied,
            onboardingDate, setOnboardingDate,
            companyDomain, setCompanyDomain,
            companyId4Api, setCompanyId4Api,
            companyIndustry, setCompanyIndustry,
            websiteName, setWebsiteName,
            websiteTheme, setWebsiteTheme
         }}
         >
               {props.children}
         </CompanyContext.Provider>
    )
}
