
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { FRONTEND_OLD } from './constants/config/handleFrontend'

import Header from './components/Header'
import Api_test_screen from './screens/Api_test_screen'
import Company_details from './screens/Company_details';
import Website_details from './screens/Website_details';
import Competitors_screen from './screens/Competitors_screen';
import Generate_keywords_screen from './screens/Generate_keywords_screen';
import Competitor_edit from './screens/Competitor_edit';
import List_keywords_screen from './screens/List_keywords_screen';
import Categorize_keywords_screen from './screens/Categorize_keywords_screen';
import Admin_screen from './screens/admin/Admin_screen'
import LoggingSystemScreen from './screens/admin/LoggingSystemScreen'
import AuthUserScreen from './screens/admin/AuthUserScreen'
import LogsDetailScreen from './screens/admin/LogsDetailScreen'
import Categorizer_updated_screen from './screens/Categorizer_updated_screen'

import * as ROUTES from "./constants/routes/routes"

import Home from './pages/dashboard/home'
import CreateProfile from './pages/signup/createProfile'
import CreateOrganization from './pages/signup/createOrganisation'
import ImproveSerprFirstPage from './pages/signup/improveSerprFirstPage'
import ImproveSerprSecondPage from './pages/signup/improveSerprSecondPage'

// import ACHome from './pages/admin-console/acHome'
// import ACUsers from './pages/admin-console/acUsers'
// import ACUsersInvite from './pages/admin-console/acUsersInvite'
// import ACUsersDelete from './pages/admin-console/acUsersDelete'
// import ACUsersEdit from './pages/admin-console/acUsersEdit'
// import ACProfile from './pages/admin-console/acProfile'
// import ACSettings from './pages/admin-console/acSettings'
// import ACBilling from './pages/admin-console/acBilling'
// import ACOrganisation from './pages/admin-console/acOrganisation'
// import ACCompanies from './pages/admin-console/acCompanies'
// import ACCompanyAdd from './pages/admin-console/acCompanyAdd'
// import ACCompanyEdit from './pages/admin-console/acCompanyEdit'
// import ACCompanyDelete from './pages/admin-console/acCompanyDelete'
// import ACSecurity from './pages/admin-console/acSecurity'
// import ACHelp from './pages/admin-console/acHelp'


import PrivateRoute from './utils/PrivateRoute'

import Profile from './pages/dashboard/profile'
import AddCompany from './pages/dashboard/addCompany'
import AddWebsite from './pages/dashboard/addWebsite'
import AddKeywords from './pages/dashboard/addKeywords'
import AddKeywordsCopy from './pages/dashboard/addKeywordsCopy'
import AddKeywordsSuccess from './pages/dashboard/addKeywordsSuccess'
import AddKeywordsUpload from './pages/dashboard/addKeywordsUpload'
import AddKeywordsGenerate from './pages/dashboard/addKeywordsGenerate'
import Billing from './pages/dashboard/billing'
import BulkAddUsers from './pages/dashboard/bulkAddUsers'
import Companies from './pages/dashboard/companies'
import DeleteCompany from './pages/dashboard/deleteCompany'
import DeleteWebsite from './pages/dashboard/deleteWebsite'
import DeleteUser from './pages/dashboard/deleteUser'
import EditCompany from './pages/dashboard/editCompany'
import EditKeywords from './pages/dashboard/editKeywords'
import EditUser from './pages/dashboard/editUser'
import EditWebsite from './pages/dashboard/editWebsite'
import InviteUser from './pages/dashboard/inviteUsers'
import Keywords from './pages/dashboard/keywords'
// import Organisation from './pages/dashboard/organisation'
// import OrganisationSecurity from './pages/dashboard/organisationSecurity'
import Settings from './pages/dashboard/settings'
import Users from './pages/dashboard/users'
import Websites from './pages/dashboard/websites'
import Inbox from './pages/dashboard/inbox'
import Summary from './pages/visualization/Welcome'
import Benchmarks from './pages/visualization/Benchmarks'
import Coverage from './pages/visualization/Coverage'
import Competition from './pages/visualization/Competition'
import Results from './pages/visualization/Results'
import AccountNotActive from './pages/signup/accountNotActive'

import PricingPage from './pages/dashboard/pricingPage'
import PageNotFound from './pages/PageNotFound'
import UserAdmins from './pages/serprAdmin/UsersAdmins'
import Dashboards from './pages/serprAdmin/Dashboards'
import KeywordsAdmin from './pages/serprAdmin/KeywordsAdmin'
import UserPreferences from './pages/serprAdmin/UserPreferences'
import UsersAuth0 from './pages/serprAdmin/UsersAuth0'
import AddCompetitors from './pages/dashboard/addCompetitors'
import AddKeywordsProfile from './pages/dashboard/addKeywordsProfile'
import VerifyUsersEmail from './pages/VerifyUsersEmail'

  // true means pricing is enabled
  // false means pricing is disabled
  export const pricingEnabled = false


function App() {
  return (
    FRONTEND_OLD ?
    <Router>
      <Switch>
      <Header />
        <Container>
          <Route exact path='/profile' component={Api_test_screen} />
          <Route exact path='/admin' component={Admin_screen} />
          <Route exact path='/admin/logs/' component={LoggingSystemScreen} />
          <Route exact path='/admin/users/' component={AuthUserScreen} />
          <Route exact path='/admin/logs/details/:email' component={LogsDetailScreen} />
          <Route exact path='/company/details/:companyId' component={Company_details} />
          <Route exact path='/website/details/:websiteId' component={Website_details} />
          <Route exact path='/competitors/:websiteId' component={Competitors_screen} />
          <Route exact path='/competitor/edit/:competitorId' component={Competitor_edit} />
          <Route exact path='/keywords/:websiteId' component={Generate_keywords_screen} />
          <Route exact path='/keywords/list/:websiteId' component={List_keywords_screen} />
          <Route exact path='/keywords/categorizer/updated/:websiteId' component={Categorizer_updated_screen} />
          <Route exact path='/keywords/categorizer/:websiteId' component={Categorize_keywords_screen} />
          
        </Container>
        </Switch>
    </Router> 
    :
    <Router>
      <Switch>
    {/* <Header /> */}
      {/* Signup Pages */}
      <PrivateRoute exact path = {ROUTES.CREATEPROFILE} component={CreateProfile} />
      <PrivateRoute exact path = {ROUTES.CREATEORGANIZATION} component={CreateOrganization} />
      <PrivateRoute exact path = {ROUTES.IMPROVE_SERPR_FIRST_PAGE} component={ImproveSerprFirstPage} />
      <PrivateRoute exact path = {ROUTES.IMPROVE_SERPR_SECOND_PAGE} component={ImproveSerprSecondPage} />
      
      {/* Dashboard */}
      <PrivateRoute exact path = {ROUTES.HOME} component={Home} />
      <PrivateRoute exact path = {ROUTES.PROFILE} component={Profile} />
      <PrivateRoute exact path = {ROUTES.ADD_COMPANY} component={AddCompany} />
      <PrivateRoute exact path = {ROUTES.ADD_WEBSITE} component={AddWebsite} />
      <PrivateRoute exact path = {ROUTES.ADD_COMPETITORS} component={AddCompetitors} />
      <PrivateRoute exact path = {ROUTES.ADD_KEYWORDS} component={AddKeywords} />
      <PrivateRoute exact path = {ROUTES.ADD_KEYWORDS_COPY} component={AddKeywordsCopy} />
      <PrivateRoute exact path = {ROUTES.ADD_KEYWORDS_PROFILE} component={AddKeywordsProfile} />
      <PrivateRoute exact path = {ROUTES.ADD_KEYWORDS_SUCCESS} component={AddKeywordsSuccess} />
      <PrivateRoute exact path = {ROUTES.ADD_KEYWORDS_UPLOAD} component={AddKeywordsUpload} />
      <PrivateRoute exact path = {ROUTES.ADD_KEYWORDS_GENERATE} component={AddKeywordsGenerate} />
      <PrivateRoute exact path = {ROUTES.BILLING} component={Billing} />
      <PrivateRoute exact path = {ROUTES.BULK_ADD_USERS} component={BulkAddUsers} />
      <PrivateRoute exact path = {ROUTES.COMPANIES} component={Companies} />
      <PrivateRoute exact path = {ROUTES.DELETE_COMPANY} component={DeleteCompany} />
      <PrivateRoute exact path = {ROUTES.DELETE_WEBSITE} component={DeleteWebsite} />
      <PrivateRoute exact path = {ROUTES.DELETE_USER} component={DeleteUser} />
      <PrivateRoute exact path = {ROUTES.EDIT_COMPANY} component={EditCompany} />
      <PrivateRoute exact path = {ROUTES.EDIT_KEYWORDS} component={EditKeywords} />
      <PrivateRoute exact path = {ROUTES.EDIT_USER} component={EditUser} />
      <PrivateRoute exact path = {ROUTES.EDIT_WEBSITE} component={EditWebsite} />
      <PrivateRoute exact path = {ROUTES.INVITE_USERS} component={InviteUser} />
      <PrivateRoute exact path = {ROUTES.INBOX} component={Inbox} />
      <PrivateRoute exact path = {ROUTES.KEYWORDS} component={Keywords} />

      {pricingEnabled && <PrivateRoute exact path = {ROUTES.PRICING} component={PricingPage} />}

      {/* Commented due to requirements to remove Organisation level at this point */}
      {/* <PrivateRoute exact path = {ROUTES.ORGANISATION} component={Organisation} />
      <PrivateRoute exact path = {ROUTES.ORGANISATION_SECURITY} component={OrganisationSecurity} /> */}
      <PrivateRoute exact path = {ROUTES.SETTINGS} component={Settings} />
      <PrivateRoute exact path = {ROUTES.USERS} component={Users} />
      <PrivateRoute exact path = {ROUTES.WEBSITES} component={Websites} />
      <PrivateRoute exact path = {ROUTES.ACCOUNT_NOT_ACTIVE} component={AccountNotActive} />

      {/* LUMINR ADMIN */}
      <PrivateRoute exact path = {ROUTES.USERS_ADMIN} component={UserAdmins} />
      <PrivateRoute exact path = {ROUTES.SERPR_DASHBOARDS} component={Dashboards} />
      <PrivateRoute exact path = {ROUTES.KEYWORDS_ADMIN} component={KeywordsAdmin} />
      <PrivateRoute exact path = {ROUTES.USER_PREFERENCES} component={UserPreferences} />
      <PrivateRoute exact path = {ROUTES.LIST_USERS_AUTH0}  component={UsersAuth0} />

      {/* Admin Console */}
      {/* <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_HOME} component={ACHome} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_INVITE_USER} component={ACUsersInvite} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_EDIT_USER} component={ACUsersEdit} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_DELETE_USER} component={ACUsersDelete} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_USERS} component={ACUsers} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_PROFILE} component={ACProfile} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_SETTINGS} component={ACSettings} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_BILLING} component={ACBilling} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_ORGANISATION} component={ACOrganisation} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_COMPANIES} component={ACCompanies} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_ADD_COMPANY} component={ACCompanyAdd} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_EDIT_COMPANY} component={ACCompanyEdit} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_DELETE_COMPANY} component={ACCompanyDelete} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_SECURITY} component={ACSecurity} />
      <PrivateRoute exact path = {ROUTES.ADMIN_CONSOLE_HELP} component={ACHelp} /> */}


      {/* Visualization */}
      <PrivateRoute exact path = {ROUTES.VISUALIZATION_SUMMARY} component={Summary} />
      <PrivateRoute exact path = {ROUTES.VISUALIZATION_BENCHMARKS} component={Benchmarks} />
      <PrivateRoute exact path = {ROUTES.VISUALIZATION_COVERAGE} component={Coverage} />
      <PrivateRoute exact path = {ROUTES.VISUALIZATION_COMPETITION} component={Competition} />
      <PrivateRoute exact path = {ROUTES.VISUALIZATION_RESULTS} component={Results} />

      <PrivateRoute exact path={ROUTES.VERIFY_USERS_EMAIL} component={VerifyUsersEmail} />
      <Route path="*" component={PageNotFound} />
      </Switch>

    </Router>
    
    
  )
}

export default App;
