import  axios from 'axios'
import { 
    SERP_LEAGUE_REQUEST,
    SERP_LEAGUE_SUCCESS,
    SERP_LEAGUE_FAIL,
    COMPETITOR_SWOT_LANDING_PAGES_REQUEST,
    COMPETITOR_SWOT_LANDING_PAGES_SUCCESS,
    COMPETITOR_SWOT_LANDING_PAGES_FAIL,
    MONTHLY_MOVEMENTS_NUMBER_REQUEST,
    MONTHLY_MOVEMENTS_NUMBER_SUCCESS,
    MONTHLY_MOVEMENTS_NUMBER_FAIL,
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import { 
    READ_RANKINGS_REQUEST,
    READ_RANKINGS_SUCCESS,
    READ_RANKINGS_FAIL,
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import { 
    GET_FILTERS_REQUEST,
    GET_FILTERS_SUCCESS,
    GET_FILTERS_FAIL,
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import { 
    READ_SRE_REQUEST,
    READ_SRE_SUCCESS,
    READ_SRE_FAIL,
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import { 
    RETRIEVE_SWOT_REQUEST,
    RETRIEVE_SWOT_SUCCESS,
    RETRIEVE_SWOT_FAIL,
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import { 
    CONTENT_EXPLORER_REQUEST,
    CONTENT_EXPLORER_SUCCESS,
    CONTENT_EXPLORER_FAIL,
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import { 
    INDUSTRY_BENCHMARKS_POWER_REQUEST,
    INDUSTRY_BENCHMARKS_POWER_SUCCESS,
    INDUSTRY_BENCHMARKS_POWER_FAIL,
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import { 
    INDUSTRY_BENCHMARKS_STATS_REQUEST,
    INDUSTRY_BENCHMARKS_STATS_SUCCESS,
    INDUSTRY_BENCHMARKS_STATS_FAIL,
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import { 
    SERP_COMPOSITION_REQUEST,
    SERP_COMPOSITION_SUCCESS,
    SERP_COMPOSITION_FAIL,

    SERP_COMPOSITION_INCLUDE_REQUEST,
    SERP_COMPOSITION_INCLUDE_SUCCESS,
    SERP_COMPOSITION_INCLUDE_FAIL,

    SERP_COMPOSITION_FOR_BENCHMARKS_REQUEST,
    SERP_COMPOSITION_FOR_BENCHMARKS_SUCCESS,
    SERP_COMPOSITION_FOR_BENCHMARKS_FAIL,

    SERP_REAL_ESTATE_SCORE_REQUEST,
    SERP_REAL_ESTATE_SCORE_SUCCESS,
    SERP_REAL_ESTATE_SCORE_FAIL,

    COMPETITOR_SWOT_HEATMAP_REQUEST,
    COMPETITOR_SWOT_HEATMAP_SUCCESS,
    COMPETITOR_SWOT_HEATMAP_FAIL,

    HVN_SCORE_REQUEST,
    HVN_SCORE_SUCCESS,
    HVN_SCORE_FAIL,

    TOTAL_SEARCH_CTR_REQUEST,
    TOTAL_SEARCH_CTR_SUCCESS,
    TOTAL_SEARCH_CTR_FAIL,

    BIG_KEYWORD_RANKING_REQUEST,
    BIG_KEYWORD_RANKING_SUCCESS,
    BIG_KEYWORD_RANKING_FAIL,

    COMPETITOR_LANDSCAPE_REQUEST,
    COMPETITOR_LANDSCAPE_SUCCESS,
    COMPETITOR_LANDSCAPE_FAIL,

    FEATURED_SNIPPED_OPPORTUNITIES_REQUEST,
    FEATURED_SNIPPED_OPPORTUNITIES_SUCCESS,
    FEATURED_SNIPPED_OPPORTUNITIES_FAIL,

    MONTHLY_MOVEMENTS_REQUEST,
    MONTHLY_MOVEMENTS_SUCCESS,
    MONTHLY_MOVEMENTS_FAIL, 

    INDUSTRY_BENCHMARKS_CHANNEL_REQUEST,
    INDUSTRY_BENCHMARKS_CHANNEL_SUCCESS,
    INDUSTRY_BENCHMARKS_CHANNEL_FAIL,

    TRACKING_SUMMARY_REQUEST,
    TRACKING_SUMMARY_SUCCESS,
    TRACKING_SUMMARY_FAIL, 

    COMPETITOR_CHANNEL_REQUEST,
    COMPETITOR_CHANNEL_SUCCESS,
    COMPETITOR_CHANNEL_FAIL, 

    SERP_MOCKUP_REQUEST,
    SERP_MOCKUP_SUCCESS,
    SERP_MOCKUP_FAIL, 

    WEBSITE_TRACKING_STATUS_REQUEST,
    WEBSITE_TRACKING_STATUS_SUCCESS,
    WEBSITE_TRACKING_STATUS_FAIL, 

    SERPR_SCORE_100_REQUEST,
    SERPR_SCORE_100_SUCCESS,
    SERPR_SCORE_100_FAIL, 

    SERPR_SCORE_REQUEST,
    SERPR_SCORE_SUCCESS,
    SERPR_SCORE_FAIL, 
 } from '../../constants/visualization/dataScienceAPIsConstants'

 import {
    // PERFORMANCE_LOGGING_REQUEST,
    // PERFORMANCE_LOGGING_SUCCESS,
    PERFORMANCE_LOGGING_FAIL,

} from '../../constants/loggingsystem/performanceLoggingConstants'

 axios.interceptors.request.use( x => {
    // to avoid overwriting if another interceptor
    // already defined the same object (meta)
    x.meta = x.meta || {}
    x.meta.requestStartedAt = new Date().getTime();
    return x;
})

axios.interceptors.response.use(x => {
    if(x.config.url.split('/')[2] === "gc_api") {
        //console.log(`Execution time for: ${x.config.url} - ${new Date().getTime() - x.config.meta.requestStartedAt} ms`)
        x.responseTime = new Date().getTime() - x.config.meta.requestStartedAt;
    }
    return x;
},

)



export const getSerpLeague = (accessToken, params, email) => async (dispatch) => {
    try {
        dispatch({
            type: SERP_LEAGUE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const serpLeague = await axios.post(
            `/api/gc_api/serpLeague`,
            params,
            config
        )

        dispatch({
            type: SERP_LEAGUE_SUCCESS,
            payload: serpLeague.data,
            total_rows: serpLeague.headers['x-total-rows'],
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: serpLeague.responseTime,
        //     apiName: serpLeague.config.url.split('/')[3],
        //     createdAt: serpLeague.config.meta.requestStartedAt
        // }

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

        return serpLeague

    } catch (error) {
        dispatch({
            type: SERP_LEAGUE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
            total_rows: 0
        })
        dispatch({
            type: PERFORMANCE_LOGGING_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

    
}

export const readRankings = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: READ_RANKINGS_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const rankings = await axios.post(
            `/api/gc_api/readRankings`,
            params,
            config
        )

        dispatch({
            type: READ_RANKINGS_SUCCESS,
            payload: rankings.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: rankings.responseTime,
        //     apiName: rankings.config.url.split('/')[3],
        //     createdAt: rankings.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

        return rankings

    } catch (error) {
        dispatch({
            type: READ_RANKINGS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getFilters = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: GET_FILTERS_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const filters = await axios.post(
            `/api/gc_api/getFilters`,
            params,
            config
        )

        dispatch({
            type: GET_FILTERS_SUCCESS,
            payload: filters.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: rankings.responseTime,
        //     apiName: rankings.config.url.split('/')[3],
        //     createdAt: rankings.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: GET_FILTERS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const readSRE = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: READ_SRE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const readSre = await axios.post(
            `/api/gc_api/readSRE`,
            params,
            config
        )

        dispatch({
            type: READ_SRE_SUCCESS,
            payload: readSre.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: readSre.responseTime,
        //     apiName: readSre.config.url.split('/')[3],
        //     createdAt: readSre.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: READ_SRE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const retrieveSWOT = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: RETRIEVE_SWOT_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const retrieveSwot = await axios.post(
            `/api/gc_api/retrieveSWOT`,
            params,
            config
        )

        dispatch({
            type: RETRIEVE_SWOT_SUCCESS,
            payload: retrieveSwot.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: retrieveSwot.responseTime,
        //     apiName: retrieveSwot.config.url.split('/')[3],
        //     createdAt: retrieveSwot.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: RETRIEVE_SWOT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getContentExplorer = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: CONTENT_EXPLORER_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const contentExplorer = await axios.post(
            `/api/gc_api/getContentExplorer`,
            params,
            config
        )

        dispatch({
            type: CONTENT_EXPLORER_SUCCESS,
            payload: contentExplorer.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: contentExplorer.responseTime,
        //     apiName: contentExplorer.config.url.split('/')[3],
        //     createdAt: contentExplorer.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: CONTENT_EXPLORER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getIndustryBenchmarksPower = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: INDUSTRY_BENCHMARKS_POWER_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            params: params
        }
        
        const benchmarksPower = await axios.get(
            `/api/gc_api/getIndustryBenchmarksPower`,
            config
        )

        dispatch({
            type: INDUSTRY_BENCHMARKS_POWER_SUCCESS,
            payload: benchmarksPower.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: benchmarksPower.responseTime,
        //     apiName: benchmarksPower.config.url.split('/')[3],
        //     createdAt: benchmarksPower.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })
    } catch (error) {
        dispatch({
            type: INDUSTRY_BENCHMARKS_POWER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getIndustryBenchmarksStats = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: INDUSTRY_BENCHMARKS_STATS_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            params: params
        }
        
        const benchmarksStats = await axios.get(
            `/api/gc_api/getIndustryBenchmarksStats`,
            config
        )

        dispatch({
            type: INDUSTRY_BENCHMARKS_STATS_SUCCESS,
            payload: benchmarksStats.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: benchmarksStats.responseTime,
        //     apiName: benchmarksStats.config.url.split('/')[3],
        //     createdAt: benchmarksStats.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: INDUSTRY_BENCHMARKS_STATS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSerpCompositionInclude = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: SERP_COMPOSITION_INCLUDE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const serpCompositionInclude = await axios.post(
            `/api/gc_api/getSerpComposition`,
            params,
            config
        )

        dispatch({
            type: SERP_COMPOSITION_INCLUDE_SUCCESS,
            payload: serpCompositionInclude.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: serpComposition.responseTime,
        //     apiName: serpComposition.config.url.split('/')[3],
        //     createdAt: serpComposition.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: SERP_COMPOSITION_INCLUDE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSerpComposition = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: SERP_COMPOSITION_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const serpComposition = await axios.post(
            `/api/gc_api/getSerpComposition`,
            params,
            config
        )

        dispatch({
            type: SERP_COMPOSITION_SUCCESS,
            payload: serpComposition.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: serpComposition.responseTime,
        //     apiName: serpComposition.config.url.split('/')[3],
        //     createdAt: serpComposition.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: SERP_COMPOSITION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSerpCompositionForBenchmarks = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: SERP_COMPOSITION_FOR_BENCHMARKS_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            },
        }
        
        const serpCompositionForBenchmarks = await axios.post(
            `/api/gc_api/getSerpCompositionForBenchmarks`,
            params,
            config
        )

        dispatch({
            type: SERP_COMPOSITION_FOR_BENCHMARKS_SUCCESS,
            payload: serpCompositionForBenchmarks.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: serpComposition.responseTime,
        //     apiName: serpComposition.config.url.split('/')[3],
        //     createdAt: serpComposition.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: SERP_COMPOSITION_FOR_BENCHMARKS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSerpRealEstateScore = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: SERP_REAL_ESTATE_SCORE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            params
        }
        
        const realEstateScore = await axios.get(
            `/api/gc_api/getSerpRealEstateScore`,
            config
        )

        dispatch({
            type: SERP_REAL_ESTATE_SCORE_SUCCESS,
            payload: realEstateScore.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: realEstateScore.responseTime,
        //     apiName: realEstateScore.config.url.split('/')[3],
        //     createdAt: realEstateScore.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: SERP_REAL_ESTATE_SCORE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const competitorSwotHeatmap = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: COMPETITOR_SWOT_HEATMAP_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const competitorSwot = await axios.post(
            `/api/gc_api/competitorSwotHeatmap`,
            params,
            config
        )

        dispatch({
            type: COMPETITOR_SWOT_HEATMAP_SUCCESS,
            payload: competitorSwot.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: competitorSwot.responseTime,
        //     apiName: competitorSwot.config.url.split('/')[3],
        //     createdAt: competitorSwot.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: COMPETITOR_SWOT_HEATMAP_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const competitorSwotLandingPages = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: COMPETITOR_SWOT_LANDING_PAGES_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const competitorSwot = await axios.post(
            `/api/gc_api/competitorSwotLandingPages`,
            params,
            config
        )

        dispatch({
            type: COMPETITOR_SWOT_LANDING_PAGES_SUCCESS,
            payload: competitorSwot.data,
            total_rows:competitorSwot.headers['x-total-rows']
        })


    } catch (error) {
        dispatch({
            type: COMPETITOR_SWOT_LANDING_PAGES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
            total_rows:0
        })
    }
}

export const hvnScoreLeaderboard = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: HVN_SCORE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const hvnScore = await axios.post(
            `/api/gc_api/hvnScoreLeaderboard`,
            params,
            config
        )

        dispatch({
            type: HVN_SCORE_SUCCESS,
            payload: hvnScore.data,
            total_rows:hvnScore.headers['x-total-rows']
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: hvnScore.responseTime,
        //     apiName: hvnScore.config.url.split('/')[3],
        //     createdAt: hvnScore.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: HVN_SCORE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
            total_rows:0
        })
    }
}


export const totalSearchCtr = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: TOTAL_SEARCH_CTR_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const searchCtr = await axios.post(
            `/api/gc_api/totalSearchCtr`,
            params,
            config
        )

        dispatch({
            type: TOTAL_SEARCH_CTR_SUCCESS,
            payload: searchCtr.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: searchCtr.responseTime,
        //     apiName: searchCtr.config.url.split('/')[3],
        //     createdAt: searchCtr.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: TOTAL_SEARCH_CTR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const bigKeywordRankingOpp = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: BIG_KEYWORD_RANKING_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const keywordRanking = await axios.post(
            `/api/gc_api/bigKeywordRankingOpp`,
            params,
            config
        )

        dispatch({
            type: BIG_KEYWORD_RANKING_SUCCESS,
            payload: keywordRanking.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: keywordRanking.responseTime,
        //     apiName: keywordRanking.config.url.split('/')[3],
        //     createdAt: keywordRanking.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: BIG_KEYWORD_RANKING_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getCompetitorLandscape = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: COMPETITOR_LANDSCAPE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const competitorLandscape = await axios.post(
            `/api/gc_api/competitorLandscape`,
            params,
            config
        )

        dispatch({
            type: COMPETITOR_LANDSCAPE_SUCCESS,
            payload: competitorLandscape.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: competitorLandscape.responseTime,
        //     apiName: competitorLandscape.config.url.split('/')[3],
        //     createdAt: competitorLandscape.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: COMPETITOR_LANDSCAPE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const featuredSnippetOpp = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: FEATURED_SNIPPED_OPPORTUNITIES_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const featuredSnippet = await axios.post(
            `/api/gc_api/featuredSnippetOpp`,
            params,
            config
        )
        dispatch({
            type: FEATURED_SNIPPED_OPPORTUNITIES_SUCCESS,
            payload: featuredSnippet.data,
            total_rows: featuredSnippet.headers['x-total-rows']

        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: featuredSnippet.responseTime,
        //     apiName: featuredSnippet.config.url.split('/')[3],
        //     createdAt: featuredSnippet.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })


    } catch (error) {
        dispatch({
            type: FEATURED_SNIPPED_OPPORTUNITIES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
            total_rows: 0
        })
    }
}

export const monthlyMovements = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: MONTHLY_MOVEMENTS_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const monthlyMovements = await axios.post(
            `/api/gc_api/monthlyMovements`,
            params,
            config
        )

        dispatch({
            type: MONTHLY_MOVEMENTS_SUCCESS,
            payload: monthlyMovements.data,
            total_rows:monthlyMovements.headers['x-total-rows']
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: monthlyMovements.responseTime,
        //     apiName: monthlyMovements.config.url.split('/')[3],
        //     createdAt: monthlyMovements.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: MONTHLY_MOVEMENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
            total_rows:0
        })
    }
}

export const monthlyMovementsNumber = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: MONTHLY_MOVEMENTS_NUMBER_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const monthlyMovementsNumber = await axios.post(
            `/api/gc_api/monthlyMovementsNumber`,
            params,
            config
        )

        dispatch({
            type: MONTHLY_MOVEMENTS_NUMBER_SUCCESS,
            payload: monthlyMovementsNumber.data
        })

    } catch (error) {
        dispatch({
            type: MONTHLY_MOVEMENTS_NUMBER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const trackingSummary = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: TRACKING_SUMMARY_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const trackingSummary = await axios.post(
            `/api/gc_api/trackingSummary`,
            params,
            config
        )

        dispatch({
            type: TRACKING_SUMMARY_SUCCESS,
            payload: trackingSummary.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: trackingSummary.responseTime,
        //     apiName: trackingSummary.config.url.split('/')[3],
        //     createdAt: trackingSummary.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: TRACKING_SUMMARY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const competitorChannel = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: COMPETITOR_CHANNEL_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const competitorChannel = await axios.post(
            `/api/gc_api/competitorChannel`,
            params,
            config
        )

        dispatch({
            type: COMPETITOR_CHANNEL_SUCCESS,
            payload: competitorChannel.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: competitorChannel.responseTime,
        //     apiName: competitorChannel.config.url.split('/')[3],
        //     createdAt: competitorChannel.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: COMPETITOR_CHANNEL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const serpMockup = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: SERP_MOCKUP_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }
        
        const serpMockup = await axios.post(
            `/api/gc_api/serpMockup`,
            params,
            config
        )

        dispatch({
            type: SERP_MOCKUP_SUCCESS,
            payload: serpMockup.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: serpMockup.responseTime,
        //     apiName: serpMockup.config.url.split('/')[3],
        //     createdAt: serpMockup.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: SERP_MOCKUP_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getIndustryBenchmarksChannel = (accessToken, params, email) => async (dispatch) => {
    try {
       
        dispatch({
            type: INDUSTRY_BENCHMARKS_CHANNEL_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            params: params
        }
        
        const benchmarksChannel = await axios.get(
            `/api/gc_api/getIndustryBenchmarksChannel`,
            config
        )

        dispatch({
            type: INDUSTRY_BENCHMARKS_CHANNEL_SUCCESS,
            payload: benchmarksChannel.data,
        })

        // Performance logging
        //dispatch({
   //         type: PERFORMANCE_LOGGING_REQUEST
   //     })

        // const paramsLogging = {
        //     userEmail: email,
        //     performanceTime: benchmarksChannel.responseTime,
        //     apiName: benchmarksChannel.config.url.split('/')[3],
        //     createdAt: benchmarksChannel.config.meta.requestStartedAt
        // }     

        // const performanceLog = await axios.post(
        //     `/api/performanceLogs/createPerformanceLog`,
        //     paramsLogging,
        //     config
        // )

        //dispatch({
   //         type: PERFORMANCE_LOGGING_SUCCESS,
    //        payload: performanceLog.data,
 //       })

    } catch (error) {
        dispatch({
            type: INDUSTRY_BENCHMARKS_CHANNEL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getWebsiteTrackingStatus = (accessToken, params) => async (dispatch) => {
    try {
       
        dispatch({
            type: WEBSITE_TRACKING_STATUS_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            params: params
        }
        
        const websiteStatus = await axios.get(
            `/api/gc_api/getWebsiteTrackingStatus`,
            config
        )

        dispatch({
            type: WEBSITE_TRACKING_STATUS_SUCCESS,
            payload: websiteStatus.data,
        })


    } catch (error) {
        dispatch({
            type: WEBSITE_TRACKING_STATUS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSerprScore100 = (accessToken, params) => async (dispatch) => {
    try {
       
        dispatch({
            type: SERPR_SCORE_100_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            params: params
        }
        
        const serpScore100 = await axios.get(
            `/api/gc_api/getSerprScore100`,
            config
        )

        dispatch({
            type: SERPR_SCORE_100_SUCCESS,
            payload: serpScore100.data,
        })


    } catch (error) {
        dispatch({
            type: SERPR_SCORE_100_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSerprScore = (accessToken, params) => async (dispatch) => {
    try {
       
        dispatch({
            type: SERPR_SCORE_REQUEST
        })

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            params: params
        }
        
        const serpScore = await axios.get(
            `/api/gc_api/getSerprScore`,
            config
        )

        dispatch({
            type: SERPR_SCORE_SUCCESS,
            payload: serpScore.data,
        })


    } catch (error) {
        dispatch({
            type: SERPR_SCORE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}