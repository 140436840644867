import styled from "styled-components"
import image from "../../../../images/visualization/06.png"

export const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: repeat(auto-fill, 400px);
    background-image: linear-gradient(#C51852,#C51852);
    background-size: 5% 6px;
    background-position: top right;
    background-repeat: no-repeat;
    
`

export const Section = styled.div`
    grid-column: 1fr ;
   // box-shadow: 10px 0px 16px #0000001A;
   padding:50px;
    height: 100%;
`

export const Section1 = styled.div`
    grid-column: 1fr;
    box-shadow: 0px 10px 16px #0000001A;
    padding-top: 5px;
    background-image: linear-gradient(#C51852,#C51852);
    background-size: 13% 6px;
    background-position: top right;
    background-repeat: no-repeat;
    padding: 50px;
    height: 100%;

`

export const Section2 = styled.div`
    grid-column: 1fr;
    box-shadow: 0px 10px 16px #0000001A;
    padding-top: 5px;
    background-image: linear-gradient(#841E5A,#841E5A);
    background-size: 13% 6px;
    background-position: top right;
    background-repeat: no-repeat;
    padding: 50px;
    height: 100%;
`

export const Section3 = styled.div`
    grid-column: 1fr;
    box-shadow: 0px 10px 16px #0000001A;
    padding-top: 5px;
    background-image: linear-gradient(#F4865E,#F4865E);
    background-size: 13% 6px;
    background-position: top right;
    background-repeat: no-repeat;
    padding: 50px;
    height: 100%;
`

export const SectionFull = styled.div`

box-shadow: 0px 10px 16px #0000001A;
    padding-top: 5px;
    background-image: linear-gradient(#C51852,#C51852);
    background-size: 4% 6px;
    background-position: top right;
    background-repeat: no-repeat;
    padding: 50px;
    height: 100%;
`

export const Opacity = styled.div`
    grid-column: 1fr;
    box-shadow: 0px 10px 16px #0000001A;
    padding-top: 5px;
    background-image: linear-gradient(#F4865E,#F4865E);
    background-size: 13% 6px;
    background-position: top right;
    background-repeat: no-repeat;
    padding: 50px;
    opacity: .2;
    position: relative;
    height: 100%;
`


export const SectionWebsiteContainer = styled.div`
    display: grid;
   // max-width: 1200px; 
    gap: 30px;
    grid-template-columns: repeat(auto-fill, 400px);
    // gap: 40px 20px;
    // grid-template-columns: 1fr 1fr 1fr;
`
 
export const SectionWebsiteComponent = styled.div`
    grid-column: 1fr;

    
`

export const SectionWebsiteComponentBackground = styled.div`

    box-shadow: 0px 10px 16px #0000001A;
    padding-top: 5px;
    background-image: url(${image});
    background-size: cover;
    overflow:hidden;
    position: relative;
    padding: 50px 10px;
    padding-top: 60px;
    text-align: center;
   
    transition: 0.3s;
    transition-timing-function: ease-out;

    &:hover{
        padding-left: 14px;
        // width: 101%;
        // height: 81%;
        cursor: pointer;
        opacity: .8;
    }
    
`