import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Text } from "../../../styled-components/visualization";

export function ContentExplorerTreeMapContainer({ selectedContentUrl,contentExplorerResults }) {
  const [data, setData] = useState([])
  const [contentExplorerResultsCopy, setcontentExplorerResultsCopy] = useState(contentExplorerResults);
 
  useEffect ( ()=>{
    if(selectedContentUrl != ''){
      setcontentExplorerResultsCopy(contentExplorerResults.filter(result => result.url==selectedContentUrl))
      setData([])
    }else{
      setcontentExplorerResultsCopy(contentExplorerResults)
    }

  },[selectedContentUrl])
  

  useEffect(() => { 
    if(contentExplorerResultsCopy){
      const dataCopy = [];
      contentExplorerResultsCopy.map((result, i) => {
            
          if(selectedContentUrl != ''){
            //get the first 15 keywords of the selected row
            result.keywords.map( (value,j) => {
              if(j<15){
                dataCopy.push({
                  x: value.keyword, 
                  y: (value.search_volume)
                })  
              }
            })
            
          }else{
            if(i<15){ //get one keyword of each 15 table row
              dataCopy.push({
                x: result.keywords[0].keyword, 
                y: (result.keywords[0].search_volume)
              })  
            }
          }
        })
        setData(dataCopy)
        window.dispatchEvent(new Event('resize')); 
    }
  },
  [contentExplorerResultsCopy])

    const series =  [
        {
            data
        }
      ]
    
      const options =  {
        legend: {
          show: false
        },
        chart: {
          height: 250,
          type: 'treemap',
          toolbar: {
            show: true,
            tools: {
              download: '<img src='+require("../../../images/visualization/download.svg").default+' width="20">'
            }
          }
        },
        plotOptions: {
          treemap: {
            enableShades: false,

            shadeIntensity: 0.1,
            reverseNegativeShade: false,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
            ranges: [{
                from: 0,
                to: 150000,
                color: '#caa1b9',

            },
            {
                from: 150001,
                to: 300000,
                color: '#9c4a7a',
            },
            {
                from: 300001,
                to: 99999900,
                color: '#841e5a',
            },
            
            ],
          }
        }
        },
      }

     

  return (
    <>
    {contentExplorerResults && 
    <>
     <Text.Regular16Black style={{marginBottom:'-20px'}}>What users are searching for: </Text.Regular16Black>
      <Chart 
          options={options}
          series={series}
          type='treemap'
          height={450}
          width='100%'
      />
    </>
    }
  
  </>
  )
}
