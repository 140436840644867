import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { Content, Grid, Text, Button, } from "../../../styled-components/dashboard";
import { listWebsites } from '../../../actions/websiteActions'
import LoaderImage from "../../../components/LoaderImage";
import WebsiteComponent from "../../../components/websiteComponent";
import ErrorMessage from "../../../components/ErrorMessage";
import { listCompanyDetails } from '../../../actions/companyActions'
import { onboardedWebsites } from '../../../constants/utils'
import {  COMPETITOR_DETAILS_RESET } from "../../../constants/competitorConstants";
import { WEBSITE_DETAILS_BY_ID_RESET } from "../../../constants/websiteConstants";
import { KEYWORD_DETAILS_RESET } from "../../../constants/keywordConstants";

export function DashboardsAdminContainer({ isSuperuser, name, email, isActive, accessToken}) {
  const dispatch = useDispatch()
  let history = useHistory()

  const websiteList = useSelector(state => state.websiteList)
  const {
      loading: loadingWebsitesList, 
      error: errorWebsitesList, 
      success: successWebsitesList, 
      websites
  } = websiteList

  const companyDetails = useSelector(state => state.companyDetails)
  const {
      loading: loadingDetailsCompany, 
      error: errorDetailsCompany, 
      success: successDetailsCompany, 
      company: detailsCompany
  } = companyDetails

  const [actionNeeded, setActionNeeded] = useState(false)
  const [clickDropdown, setClickDropdown] = useState(false);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [notEmptyKeywordsWebsites, setNotEmptyKeywordsWebsites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const removePrefix = (name) => {
    return name.replace(/^(https?:\/\/|www\.|\/)/i, '');
  };

  useEffect(()=>{
    if(accessToken && email) 
        {
            if(isActive=== undefined && isSuperuser === undefined){
            } 
            else{
                if(isSuperuser){
                    if(successDetailsCompany){
                        if(successWebsitesList){
                            websites.map((website) =>{
                                if(website.no_of_keywords === 0){
                                    setActionNeeded(true)
                                }
                                const notEmptyKeywordsWebsites = websites.filter((website) => website.no_of_keywords !== 0 && website.name);
                                const sortedWebsites = [...notEmptyKeywordsWebsites].sort((a, b) =>
                                    removePrefix(a.name).toLowerCase().localeCompare(removePrefix(b.name).toLowerCase())
                                );
                                setNotEmptyKeywordsWebsites(sortedWebsites); 
                            })
                        }
                        else 
                        if(!loadingWebsitesList)
                        {
                            dispatch(listWebsites(accessToken, email))
                        }
                        } 
                     else {
                        dispatch(listCompanyDetails(accessToken, email, name))
                    }
                } else{
                    history.push(`/`)
                }
            }
        }
    },[email,
        accessToken,  
        dispatch,
        successWebsitesList,
        successDetailsCompany,
        detailsCompany,
        actionNeeded,
        isActive,
        isSuperuser
    ])

    const SaveHandler = (webId) =>{
        dispatch({type: COMPETITOR_DETAILS_RESET}) 
        dispatch({type: WEBSITE_DETAILS_BY_ID_RESET}) 
        dispatch({type: KEYWORD_DETAILS_RESET}) 

        history.push(`/edit-website/${webId}`)
    }

  
    const noCompanyContainer = () => {
        return(
            <>
            
            </>
            )
    }
    
    if(websites !== undefined){
        if(websites.filter((website) => website.no_of_keywords !== 0).length !== 0 && numberOfPages === 0){
            setNumberOfPages( Math.ceil(websites.filter((website) => website.no_of_keywords !== 0).length/ 9))
        }
    }
      
    const nextPage = () => {
        if (currentPage < numberOfPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const paginationLine = () =>{
        return (
            <div
            style ={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '3%',
                display: 'flex',
                flexDirection: 'row'
            }}
            >
            <img
                style ={{cursor: 'pointer', visibility: currentPage === 1 ? 'hidden': 'initial'}}
                onClick={()=>previousPage()}
                alt='noFound'
                src={require("../../../images/visualization/left_arrow.svg").default
                
                }
            />
            {currentPage !== numberOfPages ?
                <Text.Normal14Black>{(currentPage-1)*9+1} - {currentPage*9} / {websites.filter((website) => website.no_of_keywords !== 0).length}</Text.Normal14Black> :
                <Text.Normal14Black>{(currentPage-1)*9+1} - {websites.filter((website) => website.no_of_keywords !== 0).length} / {websites.filter((website) => website.no_of_keywords !== 0).length}</Text.Normal14Black>
            }
            
            <img
                style ={{cursor: 'pointer', visibility: currentPage === numberOfPages ? 'hidden': 'initial'}}
                onClick={() =>nextPage()}
                alt='noFound'
                src={require("../../../images/visualization/right_arrow.svg").default}
            />
            </div>
        )
    }

    const handleSearch = (event) => {
        const query = event.target.value;
        const filteredItems = websites.filter((website) => website.no_of_keywords !== 0 && website.website.toLowerCase().includes(query.toLowerCase()) || website.createdBy.toLowerCase().includes(query.toLowerCase()));
        const querySortedWebsites = [...filteredItems].sort((a, b) =>
                                    removePrefix(a.name).toLowerCase().localeCompare(removePrefix(b.name).toLowerCase())
                                );
        setSearchQuery(query);
        setFilteredItems(querySortedWebsites);   
    };

  return(
       <Content>
          {loadingWebsitesList || !successWebsitesList  ||
           loadingDetailsCompany || !successDetailsCompany
          ?
            <LoaderImage /> :
            errorWebsitesList || errorDetailsCompany ?
            <ErrorMessage /> :
            <Content.SectionTop  >
               
                    <Grid.SectionFull>
                        { !detailsCompany[0] ?
                        <>{noCompanyContainer()}</>
                         :
                            
                         websites[0] ? 
                        <>
                            <Text.Bold16Black>
                                New dashboards
                            </Text.Bold16Black>
                            <Text.Normal14Black
                                style={{
                                    marginBottom:'2rem'
                                }}
                            >
                                As a superuser, access all website which where created through Luminr Platform by any client.
                            </Text.Normal14Black>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearch}
                                style={{
                                    position: 'absolute',
                                    top: '80px',
                                    right: '50px',
                                    margin: '0',
                                    height: '40px',
                                    padding: '0 10px',
                                    background: 'transparent',
                                    border: '2px solid rgb(197, 24, 82)',
                                    color: '#222222',
                                    borderRadius: '4px'
                                }}
                            />
                            <Grid.SectionWebsiteContainer
                            style={{
                                gap: '40px 20px',
                                gridTemplateColumns: '1fr 1fr 1fr'
                            }}
                            >
                            {searchQuery ? filteredItems.map(website => (
                            <div key={website.id}>
                                <WebsiteComponent 
                                websiteId = {website.id}
                                websiteName={website.name}
                                websiteUrl={website.website}
                                websiteTheme={website.theme}
                                companyIndustry={website.industry}
                                admin={true}
                                createdBy={website.createdBy}
                                country = {website.country}
                                />
                                {/* Add the existing button or create a new one */}
                                <Button.Save 
                                style={{
                                    width: 'auto',
                                    margin: '0.5rem 0 0 0',
                                    height: '40px',
                                    padding: '0 10px',
                                    background: 'transparent',
                                    border: '2px solid rgb(197, 24, 82)',
                                    color: '#222222',
                                    fontWeight: 'bold',
                                    fontSize: '16px'
                                }}
                                    onClick={() =>SaveHandler(website.id)}
                                >
                                    Go to website
                                </Button.Save>
                                </div>
                            )) : notEmptyKeywordsWebsites
                            .slice((currentPage - 1) * 9, currentPage * 9)
                            .map(website => (
                                <div key={website.id}>
                                <WebsiteComponent 
                                websiteId = {website.id}
                                websiteName={website.name}
                                websiteUrl={website.website}
                                websiteTheme={website.theme}
                                companyIndustry={website.industry}
                                admin={true}
                                createdBy={website.createdBy}
                                country = {website.country}
                                />
                                <Button.GoTo 
                                    onClick={() =>SaveHandler(website.id)}
                                >
                                    Go to website
                                </Button.GoTo>
                                </div>
                            ))}
                           </Grid.SectionWebsiteContainer>
                           {searchQuery ? '' : paginationLine()}

                           <>
                                <div style={{ marginTop: '2.7rem' }} onClick={()=>setClickDropdown(!clickDropdown)}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <Text.Bold16Black>
                                            Old dashboards
                                        </Text.Bold16Black>
                                        <img style={{
                                                width: '15px',
                                                margin: '10px 0 0 10px',
                                                transform: clickDropdown ? 'rotateX(180deg)' : 'rotateX(0deg)'
                                            }} 
                                            alt='noFound'
                                            src="/static/media/icon_down_arrow_pink.70b9445f.svg" ></img>
                                    </div>
                                    <Text.Normal14Black
                                        style={{
                                            marginBottom:'1rem'
                                        }}
                                    >
                                        As a superuser, access all dashboard which were created before Luminr Platform.
                                    </Text.Normal14Black>
                                </div>
                                <Grid.SectionWebsiteContainer 
                                style={{ display : clickDropdown ? 'grid' : 'none',
                                    gap: '40px 20px',
                                    gridTemplateColumns: '1fr 1fr 1fr' }}>
                                {onboardedWebsites.map((website, i)=> (
                                    <WebsiteComponent 
                                    websiteId = {website.id}
                                    websiteName={website.name}
                                    websiteUrl={website.website}
                                    websiteTheme={website.theme}
                                    companyIndustry={website.industry}
                                    oldDashboard={true}
                                    />
                                ))}     
                                </Grid.SectionWebsiteContainer>
                            </>

                           {/* {actionNeeded && 
                           <>
                           <Text.Bold16Black
                           style={{
                                 
                                 marginTop: '4rem'
                             }}>
                            Action required
                           </Text.Bold16Black>
                           <Text.Normal14Black
                                
                            >
                                Add all information needed for your website in order to get the full power of Luminr.
                            </Text.Normal14Black>
                           {websitesTable()}
                           </>
                        } */}
                        </> :
                            <>
                            <Text.Normal26Black>
                                You have not added any website yet.
                            </Text.Normal26Black>
                            <Text.Normal14Black>
                                Please go to websites section and add your first website.
                            </Text.Normal14Black>
                               
                            </>
                        }
                    </Grid.SectionFull>

            {/* <Welcome.FooterRightImageForAC 
            style={{
                right: '-50px'
            }} 
            src={require("../../../images/visualization/04.png").default} /> */}
            </Content.SectionTop>
          }
      </Content>
  )
}
